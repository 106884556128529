import React, { useState, useEffect } from 'react'
import { Alert, Button, FormHelperText, Grid, Input, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import { Link, withRouter } from "react-router-dom";
// import { fontWeight } from '@mui/system';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { OPERATION, operationValue } from '../../../../Controller/Global';
import { useFormik } from 'formik';
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import { SESSION_ID, setSessionId, setUser, setUserId, USER_ID, getUser, setUserType,getUserType, getFCM_Token } from "../../../../Controller/localStorageHandler";
import { GoogleLogin } from 'react-google-login';


const SignUpForm = (props) => {
    const { code } = useParams()

    const history = useHistory()

    useEffect(()=>{
        const user_type = getUserType();
        if (user_type === "Accommodation") {
          props.history.push('/accommodationDashboard')
        }
        else if (user_type === "University") {
          props.history.push('/universityDashboard')
        }
        else if (user_type === "Partner") {
          props.history.push('/educationDashboard')
        }
        else if (user_type === "Internship/Job") {
          props.history.push('/internshipDashboard')
        }
        else if (user_type === "Employee" || user_type === "Associate") {
          props.history.push('/associates')
        } else if(user_type === 'Student'){
          props.history.push('/dashboard')
        }
        else{
    
        }
      },[])

    let [error, setError] = useState('');
    let [portalErr, setPortalErr] = useState('');
    let [success, setSuccess] = useState('')
    let [validate, handleValidate] = useState(false);
    const[email,setEmail] = useState('');
    const formik = useFormik({
        initialValues: {
            username: '',
            phone_mobile: '',
            email_address: '',
        },
        onSubmit: (values) => {
            handleValidate(true);
            if (values.username && values.email_address && phone) {
                values[OPERATION] = operationValue.SIGNUP;
                const formData = new FormData()
                for (let val in values) {
                    if (val === 'phone_mobile') {
                        formData.append(val, phone)
                    } else {
                        formData.append(val, values[val])
                    }
                }
                if(getFCM_Token()){
                    formData.append("fcm_token",getFCM_Token())
                    console.log(getFCM_Token())
                }
                formData.append('from_url',window.location.href);
                // return
                axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
                    .then(response => {
                        console.log(response)
                        if (response.data.status === "success") {
                            setSuccess('Welcome to ISHVI Free Study Abroad. Password is sent to your email for Login')
                            // history.push('/login')
                            // setTimeout(function () {
                            //     props.history.push('/')
                            // }, 3000)
                        } else {
                            setError(response.data.message)
                            
                            if(response?.data?.portal?.portal === '1'){
                                setSuccess('Your account is active... You can Signin Or Reset Your Password here')
                            }
                            if(response?.data?.portal?.portal === '0'){
                                setEmail(response?.data?.portal?.email);
                                console.log(response?.data?.portal?.portal)
                                setPortalErr('Your account is deactivated.. would you like to activate..')
                            }
  
                        }
                        console.log(response)
                    })
            }
        }

    });

    const activate_acc = async ()=>{

        const formData= new FormData()
        // values[OPERATION]=operationValue.ForgotPassword
        formData.append('operation','forgotPassword');
        formData.append('username',email);
        
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",formData)
        .then(response => {
            console.log(response)
            if(response.data.status === 'success'){
              setSuccess('Forgotten Password after submission let the following message be displayed New Password for ISHVI Free Study Abroad is been sent to your email for Login')
              setTimeout(function(){ history.push('/') },3000)
            }else{
              setError(response.data.msg)
            }
        })
    }
    // let history = useHistory();
    // let [values, handleValues] = useState({});
    const classes = useStyles()
    const [phone, setSelected] = useState('');
    const [check, setcheck] = useState(false);


    const responseGoogle = (response) => {
        console.log(response)
        // google login
        if(response){

            const formData = new FormData()
            formData.append('operation',operationValue.SIGNUP);
            formData.append('username',response?.profileObj?.givenName+''+response?.profileObj?.givenName)
            formData.append('email_address',response?.profileObj?.email)

            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
            .then(response => {
                console.log(response)
                if (response.data.status === "success") {
                    setSuccess('Welcome to ISHVI Free Study Abroad. Password is sent to your email for Login')
                } else {
                    setError(response.data.message)
                    
                    if(response?.data?.portal?.portal === '1'){
                        setSuccess('Your account is active... You can Signin Or Reset Your Password here')
                    }
                    if(response?.data?.portal?.portal === '0'){
                        setEmail(response?.data?.portal?.email);
                        console.log(response?.data?.portal?.portal)
                        setPortalErr('Your account is deactivated.. would you like to activate..')
                    }

                }
                console.log(response)
            })
        }
      }
    
    const googleErr = (response) => {
        console.log(response);
    }

    if (success) {
        return (<><Alert style={{ marginBottom: '' }} severity="success">{success}</Alert>
        <Typography
            sx={{ color: "blue", textAlign: "center", fontSize: "15px", m: 1 }}
          >
            
            <Button variant="contained" onClick={() => props.history.push('/login')}>Go To Login</Button>
            <Button onClick={() => props.history.push('/forgetPassword')}>Forget Password?</Button>
          </Typography>
        </>);
    } else {
        return (
            <>
                {/* {error && <Alert severity="error">{error}</Alert>} */}
                {portalErr && 
                
                <Typography
            sx={{ color: "blue", textAlign: "center", fontSize: "15px", m: 1 }}
          >
              <Alert severity="error">{ error} {'. '}{portalErr} <Button onClick={() => activate_acc()}>Acivate my account.</Button></Alert>
            
            
          </Typography>
                }
                

                <form onSubmit={formik.handleSubmit}>
                    <Grid xs={12}>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px",
                                }}
                            >
                                <svg width="17" height="22" viewBox="0 0 17 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="8.57881" cy="6.27803" rx="4.77803" ry="4.77803" stroke="#1A1A1A"
                                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.00002 17.7014C0.998732 17.3655 1.07385 17.0338 1.2197 16.7312C1.67736 15.8158 2.96798 15.3307 4.03892 15.111C4.81128 14.9462 5.59431 14.8361 6.38217 14.7815C7.84084 14.6534 9.30794 14.6534 10.7666 14.7815C11.5544 14.8367 12.3374 14.9468 13.1099 15.111C14.1808 15.3307 15.4714 15.7701 15.9291 16.7312C16.2224 17.348 16.2224 18.064 15.9291 18.6808C15.4714 19.6419 14.1808 20.0813 13.1099 20.2918C12.3384 20.4635 11.5551 20.5767 10.7666 20.6305C9.57937 20.7311 8.38659 20.7495 7.19681 20.6854C6.92221 20.6854 6.65677 20.6854 6.38217 20.6305C5.59663 20.5773 4.81632 20.4641 4.04807 20.2918C2.96798 20.0813 1.68652 19.6419 1.2197 18.6808C1.0746 18.3747 0.999552 18.0402 1.00002 17.7014Z"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>

                                <Input
                                    type="name"
                                    name='username'
                                    className={classes.input}
                                    autoFocus
                                    error={validate && !formik.values.username}
                                    placeholder="Name"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            {validate && !formik.values.username ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Username</FormHelperText> : ''}
                        </Grid>

                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px", margin: "16px 0",
                                }}
                            >
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.2686 7.06116L12.0031 10.4954C11.1959 11.1283 10.0644 11.1283 9.25714 10.4954L4.95508 7.06116"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.88787 1.5H15.3158C16.6752 1.51525 17.969 2.08993 18.896 3.0902C19.823 4.09048 20.3022 5.42903 20.222 6.79412V13.322C20.3022 14.6871 19.823 16.0256 18.896 17.0259C17.969 18.0262 16.6752 18.6009 15.3158 18.6161H5.88787C2.96796 18.6161 1 16.2407 1 13.322V6.79412C1 3.87545 2.96796 1.5 5.88787 1.5Z"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>

                                <Input
                                    // style={{ width: "80%",marginLeft: "10px"}}
                                    type="email"
                                    name="email_address"
                                    className={classes.input}
                                    autoFocus
                                    placeholder="Email@gmail.com"
                                    error={validate && !formik.values.email_address}
                                    value={formik.values.email_address}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            {validate && !formik.values.email_address ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Email</FormHelperText> : ''}
                        </Grid>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px 30px",
                                    margin: "16px 0",
                                }}
                            >
                                <PhoneInput
                                    country={'in'}
                                    name='phone_mobile'
                                    placeholder="Enter Number"
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: "0",
                                        opacity: "0.8"
                                    }}
                                    //   value={phone}
                                    searchPlaceholder={'Mobile Number'}
                                    inputClass={"mobile-field"}
                                    onChange={(phone) => {
                                        setSelected(phone)
                                        //   handleChange()
                                    }}
                                    value={formik.values.phone_mobile}
                                //   onChange={formik.handleChange}
                                />
                                {/*<input*/}
                                {/*    type="number"*/}
                                {/*    className={classes.input}*/}
                                {/*    autoFocus*/}
                                {/*    placeholder="Mobile Number"*/}
                                {/*/>*/}
                            </Grid>
                            {validate && !formik.values.username ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Phone Number</FormHelperText> : ''}
                        </Grid>

                        <Grid xs={12} sx={{ display: "flex", margin: "20px 0", }}>
                            <input type="checkbox"
                                //   {...label}
                                //   defaultChecked
                                style={{
                                    height: "22px",
                                    width: "30px",
                                    background: "orange",
                                    border: " 1px solid #DCE8FF"
                                }}
                                //    onChange={handleChange}
                                onChange={(e) => setcheck(e.target.checked)}
                            />
                            <Typography
                                sx={{
                                    color: "#666666",
                                    textAlign: "left",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    marginLeft: "5px",
                                    cursor:"pointer"
                                }}
                                onClick={() => props.history.push('/privacy-policy-front')}
                            >
                                By creating your account you agree with to our 
                                <span className={classes.Terms}> Terms</span> and <span
                                        className={classes.Terms}>Conditions</span>
                            </Typography>
                        </Grid>
                        <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                // onClick={handleSubmit}
                                type="submit"
                                disabled={!check}
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Sign UP
                            </Button>

                            <Typography
                                sx={{
                                    fontSize: "16",
                                    lineHeight: "20px",
                                    color: "#000",
                                    textAlign: "center",
                                    mb: 2,
                                    marginTop: 2
                                }}
                            >
                                Already have an account? -
                                <Link to={'/login'}><strong className={classes.Sign}>SIGN IN</strong></Link>
                            </Typography>
                        </Grid>
                        {/* <Grid xs={12} container>
                        <Grid xs={6} sx={{ margin: "auto", textAlign: "center" }}>
                        <GoogleLogin
                            clientId="399250898404-7uuvjbcogl2jlqao585t3l5t3atntcqf.apps.googleusercontent.com"
                            buttonText="Login with Google"
                            onSuccess={responseGoogle}
                            onFailure={googleErr}
                            cookiePolicy={'single_host_origin'}
                        />
                        
                        </Grid>
                        </Grid> */}
                        <br/>
                        <Grid xs={12} container>
                        <Grid xs={3} sx={{ margin: "auto", textAlign: "center" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/event.png`} height="30px" />
                        &nbsp;
                            <Button
                                // onClick={handleSubmit}
                                type="submit"
                                component={Link}
                                to="/EventsList"
                                variant="contained"
                                sx={{
                                    fontSize: "12px",
                                  
                                    padding: " 3px  8px",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                  
                                }}
                            >
                                Events
                            </Button>
                        </Grid>
                        <Grid xs={3} item sx={{ margin: "auto", textAlign: "center" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/whatsapp1.png`} height="30px" />
                        &nbsp;
                            <Button
                                // onClick={handleSubmit}
                                type="submit"
                                component={Link}
                                to="/WhatsappGroup"
                                variant="contained"
                                sx={{
                                    fontSize: "12px",
                                 
                                    padding: " 3px  8px",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                  
                                }}
                            >
                                
                                Whatsapp
                            </Button>
                        </Grid>
                       
                        <Grid xs={3} sx={{ margin: "auto", textAlign: "center" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/telegram.png`} height="30px" />
                        &nbsp;
                            <Button
                                // onClick={handleSubmit}
                                type="submit"
                                component={Link}
                                to="/TelegramGroup"
                                variant="contained"
                                sx={{
                                    fontSize: "12px",
                                  
                                    padding: " 3px  8px",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                  
                                }}
                            >
                               Telegram
                            </Button>
                        </Grid>
                        <Grid xs={3} sx={{ margin: "auto", textAlign: "center" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.jpeg`} height="30px" />
                        &nbsp; 
                            <Button
                                // onClick={handleSubmit}
                                type="submit"
                                component={Link}
                                to="/FacebookGroup"
                                variant="contained"
                                sx={{
                                    fontSize: "12px",
                                  
                                    padding: " 3px  8px",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                
                                }}
                            >
                                Facebook
                            </Button>
                        </Grid>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}
export default withRouter(SignUpForm)

const useStyles = makeStyles(() => ({

    Sign: {
        color: "#1b6cfc",
        fontSize: "14px",
    },
    Terms: {
        color: "#1b6cfc",
    },
    input: {
        borderColor: "transparent",
        outline: "none",
        marginLeft: "10px",
        fontSize: "16px",
        width: "80%",
        display: "flex",
        opacity: 0.7,
        letterSpacing: 0.7,
    },
    filterIcon: {
        position: "relative",
        top: 10,
        height: "75px",
        width: "75px",
        //  boxShadow : '2px 2px 10px #DCDCDC','
    },

}))