import { Grid, Typography, CircularProgress, Chip, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Person from '../../static/img/Mentor/person.png'
import { makeStyles } from "@mui/styles"
import Stars from '../../static/img/Mentor/start.png'
import "./card.css"
import Icons from './MentorDetails/Icons'

import useAppContext from "../../useContext/userContext"

function ScondCard() {
    const classes = useStyles()

    const {getMentorList, mentor_list, loading} = useAppContext()

    useEffect(()=>{
        getMentorList();
    },[]);
    

    return (
        <div className="mentors_card_section">
            {loading ?
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div> :

        mentor_list?.mentor_list?.list?.length > 0 && mentor_list?.mentor_list?.list?.map((d) => {

                    return <>
             
            <div className="card_1">

            
                <Grid xs={12} sx={{ width: "100%", display: "flex", mt: 3, position:'relative' }}>
                <Icons data={d}  />
                    
                    <Grid xs={4.5} sx={{ width: "100%", display: "flex", backgroundAttachment: "fixed" }}>
                        <img src={Person} alt='person' className={classes.PreImg} />

                    </Grid>
                    <Grid xs={7.5} sx={{ ml: 1, width: "100%", }}>
                        {/* <img src={Heart} alt='heart' className={classes.hearts} /> */}

                        <Typography
                            sx={{

                                color: "#000",
                                fontWeight: "600",
                                fontSize: "16px",
                                textAlign: "left",
                                width: "100%",
                            }}
                        >
                           {d.firstname} {d.lastname}
                        </Typography>

                        <img src={Stars} alt='stars' className={classes.star} />
                        <Typography
                            sx={{
                                color: "#000",
                                fontSize: "14px",
                                ml: 1
                            }}
                        >
                            10 Reviews
                        </Typography>
                        <Grid xs={12} sx={{ width: "100%", display: "flex", }}>
                            <Grid xs={6} sx={{ mr: 0.5, borderRight: "1px solid gray" }}>

                                <Typography
                                    sx={{

                                        color: "gray",
                                        fontSize: "14px",
                                    }}
                                >
                                    Experience
                                </Typography>
                                <Typography
                                    sx={{

                                        color: "#000",
                                        fontSize: "16px",
                                    }}
                                >
                                    {d.work_experience} Years
                                </Typography>
                                <Typography
                                    sx={{
                                        marginTop: "8%",
                                        color: "gray",
                                        fontSize: "14px",
                                    }}
                                >
                                    Countires
                                </Typography>
                                <Typography
                                    sx={{

                                        color: "#000",
                                        fontSize: "16px",
                                    }}
                                >
                                    {/* {d.country} */}
                                    <Stack direction="column" spacing={1}>
                                    {
                                        
                                        d.country && d.country.split('|##|').map((con) =>{
                                            // return con+"\n"
                                            if(con !== ' '){
                                            return <Chip size="small"  color="warning" label={`${con}`} />
                                            }
                                        })
                                    }
                                    </Stack>
                                </Typography>

                                <Typography
                                    sx={{

                                        marginTop: "8%",
                                        color: "gray",
                                        fontSize: "14px",
                                    }}
                                >
                                    Location
                                </Typography>
                                <Typography
                                    sx={{

                                        color: "#000",
                                        fontSize: "16px",
                                    }}
                                >
                                    Bangalore
                                </Typography>
                            </Grid>

                            <Grid xs={6} >
                                <Typography
                                    sx={{

                                        color: "gray",
                                        fontSize: "14px",
                                    }}
                                >
                                    Student
                                </Typography>
                                <Typography
                                    sx={{

                                        color: "#000",
                                        fontSize: "16px",
                                    }}
                                >
                                    10
                                </Typography>

                                <Typography
                                    sx={{
                                        marginTop: "8%",
                                        color: "gray",
                                        fontSize: "14px",
                                    }}
                                >
                                    Services
                                </Typography>
                                <Typography
                                    sx={{

                                        color: "#000",
                                        fontSize: "16px",
                                    }}
                                >
                                    {/* {d.services} */}
                                    <Stack direction="column" spacing={1}>
                                    {
                                        
                                        d.services && d.services.split('|##|').map((con) =>{
                                            // return con+"\n"
                                            return <Chip size="small" color="success" variant="outlined" label={`${con}`} />
                                        })
                                    }
                                    </Stack>
                                </Typography>


                                
                            </Grid>
                        </Grid>

                        
                    </Grid>
                </Grid>

            </div>
            <hr/>
            </>
               })
            }

        </div>
    )
}

export default ScondCard


const useStyles = makeStyles(() => ({
    PreImg: {
        display: "flex",
        // width: "100%",
        height:"150px",
        // margin:'auto'
    },
    star: {
        height: "15px",
        marginTop: "2px",
        width: "15px",
        float: "left",
    },
    Icons: {
        padding: "5px",
        color: "#1b6cfc",
        borderRadius: "100px",
        boxShadow: '2px 2px 10px #DCDCDC',
        justifyContent: "space-around",
    },
    hearts: {
        float: "right",
        marginRight: "10px",
        marginBottom: "40px"
    },
    Divs: {
        display: "flex"
    }

}))