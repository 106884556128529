import { Grid, Chip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { getUser, getUserId, getUserType } from '../../Controller/localStorageHandler';
import { getStudentCountListUsingStatusAction1 } from '../Action/AssociateDocumentList';
import DashStepper from "../Dashboards/Dashfor/DashStepper";
import SearchBoxWithFilter from '../SearchBox/SearchBoxWithFilter';
import { AsyncMulti } from '../StudentSearch';
import Message from '../../img/Message.png';
import whatsapp from '../../img/whatsapp.png';
import { CommonEmailSendAction } from '../Action';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'

const ProfileEvaluation = (props) => {
    const [SearchSelectId, setSearchSelectId] = React.useState(null)
    const [Student, setStudent] = React.useState(getUser())
    const history = useHistory()

    let UserLocalstorge = getUser()
    let user_contact_type = UserLocalstorge?.user_contact_type || ''

    const GetSelectedData = (dataId) => {
        var data = JSON.stringify({
            "contact_no": "",
            "status": "All",
            "year": "All",
            "semester": "All",
            "daterange": "",
            "limit_start": 0,
            "limit_end": 9,
            "search": dataId,
            "bypass": "validation"
        });
        getStudentCountListUsingStatusAction1(data).then((Data) => {
            if (Data?.data?.total > 0) {
                let contact_no = Data?.data?.list[0]
                setStudent(contact_no)
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }
    const SubmitFilter = () => {

    }
    const getEmailString = () => {
        var data = new FormData();
        data.append('type', 'FavpageEmail');
        data.append('contactid', Student?.contactid || getUserId());
        data.append('module', 'Emails');
        data.append('action', 'mailsend');
        data.append('frm_name', 'company_name');
        CommonEmailSendAction(data).then((data) => {
            toast.success(`email send Successfully`)
        }).catch((error) => {
            toast.error(`${error.message}`)
        })
    }
    const getString = () => {
        // let student1 = getUser()
        // const documents_required = GetDocumentRequiredList(Student)
        // return `phone=${Student?.phone || ''}&text=%0D%0A%0D%0A Dear${Student?.firstname} ${Student?.lastname}%0D%0A%0D%0AGreetings from *BRAINSTORM CONSULTING PVT LTD*%0D%0A%0D%0APlease find the *${data.length}* Universities 🏫  based on your profile in our App. %0D%0A%0D%0A Please *confirm and Upload Documents* for processing your application.%0D%0A%0D%0AExplore 🏫 *60000+* Programs and Universities, 10000+ Scholarship, 5000+ SOP and 
        // LOR Samples. 
        // %0D%0A Android: https://bit.ly/FreeStudyAbroad
        // %0D%0A iOS: https://apple.co/33ujR4Q
        // %0D%0A ${GetUniverCityProgramStatusRemarData()}
        // ${documents_required}
        // %0D%0A%0D%0A *Upload* : https://youtu.be/ix173Ac0hQA  
        // %0D%0A%0D%0A ${student1?.firstname || ''} ${student1?.lastname || ''} 
        // ${student1?.assigned_phone_mobile ? `%0D%0A${student1?.assigned_phone_mobile}` : ''}
        // ${student1?.assigned_phone_work ? `%0D%0A${student1?.assigned_phone_work}` : ''}
        // ${student1?.assistantphone ? `%0D%0A${student1?.assistantphone}` : ''}
        // ${student1?.assigned_email1 ? `%0D%0A📧${student1?.assigned_email1}` : ''}
        // ${student1?.reports_to_email1 ? `%0D%0A📧${student1?.reports_to_email1}` : ''}
        // ${student1?.account_website ? `%0D%0A${student1?.account_website}` : ''}     
        // %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
        // %0D%0A%0D%0A iOS: https://apple.co/33ujR4Q  
        // `
    }


    return (
        <>
            <div style={{ position: "relative", marginBottom: 20 }}>
                {user_contact_type !== "Student" && <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <AsyncMulti inputData={Student} setSearchSelectId={GetSelectedData} SubmitFilter={SubmitFilter} />
                </div>
                }
                <span style={{margin:'auto',width:'auto', display:'flex', alignItems:'center',justifyContent:'center'}}>
                <Chip color="success" label="Profile Evaluation" />
                </span>
            </div>


            {user_contact_type !== "Student" &&
                <Box sx={{ marginLeft: 0, marginRight: 0, borderRadius: 2 }}>
                    <Grid container spacing={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
                        {/* <Grid item xs={9} sx={{ mt: 1, fontWeight: 'bold' }}>
                            {Student?.firstname} {Student?.lastname}
                        </Grid> */}
                        <Grid xs={9} sx={{ width: "55%", display: "flex", justifyContent: "left", alignItems: "left" }} onClick={() => {
                            history.push({ pathname: '/Profile', state: { StudentData: Student, formNo: 1 } })
                        }}>
                            {
                                getUserType() !== 'Student'?
                            <p style={{ color: "#1A1A1A", fontSize: 20, fontWeight: "bold", color: "blue", cursor: "pointer" }} ><span> {Student?.firstname} {Student?.lastname}</span></p>
                            :
                            ''}
                        </Grid>

                        <Grid item xs={3} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between" }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                                height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                            }}>
                                <span onClick={() => getEmailString()} >
                                    <img src={`${Message}`} style={{ height: 30, width: 30 }} />
                                </span>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                                height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                            }}>
                                <a href={`https://api.whatsapp.com/send?${getString()}`} >
                                    <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                                </a>
                            </div>
                        </Grid>

                    </Grid>
                    <hr />
                </Box>}
            <DashStepper props={props.location.state} Student={Student} />
        </>
    );
};

export default ProfileEvaluation;