import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dashform from "./DashForm";
import AcademicForm from "./AcademicForm";
import PrerequisiteForm from "./PrerequisiteForm";
import { useEffect } from 'react'
import axios from 'axios';
import { getUser, USER_ID, getUserId, getSessionId, SESSION_ID, Contact_Id, setUser, logout } from '../../../Controller/localStorageHandler';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { OPERATION, operationValue } from '../../../Controller/Global';
import { fetchProfileDetails } from '../../Action';
import _ from 'lodash'

export default function DashStepper({ props, moduleEvent, Student }) {

  const history = useHistory()
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [UserDetail, setUserDetails] = React.useState(getUser())
  const [countryValue, setcountryValue] = React.useState([])
  const [programValue, setprogramValue] = React.useState([])
  const [semesterValue, setsemesterValue] = React.useState([])
  const [yearValue, setyearValue] = React.useState([])
  const [ieltsData, setieltsData] = React.useState([0, 9])
  const [toeflData, settoeflData] = React.useState([0, 120])
  const [pteData, setpteData] = React.useState([0, 90])
  const [duolingoData, setduolingoData] = React.useState([10, 160])
  const [greData, setgreData] = React.useState([260, 340])
  const [gmatData, setgmatData] = React.useState([200, 800])
  const [actData, setactData] = React.useState([1, 36])
  const [satData, setsatData] = React.useState([400, 1600])
  const [englishData, setEnglish] = React.useState([0, 100])
  const [first, setFirst] = React.useState('')
  const [second, setSecond] = React.useState('')
  const [loader, setLoader] = React.useState(false)

  // let [values, handleValues] = React.useState({});

  // const handleChange = (e) => {
  //   handleValues(prevState => ({...prevState, [e.target.name]: e.target.value}));

  // }

  useEffect(() => {
    let temp = getUser()
    setUserDetails(temp)
    setLoader(true)
    if (temp) {
      console.log(temp?.country)
      setcountryValue(temp?.country ? [temp?.country] : [])
      setprogramValue(temp?.related_programs ? [temp?.related_programs] : [])
      setsemesterValue(temp?.semester_looking_for)
      setyearValue(temp?.year_looking_for)
      setTimeout(() => {
        setLoader(false)
      }, 1000);
    }

  }, [])
  // let countryValues = Student?.country?.includes('|##|') ? Student?.country?.split("|##|") : Student?.country
  //     countryValues = _.map(countryValues, (d) => d.trim())
  useEffect(() => {
    setLoader(true)
    setUserDetails(Student)
    if (Student) {
      setcountryValue(Student?.country ? [Student?.country] : [])
      setprogramValue(Student?.related_programs ? [Student?.related_programs] : [])
      setsemesterValue(Student?.semester_looking_for)
      setyearValue(Student?.year_looking_for)
      setTimeout(() => {
        setLoader(false)
      }, 1000);
    }
  }, [Student])

  let Data = {
    degree_looking_for: UserDetail?.degree_looking_for,
    uni_puc_inter_12: 0.00,
    num_backlogs: 0,
    academic_requirements_gpa: 0.00,
    checked: '',
    English: ''
  }

  let LogingUser = getUser()
  let user_contact_type = LogingUser?.user_contact_type || ''
  const formik = useFormik({
    initialValues: Data,

    onSubmit: (values) => {
      console.log(countryValue)
      console.log(countryValue[0].includes('|##|'))
      
      const popupData = new FormData()
      if(programValue[0].includes('|##|')){
        let programData = _.map(programValue[0]?.split("|##|"), (d) => d.trim())
        console.log(programData)
        programData.map((d, i) => {
          popupData.append(`program[${i}]`, d);
          console.log(d)
      })
      }
      else{
        programValue.length > 0 ? programValue.map(o => popupData.append(`values[related_programs][]`, o)) : popupData.append(`values[related_programs][]`, '')
      }

      if(countryValue[0].includes('|##|')){
        popupData.append('country',countryValue[0])
      }
      else{
        countryValue.map((d, i) => {
          popupData.append(`country[${i}]`, d);
          console.log(d)
      })
      }

      popupData.append('module', 'Contacts')
      popupData.append(SESSION_ID, getSessionId())
      popupData.append(USER_ID, getUserId())
      popupData.append('operation', 'updateRecord')
      popupData.append('values[redirectpage]', 'dashboard')
      for (let val in values) {
        popupData.append(`values[${val}]`, values[val])
      }
      
      
      // countryValue.length > 0 ? countryValue.map(o => popupData.append(`values[country][]`, o)) : popupData.append(`values[country][]`, '')
      
      popupData.append('values[year_looking_for]', yearValue)
      popupData.append('values[semester_looking_for]', semesterValue)



      if (user_contact_type === "Student") {
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", popupData)
          .then((response) => {
            console.log(response)
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            let userId = getUserId()
            if (userId) {
              var formdata = new FormData();
              formdata.append(Contact_Id, getUserId());
              formdata.append(SESSION_ID, getSessionId())
              formdata.append(USER_ID, getUserId())
              formdata.append(OPERATION, operationValue.Fetch_profile_details)

              axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', formdata)
                .then((response) => {
                  if (response.statusText === "OK") {
                    setUser(response.data.data[0])
                  } else {
                    logout()
                  }
                })
            }
          })
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

  });
  const steps = [
    {
      label: 'Looking for',
      description: <Dashform
        values={formik.values}
        countryValue={countryValue}
        programValue={programValue}
        semesterValue={semesterValue}
        yearValue={yearValue}
        setyearValue={(data) => setyearValue(data)}
        setsemesterValue={(data) => setsemesterValue(data)}
        setcountryValue={(data) => setcountryValue(data)}
        setprogramValue={(data) => setprogramValue(data)}
        handleChange={(data) => formik.handleChange(data)}
      />,
    },
    {
      label: 'Academic',
      description: <AcademicForm
        values={formik.values}
        countryValue={countryValue}
        handleChange={formik.handleChange}
      />
    },
    {
      label: 'Test Scores',
      description: <PrerequisiteForm
        values={formik.values}
        countryValue={countryValue}
        programValue={programValue}
        pteData={pteData}
        ieltsData={ieltsData}
        toeflData={toeflData}
        duolingoData={duolingoData}
        actData={actData}
        satData={satData}
        greData={greData}
        gmatData={gmatData}
        englishData={englishData}
        setactData={(data) => setactData(data)}
        setgmatData={(data) => setgmatData(data)}
        setsatData={(data) => setsatData(data)}
        setgreData={(data) => setgreData(data)}
        setpteData={(data) => setpteData(data)}
        setieltsData={(data) => setieltsData(data)}
        settoeflData={(data) => settoeflData(data)}
        setduolingoData={(data) => setduolingoData(data)}
        setFirst={(data) => setFirst(data)}
        setSecond={(data) => setSecond(data)}
        setEnglish={(data) => setEnglish(data)}
        handleChange={(data) => formik.handleChange(data)}
      />
    },

  ];


  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }


    if (activeStep === 0) {
      formik.handleSubmit()
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      if (activeStep === 2) {
        const formData = new FormData()

        formData.append('operation', 'profileEvaluationDetails')
        for (let val in formik.values) {
          if (val === 'checked') {
            formData.append('addonConditionType', formik.values[val])
          } else if (val === 'degree_looking_for') {
            formData.append('degree_type', formik.values.degree_looking_for)
          }
          else {
            formData.append(val, formik.values[val])
          }
        }
        formData.append('addonConditionType', '')
        formData.append(`duolingofrom`, duolingoData[0])
        formData.append(`duolingoto`, duolingoData[1])
        formData.append(`ptefrom`, pteData[0])
        formData.append(`pteto`, pteData[1])
        formData.append(`toeflfrom`, toeflData[0])
        formData.append(`toeflto`, toeflData[1])
        formData.append(`ieltsfrom`, ieltsData[0])
        formData.append(`ieltsto`, ieltsData[1])

        formData.append(`grefrom`, greData[0])
        formData.append(`greto`, greData[1])
        formData.append(`gmatfrom`, gmatData[0])
        formData.append(`gmatto`, gmatData[1])
        formData.append(`actfrom`, actData[0])
        formData.append(`actto`, actData[1])
        formData.append(`satfrom`, satData[0])
        formData.append(`satto`, satData[1])
        // formData.append(`country`,countryValue)

        if (first && formData.append('group1', first))
          if (second && (second === 'act' || second === 'sat') ? formData.append('group3', second) : formData.append('group2', second))

            formData.append('call_frm', 'website')
        formData.append('page', '1')

        if(programValue[0].includes('|##|')){
          let programData = _.map(programValue[0]?.split("|##|"), (d) => d.trim())
  
          programData.map((d, i) => {
            formData.append(`program[${i}]`, d);
            console.log(d)
        })
        }
        else{
          console.log('this')
          programValue.map(o => formData.append(`program[]`, o))
         }

         if(countryValue[0].includes('|##|')){
          formData.append('country',countryValue[0])
        }
        else{
          countryValue.map((d, i) => {
            formData.append(`country[${i}]`, d);
            console.log(d)
        })
        }
        
        // countryValue.map(o => formData.append(`country[]`, o))
        formData.append(USER_ID, getUserId())

        var object = {};
        formData.forEach(function (value, key) {
          object[key] = value;
        });
        console.log("object", object)
        let name = {
          data: object
        }
        // if (user_contact_type === "Student") {
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
          .then(response => {
            console.log(response)
            if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
              // history.push('/UniversitySearchResult', response.data)
              history.push({ pathname: '/UniversitySearchResult', state: { data: response.data, screen: 'profileEvaluationDetails', page: JSON.stringify(Object.fromEntries(formData)) } })
            } else {
              setActiveStep(0)
              alert(response.data.msg || response.data.message)
            }
          })
        // } else {
        //   // debugger
        //   history.push({ pathname: '/UniversitySearchResult', state: { data: name, screen: 'profileEvaluationDetails', page: JSON.stringify(Object.fromEntries(formData)) } })
        //   // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }
      }
    }

  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if (loader) {
    return (<div className="gooey">
      <span className="dot"></span>
      <div className="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    )
  } else {
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((o, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     // <Typography variant="caption">Optional</Typography>
            //   );
            // }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={o.label} {...stepProps}>
                <StepLabel {...labelProps}>{o.label}</StepLabel>
                {/*{steps[activeStep].description}*/}
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <div style={{ textAlign: 'center', marginTop: '55%' }}>
              <CircularProgress />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/*<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>*/}
            {steps[activeStep].description}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {/*{isStepOptional(activeStep) && (*/}
              {/*  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>*/}
              {/*    Skip*/}
              {/*  </Button>*/}
              {/*)}*/}
              {!moduleEvent ?
                <Button style={{ backgroundColor: "#1B6CFC", color: "#fff", borderRadius: "50px", width: "150px", boxShadow: "0 5px 8px 0 rgba(0,0,0,0.3)" }} onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                : moduleEvent && activeStep !== steps.length - 1 ? <Button style={{ backgroundColor: "#1B6CFC", color: "#fff", borderRadius: "50px", width: "150px", boxShadow: "0 5px 8px 0 rgba(0,0,0,0.3)" }} onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button> : ""}
            </Box>
          </React.Fragment>
        )}
      </Box>
    );
  }
}
