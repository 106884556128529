import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { FormControl, TextField, Chip } from '@mui/material';

import { styled } from '@mui/system';

import { fetchAddStudentAction, fetchAddVisaMock, fetchComoonPickerListAction } from '../Action';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Stack from '@mui/material/Stack';

import _ from 'lodash'
import moment from 'moment';
import { CommonLogin } from '../Forms/CommonLogin';
import { getUser, getUserId } from '../../Controller/localStorageHandler'



const VisaMock = () => {
    const { type } = useParams() // counselling, visa_mock
    let headerMain = ''
    if (type === 'visa_mock') {
        headerMain = 'Schedule Visa Mock'
    }
    if (type === 'counselling') {
        headerMain = 'Schedule Counselling'
    }
    // console.log(type)
    var data = {
        "university_name": '',
        "university_student_id" : '',
        "passport_number" : '',
        "schedule_date": new Date(),
        "schedule_time": new Date(),
        "visa_date": new Date(),
        "visa_time": new Date(),

    };

    const [inputData, setInputData] = useState(data);

    const [uni_name_err,setuni_name_err] = useState(false);
    const [student_id_err,setstudent_id_err] = useState(false);
    const [passwort_err,setpasswort_err] = useState(false);
    const [openLogin,setOpenLogin] = React.useState(false)

    const onChangeHandler = (name, value) => {
        setInputData({ ...inputData, [name]: value })
    }

    const listInput = () => {
        let String = `${headerMain}`
        String = String + `%0D%0A First Name: ${getUser()?.firstname}`
        String = String + `%0D%0A Last Name: ${getUser()?.lastname}`
        String = String + `%0D%0A Email : ${getUser()?.email}`
        String = String + `%0D%0A Mobile No : ${getUser()?.mobile}`

        String = String + `%0D%0A%0D%0A University Name : ${inputData['university_name']}`
        String = String + `%0D%0A University Student Id : ${inputData['university_student_id']}`
        String = String + `%0D%0A Passport Number : ${inputData['passport_number']}`
        String = String + `%0D%0A Schedule Date : ${moment(inputData['schedule_date']).format('l')}`
        String = String + `%0D%0A Schedule Time : ${moment(inputData['schedule_time']).format('LT')}`
        String = String + `%0D%0A Visa Date : ${moment(inputData['visa_date']).format('l')}`
        String = String + `%0D%0A Visa Time : ${moment(inputData['visa_time']).format('LT')}`

        return String
    }

    const submitForm = () => {
        setuni_name_err(false)
        setstudent_id_err(false)
        setpasswort_err(false)

        if(inputData['university_name'] !== ''){
     
                let desc = "University Name: " + inputData['university_name'] + "\n Student Id: " +inputData['university_student_id']+ "\n Passport Number: " + inputData['passport_number']
                var visaFormData = new FormData()
                visaFormData.append('operation', 'add_activity_to_user')
                visaFormData.append('related_to', getUserId())
                visaFormData.append('description', desc)
                visaFormData.append('schedule_date', inputData['schedule_date'])
                visaFormData.append('schedule_time', inputData['schedule_time'])
                visaFormData.append('header', headerMain)
                visaFormData.append('visa_date', inputData['visa_date'])
                visaFormData.append('visa_time', inputData['visa_time'])

                fetchAddVisaMock(visaFormData).then((res) => {
                    let TempData = `${listInput()}
                        %0D%0A%0D%0A*BRAINSTORM CONSULTING*
                        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
                        %0D%0A iOS: https://apple.co/33ujR4Q`
                    if (res?.status === 'success') {
                        window.location.href = `https://api.whatsapp.com/send?phone=917619492054&text=%0D%0A ${TempData.replaceAll("&", "and")}`
                    }
                })
         
     
    }
    else{
        if(inputData['university_name'] === ''){
            return setuni_name_err(true)
        }
        if(inputData['university_student_id'] === ''){
            return setstudent_id_err(true)
        }
        if(inputData['passport_number'] === ''){
            return setpasswort_err(true)
        }
         
    }


        // return `https://api.whatsapp.com/send?phone=918861216666&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")} `
    }




 



    return (
        <React.Fragment>

            <div className="row m-0 ">

                <div className="col-12 slidingSection p-0">

                    <div className="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample1" style={{ minWidth: "100% !important" }}>


                        <div className="mt-2" style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                            <span></span>
                            <span style={{ marginBottom: 0, color: "white" }}> <Chip label={headerMain} color="warning" /> </span>
                            <span></span>

                        </div>
                        <div style={{ background: "white" }} id="flush-collapseOne-1" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne-1" data-bs-parent="#accordionFlushExample1">
                            <div className="accordion-body">
                                <div className="row m-0">
                                    <div className="col-12 p-0">

                                        <FormControl fullWidth sx={{ mt: 1.5 }}>
                              
                                            <div style={{ width: "100%", display: "flex", marginTop: '15px', justifyContent: "space-between" }}>
                                                <TextField
                                                    error={uni_name_err}
                                                    label="University Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => onChangeHandler("university_name", e.target.value)}
                                                    value={inputData['university_name']}
                                                />

                                            </div>
                                            <div style={{ width: "100%", display: "flex", marginTop: '15px', justifyContent: "space-between" }}>
                                                <TextField
                                                    error={student_id_err}
                                                    label="University Student Id"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => onChangeHandler("university_student_id", e.target.value)}
                                                    value={inputData['university_student_id']}
                                                />

                                            </div>
                                            <div style={{ width: "100%", display: "flex", marginTop: '15px', justifyContent: "space-between" }}>
                                                <TextField
                                                    error={passwort_err}
                                                    label="Passport Number"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => onChangeHandler("passport_number", e.target.value)}
                                                    value={inputData['passport_number']}
                                                />

                                            </div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack>
                                                    <div style={{ width: "100%", display: "flex", marginTop: '15px', justifyContent: "space-between" }}>


                                                        <MobileDatePicker
                                                            label="Schedule Date"
                                                            inputFormat="DD/MM/YYYY"
                                                            onChange={(newValue) => onChangeHandler('schedule_date', newValue[`$d`])}
                                                            value={inputData['schedule_date']}
                                                            fullWidth
                                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                                        />

                                                        <TimePicker
                                                            label="Schedule Time"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={inputData['schedule_time']}
                                                            onChange={(newValue) => onChangeHandler("schedule_time", newValue[`$d`])}
                                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                                        />


                                                    </div>

                                                    {
                                                        type === 'visa_mock'
                                                            ? <div style={{ width: "100%", display: "flex", marginTop: '15px', justifyContent: "space-between" }}>
                                                                <MobileDatePicker
                                                                    label="Visa Date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    onChange={(newValue) => onChangeHandler('visa_date', newValue[`$d`])}
                                                                    value={inputData['visa_date']}
                                                                    fullWidth
                                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                                />
                                                                <TimePicker
                                                                    label="Visa Time"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={inputData['visa_time']}
                                                                    onChange={(newValue) => onChangeHandler("visa_time", newValue[`$d`])}
                                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                                />
                                                            </div>
                                                            : ''
                                                    }

                                                </Stack>
                                            </LocalizationProvider>

                                        </FormControl>
                                        {
                                            getUser()
                                            ?
                                            <button className="submitBtn mt-4" onClick={submitForm} >Submit</button>
                                            :
                                            <button className="submitBtn mt-4" onClick={()=>setOpenLogin(true)} >Submit</button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>



                        <CommonLogin openLogin={openLogin} handleClose={setOpenLogin} return_url="callback" callBack={submitForm} />
                    </div>


                </div>
            </div>
        </React.Fragment>
    )

}

export default VisaMock;