import React from "react";
import { Grid, Typography, LinearProgress, Chip, IconButton, Alert } from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { barColor, getCurrencySymbol } from '../../../Controller/Global';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { getProfileDetails, USER_ID, getUser, getUserType } from "../../../Controller/localStorageHandler";
import { ToastContainer, toast } from 'react-toastify';
import PopUpModal from "../../Result/ApplyModal";
import { useHistory, useLocation } from "react-router-dom";
import FavouriteModal from "../../University/FavouriteModal";
import BookMarkMessageModal from "../../Result/BookMarkMessageModal";
import moment from "moment";
import { FaExternalLinkAlt } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const University = ({ val, setStatus, university, setOpenFav, setBookmarkId, universityName, setOpenApply, setApplyData, programName, semester, Dates, funding_type, bookmark, type, scholarshipDates, setIsBookMark, isApplied }) => {
  
  const location = useLocation();
  // console.log(location.pathname)
  const history = useHistory()
  const ApplyButtonHandler = (e) => {
    e.stopPropagation();
    setApplyData(val)
    setOpenApply(true)
  }


const [open, setOpen] = React.useState(false);
const [open_app, setOpenApp] = React.useState(false);
const [open_deadline,setOpenDeadline] = React.useState(false);
const [open_program_name,setOpenProgramName] = React.useState(false);
const [open_commision,setOpen_commision] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseApp = () =>{
    setOpenApp(false)
  }
  
  const handleOpenApp = () =>{
    setOpenApp(true)
  }

  const handleCloseDeadline = () =>{
    setOpenDeadline(false)
  }

  const handleOpenDeadline = () =>{
    setOpenDeadline(true)
  }
  const handleOpenCommision = () =>{
    setOpen_commision(true)
    console.log('done')
}
const handleCloseCommision = () =>{
    setOpen_commision(false)
}

  return (
    <Grid
      xs={12}
      sx={{

        padding:'6px',
        justifyContent: "space-between",
        background: "#fff",
        mt: 3, mr: 2, borderRadius: '8px', display: "flex",

        boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 20%), 0px 3px 4px 0px rgba(0 0 0 / 14%), 0px 1px 8px 0px rgba(0 0 0 / 12%)",
        minHeight:"160px",
      }}
    >
      <ToastContainer />
      <Grid
        xs={2}
        sx={{
          // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);",

          borderRadius: "15px", position: "relative"

        }}>
        {/* <img
            src={`${process.env.PUBLIC_URL}/img/DashBoard/Trinity.png`}
            alt="trinity"
            style={{ 
              backgroundColor: "#fff",
              padding: "7px",
              height: "80px",
            }}
          /> */}

        <LazyLoadImage
          onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
          src={university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${university}.png` : ''}
          style={{
            backgroundColor: "#fff",
            padding: "7px",
            width: "80px",

            // position: "absolute",
            content: "",
            padding: "4px",
    width: "50px",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    zIndex: "0",
    borderRadius:"8px",
    margin: "5px",
    bordeRadius: "inherit",
    background: "linear-gradient(to right, #0056ff, #45a5ff)",

          }}
          alt='trinity'
          onClick={() => {
            history.push({
              pathname: `/CollegeDetails/${val?.universitiesid}`,
              state: {
                data: val,
              }
            })
          }}
        />

        {
                    val?.associate && val?.associate === '1' ? 
                    <Tooltip
                     title="Partnered University"
                    //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "70px", marginRight: '15px', float: 'left' }}
                     open={open} onClose={handleClose} onOpen={handleOpen}
                      >
                        <Button sx={{
            
                          fontSize:'18px',
                          padding: '0px',
                          background: '#858585',
                          borderRadius: '24px',
                          marginLeft: '12px',
                          minWidth:'32px !important'
                          }} size="small" onClick={()=>setOpen(!open)}>🤝</Button>
                    </Tooltip>
                 
                     : ''
                }
                {
                    getUserType() === 'Associate Admin' 
                    ?
                    <>
                    <br/>
                    <Tooltip
                            title="Commission Amount"
                            open={open_commision} onClose={handleCloseCommision} onOpen={handleOpenCommision}
                        >
                            <Chip size='small' color="error" onClick={()=>setOpen_commision(!open_commision)} label={`${Math.trunc((parseInt(val?.commission_amount) * parseInt(getUser()?.commission))/100)}`} />
                           
                    </Tooltip>
                    </>
                    :
                    ''
                }
                <div style={{display:"block"}} >
                {!val.sop_title && <>
                    {/* <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>{val.funding_type ? val.funding_type : val.semester} <span style={{ color: "red", fontWeight: "bold" }}>  {Date ? moment(Date,'DD-MM-YYYY').format("ll") : ""}</span>
                        <span style={{ float: 'right', marginRight: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                            {getCurrencySymbol(val.country)}
                            {Math.round(val.scho_amt ? val.scho_amt : val.international_tuition_fee_yr)} / yr</span> </Typography>

                    <Typography style={{ position: "absolute", left: "15px", bottom: "-1px", zIndex: "9", fontSize: "12px", color: "#fff", fontWeight: "bold" }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography>
                    <LinearProgress variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? val.success_rate : val.acceptance_rate} style={{ marginTop: '15px', maxWidth: "40%", width: '40%', height: "18px", borderRadius: "50px", float: 'left' }} /> */}
                     <Typography onClick={(e) => ApplyButtonHandler(e)}  style={{ position: "absolute", left: "18px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold",cursor:'pointer' }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography>
                    <CircularProgress onClick={(e) => ApplyButtonHandler(e)} variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? val.success_rate : val.acceptance_rate} style={{ backgroundColor:"#edb28e", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", left: "10px"}} />

                </>}
                </div>
      </Grid>
      <Grid xs={10} sx={{ paddingLeft: "4%",marginLeft:'3%',borderRadius:"8px !important", position: "relative" }} >
        {universityName && <Typography   sx={{ fontWeight: "bold", fontSize: "14px", mt: 1, mr:4,width:'200px', color: "#137fe8",overflowX:'hidden', height:'40px'}}  onClick={() => {

        history.push({
          pathname: `/CollegeDetails/${val?.universitiesid}`,
          state: {
            data: val,
          }
        })
      }}>
          {universityName}

          {type === "scholarship" &&
          <FaExternalLinkAlt style={{color:'#ef5350',marginLeft:'10px',fontSize:'12px'}} onClick={()=>window.location.href=val?.website} />
          }
        </Typography>
        }
        
        <Typography sx={{ fontSize: "13px", mt: 1 }}>
          {funding_type && <Typography style={{ fontSize: "13px", mt: 1, mr:4,whiteSpace:'nowrap',width:'200px',overflow:'hidden',textOverflow:'ellipsis'}}> {funding_type || ''} &nbsp;</Typography>}

          {scholarshipDates && <Typography style={{ color: "red",  fontSize: "13px", mt: 1, mr:4,whiteSpace:'nowrap',width:'200px',overflow:'hidden',textOverflow:'ellipsis' }}>  {scholarshipDates ? moment(scholarshipDates).format('ll') : ""}</Typography>}
          {programName && 
          <Tooltip
          title={programName}
         //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "70px", marginRight: '15px', float: 'left' }}
         open={open_program_name} onClose={()=>setOpenProgramName(!open_program_name)} onOpen={()=>setOpenProgramName(!open_program_name)}
           >
          <Typography onClick={()=>setOpenProgramName(!open_program_name)} style={{ fontSize: "13px", mt: 1, mr:4,whiteSpace:'nowrap',width:'200px',overflow:'hidden',textOverflow:'ellipsis'}}> <span style={{color:'#FF9900'}}>{val?.degree_name || ''}</span> {programName || ''} &nbsp;</Typography>
          </Tooltip>
          }
        </Typography>
        <Typography sx={{ fontSize: "14px", mt: 1 }}>
          {semester && <span style={{ color: "#0d6efd" }}>{semester || ''} &nbsp; </span>}
          {Dates && 
          <Tooltip
          title="Deadline"
         //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "80px", marginRight: '15px', float: 'left' }}
          open={open_deadline} onClose={handleCloseDeadline} onOpen={handleOpenDeadline}
           >
          <span style={{ color: "red", fontWeight: "bold" }} onClick={()=>setOpenDeadline(!open_deadline)}>  ⏰ {Dates ? moment(Dates).format('ll') : ""}</span>
          </Tooltip>
          }
        </Typography>
        


        
{type === "Favourite" &&
              <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              style={{position: "absolute",top: "0px", right: "0px",}}
              // aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={() => setOpenFav(true)}

            >
              {val.bookmark === "1" ?
                <FavoriteIcon
                  style={{ color: 'red' }}
                  onClick={(e) => {
                    // console.log('yes')
                    e.stopPropagation();
                    (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                    setOpenFav(true)
                    setStatus(type)
                    setIsBookMark(true)
                  }
                  }
                /> :
                <FavoriteBorderIcon onClick={(e) => {
                  // console.log('yes')
                  e.stopPropagation();
                  (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                  setOpenFav(true)
                  setStatus(type)
                  setIsBookMark(false)
                }}
                />
              }
            </IconButton>}

              {type === "Matching" &&
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                style={{position: "absolute",top: "0px", right: "0px",}}
                // aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                onClick={() => setOpenFav(true)}

              >
                {val.bookmark === "1" ?
                  <FavoriteIcon
                    style={{ color: 'red' }}
                    onClick={(e) => {
                      // console.log('yes')
                      e.stopPropagation();
                      (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                      setOpenFav(true)
                      setStatus(type)
                      setIsBookMark(true)
                    }
                    }
                  /> :
                  <FavoriteBorderIcon onClick={(e) => {
                    // console.log('yes')
                    e.stopPropagation();
                    (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                    setOpenFav(true)
                    setStatus(type)
                    setIsBookMark(false)
                  }}
                  />
                }
              </IconButton>}



       
        {university &&
          <Typography sx={{ fontSize: "14px", mt: 1, display: "flex", justifyContent: "left", paddingBottom: 0 }}>
            {/* <img src={`${process.env.PUBLIC_URL}/img/Mentor/start.png`} alt='star' /> 50 Reviews */}
            
              <div style={{display:'flex'}}>
              
                {
                    val?.app_fee_waiver && val?.app_fee_waiver === '1' ?
                    <Tooltip
                     title="Application Fee Waiver"
                    //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "80px", marginRight: '15px', float: 'left' }}
                     open={open_app} onClose={handleCloseApp} onOpen={handleOpenApp}
                      >
                        <Button size="small" sx={{textDecoration:'line-through',minHeight: 0, minWidth: 0, padding: 0, paddingRight:1,paddingLeft:0}} onClick={()=>setOpenApp(!open)} >
                          {getCurrencySymbol(val.country)}{val?.international_app_fee}</Button>
                    </Tooltip>
                    : 
                    <Tooltip
                     title="Application Fee"
                     sx={{  color: '#1B6CFC' }}
                     open={open_app} onClose={handleCloseApp} onOpen={handleOpenApp}
                      >
                        <Button size="small" sx={{minHeight: 0, minWidth: 0, padding: 0, paddingRight:1,paddingLeft:0}} onClick={()=>setOpenApp(!open)}>{getCurrencySymbol(val.country)}{val?.international_app_fee}</Button>
                    </Tooltip>
                }
                </div>

            
            
            
            <div
            style={{
              display: "flex",
              float: "right",
              border: `${isApplied ? '3px solid #239e4c' : '3px solid #1B6CFC'}`,
              borderRadius: "16px 16px 16px 16px",
              // marginRight: "12px",
              flexDirection: "row",
              position:"absolute",
              bottom:"0px",
              right:"0px"
          }}        
            >

{true &&
              <Typography 
               sx={{    alignSelf: "center", margin: "0px 8px",color: `${isApplied ? '#1B6CFC' : '#1B6CFC'}` }}
              >
                <span 
                style={{ fontWeight:"bold", }}
                >
                  {getCurrencySymbol(val?.country)}{Math.round(val?.scho_amt ? val?.scho_amt : val?.international_tuition_fee_yr || 0)}
                  /yr
                </span></Typography>
            }

            {/* {type !== "Matching" &&
              <Typography sx={{ mt: '5px', fontSize: '14px', display: 'inline-block', width: '100%' }}>
                <span style={{ float: funding_type ? "left" : 'right', marginRight: '50px', fontSize: '14px', color: "rgba(27, 108, 252)", }}>
                  {getCurrencySymbol(val?.country)}{Math.round(val?.scho_amt ? val?.scho_amt : val?.international_tuition_fee_yr || 0)}/yr
                </span></Typography>
            } */}

{type === 'Matching' || type === 'Favourite' ?
              setOpenApply ? 
              
              isApplied ? <Chip
                label="Applied"
                sx={{ borderRadius: '8px !important',bgcolor: '#239e4c', color: '#fff', marginRight: '0px', float: 'left' }}
                // onClick={(e) => ApplyButtonHandler(e)}
              />
              :
              <Chip
                label="Apply"
                sx={{ borderRadius: '8px !important',bgcolor: '#1B6CFC', color: '#fff', marginRight: '0px', float: 'left' }}
                onClick={(e) => ApplyButtonHandler(e)}
              />
               :
                <>
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                    sx={{ marginRight: '75px', position: 'absolute', bottom: "-6px", right: '0' }}

                  >
                    <ArrowDownwardIcon
                    // onClick={(e)=>{
                    //     e.stopPropagation();
                    //     setBookmarkId(universitiesid)
                    //     setOpenFav(true)}
                    // }
                    />

                  </IconButton>
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                    sx={{ marginRight: '45px', position: 'absolute', bottom: "-6px", right: '0' }}

                  >
                    <RemoveRedEyeIcon
                    // onClick={(e)=>{
                    //     e.stopPropagation();
                    //     setBookmarkId(universitiesid)
                    //     setOpenFav(true)}
                    // }
                    />

                  </IconButton>
                </>
              : ''
            }
            </div>


          </Typography>
        }

        {type === "scholarship" &&
        <>
        {
        location.pathname === "/CollegeDetails/" 
        ?
        ''
        :
          <IconButton
            size="small"
            edge="end"
            aria-label="account of current user"
            // aria-controls={menuId}
            aria-haspopup="true"
            color="inherit"
            onClick={() => setOpenFav(true)}

          >
            {val.bookmark === "1" ?
              <FavoriteIcon
                style={{ color: 'red' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setBookmarkId(val?.scholarshipid)
                  setOpenFav(true)
                  setStatus(type)
                }
                }
              /> :
              <FavoriteBorderIcon onClick={(e) => {
                e.stopPropagation();
                setBookmarkId(val?.scholarshipid)
                setOpenFav(true)
                setStatus(type)
              }}
              />
            }
          </IconButton>
        }
          </>
          }
        {(type === "scholarship") ?

          <Typography sx={{ borderRadius: '0px !important', mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>

            <span style={{ float: 'right', marginRight: '50px', fontWeight: 'bold', fontSize: '16px', color: "rgba(27, 108, 252)", }}>
              {val?.country.includes('|##|') ? '$' : getCurrencySymbol(val?.country)}{Math.round(val?.scho_amt ? val?.scho_amt : val?.international_tuition_fee_yr || 0)}/yr
            </span></Typography> : ""
        }
      </Grid>
      {/* <Grid xs={1}
        style={{ display: "flex", alignItems: "center" }}
        sx={{
       borderRadius: "15px",
        }}>
 </Grid> */}
    </Grid>
  );
};
export default University;
