import React, { useEffect, useState } from 'react'
import Header from './Header'
import Card from './Card'
import { getSessionId, getUserId } from '../../../Controller/localStorageHandler'
import { fetchApplyIntenshipListAction } from '../../Action'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import PageTitle from '../../PageTitle'

const ApplyIntenship = () => {
    const history = useHistory()
    const classes = useStyles();

    const [ApplyIntenshipData, setApplyIntenshipData] = useState([])

    useEffect(() => {
        var data = new FormData();
        data.append('userid', getUserId());
        data.append('sessionid', getSessionId());
        data.append('operation', 'get_internship_details');

        fetchApplyIntenshipListAction(data).then((response) => {
            setApplyIntenshipData(response?.data || [])
        }).catch((error) => {
            setApplyIntenshipData([])
        })
    }, [])

    return (
        <div>
            <div className='global-container' style={{ padding: 0 }}>
                <Header />
                <div>
                    <div className={classes.container}>
                        <PageTitle pageTitle={`Intenships`} bold />
                        <img style={{ marginTop: '10px' }} src={`${process.env.PUBLIC_URL}/img/sort.png`} alt="" />
                    </div>
                </div>
                <div className="d-flex  justify-content-end">
                    <button
                        onClick={() => history.push('/addInternshipDetails')}
                        className="transiction_btn"
                        style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}
                    >
                        + Add Internship
                    </button>
                </div>
                <Tabs style={{ marginTop: 10 }}>
                    <TabList style={{ paddingLeft: 0, marginLeft: 0, justifyContent: 'start' }} className="tab_class tab_class_fav">
                        <Tab className="dashboard_category">Active </Tab>
                        <Tab className="dashboard_category">Inactive </Tab>
                        <Tab className="dashboard_category">Draft </Tab>
                        <Tab className="dashboard_category">Closed </Tab>
                    </TabList>
                    <TabPanel>
                        {
                            ApplyIntenshipData.length > 0 && ApplyIntenshipData.map((d) => {
                                return <Card data={d} />
                            })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            ApplyIntenshipData.length > 0 && ApplyIntenshipData.map((d) => {
                                return <Card data={d} />
                            })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            ApplyIntenshipData.length > 0 && ApplyIntenshipData.map((d) => {
                                return <Card data={d} />
                            })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            ApplyIntenshipData.length > 0 && ApplyIntenshipData.map((d) => {
                                return <Card data={d} />
                            })
                        }
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default ApplyIntenship


const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',

    },

    img: {
        display: 'flex',
        justifyContent: 'center'
    },

}));
