import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import "./commonType.css";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Duaration = ({ HandlePrev, HandleNext }) => {
    const [expanded2, setExpanded2] = useState(true);


    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    return (
        <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample1" style={{ minWidth: "100% !important" }}>
            <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                <span style={{ color: "white" }} onClick={() => HandlePrev()}>     Prev</span>
                <span style={{ marginBottom: 0, color: "white" }}>  Internship duration </span>
                <span style={{ color: "white" }} onClick={() => HandleNext()}> Next</span>
            </div>
            <div className="">
                <p>Shorter the duration, more the applications</p>
            </div>

            <div className="collapse_check_item d-flex justify-content-center align-items-center">
                <p className="mt-3">Choose duration</p>
                <ExpandMore
                    expand={expanded2}
                    onClick={handleExpandClick2}
                    aria-expanded={expanded2}
                    aria-label="show more"
                >
                    <ExpandMoreIcon style={{ color: "#000" }} />
                </ExpandMore>
                <Collapse className="" in={expanded2} timeout="auto" unmountOnExit>
                </Collapse>
            </div>

            <div className="collapse_check_item d-flex justify-content-center align-items-center mt-3">
                <p className="mt-3">Choose months</p>
                <ExpandMore
                    expand={expanded2}
                    onClick={handleExpandClick2}
                    aria-expanded={expanded2}
                    aria-label="show more"
                >
                    <ExpandMoreIcon style={{ color: "#000" }} />
                </ExpandMore>
                <Collapse className="" in={expanded2} timeout="auto" unmountOnExit>
                </Collapse>
            </div>
        </div>
    );
};

export default Duaration;
