import { Typography, Grid, Chip } from '@mui/material'
import React from 'react'
import SecondCard from './ScondCard'
import { FcGenericSortingDesc } from "react-icons/fc"
import "./card.css"


const MentorCard = () => {
    // const classes = useStyles()
    return (
        <>
            <Grid xs={11} sx={{ margin: "auto" }}>
                <Grid sx={{ display: "flex", mt: 2 }}>
                <span style={{margin:'auto',width:'auto', display:'flex', alignItems:'center',justifyContent:'center'}}>
                <Chip color="success" label="Top Mentors for you" />
                </span>
                
                </Grid>
                <SecondCard />
            </Grid>
        </>
    )
}
export default MentorCard

