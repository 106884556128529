import React, { useEffect } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {getUser} from "../../Controller/localStorageHandler"
import axios from "axios";

const PossibleDashform = (props) => {
  const {setcountryValue,setprogramValue,handleChange, yearValue,semesterValue, setyearValue, setsemesterValue,
    values,countryValue,programValue} = props;
  const [countryList, setCountryList] = React.useState('');
  const [programList, setProgramList] = React.useState('');
  const [yearList, setYearList] = React.useState([]);
  const [semesterList, setSemesterList] = React.useState([]);
  
  useEffect(()=>{
    async function syncData() {
      const params = new URLSearchParams();
      params.append('operation', 'fetchPicklist');
      params.append('name', 'related_programs');
      const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
          , {
              'Access-Control-Allow-Origin': '*',
          });
  
      params.append('name', 'country');
      const countryRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);
  
      params.append('name', 'semester');
      const semesterRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);
  
      params.append('name', 'year');
      const yearRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);
  
      setProgramList(response.data.data);
      setCountryList(countryRes.data.data);
      setSemesterList(semesterRes.data.data);
      setYearList(yearRes.data.data);
    }
    syncData();
}, []);
  
  return (
      <Grid
        xs={12}
        sx={{
          p: 3,
          paddingBottom: "80px",
          backgroundColor: "#fff",
          mt: 3,
          pb:2,
          borderRadius: "10px",
          boxShadow: 3,
        }}
      >
        <Typography > Looking for </Typography>
        <FormControl fullWidth sx={{ mt:2}}>
          <InputLabel>Degree</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // defaultValue={getUser().degree_looking_for}
            value={values.degree_looking_for}
            label="Degree"
            name="degree_looking_for"
            onChange={(e)=>handleChange(e)}
          >
            <MenuItem value="Bachelors">Bachelors</MenuItem>
            <MenuItem value="Masters">Masters</MenuItem>
            <MenuItem value="Diploma">Diploma</MenuItem>
            <MenuItem value="Phd">Phd</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt:1.5}}>
          <Autocomplete
            name='country'
            freeSolo
            multiple
            value={countryValue}
            onChange={(e,val)=>setcountryValue(val)}
            options={countryList&&countryList.map((option) => option)}
            renderInput={(params) => <TextField {...params} label="Select Country" />}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt:1.5}}>
          <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    multiple
                    name="related_programs"
                    defaultValue={programValue}
                    onChange={(e,val)=>setprogramValue(val)}
                    options={programList && programList.map((option) => option)}
                    renderInput={(params) => <TextField {...params} label="Select Program" />}
                />
        </FormControl>
        <Grid sx={{ display:"flex", justifyContent:"space-between"}}> 
        <Grid xs={5.8}>
        <FormControl fullWidth sx={{ mt:1.5}}>
          {/* <InputLabel>Semester</InputLabel> */}
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={semesterValue}
            label="Semester"
            name="semester_looking_for"
            onChange={(e)=>setsemesterValue(e.target.value)}
          >
            {semesterList.length>0 && semesterList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
          </Select> */}
          <Autocomplete
              name='semester_looking_for'
              id="free-solo-demo"
              freeSolo
              // multiples
              value={semesterValue}
              onChange={(e,val)=>setsemesterValue(val)}
              options={semesterList && semesterList.map((option) => option)}
              renderInput={(params) => <TextField {...params} label="Select Semester"/>}
          />
        </FormControl>
        </Grid>
        <Grid xs={5.8}>
        <FormControl fullWidth sx={{ mt:1.5}}>
          {/* <InputLabel>Year</InputLabel> */}
          {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={yearValue}
              label="Year"
              name="year_looking_for"
              onChange={(e)=>setyearValue(e.target.value)}
          >
              {yearList.length>0 && yearList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
          </Select> */}
          <Autocomplete
              name='year_looking_for'
              id="free-solo-demo"
              freeSolo
              // multiples
              value={yearValue}
              onChange={(e,val)=>setyearValue(val)}
              options={yearList && yearList.map((option) => option)}
              renderInput={(params) => <TextField {...params} label="Select Year"/>}
          />
        </FormControl>
        </Grid>
        </Grid>
      </Grid>
  );
};
export default PossibleDashform;