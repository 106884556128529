import React from 'react'
import {useHistory, Link} from 'react-router-dom'
import { clearUserData } from './Controller/localStorageHandler';

const ErrorComponent = () => {
  let history = useHistory()
    // clearUserData()
    // return history.push('/')
    return <div className="error-div" style={{textAlign:'center'}}>
      <h1>Something went wrong</h1>
      <a href="/" style={{padding:'10px', backgroundColor:'#1976d2', color:'#fff'}}>Go To Homepage</a>
      </div>;
};

export default class ErrorBoundary extends React.Component {
    state = {
      hasError: false,
      error: { message: "", stack: "" },
      info: { componentStack: "" }
    };
  
    static getDerivedStateFromError = error => {
        console.log(error,'error')
      return { hasError: true };
    };
  
    componentDidCatch = (error, info) => {
      this.setState({ error, info });
    };
  
    render() {
      const { hasError, } = this.state;
      const { children } = this.props;
  
      return hasError ?<>{console.log(hasError,'has error')}<ErrorComponent /> </>: children;
    }
  }