import { makeStyles } from '@mui/styles'
import React from 'react'

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import Typography from '@mui/material/Typography';




const Card = ({ Data }) => {
    

    const classes = useStyles();
    return (
        <div className={classes.container} style={{ margin: 0, borderRadius: 5, padding: 10, background: "#fff" }}>
            <div className={classes.flex}>
            <div style={{ width: "13%", display: "flex", alignItems: "center" }}>
                {/* <Email style={{ fontSize: 30 }} /> */}
                {/* <MarkEmailUnreadOutlinedIcon style={{ fontSize: 20, color:'red' }} /> */}

            {Data.reminderstatus === "Open" ? 
            <VisibilityOffOutlinedIcon style={{ fontSize: 20, color:'red' }} />
            :
            <RemoveRedEyeOutlinedIcon style={{ fontSize: 20, color:'green'}} />
            }
                
            </div>
            {/* <img className={classes.imgStyle} src={`${process.env.PUBLIC_URL}/img/bitmoji.png`} alt='bitmoji' /> */}
            <div className={classes.content}>
                <div>
                <p className={classes.para}>{Data?.university}</p>
                    
                <main className={classes.title}>{Data?.subject || ''}</main>
                    
                </div>
                {/* <p className={classes.time}>{Data?.cdate || ''}</p> */}
                
            </div>
            </div>
            <Typography className={classes.timeRight}>
            <p className={classes.time}>{Data?.cdate || ''}</p>
            </Typography>
        </div>
    )
}

export default Card

const useStyles = makeStyles({
    container: {
        // border: '1px solid #DCDCDC',
        borderRadius: '1px',
        boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)",
        // display: 'flex',
        margin: '10px',
        padding: '5px'
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: '1%',
        marginTop: '1%'
    },
    imgStyle: {
        height: '60px',
        width: '60px',
    },
    title: {
        // fontWeight: '600'
        fontSize:'12px'
    },
    para: {
        marginTop: '4px',
        fontSize:'13px'
    },
    time: {
        // display: 'flex',
        // justifyContent: 'end',
        fontSize:'10px',
        fontStyle:'italic',
    },
    timeRight:{
        textAlign:'right',
        
    },
    flex:{
        display: 'flex',
    }
});