import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getUser, USER_ID, SESSION_ID, getUserType, getUserId, getProfileDetails } from '../../Controller/localStorageHandler';
import moment from 'moment';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Chip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { fetchAddStudentListAction, fetchUnivercityApplyAction } from '../Action';
import Snackbar from '@mui/material/Snackbar';
import { useHistory, useLocation } from 'react-router-dom';

import useAppContext from "../../useContext/userContext"
import { CommonLogin } from '../Forms/CommonLogin';

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    pt: 1,
    pb: 1,
    px: 0,
};

const PopUpModal = (props) => {
    const history = useHistory()
    const location = useLocation();

    const { open, handleClose, data, studentData, setOpenMsg, setBookmarkMsg,selectedUniverSity } = props
    const {getApplicationStatus} = useAppContext()

    const [openLogin,setOpenLogin] = React.useState(false)

    // console.log(getUserType())
    // const handleClose = () => setOpen(false);
    const [successModal, setsuccessModal] = React.useState(false)
    const [successMsg, setsuccessMsg] = React.useState('')
    var date1 = new Date();
    var date2 = new Date(data.documents_deadline);

    function createData(requirement, profile, uni_req, approved) {
        return { requirement, profile, uni_req, approved };
    }
    let UserDatas = studentData ? studentData : getUser()
    const rows = [
        createData(
            'Bachelors% (0-100)',
            UserDatas?.bachelors_percentage ? UserDatas?.bachelors_percentage : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35', }} />,
            60,
            // <img alt={''} src={`${process.env.PUBLIC_URL}/img/check-icon-green.svg`}/>
            UserDatas?.bachelors_percentage >= 60 ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
        ),
        createData(
            'Backlogs Accepted',
            UserDatas?.number_backlogs ? UserDatas?.number_backlogs : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
            data?.num_backlogs ? data?.num_backlogs : 'null',
            data?.num_backlogs >= UserDatas?.number_backlogs ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />

        ),
        createData(
            '12th/PUC/Inter %',
            UserDatas?.puc_percentage_12th ? UserDatas?.puc_percentage_12th : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
            data?.uni_puc_inter_12 ? data?.uni_puc_inter_12 : 'null',
            data?.uni_puc_inter_12 <= UserDatas?.puc_percentage_12th ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />

        ),
        createData(
            '12th/PUC/Inter English%',
            UserDatas?.english_percentage_12th ? UserDatas?.english_percentage_12th : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
            data?.uni_english ? data?.uni_english : 'null',
            data?.uni_english <= UserDatas?.english_percentage_12th ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
        ),
        createData(
            'IELTS',
            UserDatas?.ielts_total_1st_attempt ? UserDatas?.ielts_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
            data?.ielts ? data?.ielts : 'null',
            data?.ielts <= UserDatas?.ielts_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
        ),
        createData(
            'TOEFL',
            UserDatas?.toefl_total_1st_attempt ? UserDatas?.toefl_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px' }} />,
            data?.toefl ? data.toefl : 'null',
            parseInt(data?.toefl) <= parseInt(UserDatas?.toefl_total_1st_attempt) ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
        ),
        createData(
            'PTE',
            UserDatas?.pte_total_1st_attempt ? UserDatas?.pte_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px' }} />,
            data?.pte ? data?.pte : 'null',
            data?.pte <= UserDatas?.pte_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
        ),
        createData(
            'Duolingo',
            UserDatas?.duolingo_total_1st_attempt ? UserDatas?.duolingo_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px' }} />,
            data?.duolingo ? data?.duolingo : 'null',
            data?.duolingo <= UserDatas?.duolingo_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
        ),
        data?.gre_total !== 'Not Applicable' ?
            createData(
                'GRE',
                UserDatas?.gre_total_1st_attempt ? UserDatas?.gre_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px' }} />,
                data?.gre_total ? data?.gre_total : 'null',
                data?.gre_total <= UserDatas?.gre_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
            )
            : '',
        data?.gmat_total !== 'Not Applicable' ?
            createData(
                'GMAT',
                UserDatas?.gmat_total_1st_attempt ? UserDatas?.gmat_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
                data?.gmat_total ? data?.gmat_total : 'null',
                data?.gmat_total <= UserDatas?.gmat_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
            )
            : '',
        data?.act_score !== 'Not Applicable' ?
            createData(
                'ACT',
                UserDatas?.act_total_1st_attempt ? UserDatas?.act_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
                data?.act_score ? data?.act_score : 'null',
                data?.act_score <= UserDatas?.act_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
            )
            : '',
        data?.sat_total !== 'Not Applicable' ?
            createData(
                'SAT',
                UserDatas?.sat_total_1st_attempt ? UserDatas?.sat_total_1st_attempt : <Chip label="Update" sx={{ bgcolor: '#F6C69E', fontSize: '10px', height: '15px', color: 'EA6D35' }} />,
                data?.sat_total ? data?.sat_total : 'null',
                data?.sat_total <= UserDatas?.sat_total_1st_attempt ? <CheckCircleIcon style={{ color: 'Green' }} /> : <CheckCircleIcon style={{ color: 'Red' }} />
            )
            : '',

    ];

    const applyUniversity = () => {
        let ProfileData = studentData ? studentData : getUser()
        const { data } = props
        // console.log(data)
        // let ProfileData = UserDatas
        var Data = JSON.stringify({
            "contactlist": [ProfileData?.contactid || getUserId()],
            "university": data.universitiesid,
            "contact_no": ProfileData.contact_no,
            "bypass": "validation"
        });

        fetchUnivercityApplyAction(Data).then((d) => {
            // setsuccessModal(true)
            // setsuccessMsg(d.message)
            getApplicationStatus('All',true)
            handleClose()
            setsuccessModal(true)
            setsuccessMsg(`Successfully Applied for ${data?.university}`)
        }).catch((error) => {
            handleClose()
            toast.error('Oops something went wrong!')
        })

        // const apply = new FormData()
        // apply.append('contactlist',getUserId())
        // apply.append('university',data.universitiesid)
        // apply.append('contact_no',UserDatas.contact_no)
        // // apply.append('userid', 270453)
        // // apply.append('sessionid', '4a1lpslt')
        // axios.post("https://erp.bscglobaledu.com/mapi/invoice/ajax_add_studentuniversity",apply, {
        // headers: {  
        //                 'sessionid':getSessionId(),
        //                 'userid':getUserId()
        //             }
        //     }).then(res => {
        //         console.log(res)
        //     }).catch(err => {
        //         console.log(err)
        //     })
        // const option = {
        //     method: "post",
        //     url: "https://erp.bscglobaledu.com/mapi/invoice/ajax_add_studentuniversity",
        //     data: apply,
        //     headers: { 
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'sessionid':`${getSessionId()}`,
        //         'userid':`${getUserId()}`
        //     },
        //   }
        // axios(option)
        // .then((response) => {
        //     console.log(response.data)
        // })
    }


    const applyUniversityForAssociate = () => {
        const { data } = props
        // console.log(data)
        // console.log("props.SelectedStudentList", props.SelectedStudentList)
        let ProfileData = UserDatas
        var Data = JSON.stringify({
            "contactlist": [
                ...props.SelectedStudentList
            ],
            "university": data.universitiesid,
            "contact_no": ProfileData.contact_no,
            "bypass": "validation"
        });
        fetchAddStudentListAction(Data).then((d) => {
            getApplicationStatus('All',true)
            setsuccessModal(true)
            setsuccessMsg(`Successfully Applied for ${data?.university}`)
            handleClose()
        }).catch((error) => {
            handleClose()
            toast.error('Oops something went wrong!')
        })


    }

    const momentDate = () => {
        let eventDate = moment(data?.inter_adm_deadline)
        let todayDate = moment()

        // return new moment().to(moment(data?.inter_adm_deadline))
        return eventDate.diff(todayDate,'days')
    }
    return (
        <div>
            {/* <Button
                onClick={handleOpen}
                sx={{
                    border: '1px solid #000000',
                    color: '#ffffff',
                    backgroundColor: '#000000',
                    fontWeight: 'bold',
                    padding: '6px 20px'
                }} type={'primary'}>Open Popup</Button> */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={successModal}
                message={successMsg}
                autoHideDuration={4000}
                onClose={()=>setsuccessModal(false)}
            >
            <Alert onClose={()=>setsuccessModal(false)} severity="success" sx={{ width: '100%' }}>
                {successMsg}
            </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        sx={{
                            color: '#1B6CFC',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            lineHeight: '30px',
                            px: 2
                        }}
                    >
                       <Chip label={`Applying for ${UserDatas?.firstname} ${UserDatas?.lastname}`} color="error" />
                    </Typography>
                    <Typography
                        sx={{
                            color: '#333333',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                            mb: 1,
                            px: 2
                        }}
                    >
                        {data?.university}, {data?.city}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        my: 1,
                        px: 2
                    }}>
                        <Typography sx={{
                            paddingRight: '20px',
                            color: '#1B6CFC',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                        }}>{data?.program}</Typography>
                        {/* <Typography sx={{
                            paddingRight: '20px',
                            color: '#1B6CFC',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                        }}></Typography> */}
                        {/* <Typography sx={{
                            paddingRight: '20px',
                            color: '#1B6CFC',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                        }}>{data.semester}</Typography> */}
                        {/* <Typography sx={{
                            paddingRight: '20px',
                            color: '#1B6CFC',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            lineHeight: '20px',
                        }}>System</Typography> */}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1,
                        borderBottom: ' 1px solid #CED0D5',
                        paddingBottom: '10px',
                        px: 2
                    }}>
                        <Typography sx={{
                            paddingRight: '0',
                            color: '#1A1A1A',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '20px',
                        }}>Application fee: ${data?.international_app_fee}</Typography>
                        <Typography sx={{
                            paddingRight: '0',
                            color: '#1A1A1A',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '20px',
                        }}>Acceptance Rate: {Math.round(data?.acceptance_rate)}%</Typography>
                    </Box>
                    <TableContainer
                        sx={{
                            overflow: 'auto',
                            maxHeight: '380px',
                        }}
                        className={"custom-table"} component={Paper}>
                        <Table sx={{ minWidth: 375 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            color: '#1A1A1A',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '17px',
                                            padding: '8px',
                                            borderBottom: '0',
                                        }}
                                    >Requirement</TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#1A1A1A',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '17px',
                                            padding: '8px',
                                            borderBottom: '0',
                                        }}
                                    >Your Profile</TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#1A1A1A',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '17px',
                                            padding: '8px',
                                            borderBottom: '0',
                                        }}
                                    >University Requirement</TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#1A1A1A',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '17px',
                                            padding: '8px',
                                            borderBottom: '0',
                                        }}
                                    >Approved</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((row,i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell
                                            sx={{
                                                color: '#666666',
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                padding: '8px',
                                                borderBottom: '0',
                                            }}
                                            component="th" scope="row">
                                            {row?.requirement}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: '#666666',
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                padding: '8px',
                                                borderBottom: '0',
                                            }}
                                        >{row?.profile}</TableCell>
                                        <TableCell
                                            sx={{
                                                color: '#666666',
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                padding: '8px',
                                                borderBottom: '0',
                                            }}
                                        >{row?.uni_req}</TableCell>
                                        <TableCell
                                            sx={{
                                                color: '#666666',
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                padding: '8px',
                                                borderBottom: '0',
                                            }}
                                        >{row?.approved}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            color: '#333333',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '15px',
                                            padding: '8px',
                                            borderBottom: '0',
                                            borderTop: '1px solid #CED0D5',
                                        }}
                                    >Deadline</TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#333333',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '15px',
                                            padding: '8px',
                                            borderBottom: '0',
                                            borderTop: '1px solid #CED0D5',
                                        }}
                                    >{moment().format('DD.MM.YYYY')}</TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#333333',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '15px',
                                            padding: '8px',
                                            borderBottom: '0',
                                            borderTop: '1px solid #CED0D5',
                                        }}
                                    >{data?.inter_adm_deadline ? data?.inter_adm_deadline : 'null'}</TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#333333',
                                            fontWeight: 'bold',
                                            fontSize: '11px',
                                            lineHeight: '15px',
                                            padding: '8px',
                                            borderBottom: '0',
                                            borderTop: '1px solid #CED0D5',
                                        }}
                                    >{data?.inter_adm_deadline ? momentDate()  : '0'} days left</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        mt: 1,
                        textAlign: 'center'
                    }}>
                        {
                        getUserId() ?
                        props?.SelectedStudentList?.length > 0 ?
                            <>
                                <Button
                                    sx={{
                                        color: '#ffffff',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        lineHeight: '20px',
                                        backgroundColor: '#1B6CFC',
                                        borderRadius: '42px',
                                        padding: '15px 50px'
                                    }}
                                    onClick={() => {
                                        props.handleClose()
                                    }
                                    }
                                >Back</Button>
                                <Button
                                    sx={{
                                        color: '#ffffff',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        lineHeight: '20px',
                                        backgroundColor: '#1B6CFC',
                                        borderRadius: '42px',
                                        padding: '15px 50px'
                                    }}
                                    onClick={() => {
                                        applyUniversityForAssociate()
                                    }
                                    }
                                >Add</Button>
                            </>
                            :
                            getUserType() === "Employee"
                            ?
                            moment(data?.inter_adm_deadline).format('YYYY.MM.DD') >=  moment().format('YYYY.MM.DD') 
                            ?
                            <Button
                                sx={{
                                    color: '#ffffff',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    lineHeight: '20px',
                                    backgroundColor: '#1B6CFC',
                                    borderRadius: '42px',
                                    padding: '15px 50px'
                                }}
                                onClick={() => {
                                    applyUniversity()
                                }
                                }
                            >Apply</Button>
                            :
                            <Button
                                sx={{
                                    color: '#ffffff',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    lineHeight: '20px',
                                    backgroundColor: '#eb4034',
                                    borderRadius: '42px',
                                    padding: '15px 50px'
                                }}>Deadline Over</Button>
                            :
                            <Button
                                sx={{
                                    color: '#ffffff',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    lineHeight: '20px',
                                    backgroundColor: '#1B6CFC',
                                    borderRadius: '42px',
                                    padding: '15px 50px'
                                }}
                                onClick={() => {
                                    applyUniversity()
                                }
                                }
                            >Apply</Button>
                            :
                            <Button
                                sx={{
                                    color: '#ffffff',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    lineHeight: '20px',
                                    backgroundColor: '#eb4034',
                                    borderRadius: '42px',
                                    padding: '15px 50px'
                                }}
                                onClick={(e) => {
                                    setOpenLogin(true)
                                }}>Login To Apply</Button>
                        }
                    </Box>
                </Box>
            </Modal>
            <CommonLogin openLogin={openLogin} handleClose={setOpenLogin} return_url="callback" callBack={applyUniversity} />
        </div>
    );
}
export default PopUpModal;
