import React from 'react';
import { Grid } from "@mui/material";
import { RiSearchLine } from "react-icons/ri";
import Filter from "../../static/img/Mentor/Filter.png"

function SearchBoxWithFilter() {
    return (
        <div>
            <Grid>
                <Grid sx={{  display: "flex", justifyContent: "space-between", width: "100%", alignItems:"center" }} >
                    <main style={{backgroundColor: '#FFF'}} className="input_container">
                        <RiSearchLine style={{ fontSize: '30px' }} />
                        <input autoFocus placeholder='Search whatsapp group' />
                    </main>
                    <div className="arrow_icon">
                        <img src={Filter} alt="" />
                    </div>
                </Grid>
            </Grid>
      </div>
    )
}

export default SearchBoxWithFilter;