import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
// import downArrow from './../../../icons/downArrow.svg'
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import "./commonType.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Type = ({ HandleNext, HandlePrev }) => {


  return (
    <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample1" style={{ minWidth: "100% !important" }}>
      <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
        <span style={{ color: "white" }} onClick={() => HandlePrev()}>     Prev</span>
        <span style={{ marginBottom: 0, color: "white" }}>  Internship Type </span>
        <span style={{ color: "white" }} onClick={() => HandleNext()}> Next</span>
      </div>
      <div className="accordion-collapse collapse show">
        <div className="collapse_check_item">
          <FormControl component="fieldset" className="radio_font">
            <FormLabel component="legend">Profile</FormLabel>
            <RadioGroup
              aria-label="gender"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <div className="d-flex justify-content-start align-items-center">
                <input
                  className="radio_style"
                  type="radio"
                  name="selector"
                  id=""
                />
                <label className="details_tag_size" htmlFor="">
                  Regular (In-office/On-field)
                </label>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <div>
                  <input
                    className="radio_style"
                    type="radio"
                    name="selector"
                    id=""
                  />
                </div>
                <label className="details_tag_size" htmlFor="">
                  Work from home (Recommended due to COVID-19 situation)
                </label>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <div>
                  <input
                    className="radio_style"
                    type="radio"
                    name="selector"
                    id=""
                  />
                </div>

                <label className="details_tag_size" htmlFor="">
                  I am open to interviewing candidates online and allowing them
                  to work from home or defer the joining date till current
                  COVID-19 situation improves
                </label>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default Type;
