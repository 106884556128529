import React, { useEffect, useState } from "react";
// import PageTitle from "../../PageTitle";
// import EventsNav from "./EventsNav";
import ResultCard from "./ResultCard";
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import { Grid } from "@mui/material";
import { fetchEventListAction } from '../../Action/index'
import { getSessionId, getUserId,getUser } from "../../../Controller/localStorageHandler";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import FlightIcon from '@mui/icons-material/Flight';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));

const UniversityProspectives = () => {
    const history = useHistory()
    const classes = useStyles();
    const currentUser = getUser();

    const [searchList, setSearchList] = useState([])
    const [loading, setLoading] = useState(false)
    const [bookmark_count, setBookmark_Count]  = useState([]);
    const [enrolled,setEnrolled] = useState([]);
    const [admitted,setAdmitted] = useState([]);
    const [applied,setApplied] = useState([]);

    const TotalRecors = 10

    useEffect(() => {
        setLoading(true)
        const formData = new FormData()
        formData.append('userid', getUserId())
        formData.append('sessionid', getSessionId())
        formData.append('operation', 'university_program')
        formData.append('studentid', getUserId())
        formData.append('email',currentUser?.email)

        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            if (response.data.status === 'success') {
                setBookmark_Count(response.data.bookmark)
                setEnrolled(response.data.enrolled)
                setAdmitted(response.data.admitted)
                setApplied(response.data.applied)
                setSearchList(response.data.data)
                setLoading(false)
            } else {
                alert(response.data.msg || response.data.message)
            }
        })

    }, [])

    const [toggle, setToggle] = useState({
        forYou: false,
        trending: false,
        upcoming: false,
    });

    return (
        <Box className={classes.root} sx={{ bgcolor: '#fafafa' }}>
            
            <Box sx={{ marginLeft: 0, marginRight: 0, borderRadius: 2 }}>
                <Grid container spacing={1}>
       
                        <Grid item xs={10} sx={{ mt: 1, fontWeight: 'bold' }}>
                            <span style={{ color: '#1B6CFC', marginRight: 5, fontWeight: "bold" }}>Universities & Programs</span>
                            {searchList ? searchList.length : ''}
                            {/* {`${screenName(props?.location?.state?.screen || props?.location?.state?.screenName)} ${tabName} in ${programValue} in ${countryValue}`} */}
                        </Grid>
   
                    

                </Grid>
                <div style={{marginTop:'10px',fontSize: '14px',textAlign:'center',overflow:'auto',whiteSpace:'nowrap'}}>
     
                <Chip sx={{marginRight:'5px'}} icon={<FavoriteBorderIcon style={{fontSize:'14px',color:'red' }}  />} label={`Favourite : ${bookmark_count.reduce((a,v) =>  a = a + v , 0 )}`} />

                <Chip sx={{marginRight:'5px'}} icon={<LibraryBooksIcon style={{fontSize:'14px', color:'red'}}/>} label={`Applied : ${applied.reduce((a,v) =>  a = a + v , 0 )}`} />
               
                <Chip sx={{marginRight:'5px'}} icon={<FlightIcon style={{fontSize:'14px', color:'blue'}}/>} label={`Enrolled : ${enrolled.reduce((a,v) =>  a = a + v , 0 )}`} />

                <Chip sx={{marginRight:'5px'}} icon={<CheckIcon style={{fontSize:'14px', color:'green' }}/>} label={`Admitted : ${admitted.reduce((a,v) =>  a = a + v , 0 )}`} />
        </div>

                <hr />


            </Box>
            <Box sx={{
                marginLeft: 0,
                marginRight: 0,
                borderRadius: 2,
                marginTop: 3,
            }}>
                {TotalRecors === 0 ?
                    <h6 style={{ fontWeight: 'bold' }}>No Data</h6>
                    :
                    loading ?
                        <div style={{ textAlign: 'center', marginTop: '55%' }}>
                            <CircularProgress />
                        </div>
                        :
                        searchList?.length > 0 && searchList?.map((val,i) => {
                      
                            return (
                                <Box sx={{
                                    bgcolor: '#fff',
                                    borderRadius: 3,
                                    pt: 2,
                                    pb: 2,
                                    mb: 2,
                                    paddingBottom: "30px",
                                    height: 'auto', boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)"
                                }}
                                // onClick={() => history.push(`/CollegeDetails/`, val)}
                                key={i}
                                
                                >
                                     <ResultCard
                                        val={val}
                                        bookmark_count={bookmark_count[i]}
                                        enrolled_count={enrolled[i]}
                                        admitted_count={admitted[i]}
                                        applied_count={applied[i]}
                                        // setBookmarkId={setBookmarkId}
                                        // setApplyData={props?.location?.state?.data?.RelatedPrograms ? setApplyData : null}
                                        // setOpenApply={props?.location?.state?.data?.RelatedPrograms ? setOpenApply : null}
                                        // setOpenFav={setOpenFav}
                                        // setOpenDownload={setOpenDownload}
                                        // setSopData={setSopData}
                                        // setAddStudentOpen={setAddStudentOpen}
        
                                    /> 
                                </Box>
                            )
                        })
                }

            </Box>
        </Box>
    );
};

export default UniversityProspectives;