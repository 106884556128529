import React from 'react';
import { styled } from "@mui/material/styles";
import { Box } from '@mui/system';
import { Chip, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BottomNav from '../Common/BottomNav';
import { Link } from 'react-router-dom';
import { getCurrencySymbol } from '../../Controller/Global';
import ResultCard from './ResultCard';
import BookMarkMessageModal from './BookMarkMessageModal';
import FavouriteModal from '../University/FavouriteModal';
import { USER_ID,getUserId, SESSION_ID,getSessionId, getUser} from '../../Controller/localStorageHandler';
import { OPERATION , operationValue} from '../../Controller/Global';
import axios from 'axios';

const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));



// const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];

const SopSearchResult = (props) => {
    const classes = useStyles();
    // const response = await axios.post("/");
// eslint-disable-next-line
    const [value, setValue] = React.useState('recents');
// eslint-disable-next-line
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [openFav,setOpenFav]= React.useState(false)
    const [openMsg,setOpenMsg]= React.useState(false)
    const [bookmarkMsg,setBookmarkMsg]= React.useState('')
    const [symbol,setSymbol]= React.useState('')
    const [bookmarkId,setBookmarkId] = React.useState('')
    const [searchList,setSearchList] = React.useState(props.location.state.data)
    const handleConfirm=async(value)=>{
        const bookmark = new FormData()
        bookmark.append(USER_ID,getUserId())
        bookmark.append(SESSION_ID,getSessionId())
        bookmark.append(OPERATION,operationValue.Bookmark)
        bookmark.append('universitiesid',bookmarkId)
        bookmark.append('btype','university')
        bookmark.append('studentid',getUserId())
        if(value === 'add'){
             bookmark.append('bookmarkstatus',1)

        }else{
            bookmark.append('bookmarkstatus',0)
        }
       await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",bookmark)
            .then(response => {
                if(response.data.status==='success'){
                    setOpenFav(false)
                    setOpenMsg(true)
                    setBookmarkMsg(value === 'add'? 'Bookmark successfully added':'Bookmark successfully removed')
                    const newdata = new FormData()
                    //   console.log(props.location.state.posted_data)
                    for (let val in props.location.state.posted_data)
                    {
                        newdata.append(val, props.location.state.posted_data[val])
                    }

                    axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",newdata)
                    .then(response => {
                        setSearchList(response.data.data)
                    })
                }
           })
    }
    return (
        <Box className={classes.root} sx={{ bgcolor: '#fafafa', }}>
            <Box sx={{

                marginLeft: 2,
                marginRight: 2,
                borderRadius: 2,

            }}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sx={{ mt: 1, fontWeight: 'bold' }}>
                        SOP <span style={{ color: '#1B6CFC' }}>{props.location.state.TotalRecords}</span>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'end' }}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"

                            color="inherit"

                        >
                            <Link to="/InviteShare"><ShareOutlinedIcon /></Link>
                        </IconButton>
                    </Grid>

                </Grid>
                <hr />


            </Box>

            <Box sx={{
                marginLeft: 2,
                marginRight: 2,
                borderRadius: 2,
                marginTop: 3,
            }}>
                <Chip label="Programs" sx={{ bgcolor: '#fff' }} />

                <Chip icon={<FilterAltOutlinedIcon />} label="Filter" sx={{ bgcolor: '#fff', float: 'right', }} />
                <Chip avatar={<img alt="Natacha" src={`${process.env.PUBLIC_URL}/img/sort.png`} />} label="Sort" sx={{ bgcolor: '#fff', float: 'right', mx: 1 }} />
            </Box>

            <Box sx={{
                marginLeft: 2,
                marginRight: 2,
                borderRadius: 2,
                marginTop: 3,
            }}>
                {
                   searchList.map((val) => {
                       return (
                            <Box sx={{ bgcolor: '#fff', borderRadius: 3, py: 1, mb: 1 }}>
                                <ResultCard
                                        val={val}
                                        university={val.university}
                                        program={val.program}
                                        semester={val.semester}
                                        acceptance_rate={val.acceptance_rate}
                                        // country={val.country}
                                        international_tuition_fee_yr={val.international_tuition_fee_yr}
                                        inter_adm_deadline={val.inter_adm_deadline}
                                        universitiesid={val.universitiesid}
                                        setBookmarkId={setBookmarkId}
                                        setApplyData={null}
                                        setOpenApply={null}
                                        setOpenFav={setOpenFav}
                                />
                            </Box>
                        )
                   })
                }
            </Box>
            <FavouriteModal open={openFav} handleClose={()=>setOpenFav(false)} confirm={(value)=>handleConfirm(value)}/>
            <BookMarkMessageModal open={openMsg} handleClose={()=>setOpenMsg(false)} data={bookmarkMsg}/>
        </Box >
    )
}

export default SopSearchResult;
