import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useEffect} from 'react'
import axios from 'axios';
import { getUser, USER_ID, getUserId,getSessionId,SESSION_ID, Contact_Id, setUser, logout } from '../../Controller/localStorageHandler';
import { useFormik } from 'formik';
import PossibleDashform from './PossibleDashform'
import PossibleAcademicForm from './PossibleAcademicForm'
import PossiblePrerequisiteForm from './PossiblePrerequisiteForm'
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { OPERATION, operationValue } from '../../Controller/Global';
import { fetchProfileDetails } from '../Action';


export default function PossibleStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [countryValue, setcountryValue] = React.useState(getUser().country?[getUser().country]:[])
  const [programValue, setprogramValue] = React.useState(getUser().related_programs?[getUser().related_programs]:[])
  const [semesterValue,setsemesterValue]= React.useState(getUser().semester_looking_for)
  const [yearValue,setyearValue]= React.useState(getUser().year_looking_for)
  const [ieltsData,setieltsData] = React.useState([0, 9])
  const [toeflData,settoeflData] = React.useState([0, 120])
  const [pteData,setpteData] = React.useState([0, 90])
  const [duolingoData,setduolingoData] = React.useState([10, 160])
  const [greData,setgreData] = React.useState([260, 340])
  const [gmatData,setgmatData] = React.useState([200, 800])
  const [actData,setactData] = React.useState([1, 36])
  const [satData,setsatData] = React.useState([400, 1600])
  const [first,setFirst] = React.useState('')
  const [second,setSecond] = React.useState('')
 // let [values, handleValues] = React.useState({});

  const history = useHistory()

  // const handleChange = (e) => {
  //   handleValues(prevState => ({...prevState, [e.target.name]: e.target.value}));
    
  // }
  const formik = useFormik({
    initialValues: {
      degree_looking_for:getUser().degree_looking_for,
      uni_puc_inter_12:0.00,
      num_backlogs:0,
      academic_requirements_gpa:0.00,
      
    },
    onSubmit: (values) => {
      const popupData = new FormData()
      popupData.append('module','Contacts')
      popupData.append(SESSION_ID,getSessionId())
      popupData.append(USER_ID, getUserId())
      popupData.append('operation','updateRecord')
      popupData.append('values[redirectpage]','dashboard')
      for(let val in values){
        popupData.append(`values[${val}]`, values[val])      
      }
      programValue.length>0?programValue.map(o=> popupData.append(`values[related_programs][]`, o)):popupData.append(`values[related_programs][]`, '')
      countryValue.length>0?countryValue.map(o=> popupData.append(`values[country][]`, o)):popupData.append(`values[country][]`, '')
      popupData.append('values[year_looking_for]',yearValue)
      popupData.append('values[semester_looking_for]',semesterValue)
      
      axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",popupData)
      .then((response)=>{
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let userId = getUserId()
        if (userId) {
            var formdata = new FormData();
            formdata.append(Contact_Id, getUserId());
            formdata.append(SESSION_ID, getSessionId())
            formdata.append(USER_ID, getUserId())
            formdata.append(OPERATION, operationValue.Fetch_profile_details)

            axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', formdata)
            .then((response) => {
              if(response.statusText === "OK"){
                setUser(response.data.data[0])
              }else{
                logout()
              }
              })
        }
      })
    
    }
    
  });

  const steps = [
    {
      label:'Looking for',
      description:<PossibleDashform
      values={formik.values}
      countryValue={countryValue}
      programValue={programValue}
      semesterValue={semesterValue}
      yearValue={yearValue}
      setyearValue={(data)=>setyearValue(data)}
      setsemesterValue={(data)=>setsemesterValue(data)}
      setcountryValue={(data)=>setcountryValue(data)}
      setprogramValue={(data)=>setprogramValue(data)}
      handleChange={(data)=>formik.handleChange(data)}
      />,
     },
    {
      label:'Academic',
      description: <PossibleAcademicForm 
      values={formik.values}
      countryValue={countryValue}
      handleChange={(data)=>formik.handleChange(data)}
      />
    },
    {
      label:'Test Scores',
      description:<PossiblePrerequisiteForm 
      values={formik.values}
      countryValue={countryValue}
      programValue={programValue}
      pteData={pteData}
      ieltsData={ieltsData}
      toeflData={toeflData}
      duolingoData={duolingoData}
      actData={actData}
      satData={satData}
      greData={greData}
      gmatData={gmatData}
      setactData={(data)=>setactData(data)}
      setgmatData={(data)=>setgmatData(data)}
      setsatData={(data)=>setsatData(data)}
      setgreData={(data)=>setgreData(data)}
      setpteData={(data)=>setpteData(data)}
      setieltsData={(data)=>setieltsData(data)}
      settoeflData={(data)=>settoeflData(data)}
      setduolingoData={(data)=>setduolingoData(data)}
      setFirst={(data)=>setFirst(data)}
      setSecond={(data)=>setSecond(data)}
      handleChange={(data)=>formik.handleChange(data)}
      />
    },
    
  ];
  

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    
    if(activeStep === 0){
      formik.handleSubmit()
      setSkipped(newSkipped);
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      if(activeStep === 2){
        const formData = new FormData()
        
        formData.append('operation','possible_options')
        for (let val in formik.values){
          if(val === 'checked'){
            formData.append('addonConditionType',formik.values[val])
          }else if(val === 'degree_looking_for'){
            formData.append('degree_type',formik.values.degree_looking_for)
          }
          else{
            formData.append(val, formik.values[val])
          }
        }
        formData.append('addonConditionType','')
            formData.append(`duolingofrom`, duolingoData[0])
            formData.append(`duolingoto`, duolingoData[1])
            formData.append(`ptefrom`, pteData[0])
            formData.append(`pteto`, pteData[1])
            formData.append(`toeflfrom`, toeflData[0])
            formData.append(`toeflto`, toeflData[1])
            formData.append(`ieltsfrom`, ieltsData[0])
            formData.append(`ieltsto`, ieltsData[1])

            formData.append(`grefrom`, greData[0])
            formData.append(`greto`, greData[1])
            formData.append(`gmatfrom`, gmatData[0])
            formData.append(`gmatto`, gmatData[1])
            formData.append(`actfrom`, actData[0])
            formData.append(`actto`, actData[1])
            formData.append(`satfrom`, satData[0])
            formData.append(`satto`, satData[1])
            if(first && formData.append('group1',first))
            if(second && (second === 'act'||second === 'sat')?formData.append('group3',second):formData.append('group2',second))
          
        formData.append('call_frm','website')
        formData.append('page','1')
        programValue.map(o=> formData.append(`program[]`, o))
        countryValue.map(o=> formData.append(`country[]`, o))
        formData.append(USER_ID,getUserId())
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",formData)
                .then(response => {
                    if(response.data.msg === 'successfully' || response.data.message === 'Successfully'){
                        // history.push('/UniversitySearchResult', response.data)
                        history.push({ pathname: '/UniversitySearchResult', state: {data: response.data, screen:'Possible Options',page:JSON.stringify(Object.fromEntries(formData))}})
                    }else{
                        alert(response.data.msg||response.data.message)
                    }
                })
      }
    }
  };
  

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((o, index) => {
          const stepProps = {};
          const labelProps = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     // <Typography variant="caption">Optional</Typography>
          //   );
          // }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={o.label} {...stepProps}>
              <StepLabel {...labelProps}>{o.label}</StepLabel>
              {/*{steps[activeStep].description}*/}
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
            <div style={{textAlign: 'center', marginTop:'55%'}}>
            <CircularProgress />
            </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/*<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>*/}
          {steps[activeStep].description}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/*{isStepOptional(activeStep) && (*/}
            {/*  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>*/}
            {/*    Skip*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Button style={{backgroundColor:"#1B6CFC",color:"#fff",borderRadius:"50px",width:"150px",boxShadow:"0 5px 8px 0 rgba(0,0,0,0.3)"}} onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}