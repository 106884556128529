import React from 'react';
import EventsCardDetails from "./EventsCardDetails";
import "./Event.css";

const EventDetails = () => {

    return (
        <div className="global-container mt-3 p-0">
            <EventsCardDetails/>
        </div>
    );
};

export default EventDetails;
