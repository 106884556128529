import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    LinearProgress,
    Typography,
    Alert
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Colors from "../../static/layout/Colors";
// import Step from "./Sliders/Slider1";
import Slider from 'react-slick';
import UploadDocumentModal from './UploadDocumentModal';
import PreviewDocumentModal from './PreviewDocumentModal';
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import { champianInsertAction  } from '../Action/index.jsx';
import _ from 'lodash'
import { UploadDocumentAction } from "../Action";

const Zolve = () => {
    const { id,name } = useParams()
    const classes = useStyles();

    const initialValues = {
        Offer:0,
        I20:0,
        Passport:0,
        Visa:0,
        Bank:0
    }

    const [progress, setProgress] = useState(initialValues); 

    var settings = {
        // dots: true,
        centerMode: true,
        autoplay: true,
        infinite: true,
        speed: 500,
  
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            //   initialSlide: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    const [openUpload, setOpenUpload] = React.useState(false);
    const [openPreview, setOpenPreview] = React.useState(false);

    const [UploadingFileData,SetUploadingFileData] = React.useState([]);
    const [ActiveUploadingData, SetActiveUploadingData] = React.useState({});

    const handleOpenUpload = (docType) => {
        let newdata = {}
        newdata[`id`] = docType
        newdata[`title`] = docType
        newdata["active"] = false
        newdata["file"] = null
        UploadingFileData.push(newdata)
        SetUploadingFileData([...UploadingFileData])
        SetActiveUploadingData(newdata)
        setOpenUpload(true);
    };
    const handleCloseUpload = () => {
        setOpenUpload(false);
    };
    const handleOpenPreview = (docType) => {
        let newdata = {}
        newdata[`id`] = docType
        newdata[`title`] = docType
        newdata["active"] = false
        newdata["file"] = null
        UploadingFileData.push(newdata)
        SetUploadingFileData([...UploadingFileData])
        setOpenPreview(true);
    };
    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    const [fullName,setFullName] = useState('');
    const [email,setEmail] = useState('');
 
    const [contactNum,setContactNum] = useState('');
  
    const [university,setUniversity] = useState([]);
    const [studentId,setStudentId] = useState('');
    const [loginId,setLoginId] = useState('');
    const [password,setPassword] = useState('');
    const [formError,setFormError] = useState(false);


    const submitForm = async() => {
        if(email === '' || contactNum === ''){
            setFormError(true)
            return 
        }
            Swal.showLoading()
            const formData = new FormData()
            formData.append('operation','checkUserExistance');
            formData.append('username',fullName);
            formData.append('email_address',email);
       
            formData.append('phone_mobile',91+contactNum);
            formData.append('student_id',studentId);
            
            formData.append('leadsource','Accomodation');

        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",formData)
        .then(response => {
            console.log(response)
        if(response?.data?.status === "success"){
        var formData = new FormData();
        formData.append('campaignid', university['0']?.campaignid);
        formData.append('contactid',response?.data?.data);
        formData.append("userid", response?.data?.data);
        formData.append("sessionid", '');
        formData.append("bypass","validation");
        champianInsertAction(formData).then(res => {
            console.log(res)
        
        if(UploadingFileData.length > 0){
        // uploading documents if present
        var data = new FormData();
        data.append('contact_id', response?.data?.data);
        data.append('submit', 'a');
        data.append('from', 'react');
        data.append('contact_type', 'student');
        UploadingFileData.map((d)=>{
            data.append(d.title,d.file);
            data.append(d.title,d.title);
        })
        UploadDocumentAction(data).then((d) => {
            console.log(d)
            Swal.fire(
                'Good job!',
                'Created contact and Successfully added to Campaign!',
                'success'
              )
           
        }).catch((error) => {
            console.log(error)
        })

        }else{

            if (res?.msg === "Success") {
                Swal.fire(
                    'Good job!',
                    'Created contact and Successfully added to Campaign!',
                    'success'
                  )

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'oops...',
                    text: 'Something went wrong!',
                  })
 
            }
        }

            
        }).catch((erro) => {
            Swal.fire({
                icon: 'error',
                title: 'oops...',
                text: 'Something went wrong!',
              })
        })
            }
    else if(response?.data?.message === "Email already exists"){

        var formData = new FormData();
        formData.append('campaignid', university['0']?.campaignid);
        formData.append('contactid',response?.data?.data);
        formData.append("userid", response?.data?.data);
        formData.append("sessionid", '');
        formData.append("bypass","validation");
        champianInsertAction(formData).then(res => {
            console.log(res)
            if (res?.msg === "Success") {
                Swal.fire(
                    'Good job!',
                    'This account is already registered. We have Successfully added to this Campaign!',
                    'success'
                  )

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'oops...',
                    text: 'Something went wrong!',
                  })
 
            }
        }).catch((erro) => {
            Swal.fire({
                icon: 'error',
                title: 'oops...',
                text: 'Something went wrong!',
              })
        })

            } 
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'oops...',
                    text: 'Something went wrong!',
                  })
            }

            // Swal.hideLoading()
        })
        
     
    }
    const saveDoc = (data) =>{
        if(data === 'I-20'){
            setProgress({...progress, ['I20']: 100})
        }
        else{
            setProgress({...progress, [data]: 100})
        }
        handleCloseUpload();
    }

    const getCampainDetails = async () =>{
        const formData = new FormData();
        formData.append('operation','get_campaign_details');
        formData.append('campaigntype','Agent Appointment');
        formData.append('campaign_no',id);
        
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",formData)
        .then(response => {
            setUniversity(response?.data?.data)
            console.log(response)
        })
    }

    useEffect(()=>{
        getCampainDetails();
    },[])


    return (
        <div style={{padding: "0 5px"}}>
            {/* <Grid
                sx={{
                    width: "100%",
                    margin: 0,
                    justifyContent: "space-around",
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/bank-card.png)`,
                    backgroundPosition: "center",
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "15px",
                    height: "180px"
                }}
            >
                <Typography
                    sx={{mt: "5px", color: "white", fontSize: "11px", width: "230px"}}></Typography>
            </Grid> */}
            <Slider className={'gre-slider'}  style={{ margin: "15px 0", height:"250px",  width: "100%" }} {...settings}>

                {university && university.map((d)=>{
                    return <div style={{padding:"0 10px", width: "100%", display: "inline-block" }}>
                    <Grid
                    sx={{
                        width: "100%",
                        margin: 0,
                        justifyContent: "space-around",
                        backgroundImage: `url(https://erp.bscglobaledu.com/${d.path}/${d.attachmentsid}_${d.storedname})`,
                        backgroundPosition: "center",
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "15px",
                        height: "150px"
                    }}
                >
                    <Typography
                        sx={{mt: "5px", color: "white", fontSize: "11px", width: "230px"}}></Typography>
                </Grid>
            </div>
                })}
            </Slider>   

            <Typography sx={{textAlign:'center',fontSize:'20px',fontWeight:'bold'}}>
                    {university && university['0']?.campaignname}
            </Typography>
            {
                formError ? <Alert severity="error" onClose={() => {setFormError(false)}}>Please fill * Mandatory fields. </Alert> : ''
            }
            
            
            <Grid container spacing={2}>
                <Grid item md={6}>
   
            <div className={classes.cardBox}>
                <FormControl sx={{my: 1, minWidth: "100%"}}>
                    <Input type="text" onChange={(e)=>setFullName(e.target.value)} value={fullName} placeholder={"Full Name *"} />
                </FormControl>
                <FormControl sx={{my: 1, minWidth: "100%"}}>
                    <Input type="text" onChange={(e)=>setEmail(e.target.value)} value={email} placeholder={"E-mail *"} />
                </FormControl>
             
                <FormControl sx={{my: 1, minWidth: "100%"}}>
                    <Input type="text" onChange={(e)=>setContactNum(e.target.value)} value={contactNum} placeholder={"Mobile No *"} />
                </FormControl>
                <FormControl sx={{my: 1, minWidth: "100%"}}>
                    <Input type="text" onChange={(e)=>setStudentId(e.target.value)} value={studentId} placeholder={"Student Id"} />
                </FormControl>
                {/* <FormControl sx={{my: 1, width: "50%",paddingRight:"5px"}}>
                    <Input type="text" onChange={(e)=>setLoginId(e.target.value)} value={loginId} placeholder={"Login Id"} />
                </FormControl>
                <FormControl sx={{my: 1, width: "50%",paddingLeft:"5px"}}>
                    <Input type="text" onChange={(e)=>setPassword(e.target.value)} value={password} placeholder={"Password"} />
                </FormControl> */}
                
            </div>
            </Grid>
            <Grid item md={6}>
            <div className="global-container" style={{paddingBottom:"50px",zIndex:'1'}}>
                <div>
                    <p style={{
                        marginTop: '20px',
                        color: '#666666',
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 'normal'
                    }}>Upload Documents</p>
                </div>
                <div style={{display:"inline-block",width:"100%"}} className="flexCenterJcB">
                    <main style={{marginTop: '4%',display:"inline-block"}}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`}/>
                    </main>
                    <main className="flexCenterJcB" style={{display:"inline-block",width:"60%",paddingLeft:"10px"}}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold',fontSize:"13px"
                                }}> Offer Letter</p>
                                <Box sx={{width: '140%'}}>
                                    <LinearProgress variant="determinate" value={progress['Offer']}/>
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888',display:"inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>handleOpenUpload('Offer')} />
                        <img style={{marginRight:"5px",marginLeft:"5px"}} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={()=>handleOpenPreview('Offer')}/>
                    </p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{display:"inline-block",width:"100%"}} className="flexCenterJcB">
                    <main style={{marginTop: '4%',display:"inline-block"}} >
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`}/>
                    </main>
                    <main className="flexCenterJcB" style={{display:"inline-block",width:"60%",paddingLeft:"10px"}}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold',fontSize:"13px"
                                }}> I-20</p>
                                <Box sx={{width: '140%'}}>
                                    <LinearProgress variant="determinate" value={progress['I20']}/>
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888',display:"inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>handleOpenUpload('I-20')}/>
                        <img style={{marginRight:"5px",marginLeft:"5px"}} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={()=>handleOpenPreview('I-20')}/>
                    </p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{display:"inline-block",width:"100%"}} className="flexCenterJcB">
                    <main style={{marginTop: '4%',display:"inline-block"}}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`}/>
                    </main>
                    <main className="flexCenterJcB" style={{display:"inline-block",width:"60%",paddingLeft:"10px"}}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold',fontSize:"13px"
                                }}> Passport </p>
                                <Box sx={{width: '140%'}}>
                                    <LinearProgress variant="determinate" value={progress['Passport']}/>
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888',display:"inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>handleOpenUpload('Passport')}/>
                        <img style={{marginRight:"5px",marginLeft:"5px"}} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={()=>handleOpenPreview('Passport')}/>
                    </p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{display:"inline-block",width:"100%"}} className="flexCenterJcB">
                    <main style={{marginTop: '4%',display:"inline-block"}}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`}/>
                    </main>
                    <main className="flexCenterJcB" style={{display:"inline-block",width:"60%",paddingLeft:"10px"}}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold',display:"inline-block",fontSize:"13px"
                                }}>Visa Copy</p>
                                <Box sx={{width: '140%'}}>
                                    <LinearProgress variant="determinate" value={progress['Visa']}/>
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888',display:"inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>handleOpenUpload('Visa')}/>
                        <img style={{marginRight:"5px",marginLeft:"5px"}} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`}/>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`}  onClick={()=>handleOpenPreview('Visa')}/></p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{display:"inline-block",width:"100%"}} className="flexCenterJcB">
                    {/* <main style={{marginTop: '4%',display:"inline-block"}}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`}/>
                    </main>
                    <main className="flexCenterJcB" style={{display:"inline-block",width:"60%",paddingLeft:"10px"}}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold',display:"inline-block",fontSize:"13px"
                                }}> Bank Loan Sanction</p>
                                <Box sx={{width: '140%'}}>
                                    <LinearProgress variant="determinate" value={progress['Bank']}/>
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888',display:"inline-block"
                    }}>
                    <div>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>handleOpenUpload('Bank')}/>
                        <img style={{marginRight:"5px",marginLeft:"5px"}} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={()=>handleOpenPreview('Bank')}/>
                    </div>
                    </p> */}
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                    
                    <UploadDocumentModal open={openUpload} handleClose={handleCloseUpload} save={saveDoc} ActiveUploadingData={ActiveUploadingData} SetUploadingFileData={(data) => {
                let Data = UploadingFileData.map((d) => {
                    if (d.id === data.id) {
                        return data
                    } else {
                        return d
                    }
                })
                SetUploadingFileData(Data)
            }} />
                    <PreviewDocumentModal open={openPreview} handleClose={handleClosePreview}/>
                </div>
            </div>
            </Grid>

            <Grid xs={12} className={classes.gridFlex} style={{marginBottom: '5px', marginTop: '20px'}}>
                        <Button className={classes.resetBtn}>Reset</Button>
                        <Button
                            onClick={()=>submitForm()}
                            className={classes.submitBtn}
                            type={'submit'}>
                                Submit
                        </Button>
                    </Grid>
            </Grid>
            
        </div>
        
    );
};
const useStyles = makeStyles(() => ({
    docNo: {
        border: "1px solid transparent",
        borderRadius: "50px",
        height: "50px",
        width: "50px",
        backgroundColor: "#46a719",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        marginTop: '5%',
        backgroundColor: '#ffffff',
        borderRadius: '30px',
        padding: '3px 15px',
    },
    no: {
        textAlign: "center",
        fontSize: "17px",
        color: "white",
        fontWeight: "bold",
    },
    viewDiv: {
        border: "1px solid transparent",
        width: "fit-content",
        padding: "0px 15px 0px 15px",
        borderRadius: '25px',
        backgroundColor: Colors.lightBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    view: {
        fontWeight: 'bold',
        color: Colors.blue,
        padding: '5px',
    },
    submitImage: {
        marginTop: '10%',
        display: 'flex',
        justifyContent: 'center'
    },
    bar: {
        display: 'flex',
        flexDirection: 'column'
    },
        cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
        textAlign:'center',
        justifyContent:'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));
export default Zolve;