import React from 'react';
import logo1 from '../../../static/img/Associate/image//logo1.png'
import { useHistory } from 'react-router-dom';

const RegistratedCard = ({ name, trans, dollar, persent }) => {
    const history = useHistory()

    return (
        <div className="d-flex align-items-center follow_up_card" style={{ boxShadow: "0px 10px 20px rgba(0 0 0 / 0.4), 0px 2px 6px rgba(0 0 0 / 0.4), 0px 0px 1px rgba(0 0 0 / 0.4)", justifyContent: "space-between", paddingLeft: 20, paddingRight: 30 }}
            onClick={() => {
                history.push({
                    pathname: '/student-list',
                    state: {
                        'status': name !== "Total" ? name : 'All',
                        "type": "Register"
                    }
                })
            }}
        >
            <div>
                <h3 className="budget_text">{name}</h3>
                <h4 className="budget_title">{trans}</h4>

            </div>
            <div>
                <h3 className="budget_text">{dollar}</h3>
                <h4 className="budget_title">{persent}</h4>
            </div>
        </div>
    );
};

export default RegistratedCard;