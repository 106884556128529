import React from 'react'
import { Avatar, FormControl, Grid, Input, ModalUnstyled, LinearProgress, Button, Typography, InputLabel, Backdrop, CircularProgress } from "@mui/material";

import { Box, styled } from '@mui/system'
import { getUser, getUserId } from '../../../Controller/localStorageHandler';
import { makeStyles } from "@mui/styles";
import UploadDocumentModal from '../../Zolve/UploadDocumentModal';
import PreviewDocumentModal from '../../Zolve/PreviewDocumentModal';
import Colors from '../../../static/layout/Colors';
import { CommonLogin } from '../../Forms/CommonLogin';
import { ToastContainer, toast } from 'react-toastify';
import Slider from '@mui/material/Slider';
import _ from 'lodash';
import uniqid from 'uniqid';
import axios from 'axios';
import { UploadDocumentAction } from '../../Action';
import {useParams, useHistory} from 'react-router-dom';
import AccomodationCard from '../Card';



export const AddAccomodation = (props) => {
    let Data = getUser()
    const { id } = useParams()
    const histroy = useHistory()

    const classes = useStyles();
    let progressVal = {
        "i20":0,
        "passport":0,
        "visa":0,
        "offer":0,
        "others":0,
    }
    const [progress, setProgress] = React.useState(progressVal);
    const [openUpload, setOpenUpload] = React.useState(false);
    const [openPreview, setOpenPreview] = React.useState(false);
    const [openLogin, setOpenLogin] = React.useState(false)

    var nam = Data?.firstname ? Data?.firstname : '' + ' ' + Data?.lastname ? Data?.lastname : '';
    const [name, setName] = React.useState(nam);
    const [nameErr, setNameErr] = React.useState(false);
    const [intake, setIntake] = React.useState('');
    const [intakeErr, setIntakeErr] = React.useState(false);
    const [year, setYear] = React.useState('');
    const [yearErr, setErrYear] = React.useState(false);
    const [appId, setAppId] = React.useState('');
    const [appIdErr, setAppIdErr] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [usernameErr, setUsernameErr] = React.useState(false);
    const [passport, setPassport] = React.useState('')

    const [UploadingFileData, SetUploadingFileData] = React.useState([]);
    const [SubmitButtonDisable, setSubmitButtonDisable] = React.useState(false)
    const [ActiveUploadingData, SetActiveUploadingData] = React.useState({});

    const [range, setRange] = React.useState([300, 1000]);

    const [backDrop,setBackDrop] = React.useState(false);

    const [accomDetails, setAccomDetails] = React.useState([]);

    const DataMapping = [
        {
            "document": "I-20",
            "title": "I-20",
            "category": "Academic",
        },
        {
            "document": "Offer Letter",
            "title": "Offer Letter",
            "category": "Academic",
        },
        {
            "document": "Passport Copy",
            "title": "Passport Copy",
            "category": "Academic",
        },
        {
            "document": "Visa Copy",
            "title": "Visa Copy",
            "category": "Academic",
        },
        {
            "document": "Others",
            "title": "Others",
            "category": "General",
        }
    ]

    const handleOpenUpload = () => {
        setOpenUpload(true);
    };
    const handleCloseUpload = () => {
        setOpenUpload(false);
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    };
    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    const marks = [
        {
            value: 300,
            label: '300$',
        },

        {
            value: 1000,
            label: '1000$',
        },
        {
            value: 1500,
            label: '1500$',
        },
        {
            value: 2000,
            label: '2000$',
        },
        {
            value: 2500,
            label: '2500$',
        },
        {
            value: 3000,
            label: '3000$',
        },
    ];

    const handleChange = (event, newValue) => {
        setRange(newValue);
    };

    function valuetext(value) {
        return `${value}°C`;
    }

    const validateForm = () => {
        setNameErr(false);
        setIntakeErr(false);
        setErrYear(false);
        setAppIdErr(false);
        setUsernameErr(false);
        if (name === '') {
            setNameErr(true);
            toast.error("Kindly Write your name")
            return
        }
        else if (intake === '') {
            setIntakeErr(true);
            toast.error("Kindly Enter Correct Intake")
            return
        } else if (year === '') {
            setErrYear(true);
            toast.error("Kindly Enter Year")
            return
        } else if (appId === '') {
            setAppIdErr(true);
            toast.error("Kindly Enter Application Id / Student Id / Reference Id")
            return
        } else if (username === '') {
            setUsernameErr(true);
            toast.error("Kindly Write your Username")
            return
        } else {
            if (getUserId()) {
                submit()
                return
            }
            else {
                setOpenLogin(true)
                return
            }
            // submit function
        }
    }
    const submit = async () => {
        setBackDrop(true)
        let userData = getUser()
        const formData = new FormData()
        formData.append('operation', 'add_comments_from_accomodation')
        formData.append('name', name)
        formData.append('email', getUser()?.email)
        formData.append('mobile', getUser()?.mobile)
        formData.append('intake', intake)
        formData.append('year', year)
        formData.append('appId', appId)
        formData.append('username', username)
        formData.append('passport', passport)
        formData.append('budget',range)
        formData.append('contactid',getUserId())
        formData.append('accommidationid',id)

        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            // console.log(response)
            if(response?.data?.status === "success"){
         
        var data = new FormData();
        data.append('contact_id', getUserId());
        data.append('submit', 'a');
        data.append('from', 'react');
        data.append('contact_type', 'student');
        // data.append('Air', 'Air Ticket Confirmation');
        _.map(_.cloneDeep(UploadingFileData), (d) => {
            if (d.file) {
                let Data = _.find(DataMapping, (d1) => d1.document === d.title)
                data.append(Data.title, d.file); // Data.title 10th 
                data.append(Data.title, d.title); // Data.title 10th
            }
        })

        UploadDocumentAction(data).then((d) => {
            // console.log(d)
   
            SetUploadingFileData([])
            setBackDrop(false)
            toast.success("We have received your enquiry, we will share your details to the Landlord if it is still available.")
            histroy.push("/accomodation")
        }).catch((error) => {
            SetUploadingFileData([])
   
            setBackDrop(false)
            console.log(error)
        })
            }
        })
        
    }

    const saveDoc = (data) =>{
        handleCloseUpload();
    }

    React.useEffect(() => {
        if (UploadingFileData <= 0) {
            setSubmitButtonDisable(true)
        } else {
            setSubmitButtonDisable(false)
        }
        _.map(_.cloneDeep(UploadingFileData), (d) => {
            if (!d.file) {
                setSubmitButtonDisable(true)
            }
        })
    }, [UploadingFileData])

    const fetchAccomodation = async() =>{
        var formData = new FormData()
        formData.append('operation','accomodationSingle')
        formData.append('accommidationid',id)
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            // console.log(response)
            if(response?.data?.status === "success"){
                setAccomDetails(response?.data?.data[0])
            }
            else{
                setAccomDetails([])
            }
            
        })
    }

    React.useEffect(()=>{
        fetchAccomodation()
    },[])

    if(backDrop){
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backDrop}
            onClick={()=>setBackDrop(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          }

    return <div>
        <div style={{ textAlign: "center" }}>
            <h6>Apply Accommodation</h6>
            
            <hr />
        </div>
        <AccomodationCard val={accomDetails} hide="links" />

        <div style={{ paddingLeft: 15, paddingRight: 15, height: "90%", overflowY: "scroll" }}>
            <span style={{ textAlign: 'right', fontSize: '10px' }}>* Mandatory Fields</span>
            <div className={classes.cardBox}>

                <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel>Name *</InputLabel>

                    <Input type="text" placeholder={"Full Name"} onChange={(e) => setName(e.target.value)} value={name} error={nameErr ? true : false} />
                </FormControl>
                <FormControl sx={{ my: 1, width: "50%", paddingRight: "5px" }}>
                    <InputLabel>Intake *</InputLabel>
                    <Input type="text" value={intake} onChange={(e) => setIntake(e.target.value)} placeholder={"Intake"} error={intakeErr ? true : false} />
                </FormControl>
                <FormControl sx={{ my: 1, width: "50%", paddingRight: "5px" }}>
                    <InputLabel>Year *</InputLabel>
                    <Input type="number" max="2020" min="2030" value={year} onChange={(e) => setYear(e.target.value)} placeholder={"Year"} error={yearErr ? true : false} />
                </FormControl>
                <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel>Application / Student / Reference Id *</InputLabel>
                    <Input type="text" value={appId} onChange={(e) => setAppId(e.target.value)} placeholder={"Application / Student ID"} error={appIdErr ? true : false} />
                </FormControl>

                <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel>Username *</InputLabel>
                    <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Username' error={usernameErr ? true : false} />
                </FormControl>
                <FormControl sx={{ my: 1, minWidth: "100%" }}>
                    <InputLabel>Passport Number</InputLabel>
                    <Input type="text" value={passport} onChange={(e) => setPassport(e.target.value)} placeholder={"Passprot No"} />
                </FormControl>
                <FormControl sx={{ my: 1, minWidth: "100%" }}>

                    <Typography >
                        Budget Range
                    </Typography>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={range}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        max={3000}
                        min={100}
                        step={100}
                        marks={marks}
                    />

                </FormControl>



            </div>
            <div style={{ paddingBottom: "50px" }}>
                <div>
                    <p style={{
                        marginTop: '20px',
                        color: '#666666',
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 'normal'
                    }}>Upload Documents</p>
                </div>

                <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                    <main style={{ marginTop: '4%', display: "inline-block" }} >
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                    </main>
                    <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold', fontSize: "13px"
                                }}> I-20</p>
                                <Box sx={{ width: '140%' }}>
                                    <LinearProgress variant="determinate" value={progress['i20']} />
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888', display: "inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>{
                            let newdata = {}
                            newdata[`id`] = uniqid()
                            newdata[`title`] = "I-20"
                            newdata["active"] = false
                            newdata["file"] = null
                            UploadingFileData.push(newdata)
                            SetActiveUploadingData(newdata)
                            handleOpenUpload()
                        }} />
                        <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} />
                    </p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                    <main style={{ marginTop: '4%', display: "inline-block" }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                    </main>
                    <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold', fontSize: "13px"
                                }}> Passport </p>
                                <Box sx={{ width: '140%' }}>
                                    <LinearProgress variant="determinate" value={progress['passport']} />
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888', display: "inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>{
                            let newdata = {}
                            newdata[`id`] = uniqid()
                            newdata[`title`] = "Passport Copy"
                            newdata["active"] = false
                            newdata["file"] = null
                            UploadingFileData.push(newdata)
                            SetActiveUploadingData(newdata)
                            handleOpenUpload()
                        }}/>
                        <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} />
                    </p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                    <main style={{ marginTop: '4%', display: "inline-block" }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                    </main>
                    <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold', display: "inline-block", fontSize: "13px"
                                }}>Visa Copy</p>
                                <Box sx={{ width: '140%' }}>
                                    <LinearProgress variant="determinate" value={progress['visa']} />
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888', display: "inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>{
                            let newdata = {}
                            newdata[`id`] = uniqid()
                            newdata[`title`] = "Visa Copy"
                            newdata["active"] = false
                            newdata["file"] = null
                            UploadingFileData.push(newdata)
                            SetActiveUploadingData(newdata)
                            handleOpenUpload()
                        }} />
                        <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} /></p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                    <main style={{ marginTop: '4%', display: "inline-block" }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                    </main>
                    <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold', display: "inline-block", fontSize: "13px"
                                }}>Offer Letter </p>
                                <Box sx={{ width: '140%' }}>
                                    <LinearProgress variant="determinate" value={progress['offer']} />
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888', display: "inline-block"
                    }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>{
                            let newdata = {}
                            newdata[`id`] = uniqid()
                            newdata[`title`] = "Offer Letter"
                            newdata["active"] = false
                            newdata["file"] = null
                            UploadingFileData.push(newdata)
                            SetActiveUploadingData(newdata)
                            handleOpenUpload()
                        }} />
                        <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} /></p>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                </div>
                <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                    <main style={{ marginTop: '4%', display: "inline-block" }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                    </main>
                    <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                        <nav>
                            <div className={classes.bar}>
                                <p style={{
                                    fontWeight: 'bold', display: "inline-block", fontSize: "13px"
                                }}>Others</p>
                                <Box sx={{ width: '140%' }}>
                                    <LinearProgress variant="determinate" value={progress['others']} />
                                </Box>
                            </div>
                        </nav>
                    </main>
                    <p style={{
                        fontWeight: 'normal',
                        color: '#888888', display: "inline-block"
                    }}>
                        <div>
                            <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={()=>{
                            let newdata = {}
                            newdata[`id`] = uniqid()
                            newdata[`title`] = "Others"
                            newdata["active"] = false
                            newdata["file"] = null
                            UploadingFileData.push(newdata)
                            SetActiveUploadingData(newdata)
                            handleOpenUpload()
                        }} />
                            <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} />
                            <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} />
                        </div>
                    </p>
                    <Grid xs={12} sx={{ display: "flex", margin: "20px 0" }}>
                        <input type="checkbox"
                            //   {...label}
                            //   defaultChecked
                            style={{ height: "22px", width: "30px", background: "orange", border: " 1px solid #DCE8FF" }}
                        //    onChange={handleChange}
                        // onChange={(e) => setcheck(e.target.checked)}
                        />
                        <Typography
                            sx={{ color: "#666666", textAlign: "left", fontSize: "16px", lineHeight: "20px", marginLeft: "5px" }}
                        >
                            By submiting you agree with  our <a href="#" style={{ color: "#1b6cfc" }}
                                className={classes.Terms}>Terms</a> and <a href="#" style={{ color: "#1b6cfc" }} className={classes.Terms}>Conditions</a>
                        </Typography>
                    </Grid>
                    {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                    <Grid xs={12} style={{ marginBottom: '5px', marginTop: '20px', textAlign: 'center' }}>
                        <div>
                        
                            <Button
                                onClick={() => validateForm()}
                                className={classes.submitBtn}
                                type={'submit'}>
                                Submit
                            </Button>

                        </div>
                    </Grid>
                    <UploadDocumentModal open={openUpload} handleClose={handleCloseUpload} save={saveDoc} ActiveUploadingData={ActiveUploadingData} SetUploadingFileData={(data) => {
                let Data = UploadingFileData.map((d) => {
                    if(d?.title === "I-20"){
                        setProgress({ ...progress, ['i20']: 100 })
                    }
                    if(d?.title === "Passport Copy"){
                        setProgress({ ...progress, ['passport']: 100 })
                    }
                    if(d?.title === "Visa Copy"){
                        setProgress({ ...progress, ['visa']: 100 })
                    }
                    if(d?.title === "Offer Letter"){
                        setProgress({ ...progress, ['offer']: 100 })
                    }
                    if(d?.title === "Others"){
                        setProgress({ ...progress, ['others']: 100 })
                    }
                    if (d.id === data.id) {
                        return data
                    } else {
                        return d
                    }
                    
                })
                SetUploadingFileData(Data)
                // console.log(Data)
            }} />
                    <PreviewDocumentModal open={openPreview} handleClose={handleClosePreview} />
                </div>
            </div>

            <CommonLogin openLogin={openLogin} handleClose={setOpenLogin} return_url="callback" callBack={submit} />

        </div>
    </div>

}
const useStyles = makeStyles(() => ({
    docNo: {
        border: "1px solid transparent",
        borderRadius: "50px",
        height: "50px",
        width: "50px",
        backgroundColor: "#46a719",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        marginTop: '5%',
        backgroundColor: '#ffffff',
        borderRadius: '30px',
        padding: '3px 15px',
    },
    no: {
        textAlign: "center",
        fontSize: "17px",
        color: "white",
        fontWeight: "bold",
    },
    viewDiv: {
        border: "1px solid transparent",
        width: "fit-content",
        padding: "0px 15px 0px 15px",
        borderRadius: '25px',
        backgroundColor: Colors.lightBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    view: {
        fontWeight: 'bold',
        color: Colors.blue,
        padding: '5px',
    },
    submitImage: {
        marginTop: '10%',
        display: 'flex',
        justifyContent: 'center'
    },
    bar: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));