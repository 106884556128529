import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import EventsNav from "./EventsNav";
import EventsCard from "./EventsCard";
import { Grid, Chip, Box, Pagination } from "@mui/material";
import { fetchEventListAction } from '../Action/index'
import { getSessionId, getUserId } from "../../Controller/localStorageHandler";
import _ from 'lodash'
import moment from 'moment'
import useAppContext from "../../useContext/userContext"


const EventsList = () => {
    const { getEventList, event_list } = useAppContext()

    const [EventListData, setEventListData] = useState([])

    const [countData, setCountDat] = useState([]);


    const [joinData, setJoinData] = useState([]);

    const [currentPage,setCurrentPage]=useState(1);
    const detailPerPage=10
    const indexOfLastPage=currentPage*detailPerPage;
    const indexOfFirstPage=indexOfLastPage-detailPerPage;
    const paginate=(e, value)=>{
       setCurrentPage(value);
       window.scrollTo({top:20 ,behavior:"smooth"})
    }

    

    useEffect(() => {

        getEventList()
    }, [])

    const [toggle, setToggle] = useState({
        current: true,
        upcoming: false,
        past: false,
    });

    useEffect(() => {
        setCountDat(event_list?.event_list?.count_data)
        setJoinData(event_list?.event_list?.join_data)

        let newDate = moment(new Date()).format("DD-MM-YYYY")
        // console.log(event_list?.event_list?.data)
        const filterGroupArr =  _.orderBy(_.filter(event_list?.event_list?.data, function (o) {
            let eventDate = moment(new Date(o?.event_date)).format("DD-MM-YYYY")
            let now = new Date()
            let date2 = new Date(o?.event_date)


            if (toggle['current']) {
                return eventDate == newDate
            }
            if (toggle['upcoming']) {
                return now.getTime() < date2.getTime()
            }
            if (toggle['past']) {
                return now.getTime() > date2.getTime()
            }

        }),['event_date'],[`${toggle['upcoming'] ? 'acs' : 'desc'}`])
        setEventListData(filterGroupArr)
    }, [event_list, toggle])






    return (
        <div className="global-container mt-3 p-0">
            {/* <PageTitle pageTitle={`Events`} bold /> */}
            <span style={{ margin: 'auto', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Chip color="success" label="Events" />
            </span>
            <Grid style={{ marginTop: '25px' }} xs={12}>
                <EventsNav style={{ marginTop: '10px' }} toggle={toggle} setToggle={setToggle} />
            </Grid>
            <Grid container spacing={2}>
                {EventListData?.length > 0 && EventListData.slice(indexOfFirstPage,indexOfLastPage)?.map((d) => {
                    return <Grid item lg={4}>
                        <EventsCard data={d} countData={countData || null} joinData={joinData || null} />
                    </Grid>
                })}
            </Grid>
            <Box display="flex"
                                justifyContent="center"
                                alignItems="center"
                                style={{width:'100%'}}>
                <Pagination
                    defaultPage={1}
                    page={currentPage}
                    count={Math.ceil((EventListData?.length) / 10)}
                    color="primary"
                    onChange={paginate} 
                />
            </Box>
        </div>
    );
};

export default EventsList;