import React, { useEffect, useState } from 'react'
import PageTitle from '../../PageTitle'
import Contents from './Contents'
import FeaturedCards from './FeaturedCards'
import { fetchDocumentCheckListAction } from "../../Action";
import { getUser, getUserId, getUserType } from "../../../Controller/localStorageHandler";

const DocumentCheckList = () => {
    const [DocumentCheckListData, setDocumentCheckListData] = useState()
    const [seletedCheckList, setseletedCheckList] = useState('Academic')
    const header = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
    const featured = {
        display: 'flex',
        flex: 1,
        // justifyContent : 'space-evenly',
        overflow: 'auto',
        marginRight: '5%'
    }
    const getDocumentCheckListData = (category) => {
        var data = `{\n"contact_id":"${getUserId()}","category":"${category}","bypass":"validation"\n}`;
        fetchDocumentCheckListAction(data).then((datas) => {
            setDocumentCheckListData(datas.data)
            console.log(datas)
        }).catch((error) => {
            setDocumentCheckListData([])

        })
    }
    useEffect(() => {
        getDocumentCheckListData(seletedCheckList)
    }, [])

    useEffect(() => {
        getDocumentCheckListData(seletedCheckList)
    }, [seletedCheckList])

    return (
        <div>
            <div>
                <Contents
                    DocumentCheckListData={DocumentCheckListData}
                    setseletedCheckList={(data) => setseletedCheckList(data)}
                    seletedCheckList={seletedCheckList}
                />
                {/* <div style={header}>
                    <PageTitle pageTitle='Featured Doctors' bold />
                    <p>See all</p>
                </div> */}
                {/* <main style={featured}>
                    <FeaturedCards />
                    <FeaturedCards />
                    <FeaturedCards />
                </main> */}
            </div>
        </div>
    )
}

export default DocumentCheckList
