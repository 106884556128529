import React,{useState} from "react";
import { Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box } from "@mui/system";

import { getUser,getUserId,getSessionId,USER_ID,SESSION_ID } from "../../Controller/localStorageHandler";

import axios from 'axios';
import { withRouter, useHistory } from 'react-router-dom'

const CountryCard = ({ Data }) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [degreeType,setDegreeType] = useState(getUser().degree_looking_for ? getUser().degree_looking_for : '')


  const move = ''
  const heading = ''


  const getUniversityList = async(country,program) =>{

    const formData = new FormData()
    // formData.append('program', '')
    formData.append(USER_ID, getUserId())
    formData.append(SESSION_ID, getSessionId())
    formData.append('page', 1)
    formData.append('call_frm', 'website')
    formData.append('operation', 'profileEvaluationDetails_new')
    formData.append('related_programs', program)
    formData.append('country', country)
    formData.append('degree_type', degreeType)
    formData.append('bypass', 'validation')
    formData.append('lowrange', '')

    await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
    .then(response => {
      console.log(response)
      if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
   
          history.push({
              pathname: '/UniversitySearchResult',
              state: {
                  data: response.data, screen: move ? heading : 'profileEvaluationDetails',
                  page: JSON.stringify(Object.fromEntries(formData)),
                  programValue: program,
                  countryValue: country,
                  tabName: degreeType
              }
          })
    
      } else {
          alert(response.data.msg || response.data.message)
      }
  })
  .finally(() => {
      setLoading(false)
  })
  }
  if (loading) {
    return (<div className="gooey">
        <span className="dot"></span>
        <div className="dots">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>)
} else {

  return (
    <Box sx={{
      bgcolor: '#fff',
      borderRadius: 3,
      pt: 2,
      pb: 2,
      pr: 2,
      pl: 2,
      mb: 2,
      boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)",
      width: "100%",
      marginRight: 2,
      position: "relative"
    }}
    >
      <Typography sx={{ fontWeight: "700", mb: 2 }}>
        {Data?.program || ''} - <span className={classes.topnum}>{Data?.country?.length || 0}</span>
      </Typography>
      <Grid xs={12} container>
        {
          Data?.country?.length > 0 && Data?.country?.map((d) => {
            return <Grid xs={4} onClick={()=>getUniversityList(d?.country_name,Data?.program)}>
              <img src={`${process.env.PUBLIC_URL}/img/DashBoard/Countries/${d?.country_name || ''}.png`} alt='unitedState' className={classes.IconImg} />
              <span className={classes.texts} style={{ paddingLeft: 10 }}>
                <span style={{ fontSize: 15 }}>{d?.country_name || ''} <span style={{ fontWeight: "bold" }} className={classes.numbers}>{d?.count || ''}</span></span>
              </span>
            </Grid>
          })
        }
      </Grid>

      <div style={{ position: "absolute", right: 10, bottom: 10 }}>
        <Button variant="contained" sx={{ padding: "8px 14", borderRadius: 5, fontWeight: "100", fontSize: "12px", textTransform: "none" }}>more</Button>
      </div>

    </Box>
    // </Grid>
  );
      }
};
export default CountryCard;

const useStyles = makeStyles(() => ({
  IconImg: {
    width: "16px",
    marginTop: "10px",
    height: "16px",
  },
  texts: {
    color: "#000",
    fontSize: "12px",
    lineHeight: "30px",
    marginLeft: "2px",
  },
  numbers: {
    color: "#1b6cfc",
    fontWeight: "500",
    marginRight: "5%",
  },
  btn: {
    color: "#fff",
    backgroundColor: "#1b6cfc",
    fontSize: "16px",
    padding: "5px 20px",
    borderRadius: "20px",
    border: "none",
    marginTop: "10px",
    fontWeight: "100",
  },
  topnum: {
    color: "#1b6cfc",
  },
}));
