import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, TextField, Typography } from "@mui/material";
import { getUser } from '../../Controller/localStorageHandler';
import moment from 'moment';

export const Agreement = () => {
    let Data = getUser()

    return <div>

        <Accordion style={{ borderRadius: 25, paddingTop: 10 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: "#1B6CFC", color: "white", fontWeight: "bold", borderRadius: 25 }}
            >
                <Typography>  Terms & Conditions </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Brainstorm's relationship with you in relation to this website. </p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>The term ‘Brainstrom Consulting Pvt.Ltd' or 'us' or 'we' refers to the owner of the website whose registered office is #409/1, 2nd Floor, Golden Eye, Opp HDFC Bank, R.T. Nagar, Bangalore - 560032 Our company registration number is U18101KA2007PTC041861 –Bangalore. The term 'you' refers to the user or viewer of our website.</p>
                <b style={{ textAlign: "justify", fontSize: 12 }}>The use of this website is subject to the following terms of use:</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements. This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions. All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website. Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence. From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s). You may not create a link to this website from another website or document without Brainstrom Consulting Pvt.Ltd,'s prior written consent. Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</p>
            </AccordionDetails>
        </Accordion>

        <Accordion style={{ borderRadius: 25, paddingTop: 10 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: "#1B6CFC", color: "white", fontWeight: "bold", borderRadius: 25 }}
            >
                <Typography>  Privacy Policy  </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ textAlign: "justify", fontSize: 12 }}>This privacy policy sets out how Brainstorm Consulting Pvt Ltd. uses and protects any information that you give when you use this website.  </p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Brainstorm Consulting Pvt. Ltd. is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
                <b style={{ textAlign: "justify", fontSize: 12 }}>Brainstorm Consulting Pvt. Ltd. may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</b>
                <b>What we collect</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Name and job title, Mobile, Test Scores, Annual Income, Personla Choice of Univeristies, Program</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Contact information including email address, Mobile Number</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Demographic information such as postcode, preferences and interests</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Other information relevant to customer surveys and/or offers</p>
                <b>What we do with the information we gather</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Internal record keeping</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We may use the information to improve our products and services.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We may use this information to sell our products &amp; services to you which you can buy at your own judgement.</p>
                <b>Security</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
                <b>How we use cookies</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                <b>Links to other websites</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
                <b>Controlling your personal information</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>information to be used by anybody for direct marketing purposes</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at email address</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to #409/1, 2nd Floor, Golden Eye, Opp HDFC Bank, R.T. Nagar, Bangalore - 560032.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion style={{ borderRadius: 25, paddingTop: 10 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: "#1B6CFC", color: "white", fontWeight: "bold", borderRadius: 25 }}
            >
                <Typography> Refund Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Fees once paid is non-refundable, non-transfarable and non-adjustable under any circumstances. No installment scheme is allowed. We can stop all services if the fee is not paid within 15 days from enrollment. </p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>If opted for Admission counselling services, fees should be paid within 15 days</p>
            </AccordionDetails>
        </Accordion>
        <Accordion style={{ borderRadius: 25, paddingTop: 10 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: "#1B6CFC", color: "white", fontWeight: "bold", borderRadius: 25 }}
            >
                <Typography> Cookies Policy </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Please read this cookie policy carefully before using [ISHVI] Mobile App operated by Brainstorm Consulting.</p>
                <b>What are cookies?</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Cookies are simple text files that are stored on your computer or mobile device by a website’s server. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.</p>
                <b>What types of cookies do we use?</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Necessary cookies</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Functionality cookies</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Functionality cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Analytical cookies</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the website.</p>
                <b>Contacting us</b>
                <p style={{ textAlign: "justify", fontSize: 12 }}>If you have any questions about this policy or our use of cookies, please contact us.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion style={{ borderRadius: 25, paddingTop: 10 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: "#1B6CFC", color: "white", fontWeight: "bold", borderRadius: 25 }}
            >
                <Typography> Trade Mark Registrations  </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
            </AccordionDetails>
        </Accordion>

        {
            Data ? 
        <Accordion style={{ borderRadius: 25, paddingTop: 10 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: "#1B6CFC", color: "white", fontWeight: "bold", borderRadius: 25 }}
            >
                <Typography> Agreements</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ textAlign: "justify", fontSize: 12 }}>You agree to our below conditions based on you signup/enrollment on {moment(Data.support_start_date).format("MMM Do YY")}</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>by  and between :-</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>M/S BRAINSTORM CONSULTING PRIVATE LIMTED, a body incorporated under Indian Companies act having its registered office at 409/1, 2nd Floor, Above HDFC Bank, R T Nagar, Bangalore 560032 by its  Director  Y V S Rao S/o Y Venkateswarulu, Hindu aged 33 years.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Hereinafter called the Party of the First Part,</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>And M/S XXXXXX  represented by XXXXXX, S/O __________, aged about ____ Years , R/O ______________</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Here in after called Party of the Second part.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>The term Party of the first part will be referred as “Company” at relevant places wherever it denotes; similarly the party of the second part is referred as “Freelance Agent or Agent” wherever it denotes, at relevant places in this MOU.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Whereas the Company M/S BRAINSTORM CONSULTING PRIVATE LIMTED is rendering counseling and consultancy   guidance to students aspiring to pursue higher education in USA and other countries through branches in several places in India and abroad. As part of its initiative to expand its services and operations to various parts of the country the company has decided to employ freelance agents, for which purpose the company and freelance agent have come to an understanding, discussed the modalities for rendering services to the students.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>The broad modalities and the arrangements have been discussed where under both the company and agent have agreed to act, on the terms and conditions mutually agreed upon both, on the developmental purposes.</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>Now this MOU witnesses as follows:</p>
                <p style={{ textAlign: "justify", fontSize: 12 }}>The company agrees to process applications received from the agent, for students to study in USA / UK / Australia / Canada as per following terms and conditions.</p>
                <ol>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>Please understand that for the knowledge of the student, Agent is processing the application himself and not by any third party.  The process and the result are agent’s total responsibility.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>The agent shall counsel the student, collect and forward a copy of student application form duly filled-up with all details by the student. The format for the basic information to be filled is attached as Appendix ‘A’ to this MOU.  This form, along with one set of documents is to be sent with a covering letter, bringing out any other point which is discussed with the students like preferences for specialization, location, etc.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>The company will select one or more universities depending on the student’s eligibility.  The company will discuss about the basis of university selection at this stage to you. In turn, you can inform the same to the student.  He can choose any one of the universities mentioned in the list and send it back to you.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>If the selection is acceptable to the student, the agent will collect all relevant documents as specified by us and forward the same to us along with a non-refundable fee of Rs.1500/- (Rupees Two thousand only) including Service Tax  in case of USA, Canada, Sweden, Germany, Italy, and Dubai Universities.  No such fee is required to be paid for the students applying to UK, New Zealand and Australia.  Any application fee is also to be collected from the student, if required and courier charges.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>For any of the universities applied by BSC, the follow up with the universities will be done by BSC only.  The student should not contact the university directly.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>The application fee is liable for change by universities without notice. In cases where BSC is not charging the agent for application fee, the agent should not collect any amount from the student in the name of application fee.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>If the student intends to apply for more than one university, for each university, he / she has to pay an additional Rs.1500/- and application fee wherever it is applicable.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>In all cases of US Universities where ETS reporting is required, the student has to do the same on his own. BSC will inform the agent of any such requirements. In some cases the universities after sending the acceptance letter, insist upon paying a ‘Pre paid tuition deposit’ which is a fraction of the first semester fee and is non refundable. This amount is reduced from fee when the student reports to the university. The student will not receive his I-20 unless this deposit is paid. The same is to be explained to the student by the agent. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>This business offer is applicable if and only if the student applies for the universities mentioned in Appendix ‘B’ and is subject to eligibility to one of these universities.  Incentives which are applicable on successful reporting of the students to the respective universities has also been mentioned in Appendix ‘B’.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>An additional list of universities for students desirous of going to UK and Australia is attached as Appendix ‘C, the incentive will be shared as mentioned in the attachment.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>The company will file the application only when complete set of documents, as required are submitted, along with application fee if any. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>The company will follow up with the University and obtain I-20 and forward it to you only.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>This incentive will be paid by the Universities only upon student reporting to University / College and pays up the first semester tuition fee, which will take approximately 3-4 months.  This incentive in turn will be paid to you once the company receives the same from the University / college, which may take further two months time. These incentives are subject to Service Tax and the balance amount is paid as specified in the attachments after the deduction of TDS. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>Please counsel the student that it takes a minimum three months period for US universities to take a decision on the file.  In the intermediate period, if the company receives any updates on the student’s application, the same will be intimated to the agent on a regular basis.</p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>In case of Australia and UK universities, unconditional / conditional offer letter is expected to be handed over to the agent within a minimum period of two weeks to a maximum period of four weeks time. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>After the student successfully obtains the I-20 / admission letter, it is the agent’s responsibility to guide the student for visa purposes.  However, any guidance to the agent on this aspect will be provided by the company , free of cost </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}>Please note that at no stage, the company directly involves with the student and company is not responsible for any omission or commission done by the agent towards the student or to any third party whatsoever in this matter. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}> The company is not responsible for the authenticity of the documents submitted by the students forwarded by you for admission as well as at visa stage and the student is only responsible for any consequences that may arise due to non-genuine documents. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}> Though all efforts would be put from the company’s side to obtain the admission / acceptance of the application, it does not necessarily mean that it is a guaranteed admission. </p></li>
                    <li> <p style={{ textAlign: "justify", fontSize: 12 }}> No refund of service charge and application fee, in case of denial of application for admission and visa. </p></li>
                </ol>
                <p style={{ textAlign: "justify", fontSize: 12 }}>By using you app I agree your terms and conditions .</p>
            </AccordionDetails>
        </Accordion>
        :
        ''
}

    </div>
}