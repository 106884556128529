import React from 'react';

// import BGImg from "../../img/bgImg.jpg";
import "../Common/CSS/Common.css";

import SelectBox from "../Common/Forms/Select";
// import ButtonBox from "../Common/Forms/Button"

const LowTutionFee = (props) => {
    return (
        <>
            <div className="row m-0 mt-2">
                <div className="col-12 gradientBack text-center positionUpRel p-0">
                    {/* <img src={BGImg} height="100%" alt="" /> */}
                    <p className="mb-0 mt-4 formHeader">Low Tution Fee</p>
                </div>
            </div>
            
            <div className="positionUpAb p-4">
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Country" />
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Degree"/>
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Programm"/>
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Tution Fee"/>
                    {/* <ButtonBox height="50px" caption="Search" /> */}
                    <button className="submitBtn ltfButton mt-4">Search</button>
            </div>
        </>
    )
}

export default LowTutionFee;