import React,{useContext,useReducer,useEffect,createContext,useState} from 'react'
import userReducer,{initialState} from './userReducer'
import { getUser,getSessionId,getUserId,getUserType, SESSION_ID, USER_ID, clearUserData } from '../Controller/localStorageHandler';
import { getCurrencySymbol, OPERATION, operationValue } from '../Controller/Global';
import { CommonEmailSendAction, fetchMachingUniversitiesAction, fetchPossibleScholarshipsAction, fetchRelaventSopAction, fetchApplicationStatusAction, fetchRelatedProgramAction, fetchEventListAction, fetchServiceListAction, fetchStudentUploadDocumentAction } from '../components/Action';
import axios from 'axios'
import _ from "lodash"
import { useHistory } from 'react-router-dom'
import {getStudentCountListUsingStatusAction} from "../components/Action/AssociateDocumentList"


const userContext = createContext(initialState)


export const AppProvider = ({children}) => {
    const history = useHistory()
    const [state, dispatch] = useReducer(userReducer, initialState)
    const [backdrop,setBackdrop] = useState(false)
    const [sessionInvalid,setSessionInvalid] = useState(false)
    const [loading,setLoading] = useState(false)


    const getUserContext = () =>{

        dispatch({
            type:'Get_user',
            payload: {
                user: getUser()
            }})
    }

    const getFooterValues = () =>{
        dispatch({type:'Footer_values'})
    }

    // get favourite university list
    const getFavouriteUniversities = (addonConditionType, moduleType, propUser,force_refresh) =>{
        if(state?.favourite_universities?.length === 0 || force_refresh === true){
            setLoading(true)
            const Data = JSON.parse(propUser)
            const Favourite = new FormData()
            Favourite.append(OPERATION, operationValue.Favorites)
            Favourite.append(SESSION_ID, getSessionId())
            Favourite.append(USER_ID, getUserId())
            Favourite.append('userType',getUserType())
            // Favourite.append('moduleType', `${moduleType}`)
            Favourite.append('moduleType', 'Universities')
            Favourite.append('addonConditionType', `${addonConditionType}`)
            if (Data?.contactid) {
                Favourite.append('contactid', `${Data?.contactid}`)
            }
         
            axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', Favourite).then((response) => {
                // console.log(response)
                if (response.data.status === "false") {
                    if(response.data.msg === "Session Invalid"){
                        setSessionInvalid(true)
                    }
                    dispatch({
                        type:'favourite_universities',
                        payload: {
                            favourite_universities: []
                        }})
                    setLoading(false)
                } else {
                    dispatch({
                        type:'favourite_universities',
                        payload: {
                            favourite_universities: response.data
                        }})
                    setLoading(false)
                }
            }).catch((err) => {
                setLoading(false)
            })
        }
       
    }

    // get partnered university list
    const getParteneredUniversities = (activeDgree,moduleType,propUser, force_refresh) => {
        // console.log(state?.partenered_universities?.length)
        if(state?.partenered_universities?.length === 0 || force_refresh === true){ 
            setLoading(true)
            const Data = JSON.parse(propUser)
            // console.log("User Data",Data)
       
            if (Data && Data?.country && Data?.related_programs && Data?.degree_looking_for && Data?.semester_looking_for) {
                let programData = _.map(Data?.related_programs?.split("|##|"), (d) => d.trim())
                var formdata = new FormData();
                programData.map((d, i) => {
                    formdata.append(`program[${i}]`, d);
                })
                formdata.append('operation', 'profileEvaluationDetails_new');
                formdata.append('country', Data?.country);
          
                formdata.append('degree_type', Data?.degree_looking_for);
                formdata.append('semester', Data?.semester_looking_for);
                formdata.append('selpage', '1');
                formdata.append('page_no', '1');
                formdata.append('page', '1');
                formdata.append('inter_adm_deadline', ' ');
                formdata.append('filter_apply', ' ');
                formdata.append('sort_name', ' ');
                formdata.append('Sort', 'Order by cast(`uni_rank` as decimal(13,2))');
                formdata.append('stdfrm', '1');
                formdata.append('call_frm', 'website');
                formdata.append(USER_ID, propUser?.contactid || getUserId())
                formdata.append('Rank', '0.00,1000.00');
                formdata.append('Tution_Fee', '0.00,100000.00');
                formdata.append('partner_condition','partner');
                formdata.append('addonConditionType', activeDgree);
                formdata.append('pages','all')
                
                
                fetchMachingUniversitiesAction(formdata).then((data) => {
                    if (data?.status === "false") {
                        dispatch({
                            type:'partenered_universities',
                            payload: {
                                partenered_universities: []
                            }})
                            setLoading(false)
                    }
                    else{
                        dispatch({
                            type:'partenered_universities',
                            payload: {
                                partenered_universities: data
                            }})
                            setLoading(false)
                    }
                }).catch((error) => {
                    console.log("error", error)
                    setLoading(false)
                })
            } else {
                dispatch({
                    type:'partenered_universities',
                    payload: {
                        partenered_universities: []
                    }})
                    setLoading(false)
            }
        }
    }

    // get matching universities
    const getMatchingUniversities = (activeDgree,moduleType,propUser,force_refresh) =>{
        if(state?.matching_universities?.length === 0 || force_refresh === true){
            const Data = JSON.parse(propUser)

            if (Data && Data?.country && Data?.related_programs && Data?.degree_looking_for && Data?.semester_looking_for) {
                setLoading(true)
                let programData = _.map(Data?.related_programs?.split("|##|"), (d) => d.trim())
                var formdata = new FormData();
                programData.map((d, i) => {
                    formdata.append(`program[${i}]`, d);
                })
                formdata.append('operation', 'profileEvaluationDetails_new');
                formdata.append('country', Data?.country);
            
                formdata.append('degree_type', Data?.degree_looking_for);
                formdata.append('semester', Data?.semester_looking_for);
                formdata.append('selpage', '1');
                formdata.append('page_no', '1');
                formdata.append('page', '1');
                formdata.append('inter_adm_deadline', ' ');
                formdata.append('filter_apply', ' ');
                formdata.append('sort_name', ' ');
                formdata.append('Sort', 'Order by cast(`uni_rank` as decimal(13,2))');
                formdata.append('stdfrm', '1');
                formdata.append('call_frm', 'website');
                formdata.append(USER_ID, propUser?.contactid || getUserId())
                formdata.append('Rank', '0.00,1000.00');
                formdata.append('Tution_Fee', '0.00,100000.00');
                formdata.append('addonConditionType', activeDgree);
                formdata.append('pages','all')
                fetchMachingUniversitiesAction(formdata).then((data) => {
                    if (data?.status === "false") {
                        dispatch({
                            type:'matching_universities',
                            payload: {
                                matching_universities: []
                            }})
                        setLoading(false)
                    }
                    else{
                        dispatch({
                            type:'matching_universities',
                            payload: {
                                matching_universities: data
                            }})
                            setLoading(false)
                    }
                    
                }).catch((error) => {
                    setLoading(false)
                })
            } else {
                dispatch({
                    type:'matching_universities',
                    payload: {
                        matching_universities: []
                    }})
                    setLoading(false)
            }
        }
    }

    // get application status
    const getApplicationStatus = (confirmstatus,force_refresh) =>{
        if(state?.application_status_student?.length === 0 || force_refresh === true){
            setLoading(true)
            var formdata = new FormData();
            formdata.append(OPERATION, operationValue.Applicationstatus)
            formdata.append(SESSION_ID, getSessionId())
            formdata.append(USER_ID, getUserId())
            formdata.append('confirmstatus', confirmstatus);
    
            fetchApplicationStatusAction(formdata).then((data) => {
            
                dispatch({
                    type:'application_status_student',
                    payload: {
                        application_status_student: data
                    }})
                    setLoading(false)
                // setFetchApplicationData(data?.data || [])
            }).catch((error) => {
                dispatch({
                    type:'application_status_student',
                    payload: {
                        application_status_student: []
                    }})
                    setLoading(false)
            })
        }
    }

    // get possible scholarships
    const getPossibleScholarShips = ()=>{
        if(state?.possible_scholarship_list?.length === 0){
            setLoading(true)
        var formdata = new FormData();
        formdata.append(OPERATION, operationValue.FetchScholarshipResult)
        formdata.append(SESSION_ID, getSessionId())
        formdata.append(USER_ID, getUserId())
        formdata.append('call_frm', 'website');
        formdata.append('Sort', '');
        formdata.append('degreeType', 'Masters');
        formdata.append('country', 'USA');
        formdata.append('program', 'Data Science');
        formdata.append('page', '1');
        formdata.append('stdfrm', '1');
        fetchPossibleScholarshipsAction(formdata)
            .then((data) => {
                dispatch({
                    type:'possible_scholarship_list',
                    payload: {
                        possible_scholarship_list: data
                    }})
                    setLoading(false)

            }).catch((error) => {
                dispatch({
                    type:'possible_scholarship_list',
                    payload: {
                        possible_scholarship_list: []
                    }})
                    setLoading(false)
            })
        }
    }

    // get SOP statement of purpose
    const getSop = () =>{
        if(state?.sop_list?.length === 0){
            setLoading(true)
        var formdata = new FormData();
        formdata.append(OPERATION, operationValue.FetchSOPwithoutUniversity)
        formdata.append(SESSION_ID, getSessionId())
        formdata.append(USER_ID, getUserId())
        formdata.append("operation", "fetchSOPwithoutUniversity");
        formdata.append("call_frm", "website");
        formdata.append("degreeType", "Masters");
        formdata.append("program", "'MBA'");
        formdata.append("page", "1");

        fetchRelaventSopAction(formdata)
            .then((data) => {
                dispatch({
                    type:'sop_list',
                    payload: {
                        sop_list: data
                    }})
                    setLoading(false)
            }).catch((error) => {
                dispatch({
                    type:'sop_list',
                    payload: {
                        sop_list: []
                    }})
                    setLoading(false)
            })
        }
    }

     // get event list
     const getEventList = (force_refresh) =>{
        if(state?.event_list?.length === 0 || force_refresh === true){
            setLoading(true)
            let formData = new FormData()
            formData.append("operation", "get_event_list")
            formData.append("campaigntype", "Spot Admissions")
            formData.append("userid", getUserId())
            formData.append("sessionid", getSessionId())
    
            fetchEventListAction(formData).then((response) => {
                // setEventListData(response)
                dispatch({
                    type:'event_list',
                    payload: {
                        event_list: response
                    }})
                    setLoading(false)
            }).catch((error) => {
                dispatch({
                    type:'event_list',
                    payload: {
                        event_list: []
                    }})
                    setLoading(false)
            })
        }
    }

    // get picklist values
    // get related programs picklist
    const getRelatedProgramsPickList = () =>{
        // console.log(state?.related_programs_picklist?.length)
        if(state?.related_programs_picklist?.length === 0){
            // console.log('working')
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'related_programs');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'related_programs_picklist',
                payload: {
                    related_programs_picklist: res?.data?.data
                }})
        });
        }
    }

    // get country picklist

    const getCountryPickList = () =>{
        if(state?.country_picklist?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'country');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'country_picklist',
                payload: {
                    country_picklist: res?.data?.data
                }})
        });
        }
    }

    // get semester picklist

    const getSemesterPickList = () =>{
        if(state?.semester_picklist?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'semester');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'semester_picklist',
                payload: {
                    semester_picklist: res?.data?.data
                }})
        });
        }
    }

    // get year picklist
    const getYearPickList = () =>{
        if(state?.year_picklist?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'year');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'year_picklist',
                payload: {
                    year_picklist: res?.data?.data
                }})
        });
        }
    }

    //  get leadstatus picklist
    const getLeadstatusPicklist = () =>{
        if(state?.leadstatus_picklist?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'leadstatus');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'leadstatus_picklist',
                payload: {
                    leadstatus_picklist: res?.data?.data
                }})
        });
        }

    }

    //  get bachelors college name picklist
    const getBachelorsCollegeNamePicklist = () =>{
        if(state?.bachelors_college_name?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'bachelors_college_name');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'bachelors_college_name',
                payload: {
                    bachelors_college_name: res?.data?.data
                }})
        });
        }

    }

    //  get bachelors year of passing picklist
    const getBachelorsYearOfPassingPicklist = () =>{
        if(state?.bachelors_year_of_passing?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'bachelors_year_of_passing');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'bachelors_year_of_passing',
                payload: {
                    bachelors_year_of_passing: res?.data?.data
                }})
        });
        }

    }


    // get puc_college_name_12th picklist
    const getPucCollegeNamePicklist = () =>{
        if(state?.puc_college_name_12th?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'puc_college_name_12th');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'puc_college_name_12th',
                payload: {
                    puc_college_name_12th: res?.data?.data
                }})
        });
        }

    }

    // get puc_year_of_passing_12th picklist
    const getPucYearOfPassingPicklist = () =>{
        if(state?.puc_year_of_passing_12th?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'puc_year_of_passing_12th');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'puc_year_of_passing_12th',
                payload: {
                    puc_year_of_passing_12th: res?.data?.data
                }})
        });
        }

    }

    // get german_language picklist
    const getGermanLanguagePicklist = () =>{
        if(state?.german_language?.length === 0){
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'german_language');
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
            dispatch({
                type:'german_language',
                payload: {
                    german_language: res?.data?.data
                }})
        });
        }

    }

    // get leadsource picklist
    const getLeadSourcePicklist = () =>{
        if(state?.leadsource_picklist?.length === 0){
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'leadsource');
            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
                dispatch({
                    type:'leadsource_picklist',
                    payload: {
                        leadsource_picklist: res?.data?.data
                    }})
            });
            }
    }


    // get ConvertBy picklist
    const getConvertByPicklist = () =>{
        if(state?.convertby_picklist?.length === 0){
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'convert_by');
            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params).then((res)=>{
                dispatch({
                    type:'convertby_picklist',
                    payload: {
                        convertby_picklist: res?.data?.data
                    }})
            });
            }
    }
 
    //get required documents
    const getDocuments = () =>{
        if(state?.doc_list?.length === 0){
            let ProfileData = getUser()
            let Data = ProfileData?.documents_required ? ProfileData.documents_required.split(" |##| ") : []
            dispatch({
                type:'doc_list',
                payload: {
                    doc_list: Data
                }})
        }
    }

    // get uploaded documents
    const GetUploadDocument = () => {
        if(state?.upload_doc_list?.length === 0){
            var data = `{\n"contact_id":"${getUserId()}","bypass":"validation"\n}`;
            fetchStudentUploadDocumentAction(data).then((data1) => {
                console.log(data1)
                dispatch({
                    type:'upload_doc_list',
                    payload: {
                        upload_doc_list: data1
                    }})
            }).catch((error) => {
                console.log("error", error)
                dispatch({
                    type:'upload_doc_list',
                    payload: {
                        upload_doc_list: []
                    }})
            })
        }
    }

    // get mentor list
    const getMentorList = () =>{
        if(state?.mentor_list?.length === 0){
            setLoading(true)
            var data = JSON.stringify({
                "contact_no": 'CON75714',
                "status": 'All',
                "year": 'All',
                "semester": 'All',
                "daterange": "",
                "limit_start":  '0',
                "limit_end": 9,
                "search": '',
                "bypass": "validation",
                "user_contact_type":"Employee"
            });
            getStudentCountListUsingStatusAction(data).then((Data) => {
               
                dispatch({
                    type:'mentor_list',
                    payload: {
                        mentor_list: Data?.data
                    }})
                setLoading(false)
                // setMentorList(Data?.data || [])
                // console.log(Data)
             
            }).catch((error) => {
                dispatch({
                    type:'mentor_list',
                    payload: {
                        mentor_list: []
                    }})
                setLoading(false)
            })
        }
    }

    // get service list
    const getServiceList = () =>{
        if(state?.service_list?.length === 0){
            setLoading(true)
            fetchServiceListAction().then((data) => {
                dispatch({
                    type:'service_list',
                    payload: {
                        service_list: data?.data
                    }})
                setLoading(false)
            }).catch((error) => {
                dispatch({
                    type:'service_list',
                    payload: {
                        service_list: []
                    }})
                setLoading(false)
            })
        }
    }
    

    const value = {
        loading,
        setLoading,
        sessionInvalid,
        setSessionInvalid,
        getUserContext,
        user: state.user,
        backdrop,
        getDocuments,
        doc_list: state?.doc_list,
        GetUploadDocument,
        upload_doc_list: state?.upload_doc_list,
        getFavouriteUniversities,
        favourite_universities: state.favourite_universities,
        getParteneredUniversities,
        partenered_universities: state.partenered_universities,
        getMatchingUniversities,
        matching_universities: state.matching_universities,
        getApplicationStatus,
        application_status_student: state.application_status_student,
        getPossibleScholarShips,
        possible_scholarship_list: state.possible_scholarship_list,
        getSop,
        sop_list: state.sop_list,
        getEventList,
        event_list: state.event_list,
        getRelatedProgramsPickList,
        related_programs_picklist: state.related_programs_picklist,
        getCountryPickList,
        country_picklist: state.country_picklist,
        getSemesterPickList,
        semester_picklist: state.semester_picklist,
        getYearPickList,
        year_picklist: state.year_picklist,
        getLeadstatusPicklist,
        leadstatus_picklist: state.leadstatus_picklist,
        getBachelorsCollegeNamePicklist,
        bachelors_college_name: state.bachelors_college_name,
        getBachelorsYearOfPassingPicklist,
        bachelors_year_of_passing: state.bachelors_year_of_passing,
        getPucCollegeNamePicklist,
        puc_college_name_12th: state.puc_college_name_12th,
        getPucYearOfPassingPicklist,
        puc_year_of_passing_12th: state.puc_year_of_passing_12th,
        getGermanLanguagePicklist,
        german_language: state.german_language,
        getLeadSourcePicklist,
        leadsource_picklist: state.leadsource_picklist,
        getConvertByPicklist,
        convertby_picklist: state.convertby_picklist,

        getMentorList,
        mentor_list: state.mentor_list,
        getServiceList,
        service_list: state.service_list,
    }
    return <userContext.Provider value={value}>{children}</userContext.Provider>
}

const useAppContext = () =>{
    const context = useContext(userContext)

    if(context === undefined) {
        throw new Error("useAppContext must be used within userContext")
    }
    
    return context
}

export default useAppContext;