import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Chat from './Chat';
import { useHistory, useParams } from 'react-router-dom';
import { getStudentCountListUsingStatusAction, GetRegisterCountDataAction, getStudentCountListUsingStatusAction1, GetUniversityProgramStudents, getEducationLoanStudents, GetEventJoinedListAction } from '../../Action/AssociateDocumentList';
import { getUser, getUserType, getSessionId, getUserId, setCookie_type, getCookie_type, setCookie_status, getCookie_status, setShowType, getShowType } from '../../../Controller/localStorageHandler';
import { Box } from '@mui/system';
import { fetchComoonPickerListAction } from '../../Action';
import { AddStudentModal } from './AddStudentModal';
import { CreateNotification } from './CreateNotification';
import { AsyncMulti } from '../../StudentSearch';

import axios from "axios";

import { DateRangePicker } from "mui-daterange-picker";

import useAppContext from "../../../useContext/userContext"
import Checkbox from '@mui/material/Checkbox';

import ReactExport from "react-export-excel";
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const Header = () => {
    const history = useHistory();
    const classes = useStyles();

    const {
        getRelatedProgramsPickList, related_programs_picklist,
        getCountryPickList, country_picklist,
        getSemesterPickList, semester_picklist,
        getYearPickList, year_picklist,
        getLeadstatusPicklist, leadstatus_picklist,
        getLeadSourcePicklist, leadsource_picklist,
        getConvertByPicklist,convertby_picklist,
    } = useAppContext()

    const { id } = useParams()
    const [StudentList, SetStudentList] = useState([])
    const [allStudentList, SetallStudentList] = useState([])
    const [loading, Setloading] = useState(false)
    const [page, setPage] = React.useState(1)
    const [selectedSemester, SetSelectedSemester] = React.useState('All')
    const [selectedYear, SetSelectedYear] = React.useState('All')
    const [selectedLeadsource, setSelectedLeadsource] = React.useState('')
    const [selectedLeadstatus, setSelectedLeadstatus] = React.useState('')
    const [convertBy,setConvertBy] = React.useState('')
    const [open, SetOpen] = React.useState(false)
    const [search, SetSearch] = React.useState('')
    const [SearchSelectId, setSearchSelectId] = React.useState(null)
    const [Student, setStudent] = React.useState(getUser())
    const [dateRange, setDateRange] = React.useState('');
    const [followup_Date_Range, setFolloupDateRange] = React.useState('')
    const [refresh, setRefresh] = React.useState(false);

    const [openDate, setOpenDate] = React.useState(false);

    const [openFollowUpDate, setOpenFollowUpDate] = React.useState(false);

    const [notificationOpen, setNotificationOpen] = React.useState(false)

    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);

    const Limit = 10

    const user_type = getUserType()

    let type
    let status
    let showType
    let link = history?.location?.state?.link || "student"



    const loadMore = (page) => {
        setPage(page)
    }


    const toggleDate = () => 
    (!openDate);

    const newRange = (data) => {
        var newDateRange = []
        newDateRange.push(data['startDate'])
        newDateRange.push(data['endDate'])
        setDateRange(newDateRange)
        // console.log(newDateRange)
    }

    const followupDateRange = (data) => {
        var newDateRange = []
        newDateRange.push(data['startDate'])
        newDateRange.push(data['endDate'])
        setFolloupDateRange(newDateRange)
    }



    const GetStudentData = (contact_no) => {

        console.log(showType)

        let ProfileData = getUser()
        if (type === "Student") {
            var data = JSON.stringify({
                "contact_no": ProfileData?.contact_no || null,
                "status": status,
                "showType": showType,
                "year": selectedYear ? selectedYear : 'All',
                "semester": selectedSemester ? selectedSemester : 'All',
                "daterange": dateRange,
                "followup_Date_Range": followup_Date_Range,
                "limit_start": Limit * (page - 1),
                "limit_end": Limit,
                "search": contact_no ? contact_no : search,
                "bypass": "validation",
                "leadsource": selectedLeadsource,
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            getStudentCountListUsingStatusAction(data).then((Data) => {
                console.log(Data)
                Setloading(false)
                SetStudentList(Data?.data || [])
                SetallStudentList(Data?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        } else if (type === "Register") {
            var data = JSON.stringify({
                "bypass": "validation",
                "contact_no": ProfileData?.contact_no || null,
                "filtersearch": '',
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "search": contact_no ? contact_no : search,
                "semester": `${selectedSemester ? selectedSemester : 'All'}`,
                "status": `${status ? status : 'All'}`,
                "year": `${selectedYear ? selectedYear : 'All'}`,
                "daterange": dateRange,
                "followup_Date_Range": followup_Date_Range,
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetRegisterCountDataAction(data).then((res) => {
                console.log(res)
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        } else if (type === "Application") {
            var data = JSON.stringify({
                "bypass": "validation",
                "contact_no": ProfileData?.contact_no || null,
                "filtersearch": ``,
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "search": contact_no ? contact_no : search,
                "semester": `${selectedSemester ? selectedSemester : 'All'}`,
                "status": "All",
                "year": `${selectedYear ? selectedYear : 'All'}`,
                "project_task_sts": `${status}`,
                "daterange": dateRange,
                "followup_Date_Range": followup_Date_Range,
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`,
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetRegisterCountDataAction(data).then((res) => {
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        }
        else if (type === 'University Program Bookmarked') {
            var data = JSON.stringify({
                "type": "bookmarked",
                "bypass": "validation",
                "university_id": history?.location?.state?.val?.universitiesid || null,
                "filtersearch": ``,
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetUniversityProgramStudents(data).then((res) => {
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })

        }
        else if (type === "University Program Applied") {
            var data = JSON.stringify({
                "type": "applied",
                "bypass": "validation",
                "university_id": history?.location?.state?.val?.universitiesid || null,
                "filtersearch": ``,
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetUniversityProgramStudents(data).then((res) => {
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })

        }
        else if (type === "University Program Enrolled") {

            var data = JSON.stringify({
                "type": "enrolled",
                "bypass": "validation",
                "university_id": history?.location?.state?.val?.universitiesid || null,
                "filtersearch": ``,
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetUniversityProgramStudents(data).then((res) => {
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        }
        else if (type === "University Program Admitted") {
            var data = JSON.stringify({
                "type": "admitted",
                "bypass": "validation",
                "university_id": history?.location?.state?.val?.universitiesid || null,
                "filtersearch": ``,
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetUniversityProgramStudents(data).then((res) => {
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        }
        else if (type === "EducationLoan") {
            let userId = getUserId()
            let sessionId = getSessionId()
            var data = JSON.stringify({
                "sessionid": sessionId,
                "userid": userId,
                "contact_no": ProfileData?.contact_no || null,
                "status": status,
                "year": selectedYear ? selectedYear : 'All',
                "semester": selectedSemester ? selectedSemester : 'All',
                "daterange": dateRange,
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "search": contact_no ? contact_no : search,
                "link": link,
                "bypass": "validation",
                "followup_Date_Range": followup_Date_Range,
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            })

            // var Data1 = `{"bypass":"validation","sessionid":"${sessionId}","userid":"${userId}","contact_no":"${ProfileData?.contact_no || null}","status":"${status}","year":"${selectedYear ? selectedYear : 'All'}","semester":"${selectedSemester ? selectedSemester : 'All'}","daterange":"${dateRange}","limit_start":"${Limit}","limit_end":"${Limit * (page - 1)}","search":"${contact_no ? contact_no : search}","link":"${link}"}`

            // var data1 = `{"contact_no":"${ProfileData?.contact_no || ''}","bypass":"validation","sessionid":"${sessionId}","userid":"${userId}"}`
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            getEducationLoanStudents(data).then((Data) => {
                // console.log(Data)
                Setloading(false)
                SetStudentList(Data?.data || [])
                SetallStudentList(Data?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        }
        else if (type === "Event") {
            var data = JSON.stringify({
                "campaignId": history?.location?.state?.campaignId || id,
                "bypass": "validation",
                "contact_no": ProfileData?.contact_no || null,
                "filtersearch": '',
                "limit_end": Limit,
                "limit_start": Limit * (page - 1),
                "priority": "All",
                "search": contact_no ? contact_no : search,
                "semester": `${selectedSemester ? selectedSemester : 'All'}`,
                "status": `${status ? status : 'All'}`,
                "year": `${selectedYear ? selectedYear : 'All'}`,
                "daterange": dateRange,
                "followup_Date_Range": followup_Date_Range,
                "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
                "convert_by":`${convertBy ? convertBy : 'All'}`
            });
            Setloading(true)
            SetStudentList([])
            SetallStudentList([])
            GetEventJoinedListAction(data).then((res) => {
                console.log(res)
                Setloading(false)
                SetStudentList(res?.data || [])
                SetallStudentList(res?.data || [])
            }).catch((error) => {
                Setloading(false)
            })
        }


    }


    useEffect(() => {
        if (history?.location?.state) {
            type = history?.location?.state?.type || "Student"
            status = history?.location?.state?.status || "All"
            showType = history?.location?.state?.showType || "All"
            setCookie_type(type)
            setCookie_status(status)
            setShowType(showType)
        }
        else if (getCookie_type() === null) {
            type = 'Student'
            status = 'All'
            setShowType = 'All'
        }
        else {
            type = getCookie_type()
            status = getCookie_status()
            showType = getShowType()
        }

 

        GetStudentData()
    }, [history?.location?.state?.type, history?.location?.state?.status, page, selectedSemester, selectedYear, search, dateRange, refresh, selectedLeadsource, followup_Date_Range, selectedLeadstatus,convertBy])


    const SubmitFilter = () => {

    }



    const GetSelectedData = (dataId) => {
        console.log(dataId)
        var data = JSON.stringify({
            "contact_no": "",
            "status": "All",
            "year": "All",
            "semester": "All",
            "daterange": dateRange,
            "limit_start": 0,
            "limit_end": 9,
            "search": dataId,
            "bypass": "validation",
            "followup_Date_Range": followup_Date_Range,
            "lead_status":`${selectedLeadstatus ? selectedLeadstatus : 'All'}`,
            "convert_by":`${convertBy ? convertBy : 'All'}`
        });
        SetStudentList([])
        SetallStudentList([])
        getStudentCountListUsingStatusAction1(data).then((Data) => {
            console.log(Data)
            if (Data?.data?.total > 0) {
                let contact_no = Data?.data?.list[0]
                setStudent(contact_no)
                SetStudentList(Data?.data)
                SetallStudentList(Data?.data)
                // console.log(Data?.data)
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }

    useEffect(() => {
        // GetSemester()
        getSemesterPickList()
        // GetYear()
        getYearPickList()
        // GetLeadSource()
        getLeadstatusPicklist()
        getCountryPickList()
        getRelatedProgramsPickList()
        getLeadSourcePicklist()
        getConvertByPicklist()
    }, []);

    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];

        if (checked) {
            StudentList?.list?.map((d) => {
                collection.push(d?.contactid);
            })
        }

        setCheckedList(collection);
        setItemsChecked(checked);
    };

    return (
        <div >
            <div style={{ position: "relative" }}>
                <AsyncMulti inputData={Student} setSearchSelectId={GetSelectedData} SubmitFilter={SubmitFilter} />
                {/* <h3 style={{ marginTop: 20 }}>Student List</h3> */}
            </div>
           
            <div className={`${classes.dropdownContainer} flexCenterJcB hideScrollbar`} >

                <FormControl sx={{ m: 1, minWidth: "30%" }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={({ target }) => {
                            SetSelectedSemester(target.value)
                        }}
                        size="small"
                    >
                        {semester_picklist?.semester_picklist?.length > 0 && semester_picklist?.semester_picklist?.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>

                {/* ===================================================================== */}
                <FormControl sx={{ m: 1, minWidth: "20%" }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={({ target }) => {
                            SetSelectedYear(target.value)
                        }}
                        size="small"
                    >
                        {year_picklist?.year_picklist?.length > 0 && year_picklist?.year_picklist?.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })}
                    </Select>
                </FormControl>

{/* =========================== Convert By ========================================== */}
<FormControl   sx={{ m: 1,minWidth: "30%"}}  fullWidth>
                    <InputLabel id="demo-simple-select-label">Convert by</InputLabel>
                    <Select
                        
                        label="Convert by"
                        onChange={(e) => {
                            setConvertBy(e.target.value)
                        }}
                        size="small"
                    >
                        <MenuItem value="All">All</MenuItem>
                        {convertby_picklist?.convertby_picklist?.length > 0 && convertby_picklist?.convertby_picklist?.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                {/* =========================== Lead Status ========================================== */}
                <FormControl   sx={{ m: 1,minWidth: "25%"}}  fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        
                        label="Status"
                        onChange={(e) => {
                            setSelectedLeadstatus(e.target.value)
                        }}
                        size="small"
                    >
                        <MenuItem value="All">All</MenuItem>
                        {leadstatus_picklist?.leadstatus_picklist?.length > 0 && leadstatus_picklist?.leadstatus_picklist?.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                {/* =========================== Lead Date ========================================== */}
                &nbsp;
                <span style={{ width: '100%' }}>
                    {
                        openDate
                            ?
                            <Chip sx={{ height: '40px' }} onClick={toggleDate} label="Close Lead Date" variant="outlined" color="error" />
                            // <Button onClick={toggleDate} variant="contained" color="error"></Button>
                            :
                            <Chip sx={{ height: '40px' }} onClick={toggleDate} label="Lead Date" variant="outlined" />
                        // <Button onClick={toggleDate} variant="contained">Lead Date</Button>
                    }
                </span>

                {/* =========================== folloup date ========================================== */}
                &nbsp;
                <span style={{ width: '100%', }}>
                    {
                        openFollowUpDate
                            ?
                            <Chip sx={{ height: '40px' }} label="Close Follow Up Date" onClick={() => setOpenFollowUpDate(!openFollowUpDate)} variant="outlined" color="error" />
                            :
                            <Chip sx={{ height: '40px' }} label="Follow Up Date" onClick={() => setOpenFollowUpDate(!openFollowUpDate)} variant="outlined" />
                    }
                </span>


            </div>

            <DateRangePicker
                open={openDate}
                toggle={toggleDate}
                onChange={(range) => newRange(range)}
            />

            <DateRangePicker
                open={openFollowUpDate}
                toggle={() => setOpenFollowUpDate(!openFollowUpDate)}
                onChange={(range) => followupDateRange(range)}
            />


            <div className='flexCenterJcB'>
                <main className='flexCenterJcB'>
                    {/* <CheckBoxOutlineBlankOutlinedIcon className={classes.squareIcon} /> */}
                    <Checkbox checked={itemsChecked} onClick={selectItem.bind(this)} />
                    <p className={classes.student}>Students - <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ff9800', color: '#fff' }}>{history?.location?.state?.status || getCookie_status() || "All"}</span><span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ef5350', color: '#fff' }}>  {StudentList?.total || 0}</span></p>
                </main>
                {
                    checkedList?.length > 0
                        ?
                        <>
                        <main>
                            <p className={classes.student}>Selected - <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ff9800', color: '#fff' }}>{checkedList?.length}</span></p>
                        </main>
                        <main>
                            <AddAlertOutlinedIcon onClick={()=>setNotificationOpen(true)} sx={{color:"#2979ff",cursor:'pointer'}} />
                        </main>
                        </>
                        :
                        <></>
                }


                <main>

                    <div className={classes.addContact} onClick={() => { SetOpen(true) }}>
                        <PersonAddAltIcon className={classes.addIcon} />
                        {/* <img src={`${process.env.PUBLIC_URL}/img/student/addContact.png`} width="60px" alt='' /> */}
                        {/* <p style={{marginTop:'10px'}}>Add New Contact</p> */}
                    </div>
                </main>
            </div>

            {/* <ExcelFile element={<button>Export Data</button>}>
                <ExcelSheet data={StudentList?.list} name="Students">
                    <ExcelColumn label="Name" value="lastname"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn label="Contact Number" value="mobile"/>
                </ExcelSheet>
            </ExcelFile> */}

            <Grid container spacing={2}>
                {loading ?
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div> :

                    StudentList?.list?.length > 0 && StudentList?.list?.map((d) => {
                        // console.log(d) 
                        return <Grid item xs={12} lg={4}>
                            <Box sx={{
                                // bgcolor: '#fff',
                                // borderRadius: 3,
                                pt: 1,
                                pb: 1,
                                mb: 2,
                                // paddingBottom: "30px",
                                height: 'auto',
                                // boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)"
                            }}><Chat type={id ? 'campaign' : ''} data={d} checkedList={checkedList} setCheckedList={setCheckedList} refreshPage={setRefresh} programList={related_programs_picklist?.related_programs_picklist} countryList={country_picklist?.country_picklist} semesterList={semester_picklist?.semester_picklist} convertByList={convertby_picklist?.convertby_picklist} /></Box>
                        </Grid>
                    })
                }
            </Grid>
            {!loading && <Box
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Pagination
                    style={{ paddingTop: 50 }}
                    defaultPage={1}
                    page={page}
                    count={Math.ceil(StudentList?.total / 20)}
                    color="primary"
                    onChange={(e, p) => loadMore(p)}
                />
            </Box>}

            <AddStudentModal open={open} onClose={() => SetOpen(false)} />
            <CreateNotification selected={checkedList} open={notificationOpen} onClose={()=>setNotificationOpen(false)} />
        </div>
    )
}

export default Header

const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        padding: '10px',
        width: '100%',
        height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        boxShadow: '2px 2px 10px #DCDCDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7
    },
    dropdownContainer: {
        margin: '50px 0 10px 0',
        textAlign: 'center',

        fontSize: '14px', overflow: 'auto', whiteSpace: 'nowrap',
        WebkitOverflowScrolling: 'none'
    },

    squareIcon: {
        opacity: 0.5,
        fontSize: '20px'
    },
    addIcon: {
        opacity: 0.9,
        color: '#1b6cfc',
        border: '1px solid',
        padding: '5px',
        borderRadius: '20px',
        borderColor: 'lightgray',
        boxShadow: '1px 2px 3px lightgray',
        fontSize: '30px'
    },
    student: {
        // fontWeight: 'bold',
        fontSize: '14px',
        // marginLeft: '3%',
        // width: '300px',
        marginTop: '15px'
    },
    addContact: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px'
    }
}))