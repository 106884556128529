import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";

import { BoxShadow } from "react-shadow-component";

import Nav1 from "./component/Nav1.js";
import BasicSelect1 from "./component/select1";
import BasicSelect from "./component/select.js";

import { Link } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import "./Profile.css";

import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useHistory, useParams } from "react-router-dom";

import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Select,
  Chip,
  InputLabel,
} from "@mui/material";

import { styled } from "@mui/system";

import { OPERATION, operationValue } from "../../Controller/Global";

import { fetchAddStudentAction, fetchComoonPickerListAction } from "../Action";

import "react-phone-input-2/lib/style.css";

import { createFilter } from "react-select";
import AsyncSelect from "react-select/async";


import _ from "lodash";

const SButton = styled(Button)`
  color: "#f8f8ff";
  border-color: tomato;
`;
const Phone = styled(PhoneInput)`
  width: "100%";
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };


const RegisterFrom = () => {
  
  const { number } = useParams();

  var data = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    whatsapp: "",
    degree_looking_for: "",
    country: "",
    semester_looking_for: "",
    year_looking_for: "",
    program: "",

    bachelors_year_of_passing: "",
    bachelors_percentage: "",
    number_backlogs: "",
    bachelor_degree_name: "",

    puc_percentage_12th: "",
    puc_year_of_passing_12th: "",
    german_language: "",

    toefl_total_1st_attempt: "",
    toefl_reading_1st_attempt: "",
    toefl_listening_1st_attempt: "",
    toefl_writing_1st_attempt: "",
    toefl_speaking_1st_attempt: "",

    pte_total_1st_attempt: "",
    pte_reading_1st_attempt: "",
    pte_listening_1st_attempt: "",
    pte_writing_1st_attempt: "",
    pte_speaking_1st_attempt: "",

    ielts_total_1st_attempt: "",
    ielts_reading_1st_attempt: "",
    ielts_listening_1st_attempt: "",
    ielts_writing_1st_attempt: "",
    ielts_speaking_1st_attempt: "",

    duolingo_total_1st_attempt: "",
    duolingo_reading_1st_attempt: "",
    duolingo_listening_1st_attempt: "",
    duolingo_writing_1st_attempt: "",
    duolingo_speaking_1st_attempt: "",

    gre_awa: "",
    gre_total_1st_attempt: "",
    gre_quants_1st_attempt: "",
    gre_verbal_1st_attempt: "",

    gmat_awa: "",
    gmat_total_1st_attempt: "",
    gmat_quants_1st_attempt: "",
    gmat_verbal_1st_attempt: "",

    act_total_1st_attempt: "",
    act_quants_1st_attempt: "",
    act_verbal_1st_attempt: "",

    sat_total_1st_attempt: "",
    sat_quant_1st_attempt: "",
    sat_verbal_1st_attempt: "",

    tuition_fee: "",
    living_expenses: "",
    remarks: "",

    related_to: "",
    admittedUniversity: "",
    appliedUniversity: "",
  };

  const [inputData, setInputData] = useState(data);

  const [yearList, SetyearList] = React.useState([]);
  const [semesterlist, SetsemesterList] = React.useState([]);
  const [ContryList, SetContryList] = React.useState([]);
  const [ProgrammerList, SetProgrammerList] = React.useState([]);

  const [greTotalList, setgreTotalList] = React.useState([]);
  const [greQuantsList, setgreQuantsList] = React.useState([]);
  const [greVerbalList, setgreVerbalList] = React.useState([]);
  const [toeflTotalList, settoeflTotalList] = React.useState([]);
  const [toeflReadingList, settoeflReadingList] = React.useState([]);
  const [toeflListeningList, settoeflListeningList] = React.useState([]);
  const [toeflWritingList, settoeflWritingList] = React.useState([]);
  const [toeflSpeakingList, settoeflSpeakingList] = React.useState([]);
  const [pteTotalList, setpteTotalList] = React.useState([]);
  const [pteReadingList, setpteReadingList] = React.useState([]);
  const [pteListeningList, setpteListeningList] = React.useState([]);
  const [pteWritingList, setpteWritingList] = React.useState([]);
  const [duolingoTotalList, setduolingoTotalList] = React.useState([]);
  const [duolingoReadingList, setduolingoReadingList] = React.useState([]);
  const [duolingoListeningList, setduolingoListeningList] = React.useState([]);
  const [duolingoWritingList, setduolingoWritingList] = React.useState([]);
  const [duolingoSpeakingList, setduolingoSpeakingList] = React.useState([]);
  const [ieltsTotalList, setieltsTotalList] = React.useState([]);
  const [ieltsReadingList, setieltsReadingList] = React.useState([]);
  const [ieltsListeningList, setieltsListeningList] = React.useState([]);
  const [ieltsWritingList, setieltsWritingList] = React.useState([]);
  const [gmatTotalList, setgmatTotalList] = React.useState([]);
  const [gmatQuantsList, setgmatQuantsList] = React.useState([]);
  const [gmatVerbalList, setgmatVerbalList] = React.useState([]);
  const [actTotalList, setactTotalList] = React.useState([]);
  const [actQuantsList, setactQuantsList] = React.useState([]);
  const [satTotalList, setsatTotalList] = React.useState([]);
  const [satQuantsList, setsatQuantsList] = React.useState([]);

  // const [universityValue, setuniversityValue] = React.useState('');
  const [admittedUniversities, setAdmittedUniversities] = React.useState([]);
  const [appliedUniversities, setAppliedUniversities] = React.useState([]);

  const onChangeHandler = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };

  const listInput = () => {
    let String = "";
    String = String + `%0D%0A First Name: ${inputData["firstname"]}`;
    String = String + `%0D%0A Last Name: ${inputData["lastname"]}`;
    String = String + `%0D%0A Email : ${inputData["email"]}`;
    String = String + `%0D%0A Mobile No : ${inputData["phone"]}`;
    String = String + `%0D%0A Whatsapp No : ${inputData["whatsapp"]}`;

    String =
      String + `%0D%0A Degree looking for : ${inputData["degree_looking_for"]}`;
    String = String + `%0D%0A Country looking for : ${inputData["country"]}`;
    String =
      String +
      `%0D%0A Semester looking for : ${inputData["semester_looking_for"]}`;
    String =
      String + `%0D%0A Year looking for : ${inputData["year_looking_for"]}`;
    String = String + `%0D%0A program looking for : ${inputData["program"]}`;

    String =
      inputData["degree_looking_for"] !== "Bachelors"
        ? String +
          `%0D%0A Bachelors year of passing : ${inputData["bachelors_year_of_passing"]}
        %0D%0A Bachelors percentage : ${inputData["bachelors_percentage"]}
        %0D%0A Number of backlogs : ${inputData["number_backlogs"]}
        %0D%0A Bachelors course : ${inputData["bachelor_degree_name"]}`
        : String;

    String =
      String + `%0D%0A 12th/PUC %ge : ${inputData["puc_percentage_12th"]}`;
    String =
      String +
      `%0D%0A 12th/PUC Year of Passing : ${inputData["puc_year_of_passing_12th"]}`;
    String =
      String + `%0D%0A German Language : ${inputData["german_language"]}`;

    String =
      String + `%0D%0A TOEFL Total : ${inputData["toefl_total_1st_attempt"]}`;
    String =
      String +
      `%0D%0A TOEFL Reading : ${inputData["toefl_reading_1st_attempt"]}`;
    String =
      String +
      `%0D%0A TOEFL Listening : ${inputData["toefl_listening_1st_attempt"]}`;
    String =
      String +
      `%0D%0A TOEFL Writing : ${inputData["toefl_writing_1st_attempt"]}`;
    String =
      String +
      `%0D%0A TOEFL Speaking : ${inputData["toefl_speaking_1st_attempt"]}`;

    String =
      String + `%0D%0A PTE Total : ${inputData["pte_total_1st_attempt"]}`;
    String =
      String + `%0D%0A PTE Reading : ${inputData["pte_reading_1st_attempt"]}`;
    String =
      String +
      `%0D%0A PTE Listening : ${inputData["pte_listening_1st_attempt"]}`;
    String =
      String + `%0D%0A PTE Writing : ${inputData["pte_writing_1st_attempt"]}`;
    String =
      String + `%0D%0A PTE Speaking : ${inputData["pte_speaking_1st_attempt"]}`;

    String =
      String + `%0D%0A IELTS Total : ${inputData["ielts_total_1st_attempt"]}`;
    String =
      String +
      `%0D%0A IELTS Reading : ${inputData["ielts_reading_1st_attempt"]}`;
    String =
      String +
      `%0D%0A IELTS Listening : ${inputData["ielts_listening_1st_attempt"]}`;
    String =
      String +
      `%0D%0A IELTS Writing : ${inputData["ielts_writing_1st_attempt"]}`;
    String =
      String +
      `%0D%0A IELTS Speaking : ${inputData["ielts_speaking_1st_attempt"]}`;

    String =
      String +
      `%0D%0A Duolingo Total : ${inputData["duolingo_total_1st_attempt"]}`;
    String =
      String +
      `%0D%0A Duolingo Reading : ${inputData["duolingo_reading_1st_attempt"]}`;
    String =
      String +
      `%0D%0A Duolingo Listening : ${inputData["duolingo_listening_1st_attempt"]}`;
    String =
      String +
      `%0D%0A Duolingo Writing : ${inputData["duolingo_writing_1st_attempt"]}`;
    String =
      String +
      `%0D%0A Duolingo Speaking : ${inputData["duolingo_speaking_1st_attempt"]}`;

    String =
      inputData["degree_looking_for"] !== "Bachelors"
        ? String +
          `%0D%0A GRE Total : ${inputData["gre_total_1st_attempt"]}
        %0D%0A GRE AWA : ${inputData["gre_awa"]}
        %0D%0A GRE Verbal : ${inputData["gre_verbal_1st_attempt"]}
        %0D%0A GRE Quants : ${inputData["gre_quants_1st_attempt"]}

        %0D%0A GMAT Total : ${inputData["gmat_total_1st_attempt"]}
        %0D%0A GMAT AWA : ${inputData["gmat_awa"]}
        %0D%0A GMAT Verbal : ${inputData["gmat_verbal_1st_attempt"]}
        %0D%0A GMAT Quants : ${inputData["gmat_quants_1st_attempt"]}`
        : String +
          `%0D%0A ACT Total : ${inputData["act_total_1st_attempt"]}
        %0D%0A ACT Verbal : ${inputData["act_verbal_1st_attempt"]}
        %0D%0A ACT Quants : ${inputData["act_quants_1st_attempt"]}

        %0D%0A SAT Total : ${inputData["sat_total_1st_attempt"]}
        %0D%0A SAT Verbal : ${inputData["sat_verbal_1st_attempt"]}
        %0D%0A SAT Quants : ${inputData["sat_quants_1st_attempt"]}`;

    String = String + `%0D%0A Tuition Fee : ${inputData["tuition_fee"]}`;
    String =
      String + `%0D%0A Living Expenses : ${inputData["living_expenses"]}`;
    String = String + `%0D%0A Remarks : ${inputData["remarks"]}`;

    // var admittedUniversity = ''
    // admittedUniversities.map((university)=>{
    //     admittedUniversity = admittedUniversity+', '+university?.label
    // })

    String =
      String +
      `%0D%0A *Admitted Universities* : ${inputData["admittedUniversity"]}`;

    // var appliedUniversity = ''
    // appliedUniversities.map((university)=>{
    //     appliedUniversity = appliedUniversity+', '+university?.label
    // })

    String =
      String +
      `%0D%0A *Applied Universities* : ${inputData["appliedUniversity"]}`;

    return String;
  };

  const submitForm = () => {
    var formData = new FormData();
    formData.append("degree_looking_for", inputData["degree_looking_for"]);
    formData.append("operation", "Add_student");
    formData.append("email_address", inputData["email"]);
    formData.append("phone_mobile", inputData["phone"]);
    formData.append(
      "year_looking_for",
      inputData["year_looking_for"] ? inputData["year_looking_for"] : "All"
    );
    formData.append(
      "country",
      inputData["country"] ? inputData["country"] : "All"
    );
    formData.append(
      "semester_looking_for",
      inputData["semester_looking_for"]
        ? inputData["semester_looking_for"]
        : "All"
    );
    formData.append(
      "username",
      `${inputData["firstname"]} ${inputData["lastname"]}`
    );
    formData.append(
      "related_programs",
      inputData["program"] ? inputData["program"] : "All"
    );

    console.log(formData);
    fetchAddStudentAction(formData)
      .then((res) => {
        console.log(res);
        if (res?.data !== "") {
          inputData["related_to"] = res?.data;
          inputData["desc"] = listInput().replaceAll("%0D%0A", "\n");
          let config = {
            url: "https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact",
            data: JSON.stringify(inputData),
            method: "post",
          };
          axios(config).then((response) => {
            console.log(response);

            let TempData = `${listInput()}
                        %0D%0A%0D%0A*BRAINSTORM CONSULTING*
                        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
                        %0D%0A iOS: https://apple.co/33ujR4Q`;

            // console.log(TempData)
            if (number) {
              window.location.href = `https://api.whatsapp.com/send?phone=${number}&text=%0D%0A ${TempData.replaceAll(
                "&",
                "and"
              )} `;
            } else {
              window.location.href = `https://api.whatsapp.com/send?phone=917619492054&text=%0D%0A ${TempData.replaceAll(
                "&",
                "and"
              )} `;
            }
          });
        } else {
          console.log("something went wrong");
        }
      })
      .catch((error) => {});

    // return `https://api.whatsapp.com/send?phone=918861216666&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")} `
  };

  const promiseOptions = (inputValue) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (inputValue.length > 2)
          setTimeout(async () => {
            const university = new FormData();
            university.append(OPERATION, operationValue.FetchUniversity);
            university.append("validation", "bypass");
            university.append("search", inputValue);
            university.append("degreeType", inputData["degree_looking_for"]);
            axios
              .post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
              .then((response) => {
                console.log(response);
                let SelectData = [];
                if (Array.isArray(response?.data?.data)) {
                  response?.data?.data?.map((data) => {
                    let temp = {
                      label: `${data}`,
                      value: `${data}`,
                    };
                    SelectData.push(temp);
                  });
                } else {
                  let temp = {
                    label: `${response?.data?.data}`,
                    value: `${response?.data?.data}`,
                  };
                  SelectData.push(temp);
                }

                resolve(SelectData);
              });
          }, 3000);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    async function syncData() {
      const params = new URLSearchParams();
      params.append("operation", "fetchPicklist");
      params.append("name", "related_programs");
      const response = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params,
        {
          "Access-Control-Allow-Origin": "*",
        }
      );
      params.append("name", "country");
      const countryRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "semester");
      const semesterRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "year");
      const yearRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "toefl_total_1st_attempt");
      const toeflTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "toefl_reading_1st_attempt");
      const toeflReadingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "toefl_listening_1st_attempt");
      const toeflListeningRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "toefl_writing_1st_attempt");
      const toeflWritingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "toefl_speaking_1st_attempt");
      const toeflSpeakingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "pte_total_1st_attempt");
      const pteTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "pte_reading_1st_attempt");
      const pteReadingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "pte_listening_1st_attempt");
      const pteListeningRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "pte_writing_1st_attempt");
      const pteWritingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "ielts_total_1st_attempt");
      const ieltsTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "ielts_reading_1st_attempt");
      const ieltsReadingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "ielts_listening_1st_attempt");
      const ieltsListeningRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "ielts_listening_1st_attempt");
      const ieltsWritingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "duolingo_total_1st_attempt");
      const duolingoTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "duolingo_reading_1st_attempt");
      const duolingoReadingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "duolingo_listening_1st_attempt");
      const duolingoListeningRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "duolingo_listening_1st_attempt");
      const duolingoWritingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "duolingo_speaking_1st_attempt");
      const duolingoSpeakingRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "german_language");
      const germanRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "gre_total_1st_attempt");
      const greTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "gre_quants_1st_attempt");
      const greQuantsRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "gre_verbal_1st_attempt");
      const greVerbalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "gmat_total_1st_attempt");
      const gmatTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "gmat_quants_1st_attempt");
      const gmatQuantsRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "gmat_verbal_1st_attempt");
      const gmatVerbalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "sat_total_1st_attempt");
      const satTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "sat_quant_1st_attempt");
      const satQuantsRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );
      console.log("satquant", satQuantsRes);

      params.append("name", "act_total_1st_attempt");
      const actTotalRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      params.append("name", "act_quants_1st_attempt");
      const actQuantsRes = await axios.post(
        "https://erp.bscglobaledu.com/mapi/JsonApi/",
        params
      );

      SetProgrammerList(response.data.data);
      SetContryList(countryRes.data.data);
      SetsemesterList(semesterRes.data.data);
      SetyearList(yearRes.data.data);

      setgreTotalList(greTotalRes.data.data);
      setgreQuantsList(greQuantsRes.data.data);
      setgreVerbalList(greVerbalRes.data.data);

      settoeflTotalList(toeflTotalRes.data.data);
      settoeflReadingList(toeflReadingRes.data.data);
      settoeflListeningList(toeflListeningRes.data.data);
      settoeflWritingList(toeflWritingRes.data.data);
      settoeflSpeakingList(toeflSpeakingRes.data.data);
      setpteTotalList(pteTotalRes.data.data);
      setpteReadingList(pteReadingRes.data.data);
      setpteListeningList(pteListeningRes.data.data);
      setpteWritingList(pteWritingRes.data.data);
      setduolingoTotalList(duolingoTotalRes.data.data);
      setduolingoReadingList(duolingoReadingRes.data.data);
      setduolingoListeningList(duolingoListeningRes.data.data);
      setduolingoWritingList(duolingoWritingRes.data.data);
      setduolingoSpeakingList(duolingoSpeakingRes.data.data);
      setieltsTotalList(ieltsTotalRes.data.data);
      setieltsReadingList(ieltsReadingRes.data.data);
      setieltsListeningList(ieltsListeningRes.data.data);
      setieltsWritingList(ieltsWritingRes.data.data);
      setgmatTotalList(gmatTotalRes.data.data);
      setgmatQuantsList(gmatQuantsRes.data.data);
      setgmatVerbalList(gmatVerbalRes.data.data);
      setactTotalList(actTotalRes.data.data);
      setactQuantsList(actQuantsRes.data.data);
      setsatTotalList(satTotalRes.data.data);
      setsatQuantsList(satQuantsRes.data.data);
    }
    syncData();
  }, []);

  return (
    <Box sx={{ p: "20px" }}>
      <Nav1 />
      <BoxShadow color="#0000001A" offsetX={0} offsetY={0} blur={20} spread={0}>
        <Container
         
          style={{ backgroundColor: "#fffafa" }} sx={{pt:3}}
        >
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <TextField
                value={inputData["firstname"] + ""}
                onChange={(e) => onChangeHandler("firstname", e.target.value)}
                label="First Name"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <TextField
                onChange={(e) => onChangeHandler("lastname", e.target.value)}
                value={inputData["lastname"]}
                label="Last Name"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  label="Email Id"
                  onChange={(e) => onChangeHandler("email", e.target.value)}
                  value={inputData["email"]}
                  size="small"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <Box>
                <Phone
                  country={"in"}
                  name="phone_mobile"
                  placeholder="Mobile"
                  inputProps={{
                    required: true,
                    autoFocus: true,
                  }}
                  //   value={phone}
                  searchPlaceholder={"Mobile"}
                  onChange={(phone) => {
                    onChangeHandler("phone", phone);
                    //   handleChange()
                  }}
                  value={inputData["phone"]}
                  //   onChange={formik.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <TextField
                label="Whatsapp No"
                onChange={(e) => onChangeHandler("whatsapp", e.target.value)}
                value={inputData["whatsapp"]}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Box
                sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                marginBottom="10px"
                marginTop="10px"
              >
                <Typography
                  variant="h5"
                  align="center"
                  margin="dense"
                  sx={{ color: "#f8f8ff", fontFamily: "Century Gothic" }}
                >
                  Looking For
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
            <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("degree_looking_for",value)}
                naam="Degree"
                totals={Degree}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("country",value)}
                naam="Country"
                totals={Country}
              />
            </Grid>
          </Grid>
          
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("semester_looking_for",value)}
                naam="Semester"
                totals={semesters}
              />
              
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
            <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("year_looking_for",value)}
                naam="Year"
                totals={years}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} mt={0} px={1}>
            <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("program",value)}
                naam="Program"
                totals={programs}
              />
            </Grid>

            <Grid container>
              <Grid item xs={12} md={12} mb={2.5}>
                <Box
                  sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                  marginBottom="10px"
                  marginTop="10px"
                >
                  <Typography
                    variant="h5"
                    align="center"
                    margin="dense"
                    sx={{ color: "#f8f8ff", fontFamily: "Century Gothic" }}
                  >
                    Acedemics
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} mb={2.5}>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    Bachelors Detail
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6} mb={1.5} direction="column" px={1}>
                <TextField
                  label="Bachelors%"
                  onChange={(e) =>
                    onChangeHandler("bachelors_percentage", e.target.value)
                  }
                  value={inputData["bachelors_percentage"]}
                  size="small"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6} mb={1.5} direction="column" px={1}>
                <TextField
                  label="No Of BackLogs"
                  onChange={(e) =>
                    onChangeHandler("number_backlogs", e.target.value)
                  }
                  value={inputData["number_backlogs"]}
                  size="small"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                marginTop="10px"
              >
                <TextField
                  label="Year Of passing"
                  onChange={(e) =>
                    onChangeHandler("bachelors_year_of_passing", e.target.value)
                  }
                  value={inputData["bachelors_year_of_passing"]}
                  size="small"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                marginTop="10px"
              >
                <TextField
                  size="small"
                  onChange={(e) =>
                    onChangeHandler("bachelor_degree_name", e.target.value)
                  }
                  value={inputData["bachelor_degree_name"]}
                  label="Bachelors In"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  height: "35px",
                  bgcolor: "#5F9EA0",
                  borderRadius: "20px",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                >
                  12th/PUC Details
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={1} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                marginTop="10px"
              >
                <TextField
                  size="small"
                  onChange={(e) =>
                    onChangeHandler("puc_percentage_12th", e.target.value)
                  }
                  value={inputData["puc_percentage_12th"]}
                  label="12th/PUC %"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={6} mb={1.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                marginTop="10px"
              >
                <TextField
                  size="small"
                  onChange={(e) =>
                    onChangeHandler("puc_year_of_passing_12th", e.target.value)
                  }
                  value={inputData["puc_year_of_passing_12th"]}
                  label="12th/PUC Year Of Passing"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={1.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                marginTop="10px"
              >
                <TextField
                  size="small"
                  onChange={(e) =>
                    onChangeHandler("german_language", e.target.value)
                  }
                  value={inputData["german_language"]}
                  label="German Language"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Box
                sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                marginBottom="10px"
                marginTop="10px"
              >
                <Typography
                  variant="h5"
                  align="center"
                  margin="dense"
                  sx={{ color: "#f8f8ff", fontFamily: "Century Gothic" }}
                >
                  Prerequisite Test
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    TOEFL
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["toefl_total_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("toefl_total_1st_attempt", e.target.value)}
                naam="Total"
                totals={ttotals}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["toefl_reading_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("toefl_reading_1st_attempt", e.target.value)}
                naam="Reading"
                totals={treading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["toefl_listening_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("toefl_listening_1st_attempt", e.target.value)}
                naam="Listening"
                totals={treading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["toefl_writing_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("toefl_writing_1st_attempt", e.target.value)}
                naam="Writing"
                totals={treading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["toefl_speaking_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("toefl_speaking_1st_attempt", e.target.value)}
                naam="Speaking"
                totals={treading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    PTE
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["pte_total_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("pte_total_1st_attempt", e.target.value)}
                naam="PTE Total"
                totals={ptreading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["pte_reading_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("pte_reading_1st_attempt", e.target.value)}
                naam="PTE Reading"
                totals={ptreading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["pte_listening_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("pte_listening_1st_attempt", e.target.value)}
                naam="PTE Listening"
                totals={ptreading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["pte_writing_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("pte_writing_1st_attempt", e.target.value)}
                naam="PTE Writing"
                totals={ptreading}
              />
            
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["pte_speaking_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("pte_speaking_1st_attempt", e.target.value)}
                naam="PTE Speaking"
                totals={ptreading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    IELTS
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["ielts_total_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("ielts_total_1st_attempt", e.target.value)}
                naam="IELTS Total"
                totals={itotal}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["ielts_reading_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("ielts_reading_1st_attempt", e.target.value)}
                naam="IELTS Reading"
                totals={ireading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["ielts_listening_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("ielts_listening_1st_attempt", e.target.value)}
                naam="IELTS Listening"
                totals={ireading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["ielts_writing_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("ielts_writing_1st_attempt", e.target.value)}
                naam="IELTS Writing"
                totals={ireading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["ielts_speaking_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("ielts_speaking_1st_attempt", e.target.value)}
                naam="IELTS Speaking"
                totals={ireading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    DUOLINGO
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["duolingo_total_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("duolingo_total_1st_attempt", e.target.value)}
                naam="Total"
                totals={dtotal}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["duolingo_reading_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("duolingo_reading_1st_attempt", e.target.value)}
                naam="Reading"
                totals={dreading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["duolingo_listening_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("duolingo_listening_1st_attempt", e.target.value)}
                naam="Listening"
                totals={dreading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["duolingo_writing_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("duolingo_writing_1st_attempt", e.target.value)}
                naam="Writing"
                totals={dreading}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["duolingo_speaking_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("duolingo_speaking_1st_attempt", e.target.value)}
                naam="Speaking"
                totals={dreading}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    GRE
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["gre_total_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("gre_total_1st_attempt", e.target.value)}
                naam="GRE Total"
                totals={grtotal}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["gre_awa"]}
                onChangeHandler={(e) => onChangeHandler("gre_awa", e.target.value)}
                naam="GRE Awa"
                totals={grawa}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["gre_verbal_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("gre_verbal_1st_attempt", e.target.value)}
                naam="GRE Verbal"
                totals={grawa}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <BasicSelect1
                inputData={inputData["gre_quants_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("gre_quants_1st_attempt", e.target.value)}
                naam="GRE Quants"
                totals={grawa}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container>
                <Box
                  sx={{
                    height: "35px",
                    bgcolor: "#5F9EA0",
                    borderRadius: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ paddingTop: "3px", fontFamily: "Century Gothic" }}
                  >
                    GMAT
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["gmat_total_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("gmat_total_1st_attempt", e.target.value)}
                naam="GMAT Total"
                totals={gmtotal}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["gmat_awa"]}
                onChangeHandler={(e) => onChangeHandler("gmat_awa", e.target.value)}
                naam="GMAT Awa"
                totals={gmawa}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["gmat_verbal_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("gmat_verbal_1st_attempt", e.target.value)}
                naam="GMAT Verbal"
                totals={gmverbal}
              />
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} direction="column" px={1}>
              <BasicSelect1
                inputData={inputData["gmat_quants_1st_attempt"]}
                onChangeHandler={(e) => onChangeHandler("gmat_quants_1st_attempt", e.target.value)}
                naam="GMAT Quants"
                totals={gmawa}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Box
                sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                marginBottom="10px"
                marginTop="10px"
              >
                <Typography
                  variant="h5"
                  align="center"
                  margin="dense"
                  sx={{ color: "#f8f8ff", fontFamily: "Century Gothic" }}
                >
                  Fee
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  label="Tution Fee"
                  onChange={(e) =>
                    onChangeHandler("tuition_fee", e.target.value)
                  }
                  value={inputData["tuition_fee"]}
                  size="small"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6} mb={2.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  label="Living Expenses"
                  onChange={(e) =>
                    onChangeHandler("living_expenses", e.target.value)
                  }
                  value={inputData["living_expenses"]}
                  size="small"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
              <Box
                sx={{
                  direction: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  label="Remarks"
                  onChange={(e) => onChangeHandler("remarks", e.target.value)}
                  value={inputData["remarks"]}
                  size="small"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Box
                sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                marginBottom="10px"
                marginTop="10px"
              >
                <Typography
                  variant="h5"
                  align="center"
                  margin="dense"
                  sx={{ color: "#f8f8ff", fontFamily: "Century Gothic" }}
                >
                  Admitted Universities
                </Typography>
              </Box>
            </Grid>
          </Grid>
          
          
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
            <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("admittedUniversity",value)}
                naam="Select Universities"
                totals={ulist}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Box
                sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                marginBottom="10px"
                marginTop="10px"
              >
                <Typography
                  variant="h5"
                  align="center"
                  margin="dense"
                  sx={{ color: "#f8f8ff", fontFamily: "Century Gothic" }}
                >
                  Applied Universities
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5} px={1}>
            <BasicSelect
                onChangeHandler={(e,value) => onChangeHandler("appliedUniversity",value)}
                naam="Select Universities"
                totals={ulist}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} md={1} mb={2.5}>
              <Checkbox {...label} />
            </Grid>
            <Grid item xs={10} md={11}>
              <Typography component={Link} to={"/privacy-policy-front"}>
                Terms and Conditions
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={2.5}>
              <Container
                sx={{ backgroundColor: "#000080", borderRadius: "20px" }}
                marginBottom="10px"
                marginTop="10px"
              >
                <SButton variant="text" onClick={() => submitForm()}>
                  SUBMIT
                </SButton>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </BoxShadow>
    </Box>
  );
};

const Degree = ["Masters", "Bachelors", "Diploma", "Phd"];
const Country = [
  "Australia",
  "Canada",
  "Germany",
  "Ireland",
  "Malaysia",
  "New Zealand",
  "Singapore",
  "Sweden",
  "UK",
  "USA",
  "Denmark",
  "Netherland",
  "Switzerland",
  "France",
  "Cyprus",
  "Italy",
  "Poland",
  "Spain",
  "Lativa",
  "Iceland",
  "Austria",
  "Estonia",
  "UAE",
  "Belgium",
  "England",
  "Mauritius",
  "Hungray",
  "Czech Republic",
  "Lithuania",
  "South Korea",
  "Israel",
  "Russia",
  "Malta",
  "Argentina",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Belarus",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Chile",
  "China",
  "Colombia",
  "Costarica",
  "Croatia",
  "Cuba",
  "Ecuador",
  "Egypt",
  "Greece",
  "Hongkong",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kuwait",
  "Lebanon",
  "Macau Sar",
  "Mexico",
  "Oman",
  "Pakistan",
  "Panama",
  "Paraguay",
  "Peru",
  "Philippines",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Saudi Arabia",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Sri Lanka",
  "Taiwan",
  "Thailand",
  "Turkey",
  "Ukraine",
  "Uruguay",
  "Venezuela",
  "Vietnam",
  "Norway",
  "Finland",
];
const semesters = [
  "Fall",
  "Summer",
  "Spring",
  "Winter",
  "September",
  "October",
  "November",
  "August",
  "April",
  "May",
  "January",
  "February",
  "December",
  "March",
  "June",
  "July",
];
const years = ["2023", "2024", "2025"];
const programs = [
  "Biology",
  "Aerospace Engineering",
  "Agricultural",
  "Architecture Engineering",
  "Arts",
  "Bio Technology",
  "Biomedical Engineering",
  "Chemical Engineering",
  "Chemistry,Civil Engineering",
  "Computer Science",
  "Data Science",
  "Dental",
  "Electrical Engineering",
  "Finance & Accounting",
  "Forensic Science",
  "Geography",
  "Health Science",
  "Industrial Engineering",
  "Law",
  "Marine Technology",
  "Marketing & Management",
  "Mathematics",
  "Mechanical Engineering",
  "Medical Electronics",
  "Pharmacy",
  "Physics",
  "Telecommunication Engineering",
  "Tourism & Hospitality",
  "Management",
  "International Business",
  "Strategy & Consulting",
  "Electronic Communications",
  "Social Science",
  "Business,Sociology",
  "Molecular Biology",
  "Food Science",
  "Zoology",
  "Administration",
  "MBA",
  "Business Analytics",
  "Computer Engineering",
  "Information Technology",
  "Software Engineering",
  "Management Information System",
  "Cyber Security",
  "Automobile Engineering",
  "Automotive Engineering",
  "Public Health",
  "Hospital Administration",
  "Nursing",
  "Machine Learning",
  "Artificial Intelligence",
  "Supply Chain Management",
];
const ttotals = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
  "100",
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "109",
  "110",
  "111",
  "112",
  "113",
  "114",
  "115",
  "116",
  "117",
  "118",
  "119",
  "120",
  "Not Required",
  "Not Applicable",
];
const treading = [
  "0","1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
  "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
];
const ptreading = [
  "10",
  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
  "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
  "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
  "41", "42", "43", "44", "45", "46", "47", "48", "49", "50",
  "51", "52", "53", "54", "55", "56", "57", "58", "59", "60",
  "61", "62", "63", "64", "65", "66", "67", "68", "69", "70",
  "71", "72", "73", "74", "75", "76", "77", "78", "79", "80",
  "81", "82", "83", "84", "85", "86", "87", "88", "89", "90","Not Required","Not Applicable"
];
const itotal = [
  "0", "0.5", "1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0", "5.5", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0","Not Required","Not Applicable",
];
const ireading = [
  "1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0", "5.5", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0"
];
const dtotal = [
  "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160","Not Applicable","Not Required"
];
const dreading = [
  "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160"
];

const grtotal = [
  "260", "261", "262", "263", "264", "265", "266", "267", "268", "269",
  "270", "271", "272", "273", "274", "275", "276", "277", "278", "279",
  "280", "281", "282", "283", "284", "285", "286", "287", "288", "289",
  "290", "291", "292", "293", "294", "295", "296", "297", "298", "299",
  "300", "301", "302", "303", "304", "305", "306", "307", "308", "309",
  "310", "311", "312", "313", "314", "315", "316", "317", "318", "319",
  "320", "321", "322", "323", "324", "325", "326", "327", "328", "329",
  "330", "331", "332", "333", "334", "335", "336", "337", "338", "339",
  "340","Not Applicable","Not Required"
];

const grawa = [
  "130", "131", "132", "133", "134", "135", "136", "137", "138", "139",
    "140", "141", "142", "143", "144", "145", "146", "147", "148", "149",
    "150", "151", "152", "153", "154", "155", "156", "157", "158", "159",
    "160", "161", "162", "163", "164", "165", "166", "167", "168", "169",
    "170"
];
const gmtotal = [
  "200", "210", "220", "230", "240", "250", "260", "270", "280", "290",
    "300", "310", "320", "330", "340", "350", "360", "370", "380", "390",
    "400", "410", "420", "430", "440", "450", "460", "470", "480", "490",
    "500", "510", "520", "530", "540", "550", "560", "570", "580", "590",
    "600", "610", "620", "630", "640", "650", "660", "670", "680", "690",
    "700", "710", "720", "730", "740", "750", "760", "770", "780", "790",
    "800","Not Applicable","Not Required"
];
const gmawa = [
  "130", "131", "132", "133", "134", "135", "136", "137", "138", "139",
  "140", "141", "142", "143", "144", "145", "146", "147", "148", "149",
  "150", "151", "152", "153", "154", "155", "156", "157", "158", "159",
  "160", "161", "162", "163", "164", "165", "166", "167", "168", "169",
  "170"
];
const gmverbal = [
  "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
  "16", "17", "18", "19", "20", "21", "22", "23", "24", "25",
  "26", "27", "28", "29", "30", "31", "32", "33", "34", "35",
  "36", "37", "38", "39", "40", "41", "42", "43", "44", "45",
  "46", "47", "48", "49", "50", "51"
];
const ulist = [
  "AACHEN UNIVERSITY",
  "Aalborg University",
  "AALEN UNIVERSITY",
  "AALESUND UNIVERSITY COLLEGE",
  "Aalto University",
  "AALTO UNIVERSITY EXECUTIVE EDUCATION",
  "Aarhus School of Architecture",
  "AARHUS UNIVERSITY",
  "Abat Oliba CEU University",
  "ABBEY COLLEGE",
  "ABERTAY UNIVERSITY",
  "ABERYSTWYTH UNIVERSITY",
  "ABILENE CHRISTIAN UNIVERSITY",
  "ACADEMIA",
  "Academia Costume and Moda",
  "ACADEMY OF ART UNIVERSITY",
  "ACADIA UNIVERSITY",
  "Accademia",
  "Accademia Del Lusso",
  "ACCADEMIA RIACI",
  "Accadis Hochschule Bad Homburg University",
  "ACCADIS UNIVERSITY OF APPLIED SCIENCES",
  "Adam Mickiewicz University",
  "ADELAIDE COLLEGE OF TECHNOLOGY",
  "ADELPHI UNIVERSITY",
  "AEROSIM FLIGHT ACADEMY",
  "AESE Business School",
  "AGH University of Science and Technology",
  "AGNES SCOTT COLLEGE",
  "AGSM Athens Graduate School of Management",
  "AIM - Austrian Institute of Management",
  "ALABAMA A and M UNIVERSITY",
  "Alabama Agricultural and Mechanical University",
  "ALABAMA STATE UNIVERSITY",
  "ALABANY UNIVERSITY",
  "ALASKA PACIFIC UNIVERSITY",
  "Alba Graduate Business School",
  "ALBANY MEDICAL COLLEGE",
  "ALBION COLLEGE",
  "ALCHIMIA CONTEMPORARY JEWELLERY SCHOOL",
  "ALCORN STATE UNIVERSITY",
  "ALDERSON BROADDUS UNIVERSITY",
  "Alexandru Ioan Cuza University of Iasi (UAIC)",
  "ALFRED UNIVERSITY",
  "Algebra University College",
  "ALGOMA UNIVERSITY",
  "ALGONQUIN COLLEGE",
  "ALICE SALOMON HOCHSCHULE BERLIN",
  "ALL UNIVERSITIES",
  "ALLEGHENY COLLEGE",
  "ALLGONQUIN COLLEGE",
  "ALLIANCE MANCHESTER BUSINESS SCHOOL",
  "ALLIANT INTERNATIONAL UNIVERSITY",
  "ALMA COLLEGE",
  "Alpen-Adria-University",
  "ALVERNO COLLEGE",
  "Alytaus Kolegija University of Applied Sciences",
  "AMARILLO COLLEGE",
  "America University of Malta",
  "AMERICAN COLLEGE DUBLIN",
  "American College of Thessaloniki",
  "AMERICAN FILM INSTITUTE",
  "AMERICAN INTERNATIONAL  COLLEGE",
  "AMERICAN NATIONAL UNIVERSITY",
  "AMERICAN UNIVERSITY",
  "AMERICAN UNIVERSITY IN BULGARIA",
  "American University in Dubai",
  "American University of Malta",
  "AMHERST COLLEGE",
  "AMI EDUCATION",
  "AMITY GLOBAL BUSINESS SCHOOL,SINGAPORE",
  "AMRIDGE UNIVERSITY",
  "AMSTERDAM UNIVERSITY OF APPLIED SCIENCES",
  "AMT EDUCATION AT UNIVERSITY OF BALLARATH",
  "ANDREWS UNIVERSITY",
  "ANGELO STATE UNIVERSITY",
  "ANGLIA RUSKIN UNIVERSITY",
  "Anglo American University",
  "ANHALT UNIVERSITY OF APPLIED SCIENCES",
  "ANSBACH UNIVERSITY OF APPLIED SCIENCES",
  "ANTELOPE VALLEY COLLEGE",
  "ANTIOCH UNIVERSITY NEW ENGLAND",
  "ANTIOCH UNIVERSITY SEATTLE",
  "ANTIOCH UNIVERSITY, LOS ANGELES",
  "ANTWERP MANAGEMENT SCHOOL",
  "APPALACHIAN STATE UNIVERSITY",
  "AQUINAS COLLEGE",
  "ARA INSTITUTE OF CANTERBURY",
  "Arcada University of Applied Sciences",
  "ARCADIA UNIVERSITY",
  "ARCHITECTURAL ASSOCIATION SCHOOL OF ARCHITECTURE",
  "ARDEN UNIVERSITY",
  "ARIZONA SCHOOL OF ACUPUNCTURE & ORIENTAL MEDICINE",
  "ARIZONA STATE UNIVERSITY",
  "ARIZONA STATE UNIVERSITY, DOWNTOWN PHOENIX",
  "ARIZONA STATE UNIVERSITY, POLYTECNIC CAMPUS",
  "ARIZONA STATE UNIVERSITY,TEMPE",
  "ARKANSAS NORTHEASTERN COLLEGE",
  "ARKANSAS STATE UNIVERSITY",
  "ARKANSAS TECHNOLOGICAL UNIVERSITY,RUSSELLVILLE",
  "ARMSTRONG STATE UNIVERSITY",
  "AROS BUSINESS ACADEMY",
  "ART CENTER COLLEGE OF DESIGN",
  "Artevelde University of Applied Sciences",
  "ARTS ET MÉTIERS PARISTECH",
  "ASHLAND UNIVERSITY, OHIO",
  "ASIA PACIFIC INSTITUTE OF INFORMATION TECHNOLOGY",
  "ASIA PACIFIC INTERNATIONAL COLLEGE",
  "Asia School of Business",
  "ASIAN INSTITUTE OF MANAGEMENT",
  "ASPIRE2 INTERNATIONAL",
  "ASSEMBLIES OF GOD THEOLOGICAL SEMINARY",
  "ASSUMPTION COLLEGE",
  "ASTON UNIVERSITY",
  "ATHABASCA UNIVERSITY",
  "ATHENS STATE COLLEGE",
  "ATHENS STATE UNIVERSITY",
  "Athens University of Economics and Business",
  "ATHLONE INSTITUTE OF TECHNOLOGY",
  "ATLANTIC AVIATION GROUP",
  "ATLANTIS UNIVERSITY",
  "ATMC- BALLARAT UNIVERSITY GEELONG CAMPUS",
  "ATMC-UNIVERSITY OF SUNSHINE COAST",
  "AUBURN",
  "AUBURN UNIVERSITY",
  "AUBURN UNIVERSITY, AUBURN",
  "AUBURN UNIVERSITY, MONTGOMERY",
  "AUCKLAND INSTITUTE OF STUDIES",
  "AUCKLAND UNIVERSITY OF TECHNOLOGY",
  "AUDENCIA BUSINESS SCHOOL",
  "AUGSBURG UNIVERSITY",
  "AUGUSTANA COLLEGE",
  "AURORA UNIVERSITY",
  "AUSTIN PEAY STATE UNIVERSITY",
  "AUSTON INSTITUTE OF MANAGEMENT",
  "AUSTRALASIAN COLLEGE OF NATURAL THERAPIES",
  "AUSTRALIAN CATHOLIC UNIVERSITY",
  "AUSTRALIAN COLLEGE OF ARTS",
  "AUSTRALIAN INSTITUTE OF TECHNOLOGY AND EDUCATION",
  "AUSTRALIAN NATIONAL AIRLINE COLLEGE",
  "AUSTRALIAN NATIONAL UNIVERSITY",
  "AUSTRALIAN TECHNICAL AND MANAGEMENT",
  "Autonomous University of Barcelona",
  "AVT BUSINESS SCHOOL",
  "AZUSA PACIFIC UNIVERSITY",
  "BA SCHOOL OF BUSINESS AND FINANCE",
  "Babes-Bolyai University",
  "BABSON COLLEGE",
  "BAKER UNIVERSITY",
  "BALDWIN WALLACE UNIVERSITY",
  "BALL STATE UNIVERSITY, MUNICE",
  "BALTIC INTERNATIONAL ACADEMY",
  "BAPTIST BIBLE COLLEGE",
  "Barcelona Graduate School of Economics",
  "BARCELONA INSTITUTE OF ARCHITECTURE",
  "BARCELONA SCHOOL OF INFORMATICS",
  "Barcelona School of Management",
  "BARCELONA TECHNOLOGY SCHOOL",
  "BARD COLLEGE",
  "BARRY UNIVERSITY,MIAMI SHORES",
  "BARTLETT SCHOOL OF ARCHITECTURE",
  "BARUCH COLLEGE CITY UNIVERSITY OF NEWYORK",
  "BASAIR AVIATION COLLEGE",
  "BASTYR UNIVERSITY",
  "BATES COLLEGE",
  "BATH SPA UNIVERSITY",
  "BAU INTERNATIONAL BERLIN - UNIVERSITY OF APPLIED SCIENCE",
  "BAUHAUS UNIVERSITAT WEIMAR",
  "BAYLOR COLLEGE OF MEDICINE, HOUSTON",
  "BAYLOR UNIVERSITY",
  "BBW Hochschule",
  "BELLARMINE UNIVERSITY",
  "BELLEVUE UNIVERSITY",
  "BELMONT UNIVERSITY",
  "BELOIT COLLEGE",
  "BEMIDJI STATE UNIVERSITY",
  "BENEDICTINE UNIVERSITY",
  "BENTLEY UNIVERSITY",
  "Bergen Academy of Art and Design",
  "Bergen University College",
  "BERKELEY COLLEGE",
  "BERLIN INSTITUTE OF TECHNOLOGY",
  "Berlin International College",
  "Berlin International University of Applied Sciences",
  "BERLIN SCHOOL OF BUSINESS AND INNOVATION",
  "BERLIN SCHOOL OF ECONOMICS AND LAW",
  "BERLIN SCHOOL OF PUBLIC HEALTH",
  "BERLIN UNIVERSITY OF ARTS",
  "BERLIN-BRANDEDBURG SCHOOL FOR REGENERATIVE THERAPIES",
  "Bern University of Applied Sciences",
  "BEUTH UNIVERSITY OF APPLIED SCIENCES BERLIN",
  "BI Norwegian Business School",
  "BI NORWEGIAN BUSINESS SCHOOL, NORWAY",
  "Bialystok University of Technology",
  "BIELEFELD UNIVERSITY",
  "Bifr",
  "BINGHAMTON UNIVERSITY",
  "BIOLA UNIVERSITY",
  "BIRKBECK UNIVERSITY OF LONDON",
  "BIRMINGHAM CITY UNIVERSITY",
  "Birmingham City University International College",
  "BIRMINGHAM SOUTHERN COLLEGE",
  "BISHOP'S UNIVERSITY",
  "BLACK HILLS STATE UNIVERSITY",
  "Blekinge Institute of Technology",
  "BLOOMSBURG UNIVERSITY OF PENNSYLVANIA",
  "BOCCONI UNIVERSITY",
  "BOCHUM UNIVERSITY OF APPLIED SCIENCES",
  "BOISE STATE UNIVERSITY",
  "BOLOGNA BUSINESS SCHOOL",
  "BOND UNIVERSITY",
  "BOSTON ARCHITECTURAL COLLEGE",
  "BOSTON COLLEGE",
  "BOSTON UNIVERSITY",
  "BOSTON UNIVERSITY CARROLL SCHOOL OF MANAGEMENT",
  "BOSTON UNIVERSITY COLLEGE OF COMMUNICATION",
  "BOURNEMOUTH UNIVERSITY",
  "BOUVE COLLEGE OF HEALTH SCIENCES AT NORTHEASTERN UNIVERSITY",
  "BOW VALLEY COLLEGE",
  "BOWIE STATE UNIVERSITY",
  "BOWLING GREEN STATE UNIVERSITY",
  "BOXHILL INSTITUTE OF TAFE",
  "BPP UNIVERSITY",
  "BRADLEY UNIVERSITY,PEORIA",
  "BRAND ACADEMY - UNIVERSITY OF APPLIED SCIENCES, HAMBURG",
  "BRANDEIS INTERNATIONAL BUSINESS SCHOOL",
  "BRANDEIS UNIVERSITY",
  "BRANDENBURG UNIVERSITY OF TECHNOLOGY COTTBUS",
  "BREDA UNIVERSITY OF APPLIED SCIENCES",
  "BREMEN UNIVERSITY OF APPLIED SCIENCES - HOCHSCHULE BREMEN",
  "BRIAR CLIFF UNIVERSITY",
  "BRIDGEWATER COLLEGE",
  "BRIDGEWATER STATE COLLEGE",
  "BRIDGEWATER STATE UNIVERSITY",
  "BRIGHAM YOUNG UNIVERSITY",
  "BRIGHTON COLLEGE",
  "BRISTOL COLLEGE",
  "BRITISH COLUMBIA INSTITUTE OF TECHNOLOGY",
  "Brno International Business School",
  "Brno University of Technology",
  "BROCK UNIVERSITY",
  "BROWN UNIVERSITY",
  "BROWN UNIVERSITY PROVIDENCE",
  "BRUNEL UNIVERSITY LONDON",
  "BUCERIUS LAW SCHOOL",
  "BUCKNELL UNIVERSITY",
  "BUDAPEST METROPOLITAN UNIVERSITY",
  "Budapest University of Technology and Economics",
  "BURG GIEBICHENSTEIN UNIVERSITY OF ART AND DESIGN",
  "Business Academy Aarhus",
  "Business Academy SouthWest",
  "BUSINESS AND HOTEL MANAGEMENT SCHOOL",
  "BUSINESS INSTITUTE AALBORG",
  "BUSINESS SCHOOL LAUSANNE",
  "BUTLER UNIVERSITY",
  "C3S BUSINESS SCHOOL, BARCELONA",
  "CA FOSCARI UNIVERSITY OF VENICE",
  "CALFLIN UNIVERSITY",
  "CALIFORNIA BAPTIST UNIVERSITY",
  "CALIFORNIA BAPTISTS UNIVERSITY",
  "CALIFORNIA COAST UNIVERSITY",
  "CALIFORNIA COLLEGE OF THE ARTS",
  "CALIFORNIA INSTITUTE OF TECHNOLOGY",
  "CALIFORNIA INTERNATIONAL UNIVERSITY",
  "CALIFORNIA LUTHERAN UNIVERSITY",
  "CALIFORNIA MIRAMAR UNIVERSITY",
  "CALIFORNIA NATIONAL UNIVERSITY",
  "CALIFORNIA POLYTECHNIC STATE UNIVERSITY, SAN LUIS OBISPO",
  "CALIFORNIA SOUTH BAY UNIVERSITY",
  "CALIFORNIA STATE POLYTECHNIC UNIVERSITY POMONA",
  "CALIFORNIA STATE POLYTECHNIC UNIVERSITY, POMONA",
  "CALIFORNIA STATE UNIVERSITY CHANNEL ISLANDS",
  "CALIFORNIA STATE UNIVERSITY CHICO",
  "CALIFORNIA STATE UNIVERSITY DOMINGUEZ HILLS",
  "CALIFORNIA STATE UNIVERSITY EAST BAY",
  "CALIFORNIA STATE UNIVERSITY FRESNO",
  "CALIFORNIA STATE UNIVERSITY FULLERTON",
  "CALIFORNIA STATE UNIVERSITY LONG BEACH",
  "CALIFORNIA STATE UNIVERSITY LOS ANGELES",
  "CALIFORNIA STATE UNIVERSITY NORTH RIDGE",
  "CALIFORNIA STATE UNIVERSITY, CHANNEL ISLANDS",
  "CALIFORNIA STATE UNIVERSITY, DOMINGUEZ HILLS",
  "CALIFORNIA STATE UNIVERSITY, EASTBAY",
  "CALIFORNIA STATE UNIVERSITY, FRESNO",
  "CALIFORNIA STATE UNIVERSITY, SAN BERNARDINO",
  "CALIFORNIA STATE UNIVERSITY, STANISLAUS",
  "CALIFORNIA STATE UNIVERSITY,BAKERSFIELD",
  "CALIFORNIA STATE UNIVERSITY,CHICO",
  "CALIFORNIA STATE UNIVERSITY,FRESNO",
  "CALIFORNIA STATE UNIVERSITY,FULLERTON",
  "CALIFORNIA STATE UNIVERSITY,LONG BEACH",
  "CALIFORNIA STATE UNIVERSITY,LOS ANGELES",
  "CALIFORNIA STATE UNIVERSITY,MONTEREY BAY",
  "CALIFORNIA STATE UNIVERSITY,NORTH RIDGE",
  "CALIFORNIA STATE UNIVERSITY,NORTHRIDGE",
  "CALIFORNIA STATE UNIVERSITY,SACRAMENTO",
  "CALIFORNIA STATE UNIVERSITY,SAN MARCOS",
  "CALIFORNIA UNIVERSITY OF MANAGEMENT & SCIENCE-ANAHEIM",
  "CALIFORNIA UNIVERSITY OF PENNSYLVANIA",
  "CAMBRIAN COLLEGE",
  "CAMBRIDGE INTERNATIONAL COLLEGE",
  "CAMBRIDGE JUDGE BUSINESS SCHOOL",
  "CAMERON UNIVERSITY",
  "CAMOSUN COLLEGE",
  "CAMPBELL UNIVERSITY",
  "Campbellsville University",
  "CANADIAN COLLEGE FOR HIGHER STUDIES",
  "CANADIAN COLLEGE OF HIGHER STUDIES",
  "CANADORE COLLEGE",
  "CANISIUS COLLEGE",
  "Canterbury Christ Church University",
  "CAPE BRETON UNIVERSITY",
  "CAPILANO UNIVERSITY",
  "CAPITAL UNIVERSITY, OHIO",
  "CARDIFF METROPOLITAN UNIVERSITY",
  "CARDIFF UNIVERSITY",
  "Carinthia University of Applied Sciences",
  "CARLETON COLLEGE",
  "CARLETON UNIVERSITY",
  "CARLOW BUSINESS SCHOOL",
  "CARNEGIE MELLON UNIVERSITY PITTSBURG",
  "CARNEGIE MELLON UNIVERSITY,PITTSBURG",
  "CARRICK INSTITUTE OF EDUCATION, MELBOURNE CAMPUS",
  "CASE WESTERN RESERVE UNIVERSITY,CLEVELAND",
  "CASS BUSINESS SCHOOL",
  "CASTLETON STATE UNIVERSITY",
  "Cat",
  "CATAWBA COLLEGE",
  "CATHOLIC UNIVERSITY OF AMERICA",
  "Catholic University of Sacred Heart",
  "CATHOLIC UNIVERSITY OF WEST",
  "Ca’ Foscari University of Venice",
  "CCS University",
  "CDE COLLEGE",
  "CEGEP DE LA GASPESIE ET DESLLES",
  "CEGEP MARIE VICTORINC",
  "CENTENNIAL COLLEGE",
  "CENTRAL CONNECTICUT STATE UNIVERSITY GREAT BRITAIN",
  "CENTRAL CONNECTICUT STATE UNIVERSITY, NEW BRITAIN",
  "Central European University- Austria campus",
  "CENTRAL MICHIGAN UNIVERSITY",
  "CENTRAL QUEENSLAND UNIVERSITY",
  "CENTRAL QUEENSLAND UNIVERSITY, BRISBANE INTERNATIONAL CAMPUS",
  "CENTRAL QUEENSLAND UNIVERSITY, GOLD COAST INTERNATIONAL CAMPUS",
  "CENTRAL QUEENSLAND UNIVERSITY, MELBOURNE INTERNATIONAL CAMPUS",
  "CENTRAL QUEENSLAND UNIVERSITY, SYDNEY CAMPUS",
  "CENTRAL WASHINGTON UNIVERSITY",
  "CentraleSupélec",
  "Centria University of Applied Sciences",
  "CESAR RITZ COLLEGES",
  "CEU Cardenal Herrera University",
  "CEU San Pablo University",
  "CHADRON STATE UNIVERSITY",
  "CHALMERS UNIVERSITY OF TECHNOLOGY",
  "CHAPMAN UNIVERSITY",
  "Chappe Institute",
  "CHARITE -UNIVERSITY MEDICINE BERLIN",
  "CHARITE UNIVERSITY MEDICINE BERLIN",
  "CHARLES DARWIN UNIVERSITY",
  "Charles III University of Madrid",
  "CHARLES STURT UNIVERSITY",
  "CHARLES UNIVERSITY",
  "CHARLESTON SOUTHERN UNIVERSITY",
  "CHEMNITZ UNIVERSITY OF TECHNOLOGY",
  "CHICAGO KENT COLLEGE OF LAW",
  "CHICAGO STATE UNIVERSITY",
  "CHOWAN UNIVERSITY",
  "CHRISTIAN BROTHERS UNIVERSITY",
  "CHRISTOPHER NEWPORT UNIVERSITY",
  "CHUNGNAM NATIONAL UNIVERSITY",
  "CHUR UNIVERSITY OF APPLIED SCIENCES",
  "CIMBA",
  "CITY COLLEGE OF NEW YORK",
  "CITY UNIVERSITY OF LONDON",
  "CITY UNIVERSITY OF NEW YORK",
  "CITY UNIVERSITY OF SEATTLE",
  "CLAFLIN UNIVERSITY",
  "CLAREMONT GRADUATE UNIVERSITY",
  "CLARION UNIVERSITY OF PENNSYLVANIA",
  "CLARK ATLANTA UNIVERSITY",
  "CLARK UNIVERSITY",
  "CLARKSON UNIVERSITY",
  "CLAUDE BERNARD UNIVERSITY LYON-1",
  "CLEARY UNIVERSITY",
  "CLEMSON UNIVERSITY",
  "CLEVELAND STATE UNIVERSITY",
  "COAST MOUNTAIN COLLEGE",
  "COASTAL CAROLINA UNIVERSITY",
  "COBURG UNIVERSITY OF APPLIED SCIENCES",
  "COE COLLEGE",
  "COGSWELL POLYTECHNICAL COLLEGE",
  "COLLEGE DE PARIS",
  "COLLEGE DE PARIS, PARIS CAMPUS",
  "COLLEGE OF ALAMEDA",
  "COLLEGE OF BUSINESS ADMINISTRATION-INHA UNIVERSITY",
  "COLLEGE OF LAKE COUNTRY",
  "COLLEGE OF NEW CALEDONIA",
  "COLLEGE OF NORTH ATLANTIC",
  "College of Rockies",
  "COLLEGE OF TECHNOLOGY LONDON",
  "COLLEGE OF THE ROCKIES",
  "COLLEGIUM CIVITAS",
  "COLOGNE BUSINESS SCHOOL",
  "COLOGNE UNIVERSITY OF APPLIED SCIENCES",
  "COLORADO CHRISTIAN UNIVERSITY",
  "COLORADO MESA UNIVERSITY",
  "COLORADO SCHOOL OF MINES",
  "COLORADO STATE UNIVERSITY PUEBLO",
  "COLORADO STATE UNIVERSITY, PUEBLO",
  "COLORADO STATE UNIVERSITY,FORT COLLINS",
  "COLORADO STATE UNIVERSITYFORT COLLINS",
  "Columbia College",
  "Columbia Southern University",
  "COLUMBIA UNIVERSITY",
  "COLUMBIA UNIVERSITY SCHOOL OF ARTS",
  "COLUMBIA UNIVERSITY, SCHOOL OF BUSINESS",
  "COLUMBIA UNIVERSITY, SCHOOL OF ENGINEERING AND APPLIED SCIENCE",
  "COLUMBUS STATE UNIVERSITY",
  "Comillas Pontifical University",
  "Complutense University of Madrid",
  "CONCORDIA UNIVERSITY",
  "CONCORDIA UNIVERSITY CHICAGO",
  "CONCORDIA UNIVERSITY WISCONSIN",
  "CONCORDIA UNIVERSITY, EDMONTON",
  "CONESTOGA COLLEGE",
  "CONESTOGA GUELPH CAMPUS",
  "CONFEDERATION COLLEGE",
  "Copenhagen Business Academy",
  "COPENHAGEN BUSINESS SCHOOL",
  "Copenhagen School of Design and Technology",
  "CORK INSTITUTE OF TECHNOLOGY",
  "CORNELL UNIVERSITY",
  "Corvinus University of Budapest",
  "COTRUGLI Business School",
  "COVENTRY UNIVERSITY",
  "Cracow University of Technology",
  "CRANFIELD SCHOOL OF MANAGEMENT",
  "CRANFIELD UNIVERSITY",
  "CREATIVE ACADEMY",
  "CREIGHTON UNIVERSITY",
  "crmtiger univercity",
  "CURTIN SINGAPORE",
  "CURTIN UNIVERSITY OF TECHNOLOGY",
  "CYPRUS INSTITUTE OF MANAGEMENT",
  "CYPRUS INTERNATIONAL INSTITUTE OF MANAGEMENT",
  "CYPRUS INTERNATIONAL UNIVERSITY",
  "Czech Management Institute",
  "Czech Technical University in Prague",
  "DAEMEN COLLEGE",
  "DAKOTA STATE UNIVERSITY",
  "DAKOTA WESLEYAN UNIVERSITY",
  "Dalarna University",
  "DALHOUSIE UNIVERSITY",
  "DALLAS BAPTIST UNIVERSITY",
  "DALLAS BAPTISTS UNIVERSITY",
  "Dania - Academy of Higher Education",
  "Danish School of Media and Journalism",
  "Danube University Krems",
  "DARTMOUTH COLLEGE",
  "DARTMOUTH MEDICAL SCHOOL",
  "Daugavpils University",
  "DAWSON COLLEGE",
  "DBS MUSIC",
  "DE MONTFORT UNIVERSITY",
  "DEAKIN UNIVERSITY",
  "DEGGENDORF INSTITUTE OF TECHNOLOGY",
  "DEGGENDORF UNIVERSITY OF APPLIED SCIENCES",
  "DELAWARE STATE UNIVERSITY",
  "DELFT INSTITUTE OF TECHNOLOGY",
  "DELFT UNIVERSITY OF TECHNOLOGY",
  "DELTA STATE UNIVERSITY",
  "DENISON UNIVERSITY",
  "DEPAUL UNIVERSITY,CHICAGO",
  "DESIGN ACADEMY BERLIN ( SRH Hochschule )",
  "Design School Kolding",
  "DESSAU INSTITUTE OF ARCHITECTURE",
  "DEVRY UNIVERSITY",
  "Diaconia University of Applied Sciences",
  "DIGIPEN INSTITUTE OF TECHNOLOGY",
  "DIGITAL FILM ACADEMY",
  "DOMAIN ACADEMY",
  "DOMINICAN UNIVERSITY OF CALIFORNIA",
  "Domus Academy",
  "DONGGUK BUSINESS SCHOOL",
  "DORSET COLLEGE",
  "DORTMUND UNIVERSITY OF APPLIED SCIENCE AND ARTS",
  "Douglas College",
  "DOUGLOUS COLLEGE",
  "DOWLING COLLEGE",
  "DRAKE UNIVERSITY,DES MONIES",
  "DRESDEN INTERNATIONAL GRADUATE SCHOOL FOR BIO-MEDICINE AND BIO-ENGINEERING",
  "DRESDEN INTERNATIONAL UNIVERSITY",
  "DRESDEN UNIVERSITY",
  "DREW UNIVERSITY",
  "DREXEL UNIVERSITY,PHILADELPHIA",
  "DRUCKER SCHOOL OF MANAGEMENT",
  "DRURY UNIVERSITY",
  "DUALE HOCHSCHULE BADEN-W",
  "DUBLIN BUSINESS SCHOOL",
  "DUBLIN CITY UNIVERSITY",
  "DUBLIN INSTITUTE OF TECHNOLOGY",
  "DUKE UNIVERSITY, DURHAM",
  "DUNDALK INSTITUTE OF TECHNOLOGY",
  "DUQUESNE UNIVERSITY",
  "DURHAM BUSINESS SCHOOL",
  "DURHAM COLLEGE",
  "DURHAM UNIVERSITY",
  "EAE Business School",
  "EALING HAMMERSMITH AND WEST LONDON COLLEGE",
  "EAST CAROLINA UNIVERSITY",
  "EAST STROUDSBURG UNIVERSITY OF PENNSYLVANIA",
  "EAST TENNESSEE STATE UNIVERSITY,JOHNSON CITY",
  "EASTERN CONNECTICUT STATE UNIVERSITY",
  "EASTERN ILLINOIS UNIVERSITY,CHARLESTON",
  "EASTERN INSTITUTE OF TECHNOLOGY",
  "EASTERN KENTUCKY UNIVERSITY",
  "EASTERN MEDITERRANEAN UNIVERSITY",
  "EASTERN MENNONITE UNIVERSITY",
  "EASTERN MICHIGAN UNIVERSITY",
  "EASTERN NAZERENE COLLEGE",
  "EASTERN NEW MEXICO UNIVERSITY",
  "EASTERN WASHINGTON UNIVERSITY",
  "EBERSWALDE UNIVERSITY OF SUSTAINABLE DEVELOPEMENT",
  "EBS BUSINESS SCHOOL",
  "EBS UNIVERSITY",
  "ECEMA BUSINESS SCHOOL LYON CAMPUS",
  "ECOLE CENTRALE DE LILLE",
  "ECOLE CENTRALE DE NANTES",
  "ECOLE CENTRALE LYON",
  "ECOLE CONTE PARIS CAMPUS",
  "ÉCOLE DE MANAGEMENT DE NORMANDIE",
  "ECOLE DE MANAGEMENT STRASBOURG",
  "ECOLE DES MINES D'ALBI-CARMAUX",
  "Ecole des Ponts ParisTech",
  "ECOLE NATIONALE DE  I'AVIATION CIVILE",
  "ECOLE NATIONALE DEI'AVIATION CIVILE",
  "ECOLE NATIONALE SUPERIEURE DE MECANIQUE ET D'AEROTECHNIQUE",
  "École normale supérieure Paris-Saclay",
  "ECOLE POLYTECHNIQUE",
  "ECOLE POLYTECHNIQUE FEDERALE DE LAUSANNE",
  "ÉCOLE SUPÉRIEURE DE COMMERCE DE PAU",
  "EDGEWOOD COLLEGE",
  "EDHEC BUSINESS SCHOOL",
  "EDINBURGH NAPIER UNIVERSITY",
  "EDITH COWAN UNIVERSITY",
  "EDMONDS COMMUNITY COLLEGE",
  "EICAR, The International Film and Television School of Paris",
  "EIIE EURASIA INSTITUTE FOR INTERNATIONAL EDUCATION GMBH, BERLIN",
  "EINDHOVEN UNIVERSITY OF TECHNOLOGY",
  "EIT DIGITAL MASTER SCHOOL",
  "EM STRASBOURG BUSINESS SCHOOL",
  "EMAS BUSINESS SCHOOL",
  "EMBRY-RIDDLE AERONAUTICAL UNIVERSITY, DAYTONA BEACH",
  "EMBRY-RIDDLE AERONAUTICAL UNIVERSITY, PRESCOTT ARIZONA CAMPUS",
  "EMILY CARR UNIVERSITY",
  "EMLYON BUSINESS SCHOOL",
  "EMORY UNIVERSITY",
  "ENSG",
  "Eötvös Loránd University",
  "EPF GRADUATE SCHOOL OF ENGINEERING",
  "EPITA GRADUATE SCHOOL OF COMPUTER SCIENCE",
  "Erasmus University Rotterdam",
  "ERASMUS UNIVERSITY, ROTTERDUM",
  "ESADE BUSINESS AND LAW SCHOOL",
  "ESAIP GRADUATE SCHOOL OF ENGINEERING",
  "ESART CAMPUS BARCELONA",
  "ESC DIJON / BURGUNDY SCHOOL OF BUSINESS",
  "ESC RENNES SCHOOL OF BUSINESS",
  "ESCP BUSINESS SCHOOL - TURIN CAMPUS",
  "ESCP BUSINESS SHOOL",
  "ESCP EUROPE",
  "ESCP EUROPE BUSINESS SCHOOL",
  "ESCUELA UNIVERSITARIA DE HOSTELERIA Y TURISMO",
  "ESIC Business & Marketing School",
  "ESIEE PARIS",
  "ESLSCA BUSINESS SCHOOL",
  "ESMOD",
  "ESMT BERLIN",
  "ESSEC BUSINESS SCHOOL",
  "ESSEEC BUSINESS SCHOOL",
  "ESSLINGEN UNIVERSITY OF APPLIED SCIENCE",
  "ESSLINGEN UNIVERSITY OF APPLIED SCIENCES",
  "ESTACA",
  "Estonian Academy of Arts",
  "Estonian Academy of Music and Theatre",
  "Estonian Business School",
  "ESTONIAN ENTREPRENEURSHIP UNIVERSITY OF APPLIED SCIENCES",
  "Estonian University of Life Sciences",
  "ESTP PARIS",
  "ETH ZURICH",
  "EU Business School",
  "EU BUSINESS SCHOOL BARCELONA",
  "EU BUSINESS SCHOOL MUNICH",
  "EU MUNICH BUSINESS SCHOOL",
  "EURECOM",
  "EUROPA FACHHOCHSCHULE FRESENIUS (IDSTEIN)",
  "EUROPA UNIVERSITAT FLENSBURG",
  "EUROPEAN BUSINESS INSTITUTE",
  "European Humanities University",
  "EUROPEAN MOLECULAR BIOLOGY LABORATORY",
  "EUROPEAN UNIVERSITY OF BUSINESS SCHOOL",
  "EUROPEAN UNIVERSITY OF BUSINESS SCHOOL GENEVA",
  "EUROPEAN UNIVERSITY OF BUSINESS SCHOOL MONTREUX",
  "EUROPEAN UNIVERSITY OF BUSINESS SCHOOL MUNICH",
  "European University of Tourism",
  "European University of Valencia",
  "EUROPEAN UNIVERSITY VIADRINA FRANKFURT",
  "Evergreen College",
  "FACHHOCHSCHULE AACHEN",
  "FACHHOCHSCHULE DORTMUND UNIVERSITY OF APPLIED SCIENCES AND ARTS",
  "FACHHOCHSCHULE FRANKFURT AM MAIN",
  "FACHHOCHSCHULE GELSENKIRCHEN",
  "FACHHOCHSCHULE GIE",
  "FACHHOCHSCHULE KIEL",
  "FACHHOCHSCHULE OSNABRUCK",
  "FACHHOCHSCHULE SUDWESTFALEN",
  "FACHHOCHSCHULE WEST COAST",
  "FACHHOCHSCHULE WORMS",
  "FAIRFIELD UNIVERISTY",
  "FAIRLEIGH DICKINSON UNIVERSITY",
  "FAIRLEIGH DICKINSON UNIVERSITY, MADISON CAMPUS",
  "FAIRLEIGH DICKINSON UNIVERSITY, METROPOLITAN CAMPUS",
  "FAIRLEIGH DICKINSON UNIVERSITY, TEANECK CAMPUS",
  "FAIRLEIGH DICKINSON UNIVERSITY, VANCOUVER",
  "FALMOUTH UNIVERSITY",
  "FANSHAWE COLLEGE",
  "Far Eastern Federal University (FEFU)",
  "FASHION INSTITUTE OF TECHNOLOGY",
  "FEDERATION UNIVERSITY",
  "FEDERATION UNIVERSITY, BRISBANE",
  "FERNUNIVERSITAT HAGEN",
  "FERRANDI PARIS",
  "FERRIS STATE UNIVERSITY",
  "FH AACHEN - UNIVERSITY OF APPLIED SCIENCES",
  "FH Joanneum",
  "FH MITTELSTANDS BERLIN CAMPUS",
  "FH MUNSTER UNIVERSITY OF APPLIED SCIENCES  - STEINFURT",
  "FISHER COLLEGE",
  "FITCHBURGH STATE UNIVERSITY",
  "Flanders Business School",
  "FLEMING COLLEGE",
  "FLENSBURG UNIVERSITY OF APPLIED SCIENCES",
  "FLINDERS UNIVERSITY",
  "Florence Institute Of Design International",
  "FLORIDA A and M UNIVERSITY",
  "FLORIDA ATLANTIC UNIVERSITY",
  "FLORIDA GULF COAST UNIVERSITY",
  "FLORIDA INSTITUTE OF TECHNOLOGY ,MELBOURNE",
  "FLORIDA INSTITUTE OF TECHNOLOGY MELBOURNE",
  "FLORIDA INTERNATIONAL UNIVERSITY,MIAMI",
  "FLORIDA INTERNATIONAL UNIVERSITYMIAMI",
  "FLORIDA POLYTECHNIC UNIVERSITY",
  "FLORIDA STATE UNIVERSITY,TALLAHASSEE",
  "FLORIDA STATE UNIVERSITYTALLAHASSEE",
  "FOHHOCHSCHULE KARNTEN",
  "FOLKWANG UNIVERSITY OF THE ARTS",
  "Fontys University of Applied Science",
  "Fontys University of Applied Sciences",
  "FORDHAM UNIVERSITY",
  "FORT HAYS STATE UNIVERSITY",
  "FORT VALLEY STATE UNIVERSITY",
  "FRAMINGHAM STATE UNIVERSITY",
  "FRANCE BUSINESS SCHOOL",
  "FRANKFURT SCHOOL OF FINANCE AND MANAGEMENT",
  "FRANKLIN PIERCE COLLEGE",
  "FRANKLIN UNIVERSITY",
  "FRASER INTERNATIONAL COLLEGE",
  "FREDERICK INSTITUTE OF TECHNOLOGY",
  "FREDERICK UNIVERSITY",
  "FREE UNIVERSITY OF BOZEN BOLZANO",
  "FREIBERG TU BERGAKADEMIE",
  "FREIBERG UNIVERSITY OF MINING AND TECHNOLOGY",
  "FREIE UNIVERSIT",
  "FREIE UNIVERSITY OF BERLIN",
  "FRIEDRICH ALEXANDER UNIVERSITAT ERLANGEN NURNBERG",
  "FRIEDRICH SCHILLER UNIVERSITY JENA",
  "FRIEDRICH-SCHILLER-UNIVERSITAT JENA",
  "FROSTBURG STATE UNIVERSITY",
  "FULDA UNIVERSITY OF APPLIED SCIENCES",
  "FULL SAIL UNIVERSITY",
  "FULLERTON COLLEGE",
  "GALLAUDET UNIVERSITY",
  "GALWAY-MAYO INSTITUTE OF TECHNOLOGY",
  "GANNON UNIVERSITY,ERIE",
  "Gasma - Gastronomy & Culinary Management Campus",
  "GAVLE UNIVERSITY COLLEGE",
  "Gdansk University of Technology",
  "GENEVA BUSINESS SCHOOL",
  "GENEVA SCHOOL OF ECONOMICS AND MANAGEMENT",
  "GEORG AUGUST UNIVERSITAT GOTTINGEN",
  "GEORG-SIMON-OHM-HOCHSCHULE F",
  "GEORGE BROWN COLLEGE",
  "GEORGE MASON UNIVERSITY",
  "GEORGE WASHINGTON UNIVERSITY",
  "GEORGETOWN UNIVERSITY",
  "GEORGIA COLLEGE & STATE UNIVERSITY",
  "GEORGIA INSTITUTE OF TECHNOLOGY",
  "GEORGIA SOUTHERN UNIVERSITY",
  "GEORGIA SOUTHWESTERN STATE UNIVERSITY",
  "GEORGIA STATE UNIVERSITY",
  "GEORGIAN COLLEGE",
  "GERMAN SPORT UNIVERSITY COLOGNE",
  "Ghent University",
  "GIRNE AMERICAN UNIVERSITY",
  "GISMA BUSINESS SCHOOL",
  "Glamis Business School",
  "GLASGOW CALEDONIAN UNIVERSITY",
  "GLASGOW UNIVERSITY",
  "GLENDALE COMMUNITY COLLEGE",
  "GLION INSTITUTE OF HIGHER EDUCATION",
  "GLYNDWR UNIVERSITY",
  "GOETHE-UNIVERSITY OF FRANKFURT",
  "GOLDEN GATE UNIVERSITY",
  "GOLDEY BEACOM COLLEGE",
  "Goldsmiths, University of London",
  "GONZAGA UNIVERSITY",
  "GORDON INSTITUTE OF TAFE",
  "GOTEBORG UNIVERSITY",
  "GOTHENBURG SCHOOL OF ECONOMICS",
  "GOTTINGEN GRADUATE SCHOOL FOR NEUROSCIENCES AND MOLECULAR BIOSCIENCES",
  "GOVERNORS STATE UNIVERSITY",
  "Graiciunas School of Management",
  "GRAMBLING STATE UNIVERSITY",
  "Grand Canyon University",
  "GRAND VALLEY STATE UNIVERSITY",
  "GRANT MACEWAN UNIVERSITY",
  "Graz University of Technology",
  "GRENOBLE INSTITUTE OF TECHNOLOGY",
  "GRENOBLE SCHOOL OF MANAGEMENT",
  "GRIFFITH COLLEGE DUBLIN",
  "GRIFFITH UNIVERSITY",
  "GRIFFITH UNIVERSITY, GOLD COAST CAMPUS",
  "H",
  "H-FARM",
  "Haaga-Helia University of Applied Sciences",
  "HAFENCITY UNIVERSITY OF HAMBURG",
  "Halmstad University",
  "HAMBURG UNIVERSITY OF APPLIED SCIENCE",
  "HAMBURG UNIVERSITY OF TECHNOLOGY",
  "HÄME UNIVERSITY OF APPLIED SCIENCES",
  "HAMK H",
  "HAMLINE UNIVERSITY",
  "HAMPTON UNIVERSITY",
  "HAN UNIVERSITY OF APPLIED SCIENCE",
  "HAN UNIVERSITY OF APPLIED SCIENCES",
  "HANKEN SCHOOL OF ECONOMICS",
  "HANYANG UNIVERSITY BUSINESS SCHOOL",
  "HANZE INTERNATIONAL BUSINESS SCHOOL",
  "HANZE UNIVERSITY OF APPLIED SCIENCES",
  "Harrison Middleton University",
  "HARTWICK COLLEGE",
  "Harvard Univ - F'21",
  "HARVARD UNIVERSITY",
  "HARZ UNIVERSITY OF APPLIED SCIENCES",
  "HASSELT UNIVERSITY",
  "HASSO LATTNER INSTITUT",
  "HAWAII PACIFIC UNIVERSITY",
  "HEADMASTERS ADVANCED ACADEMY TRAINING",
  "HEC MONTREAL",
  "HEC PARIS",
  "HECTOR SCHOOL OF BUSINESS",
  "Hedmark University of Applied Sciences",
  "HEILBRONN UNIVERSITY",
  "HEINZ SCHOOL AUSTRALIA",
  "Helmholtz International Graduate School for Cancer Research",
  "HELMHOLTZ-ZENTRUM DREDEN-ROSSENDORF",
  "HELMUT SCHMIDT UNIVERSITY",
  "Helsinki Metropolia University of Applied Sciences",
  "HELSINKI SCHOOL OF BUSINESS",
  "HENLEY BUSINESS SCHOOL",
  "Henley Business School Malta",
  "HERIOT WATT UNIVERSITY",
  "HERIOT-WATT UNIVERSITY",
  "HERTIE SCHOOL OF GOVERNANCE",
  "HHL LEIPZIG GRADUATE SCHOOL OF MANAGEMENT",
  "Higher School of Economics (HSE)",
  "HILDESHEIM HOLZMINDEN GOTTINGEN",
  "HOCHSCHULE BONN RHEIN SIEG",
  "HOCHSCHULE BREMEN",
  "HOCHSCHULE BREMERHAVEN",
  "HOCHSCHULE DARMSTADT UNIVERSITY OF APPLIED SCIENCES",
  "HOCHSCHULE DUSSELDORF",
  "HOCHSCHULE DUSSELDORF UNIVERSITY OF APPLIED SCIENCES",
  "HOCHSCHULE EMDEN -LEER",
  "HOCHSCHULE FRESENIUS",
  "HOCHSCHULE FUR ANGEWANDTE WISSENSCHAFTEN HAMBURG",
  "HOCHSCHULE FUR ANGEWANDTE WISSENSCHAFTEN MUNCHEN",
  "HOCHSCHULE FUR TECHNIK UND WIRTSCHAFT BERLIN",
  "HOCHSCHULE FURTWANGEN UNIVERSITY",
  "HOCHSCHULE KAISERSLAUTERN UNIVERSITY OF APPLIED SCIENCES",
  "HOCHSCHULE KARLSRUHE - TECHNIK UND WIRTSCHAFT",
  "HOCHSCHULE LANDSHUT UNIVERSITY OF APPLIED SCIENCES",
  "HOCHSCHULE MUNCHEN",
  "Hochschule Neu-Ulm University of Applied Sciences (HNU)",
  "HOCHSCHULE NIEDERRHEIN, UNIVERSITY OF APPLIED SCIENCES",
  "HOCHSCHULE NORDHAUSEN- UNIVERSITY OF APPLIED SCIENCES",
  "HOCHSCHULE OFFENBURG",
  "HOCHSCHULE PFORZHEIM",
  "HOCHSCHULE RAVENSBURG-WEINGARTEN",
  "HOCHSCHULE STRALSUND",
  "HOCHSCHULE WISMAR",
  "HOF UNIVERSITY OF APPLIED SCIENCES",
  "HOFSTRA UNIVERSITY",
  "HOGESCHOOL-UNIVERSITEIT BRUSSEL",
  "HOLLAND COLLEGE",
  "HOLMES INSTITUTE",
  "HOLMESGLEN INSTITUTE OF TAFE",
  "HOOD COLLEGE",
  "HOTEL AND TOURISM MANAGEMENT INSTITUTE",
  "HOTEL SCHOOL, SYDNEY",
  "HOWARD UNIVERSITY",
  "Howest University College West Flanders",
  "HTW BERLIN UNIVERSITY OF APPLIED SCIENCE",
  "HTW BERLIN-UNIVERSITY OF APPLIED SCIENCES",
  "HULT INTERNATIONAL BUSINESS SCHOOL",
  "HUMAK University of Applied Sciences",
  "HUMBER COLLEGE",
  "HUMBOLDT STATE UNIVERSITY",
  "HUMBOLDT UNIVERSITY BERLIN",
  "IBA INTERNATIONAL BUSINESS ACADEMY",
  "IBC Henley",
  "IBD BUSINESS SCHOOL",
  "IBO BUSINESS SCHOOL",
  "ICBS Business College",
  "Iceland University of the Arts",
  "ICHEC BRUSSELS MANAGEMENT SCHOOL",
  "IDAHO STATE UNIVERSITY",
  "IE BUSINESS SCHOOL",
  "IE University",
  "IESE Business School",
  "IESEG SCHOOL OF MANAGEMENT",
  "IFS INTERNATIONAL FILMSCHOOL COLOGNE",
  "IGLOBAL UNIVERSITY",
  "IIBIT_ADELAIDE CAMPUS OF UNIVERSITY OF BALLARAT",
  "IIBIT_SYDNEY CAMPUS",
  "IIBIT_SYDNEY CAMPUS OF UNIVERSITY OF BALLARAT",
  "IIMENAU UNIVERSITY OF TECHNOLOGY",
  "Iḷisaġvik College",
  "ILLINOIS INSTITUTE OF TECHNOLOGY ARMOUR",
  "ILLINOIS INSTITUTE OF TECHNOLOGY CHICAGO",
  "ILLINOIS INSTITUTE OF TECHNOLOGY, ARMOUR",
  "ILLINOIS INSTITUTE OF TECHNOLOGY, CHICAGO",
  "ILLINOIS INSTITUTE OF TECHNOLOGY, STUART",
  "ILLINOIS STATE UNIVERSITY",
  "IMC University of Applied Sciences Krems",
  "IMD BUSINESS SCHOOL",
  "IMPERIAL COLLEGE BUSINESS SCHOOL",
  "IMPERIAL COLLEGE LONDON",
  "IMT ATLANTIQUE ENGINEERING SCHOOL",
  "IMT SCHOOL FOR ADVANCED STUDIES LUCCA",
  "INDIANA INSTITUTE OF TECHNOLOGY",
  "INDIANA STATE UNIVERSITY,TERREHAUTE",
  "INDIANA STATE UNIVERSITYTERREHAUTE",
  "INDIANA UNIVERSITY BLOOMINGTON",
  "INDIANA UNIVERSITY OF PENNSYLVANIA",
  "INDIANA UNIVERSITY-PURDUE UNIVERSITY, FORT WAYNE",
  "INDIANA UNIVERSITY-PURDUE UNIVERSITY,INDIANAPOLIS",
  "INFORMATICS COLLEGE",
  "INSA BUSINESS MARKETING AND COMMUNICATION, BARCELONA",
  "INSA Lyon",
  "INSA RENNES",
  "INSEAD BUSINESS SCHOOL",
  "INSEAD-EUROPE CAMPUS",
  "INSEEC BUSINESS SCHOOL",
  "INSEEC BUSINESS SCHOOL, Bordeaux",
  "INSEEC BUSINESS SCHOOL, PARIS",
  "Institut Barcelona d'Estudis Internacionals",
  "INSTITUT NATIONAL POLYTECHNIC TOULOUSE",
  "INSTITUT SUPERIEUR DE AERONAUTIQUE ET DE",
  "INSTITUT SUPERIEUR DE L'AERONAUTIQUE ET DE L'ESPACE",
  "Institut supérieur du commerce de Paris",
  "INSTITUTE FOR ADVANCED ARCHITECTURE OF CATALONIA",
  "INSTITUTE MACHINE TOOLS AND INDUSTRIAL MANAGEMENT",
  "Institute of Management Technology, Dubai",
  "Institute of Polytechnic Science and Aeronautics",
  "INSTITUTE OF TECHNOLOGY SLIAGO",
  "Institute of Technology Sligo",
  "INSTITUTE OF TECHNOLOGY, BLANCHARDSTOWN",
  "INSTITUTE OF TECHNOLOGY,CARLOW",
  "International Baptist College and Seminary",
  "International Business Academy",
  "International Business School at Vilnius University",
  "INTERNATIONAL COLLEGE OF HOTEL MANAGEMENT (ICHM)",
  "INTERNATIONAL COLLEGE OF MANITOBA",
  "International Graduate Center",
  "INTERNATIONAL GRADUATE CENTER BREMEN",
  "International Hellenic University",
  "INTERNATIONAL INSTITUTE OF BUSINESS AND INFORMATION TECHNOLOGY",
  "INTERNATIONAL ISLAMIC UNIVERSITY MALAYSIA",
  "International Islamic University of Malaysia",
  "INTERNATIONAL LEIBNIZ RESEARCH SCHOOL",
  "International Management Institute of Saint Petersburg",
  "INTERNATIONAL MAX PLANCK RESEARCH SCHOOL",
  "INTERNATIONAL MINES ALBI GRADUATE SCHOOL OF ENGINEERING",
  "International School of Law and Business",
  "INTERNATIONAL SCHOOL OF MANAGEMENT",
  "INTERNATIONAL SPACE UNIVERSITY",
  "INTERNATIONAL TECHNOLOGICAL UNIVERSITY",
  "INTERNATIONAL UNIVERSITY",
  "INTERNATIONAL UNIVERSITY IN GENEVA",
  "INTERNATIONAL UNIVERSITY IN GERMANY",
  "INTERNATIONAL UNIVERSITY OF APPLIED SCIENCES BAD HONNEF-BONNE",
  "International University of Catalonia",
  "International University of La Rioja",
  "INTERNATIONAL UNIVERSITY OF LOGISTICS AND TECHNOLOGY",
  "INTI COLLEGE SUBANG JAYA",
  "IOWA STATE UNIVERSITY,AMES",
  "ISC PARIS BUSINESS SCHOOL",
  "ISEP ENGINEERING SCHOOL",
  "ISEP SCHOOL OF ELECTRONICS AND TELE COMMUNICATION",
  "ISI, L'institut Supérieur d'Informatique",
  "ISITECH BUSINESS SCHOOL",
  "ISM University of Management and Economics",
  "ISMA University",
  "ISMA University of Applied Sciences",
  "ISTITUTO EUROPEO DI DESIGN",
  "IT University of Copenhagen",
  "ITM MINE ALBI- CARMAUX",
  "ITMO University",
  "IU INTERNATIONAL UNIVERSITY OF APPLIED SCIENCES",
  "J?zeps V?tols Latvian Academy of Music",
  "JACKSON STATE UNIVERSITY",
  "JACKSONVILLE STATE UNIVERSITY",
  "JACOBS UNIVERSITY BREMEN",
  "Jagiellonian University",
  "JAMES COOK UNIVERSITY",
  "JAMES COOK UNIVERSITY, SINGAPORE CAMPUS",
  "JAMES MADISON UNIVERSITY",
  "JAMK University of Applied Sciences",
  "Jaume I University",
  "Johannes Gutenberg-Universität Mainz",
  "Johannes Kepler University Linz",
  "John Cabot University",
  "JOHN HOPKINS BLOOMBERG SCHOOL OF PUBLIC HEALTH",
  "JOHN HOPKINS UNIVERSITY",
  "JOHN JAY COLLEGE OF CRIMINAL JUSTICE",
  "JOHNSON AND WALES UNIVERSITY",
  "JONKOPING UNIVERSITY",
  "JONKOPING UNIVERSITY FOUNDATION",
  "JULIUS MAXIMILIAN UNIVERSITY",
  "Justice Institute of British Columbia",
  "JYVASKYLA UNIVERSITY",
  "Kajaani University of Applied Sciences",
  "KANGAN BATMAN INSTITUTE OF TAFE",
  "KANSAS STATE UNIVERSITY,MANHATTAN",
  "KANSAS STATE UNIVERSITYMANHATTAN",
  "KAPLAN BUSINESS SCHOOL",
  "KAPLAN HIGHER EDUCATION ACADEMY",
  "Karelia University of Applied Sciences",
  "KARLSHOCHSCHULE INTERNATIONALL UNIVERSITY",
  "KARLSRUHE INSTITUTE OF TECHNOLOGY",
  "KARLSRUHE UNIVERSITY OF APPLIED SCIENCES",
  "Karlstad University",
  "Karolinska Institutet",
  "KASSEL UNIVERSITY",
  "KATHOLIEKE UNIVERSITEIT LEUVEN",
  "KATHOLIEKE UNIVERSITY OF LEUVEN",
  "KAUNAS UNIVERSITY OF TECHNOLOGY",
  "Kaunas University of Technology (KTU)",
  "Kauno Kolegija University of Applied Sciences",
  "Kazan Federal University",
  "Kazimieras Simonavi?ius University",
  "KdG University of Applied Sciences and Arts",
  "KEAN UNIVERSITY OF NEW JERSEY",
  "KEDGE BUSINESS SCHOOL",
  "Keele University",
  "KELLOGG SCHOOL OF MANAGEMENT",
  "KELLOGG SCHOOL OF SCIENCE AND TECHNOLOGY, CALIFORNIA",
  "KEMPTEN UNIVERSITY OF APPLIED SCIENCES",
  "Kenai Peninsula College",
  "KENNESAW STATE UNIVERSITY",
  "KENT STATE UNIVERSITY",
  "KETTERING UNIVERSITY",
  "KEYANO COLLEGE",
  "KEYCE BUSINESS SCHOOL MONTPELIER CAMPUS",
  "King Juan Carlos University",
  "KINGS COLLEGE LONDON",
  "KINGS SEMINARY",
  "KINGSTON UNIVERSITY",
  "Klaip?da Business Higher School",
  "Klaip?da State College",
  "Klaipeda University",
  "Kodiak College",
  "KOLN INTERNATIONAL SCHOOL OF DESIGN",
  "KOREA UNIVERSITY BUSINESS SCHOOL",
  "KRISTIANSTAD UNIVERSITY",
  "KTH Royal Institute of Technology",
  "KTH THE ROYAL INSTITUTE OF TECHNOLOGY",
  "KTH, THE ROYAL INSTITUTE OF TECHNOLOGY",
  "KTH-ROYAL INSTITUTE OF TECHNOLOGY",
  "KU Leuven",
  "KUEHNE LOGISTICS UNIVERSITY",
  "KUHEN LOGISTICS UNIVERSITY",
  "Kwantlen Polytechnic University",
  "Kymenlaakso University of Applied Sciences",
  "LA SALLE UNIVERSITY",
  "LA SIERRA UNIVERSITY, RIVERSIDE",
  "LA TROBE UNIVERSITY",
  "LAB UNIVERSITY OF APPLIED SCIENCE",
  "LABA Free Academy Fine Arts",
  "Lahti University of Applied Sciences",
  "LAKEHEAD UNIVERSITY",
  "LAMAR UNIVERSITY,BEAUMONT",
  "LAMART COLLEGE OF TECHNOLOGY",
  "LAMBTON COLLEGE",
  "LAMBTON COLLEGE, TORONTO",
  "LANCASTER UNIVERSITY",
  "Lancaster University Management School",
  "LANGARA COLLEGE",
  "LANSBRIDGE UNIVERSITY",
  "Lapland University of Applied Sciences",
  "LAPPEENRANTA  UNIVERSITY OF TECHNOLOGY",
  "Lappeenranta University of Technology",
  "LASALLE COLLEGE, MONTREAL",
  "LATROBE UNIVERSITY",
  "Latvia University of Agriculture",
  "Latvia University of Life Sciences & Technologies",
  "LATVIA UNIVERSITY OF SCIENCE AND TECHNOLOGY",
  "Latvian Academy of Culture",
  "Latvian Academy of Sport Education (LASE)",
  "Latvian Christian Academy",
  "Latvian Maritime Academy",
  "Laurea University of Applied Sciences",
  "LAURENTIAN UNIVERSITY",
  "LAUSITZ UNIVERSITY OF APPLIED SCIENCES",
  "Laval University",
  "LAWRENCE TECHNOLOGICAL UNIVERSITY",
  "Lazarski University",
  "LCC International University",
  "LCI BARCELONA",
  "LE CORDON BLEU",
  "LEBANON VALLEY COLLEGE",
  "LEEDS BECKETT UNIVERSITY",
  "LEHIGH UNIVERSITY ,BETHLAHEM",
  "LEIBNIZ UNIVERSITAT HANNOVER",
  "LEIBNIZ UNIVERSITY OF HANOVER",
  "LEIDEN UNIVERSITY",
  "LEIPZIG UNIVERSITY",
  "LEONARD DE VINCE",
  "LES ROCHES",
  "LETOURNEAU UNIVERSITY",
  "Letterkenny Institute of Technology",
  "Letterkenny International of Technology (Killybegs Campus)",
  "Letterkenny International of Technology (Letterkenny Campus)",
  "LEUPHANA UNIVERSITY OF LUNEBURG",
  "LEWIS UNIVERSITY",
  "LIAISON COLLEGE",
  "Liberty university",
  "Liepaja University",
  "Lillebaelt Academy of Professional Higher Education",
  "Lillehammer University College",
  "LIMAK Austrian Business School",
  "Limerick Institute of Technology",
  "LINCOLN COLLEGE",
  "LINCOLN UNIVERSITY",
  "LINK CAMPUS UNIVERSITY",
  "LINKOPING UNIVERSITY",
  "Linnaeus University",
  "Lithuania Business University of Applied Sciences",
  "Lithuanian Academy of Music and Theatre",
  "Lithuanian Sports University",
  "Lithuanian University of Health Sciences",
  "LIVERPOOL HOPE UNIVERSITY",
  "LIVERPOOL JOHN MOORES UNIVERSITY",
  "LODZ UNIVERSITY OF TECHNOLOGY",
  "LOMA LINDA UNIVERSITY",
  "Lomonosov Moscow State University (MSU)",
  "Lomonosov Moscow State University Business School",
  "LONDON BUSINESS SCHOOL",
  "London Business School (LBS) - Dubai",
  "LONDON COLLEGE OF BUSINESS",
  "LONDON COLLEGE OF FASHION",
  "LONDON METROPOLITAN UNIVERSITY",
  "LONDON SCHOOL OF BUSINESS AND FINANCE",
  "LONDON SCHOOL OF HYGIENE AND TROPICAL MEDICINE",
  "LONDON SOUTH BANK UNIVERSITY",
  "LONDON SOUTHBANK UNIVERSITY",
  "LONG ISLAND UNIVERSITY CW POST CENTER",
  "LONG ISLAND UNIVERSITY, CW POST CENTER",
  "LONG ISLAND UNIVERSITY,BROOKYLN",
  "LONG ISLAND UNIVERSITYBROOKYLN",
  "LOUGHBOROUGH UNIVERSITY",
  "LOUISIANA STATE UNIVERSITY, HEALTH SCIENCES CENTER",
  "LOUISIANA STATE UNIVERSITY,BATON ROUGE",
  "LOUISIANA TECHNOLOGICAL UNIVERSITY,RUSTON",
  "Louvain School of Management",
  "LOYALIST COLLEGE",
  "LOYOLA MARYMOUNT UNIVERSITY,LOS ANGELES",
  "LOYOLA UNIVERSITY MARYLAND",
  "LOYOLA UNIVERSITY OF CHICAGO",
  "Lucerne University of Applied Sciences and Arts",
  "LUDWIG MAXIMILIANS UNIVERSITAT MUNICH",
  "Ludwig-Maximilians-Universität München",
  "LUISS GUIDO CARLI",
  "LULEA UNIVERSITY OF TECHNOLOGY",
  "LUND UNIVERSITY",
  "LUND UNIVERSTY",
  "LYNN UNIVERSITY",
  "M TAUBMAN COLLEGE OF ARCHITECTURE AND URBAN PLANNING",
  "MAASTRICHT UNIVERSITY",
  "MACQUARIE UNIVERSITY",
  "Macromedia University",
  "MAHARISHI UNIVERSITY  OF MANAGEMENT",
  "MAINE MARITIME ACADEMY",
  "MALARDALEN UNIVERSITY COLLEGE",
  "MALMO UNIVERSITY",
  "Malta College of Arts, Science and Technology (MCAST)",
  "Malta Institute of Management",
  "MANAGEMENT DEVELOPMENT INSTITUTE OF SINGAPORE",
  "MANCGESTER METROPOLITAN UNIVERSITY",
  "MANCHESTER BUSINESS SCHOOL",
  "MANCHESTER METROPOLITAN UNIVERSITY",
  "MANCHESTER SCHOOL OF ART",
  "MANCHESTER UNIVERSITY",
  "MANHATTAN INSTITUTE OF MANAGEMENT",
  "MANHATTANVILLE COLLEGE",
  "MANNHEIM UNIVERSITY OF APPLIED SCIENCES",
  "MANSFIELD UNIVERSITY OF PENNSYLVANIA",
  "MANUKAU INSTITUTE OF TECHNOLOGY",
  "MARBELLA DESIGN ACADEMY",
  "MARCHE POLYTECHNIC UNIVERSITY",
  "Marijampol? College",
  "MARIST COLLEGE",
  "MARITIME GREENWICH COLLEGE",
  "MARKETING INSTITUTE OF SINGAPORE",
  "MARQUETTE UNIVERSITY - MILWAUKEE",
  "MARSHALL UNIVERSITY",
  "MARTIN LUTHER UNIVERSITY HALLE-WITTENBERG",
  "MARTIN LUTHER UNIVERSITY OF HALLE-WITTENBERG",
  "MARYMOUNT UNIVERSITY, ARLINGTON",
  "MARYWOOD UNIVERSITY",
  "Masaryk University",
  "MASSACHUSETTS COLLEGE OF ART AND DESIGN",
  "MASSACHUSETTS COLLEGE OF PHARMACY AND HEALTH SCIENCES, BOSTON CAMPUS",
  "MASSACHUSETTS INSTITUTE OF TECHNOLOGY",
  "MASSACHUSETTS INSTITUTE OF TECHNOLOGY,SLOAN",
  "MASSEY UNIVERSITY",
  "Matanuska Susitna College",
  "MAX DELBR",
  "Max Delbrueck Center for Molecular Medicine",
  "MAX PLANCK INSTITUTE FOR HUMAN COGNITIVE AND BRAIN SCIENCES",
  "MAX PLANCK INSTITUTE FOR MARINE MICROBIOLOGY",
  "MAX PLANCK INSTITUTE FOR NUCLEAR PHYSICS",
  "MAYNOOTH UNIVERSITY",
  "MC NEESE STATE UNIVERSITY, LAKE CHARLES",
  "MCGILL UNIVERSITY",
  "MCMASTER UNIVERSITY",
  "MCPHS UNIVERSITY, BOSTON",
  "MEDICAL UNIVERSITY OF SOUTH CAROLINA",
  "MEDICINE HAT COLLEGE",
  "MEDIZINISCHE HOCHSCHULE HANNOVER (MHH)",
  "MELBOURNE BUSINESS SCHOOL",
  "MELBOURNE COLLEGE OF HAIR & BEAUTY",
  "MELBOURNE INSTITUE OF TECHNOLOGY",
  "MELBOURNE INSTITUTE OF BUSINESS AND TECHNOLOGY",
  "MELBOURNE INSTITUTE OF TECHNOLOGY (MELBOURNE CAMPUS",
  "MELBOURNE INSTITUTE OF TECHNOLOGY (MELBOURNE CAMPUS OF UNIVERSITY OF BALLARAT)",
  "MELBOURNE INSTITUTE OF TOURISM AND HOSPITALITY",
  "MELBOURNE INTERNATIONAL COLLEGE",
  "MEMORIAL UNIVERSITY",
  "MERCER UNIVERSITY",
  "MERCYHURST UNIVERSITY",
  "MERRIMACK COLLEGE",
  "MESSIAH COLLEGE",
  "MET film school",
  "METROPOLIA UNIVERSITY OF APPLIED SCIENCE",
  "Metropolia University of Applied Sciences",
  "METROPOLITAN STATE UNIVERSITY",
  "Metropolitan University College",
  "Metropolitan University Prague",
  "MIAMI UNIVERSITY, OHIO",
  "MIB TRIESTE SCHOOL OF MANAGEMENT",
  "MICHIGAN STATE UNIVERSITY,EAST LANSING",
  "MICHIGAN STATE UNIVERSITYEAST LANSING",
  "MICHIGAN TECHNOLOGICAL UNIVERSITY,HOUGHTON",
  "Mid Sweden University",
  "MIDDLE TENNESSEE STATE UNIVERSITY,MURSFREEBORO",
  "MIDDLESEX UNIVERSITY",
  "Middlesex University Malta",
  "MIDS BUSINESS SCHOOL",
  "MIDWESTERN STATE UNIVERSITY",
  "Midwestern University",
  "Mikkeli University of Applied Sciences",
  "MILWAUKEE SCHOOL OF ENGINEERING",
  "MINNESOTA STATE UNIVERSITY,MANKATO",
  "MIP MILAN POLYTECHNIC UNIVERSITY",
  "MISSISSIPPI COLLEGE",
  "MISSISSIPPI STATE UNIVERSITY,MISSISSIPPI STATE",
  "MISSOURI STATE UNIVERSITY",
  "MISSOURI UNIVERSITY OF SCIENCE AND TECHNOLOGY",
  "MISSOURI WESTERN STATE UNIVERSITY",
  "MITTWEIDA UNIVERSITY OF APPLIED SCIENCES",
  "MODUL University Vienna",
  "MOHAWK COLLEGE",
  "Molde University College - Specialized University in Logistics",
  "MONASH UNIVERSITY",
  "MONMOUTH UNIVERSITY",
  "MONROE COLLEGE",
  "MONTANA STATE UNIVERSITY,BOZEMAN",
  "MONTCLAIR STATE UNIVERSITY",
  "MONTPELLIER BUSINESS SCHOOL",
  "MONTREAL COLLEGE OF INFORMATION TECHNOLOGY",
  "MOREHEAD STATE UNIVERSITY",
  "MORGAN STATE UNIVERSITY",
  "Moscow Institute of Physics and Technology (MIPT)",
  "MOSCOW INTERNATIONAL HIGHER BUSINESS SCHOOL MIRBIS",
  "MOSCOW SCHOOL OF MANAGEMENT SKOLKOVO",
  "Moscow University Touro",
  "MOUNT MERCY UNIVERSITY",
  "MOUNT SAINT VINCENT UNIVERSITY",
  "MOUNT SINAI SCHOOL OF MEDICINE",
  "Multimedia University - MMU Cyberjaya",
  "MUNICH BUSINESS SCHOOL",
  "MUNICH INTELLECTUAL PROPERTY LAW CENTER",
  "MUNICH UNIVERSITY OF APPLIED SCIENCE",
  "MUNICH UNIVERSITY OF TECHNOLOGY",
  "MURDOCH UNIVERSITY",
  "Murdoch University Dubai",
  "MURRAY STATE UNIVERSITY",
  "MUSICIANS INSTITUTE",
  "Mykolas Romeris University",
  "NANYANG INSTITUTE OF MANAGEMENT",
  "NANYANG TECHNOLOGICAL UNIVERSITY",
  "NATIONAL CALIFORNIA STATE UNIVERSITY",
  "NATIONAL CENTER FOR FOOD SAFETY AND TECHNOLOGY",
  "NATIONAL COLLEGE OF IRELAND",
  "NATIONAL INSTITUTE OF EDUCATION,SINGAPORE",
  "National Paralegal College",
  "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
  "National Technical University of Athens",
  "NATIONAL UNIVERSITY",
  "National University of Distance Education",
  "NATIONAL UNIVERSITY OF IRELAND",
  "NATIONAL UNIVERSITY OF IRELAND GALWAY",
  "National University of Science and Technology MISiS",
  "NATIONAL UNIVERSITY OF SINGAPORE",
  "NEAPOLIS UNIVERSITY PAFOS",
  "NEAR EAST UNIVERSITY",
  "NEBRASKA COLLEGE OF TECHNICAL AGRICULTURE",
  "NEBRASKA WESLEYAN UNIVERSITY",
  "Nebrija University",
  "NELSON MARLBOROUGH INSTITUTE OF TECH",
  "NEOMA BUSINESS SCHOOL",
  "NEW BRUNSWICK COMMUNITY COLLEGE",
  "NEW BULGARIAN UNIVERSITY",
  "NEW ENGLAND COLLEGE",
  "NEW ENGLAND INSTITUTE OF TECHNNOLOGY",
  "NEW EUROPEAN COLLEGE",
  "NEW JERSEY CITY UNIVERSITY",
  "NEW JERSEY INSTITUTE OF TECHNOLOGY",
  "NEW JERSEY INSTITUTE OF TECHNOLOGY NEWARK",
  "NEW JERSEY INSTITUTE OF TECHNOLOGY, NEWARK",
  "NEW MEXICO INSTITUTE OF MINING TECHNOLOGY, SOCORRO",
  "NEW MEXICO STATE UNIVERISTY, LAS CRUCES",
  "NEW YORK FILM ACADEMY",
  "NEW YORK FILM ACADEMY AUSTRALIA",
  "NEW YORK INSTITUTE OF TECHNOLOGY, LONG ISLAND",
  "NEW YORK INSTITUTE OF TECHNOLOGY, MANHATTAN",
  "NEW YORK INSTITUTE OF TECHNOLOGY,NEW YORK",
  "NEW YORK INSTITUTE OF TECHNOLOGY,OLD WESTBURY",
  "NEW YORK INSTITUTE OF TECHNOLOGY,VANCOUVER",
  "NEW YORK INSTITUTE OF TECHNOLOGYOLD WESTBURY",
  "NEW YORK INSTITUTE OF TECHNOLOGYVANCOUVER",
  "NEW YORK MEDICAL COLLEGE",
  "NEW YORK SCHOOL OF INTERIOR DESIGN",
  "NEW YORK UNIVERSITY",
  "NEW YORK UNIVERSITY BUFFALO",
  "NEW YORK UNIVERSITY COLLEGE OF DENTISTRY",
  "NEW YORK UNIVERSITY SCHOOL OF LAW",
  "NEW YORK UNIVERSITY SCHOOL OF PROFESSIONAL STUDIES",
  "NEW YORK UNIVERSITY STREN SCHOOL OF BUSINESS",
  "NEW YORK UNIVERSITY TANDON SCHOOL OF ENGINEERING",
  "NEW YORK UNIVERSITY, BROOKLYN",
  "NEW YORK UNIVERSITY, BUFFALO",
  "NEW YORK UNIVERSITY, COURANT",
  "NEW YORK UNIVERSITY, GRADUATE SCHOOL OF ARTS AND SCIENCE",
  "NEW YORK UNIVERSITY, SCHOOL OF CONTINUING AND PROFESSIONAL STUDIES",
  "NEW ZEALAND COLLEGE",
  "NEWCASTLE UNIVERSITY",
  "NHH - Norwegian School of Economics",
  "NIAGARA COLLEGE",
  "Nicolaus Copernicus University",
  "NLA University College",
  "Nord University",
  "NORFOLK STATE UNIVERSITY",
  "Normal School of Lyon",
  "NORQUEST COLLEGE",
  "NORTH ALBERTA INSTITUTE OF TECHNOLOGY",
  "NORTH AMERICAN UNIVERSITY",
  "NORTH CAROLINA A&T STATE UNIVERSITIY",
  "NORTH CAROLINA STATE UNIVERSITY RALEIGH",
  "NORTH CENTRAL COLLEGE",
  "NORTH DAKOTA STATE UNIVERSITY, FARGO",
  "NORTH EASTERN ILLINOIS UNIVERSITY",
  "NORTH ISLAND COLLEGE",
  "NORTH WEST MISSOURI STATE UNIVERSITY, MARYVILLE",
  "NORTH WESTERN POLYTECHNIC UNIVERSITY",
  "NORTHEASTERN UNIVERSITY BOSTON",
  "NORTHEASTERN UNIVERSITY CANADA",
  "NORTHEASTERN UNIVERSITY SCHOOL OF ARTS AND SCIENCES",
  "NORTHEASTERN UNIVERSITY SCHOOL OF ENGINEERING",
  "NORTHEASTERN UNIVERSITY SEATTLE",
  "NORTHEN COLLEGE",
  "NORTHERN ALBERTA INSTITUTE OF TECHNOLOGY",
  "NORTHERN ARIZONA UNIVERSITY",
  "NORTHERN COLLEGE",
  "NORTHERN COLLEGE AT PURES TORONTO",
  "NORTHERN ILLINOIS UNIVERSITY,DEKALB",
  "NORTHERN INSTITUTE OF TECHNOLOGY MANAGEMENT",
  "NORTHERN KENTUCKY UNIVERSITY",
  "NORTHERN LIGHTS COLLEGE",
  "Northern Lithuania College",
  "NORTHERN MICHIGAN UNIVERSITY",
  "NORTHTECH (WHANGAREI CAMPUS)",
  "NORTHUMBRIA UNIVERSITY",
  "NORTHUMBRIA UNIVERSITY (LONDON CAMPUS)",
  "NORTHUMBRIA UNIVERSITY (NEWCASTLE CAMPUS)",
  "NORTHUMBRIA UNIVERSITY(london campus)",
  "Northumbria University, London Campus",
  "NORTHWEST MISSOURI STATE UNIVERSITY",
  "NORTHWESTERN POLYTECHNIC UNIVERSITY",
  "NORTHWESTERN UNIVERSITY",
  "NORTHWESTERN UNIVERSITY KELLOGG",
  "NORTHWOOD  UNIVERSITY",
  "Norwegian Academy of Music",
  "NORWEGIAN SCHOOL OF MANAGEMENT",
  "Norwegian University of Life Sciences",
  "Norwegian University of Science and Technology",
  "NOTTINGHAM TRENT UNIVERSITY",
  "NOVA SOUTHEASTERN UNIVERSITY",
  "NOVANCIA BUSINESS SCHOOL",
  "Novia University of Applied Sciences",
  "Novosibirsk State University",
  "NUI Galway",
  "Nuova Accademia di Belle Arti",
  "NUREMBERG INSTITUTE OF TECHNOLOGY OF GEORG SIMON OHM",
  "NURTINGEN-GEISLINGEN UNIVERSITY OF APPLIED SCIENCE",
  "NYENRODE BUSINESS UNIVERSITY",
  "OAKLAND UNIVERSITY,ROCHESTER",
  "Oakwood University",
  "OBUDA UNIVERSITY",
  "Odisee University College",
  "OFFENBURG UNIVERSITY OF APPLIED SCIENCES",
  "OHIO STATE UNIVERSITY OF PUBLIC HEALTH",
  "OHIO STATE UNIVERSITY, COLUMBUS",
  "OHIO UNIVERSITY,ATHENS",
  "OKANAGAN COLLEGE",
  "OKLAHOMA CHRISTIAN UNIVERSITY",
  "OKLAHOMA CITY UNIVERSITY",
  "OKLAHOMA STATE UNIVERSITY TULSA",
  "OKLAHOMA STATE UNIVERSITY, TULSA",
  "OKLAHOMA STATE UNIVERSITY,STILLWATER",
  "OKLAHOMA STATE UNIVERSITYSTILLWATER",
  "OLD DOMINION UNIVERSITY,NORFOLK",
  "ONTARIO COLLEGE OF ART AND DESIGN",
  "ONTARIO COLLEGES",
  "ONTARIO INSTITUTE OF TECHNOLOGY",
  "Ontario Tech University",
  "Open University of Catalonia",
  "ORAL ROBERTS UNIVERSITY",
  "OREBRO UNIVERSITY",
  "OREGON STATE UNIVERSITY,CORVALLIS",
  "Oslo and Akershus University College of Applied Sciences",
  "OSNABRÜCK UNIVERSITY",
  "OSTFALIA UNIVERSITY OF APPLIED SCIENCES",
  "OSTWESTFALEN-LIPPE UNIVERSITY APPLIED SCIENCES",
  "Ottawa University",
  "OTTO VON GUERICKE UNIVERSITY OF MAGDEBURG",
  "OULU BUSINESS SCHOOL",
  "Oulu University of Applied Sciences",
  "OUR LADY OF THE LAKE UNIVERSITY",
  "OXFORD BROOKES UNIVERSITY",
  "Pablo de Olavide University",
  "PACE UNIVERSITY NEW YORK CITY",
  "PACE UNIVERSITY, NEW YORK CITY",
  "PACIFIC LUTHERAN UNIVERSITY",
  "PACIFIC STATES UNIVERSITY",
  "PALACKY UNIVERSITY OLOMOUC",
  "PANTHEON SORBONNE UNIVERSITY",
  "Panthéon-Assas University Paris II",
  "PARIS BUSINESS SCHOOL",
  "PARKLAND COLLEGE",
  "PARSONS NEW SCHOOLS OF DESIGNS",
  "Paul Sabatier University",
  "Paul Valéry University",
  "PAULS SMITH S COLLEGE",
  "PENNSYLVANIA STATE OF UNIVERSITY,HARIRISBURG",
  "PENNSYLVANIA STATE OF UNIVERSITY,UNIVERSITY PARK",
  "PENNSYLVANIA STATE OF UNIVERSITYHARIRISBURG",
  "Peoples' Friendship University of Russia",
  "PEPPERDINE UNIVERSITY",
  "Perth College of Beauty Therapy",
  "PERTH INSTITUTE OF BUSINESS AND TECHNOLOGY",
  "Peter the Great St. Petersburg Polytechnic University",
  "PFEIFFER UNIVERSITY",
  "PFH PRIVATE UNIVERSITY OF APPLIED SCIENCES GOTTINGEN",
  "PFH-Private University OfApplied Science",
  "PFORZHEIM UNIVERSITY OF APPLIED SCIENCES",
  "PHILADELPHIA UNIVERSITY",
  "PHILIPPS UNIVERSITY MARBURG",
  "Phoenix Seminary",
  "PIERRE AND MARIE CURIE UNIVERSITY",
  "PITTSBURG STATE UNIVERSITY",
  "PLYMOUTH MARJON UNIVERSITY",
  "PLYMOUTH STATE UNIVERSITY",
  "POINT BLANK MUSIC COLLEGE",
  "POINT LOMA UNIVERSITY",
  "POINT PARK UNIVERSITY",
  "Pole Universitaire Leonard De Vinci",
  "POLI.design",
  "POLIMODA UNIVERSITY",
  "POLISH ACADEMY OF SCIENCES -INSTITUTE OF ECONOMICS",
  "POLITECHNICO DI MILANO",
  "Politechnika Bialostocka University",
  "Politecnico di Bari",
  "POLITECNICO DI MILANO",
  "POLITECNICO DI TORINO",
  "Polytechnic Institute of Lisbon",
  "Polytechnic School of Design",
  "POLYTECHNIC SCHOOL OF THE UNIVERSITY ORLEANS",
  "Polytechnic University of Catalonia",
  "POLYTECHNIC UNIVERSITY OF PUERTO RICO",
  "POLYTECHNIC UNIVERSITY OF TORINO",
  "POLYTECHNIC UNIVERSITY, BROOKLYN CAMPUS",
  "Pompeu Fabra University",
  "PORTLAND STATE UNIVERSITY",
  "POST UNIVERSITY",
  "POZNAN UNIVERSITY OF ECONOMICS",
  "POZNAN UNIVERSITY OF TECHNOLOGY",
  "Prague International Business School",
  "PRAIRIE VIEW A and M UNIVERSITY",
  "PRATT INSTITUTE",
  "Prescott College",
  "Prince William Sound College",
  "PRINCETON UNIVERSITY",
  "PSB ACADAMY",
  "PURDUE UNIVERSITY CALUMET",
  "PURDUE UNIVERSITY NORTHWEST",
  "PURDUE UNIVERSITY, CALUMET",
  "PURDUE UNIVERSITY, WEST LAFAYETTE",
  "Putra Business School",
  "Quasar Design University",
  "QUEEN MARGARET UNIVERSITY",
  "QUEEN MARY UNIVERSITY",
  "QUEEN MARY UNIVERSITY OF LONDON",
  "QUEENS UNIVERSITY",
  "QUEENS UNIVERSITY BELFAST",
  "QUEENSLAND INSTITUTE OF BUSINESS AND TECHNOLOGY",
  "QUEENSLAND INTERNATIONAL BUSINESS ACADEMY",
  "QUEENSLAND UNIVERSITY OF TECHNOLOGY",
  "QUINNIPIAC UNIVERSITY",
  "QUT BUSINESS SCHOOL",
  "R?ga Stradi?",
  "RADBOUD UNIVERSITY",
  "Radford university",
  "RAFFLES COLLEGE OF HIGHER EDUCATION",
  "RAFFLES UNIVERSITY ISKANDAR, MALAYSIA",
  "Ramon Llull University",
  "RAVENSBOURNE UNIVERSITY",
  "RED RIVER COLLEGE",
  "REGENSBURG UNIVERSITY OF APPLIED SCIENCES",
  "REGENT UNIVERSITY",
  "REGIS UNIVERSITY",
  "RENNESELAER POLYTECHNIC INSTITUTE, TROY",
  "RENSSELAER POLYTECHNIC INSTITUTE",
  "REUTLINGEN UNIVERSITY",
  "REYKJAVIK UNIVERSITY",
  "REYKJAVIK UNIVERSITY-SCHOOL OF BUSINESS",
  "Rezekne Academy of Technologies",
  "RHEINISCHWESTFALISCHE TECHNISCHE HOCHSCHULE AACHEN",
  "RHINE WAAL UNIVERSITY OF APPLIED SCIENCES",
  "RHINE WAAL UNIVERSIY OF APPLIED SCIENCE",
  "RHODE ISLAND SCHOOL OF DESIGN",
  "RICE UNIVERSITY, HOUSTON",
  "RICHARD STOCKTON COLLEGE OF NEW JERSEY",
  "RIDER UNIVERSITY",
  "Riga Aeronautical Institute",
  "Riga Graduate School of Law",
  "RIGA INTERNATIONAL SCHOOL OF ECONOMICS AND BUSINESS ADMINISTRATION",
  "RIGA TECHNICAL UNIVERSITY",
  "RISEBA UNIVERSITY OF BUSINESS, ARTS AND TECHNOLOGY",
  "RIVIER UNIVERSITY",
  "RMIT UNIVERSITY",
  "ROBERT GORDON UNIVERSITY",
  "ROBERT MORRIS COLLEGE",
  "ROCHESTER INSTITUTE OF TECHNOLOGY",
  "ROCKY MOUNTAIN COLLEGE OF ART + DESIGN",
  "ROGER WILLIAMS UNIVERSITY",
  "Roma Tre University",
  "Rome Business School",
  "ROOSEVELT UNIVERSITY",
  "ROSALIND FRANKLIN  UNIVERSITY",
  "ROSENHEIM UNIVERSITY O0F APPLIED SCIENCES",
  "ROSENHEIM UNIVERSITY OF APPLIED SCIENCES",
  "Roskilde University",
  "ROTTERDAM BUSINESS SCHOOL",
  "Rovira i Virgili University",
  "ROWAN UNIVERSITY, GLASSBORO",
  "ROYAL CANADIAN INSTITUTE OF TECHNOLOGY",
  "ROYAL DANISH ACADEMY OF FINE ARTS",
  "ROYAL HOLLOWAY UNIVERSITY OF LONDON",
  "ROYAL INSTITUTE OF TECHNOLOGY",
  "ROYAL IRISH INTERNATIONAL BUSINESS SCHOOL",
  "ROYAL MILITARY COLLEGE OF CANADIAN",
  "ROYAL ROADS UNIVERSITY",
  "RUHR-UNIVERSITAT BOCHUM",
  "Ruprecht-Karls-Universität Heidelberg",
  "RUSH UNIVERSITY",
  "RUTGERS BUSINESS SCHOOL NEWARK CAMPUS",
  "RUTGERS BUSINESS SCHOOL, NEWARK CAMPUS",
  "RUTGERS THE STATE UNIVERSITY OF NEW JERSEY, NEWARK CAMPUS",
  "RUTGERS THE STATE UNIVERSITY OF NEW JERSEY,NEW BRUNSWICK",
  "RUTGERS THE STATE UNIVERSITY OF NEW JERSEY-CAMDEN",
  "RUTGERS THE STATE UNIVERSITY OF NEW JERSEYNEW BRUNSWICK CAMPUS",
  "RWTH AACHEN UNIVERSITY",
  "RWTH AALEN UNIVERSITY",
  "RYERSON UNIVERSITY",
  "S",
  "SAA GLOBAL EDUCATION",
  "SAARLAND UNIVERSITY",
  "SAARLAND UNIVERSITY OF APPLIED SCIENCES - HTW SAAR",
  "SAC GERMANY",
  "SACRED HEART UNIVERSITY",
  "SAE INSTITUTE",
  "SAGINAW VALLEY STATE UNIVERSITY",
  "SAID BUSINESS SCHOOL",
  "Saimaa University of Applied Sciences",
  "SAINT FRANCIS COLLEGE",
  "SAINT JOSEPH UNIVERSITY",
  "SAINT LEO UNIVERSITY",
  "SAINT LOUIS UNIVERSITY",
  "SAINT MARTIN'S UNIVERSITY",
  "SAINT MARY'S UNIVERSITY OF MINNESOTA",
  "SAINT MARYS UNIVERSITY",
  "SAINT PETERS UNIVERSITY",
  "Saint Petersburg Electrotechnical University LETI",
  "salem university",
  "SALFORD UNIVERSITY",
  "SALISBURY UNIVERSITY",
  "SAM HOUSTON STATE UNIVERSITY",
  "Samara University",
  "Samford University",
  "SAN DIAGO STATE UNIVERSITY,SAN DIAGO",
  "SAN DIEGO STATE UNIVERSITY",
  "SAN FRANCISCO STATE UNIVERSITY,SAN FRANCISCO",
  "SAN FRANCISCO STATE UNIVERSITYSAN FRANCISCO",
  "SAN JOSE STATE UNIVERSITY",
  "SANTA CLARA UNIVERSITY",
  "SAPIENZA UNIVERSITY OF ROME",
  "SASKATCHEWAN POLYTECHNIC",
  "SATAKUNTA UNIVERSITY OF APPLIED SCIENCE",
  "SAULT COLLEGE- BRAMPTON CAMPUS",
  "SAVANNAH COLLEGE OF ART AND DESIGN",
  "Savonia University of Applied Sciences",
  "SAXION UNIVERSITY OF APPLIED SCIENCES",
  "Schellhammer Business School",
  "SCHILLER INTERNATIONAL UNIVERSITY",
  "SCHMALKALDEN UNIVERSITY OF APPLIED SCIENCES",
  "School of Advanced Air and Space Studies",
  "School of Architecture (Taliesin West)",
  "SCHOOL OF BUSINESS NATIONAL LOUIS UNIVERSITY",
  "SCHOOL OF PHARMACY, UNIVERSITY OF LONDON",
  "SCHOOL OF THE ART INSTITUTE OF CHICAGO",
  "SCHULICH SCHOOL OF BUSINESS",
  "Sciences Po",
  "SCUOLA POLITECNICA DI DESIGN SPD",
  "Scuola Superiore Sant'Anna",
  "SDA BOCCONI SCHOOOL OF MANAGEMENT",
  "SEATTLE PACIFIC UNIVERSITY",
  "SEATTLE UNIVERSITY",
  "Sein",
  "SEINAJOKI UNIVERSITY OF APPLIED SCIENCE",
  "SEJONG UNIVERSITY",
  "SELKIRK COLLEGE",
  "Semmelweis University",
  "SENECA COLLEGE",
  "Seneca College, Newnham Campus",
  "SEOUL NATIONAL UNIVERSITY",
  "SETON HALL UNIVERSITY",
  "SEVANAN BUSINESS SCHOOL",
  "SHAFSTON COLLEGE",
  "SHANNON COLLEGE OF HOTEL MANAGEMENT",
  "SHEFFIELD HALLAM UNIVERSITY",
  "SHELTON COLLEGE INTERNATIONAL",
  "SHENANDOAH UNIVERSITY",
  "SHEPHERD UNIVERSITY",
  "SHERIDAN COLLEGE",
  "SHORELINE COMMUNITY COLLEGE",
  "SILESIAN UNIVERSITY OF TECHNOLOGY",
  "SILICON VALLEY UNIVERSITY",
  "SIMON FRASER UNIVERSITY",
  "SINGAPORE INSTITUTE OF MANAGEMENT",
  "SINGAPORE INSTITUTE OF MANAGEMENT ( University at Buffalo,The State University of New York)",
  "SINGAPORE INSTITUTE OF TECHNOLOGY",
  "SINGAPORE MANAGEMENT UNIVERSITY",
  "SINGAPORE UNIVERSITY OF TECHNOLOGY AND DESIGN",
  "SKEMA BUSINESS SCHOOL",
  "SLIPPERY ROCK UNIVERSITY",
  "SMBS - Business School",
  "SMITH COLLEGE",
  "SMK University of Applied Social Sciences",
  "SOAS, UNIVERSITY OF LONDON",
  "SOBEY SCHOOL OF BUSINESS",
  "SOLBRIDGE INTERNATIONAL SCHOOL OF BUSINESS",
  "SOLENT UNIVERSITY",
  "Solvay Brussels School of Economics and Management",
  "Sophiahemmet University College",
  "Sorbonne University",
  "SOUTH AUSTRALIAN INSTITUTE OF BUSINESS AND TECHNOLOGY",
  "SOUTH CAROLINA STATE UNIVERSITY",
  "SOUTH DAKOTA STATE UNIVERSITY, BROOKINGS",
  "South University",
  "South Ural State University",
  "SOUTH WESTPHALIA UNIVERSITY OF APPLIED SCIENCE",
  "SOUTHBANK INSTITUTE OF TAFE",
  "SOUTHEAST MISSOURI STATE UNIVERSITY",
  "SOUTHEASTERN LOUISIANA UNIVERSITY",
  "SOUTHERN ARKANSAS UNIVERSITY",
  "SOUTHERN CROSS UNIVERSITY",
  "SOUTHERN ILLINOIS UNIVERSITY CARBONDALE",
  "SOUTHERN ILLINOIS UNIVERSITY, CARBONDALE",
  "SOUTHERN ILLINOIS UNIVERSITY, EDWARDSVILLE",
  "SOUTHERN INSTITUTE OF TECHNOLOGY",
  "SOUTHERN METHODIST UNIVERSITY, DALLAS",
  "SOUTHERN NEW HAMPSHIRE",
  "SOUTHERN NEW HAMPSHIRE UNIVERSITY, MANCHESTER",
  "SOUTHERN POLYTECHNIC STATE UNIVERSITY, MARIETTA",
  "SOUTHERN STATES UNIVERSITY",
  "SOUTHERN UNIVERSITY A and M COLLEGE, BATON ROUGE",
  "Southwest College of Naturopathic Medicine and Health Sciences",
  "SP Jain School of Global Management",
  "SPEOS PARIS PHOTOGRAPHIC INSTITUTE",
  "Spring Hill College",
  "SRH Berlin university of applied sciences",
  "SRH DRESDEN",
  "SRH HOCHSCHULE BERLIN",
  "SRH HOCHSCHULE HAMM",
  "SRH HOCHSCHULE HEIDELBERG",
  "SRH University Berlin",
  "SRH UNIVERSITY OF APPLIED SCIENCES BERLIN",
  "SRH UNIVERSITY OF APPLIED SCIENCES HEIDELBERG",
  "ST CLOUD STATE UNIVERSITY",
  "ST JOHNS UNIVERSITY, JAMAICA",
  "ST JOHNS UNIVERSITY, QUEENS",
  "St Michel College",
  "St Petersburg University",
  "ST. CLAIR COLLEGE",
  "ST. GEORGE'S UNIVERSITY OF LONDON",
  "St. Ignatius of Loyola College",
  "ST. JOHNS UNIVERSITY",
  "ST. LAWRENCE COLLEGE",
  "ST. MARYS UNIVERSITY",
  "St.FRANCIS XAVIER UNIVERSITY",
  "ST.FRANSIS UNIVERSITY",
  "STAFFORDSHIRE UNIVERSITY",
  "STANFORD UNIVERSITY",
  "STATE UNIVERSITY OF NEW YORK AT BINGHAMTON",
  "STATE UNIVERSITY OF NEW YORK AT BUFFALO",
  "STATE UNIVERSITY OF NEW YORK AT FARMINGDALE",
  "STATE UNIVERSITY OF NEW YORK AT OLD WESTBURY",
  "STATE UNIVERSITY OF NEW YORK AT STONY BROOK",
  "STATE UNIVERSITY OF NEW YORK AT UTICA",
  "STATE UNIVERSITY OF NEW YORK COLLEGE AT NEW PALTZ",
  "STATE UNIVERSITY OF NEW YORK GENESEO",
  "STATE UNIVERSITY OF NEW YORK INSTITUTE OF TECHNOLOGY",
  "STATE UNIVERSITY OF NEW YORK PLATTSBURGH",
  "STATE UNIVERSITY OF NEW YORK POLYTECHNIC INSTITUTE AT UTICA",
  "STATE UNIVERSITY OF NEW YORK, ALBANY",
  "STATE UNIVERSITY OF NEW YORK, OSWEGO",
  "STATE UNIVERSITY OF NEW YORK, PLATTSBURGH",
  "STATE UNIVESRITY OF NEW YORK, FREDONIA",
  "STEPHEN F.AUSTIN STATE UNIVERSITY, NACOGDOCHES",
  "STEVENS INSTITUTE OF TECHNOLOGY,HOBOKEN",
  "STEVENS INSTITUTE OF TECHNOLOGYHOBOKEN",
  "STOCKHOLM BUSINESS SCHOOL",
  "STOCKHOLM SCHOOL OF ECONOMICS",
  "STOCKHOLM SCHOOL OF ECONOMICS IN RIGA",
  "Stockholm School of Economics in Russia",
  "Stockholm University",
  "STOCKHOLM UNIVERSITY OF THE ARTS",
  "STONY BROOK UNIVERSITY",
  "STRATFORD UNIVERSITY",
  "STRATHCLYDE BUSINESS SCHOOL",
  "Strathclyde Business School - Malaysia",
  "STUDIALIS INTERNATIONAL",
  "STUTTGART INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
  "STUTTGART UNIVERSITY OF APPLIED SCIENCES",
  "SUFFOLK UNIVERSITY, BOSTON",
  "SUFFOLK UNIVERSITY, MADRID CAMPUS",
  "SUNGKYUNKWAN UNIVERSITY",
  "SUNNY ALBANY",
  "SUNNY CORTLAND",
  "SUNY POLYTECHNIC INSTITUTE",
  "SWANSEA UNIVERSITY",
  "SWEDISH UNIVERSITY OF AGRICULTURAL SCIENCES",
  "SWINBURNE UNIVERSITY OF TECHNOLOGY",
  "SWINBURNE UNIVERSITY OF TECHNOLOGY, HAWTHORN CAMPUS",
  "SWINBURNE UNIVERSITY OF TECHNOLOGY,MELBOURNE",
  "SWISS HOTEL MANAGEMENT SCHOOL",
  "SWPSUNIVERSITY OF SOCIAL SCIENCES AND HUMANITY",
  "SYDNEY BUSINESS SCHOOL",
  "SYDNEY COLLEGE OF BUSINESS AND IT",
  "SYDNEY INSTITUTE OF BUSINESS AND TECHNOLOGY",
  "SYNERGY INSTITUTE OF ECONOMICS AND FINANCE",
  "SYRACUSE UNIVERSITY",
  "Széchenyi István University",
  "TAFE INTERNATIONAL WESTERN AUSTRALIA",
  "TAFE QUEENSLAND",
  "TAFE SOUTH AUSTRALIA",
  "Tallinn University",
  "Tallinn University of Technology",
  "Tampere University",
  "Tampere University Hervanta campus",
  "TAMPERE UNIVERSITY OF APPLIED SCIENCE",
  "Tampere University of Applied Sciences",
  "TAYLORS UNIVERSITY COLLEGE",
  "TECHNICAL UNIVERSITY INGOISTADT",
  "TECHNICAL UNIVERSITY INGOLSTADT",
  "TECHNICAL UNIVERSITY NUREMBERG OHM PROFESSIONAL SCHOOL",
  "TECHNICAL UNIVERSITY OF APPLIED SCIENCES WILDAU",
  "TECHNICAL UNIVERSITY OF BERLIN",
  "TECHNICAL UNIVERSITY OF CARTAGENA",
  "TECHNICAL UNIVERSITY OF CENTRAL HESSEN",
  "TECHNICAL UNIVERSITY OF CLAUSTHAL",
  "TECHNICAL UNIVERSITY OF COLOGNE",
  "TECHNICAL UNIVERSITY OF DENMARK",
  "TECHNICAL UNIVERSITY OF EINDHOVEN",
  "TECHNICAL UNIVERSITY OF KAISERSLAUTERN",
  "Technical University of Madrid",
  "TECHNICAL UNIVERSITY OF MUNICH",
  "TECHNION -ISRAEL INSTITUTE OF TECHNOLOGY",
  "TECHNISCHE HOCHSCHULE INGOLSTADT",
  "TECHNISCHE HOCHSCHULE NURNBERG",
  "TECHNISCHE UNIVERSIT BERLIN",
  "TECHNISCHE UNIVERSITAT BRAUNSCHWEIG",
  "TECHNISCHE UNIVERSITAT CHEMNITZ",
  "TECHNISCHE UNIVERSITAT CLAUSTHAL",
  "TECHNISCHE UNIVERSITAT DARMSTADT",
  "TECHNISCHE UNIVERSITAT DORTMUND",
  "TECHNISCHE UNIVERSITAT DRESDEN",
  "TECHNISCHE UNIVERSITAT ILMENAU",
  "TECHNISCHE UNIVERSITAT MUNCHEN",
  "TECHNISCHE UNIVERSITAT SIEGEN",
  "TECHNISCHE UNIVERSITAT WIEN",
  "TECHNISCHE UNIVERSITY HURBURG",
  "Technological University Dublin",
  "TECHNOLOGY ARTS SCIENCE TH KOLN",
  "Tecnocampus School of Health Sciences",
  "TEESSIDE UNIVERSITY",
  "TELECOM ECOLE DE MANAGEMENT",
  "TELECOM PARIS",
  "TELEMARK UNIVERSITY COLLEGE",
  "TELFER SCHOOL OIF MANAGEMENT, UNIVERSITY OF OTTAWA",
  "TEMPLE UNIVERSITY",
  "TENNESSE STATE UNIVERSITY",
  "TENNESSEE TECHNOLOGICAL UNIVERSITY, COOKEVILLE",
  "TENNESSEE TECHNOLOGICAL UNIVERSITY, MARTIN",
  "test",
  "TEST 3",
  "TEST1",
  "TEST2",
  "TEXAS A and M INTERNATIONAL UNIVERSITY,LAREDO",
  "TEXAS A and M INTERNATIONAL UNIVERSITYLAREDO",
  "TEXAS A and M UNIVERSITY COLLEGE STATION",
  "TEXAS A and M UNIVERSITY HEALTH SCIENCE CENTER",
  "TEXAS A and M UNIVERSITY KINGSVILLE",
  "TEXAS A and M UNIVERSITY, COLLEGE STATION",
  "TEXAS A and M UNIVERSITY, CORPUS CHRISTI",
  "TEXAS A and M UNIVERSITY, KINGSVILLE",
  "TEXAS A and M UNIVERSITY,COMMERCE",
  "TEXAS A and M UNIVERSITYCOMMERCE",
  "TEXAS CHRISTIAN UNIVERSITY",
  "TEXAS SOUTHERN UNIVERSITY",
  "TEXAS STATE UNIVERSITY SAN MARCOS",
  "TEXAS STATE UNIVERSITY, SAN MARCOS",
  "TEXAS TECH UNIVERSITY",
  "TEXAS TECHNICAL UNIVERSITY HEALTH SCIENCE CENTER",
  "TEXAS WESLEYAN UNIVERSITY",
  "TEXAS WOMANS UNIVERSITY, DENTON",
  "TH KOLN",
  "THAMES VALLEY UNIVERSITY",
  "THAYER SCHOOL OF ENGINEERING AT DARTMOUTH",
  "THE COLLEGE OF SAINT ROSE",
  "THE COLLEGE OF WILLIAM AND MARY",
  "The Danish National School of Performing Arts",
  "THE HAGUE UNIVERSITY OF APPLIED SCIENCES",
  "THE LIAUTAUD GRADUATE SCHOOL OF BUSINESS AT UIC",
  "THE LONDON SCHOOL OF ECONOMIC AND POLITICAL SCIENCE",
  "THE LONDON SCHOOL OF ECONOMICS AND POLITICAL SCIENCE",
  "THE MERIDIAN INTERNATIONAL HOTEL SCHOOL",
  "THE MERIDIAN INTERNATIONAL HOTEL SCHOOL, MELBOURNE CAMPUS",
  "THE MERIDIAN INTERNATIONAL HOTEL SCHOOL, SYDNEY CAMPUS",
  "The New School",
  "The Oslo School of Architecture and Design",
  "The Royal Academy of Music - Aarhus/Aalborg",
  "The Royal Danish Academy of Fine Arts",
  "The Royal Danish Academy of Music",
  "THE UNIVERSITY OF AUCKLAND",
  "THE UNIVERSITY OF CHICAGO",
  "THE UNIVERSITY OF MANCHESTER ALLIANCE MANCHESTER BUSINESS SCHOOL",
  "The University of Pavia",
  "THE UNIVERSITY OF TEXAS, SAN ANTONIO",
  "THOMAS EDISON STATE COLLEGE",
  "THOMAS JEFFERSON UNIVERSITY, COLLEGE OF GRADUATE STUDIES",
  "Thomas More University of Applied Sciences",
  "THOMPSON RIVERS UNIVERSITY",
  "THUNDERBIRD, THE GARVIN SCHOOL OF MANAGEMENT",
  "TIAS SCHOOL FOR BUSINESS AND SOCIETY",
  "TIFFIN UNIVERSITY",
  "TILBURG UNIVERSITY",
  "TIO UNIVERSITY OF APPLIED SCIENCE",
  "TIPPERARY INSTITUTE, CLONMEL",
  "TIPPERARY INSTITUTE, THURLES",
  "TOI OHOMAI INSTITUTE OF TECHNOLOGY",
  "Tomsk Polytechnic University",
  "Tomsk State University",
  "TORONTO SCHOOL OF MANAGEMENT",
  "Toulouse 1 Capitole University",
  "TOULOUSE BUSINESS SCHOOL",
  "TOURO UNIVERSITY",
  "TOWSON UNIVERSITY",
  "Transport and Telecommunication Institute",
  "Trebas Institute",
  "TRENT UNIVERSITY",
  "TRI STATE UNIVERSITY",
  "Trine University",
  "TRINITY COLLEGE DUBLIN",
  "TRINITY UNIVERSITY, SAN ANTONIO",
  "TRINITY WESTERN UNIVERSITY",
  "TROY STATE UNIVERSITY, DOTHAN CAMPUS",
  "TROY STATE UNIVERSITY, TROY",
  "TROY UNIVERSITY",
  "TSI University of Applied Science",
  "TU BERGAKADEMIE FREIBURG",
  "TU Wien",
  "Tuck School of Business at Dartmouth",
  "TUFTS UNIVERSITY MEDFORD",
  "TULANE UNIVERSITY",
  "TUM ASIA - GERMAN INSTITUTE OF SCIENCE AND TECHNOLOGY",
  "TURIBA UNIVERSITY",
  "TURKU SCHOOL OF ECONOMICS",
  "TURKU UNIVERSITY OF APPLIED SCIENCE",
  "Turku University of Applied Sciences",
  "TUSKEGEE UNIVERSITY",
  "TVSA PILOT TRAINING",
  "UC Leuven-Limburg",
  "UCAM MURCIA",
  "UCAM UNIVERSITY",
  "UCFB",
  "Ulster University",
  "ULSTER UNIVERSITY (BIRMINGHAM CAMPUS)",
  "ULSTER UNIVERSITY (LONDON CAMPUS)",
  "UMEA UNIVERSITY",
  "UniCamillus",
  "UNIMORE-University of Modena",
  "UNITEC INSTITUTE OF TECHNOLOGY",
  "UNITED INTERNATIONAL BUSINESS SCHOOL",
  "United States Sports Academy",
  "UNITEDWORLD SCHOOL OF BUSINESS",
  "UNIVERSAL COLLEGE OF LEARNING",
  "Universidad Aut",
  "Universidad Cat",
  "Universidad Polit",
  "Universidad Politécnica de Madrid",
  "UNIVERSIDAD REY JUAN CARLOS",
  "Universit",
  "UNIVERSIT OF WISCONSIN-SUPERIOR",
  "UNIVERSIT'A DELLA SVIZZERA ITALIANA",
  "Università Ca' Foscari",
  "UNIVERSITA CATTOLICA DEL SACRO CUORE",
  "UNIVERSITAT AUGSBURG UNIVERSITY",
  "UNIVERSITAT BERN",
  "UNIVERSITAT BONN",
  "UNIVERSITAT BUNDESWEHR MUNCHEN",
  "Universität Düsseldorf",
  "UNIVERSITAT FREIBURG",
  "UNIVERSITAT HEIDELBERG",
  "UNIVERSITAT INTERNACIONAL DE CATALUNYA",
  "UNIVERSITAT KIEL",
  "UNIVERSITAT KONSTANZ",
  "UNIVERSITAT MAGDEBURG",
  "UNIVERSITAT MUNSTER",
  "UNIVERSITAT PADERNBORN",
  "UNIVERSITAT POLITECNICA DE CATALUNYA",
  "UNIVERSITAT STUTTGART",
  "UNIVERSITAT ULM",
  "UNIVERSITAT WURZBURG",
  "UNIVERSITÉ CATHOLIQUE DE L'OUEST - ANGERS",
  "UNIVERSITE DE LAUSANNE",
  "Université du Québec",
  "Université Grenoble Alpes",
  "Université Lumière Lyon 2",
  "Université PSL",
  "Universiti Tunku Abdul Rahman Kampar Campus",
  "University Arizona School of Health Sciences",
  "UNIVERSITY AT ALBANY - SUNY",
  "UNIVERSITY AT ALBANY SUNY SCHOOL OF PUBLIC HEALTH",
  "UNIVERSITY AT BUFFALO",
  "UNIVERSITY AT BUFFALO,THE STATE UNIVERSITY OF NEW YORK",
  "UNIVERSITY AT BUFFALOTHE STATE UNIVERSITY OF NEW YORK",
  "University Camilo Jos",
  "University Catholique de Louvain",
  "University Centre of the Westfjords",
  "UNIVERSITY COLLEGE BIRMINGHAM",
  "UNIVERSITY COLLEGE CORK",
  "UNIVERSITY COLLEGE DUBLIN",
  "University College Gent",
  "UNIVERSITY COLLEGE LONDON",
  "University College of Economics and Culture",
  "UNIVERSITY COLLEGE OF JONKOPING",
  "UNIVERSITY COLLEGE OF NORTHERN DENMARK",
  "University College of Southeast Norway",
  "University College Sjaelland",
  "University College South Denmark",
  "UniversitY de Lille",
  "University de Strasbourg",
  "UNIVERSITY HAMBURG",
  "UNIVERSITY NEW HAVEN",
  "UNIVERSITY OF  BUSINESS IN WROCLAW",
  "UNIVERSITY OF  LINCOLN",
  "University of  Montréal",
  "UNIVERSITY OF  POMPEU FABRA",
  "UNIVERSITY OF  TRIESTE",
  "UNIVERSITY OF ABERDEEN",
  "UNIVERSITY OF ADELAIDE",
  "University of Advancing Technology",
  "University of Agder",
  "UNIVERSITY OF AKRON",
  "University of Akureyri",
  "UNIVERSITY OF ALABAMA - MANDERSON SCHOOL OF BUSINESS",
  "UNIVERSITY OF ALABAMA BIRMINGHAM",
  "UNIVERSITY OF ALABAMA HUNTSVILLE",
  "UNIVERSITY OF ALABAMA, BIRMINGHAM",
  "UNIVERSITY OF ALABAMA, HUNTSVILLE",
  "UNIVERSITY OF ALABAMA,TUSCALOOSA",
  "UNIVERSITY OF ALASKA, FAIRBANKS",
  "UNIVERSITY OF ALBANY COLLEGE OF NANOSCALE SCIENCES AND ENGINEERING",
  "UNIVERSITY OF ALBERTA",
  "University of Alcal",
  "University of Alicante",
  "University of Almer",
  "UNIVERSITY OF AMSTERDAM",
  "University of Antwerp",
  "UNIVERSITY OF APPLIED SCIENCE AUGSBURG",
  "UNIVERSITY OF APPLIED SCIENCE DARMSTADT",
  "UNIVERSITY OF APPLIED SCIENCE DRESDEN",
  "UNIVERSITY OF APPLIED SCIENCE EMDEN",
  "UNIVERSITY OF APPLIED SCIENCE HOF",
  "UNIVERSITY OF APPLIED SCIENCE INGOLSTADT",
  "UNIVERSITY OF APPLIED SCIENCE JENA",
  "UNIVERSITY OF APPLIED SCIENCE KAISERSLAUTERN",
  "UNIVERSITY OF APPLIED SCIENCE LUBECK",
  "UNIVERSITY OF APPLIED SCIENCE MAINZ",
  "UNIVERSITY OF APPLIED SCIENCE MITTELHESSEN",
  "UNIVERSITY OF APPLIED SCIENCE ROSENHEIM",
  "UNIVERSITY OF APPLIED SCIENCE TECHNOLOGY BUSINESS AND DESIGN WISMAR",
  "UNIVERSITY OF APPLIED SCIENCE WURZBURG -SCHWEINFURT",
  "UNIVERSITY OF APPLIED SCIENCEKAISERSLAUTERN",
  "UNIVERSITY OF APPLIED SCIENCES - UPPER AUSTRIA",
  "UNIVERSITY OF APPLIED SCIENCES BONN-RHEIN-SIEG",
  "UNIVERSITY OF APPLIED SCIENCES EUROPE",
  "UNIVERSITY OF APPLIED SCIENCES EUROPE BERLIN CAMPUS",
  "UNIVERSITY OF APPLIED SCIENCES KIEL",
  "UNIVERSITY OF APPLIED SCIENCES MERSEBURG",
  "UNIVERSITY OF APPLIED SCIENCES OFFENBURG",
  "University of Applied Sciences Technikum Wien",
  "University of Applied Sciences Upper Austria",
  "UNIVERSITY OF APPLIED SCIENCES WEDEL",
  "UNIVERSITY OF APPLIED SCIENCES WIENER NEUSTADT",
  "UNIVERSITY OF ARIZONA, ELLER",
  "UNIVERSITY OF ARIZONA, TUCSON",
  "UNIVERSITY OF ARKANSAS FOR MEDICAL SCIENCES",
  "UNIVERSITY OF ARKANSAS, FAYETTEVILLE",
  "UNIVERSITY OF ARKANSAS, LITTLEROCK",
  "UNIVERSITY OF ARTS, PHILADELPHIA",
  "UNIVERSITY OF ATLANTA",
  "UNIVERSITY OF BALLARAT",
  "UNIVERSITY OF BALTIMORE",
  "UNIVERSITY OF BAMBERG",
  "UNIVERSITY OF BANGOR",
  "University of Barcelona",
  "UNIVERSITY OF BARI ALDO MORO",
  "UNIVERSITY OF BASEL",
  "UNIVERSITY OF BASILICATA",
  "UNIVERSITY OF BATH",
  "UNIVERSITY OF BAYREUTH",
  "UNIVERSITY OF BEDFORDSHIRE",
  "UNIVERSITY OF BERGAMO",
  "University of Bergen",
  "University of Bern",
  "UNIVERSITY OF BIRMINGHAM",
  "UNIVERSITY OF BIRMINGHAM SINGAPORE",
  "UNIVERSITY OF BOLOGNA",
  "UNIVERSITY OF BOLTON",
  "University of Bor",
  "UNIVERSITY OF BORAS",
  "University of Bordeaux",
  "University of Bradford",
  "UNIVERSITY OF BREMEN",
  "university of brescia",
  "UNIVERSITY OF BRIDGEPORT",
  "UNIVERSITY OF BRIGHTON",
  "UNIVERSITY OF BRISTOL",
  "UNIVERSITY OF BRITISH COLUMBIA",
  "UNIVERSITY OF BRITISH COLUMBIA - OKANAGAN CAMPUS",
  "UNIVERSITY OF BRITISH COLUMBIA - VANCOUVER CAMPUS",
  "UNIVERSITY OF BRUNEL",
  "University of Bucharest",
  "UNIVERSITY OF BURNGUNDY",
  "UNIVERSITY OF BUSINESS AND INTERNATIONAL STUDIES",
  "UNIVERSITY OF CALABRIA",
  "UNIVERSITY OF CALGARY",
  "University of California",
  "UNIVERSITY OF CALIFORNIA BERKELEY",
  "UNIVERSITY OF CALIFORNIA LA EXTENSION",
  "UNIVERSITY OF CALIFORNIA SAN DIEGO",
  "UNIVERSITY OF CALIFORNIA SANTA CRUZ",
  "UNIVERSITY OF CALIFORNIA, BERKELEY",
  "UNIVERSITY OF CALIFORNIA, BERKELEY, SCHOOL OF LAW",
  "UNIVERSITY OF CALIFORNIA, DAVIS",
  "UNIVERSITY OF CALIFORNIA, HASTING COLLEGE OF LAW",
  "UNIVERSITY OF CALIFORNIA, IRVINE",
  "UNIVERSITY OF CALIFORNIA, LOS ANGELES",
  "UNIVERSITY OF CALIFORNIA, MERCED",
  "UNIVERSITY OF CALIFORNIA, RIVERSIDE",
  "UNIVERSITY OF CALIFORNIA, SANTA BARBARA",
  "UNIVERSITY OF CAMBRIDGE",
  "University of Camerino",
  "University of Campania Luigi Vanvitelli",
  "UNIVERSITY OF CANADA WEST",
  "UNIVERSITY OF CANBERRA",
  "University of Cantabria",
  "UNIVERSITY OF CANTERBURY",
  "University of Castilla",
  "UNIVERSITY OF CATANIA",
  "UNIVERSITY OF CATTOLICA",
  "UNIVERSITY OF CENTRAL ARKANSAS",
  "UNIVERSITY OF CENTRAL ENGLAND, BIRMINGHAM",
  "UNIVERSITY OF CENTRAL FLORIDA ORLANDO",
  "UNIVERSITY OF CENTRAL FLORIDA, ORLANDO",
  "UNIVERSITY OF CENTRAL LANCASHIRE",
  "UNIVERSITY OF CENTRAL MISSOURI,WARRENSBURG",
  "UNIVERSITY OF CENTRAL MISSOURIWARRENSBURG",
  "UNIVERSITY OF CENTRAL OKLAHOMA EDMOND",
  "UNIVERSITY OF CENTRAL OKLAHOMA, EDMOND",
  "UNIVERSITY OF CHARLES STRUT (MELBOURNE CAMPUS)",
  "UNIVERSITY OF CHARLESTON",
  "UNIVERSITY OF CHESTER",
  "UNIVERSITY OF CHICAGO",
  "UNIVERSITY OF CHICAGO BOOTH SCHOOL OF BUSINESS",
  "UNIVERSITY OF CINCINNATI",
  "UNIVERSITY OF COLOGNE",
  "UNIVERSITY OF COLORADO AT DENVER",
  "UNIVERSITY OF COLORADO AT DENVER AND HEALTH SCIENCES CENTER",
  "UNIVERSITY OF COLORADO COLARADO SPRINGD",
  "UNIVERSITY OF COLORADO, BOULDER",
  "UNIVERSITY OF CONNECTICUT,STORRS",
  "University of Copenhagen",
  "University of Côte d'Azur",
  "UNIVERSITY OF CYPRUS",
  "UNIVERSITY OF DALLAS COLLEGE OF BUSINESS",
  "UNIVERSITY OF DALLAS,IRVING",
  "UNIVERSITY OF DAYTON",
  "University of Debrecen",
  "UNIVERSITY OF DELAWARE",
  "UNIVERSITY OF DENVER",
  "UNIVERSITY OF DERBY",
  "UNIVERSITY OF DETROIT, MERCY",
  "University of Deusto",
  "University of Dubai",
  "UNIVERSITY OF DUISBURG - ESSEN",
  "UNIVERSITY OF DUNDEE",
  "UNIVERSITY OF DUSSELDORF",
  "UNIVERSITY OF EAST ANGLIA",
  "UNIVERSITY OF EAST LONDON",
  "University of Eastern Finland",
  "University of Economics",
  "& Human Science",
  "University of Economics, Prague",
  "UNIVERSITY OF EDINBURGH",
  "UNIVERSITY OF ERLANGEN-NUREMBERG",
  "UNIVERSITY OF ESSEX",
  "UNIVERSITY OF EVANSVILLE",
  "UNIVERSITY OF EXETER",
  "University of Ferrara",
  "UNIVERSITY OF FINDLAY",
  "UNIVERSITY OF FLORENCE",
  "UNIVERSITY OF FLORIDA GAINESVILLE",
  "UNIVERSITY OF FLORIDA, GAINSVILLE",
  "UNIVERSITY OF FRASER VALLEY",
  "University of Fribourg",
  "UNIVERSITY OF GDANSK",
  "University of Geneva",
  "University of Genoa",
  "UNIVERSITY OF GEORGIA, ATHENS",
  "UNIVERSITY OF GIESSEN",
  "University of Girona",
  "UNIVERSITY OF GLAMORGAN",
  "UNIVERSITY OF GLASGOW",
  "UNIVERSITY OF GOTHENBURG",
  "UNIVERSITY OF GOTTINGEN",
  "University of Granada",
  "University of Graz",
  "UNIVERSITY OF GREENWICH",
  "UNIVERSITY OF GREIFSWALD",
  "UNIVERSITY OF GRONINGEN",
  "UNIVERSITY OF GUELPH",
  "UNIVERSITY OF HARTFORD, WEST HARTFORD",
  "UNIVERSITY OF HAWAII AT MANOA",
  "University of Helsinki",
  "UNIVERSITY OF HERTFORDSHIRE",
  "UNIVERSITY OF HILDESHEIM",
  "UNIVERSITY OF HOHENHEIM",
  "UNIVERSITY OF HOUSTON , CLEAR LAKE CITY",
  "UNIVERSITY OF HOUSTON - VICTORIA",
  "UNIVERSITY OF HOUSTON CULLEN COLLEGE OF ENGINEERING",
  "UNIVERSITY OF HOUSTON HOUSTON",
  "UNIVERSITY OF HOUSTON, CULLEN",
  "UNIVERSITY OF HOUSTON, HOUSTON",
  "UNIVERSITY OF HOUSTONCLEAR LAKE CITY",
  "UNIVERSITY OF HUDDERSFIELD",
  "UNIVERSITY OF HUDDERSFIELD LONDON",
  "University of Huelva",
  "UNIVERSITY OF HULL",
  "UNIVERSITY OF ICELAND",
  "UNIVERSITY OF IDAHO",
  "University of Illinois",
  "UNIVERSITY OF ILLINOIS AT CHICAGO",
  "UNIVERSITY OF ILLINOIS AT URBANA CHAMPAIGN",
  "UNIVERSITY OF ILLINOIS CHICAGO",
  "UNIVERSITY OF ILLINOIS SCHOOL OF ARCHITECTURE",
  "UNIVERSITY OF ILLINOIS SPRINGFIELD",
  "UNIVERSITY OF ILLINOIS URBANA CHAMPAIGN",
  "UNIVERSITY OF INCARNATE WORD",
  "UNIVERSITY OF INDIANAPOLIS",
  "University of Innsbruck",
  "UNIVERSITY OF INSUBRIA",
  "UNIVERSITY OF IOWA - IOWA CITY",
  "UNIVERSITY OF IRELAND GALWAY",
  "University of Jyv",
  "UNIVERSITY OF KAISERSLAUTERN",
  "UNIVERSITY OF KANSAS",
  "UNIVERSITY OF KANSAS MEDICAL CENTER",
  "UNIVERSITY OF KASSEL",
  "UNIVERSITY OF KENT",
  "UNIVERSITY OF KENTUCKY LEXINGTON",
  "UNIVERSITY OF KENTUCKY MEDICAL CENTER",
  "UNIVERSITY OF KIEL",
  "UNIVERSITY OF KOBLENZ AND LANDAU",
  "University of L'Aquila",
  "UNIVERSITY OF LA VERNE",
  "UNIVERSITY OF LALLY SCHOOL OF MANAGEMENT & TECHNOLOGY",
  "University of Lapland",
  "University of Las Palmas de Gran Canaria",
  "University of Latvia",
  "University of Lausanne",
  "UNIVERSITY OF LEEDS",
  "UNIVERSITY OF LEICESTER",
  "UNIVERSITY OF LEIPZIG",
  "UNIVERSITY OF LETHBRIDGE",
  "University of Liberic",
  "University of Liege",
  "UNIVERSITY OF LIMERICK",
  "University of Lisbon",
  "UNIVERSITY OF LIVERPOOL",
  "University of Lodz",
  "University of Lorraine",
  "UNIVERSITY OF LOUISIANA, LAFAYETTE",
  "UNIVERSITY OF LOUISIANA, MONROE",
  "UNIVERSITY OF LOUISVILLE",
  "University of M",
  "UNIVERSITY OF MAASTRICHT SCHOOL OF MANAGEMENT",
  "UNIVERSITY OF MACERATA",
  "UNIVERSITY OF MAGDEBURG",
  "UNIVERSITY OF MAINE",
  "UNIVERSITY OF MALAYA",
  "University of Malta",
  "UNIVERSITY OF MANAGEMENT ECONOMICS AND FINANCE",
  "UNIVERSITY OF MANCHESTER",
  "UNIVERSITY OF MANITOBA",
  "UNIVERSITY OF MANNHEIM",
  "UNIVERSITY OF MARBURG",
  "UNIVERSITY OF MARY HARDIN, BAYLOR",
  "UNIVERSITY OF MARYLAND BALTIMORE COUNTY",
  "UNIVERSITY OF MARYLAND, BALTIMORE COUNTY",
  "UNIVERSITY OF MARYLAND, COLLEGE PARK",
  "UNIVERSITY OF MARYLAND, EASTERN SHORE",
  "UNIVERSITY OF MASSACHUSETTS AMHERST",
  "UNIVERSITY OF MASSACHUSETTS BOSTON",
  "UNIVERSITY OF MASSACHUSETTS LOWELL",
  "UNIVERSITY OF MASSACHUSETTS, AMHERST",
  "UNIVERSITY OF MASSACHUSETTS, DARTMOUTH",
  "UNIVERSITY OF MASSACHUSETTS, LOWELL",
  "University of Mauritius",
  "UNIVERSITY OF MEDICINE AND DENSTISTRY OF NEW JERSEY",
  "UNIVERSITY OF MELBOURNE",
  "UNIVERSITY OF MEMPHIS",
  "UNIVERSITY OF MESSINA",
  "UNIVERSITY OF MIAMI",
  "UNIVERSITY OF MICHIGAN ANN ARBOR",
  "UNIVERSITY OF MICHIGAN BUSINESS SCHOOL",
  "UNIVERSITY OF MICHIGAN DEARBON",
  "UNIVERSITY OF MICHIGAN FLINT",
  "UNIVERSITY OF MICHIGAN, DEARBON",
  "University of Milan",
  "UNIVERSITY OF MILANO- BICOCCA",
  "UNIVERSITY OF MINNESOTA",
  "UNIVERSITY OF MINNESOTA COLLEGE OF PHARMACY",
  "UNIVERSITY OF MINNESOTA DULUTH",
  "UNIVERSITY OF MINNESOTA MORRIS",
  "UNIVERSITY OF MINNESOTA, DULUTH",
  "UNIVERSITY OF MINNESOTA, TWIN CITIES",
  "UNIVERSITY OF MISSISSIPPI MEDICAL CENTER",
  "UNIVERSITY OF MISSISSIPPI,MISSISSIPPI",
  "UNIVERSITY OF MISSOURI",
  "UNIVERSITY OF MISSOURI KANSAS CITY",
  "UNIVERSITY OF MISSOURI TRULASKE",
  "UNIVERSITY OF MISSOURI, COLUMBIA",
  "UNIVERSITY OF MISSOURI, KANSAS CITY",
  "UNIVERSITY OF MISSOURI, ST. LOUIS",
  "UNIVERSITY OF MISSOURI,ROLLA",
  "university of mobile",
  "UNIVERSITY OF MODENA AND REGGIO EMILIA",
  "University of Mons",
  "UNIVERSITY OF MONTANA,MISSOULA",
  "University of Montevallo",
  "UNIVERSITY OF MUNSTER",
  "University of Namur",
  "University of Nantes",
  "University of Naples Federico II",
  "University of Natural Resources and Life Sciences, Vienna",
  "University of Navarra",
  "UNIVERSITY OF NEBRASKA LINCOLN",
  "UNIVERSITY OF NEBRASKA MEDICAL CENTER",
  "UNIVERSITY OF NEBRASKA, LINCOLN",
  "UNIVERSITY OF NEBRASKA, OMAHA",
  "UNIVERSITY OF NEUCHATEL",
  "UNIVERSITY OF NEVADA, LAS VEGAS",
  "UNIVERSITY OF NEVADA, RENO",
  "UNIVERSITY OF NEW BRUNSWICK",
  "UNIVERSITY OF NEW ENGLAND",
  "UNIVERSITY OF NEW HAMPSHIRE, DURHAM",
  "UNIVERSITY OF NEW HAVEN",
  "UNIVERSITY OF NEW HAVEN, WEST HAVEN",
  "UNIVERSITY OF NEW HAVENSWEST HAVEN",
  "UNIVERSITY OF NEW MEXICO",
  "UNIVERSITY OF NEW ORLEANS",
  "UNIVERSITY OF NEW SOUTH WALES",
  "University of New York in Prague",
  "UNIVERSITY OF NEWCASTLE",
  "University of Niccolò Cusano Studies",
  "UNIVERSITY OF NICE SOPHIA ANTIPOLIS",
  "UNIVERSITY OF NICOSIA, CYPRUS",
  "UNIVERSITY OF NORDLAND",
  "UNIVERSITY OF NORTH ALABAMA, FLORENCE",
  "UNIVERSITY OF NORTH CAROLINA ,CHAPEL HILL",
  "UNIVERSITY OF NORTH CAROLINA AT CHAPEL HILL",
  "UNIVERSITY OF NORTH CAROLINA AT GREENSBORO",
  "UNIVERSITY OF NORTH CAROLINA CHARLOTTE",
  "UNIVERSITY OF NORTH CAROLINA, CHARLOTTE",
  "UNIVERSITY OF NORTH CAROLINA, WILMINGTON",
  "UNIVERSITY OF NORTH DAKOTA,GRAND FORKS",
  "UNIVERSITY OF NORTH FLORIDA",
  "UNIVERSITY OF NORTH GEORGIA",
  "UNIVERSITY OF NORTH TEXAS DALLAS CAMPUS",
  "UNIVERSITY OF NORTH TEXAS HEALTH SCIENCE CENTER AT FORT WORTH",
  "UNIVERSITY OF NORTH TEXAS HEALTH SCIENCE CENTER, SCHOOL OF PUBLIC HEALTH",
  "UNIVERSITY OF NORTH TEXAS, DENTON",
  "UNIVERSITY OF NORTHAMPTON",
  "UNIVERSITY OF NORTHERN BRITISH COLUMBIA",
  "UNIVERSITY OF NORTHERN IOWA,CEDAR FALLS",
  "UNIVERSITY OF NORTHERN VIRGINIA",
  "UNIVERSITY OF NORTHUMBRIA",
  "UNIVERSITY OF NORTHUMBRIA LONDON",
  "UNIVERSITY OF NOTRE DAME",
  "UNIVERSITY OF NOTTINGHAM",
  "UNIVERSITY OF NURTINGEN GEISLINGEN",
  "UNIVERSITY OF OKANAGAN",
  "UNIVERSITY OF OKLAHOMA",
  "UNIVERSITY OF OKLAHOMA HEALTH SCIENCES CENTER",
  "UNIVERSITY OF OLDENBURG",
  "UNIVERSITY OF ONTARIO INSTITUTE OF TECHNOLOGY",
  "University of Oradea",
  "UNIVERSITY OF OREGON",
  "University of Oslo",
  "UNIVERSITY OF OTTAWA",
  "University of Oulu",
  "University of Oviedo",
  "UNIVERSITY OF OXFORD",
  "University of P",
  "UNIVERSITY OF PACIFIC, STOCKTON",
  "UNIVERSITY OF PADERBORN",
  "UNIVERSITY OF PADOVA",
  "University of Palermo",
  "University of Pannonia",
  "University of Paris-Saclay",
  "University of Parma",
  "UNIVERSITY OF PASSAU",
  "University of Patras",
  "University of Pécs",
  "UNIVERSITY OF PENNSYLVANIA",
  "UNIVERSITY OF PENNSYLVANIA SCHOOL OF ENGINEERING AND APPLIED SCEIENCE",
  "UNIVERSITY OF PENNSYLVANIA WHARTON",
  "University of Perugia",
  "UNIVERSITY OF PHOENIX",
  "University of Piraeus",
  "UNIVERSITY OF PISA",
  "UNIVERSITY OF PITTSBURG",
  "UNIVERSITY OF PLYMOUTH",
  "University of Poitiers",
  "UNIVERSITY OF PORTSMOUTH",
  "University of Potsdam",
  "UNIVERSITY OF PRINCE EDWARD ISLAND",
  "UNIVERSITY OF QUEEN MARY",
  "UNIVERSITY OF QUEENSLAND AUSTRALIA",
  "UNIVERSITY OF QUEENSLAND, AUSTRALIA",
  "UNIVERSITY OF QUEENSLAND,BRISBANE",
  "UNIVERSITY OF READING",
  "UNIVERSITY OF REGINA",
  "University of Rennes",
  "UNIVERSITY OF RHEIN-WALL",
  "UNIVERSITY OF RHODE ISLAND",
  "UNIVERSITY OF RIO GRANDE",
  "UNIVERSITY OF ROCHESTER",
  "UNIVERSITY OF ROCHESTER, EASTMAN DENTAL CENTER",
  "University of Roehampton London",
  "University of Rome Tor Vergata",
  "UNIVERSITY OF ROSTOCK",
  "University of Salento",
  "University of Salerno",
  "UNIVERSITY OF SALFORD",
  "University of Salzburg",
  "UNIVERSITY OF SAN DIEGO",
  "UNIVERSITY OF SAN FRANCISCO",
  "University of Santiago de Compostela",
  "UNIVERSITY OF SASKATCHEWAN",
  "University of Science, Malaysia",
  "UNIVERSITY OF SCIENCES, PHILADELPHIA",
  "UNIVERSITY OF SCRANTON",
  "University of Sharjah",
  "UNIVERSITY OF SHEFFIELD",
  "UNIVERSITY OF SIEGEN",
  "UNIVERSITY OF SIENA",
  "University of Silesia in Katowice",
  "University of Sk",
  "UNIVERSITY OF SKOVDE",
  "UNIVERSITY OF SOUTH ALABAMA",
  "UNIVERSITY OF SOUTH AMPTON",
  "UNIVERSITY OF SOUTH AUSTRALIA",
  "UNIVERSITY OF SOUTH CAROLINA",
  "UNIVERSITY OF SOUTH DAKOTA",
  "UNIVERSITY OF SOUTH FLORIDA,TAMPA",
  "UNIVERSITY OF SOUTH FLORIDATAMPA",
  "UNIVERSITY OF SOUTH WALES",
  "UNIVERSITY OF SOUTH WALES - GLAMORGAN",
  "UNIVERSITY OF SOUTHAMPTON",
  "UNIVERSITY OF SOUTHERN CALIFORNIA",
  "University of Southern Denmark",
  "UNIVERSITY OF SOUTHERN MAINE",
  "UNIVERSITY OF SOUTHERN MISSISSIPPI, GULF COAST",
  "UNIVERSITY OF SOUTHERN MISSISSIPPI, HATTIESBURG",
  "UNIVERSITY OF SOUTHERN QUEENSLAND SYDNEY",
  "UNIVERSITY OF SOUTHERN QUEENSLAND TOOWOOMBA",
  "UNIVERSITY OF SOUTHERN QUEENSLAND, SYDNEY",
  "UNIVERSITY OF SOUTHERN QUEENSLAND, TOOWOOMBA",
  "University of St Andrews",
  "UNIVERSITY OF ST. FRANCIS",
  "University of St. Gallen",
  "UNIVERSITY OF ST. THOMAS",
  "UNIVERSITY OF ST.GALLEN",
  "UNIVERSITY OF STADELSCHULE-Staatliche Hochschule fuer Bildende Kuenste-",
  "University of Stavanger",
  "UNIVERSITY OF STIRLING",
  "UNIVERSITY OF STRATHCLYDE",
  "UNIVERSITY OF STUTTGART",
  "UNIVERSITY OF SUNDERLAND",
  "UNIVERSITY OF SURREY",
  "UNIVERSITY OF SUSSEX",
  "UNIVERSITY OF SYDNEY",
  "University of Szeged",
  "UNIVERSITY OF TAMPA",
  "University of Tartu",
  "UNIVERSITY OF TASMANIA",
  "UNIVERSITY OF TECHNOLOGY SYDNEY",
  "UNIVERSITY OF TECHNOLOGY, SYDNEY",
  "UNIVERSITY OF TEESSIDE",
  "UNIVERSITY OF TENNESSEE AT CHATTANOOGA",
  "UNIVERSITY OF TENNESSEE HEALTH SCIENCE CENTER",
  "UNIVERSITY OF TENNESSEE, KNOXVILLE",
  "UNIVERSITY OF TENNESSEE, MEMPHIS",
  "UNIVERSITY OF TEXAS AT SAN ANTONIO",
  "UNIVERSITY OF TEXAS AT TYLER",
  "UNIVERSITY OF TEXAS AUSTIN",
  "UNIVERSITY OF TEXAS DALLAS RICHARDSON",
  "UNIVERSITY OF TEXAS ELPASO",
  "UNIVERSITY OF TEXAS HEALTH SCIENCE CENTER AT SAN ANTONIO",
  "UNIVERSITY OF TEXAS RIO GRANDE VALLEY",
  "UNIVERSITY OF TEXAS SOUTHWESTERN MEDICAL CENTRE",
  "UNIVERSITY OF TEXAS, ARLINGTON",
  "UNIVERSITY OF TEXAS, AUSTIN",
  "UNIVERSITY OF TEXAS, DALLAS",
  "UNIVERSITY OF TEXAS, DALLAS, RICHARDSON",
  "UNIVERSITY OF TEXAS, ELPASO",
  "UNIVERSITY OF TEXAS, HEALTH SCIENCES CENTER AT HOUSTON",
  "UNIVERSITY OF TEXAS, MEDICAL BRANCH AT GALVESTON",
  "UNIVERSITY OF TEXAS, PAN AMERICAN",
  "University of the Aegean",
  "University of the Algarve",
  "UNIVERSITY OF THE ARTS BREMEN",
  "University of the Arts Helsinki",
  "UNIVERSITY OF THE ARTS LONDON",
  "University of the Balearic Islands",
  "University of the Basque Country",
  "UNIVERSITY OF THE CUMBERLANDS",
  "UNIVERSITY OF THE POTOMAC",
  "UNIVERSITY OF THE SUNSHINE COAST",
  "University of the West of England",
  "UNIVERSITY OF THE WEST OF SCOTLAND",
  "UNIVERSITY OF TOLEDO",
  "UNIVERSITY OF TORONTO",
  "UNIVERSITY OF TORONTO FACULTY OF LAW",
  "University of Toulouse-Jean Jaurès",
  "UNIVERSITY OF TOURS",
  "UNIVERSITY OF TRENTO",
  "UNIVERSITY OF TRIER",
  "University of Trieste",
  "University of Troms",
  "UNIVERSITY OF TROMSO – THE ARCTIC UNIVERSITY OF NORWAY",
  "UNIVERSITY OF TUBINGEN",
  "UNIVERSITY OF TULSA",
  "University of Turin",
  "University of Turku",
  "UNIVERSITY OF TWENTE",
  "University of Udine",
  "UNIVERSITY OF UTAH, SALTLAKE CITY",
  "UNIVERSITY OF VAASA",
  "University of Valencia",
  "UNIVERSITY OF VERMONT, BURLINGTON",
  "University of Verona",
  "UNIVERSITY OF VETERINARY MEDICINE HANNOVER",
  "UNIVERSITY OF VICTORIA",
  "University of Vienna",
  "UNIVERSITY OF VIRGINIA",
  "UNIVERSITY OF VIRGINIA, SCHOOL OF ENGINEERING AND APPLIED SCIENCE",
  "UNIVERSITY OF WAIKATO",
  "UNIVERSITY OF WALES SWANSEA",
  "University of Warsaw",
  "UNIVERSITY OF WARWICK",
  "University of Washington",
  "UNIVERSITY OF WASHINGTON, BOTHELL",
  "UNIVERSITY OF WASHINGTON, SEATTLE",
  "UNIVERSITY OF WATERLOO",
  "UNIVERSITY OF WEST",
  "UNIVERSITY OF WEST ALABAMA",
  "UNIVERSITY OF WEST ENGLAND",
  "UNIVERSITY OF WEST FLORIDA",
  "UNIVERSITY OF WEST GEORGIA",
  "UNIVERSITY OF WEST LONDON",
  "UNIVERSITY OF WEST OF ENGLAND",
  "UNIVERSITY OF WEST SCOTLAND",
  "UNIVERSITY OF WESTERN AUSTRALIA",
  "UNIVERSITY OF WESTERN ONTARIO",
  "UNIVERSITY OF WESTERN SYDNEY",
  "UNIVERSITY OF WESTMINSTER",
  "UNIVERSITY OF WESTMINSTER,LONDON",
  "UNIVERSITY OF WINDSOR",
  "University of Wisconsin",
  "UNIVERSITY OF WISCONSIN LA CROSSE",
  "UNIVERSITY OF WISCONSIN MILWAUKEE",
  "UNIVERSITY OF WISCONSIN STEVENS POINT",
  "UNIVERSITY OF WISCONSIN, MADISON",
  "UNIVERSITY OF WISCONSIN, PARKSIDE",
  "UNIVERSITY OF WISCONSIN, RIVER FALLS",
  "UNIVERSITY OF WISCONSIN, STOUT MENOMONIE",
  "UNIVERSITY OF WISCONSIN, WHITEWATER",
  "UNIVERSITY OF WISCONSIN-EAU CLAIRE",
  "UNIVERSITY OF WISCONSIN-EAU CLARIE",
  "UNIVERSITY OF WOLLONGONG",
  "University of Wollongong in Dubai",
  "UNIVERSITY OF WOLVERHAMPTON",
  "UNIVERSITY OF WORCESTER",
  "UNIVERSITY OF WUPPERTAL",
  "UNIVERSITY OF WURZBURG",
  "UNIVERSITY OF WYOMING, LARAMIE",
  "UNIVERSITY OF YORK",
  "University of Zurich",
  "UNIVERSITY OFMASSACHUSETTS LOWEL",
  "University West",
  "UNSW SYDNEY",
  "UPPASALA UNIVERSITY",
  "UPPER LOWA UNIVERSITY",
  "UPPSALA UNIVERSITY",
  "UQ Business School",
  "Ural Federal University",
  "USC Marshall School of Business",
  "USI Universit",
  "USQ SYDNEY EDUCATION CENTRE",
  "UTAH STATE UNIVERSITY, LOGAN",
  "Utena College",
  "UTRECHT UNIVERSITY",
  "UTS INSEARCH",
  "VALPARAISO UNIVERSITY",
  "VAMK Vaasa University of Applied Sciences",
  "VANCOUVER COMMUNITY COLLEGE",
  "VANCOUVER FILM SCHOOL",
  "VANCOUVER ISLAND UNIVERSITY",
  "VANDERBILT UNIVERSITY",
  "VARNA UNIVERSITY OF MANAGEMENT",
  "VATEL INTERNATIONAL BUSINESS SCHOOL HOTEL AND TOURISM MANAGEMENT",
  "Ventspils University College",
  "VIA University College",
  "VICTORIA UNIVERSITY",
  "VICTORIA UNIVERSITY OF WELLINGTON",
  "VILLANOVA UNIVERSITY",
  "Vilnius Business College",
  "Vilnius Co-operative College",
  "Vilnius College",
  "Vilnius College of Design",
  "Vilnius College of Technologies and Design",
  "Vilnius Gediminas Technical University",
  "Vilnius University",
  "Vincent Pol University",
  "VIRGINIA COMMONWEALTH UNIVERSITY",
  "VIRGINIA INTERNATIONAL UNIVERSITY",
  "VIRGINIA POLYTECHNIC INSTITUTE AND STATE UNIVERSITY",
  "VIRGINIA STATE UNIVERSITY",
  "VIRGINIA TECH UNIVERSITY",
  "Vita-Salute San Raffaele University",
  "VIVES University of Applied Sciences",
  "Vlerick Business School",
  "Vlerick Business School - campus Leuven",
  "VLERICK LEUVEN GENT MANAGEMENT SCHOOL",
  "VRIJE UNIVERSITEIT BRUSSEL",
  "VRIJE UNIVERSITY AMSTERDAM",
  "VU Amsterdam University",
  "Vytautas Magnus University",
  "WAGENINGEN UNIVERSITY AND RESEARCH CENTER",
  "WAGNER GRADUATE SCHOOL OF PUBLIC SERVICE",
  "WAKE FOREST UNIVERSITY",
  "WALDEN UNIVERSITY",
  "WALSH UNIVERSITY",
  "Warsaw Management University",
  "WARSAW SCHOOL OF ECONOMICS",
  "Warsaw School of Photography and Graphic Design",
  "Warsaw University of Business",
  "Warsaw University of Life Sciences",
  "WARSAW UNIVERSITY OF TECHNOLOGY",
  "WARWICK BUSINESS SCHOOL",
  "Washington College of Law",
  "WASHINGTON STATE UNIVERSITY",
  "WASHINGTON UNIVERSITY, ST. LOUIS",
  "WATERFORD INSTITUTE OF TECHNOLOGY",
  "WAYNE STATE UNIVERSITY, DETROIT",
  "WEBER STATE UNIVERSITY",
  "WEBSTER UNIVERSITY",
  "WEBSTER UNIVERSITY,ORLANDO",
  "Webster Vienna Private University",
  "WEIHENSTEPHAN-TRIESDORF UNIVERSITY OF APPLIED SCIENCES",
  "WELLINGTON INSTITUTE OF TECHNOLOGY",
  "WENTWORTH INSTITUTE OF TECHNOLOGY",
  "WESLEYAN UNIVERSITY",
  "WEST CHESTER UNIVERSITY",
  "WEST SAXON UNIVERSITY OF APPLIED SCIENCE",
  "WEST TEXAS A and M UNIVERSITY",
  "West University of Timisoara",
  "WEST VIRGINIA STATE UNIVERSITY",
  "WEST VIRGINIA UNIVERSITY",
  "WESTCLIFF UNIVERSITY",
  "WESTERN CAROLINA UNIVERSITY",
  "WESTERN ILLINOIS UNIVERSITY MACOMB",
  "WESTERN ILLINOIS UNIVERSITY, MACOMB",
  "WESTERN INSTITUTE OF TECHNOLOGY AT TARANAKI",
  "WESTERN KENTUCKY UNIVERSITY, BOWLING GREEN",
  "WESTERN MICHIGAN UNIVERSITY",
  "WESTERN NEW ENGLAND UNIVERSITY",
  "WESTERN SYDNEY INSTITUTE",
  "WESTERN SYDNEY UNIVERSITY",
  "WESTERN UNIVERSITY OF HEALTH SCIENCES",
  "WESTERN WASHINGTON UNIVERSITY",
  "WHITIREIA",
  "WHU-OTTO BEISHEIM SCHOOL OF MANAGEMENT",
  "WICHITA STATE UNIVERSITY",
  "WIDENER UNIVERSITY, CHESTER",
  "Wilfrid Laurier University",
  "WILKES UNIVERSITY,WILKES-BARRE",
  "William & Mary",
  "WILLIAM ANGLISS INSTITUTE OF TAFE",
  "WILLIAM JESSUP UNIVERSITY",
  "WILLIAM PATERSON UNIVERSITY, WAYNE",
  "WILMINGTON UNIVERSITY",
  "WINSTON-SALEM STATE UNIVERSITY",
  "WINTSON COLLEGE",
  "WITTENBORG University",
  "WITTENBORG UNIVERSITY OF APPLIED SCIENCES",
  "WOODBURY UNIVERSITY SCHOOL OF ARCHITECTURE",
  "WORCESTER POLYTECHNIC INSTITUTE",
  "World Maritime University (WMU)",
  "WORLD MARITIME UNIVERSITY,SHANGHAI",
  "WORLD MARITIME UNIVERSITYSHANGHAI",
  "WORMS UNIVERSITY OF APPLIED SCIENCE",
  "WRIGHT STATE UNIVERSITY DAYTON",
  "WRIGHT STATE UNIVERSITY, DAYTON",
  "WRITTLE UNIVERSITY COLLEGE",
  "WROCLAW UNIVERSITY OF SCIENCE & TECHNOLOGY",
  "Wroclaw University of Science and Technology",
  "WU Executive Academy",
  "WU Vienna University of Economics and Business",
  "YALE UNIVERSITY",
  "YALE UNIVERSITY NEW HAVEN",
  "Yeshiva University",
  "YET TO SHORTLIST",
  "YONSEI SCHOOL OF BUSINESS",
  "YONSEI UNIVERSITY",
  "YORK UNIVERSITY",
  "YORKVILLE UNIVERSITY",
  "YOUNGSTOWN STATE UNIVERSITY",
  "YUKON COLLEGE",
  "Zagreb School of Economics and Management",
  "Zayed University",
  "Zealand Institute of Business and Technology"
];
export default RegisterFrom;
