import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getSessionId, getUser, getUserId, SESSION_ID, USER_ID, getUserType, clearUserData, setSearchSelectedUser, getSearchSelectedUser } from '../../../../Controller/localStorageHandler';
import axios from 'axios';

export const CreateNotification = (props) => {
    // console.log(props.selected)

    const [subject, setSubject] = useState('');
    const [subjectErr, setSubjectErr] = useState(false);
    const [type, setType] = useState('Alert');
    const [description, setDescription] = useState('');
    const [descErr, setDescErr] = useState(false);

    const submitNotification = async () => {
        setSubjectErr(false)
        setDescErr(false)
        if (subject === '') {
            setSubjectErr(true)
            return
        }
        else if (description === '') {
            setDescErr(true)
            return
        }
        else {
            const notification = new FormData()
            notification.append('sessionid', getSessionId())
            notification.append('operation', 'send_notification')
            notification.append('user_type', getUserType())
            notification.append('selected_users', props.selected)
            notification.append('mentor_id', getUserId())
            notification.append('subject',subject)
            notification.append('desc',description)
            notification.append('type',type)
            await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", notification).then(res => {
            console.log(res)
            })
        }

    }

    return (
        <div>

            <Dialog
                open={props.open}
                keepMounted
                // onClose={() => props.onClose()}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Create Notification For Students</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="subject"
                        label="Subject"
                        type="text"
                        fullWidth
                        variant="standard"
                        sx={{ my: '10px' }}
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                        error={subjectErr}
                        helperText={subjectErr ? "Kindly write Subject" : ''}
                    />
                    <FormControl sx={{ my: '10px' }} fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            label="Type"
                            fullWidth
                            variant="standard"
                        >
                            <MenuItem value="Alert">Alert</MenuItem>
                            <MenuItem value="Message">Message</MenuItem>
                            <MenuItem value="Warning">Warning</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ my: '10px' }}

                        margin="dense"
                        id="Description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        error={descErr}
                        helperText={descErr ? "Kindly write description" : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={submitNotification}>Submit</Button>
                    <Button onClick={() => props.onClose()}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}