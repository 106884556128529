import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";
import { OPERATION, operationValue } from '../../Controller/Global';
import axios from "axios";
import { useFormik } from 'formik';
import { getUser, SESSION_ID, USER_ID, getUserId, getSessionId } from '../../Controller/localStorageHandler';
import { fetchAddStudentAction, fetchComoonPickerListAction } from '../Action';
import { toast } from 'react-toastify';

const InviteShare = () => {
    const classes = useStyles()
    const [phone, setSelected] = useState('');
    const [FirstName, SetFirstName] = React.useState('')
    const [LastName, SetLastName] = React.useState('')
    const [Email, SetEmail] = React.useState('')
    const [PhoneNumber, SetPhoneNumber] = React.useState('')
    const [Dgree, SetDgree] = React.useState('')

    const [yearList, SetyearList] = React.useState([])
    const [selectedYear, SetSelectedYear] = React.useState(null)

    const [semesterlist, SetsemesterList] = React.useState([])
    const [selectedSemester, SetSelectedSemester] = React.useState(null)

    const [ContryList, SetContryList] = React.useState([])
    const [Country, SetCountry] = React.useState(null)

    const [ProgrammerList, SetProgrammerList] = React.useState([])
    const [Programmer, SetProgrammer] = React.useState(null)

    const GetSemester = () => {
        var data = new FormData();
        data.append('operation', 'fetchPicklist');
        data.append('name', 'semester');
        fetchComoonPickerListAction(data).then((semester) => {
            SetsemesterList(semester)
        }).catch((err) => {
            console.log("err", err)

        })
    }

    const GetYear = () => {
        var data = new FormData();
        data.append('operation', 'fetchPicklist');
        data.append('name', 'year');
        fetchComoonPickerListAction(data).then((semester) => {
            SetyearList(semester)
        }).catch((err) => {
            console.log("err", err)
        })
    }

    const GetCountry = () => {
        var data = new FormData();
        data.append('operation', 'fetchPicklist');
        data.append('name', 'country');
        fetchComoonPickerListAction(data).then((semester) => {
            SetContryList(semester)
        }).catch((err) => {
            console.log("err", err)
        })
    }
    
    const GetProgrammer = () => {
        var data = new FormData();
        data.append('operation', 'fetchPicklist');
        data.append('name', 'related_programs');
        fetchComoonPickerListAction(data).then((semester) => {
            SetProgrammerList(semester)
        }).catch((err) => {
            console.log("err", err)
        })
    }

    useEffect(() => {
        GetSemester()
        GetYear()
        GetProgrammer()
        GetCountry()
    }, [])

    const SubmitButtonHandler = () => {
        var data = new FormData();
        data.append('operation', 'Add_student');
        data.append('sessionid', getSessionId());
        data.append('userid', getUserId());
        data.append('email_address', Email);
        data.append('phone_mobile', PhoneNumber);
        data.append('year_looking_for', selectedYear ? selectedYear : 'All');
        data.append('country', Country ? Country : 'All');
        data.append('semester_looking_for', selectedSemester ? selectedSemester : 'All');
        data.append('username', `${FirstName} ${LastName}`);
        data.append('related_programs', Programmer ? Programmer : 'All');
        // data.append('degree_type', Dgree);
        fetchAddStudentAction(data).then((res) => {
            if (res.status === "false") {
                toast.error(`${res.message}`)
            } else {
                toast.success(`Student Add SuccessFully`)
            }
        }).catch((error) => {
            toast.error(error?.message || 'student add Failed')
        })
    }

    return (
        <Grid sx={{ backgroundColor: "#fafafa" }}>
            <Grid xs={12}>

                <Grid container sx={{ justifyContent: "center" }}>

                    <div style={{ marginBottom: 15, width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 10 }}>
                        <TextField
                            value={FirstName}
                            id="outlined-basic"
                            label="First Name"
                            variant="outlined"
                            style={{ width: "48%" }}
                            onChange={({ target }) => SetFirstName(target.value)}
                        />
                        <TextField
                            value={LastName}
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            style={{ width: "48%" }}
                            onChange={({ target }) => SetLastName(target.value)}
                        />
                    </div>

                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <TextField
                            type="email"
                            value={Email}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={({ target }) => SetEmail(target.value)}
                        />
                    </div>
                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                // backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "start",
                                borderRadius: "30px",
                                border: "1px solid #EBF2FF",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                padding: "10px 30px",
                                my: "10px"
                            }}
                        >
                            <PhoneInput
                                country={'in'}
                                value={PhoneNumber}
                                searchPlaceholder={'Mobile Number'}
                                inputClass={"mobile-field"}
                                onChange={phone => SetPhoneNumber(phone)}
                            />
                            {/*<input*/}
                            {/*    type="number"*/}
                            {/*    className={classes.input}*/}
                            {/*    autoFocus*/}
                            {/*    placeholder="Mobile Number"*/}
                            {/*/>*/}
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: 15, width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <FormControl style={{ width: "48%" }}>
                            <InputLabel id="demo-simple-select-label">Degree</InputLabel>
                            <Select
                                value={Dgree}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Degree"
                                onChange={({ target }) => {
                                    SetDgree(target.value)
                                }}
                            >
                                <MenuItem value="Masters">Masters</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Bachelors">Bachelors</MenuItem>
                                <MenuItem value="Phd">Phd</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: "48%" }}>
                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                            <Select
                                value={Country}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Country"
                                onChange={({ target }) => SetCountry(target.value)}
                            >
                                {ContryList.length > 0 && ContryList.map((d) => {
                                    return <MenuItem value={d}>{d}</MenuItem>
                                })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ marginBottom: 15, width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <FormControl style={{ width: "48%" }}>
                            <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                            <Select
                                value={selectedSemester}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Semester"
                                onChange={({ target }) => {
                                    SetSelectedSemester(target.value)
                                }}
                            >
                                {semesterlist.length > 0 && semesterlist.map((d) => {
                                    return <MenuItem value={d}>{d}</MenuItem>
                                })
                                }
                            </Select>
                        </FormControl>

                        <FormControl style={{ width: "48%" }}>
                            <InputLabel id="demo-simple-select-labelYear">Year</InputLabel>
                            <Select
                                value={selectedYear}
                                labelId="demo-simple-select-labelYear"
                                id="demo-simple-select"
                                label="Year"
                                onChange={({ target }) => {
                                    SetSelectedYear(target.value)
                                }}
                            >

                                {yearList.length > 0 && yearList.map((d) => {
                                    return <MenuItem value={d}>{d}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>



                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Program</InputLabel>
                        <Select
                            value={Programmer}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Program"
                            onChange={({ target }) => {
                                SetProgrammer(target.value)
                            }}
                        >
                            {ProgrammerList.length > 0 && ProgrammerList.map((d) => {
                                return <MenuItem value={d}>{d}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                    <Button
                        onClick={() => SubmitButtonHandler()}
                        variant="contained"
                        type="submit"
                        sx={{
                            fontSize: "14px",
                            fontWaight: "600",
                            padding: " 8px  0",
                            color: "#fff",
                            backgroundColor: "#1B6CFC",
                            borderRadius: "100px",
                            width: "60%",
                            my: "10px"
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({

    Sign: {
        color: "#1b6cfc",
        fontSize: "14px",
    },
    Terms: {
        color: "#1b6cfc",
    },
    input: {
        borderColor: "transparent",
        outline: "none",
        marginLeft: "10px",
        fontSize: "16px",
        width: "80%",
        display: "flex",
        opacity: 0.7,
        letterSpacing: 0.7,
    },
    filterIcon: {
        position: "relative",
        top: 10,
        height: "75px",
        width: "75px",
        //  boxShadow : '2px 2px 10px #DCDCDC','
    },

}))

export default InviteShare;