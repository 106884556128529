import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@mui/styles";
import { Autocomplete, Button, Grid, Input, TextField, Typography } from "@mui/material";
import { OPERATION, operationValue } from '../../../Controller/Global';
import axios from 'axios';
import { useFormik } from 'formik';
import { getSessionId, getUserId, SESSION_ID, USER_ID } from '../../../Controller/localStorageHandler';
import { withRouter } from 'react-router-dom';
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from '@mui/material/CircularProgress';



const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}
const TransferUniversity = ({ history }) => {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            program: '',
            University: [],
            degreeType: 'Masters',
        },
        onSubmit: async (values) => {
            values[OPERATION] = operationValue.FetchSOPResult;
            const formData = new FormData()
            for (let val in values) {
                if (val === 'University') {
                    const university = universityValue.map(val => `"${val}"`);
                    formData.append(val, university)
                } else if (val === 'program') {
                    const program = programValue.map(val => `"${val}"`);
                    formData.append(val, program)
                }
                else {
                    formData.append(val, values[val])
                }
            }
            formData.append(USER_ID, getUserId())
            formData.append(SESSION_ID, getSessionId())
            formData.append("page", "1")
            setLoading(true)
            await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
                .then(response => {
                    if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                        history.push({ pathname: '/UniversitySearchResult', state: { data: response.data, screen: "Transfer University", page: JSON.stringify(Object.fromEntries(formData)) } })
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        },
    });

    const [programList, setProgramList] = React.useState('');
    const [universityList, setUniversityList] = React.useState('');
    const [universityValue, setuniversityValue] = React.useState([]);
    const [loading, setLoading] = React.useState('');
    const [programValue, setprogramValue] = React.useState([])
    const [semesterValue, setsemesterValue] = React.useState('')
    const [yearValue, setyearValue] = React.useState('')
    const [yearList, setYearList] = React.useState([]);
    const [semesterList, setSemesterList] = React.useState([]);

    useEffect(() => {
        async function syncData() {
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'related_programs');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                })
            setProgramList(response.data.data);

            params.append('name', 'semester');
            const semesterRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'year');
            const yearRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            setSemesterList(semesterRes.data.data);
            setYearList(yearRes.data.data);

            const university = new FormData()
            university.append(OPERATION, operationValue.FetchUniversity)
            university.append(USER_ID, getUserId())
            university.append(SESSION_ID, getSessionId())
            university.append('search', " ")
            university.append('degreeType', formik.values.degreeType)
            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
                .then((response) => {
                    setUniversityList(response.data.data)
                })
        }
        syncData();
    }, []);
    const handleData = (value, type) => {
        if (type === "program") {
            setprogramValue(value)
        }
        else if (type === 'university') {
            setuniversityValue(value)
        } else if (type === 'semester') {
            setsemesterValue(value)
        } else if (type === 'year') {
            setyearValue(value)
        }
    }
    if (loading) {
        return (<div style={{ textAlign: 'center', marginTop: '55%' }}>
            <CircularProgress />
        </div>)
    } else {
        return (
            <>
                <p style={{ fontWeight: "bold", fontSize: '20px', textAlign: 'center', fontWeight: 'bold', margin: '11px 0 -13px 0' }}>Transfer Eligibility Check</p>
                <hr />
                <form onSubmit={formik.handleSubmit}>
                    <div className={classes.cardBox}>
                        <p style={{ fontWeight: "bold", fontSize: '18px', fontWeight: 'bold', margin: '11px 0 -13px 0', paddingBottom: 10 }}>Current  University</p>
                        <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                            <RadioGroup
                                className={classes.radioGroup}
                                row name="degreeType"
                                value={formik.values.degreeType}
                                onChange={formik.handleChange}
                            >
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Diploma"
                                    label="Diploma"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Bachelors"
                                    label="Bachelors"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Masters"
                                    label="Masters"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Phd"
                                    label="Phd"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select Country</InputLabel>
                        <Select 
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Select course"
                            name="University"
                            multiple
                            value={formik.values.University}
                            onChange={formik.handleChange}
                        >
                            {countryList && countryList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> */}
                            <Autocomplete
                                name='University'
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                value={universityValue}
                                onChange={(e, val) => handleData(val, 'university')}
                                options={universityList && universityList.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select University" />}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select Program</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Select course"
                            name="program"
                            value={formik.values.program}
                            onChange={formik.handleChange}
                        >
                            {programList && programList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> */}
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                name="program"
                                value={programValue}
                                onChange={(e, val) => handleData(val, 'program')}
                                options={programList && programList.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select Program" />}
                            />
                        </FormControl>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='semester_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={semesterValue}
                                        onChange={(e, val) => handleData(val, 'semester')}
                                        options={semesterList && semesterList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Semester" />}
                                    />
                                </FormControl>

                            </Grid>


                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='semester_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={yearValue}
                                        onChange={(e, val) => handleData(val, 'year')}
                                        options={yearList && yearList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Year" />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Input
                                        type="number"
                                        name='credits'
                                        // className={classes.input}
                                        min="0"
                                        max="50"
                                        placeholder='Credits'
                                        value={formik.values.credits}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Input
                                        type="number"
                                        name='current_gpa'
                                        // className={classes.input}
                                        placeholder='Current GPA '
                                        defaultValue={formik.values.current_gpa}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <p style={{ fontWeight: "bold", fontSize: '18px', fontWeight: 'bold', margin: '11px 0 -13px 0', paddingBottom: 20, paddingTop: 15 }}>Transfer  University</p>

                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select Country</InputLabel>
                        <Select 
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Select course"
                            name="University"
                            multiple
                            value={formik.values.University}
                            onChange={formik.handleChange}
                        >
                            {countryList && countryList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> */}
                            <Autocomplete
                                name='University'
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                value={universityValue}
                                onChange={(e, val) => handleData(val, 'university')}
                                options={universityList && universityList.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select University" />}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select Program</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Select course"
                            name="program"
                            value={formik.values.program}
                            onChange={formik.handleChange}
                        >
                            {programList && programList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> */}
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                name="program"
                                value={programValue}
                                onChange={(e, val) => handleData(val, 'program')}
                                options={programList && programList.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select Program" />}
                            />
                        </FormControl>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='semester_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={semesterValue}
                                        onChange={(e, val) => handleData(val, 'semester')}
                                        options={semesterList && semesterList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Semester" />}
                                    />
                                </FormControl>

                            </Grid>


                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='semester_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={yearValue}
                                        onChange={(e, val) => handleData(val, 'year')}
                                        options={yearList && yearList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Year" />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '5px' }}>
                            <Button className={classes.resetBtn}>Reset</Button>
                            <Button
                                className={classes.submitBtn}
                                type={'submit'}
                            >Search</Button>
                        </Grid>
                    </div>


                </form>
            </>
        )
    }
};

export default withRouter(TransferUniversity);
const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));