import React,{useState,useEffect} from 'react'
import { Avatar, Grid, Input, Modal, ModalUnstyled, TextField, Autocomplete } from "@mui/material";
import Alert from '@mui/material/Alert';
import {  styled } from '@mui/system'
import ChatCard from './ChatCard';
import Camera from '../../../img/camera 1.png';
import landscape from '../../../img/landscape 1.png';
import Voice from '../../../img/Voice 2.png';
import Calling from '../../../img/Calling.png';
import whatsapp from '../../../img/whatsapp.png';
import Message from '../../../img/Message.png';
import SendMessage from '../../../img/sendMessage.png';
import { render } from '@testing-library/react';
import { fetchStudentProjectChatAction, MentorDocumentDetailAction, SendMessageAction } from '../../Action/index.jsx'
import { ToastContainer, toast } from 'react-toastify';
import { getSessionId, getUserId ,getUser} from '../../../Controller/localStorageHandler';
import { ajax_get_recentComments, ajax_update_Comment } from '../../Action/AssociateDocumentList';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

import _ from 'lodash'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 5,
    height: "90%",
    overflowY: "scroll"
};

const ChatModal = ({open,onClose,val,refreshPage, semesterList, countryList, programList, convertByList}) => {
    const [date,setDate] =useState('');
    const [status,setStatus] = useState('');
    const [commentList,setCommentList] = useState([]);
    const [comment,setComment] = useState('');
    const [refresh,setRefresh] = useState(false);
    const [year,setYear] = useState('');

    const [countryValue, setcountryValue] = React.useState([])
    const [programValue, setprogramValue] = React.useState([])
    const [degree,setDegree] = useState('');
    const [convertBy, setConvertBy] = React.useState('')


    const [semester, setSemester] = useState([]);


    let severity = ''

    const handleChangeDate = (value)=>{
        setDate(value[`$d`])
        // console.log(value[`$d`])
    }

const get_comment= ()=>{

    var data = JSON.stringify({
        "contactid": val.contactid,
        "bypass": "validation"
    });
    ajax_get_recentComments(data).then((Data) => {
        setCommentList(Data?.data)
    }).catch((error) => {
        console.log("error", error)
    })
}

const submitComment = () =>{
    var data = JSON.stringify({
        "contactid": val.contactid,
        "bypass": "validation",
        "contact_no":getUser()?.contact_no,
        "related_to":val.contactid,
        "leadstatus":status,
        "program":programValue,
        "country":countryValue,
        "followup":date,
        "semester":semester,
        "year":year,
        "comments":comment,
        "savefrom":'studentdetails',
        "degree":degree,
        "convert_by":convertBy,
    });
    ajax_update_Comment(data).then((Data) => {
       if(Data?.status === 1){
        if(status !== val?.leadstatus){
            refreshPage(true)
        }
        setRefresh(true)
        toast.success(`Updated successfully`)

       }
    }).catch((error) => {
        console.log("error", error)
    })
}


useEffect(()=>{

    get_comment()
    setComment('')
},[refresh])

useEffect(()=>{
    setStatus(val?.leadstatus)
    setDate(val?.follow_up_date)
    // console.log(val?.follow_up_date)

    let related_programs = typeof val?.related_programs == "object" ? val?.related_programs : val?.related_programs?.split("|##|")
    related_programs = _.map(related_programs, (d) => d.trim())
    let country = typeof val?.country == "object" ? val?.country : val?.country?.split("|##|")
    country = _.map(country, (d) => d.trim())
    setDegree(val?.degree_looking_for)
    setprogramValue(related_programs)
    setcountryValue(country)
    setYear(val?.year_looking_for)
    setSemester(val?.semester_looking_for)
    setConvertBy(val?.convert_by)
},[])



const getDropList = () => {
    const year = new Date().getFullYear();
    return (
      Array.from( new Array(10), (v,i) =>
        <MenuItem value={year+i}>{year+i}</MenuItem>
      )
    );
  };
    
        return (<Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style} >
                <Grid xs={12} sx={{ paddingTop: 1, display: "flex", alignItems: "center" }} container>
                    <Grid item xs={2} sx={{ width: "10%", textAlign: "center" }}>
                        <div style={{
                            marginLeft: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 33,
                            width: 33,
                            background: `url(${process.env.PUBLIC_URL}/img/user.png)`,
                            backgroundSize: "cover",
                            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>

                        </div>
                    </Grid>
                    <Grid item xs={4} sx={{ paddingLeft: 5, width: "40%", display: "flex", justifyContent: "left", alignItems: "left" }}>
                        <p style={{ color: "#1A1A1A", fontSize: 16, fontWeight: "bold", textAlign: "center" }}>{val.firstname}{val.lastname}</p>
                    </Grid>
                    <Grid item xs={4} sx={{ paddingLeft: 2, display: "flex", justifyContent: "space-between", width: "50%" }}>
                        {/* <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>
                            <a ><img src={`${Message}`} style={{ height: 30, width: 30 }} /></a>
                        </div> */}

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>
                            <a href={`https://api.whatsapp.com/send?phone=${val?.mobile || ''}&text=Hi`}> <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                        </div>
                        <div
                            onClick={() => {
                                window.open(`tel:${val?.mobile}`);
                            }}
                            style={{
                                marginRight: 16,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                                height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                            }}>
                            <img src={`${Calling}`} style={{ height: 30, width: 30 }} />
                        </div>
                    </Grid>
                </Grid>

<div className="flexCenterJcB">
<FormControl fullWidth sx={{marginTop:'20px'}} size="small">
  <InputLabel id="demo-simple-select-label">Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={status}
    label="Status"
    onChange={(e)=>setStatus(e.target.value)}
  >
    <MenuItem value="">Status</MenuItem>
    <MenuItem value="ACS Registered" >ACS Registered</MenuItem>
    <MenuItem value="Busy">Busy</MenuItem>
    <MenuItem value="Confirmation Pending">Confirmation Pending</MenuItem>
    <MenuItem value="Contact in Future">Contact in Future</MenuItem>
    <MenuItem value="Document Pending">Document Pending</MenuItem>
    <MenuItem value="Junk Lead">Junk Lead</MenuItem>
    <MenuItem value="Lost Lead">Lost Lead</MenuItem>
    <MenuItem value="Not Answering">Not Answering</MenuItem>
    <MenuItem value="Not Interested">Not Interested</MenuItem>
    <MenuItem value="Opportunities">Opportunities</MenuItem>
    <MenuItem value="Prospective">Prospective</MenuItem>
    <MenuItem value="Test Prep Registered">Test Prep Registered</MenuItem>
    <MenuItem value="Warm">Warm</MenuItem>
    <MenuItem value="Wrong No">Wrong No</MenuItem>
    <MenuItem value="Yet to Call">Yet to Call</MenuItem>
  </Select>
</FormControl>

{/* <LocalizationProvider  dateAdapter={AdapterDateFns}>
        <Stack spacing={3} >
        <MobileDatePicker
          label="Follow Up Date"
          inputFormat="dd/MM/yyyy"
          value={date}
          onChange={handleChangeDate}
          renderInput={(params) => <TextField size='small' fullWidth sx={{marginTop:'20px'}}  label="Expected Close Date" {...params} />}
          clearable={true}
        />
        </Stack>


        </LocalizationProvider> */}

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Follow Up Date"
        value={date}
        inputFormat="DD/MM/YYYY"
        onChange={handleChangeDate}
        renderInput={(params) => <TextField  size='small' fullWidth sx={{marginTop:'20px'}}  label="Expected Close Date" {...params}  />}
        clearable={true}
      />
    </LocalizationProvider>
</div>

<div style={{marginTop:'10px',fontSize: '14px',textAlign:'center',overflow:'auto',whiteSpace:'nowrap'}}>

{/* degree looking for */}
<FormControl sx={{m:1, minWidth: "50%"}} size="small">
  <InputLabel id="demo-simple-select-label">Degree</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={degree}
    label="Degree"
    onChange={(e)=>setDegree(e.target.value)}
  >
    <MenuItem value="">Select Degree</MenuItem>
    {["Masters", "Diploma", "Bachelors", "Phd"].map(o => <MenuItem value={o}>{o}</MenuItem>)}
  </Select>
</FormControl>


{/* Convert By */}
<FormControl sx={{m:1, minWidth: "50%"}} size="small">
  <InputLabel >Convert By</InputLabel>
  <Select
   
    value={convertBy}
    label="Convert By"
    onChange={(e)=>setConvertBy(e.target.value)}
  >
    <MenuItem value="">Select Covert By</MenuItem>
    {convertByList && convertByList?.map(o => <MenuItem value={o}>{o}</MenuItem>)}
  </Select>
</FormControl>

{/* Year looking for */}

<FormControl sx={{m:1, minWidth: "50%"}} size="small">
  <InputLabel >Year</InputLabel>
  <Select
    value={year}
    label="Year"
    onChange={(e)=>setYear(e.target.value)}
  >
    <MenuItem value="">Select Year</MenuItem>
  {getDropList()}
  </Select>
</FormControl>

{/* semester list */}
<FormControl sx={{m:1, minWidth: "50%"}} size="small">
  <InputLabel id="demo-simple-select-label">Semester</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={semester}
    label="Semester"
    onChange={(e)=>setSemester(e.target.value)}
  >
    <MenuItem value="">Select Semester</MenuItem>
    {semesterList && semesterList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
  </Select>
</FormControl>


{/* program list */}
<FormControl size="small" sx={{ m: 1, minWidth: "50%" }}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            name="program"
                            className="radius-select"
                            value={programValue}
                            onChange={(e, val) => setprogramValue(val)}
                            options={programList && programList.map((option) => option)}
                            renderInput={(params) => <TextField  {...params} label="Select Program" />}
                            size="small" 
                        />


                    </FormControl>

                    {/* country list */}

                    <FormControl size="small"  sx={{ m: 1, minWidth: "50%" }}>
                        <Autocomplete
                            name='country'
                            freeSolo
                            multiple
                            value={countryValue}
                            className="radius-select"
                            onChange={(e, val) => setcountryValue(val)}
                            options={countryList && countryList.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Select Country" />}
                            size="small" 
                        />
             
                    </FormControl>
</div>


                <div style={{ height: window.innerHeight - 350, overflowX: "scroll" }}>
                    {commentList.length > 0 && commentList.map((d,i)=>
                        // console.log(i)
                         <Alert sx={{marginTop:'5px',marginBottom:'2px', fontSize:'12px'}} icon={false} severity={i===0?'success': i===1 ? 'info':i===2?'warning':''}>
                         {d?.name}{" => "}{d?.commentcontent}
                       </Alert>
                    )}
                    {/* {ChatListData.map((d) =>
                        <ChatCard
                            val={this.props.val}
                            userID={this.state.userId}
                            sender={this.state.userId == d.userid}
                            {...d}
                        />
                    )} */}
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                    {/* <div style={{ width: "40%", paddingLeft: 31, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <img src={landscape} style={{ height: 20, width: 20 }} />
                            <img src={Camera} style={{ height: 20, width: 20 }} />
                            <img src={Voice} style={{ height: 20, width: 20 }} />
                        </div> */}
                    <div style={{ width: "100%", paddingRight: 10, paddingLeft: 10, display: "flex", alignItems: "center" }}>
                        <textarea
                        // onChange={({ target }) => {
                        //     this.setState({ Message: target.value })
                        // }}
                        value={comment}
                        onChange={(e)=>setComment(e.target.value)}
                            multiline
                            placeholder="Type message"
                            style={{ width: "85%", borderRadius: 25, paddingLeft: 15, paddingTop: 20 }}
                        ></textarea>
                        <img onClick={()=>submitComment()} src={SendMessage} style={{ height: 60, width: 60, }} />
                    </div>
                </div>
                <ToastContainer />
            </Box>
        </Modal>)
    
}
export default ChatModal;
