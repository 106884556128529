import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

export default function ProgramModal(props) {
    const {open, handleClose, data, handleProgramList} = props
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll={'body'}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {
                    data === 'No data'?
                        <p style={{marginTop:'10px',marginBottom:'10px'}}>{data}</p>
                    :
                    data.map((value,i) => 
                        <p key={i} onClick={()=>handleProgramList(value.program || value.relatedprogram)} style={{cursor:'pointer'}}>{i+1}. {value.program || value.relatedprogram} {value.Total?<span style={{fontSize:"13px", backgroundColor:"red",width:"20px",height:"20px",borderRadius:"50%",display:"inline-block",textAlign:"center",color:"#fff",fontWeight:"bold"}}>{value.Total}</span>:''}</p>)
                    
                }
            </DialogContent>
        </Dialog>
    )
}
