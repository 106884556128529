import React, { useEffect, useState } from 'react';
import SearchBoxWithFilter from '../SearchBox/SearchBoxWithFilter';
import sort from "./../../static/icons/sortAscending.svg";
import './WhatsApp.css';
import WAimg from '../../img/WA-Image.png';
import Users from '../../img/Users.png';
import Share from '../../img/share.png';
import Facebook from '../../img/facebook.jpeg';
import {getUser,getSessionId, getUserId, getUserType } from '../../Controller/localStorageHandler'
import { fetchEventListAction } from '../Action/index'
import axios from 'axios';
import { CircularProgress, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { Autocomplete,Dialog, DialogContent, DialogTitle, IconButton, Box, Grid,Input,Button,Alert, Typography, TextField, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";
import Slide from '@mui/material/Slide';
import { champianInsertAction } from '../Action/index'
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { CommonLogin } from '../Forms/CommonLogin';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Chip from '@mui/material/Chip';
import makeStyles from "@mui/styles/makeStyles";
import useAppContext from "../../useContext/userContext";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStyles = makeStyles((theme) => {
    return {
        root: {
            "& .MuiFormLabel-root": {
              lineHeight:1
            }
    },
     root1: {
        
          lineHeight:1
        
}
    }
  });

function FacebookGroup() {
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation()

    const [searchList, setSearchList] = React.useState([])
    const [allsearchList, setAllSearchList] = React.useState([])
    const [countList, setCountList] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const[open,setOpen] = useState(false);
    const[campaignData,setCampaignData] = useState([]);
    const[email,setEmail] = useState('');
    const[phone,setPhone] = useState('')
    const[accPresent,setAccPresent] = useState(false)
    const[joinSuccess,setJoinSuccess] = useState(false)
    const[selectedUniversity,setSelectedUniversity] = useState('');
    const[joinjData,setJoinData] = useState([]);

    const[openDialog,setOpenDialog] = useState(false);
    const [openLogin,setOpenLogin] = useState(false)
    const [Limit, setLimit] = useState(20);
  const [Start, setStart] = useState(0);
  const [page, setPage] = useState(1);


    useEffect(() => {
        // console.log(getUserId());
        // console.log(getSessionId());
        let formData = new FormData()
        formData.append("operation", "get_event_list")
        formData.append("campaigntype", "Facebook Group")
        formData.append("userid", getUserId())
        formData.append("sessionid", getSessionId())

        if(id){
            formData.append("campainId", id)
        }

        fetchEventListAction(formData).then((response) => {
            // console.log(response)
            setSearchList(response?.data)
            setAllSearchList(response?.data)
            setCountList(response?.count_data)
            setJoinData(response?.join_data)
            let newDate = moment(new Date()).format("DD-MM-YYYY")
            // let date = newDate.getDate();
            // let month = newDate.getMonth();
            // let year = newDate.getFullYear();
            // let currentDate = year+'-'+month+'-'+date
           var filterGroupArr = response?.data.filter(function(event) {
               let eventDate =  moment(new Date(event?.event_date)).format("DD-MM-YYYY")
            //    console.log(eventDate)
                return eventDate > newDate // returns true or false
              });
            // console.log(filterGroupArr)

        }).catch((error) => {
            console.log("error", error)
        })
    }, [])
    

    const filterResult = (val) =>{
        if(val === null || val === ''){
            setSearchList(allsearchList)
        }
        else{
            var Data = allsearchList
            const ResultData = _.filter((Data), (d) => val.includes(d.university))
            setSearchList(ResultData)
        }
       

    }
    const loadMorePartner = (p) => {
        setLimit(p * 20 - 20 + 20);
        setStart(p * 20 - 20);
        setPage(p);
      };

    const handleChange = (event) => {
        var Data = allsearchList
        if(event.target.name === 'a-z'){
            const ResultData =  _.orderBy(Data,[d=>d.university.toLowerCase()],['asc'])
            // console.log(ResultData)
            setSearchList(ResultData)
        }
        if(event.target.name === 'z-a'){
            const ResultData =  _.orderBy(Data,[d=>d.university.toLowerCase()],['desc'])
            // console.log(ResultData)
            setSearchList(ResultData)
        }

        if(event.target.name === 'date_asc'){
            const ResultData =  _.orderBy(Data,[d=>d.inter_adm_deadline],['asc'])
            // console.log(ResultData)
            setSearchList(ResultData)
        }

        if(event.target.name === 'date_desc'){
            const ResultData =  _.orderBy(Data,[d=>d.inter_adm_deadline],['desc'])
            // console.log(ResultData)
            setSearchList(ResultData)
        }
        
        
        toggleDialog()
    }

    const openModal = (data) => {
        setOpen(true)
        setCampaignData(data)
    }

    const handleClose = () => {
        setOpen(false)
        setCampaignData([])
    }

    const toggleDialog = () => {
        setOpenDialog(!openDialog)
      };


    const joinEvent = (data,userId) =>{
      
                
                var formData = new FormData();
                formData.append('campaignid', data.campaignid);
                formData.append('contactid', getUserId() || userId);
                formData.append("userid", getUserId() || userId)
                formData.append("sessionid", getSessionId())
                formData.append('validation','bypass')
                champianInsertAction(formData).then(res => {
                    if (res?.msg === "Success") {
                       
                        setJoinSuccess(true)
                        toast.success(`Event Joined Successfully`)
                        window.location.href=data?.whtsapp_grp_link
        
                    } else {
                        toast.error(`${res?.msg}`)
         
                    }
                }).catch((erro) => {
                    toast.error(`Event Add failed`)
                })
        
            }

    const joinWhatsapp = async (data) =>{
        // console.log(data)

        const formData = new FormData()
        formData.append('operation','Whatsapp')
        formData.append('username','Student')
        formData.append('email_address',email)
        formData.append('phone_mobile',phone)
        formData.append('country',data?.country)
        formData.append('year',data?.year_looking_for)
        formData.append('semester',data?.semester_looking_for)
        formData.append('program',data?.related_programs)
        formData.append('leadsources','Facebook')

        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response=>{
            // console.log(response)
            if(response?.data?.status === 'false'){
                setAccPresent(true)
            }
            else if(response?.data?.status === "success"){
                joinEvent(data,response?.data?.data)
            }
        })


    }

    const getString = (groupName,campaignid) => {

        let shareData = `Hi %0D%0APlease find the Active *Whatsapp Groups* , *Applying* , *Admitted*, *Currently Studying* & *Alumni* 
        %0D%0A%0D%0AGroup Name: *${groupName}*
        %0D%0AGroup Link: https://app.bscglobaledu.com/%23/WhatsappGroup/${campaignid}
        %0D%0A%0D%0A*Discussion on*: Admission Decision, Accommodation, Scholarships, Part Time Jobs, Internships, Assistance ship
        %0D%0A%0D%0AJoin Active Whatsapp Groups
        %0D%0A%0D%0A1) 300+ Active *Whatsapp Groups* with 300000+ Students
        %0D%0Ahttps://app.bscglobaledu.com/%23/WhatsappGroup
        %0D%0Ahttps://ishvi.com/whatsappgroup.php
        %0D%0A%0D%0A2) 200+ Active *Telegram Groups* with 1000000+ Students
        %0D%0Ahttps://app.bscglobaledu.com/%23/TelegramGroup
        %0D%0Ahttps://ishvi.com/telegramgroup.php
        %0D%0A%0D%0A3) 200+ Active *Facebook Groups* with 40000+ Students
        %0D%0Ahttps://app.bscglobaledu.com/%23/FacebookGroup
        %0D%0Ahttps://ishvi.com/facebookgroup.php
        %0D%0A%0D%0AThanks
        %0D%0ABrainstorm Consulting
        %0D%0A8861216666 9844426043
        %0D%0Ainfo@bscglobaledu.com
        %0D%0Awww.bscglobaledu.com
        %0D%0A*Android* https://bit.ly/FreeStudyAbroad
        %0D%0A*iOS*    https://apple.co/33ujR4Q
        `

        return `https://api.whatsapp.com/send?text=%0D%0A%0D%0A ${shareData.replaceAll("&", "and")} `
    }
    const {
        getRelatedProgramsPickList,
        related_programs_picklist,
        getCountryPickList,
        country_picklist,
        getSemesterPickList,
        semester_picklist,
        getYearPickList,
        year_picklist,
      } = useAppContext();
      const [selectedSemester, SetSelectedSemester] = React.useState([]);
      const [selectedYear, SetSelectedYear] = React.useState([]);
      const [countryValue, setcountryValue] = React.useState([]);
      const [selectedProgram, setSelectedProgram] = React.useState([]);
    
      const [groupOwner, setGroupOwner] = React.useState("");
    
      const [universityValue, setuniversityValue] = React.useState([]);
    
      
    
      useEffect(() => {
        getCountryPickList();
        getSemesterPickList();
        getYearPickList();
        getRelatedProgramsPickList();
      }, []);
    
    
      const SubmitFilter = () => {
        var Data = allsearchList;
    
        var reset = false;
    
        if (reset) {
          setSearchList(allsearchList);
        } else {
          let flag = false;
          let ResultData = [];
          if (selectedSemester?.length > 0) {
            ResultData = _.filter(Data, (d) =>
              selectedSemester.includes(d.semester)
            );
            flag = true;
          }
          if (selectedYear?.length > 0) {
            if (flag) {
              ResultData = _.filter(ResultData, (d) =>
                selectedYear.includes(d.year_looking_for)
              );
            } else {
              ResultData = _.filter(Data, (d) =>
                selectedYear.includes(d.year_looking_for)
              );
              flag = true;
            }
          }
          if (universityValue?.length > 0) {
            if (flag) {
              ResultData = _.filter(ResultData, (d) =>
                universityValue.includes(d.university)
              );
            } else {
              ResultData = _.filter(Data, (d) =>
                universityValue.includes(d.university)
              );
              flag = true;
            }
          }
          if (countryValue?.length > 0) {
            if (flag) {
              ResultData = _.filter(ResultData, (d) =>
                countryValue.includes(d.country)
              );
            } else {
              ResultData = _.filter(Data, (d) => countryValue.includes(d.country));
              flag = true;
            }
          }
    
          if (groupOwner !== "" && groupOwner !== null) {
            if (flag) {
              ResultData = _.filter(
                ResultData,
                (d) => groupOwner === d.campaign_owner
              );
            } else {
              ResultData = _.filter(Data, (d) => groupOwner === d.campaign_owner);
              flag = true;
            }
          }
          if (selectedProgram?.length > 0) {
            if (flag) {
              ResultData = _.filter(ResultData, (d) =>
                selectedProgram.includes(d.related_programs)
              );
            } else {
              ResultData = _.filter(Data, (d) =>
                selectedProgram.includes(d.related_programs)
              );
              flag = true;
            }
          }
    
          setSearchList(ResultData);
        }
      };
      
      useEffect(() => {
        SubmitFilter();
        
        if((selectedSemester?.length === undefined  || selectedSemester?.length === 0) && (selectedYear?.length === undefined  || selectedYear?.length ===0) && (universityValue?.length === undefined  || universityValue?.length === 0) && (countryValue?.length === undefined  || countryValue?.length === 0) && (selectedProgram?.length === undefined  || selectedProgram?.length === 0)){
    
            setSearchList(allsearchList);
        }
        
      }, [
        selectedSemester,
        selectedYear,
        universityValue,
        countryValue,
        groupOwner,
        selectedProgram,
      ]);
        const classes=useStyles();
        const universityList =
    allsearchList &&
    _.uniqBy(allsearchList, "university").map((option) => option?.university);

    return (
        <div>
            {/* <p className="faq_question">How can we help you?</p> */}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button sx={{ minWidth: '0px', paddingLeft: '0px' }} onClick={() => history.goBack()}><ArrowBackIcon /></Button>
                       
                    </div>
            <div className="d-flex align-items-center my-3 justify-content-between">
                <p className="boldText">Facebook Groups list</p>
                <button className="sort_btn" onClick={toggleDialog}>
                    <img src={sort} alt="" />
                    sort
                </button>
                <Dialog onClose={toggleDialog} open={openDialog}>
                    <DialogTitle>Sort</DialogTitle>
                 
                        <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                        <FormGroup>
                        <FormControlLabel
                            control={
                            <Checkbox  name="a-z" onChange={handleChange}/>
                            }
                            label="A-Z"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox  name="z-a" onChange={handleChange}/>
                            }
                            label="Z-A"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox  name="date_asc" onChange={handleChange}/>
                            }
                            label="Date ASC"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox  name="date_desc" onChange={handleChange}/>
                            }
                            label="Date DESC"
                        />
                        </FormGroup>
                        </FormControl>
                </Dialog>
            </div>


            {/* <div className="CommonSearchForNameEmailandMobile">
            <Autocomplete
                            name='Search Facebook group'
                            id="free-solo-demo"
                            freeSolo
                            
                            // value={selectedSemester}
                            onChange={(e, val) => filterResult(val)}
                            options={allsearchList &&  _.uniqBy(allsearchList, 'university').map((option) => option?.university)}
                            renderInput={(params) => <TextField {...params} label="Search Facebook group" />}
                        />
            </div> */}
           
            <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginTop: "20px",
          overflowX: "auto",
          whiteSpace: "nowrap",
          justifyContent:"space-between"
        }}
      >
        
        <Box style={{ minWidth: "300px", marginBottom: 15,marginTop:"10px" }} sx={{marginRight:1}}>
          <Autocomplete
            name="University"
            id="free-solo-demo"
            className={classes.root}
            freeSolo
            // multiple
            value={universityValue}
            onChange={(e, val) => setuniversityValue(val)}
            options={universityList && universityList.map((option) => option)}
            renderInput={(params) => (
              <TextField {...params} label="University" />
            )}
          />
        </Box>
        <Box style={{ minWidth: "130px", marginBottom: 15,marginTop:"10px" }} sx={{marginRight:1}}>
          <Autocomplete
            name="Semester"
            id="free-solo-demo"
            className={classes.root}
            freeSolo
            multiple
            value={selectedSemester}
            onChange={(e, val) => SetSelectedSemester(val)}
            options={
              semester_picklist?.semester_picklist &&
              semester_picklist?.semester_picklist?.map((option) => option)
            }
            renderInput={(params) => <TextField {...params}  label="Semester" />}
          />
        </Box>
        <Box style={{ minWidth: "110px", marginBottom: 15,marginTop:"10px"  }} sx={{marginRight:1}}>
          <Autocomplete
            name="Year"
            id="free-solo-demo"
            className={classes.root}
            freeSolo
            multiple
            value={selectedYear}
            onChange={(e, val) => SetSelectedYear(val)}
            options={
              year_picklist?.year_picklist &&
              year_picklist?.year_picklist?.map((option) => option)
            }
            renderInput={(params) => <TextField {...params} label="Year" />}
          />
        </Box>
        <Box style={{ minWidth: "150px", marginBottom: 15,marginTop:"10px"  }} sx={{marginRight:1}}>
          <Autocomplete
            name="Country"
            id="free-solo-demo"
            className={classes.root}
            freeSolo
            multiple
            value={countryValue}
            onChange={(e, val) => setcountryValue(val)}
            options={
              country_picklist?.country_picklist &&
              country_picklist?.country_picklist?.map((option) => option)
            }
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        </Box>
        {getUserType() === "Employee" || getUserType() === "Associate Admin" ? (
          <FormControl style={{ minWidth: "150px", marginBottom: 15,marginTop:"10px"  }} sx={{marginRight:1}}>
            <InputLabel id="demo-simple-select-label" className={classes.root1}>Group Owner</InputLabel>
            <Select
              value={groupOwner}
              label="Group Owner"
              
              onChange={(e) => setGroupOwner(e.target.value)}
            >
              <MenuItem value="Own">Own</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
        ) : (
          ""
        )}
        <Box style={{ minWidth: "190px", marginBottom: 15,marginTop:"10px"  }} sx={{marginRight:1}}>
          <Autocomplete
            name="Related Programs"
            id="free-solo-demo"
            className={classes.root}
            freeSolo
            multiple
            value={selectedProgram}
            onChange={(e, val) => setSelectedProgram(val)}
            options={
              related_programs_picklist?.related_programs_picklist &&
              related_programs_picklist?.related_programs_picklist?.map(
                (option) => option
              )
            }
            renderInput={(params) => (
              <TextField {...params} label="Related Programs" />
            )}
          />
        </Box>
      </Box>

            { loading ?
                <div style={{textAlign: 'center', marginTop:'55%'}}>
                    <CircularProgress />
                </div>
                        :
                searchList.length === 0 ?
                    <h6 style={{ fontWeight: 'bold' }}>No Data</h6>
                :
                searchList?.slice(Start, Limit)?.map((val) => {
                    return (
                        <div className="row m-0 mt-3 mb-5">
                            <div className="col-12 p-3 boxShadow">

                                <div className="row m-0">
                                    <div className="col-2 pt-1 pb-1 p-0  brdBottom d-flex align-items-center justify-content-center">
                                    <img
                                        onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
                                        src={val.university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${val.university}.png` : ''}
                                        style={{ maxWidth: "100%", margin: 'auto', height: '30px', marginTop: '12px' }}
                                        alt='college'
                />
                                    </div>
                                    <div className="col-10 p-0 pt-1 pb-1 brdBottom">
                                        <p className="mb-0 grpAdd">{val?.university}, <span>{val.country}</span> </p>
                                        <p className="mb-0 mt-2 stream">{val.related_programs}</p>
                                    </div>
                                </div>

                                <p className="mb-0 mt-2 grpName">Group Name: <span>{val?.whatsapp_group_name}</span></p>

                                <div className="row m-0 mt-3 d-flex justify-content-between align-items-center">
                                    
                                    <div className="col-3 userCount pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly"
                                    onClick={() => {
                                        history.push({
                                            pathname: getUserId() ? getUserType() === 'Student' ? '/students' : '/student-list/'+val?.campaignid :'',
                                            state: {
                                                'status': val?.campaignname,
                                                "type": "Event",
                                                'campaignId': val?.campaignid,
                                                'val': val,
                                            }
                                        })
                                    }} 
                                    >
                                            <img src={Users} width="20" alt="" />
                                            {/* {console.log(countList['count_631627'])} */}
                                            <p className="mb-0 count">{`${countList[`count_${parseInt(val?.campaignid)}`] ? countList[`count_${parseInt(val?.campaignid)}`]:'0'}`}</p>
                                    </div>

                                    <div className="col-4 p-0 pe-2 ps-2 d-flex align-items-center justify-content-evenly">
                                    <Chip label={`${val?.semester}`} />
                                    <Chip label={`${val?.year_looking_for}`} />
                                    </div>

                                    <div className="col-5 p-0 pe-2 ps-2">

                                        <div className="row m-0 d-flex justify-content-between align-items-center">
                                            {
                
                                        _.filter((joinjData), (d) =>  { return d?.campaignid === val?.campaignid })?.length > 0 ? 
                                                
                                                <a href={val?.whtsapp_grp_link} className="col-7 userCount whtApp pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly">
                                            <img src={Facebook} width="20" alt="" />
                                                    <p className="mb-0 join">Link</p>
                                            </a>
                                                : 
                                            <a onClick={()=>getUser() ? joinEvent(val) : setOpenLogin(true)} className="col-7 userCount whtApp pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly">
                                            <img src={Facebook} width="20" alt="" />
                                                    <p className="mb-0 join">Join</p>
                                            </a>
                                            }
                                            <a href={getString(val?.whatsapp_group_name,val?.campaignid)} className="col-3 share pt-1 pb-1 p-0 d-flex align-items-center justify-content-evenly">
                                                    <img src={Share} alt="" />
                                            </a>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    
                    )
                })                
            }
            {!loading && (
            <Box display="flex" justifyContent="center" alignItems="center">
            <Pagination
            style={{ paddingTop: 50 }}
            defaultPage={1}
            page={page}
            count={Math.ceil(searchList?.length / 20)}
            color="primary"
            onChange={(e, p) => loadMorePartner(p)}
          />
        </Box>
      )}

             {/* Whatsapp join modal */}
             <Dialog
            onClose={handleClose}
            open={open}
    
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            keepMounted
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Join Event
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {
                    accPresent 
                    ?
                    <>
                    <Typography>Account Already exist with {email}. Please Login</Typography>
                    <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                component={Link}
                                to="/login"
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Login
                            </Button>
                            </Grid>
                    </>
                    :
                    joinSuccess ? 
                    <>
                    <Alert severity="success">Event Joined successfully..</Alert>
                    </>

                    :
                
            <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
              <Grid xs={12}>
          <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px", margin: "16px 0",
                                }}
                            >
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.2686 7.06116L12.0031 10.4954C11.1959 11.1283 10.0644 11.1283 9.25714 10.4954L4.95508 7.06116"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.88787 1.5H15.3158C16.6752 1.51525 17.969 2.08993 18.896 3.0902C19.823 4.09048 20.3022 5.42903 20.222 6.79412V13.322C20.3022 14.6871 19.823 16.0256 18.896 17.0259C17.969 18.0262 16.6752 18.6009 15.3158 18.6161H5.88787C2.96796 18.6161 1 16.2407 1 13.322V6.79412C1 3.87545 2.96796 1.5 5.88787 1.5Z"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>

                                <Input
                                    // style={{ width: "80%",marginLeft: "10px"}}
                                    type="email"
                                    name="email_address"
                                    sx={{width: "80%"}}
                                    autoFocus
                                    placeholder="Email@gmail.com"
                                    error={!email}
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </Grid>
                            {/* {!email ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Email</FormHelperText> : ''} */}
                        </Grid>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px 30px",
                                    margin: "16px 0",
                                }}
                            >
                                <PhoneInput
                                    country={'in'}
                                    name='phone_mobile'
                                    placeholder="Enter Number"
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: "0",
                                        opacity: "0.8"
                                    }}
                                      value={phone}
                                    searchPlaceholder={'Mobile Number'}
                                    inputClass={"mobile-field"}
                                    onChange={(phone) => {
                                        setPhone(phone)
                                        //   handleChange()
                                    }}
                                    // value={}
                                //   onChange={formik.handleChange}
                                />
                                {/*<input*/}
                                {/*    type="number"*/}
                                {/*    className={classes.input}*/}
                                {/*    autoFocus*/}
                                {/*    placeholder="Mobile Number"*/}
                                {/*/>*/}
                            </Grid>
                            {/* {!phone ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Phone Number</FormHelperText> : ''} */}
                        </Grid>
                        <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                onClick={()=>joinWhatsapp(campaignData)}
                                disabled={!email || !phone}
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Join
                            </Button>
                            </Grid>
                        </Grid>
          </Box>
}
            </DialogContent>
        </Dialog>
        <CommonLogin openLogin={openLogin} handleClose={setOpenLogin} return_url={location.pathname}  />
         
        </div>
    )
}

export default FacebookGroup;