import React from 'react';
import './Analysics.css';
import { Bar } from "react-chartjs-2";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Analysics = () => {

    return (
        <div className="container" style={{ margin: "auto"}}>
        
            <section className="" style={{ height: '280px'}}>
                {/* d-flex for small */}
                <p className="normal_title mt-5 mb-3 mx-3" style={{fontSize:'15px'}}>Analysics</p>


  <div className="row">
 
                <div className="col-6">
                <div  className="chart_section">
                    <p className="normal_title" style={{fontSize:'13px'}}>Students this Month</p>
                    <h4 style={{fontSize:'14px'}}>$100</h4>
                    <h3 className="title" style={{fontSize:'12px'}}><ArrowUpwardIcon /> $100</h3>
                    <div className="anaylytics_chart">
                        <Bar
                            data={{
                                labels: ["", "", "", "", "", "", ""],
                                datasets: [
                                    {
                                        backgroundColor: "#1B6CFC",
                                        borderColor: "#F2F4F5",
                                        borderRadius: 20,
                                        borderWidth: 5,
                                        data: [35, 35, 81, 22, 22],
                                    },
                                ],
                            }}
                            height={100}
                            width={150}
                            options={{
                                maintainAspectRatio: false,
                            }}
                        />
                    </div>
                </div>
                </div>
                <div className="col-6">

                <div  className="anaylytics_section_2">
                    <div className="content">
                        <p className="normal_title" style={{fontSize:'13px'}}>Registered This Month</p>
                        <h4 style={{fontSize:'14px'}}>$100</h4>
                        <p className="normal_title" style={{fontSize:'12px'}}>12 days left</p>
                    </div>
                    <div className="chart_section_2">
                        <div className="col_chart"></div>
                    </div>
                </div>
                </div>
                
  </div>


            </section>

        </div>
    );
};

export default Analysics;