import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import Calling from '../../../img/Calling.png';
import whatsapp from '../../../img/whatsapp.png';
import messenger from '../../../img/messenger-48.png';
import { useHistory } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import ChatModal from "./ChatModal";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { getUser, getUserType } from '../../../Controller/localStorageHandler';
import { getCurrencySymbol } from "../../../Controller/Global";
import moment from 'moment';
import Chip from '@mui/material/Chip';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';



const Chat = ({ data, refreshPage, programList, countryList, semesterList }) => {
  const history = useHistory();
  const [open,setOpen] = useState(false);

  const handleOpenAndClose = () => setOpen(!open);

  const classes = useStyles();



  return (
    <div className={`${classes.MainDiv}`} style={{backgroundColor:'#fff'}}>
      <div className={`${classes.container} flexCenterJcB`} style={{ width: "100%", padding: 15,backgroundColor:'#EBF2FF',position:'relative' }} >
        {/* <div style={{ width: "5%" }} >
          <CheckBoxOutlineBlankOutlinedIcon className={classes.squareIcon} />
         
        </div>  */}
        <div onClick={()=>handleOpenAndClose()} style={{with:'10%',height:'100%',color:'#fff',background:'linear-gradient(to bottom, #ff3300 0%, #ffcc99 100%)',position:'absolute',left:'-10px',writingMode:'vertical-lr',textOrientation:'upright'}}>
        COMPARE
        </div>
        <div style={{ display: "flex" }} >
 
          <AccountCircleOutlinedIcon sx={{fontSize:'40px', color:"#24bcc9", display:'flex',justifyContent:'center',alignItems:'center',mt:'20px',mr:'10px'}} />
          <main className={classes.nameDetails}>
            <p className={classes.name}><span onClick={() => history.push({ pathname: '/status', state: { StudentData: data, status: 'Visa Approved' } })}>{data.firstname} {data.lastname} </span> 
            &nbsp; <CommentIcon sx={{fontSize:'25px', color:'#00acee',cursor:'pointer'}} /> 
            &nbsp; {data?.instagram ? <InstagramIcon onClick={()=>window.location.href="https://www.instagram.com/"+data?.instagram} sx={{fontSize:'25px', color:'#bc2a8d',cursor:'pointer'}} /> : ''}
            &nbsp; {data?.twitter_id ? <TwitterIcon onClick={()=>window.location.href="https://twitter.com/"+data?.twitter_id} sx={{fontSize:'25px', color:'#00acee',cursor:'pointer'}} /> : ''}
            &nbsp; {data?.linked_id ? <LinkedInIcon onClick={()=>window.location.href="https://www.linkedin.com/in/"+data?.linked_id}  sx={{fontSize:'25px', color:'#0072b1',cursor:'pointer'}} /> : '' }
            &nbsp; {data?.linked_id ? <img onClick={()=>window.location.href="https://www.facebook.com/"+data?.facebook_id} src={messenger} width={25} /> : '' }
            {getUserType() !== 'Student' ? 
            <>
            &nbsp; <a href={`https://api.whatsapp.com/send?phone=${data?.mobile}`}><WhatsAppIcon sx={{fontSize:'25px', color:'#4FCE5D',cursor:'pointer'}} /></a>
            &nbsp; <CallIcon onClick={() => { window.open(`tel:${data.mobile}`)}} sx={{fontSize:'25px', color:'#0072b1',cursor:'pointer'}} />
            </>
            :
            ''
            }
            </p>
            {
              data.bachelors_percentage ? <Chip size="small" label={`Bachelors % : ${data?.bachelors_percentage}%`} color="primary" /> : ''
            }
            {
              data.number_backlogs ? <Chip size="small" label={`Backlogs : ${data?.number_backlogs}`} color="success" /> : ''
            }
            {
              data.work_experience ? <Chip size="small" label={`Work Experience : ${Math.round(data?.work_experience)}`} color="warning" /> : ''
            }
            {
              data.gmat_total_1st_attempt ? <Chip size="small" label={`Gre : ${data?.gmat_total_1st_attempt}`} color="error" /> : ''
            }
            {
              data.toefl_total_1st_attempt ? <Chip size="small" label={`Toefl : ${data?.toefl_total_1st_attempt}`} color="secondary" /> : ''
            }
            {
              data.pte_total_1st_attempt ? <Chip size="small" label={`Pte : ${data?.pte_total_1st_attempt}`} color="primary" /> : ''
            }
            {
              data.duolingo_total_1st_attempt ? <Chip size="small" label={`Duolingo : ${data?.duolingo_total_1st_attempt}`} color="success" /> : ''
            }
          </main>
        </div>


      </div>
    </div>
  );
};

export default Chat;

const useStyles = makeStyles(() => ({

  container: {
    // background: "#fff",
    // boxShadow: "rgba(0 0 0 / 0.4) 0px 10px 20px, rgba(0 0 0 / 0.4) 0px 2px 6px, rgba(0 0 0 / 0.4) 0px 0px 1px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "0 3% 0 5%",
    height: '10%'
  },
  image: {
    height: "45px",
    width: "45px",
    borderRadius: "30px",
  },
  nameDetails: {
    marginTop: "15px",
  },
  name: {
    // fontWeight: "bold",
    //   minWidth: "200px",
    fontSize: '14px',
    flexGrow: 1,
    '@media (max-width: 472px)': {
      fontSize: '14px',
      wordBreak: 'break-all'
    }
  },
  details: {
    // fontWeight: '500',
    // marginTop: '-2%',
    fontSize: '11px',
    '@media (max-width: 472px)': {
      fontSize: '11px',
      wordBreak: 'break-all'
    }
  },
  squareIcon: {
    opacity: 0.5,
    fontSize:'20px'
},
  logos: {
    alignSelf: 'flex-end',
    '@media (max-width: 472px)': {
      justifyContent: 'center',
      //  margin : 'auto'
    }
  }
}));
