

import { Chip, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import React from 'react'
import { barColor, getCurrencySymbol } from '../../Controller/Global';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import { useHistory } from "react-router-dom";

export default function ResultCard1(props) {
    const {

        setBookmarkId,
        setApplyData,
        setOpenApply,
        setOpenFav,
        val, type, setStatus, setOpenDownload, setSopData
    } = props;
    const history = useHistory()
    return (
        <Grid container 
        onClick={() => {
            history.push({
              pathname: '/CollegeDetails/',
              state: {
                data: val,
              }
            })
          }}>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <img
                    onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
                    src={val.university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${val.university}.png` : ''}
                    style={{ maxWidth: "100%", margin: 'auto', height: '50px', marginTop: '12px' }}
                    alt='college'
                />
            </Grid>
            <Grid item xs={10} sx={{ pl: 1, position: 'relative' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '13px', mt: 1, width: "230px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{val.scholarship_name ? val.scholarship_name : val.university}</Typography>
                <Typography sx={{ fontSize: '13px' }}>{val.related_programs ? val.related_programs : val.program}</Typography>
                {/* <hr style={{ maxWidth: '70%', marginRight: '30%', color: '#cfcaca', width:'70%' }} /> */}

                {/* <Typography sx={{ mt: 0, fontSize: '15px',display: 'inline-block',width:'100%'}}>{val.funding_type?val.funding_type:val.semester} {val.app_end_date? val.app_end_date:val.inter_adm_deadline}<span style={{ float: 'right', marginRight: '10px', fontWeight: 'bold', fontSize: '16px' }}>{val.country} {getCurrencySymbol(val.country)} {Math.round(val.scho_amt?val.scho_amt:val.international_tuition_fee_yr)} / yr</span></Typography> */}

                {/* <img src={`${process.env.PUBLIC_URL}/img/percenatge.png`} alt='percentage' style={{ marginTop: '15px',maxWidth:"100%" }} /> */}
                {/* <Typography style={{ position: "absolute",left: "15px",bottom: "-1px",zIndex: "9",fontSize: "12px",color: "#fff",fontWeight: "bold"}}>{Math.round(val.success_rate?val.success_rate:val.acceptance_rate)}%</Typography> */}
                {/* <LinearProgress variant="determinate" color={barColor(val.success_rate?val.success_rate:val.acceptance_rate)} value={val.success_rate?val.success_rate:val.acceptance_rate } style={{ marginTop: '15px',maxWidth:"40%",width:'40%', height:"18px" , borderRadius:"50px",float: 'left' }}/> */}

                <Grid sx={{ display: "flex", justifyContent: "flex-end", paddingRight: 2 }}>
                    {val.university ?
                        setOpenApply ? <Chip
                            label="Apply"
                            sx={{ bgcolor: '#1B6CFC', color: '#fff', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "40px", marginRight: '15px', float: 'left' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setApplyData(val)
                                setOpenApply(true)
                            }}
                        /> :
                            <>
                                <IconButton
                                    size="small"
                                    edge="end"
                                    aria-label="account of current user"
                                    // aria-controls={menuId}
                                    aria-haspopup="true"
                                    color="inherit"
                                // sx={{}}

                                >
                                    <ArrowDownwardIcon
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSopData(val)
                                            setOpenDownload(true)
                                        }}
                                    />

                                </IconButton>
                                {/* <IconButton
                                    size="small"
                                    edge="end"
                                    aria-label="account of current user"
                                    // aria-controls={menuId}
                                    aria-haspopup="true"
                                    color="inherit"
                                // sx={{ marginRight: '45px', position: 'absolute', bottom: "-6px", right: '0' }}

                                >
                                    <RemoveRedEyeIcon
                                    // onClick={(e)=>{
                                    //     e.stopPropagation();
                                    //     setBookmarkId(universitiesid)
                                    //     setOpenFav(true)}
                                    // }
                                    />

                                </IconButton> */}
                            </>
                        : ''
                    }
                    {val.scholarship_name &&
                        <>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                // aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            // sx={{ marginRight: '75px', position: 'absolute', bottom: "-6px", right: '0' }}

                            >
                                < LanguageIcon
                                // onClick={(e)=>{
                                //     e.stopPropagation();
                                //     setBookmarkId(universitiesid)
                                //     setOpenFav(true)}
                                // }
                                />

                            </IconButton>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                // aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            // sx={{ marginRight: '45px', position: 'absolute', bottom: "-6px", right: '0' }}

                            >
                                < MailOutlineIcon
                                // onClick={(e)=>{
                                //     e.stopPropagation();
                                //     setBookmarkId(universitiesid)
                                //     setOpenFav(true)}
                                // }
                                />

                            </IconButton>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                // aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            // sx={{ marginRight: '105px', position: 'absolute', bottom: "-6px", right: '0' }}

                            >
                                <CallIcon
                                // onClick={(e)=>{
                                //     e.stopPropagation();
                                //     setBookmarkId(universitiesid)
                                //     setOpenFav(true)}
                                // }
                                />

                            </IconButton>
                        </>

                    }
                    <IconButton
                        size="small"
                        edge="end"
                        aria-label="account of current user"
                        // aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                        // sx={{ marginRight: '15px', position: 'absolute', bottom: "-6px", right: '0' }}
                        onClick={() => setOpenFav(true)}

                    >
                        {val.bm_bookmark === '1' ?
                            <FavoriteIcon
                                style={{ color: 'red' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setBookmarkId(val.sopid)
                                    setOpenFav(true)
                                    setStatus(type)
                                }
                                }
                            /> :
                            <FavoriteBorderIcon onClick={(e) => {
                                e.stopPropagation();
                                setBookmarkId(val.sopid)
                                setOpenFav(true)
                                setStatus(type)
                            }}
                            />
                        }
                    </IconButton>
                </Grid>
            </Grid>

        </Grid>
    )
}
