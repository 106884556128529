import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'

import axios from 'axios';
import _ from 'lodash'

import Box from '@mui/material/Box';
import { fetchComoonPickerListAction } from '../Action';
import { getSessionId, getUserType, getUserId, SESSION_ID, USER_ID } from '../../Controller/localStorageHandler';
import { OPERATION, operationValue } from '../../Controller/Global';

import useAppContext from "../../useContext/userContext"

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    pt: 3,
    pb: 1,
    px: 0,
    overflow: 'scroll',
    height: "80%",
    pl: 3,
    pr: 3
};

export default function WhatsappFilter(props) {
    // const classes = useStyles();
    const { open, handleClose, SubmitFilter, univerSityList } = props
    const {
        getRelatedProgramsPickList, related_programs_picklist,
        getCountryPickList, country_picklist,
        getSemesterPickList, semester_picklist,
        getYearPickList, year_picklist,
    } = useAppContext()
    const [selectedSemester, SetSelectedSemester] = React.useState([])
    const [selectedYear, SetSelectedYear] = React.useState([])
    const [countryValue, setcountryValue] = React.useState([])
    const [selectedProgram, setSelectedProgram] = React.useState([])

    const [groupOwner,setGroupOwner] = React.useState('')

    const [universityValue, setuniversityValue] = React.useState([]);

    const [reset, setreset] = React.useState(false);











    useEffect(() => {
        getCountryPickList()
        getSemesterPickList()
        getYearPickList()
        getRelatedProgramsPickList()
    }, [])






    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => handleClose()}
        >
            <Box sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl style={{ width: "48%", marginBottom: 20 }}>
                        <Autocomplete
                            name='Semester'
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            value={selectedSemester}
                            onChange={(e, val) => SetSelectedSemester(val)}
                            options={semester_picklist?.semester_picklist && semester_picklist?.semester_picklist?.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Semester" />}
                        />
                    </FormControl>
                    <FormControl style={{ width: "48%", marginBottom: 20 }}>
                        <Autocomplete
                            name='Year'
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            value={selectedYear}
                            onChange={(e, val) => SetSelectedYear(val)}
                            options={year_picklist?.year_picklist && year_picklist?.year_picklist?.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Year" />}
                        />
                    </FormControl>

                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl style={{ width: "48%", marginBottom: 20 }}>

                        <Autocomplete
                            name='Country'
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            value={countryValue}
                            onChange={(e, val) => setcountryValue(val)}
                            options={country_picklist?.country_picklist && country_picklist?.country_picklist?.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Country" />}
                        />
                    </FormControl>

                    <FormControl style={{ width: "48%", marginBottom: 20 }}>
                        <InputLabel id="demo-simple-select-label">Group Owner</InputLabel>
                        <Select
                            
                            value={groupOwner}
                            label="Group Owner"
                            onChange={(e)=>setGroupOwner(e.target.value)}
                        >
                            <MenuItem value="Own">Own</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FormControl style={{ width: "100%", marginBottom: 20 }}>
                    <Autocomplete
                        name='University'
                        id="free-solo-demo"
                        freeSolo
                        // multiple
                        value={universityValue}
                        onChange={(e, val) => setuniversityValue(val)}
                        options={univerSityList && univerSityList.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="University" />}
                    />
                </FormControl>

                <FormControl style={{ width: "100%", marginBottom: 20 }}>

                    <Autocomplete
                        name='Related Programs'
                        id="free-solo-demo"
                        freeSolo
                        multiple
                        value={selectedProgram}
                        onChange={(e, val) => setSelectedProgram(val)}
                        options={related_programs_picklist?.related_programs_picklist && related_programs_picklist?.related_programs_picklist?.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Related Programs" />}
                    />
                </FormControl>


                <div style={{ width: "100%", marginBottom: 20, textAlign: "center" }}>
                    <Button
                        onClick={() => {
                            handleClose()
                            setreset(true)
                            props.SubmitFilter(selectedSemester, selectedYear, universityValue, countryValue, groupOwner, false)
                        }}
                        type="submit"
                        style={{
                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                            borderRadius: '30px',
                            height: '45px',
                            width: '80%',
                            backgroundColor: '#1B6CFC',
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            lineHeight: '20px',
                            marginLeft: '7px'
                        }}
                    >
                        Submit
                    </Button>
                    {reset &&
                        <Button
                            onClick={() => {
                                handleClose()
                                SetSelectedSemester([])
                                SetSelectedYear([])
                                setuniversityValue([])
                                setcountryValue([])
                                setGroupOwner([])
                                setreset(false)
                                props.SubmitFilter(selectedSemester, selectedYear, universityValue, countryValue, groupOwner, true)
                            }}
                            type="Reset"
                            style={{
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                                borderRadius: '30px',
                                height: '45px',
                                width: '80%',
                                backgroundColor: '#1B6CFC',
                                color: '#ffffff',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                lineHeight: '20px',
                                marginLeft: '7px',
                                marginTop: "10px"
                            }}
                        >
                            Reset
                        </Button>
                    }
                </div>
            </Box>
        </Modal>
    )
}

