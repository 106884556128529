import React, { useEffect } from 'react';
import {Checkbox, Grid, Input, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import {makeStyles} from "@mui/styles";



const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
      },
    }),
  );
  
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }


  const PossibleAcademicForm = (props) => {
    const classes = useStyles();
    const {values,handleChange,countryValue}= props
    const [bachelor,setBachelor] =React.useState(false)
    const [internship,setInternship] = React.useState(false)

    useEffect(() => {
        if(values.degree_looking_for !== 'Bachelors'){
            setBachelor(true)
        }
        if(countryValue.length ===1 && (countryValue[0] === "UK" || countryValue === "France" || countryValue === "Germany")){
            setInternship(true)
        }
        
    },[])
    const extraoption=(value)=>{
        if(value.length === 1){
            if(value[0] === "USA"){
                return 'STEM'
            }else if(value[0] === "UK"){
                return 'Placement'
            }else if(value[0] === "Canada"){
                return 'CO-OP'
            }
        }
        
    }
    return (
        <div>
            <Grid
                xs={12}
                sx={{
                    p: 3,
                    paddingBottom: "80px",
                    backgroundColor: "#fff",
                    mt: 3,
                    pb: 2,
                    borderRadius: "10px",
                    boxShadow: 3,
                }}
            >
                <Typography> Academic </Typography>
                {/* <FormControl fullWidth sx={{mt: 2}}>
                    <InputLabel>English</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value=""
                        label="Degree"
                        onChange="handleChange"
                    >
                        <MenuItem value={10}>BS</MenuItem>
                        <MenuItem value={20}>MS</MenuItem>
                        <MenuItem value={30}>Bs</MenuItem>
                    </Select>
                </FormControl> */}
                <FormControl fullWidth sx={{mt: 1.5}}>
                    <Input
                            type="number"
                            name='uni_puc_inter_12'
                            // className={classes.input}
                            placeholder='12th Percentage'
                            value={values.puc_percentage_12th}
                            onChange={handleChange}
                        />
                </FormControl>

                {bachelor && <><FormControl fullWidth sx={{mt: 1.5}}>
                    <Input
                            type="number"
                            name='academic_requirements_gpa'
                            // className={classes.input}
                            placeholder='Bachelors %'
                            defaultValue={values.academic_requirements_gpa}
                            onChange={handleChange}
                        />
                </FormControl>
                <FormControl fullWidth sx={{mt: 1.5}}>
                    <Input
                            type="number"
                            name='num_backlogs'
                            // className={classes.input}
                            placeholder="Backlogs"
                            defaultValue={values.num_backlogs}
                            onChange={handleChange}
                        />
                </FormControl></>}
                {/* <FormControl component="fieldset" sx={{m: 1, minWidth: "100%"}}>
                    <RadioGroup  
                        className={classes.radioGroup} 
                        row name="extraOption"
                        value={extraoption(countryValue)}
                        onChange={(e)=>handleChange(e.target.value)}
                    >
                        {internship && <MyFormControlLabel
                            className={"radioLabel"}
                            value="Internship"
                            label="Internship"
                            control={<Radio className={"radioRadioMain"}/>}
                        />}
                       {countryValue.length ===1 && countryValue[0] ===  "UK" && <MyFormControlLabel
                            className={"radioLabel"}
                            value="Placement"
                            label="Placement"
                            control={<Radio className={"radioRadioMain"}/>}
                        />}
                        {countryValue.length ===1 && countryValue[0] === "USA" &&  <MyFormControlLabel
                            className={"radioLabel"}
                            value="STEM"
                            label="STEM"
                            control={<Radio className={"radioRadioMain"}/>}
                        />}
                        {countryValue.length ===1 && countryValue[0] === "Canada" && <MyFormControlLabel
                            className={"radioLabel"}
                            value="CO_OP"
                            label="CO_OP"
                            control={<Radio className={"radioRadioMain"}/>}
                        />}
                    </RadioGroup>
                </FormControl> */}
                {internship && <FormControlLabel control={
                            <Checkbox 
                                name={values.checked} 
                                value="Internship" 
                                onChange={(e)=>console.log(e.target.value)}
                            />
                        } label="Internship" />}

                {countryValue.length ===1 && countryValue[0] ===  "UK" && <FormControlLabel control={
                            <Checkbox 
                                name={'checked'} 
                                value="Placement" 
                                onChange={(e)=>console.log(e.target.value)}
                            />
                        } label="Placement" />}
                
                {countryValue.length ===1 && countryValue[0] === "USA" &&  <FormControlLabel control={
                            <Checkbox 
                                name='checked'
                                value="STEM" 
                                onChange={handleChange}
                            /> 
                        } label="STEM" />}
                {countryValue.length ===1 && countryValue[0] === "Canada" && <FormControlLabel control={
                            <Checkbox 
                                name={values.checked} 
                                value="Co-OP" 
                                onChange={(e)=>handleChange(e.target.value)}
                            /> 
                        } label="CO-OP" />}
            </Grid>
        </div>
    );
};

export default PossibleAcademicForm;

const useStyles = makeStyles(() => ({
    radioGroup:{
        marginBottom:'5px'
    },
}));