import { ArrowDownward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getUser } from "../../Controller/localStorageHandler";
import addMony from "../../static/img/Associate/icons/addMoney.png";
import more from "../../static/img/Associate/icons/more.png";
import sendMony from "../../static/img/Associate/icons/sendMoney.png";
import logo1 from "../../static/img/Associate/image/logo1.png";
import usLogo from "../../static/img/Associate/image/usLogo.png";
import { GetApplicationCountDataAction, getAssociateStatusProjecttCountAction, getAssociateStudentListCountStatusAction } from "../Action/AssociateDocumentList";
import BottomNav from "../Common/BottomNav";
// import BottomNav from "../../pages/inc/BottomNav";
import Analysics from "./Analysics/Analysics";
import Applications from "./Applications/Applications";
import "./Dashboard.css";
import Registrated from "./Registrated/Registrated";
import Students from "./Students/Students";
import _ from 'lodash'
import ContentLoader from "react-content-loader";
import { AddStudentModal } from "../Student/StudentList/AddStudentModal";
import moment from "moment";

let Data = {
  "Admitted_count": "Admitted",
  "Application Fee Pending_count": "Application Fee Pending",
  "Application Incomplete_count": "Application Incomplete",
  "Application Review_count": "Application Review",
  "Conditional Admission_count": "Conditional Admission",
  "Eligibility Checking_count": "Eligibility Checking",
  "I-20/Offer/CAS/COE_count": "I-20/Offer/CAS/COE",
  "Info/Doc Pending_count": "Info/Doc Pending",
  "Portal Not Opened_count": "Portal Not Opened",
  "Ready for Submission_count": "Ready for Submission",
  "Tuition Deposit_count": "Tuition Deposit",
  "University Rejected_count": "University Rejected",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
  "Visa Denied_count": "Visa Denied",
  "Visa Yet To Apply_count": "Visa Yet To Apply",
  "Yet to Fill_count": "Yet to Fill"
}

let AssociateNameChanging = {
  "Tuition Deposit_count": "Tuition Deposit",
  "Shortlisting_count": "Shortlisting",
  "Filling_count": "Filling",
  "Completed_count": "Completed",
  "Application Review_count": "Application Review",
  "Profile Analysis_count": "Profile Analysis",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
  "Visa closed_count": "Visa Denied",
  "Visa Yet To Apply_count": "Visa Yet To Apply"
}

let employNameChanging = {
  "Profile Analysis_count": "Profile Analysis",
  "Shortlisting_count": "Shortlisting",
  "Filling_count": "Filling",
  "Application Review_count": "Application Review",
  "Tuition Deposit_count": "Tuition Deposit",
  "Visa Yet To Apply_count": "Visa Yet To Apply",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
}


const Associate = () => {
  let UserLocalstorge = getUser()
  let user_contact_type = UserLocalstorge?.user_contact_type || ''

  const [getListCountByData, setGetListCountByData] = useState([])
  const [GetAssociateProjectStatus, setGetAssociateProjectStatus] = useState({})
  const [GetApplicationCountData, setGetApplicationCountData] = useState({})

  const [StudenLoader, setStudenLoader] = useState(false)
  const [RegisteredLoader, setRegisteredLoader] = useState(false)
  const [ApplicationsLoader, setApplicationsLoader] = useState(false)
  const [open, SetOpen] = React.useState(false)


  const AssociateStudentListCountStatus = () => {
    setStudenLoader(true)
    let ProfileData = getUser()
    var data = new FormData();
    data.append('contact_no', ProfileData?.contact_no || '');

    getAssociateStudentListCountStatusAction(data).then((response) => {
      let Order = _.orderBy(_.map(response?.count_data || [], (d) => {
        d.count = parseInt(d.count)
        return d
      }), ['count'], 'desc')
      setStudenLoader(false)
      setGetListCountByData(Order)
    }).catch((error) => {
      setStudenLoader(false)
      setGetListCountByData([])
    })
  }

  const AssociateStatusProjecttCount = () => {
    setRegisteredLoader(true)
    let ProfileData = getUser()
    var data1 = `{"contact_no":"${ProfileData?.contact_no || ''}","bypass":"validation"}`;
    getAssociateStatusProjecttCountAction(data1).then((response) => {
      let Data = []
      let ResponseData = response?.data || {}
      _.map(Object.keys(response?.data || {}), (d) => {
        let temp = { name: '', score: 0 }
        temp.name = d
        temp.score = parseInt(ResponseData[`${d}`])
        Data.push(temp)
      })
      setRegisteredLoader(false)
      setGetAssociateProjectStatus(response?.data || {})
    }).catch((error) => {
      setRegisteredLoader(false)
      setGetAssociateProjectStatus({})
    })
  }

  const ApplicationCountData = () => {
    setApplicationsLoader(true)
    let ProfileData = getUser()

    var data1 = `{"contact_no":"${ProfileData?.contact_no || ''}","bypass":"validation"}`;
    GetApplicationCountDataAction(data1).then((response) => {
      let Data = []
      let ResponseData = response?.data || {}
      _.map(Object.keys(response?.data || {}), (d) => {
        let temp = { name: '', score: 0 }
        temp.name = d
        temp.score = parseInt(ResponseData[`${d}`])
        Data.push(temp)
      })
      setApplicationsLoader(false)
      setGetApplicationCountData(Data)
    }).catch((error) => {
      setApplicationsLoader(false)
      setGetApplicationCountData({})
    })
  }


  useEffect(() => {
    AssociateStudentListCountStatus()
    AssociateStatusProjecttCount()
    ApplicationCountData()
  }, [])

  let DataNameChange = user_contact_type === "Employee" ? employNameChanging : AssociateNameChanging
  let GetApplicationCountData1 = _.orderBy(_.filter(GetApplicationCountData, (d) => d.name !== "status"), ['score'], 'desc')
  let ApplicationData1 = _.filter(GetApplicationCountData1, (d) => d?.name?.search("_todaymodified") === -1)

  let RegisterData = GetAssociateProjectStatus ? GetAssociateProjectStatus : {}

  let _todayRehisteredCountArray = []
  _.map(Object.keys(RegisterData), (d) => {
    if (`${d}`.replace('_todaymodified', " ") === d) {
      _todayRehisteredCountArray.push(parseInt(RegisterData[d]))
    }
  })
  let TotalValue = _.sumBy(_todayRehisteredCountArray, (d) => parseInt(d))




  const getRegisteredData = () => {
    let string = `Projects:${TotalValue}%0D%0A`
    Object.keys(DataNameChange)?.map((register, id) => {
      string = string + `${AssociateNameChanging[register] || ''}:${RegisterData[`${register}`]}%0D%0A`
    })
    return string
  }

  const getApplicationStuatusData = () => {
    let TotalValue = _.sumBy(ApplicationData1, (d) => parseInt(d.score))
    let string = `Project Task:${TotalValue}%0D%0A`
    if (ApplicationData1.length > 0) {
      ApplicationData1.map((appData, i) => {
        string = string + `${Data[appData.name] || ''}:${appData?.score || 0}%0D%0A`
      })
    }
    return string
  }

  const getString = () => {
    const UserData = getUser()
    let RegisteredStuatus = getRegisteredData()
    let ApplicationStuatus = getApplicationStuatusData()
    let TempData = `Dear Sir
    %0D%0A%0D%0A Please find the Daily Report ${moment().format('MMMM Do YYYY, h:mm:ss a')} 
    %0D%0A ${UserData?.firstname || ''} ${UserData?.lastname || ''} 
    %0D%0A%0D%0A Projects 
    %0D%0A%0D%0A ${RegisteredStuatus}
    %0D%0A%0D%0A%0D%0A Project Task
    %0D%0A%0D%0A ${ApplicationStuatus}
    %0D%0A%0D%0A test
    %0D%0A ${UserData?.firstname || ''} ${UserData?.lastname || ''} 
    ${UserData?.assigned_phone_mobile ? `%0D%0A${UserData?.assigned_phone_mobile}` : ''}
    ${UserData?.assigned_phone_work ? `%0D%0A${UserData?.assigned_phone_work}` : ''}
    ${UserData?.assistantphone ? `%0D%0A${UserData?.assistantphone}` : ''}
    ${UserData?.assigned_email1 ? `%0D%0A${UserData?.assigned_email1}` : ''}
    ${UserData?.reports_to_email1 ? `%0D%0A${UserData?.reports_to_email1}` : ''}
    ${UserData?.account_website ? `%0D%0A${UserData?.account_website}` : ''}
   %0D%0A iOS: https://apple.co/33ujR4Q
   %0D%0A Android: https://bit.ly/FreeStudyAbroad`
    return `https://api.whatsapp.com/send?text=%0D%0A%0D%0A ${TempData.replaceAll('&', "and")}`
  }



  if (StudenLoader || RegisteredLoader || ApplicationsLoader) {
    return (<ContentLoader height={window.innerHeight} width={window.innerWidth} viewBox="0 0 265 230">
      <rect x="15" y="15" rx="4" ry="4" width="200" height="25" />
      <rect x="15" y="50" rx="2" ry="2" width="40" height="15" />
      <rect x="75" y="45" rx="16" ry="16" width="55" height="22" />
      <rect x="15" y="75" rx="3" ry="3" width="215" height="15" />
      <rect x="15" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="75" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="135" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="15" y="135" rx="16" ry="16" width="55" height="22" />
      <rect x="15" y="165" rx="2" ry="2" width="150" height="50" />
      <rect x="215" y="180" rx="2" ry="2" width="40" height="20" />
    </ContentLoader>)
  } else {
    return (
      <div className="dashboard_body">
        {/* <div className="d-flex align-items-center justify-content-center"> */}
        <div className="transiction_container">
          <h3 className="dashboard_title">Dashboard</h3>
          <Tabs>
            <TabList className="tab_class" style={{ paddingLeft: 0, margin: 0 }} >
              <Tab className="dashboard_category" style={{ width: "25%" }}>Students</Tab>
              <Tab className="dashboard_category" style={{ width: "25%" }}>Registered</Tab>
              <Tab className="dashboard_category" style={{ width: "25%" }}>Applications</Tab>
              <Tab className=" dashboard_category" style={{ width: "25%" }}>Others</Tab>
            </TabList>
            <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className="transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $1000 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn"
                        style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}
                        onClick={() => SetOpen(true)}>
                        + Add Student
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Students</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>

                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className=" transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $999 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn" style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}>
                        + Add
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Registered</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>
                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className=" transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $1111 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn" style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}>
                        + Add Application
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Applications</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>

                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className=" transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $1200 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn">
                        <img src={addMony} alt="" />
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Invoice</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>

                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>
              </section>
            </TabPanel>
          </Tabs>
        </div>
        {/* </div> */}
        <Analysics />

        <Students studentsData={getListCountByData} />
        <Registrated RegistratedData={GetAssociateProjectStatus ? GetAssociateProjectStatus : {}}
          DataNameChange={DataNameChange}
          employNameChanging={employNameChanging}
          AssociateNameChanging={AssociateNameChanging}
          getString={getString}
          TotalValue={TotalValue}
        />
        <Applications
          getString={getString}
          ApplicationData={GetApplicationCountData}
          Data={Data}
        />

        <BottomNav />
        <AddStudentModal open={open} onClose={() => SetOpen(false)} />

      </div>
    );
  };
}

export default Associate;
