export const initialState = {
    user: [],
    bottomValue: [],
    favourite_universities: [],
    partenered_universities: [],
    matching_universities: [],
    application_status_student:[],
    possible_scholarship_list:[],
    sop_list:[],
    related_programs_picklist:[],
    country_picklist:[],
    semester_picklist:[],
    year_picklist:[],
    event_list:[],
    leadstatus_picklist:[],
    bachelors_college_name:[],
    bachelors_year_of_passing:[],
    puc_college_name_12th:[],
    puc_year_of_passing_12th:[],
    german_language:[],
    leadsource_picklist:[],
    convertby_picklist:[],
    doc_list:[],
    upload_doc_list:[],
    mentor_list:[],
    service_list:[],
}


const useReducer = (state,action)=>{
    const { type,payload } = action;

    switch(type){
        case "Get_user":
            // console.log("Get User",payload)
            return {
                ...state,
                user : payload,
            }
        case "Footer_values":
            // console.log("Footer Values",payload)
            return {
                ...state,
                getBottomVal : payload,
            }
        case "favourite_universities":
            // console.log("Favourite Universities",payload)
            return {
                ...state,
                favourite_universities : payload,
            }
        case "partenered_universities":
            // console.log("Partenered Universities",payload)
            return {
                ...state,
                partenered_universities : payload,
            }
        case "matching_universities":
            // console.log("Matching Universities",payload)
            return {
                ...state,
                matching_universities : payload,
            }
        case "application_status_student":
            // console.log("application_status_student",payload)
            return {
                ...state,
                application_status_student : payload,
            }
        case "possible_scholarship_list":
            // console.log("possible_scholarship_list",payload)
            return {
                ...state,
                possible_scholarship_list: payload,
            }
        case "sop_list":
            // console.log("sop list",payload)
            return {
                ...state,
                sop_list: payload,
            }
        case "related_programs_picklist":
            // console.log("related_programs_picklist",payload)
            return {
                ...state,
                related_programs_picklist: payload,
            }
        case "country_picklist":
            // console.log("country_picklist",payload)
            return {
                ...state,
                country_picklist: payload,
            }
        case "semester_picklist":
            // console.log("semester_picklist",payload)
            return {
                ...state,
                semester_picklist: payload,
            }
        case "year_picklist":
            // console.log("year_picklist",payload)
            return {
                ...state,
                year_picklist: payload,
            }
        case "event_list":
            // console.log("event_list",payload)
            return {
                ...state,
                event_list: payload,
            }
        case "leadstatus_picklist":
            // console.log("leadstatus_picklist",payload)
            return {
                ...state,
                leadstatus_picklist: payload,
            }
        case "bachelors_college_name":
            // console.log("bachelors_college_name",payload)
            return {
                ...state,
                bachelors_college_name: payload,
            }
        case "bachelors_year_of_passing":
            // console.log("bachelors_year_of_passing",payload)
            return {
                ...state,
                bachelors_year_of_passing: payload,
            }
        case "puc_college_name_12th":
            // console.log("puc_college_name_12th",payload)
            return {
                ...state,
                puc_college_name_12th: payload,
            }
        case "puc_year_of_passing_12th":
            // console.log("puc_year_of_passing_12th",payload)
            return {
                ...state,
                puc_year_of_passing_12th: payload,
            }
        case "german_language":
            // console.log("german_language",payload)
            return {
                ...state,
                german_language: payload,
            }
        case "leadsource_picklist":
            // console.log("leadsource_picklist",payload)
            return {
                ...state,
                leadsource_picklist: payload,
            }
        case "convertby_picklist":
            return {
                ...state,
                convertby_picklist: payload,
            }
        case "doc_list":
            return {
                ...state,
                doc_list: payload,
            }
        case "upload_doc_list":
            console.log(payload)
            return {
                ...state,
                upload_doc_list: payload,
            }
        case "mentor_list":
            return {
                ...state,
                mentor_list: payload,
            }
        case "service_list":
            return {
                ...state,
                service_list: payload,
            }
        default:
            throw new Error(`No case for type ${type} found in useReducer`)
    }
}

export default useReducer;