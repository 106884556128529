import React, { useEffect, useState } from 'react'
import Header from './Header'
import AccomodationCard from './Card'
import { fetchAccomodationListAction } from '../Action'
import { getSessionId, getUserId } from '../../Controller/localStorageHandler'
import { makeStyles } from '@mui/styles'
import PageTitle from '../PageTitle'
import _ from 'lodash'
import { Autocomplete, Box, CircularProgress, Pagination, TextField } from '@mui/material'
const Accomodation = () => {
    const classes = useStyles();

    const [AccomodationData, setAccomodationData] = useState([])
    const [searchList, setSearchList] = React.useState([])
    const [allsearchList, setAllSearchList] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const [Limit, setLimit] = useState(30)
    const [Start, setStart] = useState(0)
    const [page, setPage] = useState(1)

    const filterResult = (val) => {
        if (val === null || val === '') {
            setSearchList(allsearchList)
        }
        else {
            var Data = allsearchList
            const ResultData = _.filter((Data), (d) => val.includes(d.university))
            setSearchList(ResultData)
        }


    }

    const loadMore = (p) => {
        setLimit(((p * 50) - 50) + 50)
        setStart((p * 50) - 50)
        setPage(p)
    }

    useEffect(() => {
        var data = new FormData();
        data.append('userid', getUserId());
        data.append('sessionid', getSessionId());
        data.append('operation', 'get_accomdation_details');
        fetchAccomodationListAction(data).then((response) => {
            setAccomodationData(_.filter(response?.data || [], (d) => d.is_active === "1"))
            setSearchList(_.filter(response?.data || [], (d) => d.is_active === "1"))
            setAllSearchList(_.filter(response?.data || [], (d) => d.is_active === "1"))
        }).catch((error) => {
            setAccomodationData([])
        })
    }, [])
    return (
        <div>
            <div className='global-container' style={{ padding: 0 }}>
                {/* <Header /> */}
                <div className="CommonSearchForNameEmailandMobile">
                    <Autocomplete
                        name='Search whatsapp group'
                        id="free-solo-demo"
                        freeSolo

                        // value={selectedSemester}
                        onChange={(e, val) => filterResult(val)}
                        options={allsearchList && _.uniqBy(allsearchList, 'university').map((option) => option?.university)}
                        renderInput={(params) => <TextField {...params} label="Search whatsapp group" />}
                    />
                </div>
                <div className={classes.container}>
                    <PageTitle pageTitle={`Accomodation List ${searchList?.length || 0}`} bold />
                    <img style={{ marginTop: '10px' }} src={`${process.env.PUBLIC_URL}/img/sort.png`} alt="" />
                </div>

                {
                    loading ?
                        <div style={{ textAlign: 'center', marginTop: '55%' }}>
                            <CircularProgress />
                        </div>
                        :
                        searchList?.length > 0 && searchList?.slice(Start, Limit)?.map((d) => {
                            return <AccomodationCard val={d} />
                        })
                }
                {
                                !loading && <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ width: '100%',marginTop:'30px' }}
                                >

                                    <Pagination
                                        siblingCount={0}
                                        defaultPage={1}
                                        page={page}
                                        count={Math.ceil(searchList?.length / 50 )}

                                        onChange={(e, p) => loadMore(p)}

                                    />
                                </Box>
                            }

            </div>
        </div>
    )
}

export default Accomodation

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between'
    },

}));
