import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Select } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { SortList, SortListData } from '../../Controller/Global';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import { getUserType } from '../../Controller/localStorageHandler';

export default function SortModal(props) {
    const {open, handleClose, handleSortList,type} = props
    const [tabValue, setTabValue] = React.useState(0)
    const [sortValue, setSortValue] = React.useState('')
    const classes = useStyles();

    const sortFunction = (value)=>{
        console.log(SortListData.find(o => o.label === 'profileEvaluationDetails').list[0])
        return SortListData.find(o => o.label === 'profileEvaluationDetails').list
    }


    const ChangeTab =(event,newValue)=>{
        console.log(newValue)
        setTabValue(newValue)
    }
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll={'body'}
            // fullWidth
            // maxWidth={'xl'}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {/* <h1>hk</h1>
                {
                   sortFunction(type).map((o) => 
                        <p onClick={()=>selectSort(o)} style={{cursor:'pointer'}}>{o.label} </p>)
                } */}
                 <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={tabValue}
                        onChange={ChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="0" label="Academic" />
                        <Tab value="1" label="Financial" />
                        <Tab value="2" label="Pre-Requisite" />
                    </Tabs>
                    {tabValue == 0 && 
                        <form onSubmit={()=>handleSortList(sortValue)} className={`${classes.cardBox} select-label-rmv`}>
                           {sortFunction(type)[0].map(o=>{
                               return <FormControl fullWidth sx={{mt: 2}}>
                                   <InputLabel id="demo-simple-select-label">{o.label}</InputLabel>
                                    <Select
                                        label={o.label}
                                        placeholder={o.label}
                                        onChange={(e)=>setSortValue(e.target.value)}
                                    >
                                        {o.value.map(obj=> <MenuItem value={obj.value}>{obj.label}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            })
                           } 
                           <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '20px' }}>
                                <Button className={classes.resetBtn}>Reset</Button>
                                <Button
                                    // onClick={()=>history.push('/SopSearchResult')}
                                    className={classes.submitBtn}
                                    type={'submit'}
                                >Search</Button>
                            </Grid>
                        </form>
                    }
                    {
                        tabValue == 1 && 
                        <form onSubmit={()=>handleSortList(sortValue)} className={`${classes.cardBox} select-label-rmv`}>
                           {sortFunction(type)[1].map(o=>{
                                if(o.label === 'Commision'){
                                    if(getUserType() === 'Student'){
                                        return ''
                                    }
                                    else{
                                        return <FormControl fullWidth sx={{mt: 2}}>
                                   <InputLabel id="demo-simple-select-label">{o.label}</InputLabel>
                                    <Select
                                        label={o.label}
                                        placeholder={o.label}
                                        onChange={(e)=>setSortValue(e.target.value)}
                                    >
                                        {o.value.map(obj=> <MenuItem value={obj.value}>{obj.label}</MenuItem>)}
                                    </Select>
                                    </FormControl>
                                    }
                                }
                               else{
                                return <FormControl fullWidth sx={{mt: 2}}>
                                   <InputLabel id="demo-simple-select-label">{o.label}</InputLabel>
                                    <Select
                                        label={o.label}
                                        placeholder={o.label}
                                        onChange={(e)=>setSortValue(e.target.value)}
                                    >
                                        {o.value.map(obj=> <MenuItem value={obj.value}>{obj.label}</MenuItem>)}
                                    </Select>
                                    </FormControl>
                               }
                                })
                            }
                            <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '20px' }}>
                                <Button className={classes.resetBtn}>Reset</Button>
                                <Button
                                    // onClick={()=>history.push('/SopSearchResult')}
                                    className={classes.submitBtn}
                                    type={'submit'}
                                >Search</Button>
                            </Grid>
                        </form>
                    }
                    {
                        tabValue == 2 && 
                        <form onSubmit={()=>handleSortList(sortValue)} className={`${classes.cardBox} select-label-rmv`}>
                           {sortFunction(type)[2].map(o=>{
                               return <FormControl fullWidth sx={{mt: 2}}>
                                   <InputLabel id="demo-simple-select-label">{o.label}</InputLabel>
                                    <Select
                                        label={o.label}
                                        placeholder={o.label}
                                        onChange={(e)=>setSortValue(e.target.value)}
                                    >
                                        {o.value.map(obj=> <MenuItem value={obj.value}>{obj.label}</MenuItem>)}
                                    </Select>
                                </FormControl>
                           })
                        }
                        <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Button className={classes.resetBtn}>Reset</Button>
                            <Button
                                // onClick={()=>history.push('/SopSearchResult')}
                                className={classes.submitBtn}
                                type={'submit'}
                            >Search</Button>
                        </Grid>

                        </form>
                    }
                    </Box>
            </DialogContent>
        </Dialog>
    )
}
const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
}));