import { Autocomplete, Typography,Checkbox, Chip, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel,  IconButton,Grid,Button, MenuItem, Select, Switch, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";
import axios from 'axios';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import Slider from '@mui/material/Slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import PrerequisiteFilter from './PrerequisiteFilter';
import { useFormik } from 'formik';
import { makeStyles } from "@mui/styles";

import useAppContext from '../../useContext/userContext'
import { getUserType } from '../../Controller/localStorageHandler';
import { useState } from 'react';

let searchData = require('../Common/Search/unisearchdata.json');

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}
export default function FilterModal(props) {
    const classes = useStyles();
    const {
        getRelatedProgramsPickList,related_programs_picklist,
        getCountryPickList,country_picklist,
    } = useAppContext()
    // console.log(related_programs_picklist)
    const {open, handleClose,handleFilterList, filterValues} = props
    console.log(filterValues)
    console.log(country_picklist)
    const [tabValue, setTabValue] = React.useState(0)

    const ChangeTab =(event,newValue)=>{
        console.log(newValue)
        setTabValue(newValue)
    }

    const formik = useFormik({
        initialValues: {
            University_Type:'',
            Rank:[0, 1000],
            GPA:[0, 100],
            English:[0, 100],
            Internship:[0, 100],
            Appli_Fee_Waiver:'',
            Associate:'',
            // Admission_Deadline_From:[null, null],
            Application_Fee:[0, 10000],
            Tution_Fee:[0, 100000],
            commission_amt:[0, 30000],
            GRE:[0, 340],
            GMAT:[0, 800],
            SAT:[0, 1600],
            TOEFL:[0, 120],
            PTE:[0, 120],
            ACT:[0, 9],
            IELTS:[0, 9],
            DUOLINGO:[0, 9],
            country:'',
            program:'',
            university:''
        },
        enableReinitialize: false,
        onSubmit: async (values) => {
            handleFilterList(values)
            // console.log(values)
        }
    })
    useEffect(() => {
        getRelatedProgramsPickList()
        getCountryPickList()
    }, []);
    const valuetext = (value) => {
        return `${value}`
    }
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll={'body'}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
            <form onSubmit={formik.handleSubmit} className={`${classes.cardBox} select-label-rmv`}>
            <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={tabValue}
                        onChange={ChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value={0} label="Academic" />
                        <Tab value={1} label="Financial" />
                        <Tab value={2} label="Pre-Requisite" />
                    </Tabs>
                    {tabValue == 0 && <>
                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <Autocomplete
                        name='country'
                        freeSolo
                        multiple
                        value={formik.values.country || filterValues?.country}
                        onChange={(e,value)=>formik.setFieldValue("country",value)}
                        options={country_picklist?.country_picklist && country_picklist?.country_picklist.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Select Country" />}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <Autocomplete
                        name='related_programs'
                        freeSolo
                        multiple
                        value={formik.values.program || filterValues?.program}
                        onChange={(e,value)=>formik.setFieldValue("program",value)}
                        options={related_programs_picklist?.related_programs_picklist && related_programs_picklist?.related_programs_picklist.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Select Program" />}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <Autocomplete
                        name='university'
                        freeSolo
                        multiple
                        value={formik.values.university || filterValues?.university}
                        onChange={(e,value)=>formik.setFieldValue("university",value)}
                        options={searchData.university.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Select University" />}
                    />
                </FormControl>

                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ m: 1, mb:2, minWidth: "100%" }}> University Type :</Typography>
                        <RadioGroup
                            className={classes.radioGroup}
                            row name="University_Type"
                            defaultValue={formik.values.University_Type || filterValues?.University_Type}
                            onChange={formik.handleChange}
                        >
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Pubilc"
                                label="Public"
                                style={{ padding: "5px 17px",marginBottom:5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}                               
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Private"
                                label="Private"
                                style={{ padding: "5px 17px",marginBottom:5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Both"
                                label="Both"
                                style={{ padding: "5px 17px",marginBottom:5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                        <Typography sx={{  mt:1,mb:1,  minWidth: "100%" }}> Rank % :</Typography>
                        <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={1000}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.Rank || formik.values.Rank}
                            min={0}
                            step={1}
                            max={1000}
                            name="Rank"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                        <Typography sx={{  mt:1, mb:1, minWidth: "100%" }}> GPA % :</Typography>
                        <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={100}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.GPA || formik.values.GPA}
                            min={0}
                            step={1}
                            max={100}
                            name="GPA"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                        <Typography sx={{  mt:1,mb:1,  minWidth: "100%" }}> English % :</Typography>
                        <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={100}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.English || formik.values.English}
                            min={0}
                            step={1}
                            max={100}
                            name="English"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                        <Typography sx={{ mt:1,mb:1, minWidth: "100%" }}> Internship(months) :</Typography>
                        <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={100}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.Internship || formik.values.Internship}
                            min={0}
                            step={1}
                            max={100}
                            name="Internship"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    </>
                    
                    }
                    {tabValue == 1 && <>
                        <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ m: 1, mb:0, minWidth: "100%" }}> Application Fee :</Typography>
                        <Chip
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={10000}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.Application_Fee || formik.values.Application_Fee}
                            min={0}
                            step={1}
                            max={10000}
                            name="Application_Fee"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ m: 1, mb:0, minWidth: "100%" }}> Tution Fee :</Typography>
                        <Chip
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={100000}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.Tution_Fee || formik.values.Tution_Fee}
                            min={0}
                            step={1}
                            max={100000}
                            name="Tution_Fee"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    {
                        getUserType() === 'Eployee' &&
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ m: 1, mb:0, minWidth: "100%" }}>Commission Amount :</Typography>
                        <Chip
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={30000}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.commission_amt || formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={30000}
                            name="commission_amt"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    }
                   
                <FormControlLabel control={
                            <Checkbox 
                                name='Appli_Fee_Waiver'
                                value={1} 
                                onChange={formik.handleChange}
                                checked={filterValues?.Appli_Fee_Waiver || formik.values.Appli_Fee_Waiver}
                            />
                        } label="Application Fee Waiver" />
                <FormControlLabel control={
                            <Checkbox 
                                name='Associate'
                                value={1} 
                                onChange={formik.handleChange}
                                checked={filterValues?.Associate || formik.values.Associate}
                            />
                        } label="Associated" />
                    </>
                    }
                    {tabValue == 2 && <>
                        <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> GRE :</Typography>
                        <Chip 
                                label={0}
                                sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                            />
                            <Chip 
                                label={340}
                                sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                            />
                        <Slider
                            value={filterValues?.GRE || formik.values.GRE}
                            min={0}
                            step={1}
                            max={340}
                            name="GRE"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> GMAT :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={800}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.GMAT || formik.values.GMAT}
                            min={0}
                            step={1}
                            max={800}
                            name="GMAT"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> SAT :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={1600}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.SAT || formik.values.SAT}
                            min={0}
                            step={1}
                            max={1600}
                            name="SAT"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> TOEFL :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={120}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.TOEFL || formik.values.TOEFL}
                            min={0}
                            step={1}
                            max={120}
                            name="TOEFL"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> PTE :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={120}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.PTE || formik.values.PTE}
                            min={0}
                            step={1}
                            max={120}
                            name="PTE"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> IELTS :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={9}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.IELTS || formik.values.IELTS}
                            min={0}
                            step={1}
                            max={9}
                            name="IELTS"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> ACT :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={9}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.ACT || formik.values.ACT}
                            min={0}
                            step={1}
                            max={9}
                            name="ACT"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> DUOLINGO :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={9}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px", right:0, bottom:46}}

                        />
                        <Slider
                            value={filterValues?.DUOLINGO || formik.values.DUOLINGO}
                            min={0}
                            step={1}
                            max={9}
                            name="DUOLINGO"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="on"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    </>
                    }
            </Box>
            <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '5px' }}>
                        <Button className={classes.resetBtn}>Reset</Button>
                        <Button
                            // onClick={()=>history.push('/SopSearchResult')}
                            className={classes.submitBtn}
                            type={'submit'}
                        >Filter</Button>
                    </Grid>
            </form>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
}));

