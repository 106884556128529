import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import Card from "./Card/index";
import _ from 'lodash'
import { OPERATION, operationValue, api } from "../../Controller/Global";
import {
  getSessionId,
  getUserId,
  SESSION_ID,
  USER_ID,
} from "../../Controller/localStorageHandler";
import axios from "axios";

const Data = [
  {
      name: "Avanse Financial Services Ltd",
      img: "AVANSE.png"
  },
  {
      name: "Auxilo Finserve Pvt Ltd",
      img: "AUXILO.png"
  },
  {
      name: "InCred",
      img: "INCRED.png"
  },
  {
      name: "SBI",
      img: "SBI.png"
  },
  {
      name: "HDFC CREDILA",
      img: "HDFC CREDILA.png"
  },
  {
      name: "AXIS Bank",
      img: "AXIS BANK.png"
  },
  {
      name: "Nomad",
      img: "NOMARD CREDIT.png"
  },
  {
      name: "MPOWER",
      img: "MPOWER.png"
  },
  {
      name: "IDFC FIRST",
      img: "IDFC FIRST BANK.png"
  },
  {
      name: "CREDENC",
      img: "CREDENC.png"
  }
]

function EducationLoanList() {
  const [data, setData] = useState([]);
  const Favourite = new FormData();

  useEffect(() => {
    Favourite.append(OPERATION, operationValue.FetchVendor);
    Favourite.append(SESSION_ID, getSessionId());
    Favourite.append(USER_ID, getUserId());
    Favourite.append("associate_type", "");
    axios
      .post(api, Favourite)
      .then((response) => {
        setData(response?.data?.data);
        console.log("dsdsd", response.data.data);
      });
  }, []);
  console.log("data", data)
  return (
    <div>
      <div style={{ backgroundColor: "#fcf7f7" }}>
        <PageTitle pageTitle="Education Loan" bold />
        {data.map((value) => {
          let ImgName = _.find(Data, (d) => d.name === value.vendorname)

          return <Card value={value} ImgName={ImgName} />
        })}
      </div>
    </div>
  );
}

export default EducationLoanList;
