import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';


export default function PreviewDocumentModal(props) {
    const {open,handleClose}= props
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth='md'
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Preview Document
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
            <div className="flexCenterJcB">
                    <main style={{ marginTop: '4%' }}>
                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`}/>
                    </main>
                    <main className="flexCenterJcB">
                        <nav>
                            <div style={{ marginTop: '20px'}}>
                                <p style={{
                                    fontWeight:'bold'
                                }}> Document Name</p>
                                {/* <Box sx={{ width: '140%' }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box> */}
                            </div>
                        </nav>
                    </main>
                    {/* <p style={{
                        fontWeight:'normal',
                        color:'#888888'
                    }}>70%</p> */}
                    <CancelPresentationRoundedIcon sx={{ opacity: 0.5 }} />
                </div>
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleClose}>
                Save changes
            </Button>
            </DialogActions>
      </Dialog>
    )
}

