import { makeStyles } from "@mui/styles";
import React from "react";

const EventsNav = ({ toggle, setToggle }) => {
 const { forYou, trending, upcoming } = toggle;
 const classes = useStyles();
 return (
  <div className={classes.container}>
   <p
       style={{marginBottom:0}}
       className={`${forYou && classes.bgBlue} ${classes.text}`}
       onClick={() =>
         setToggle({ forYou: true, trending: false, upcoming: false })
       }
   >
    For you
   </p>
   <p
       style={{marginBottom:0}}
       className={`${trending && classes.bgBlue} ${classes.text}`}
       onClick={() =>
         setToggle({ forYou: false, trending: true, upcoming: false })
       }
   >
    Trending
   </p>
   <p
       style={{marginBottom:0}}
       className={`${upcoming && classes.bgBlue} ${classes.text}`}
       onClick={() =>
         setToggle({ forYou: false, trending: false, upcoming: true })
       }
   >
    Upcoming
   </p>
  </div>
 );
};

export default EventsNav;

const useStyles = makeStyles((theme) => ({
 container: {
    display: "flex",
    justifyContent: "space-evenly",
    border: "1px solid transparent",
    borderRadius: "30px",
    boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05)",
     backgroundColor: '#FFFFFF',
     marginBottom:'20px'
 },
 text: {
    fontSize: "16px",
    lineHeight:'20px',
    fontWeight : '600',
    color:'#CED0D5',
     padding:'10px 0'
 },
 bgBlue: {
  color: "#1B6CFC",
 },
}));
