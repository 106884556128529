import { Box, Chip, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import React from 'react'
import { barColor, getCurrencySymbol } from '../../Controller/Global';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import moment from 'moment';
import { getUser } from '../../Controller/localStorageHandler';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';


export default function ResultCard(props) {
    const {
        isApplied,
        setBookmarkId,
        setApplyData,
        setOpenApply,
        setOpenFav,
        related_programs,
        val, setSopData, setOpenDownload,
        setAddStudentOpen,
        Student,
        setSource,
        setIsBookMark
    } = props;
    const history = useHistory()

    let Date = val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline
    let UserLocalstorge = getUser()
    let user_contact_type = Student?.user_contact_type || UserLocalstorge?.user_contact_type || ''

    const [open, setOpen] = React.useState(false);
    const [open_app, setOpenApp] = React.useState(false);
    const [open_deadline, setOpenDeadline] = React.useState(false);
    const [open_program_name, setOpenProgramName] = React.useState(false);
    const [open_commision, setOpen_commision] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseApp = () => {
        setOpenApp(false)
    }

    const handleOpenApp = () => {
        setOpenApp(true)
    }

    const handleCloseDeadline = () => {
        setOpenDeadline(false)
    }

    const handleOpenDeadline = () => {
        setOpenDeadline(true)
    }
    const handleOpenCommision = () => {
        setOpen_commision(true)
        console.log('done')
    }
    const handleCloseCommision = () => {
        setOpen_commision(false)
    }
    // console.log(val)
    // console.log(moment(val.inter_adm_deadline,'DD-MM-YYYY').format("MMMM Do YYYY"))

    return (
        <Grid container spacing={1} sx={{ position: 'relative', minHeight: "200px", }}>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <img
                    onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
                    src={val.university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${val.university}.png` : ''}

                    style={{
                        backgroundColor: "#fff",
                        padding: "7px",
                        width: "80px",

                        // position: "absolute",
                        content: "",
                        padding: "4px",
                        width: "50px",
                        top: "0",
                        right: "0",
                        bottom: "0px",
                        left: "0",
                        zIndex: "0",
                        borderRadius: "8px",
                        margin: "5px",
                        bordeRadius: "inherit",
                        background: "linear-gradient(to right, #0056ff, #45a5ff)",

                    }}
                    alt='college'
                />


                {
                    val?.associate && val?.associate === '1' ?
                        <Tooltip
                            title="Partnered University"

                            sx={{}}
                            open={open} onClose={handleClose} onOpen={handleOpen}
                        >
                            <Button sx={{

                                fontSize: '18px',
                                padding: '0px',
                                background: '#858585',
                                borderRadius: '24px',
                                // marginLeft: '12px',
                                minWidth: '32px !important'
                            }} size="small" onClick={() => setOpen(!open)}>🤝</Button>
                        </Tooltip>

                        : ''
                }
                {
                    user_contact_type === 'Associate Admin'
                        ?
                        <>
                            <br />
                            <Tooltip
                                title="Commission Amount"
                                open={open_commision} onClose={handleCloseCommision} onOpen={handleOpenCommision}
                            >
                                <Chip size='small' color="error" onClick={() => setOpen_commision(!open_commision)} label={`${Math.trunc((parseInt(val?.commission_amount) * parseInt(getUser()?.commission)) / 100)}`} />

                            </Tooltip>
                        </>
                        :
                        ''
                }


                <div style={{ display: "block", marginTop: '10px' }} >
                    {!val.sop_title && <>
                        {/* <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>{val.funding_type ? val.funding_type : val.semester} <span style={{ color: "red", fontWeight: "bold" }}>  {Date ? moment(Date,'DD-MM-YYYY').format("ll") : ""}</span>
                        <span style={{ float: 'right', marginRight: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                            {getCurrencySymbol(val.country)}
                            {Math.round(val.scho_amt ? val.scho_amt : val.international_tuition_fee_yr)} / yr</span> </Typography>

                    <Typography style={{ position: "absolute", left: "15px", bottom: "-1px", zIndex: "9", fontSize: "12px", color: "#fff", fontWeight: "bold" }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography>
                    <LinearProgress variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? val.success_rate : val.acceptance_rate} style={{ marginTop: '15px', maxWidth: "40%", width: '40%', height: "18px", borderRadius: "50px", float: 'left' }} /> */}
                        <Typography onClick={(e) => {
                            e.stopPropagation();
                            setApplyData(val)
                            setOpenApply(true)
                        }} style={{ position: "absolute", left: "21px", bottom: "45px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold",cursor:'pointer' }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography>
                        <CircularProgress
                            onClick={(e) => {
                                e.stopPropagation();
                                setApplyData(val)
                                setOpenApply(true)
                            }}
                            variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? parseInt(val.success_rate) : parseInt(val.acceptance_rate)} style={{ backgroundColor: "#edb28e", borderRadius: "50px", width: "35px", height: "auto !important", padding: "3px", position: "absolute", bottom: "35px", left: "12px" }} />

                    </>}
                </div>
               
            </Grid>
            <Grid item xs={10} sx={{ pl: 1, position: 'relative' }}>
                <Typography onClick={() => {
                    history.push({
                        pathname: `/CollegeDetails/${val?.universitiesid}`,
                        state: {
                            data: val,
                        }
                    })
                }} sx={{ fontWeight: "bold", fontSize: "14px", mt: 1, mr: 4, width: '200px', color: "#137fe8", overflowX: 'hidden', height: '40px' }}>{val.scholarship_name ? val.scholarship_name : val.university}</Typography>


                <Tooltip
                    title={val?.degree_name}
                    //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "70px", marginRight: '15px', float: 'left' }}
                    open={open_program_name} onClose={() => setOpenProgramName(!open_program_name)} onOpen={() => setOpenProgramName(!open_program_name)}
                >
                    <Typography sx={{ fontSize: '13px' }}><span style={{ color: '#FF9900' }}>{val?.degree_name || ''}</span> {val?.program}</Typography>
                </Tooltip>


                {
                    getUser() ?
                        user_contact_type !== "Student" && val.university
                            ?
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                // aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                                sx={{ marginRight: '15px', position: 'absolute', top: "0px", right: '0' }}
                            // onClick={() => setAddStudentOpen(true)}

                            >
                                {val.bookmark === '1' || val.bm_bookmark === '1' ?
                                    <FavoriteIcon
                                        style={{ color: 'red' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                                            setOpenFav(true)
                                            setIsBookMark(true)
                                        }
                                        }
                                    /> :

                                    <BookmarkAddOutlinedIcon
                                        style={{ color: 'red' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSource('bookmark')
                                            setApplyData(val)
                                            setBookmarkId(val.universitiesid)
                                            setAddStudentOpen(true)
                                        }}
                                    />
                                }
                            </IconButton>
                            :
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                // aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                                sx={{ marginRight: '8px', position: 'absolute', top: "0px", right: '0' }}
                            // onClick={() => setOpenFav(true)}

                            >
                                {val.bookmark === '1' || val.bm_bookmark === '1' ?
                                    <FavoriteIcon
                                        style={{ color: 'red' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                                            setOpenFav(true)
                                            setIsBookMark(true)
                                        }
                                        }
                                    /> :
                                    <FavoriteBorderIcon onClick={(e) => {
                                        e.stopPropagation();
                                        (val.scholarshipid || val.sopid) ? val.scholarshipid ? setBookmarkId(val.scholarshipid) : setBookmarkId(val.sopid) : setBookmarkId(val.universitiesid)
                                        setOpenFav(true)
                                    }}
                                    />
                                }
                            </IconButton>
                        :
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                            sx={{ marginRight: '8px', position: 'absolute', top: "0px", right: '0' }}
                            onClick={(e) => {
                                history.push({
                                    pathname: '/login'
                                })
                            }}
                        >
                            <FavoriteBorderIcon />
                           
                        </IconButton>
                        
                }

               


                {/* <hr style={{ maxWidth: '70%', marginRight: '30%', color: '#cfcaca', width: '70%' }} /> */}


                {!val.sop_title && <>

                    <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>{val.funding_type ? val.funding_type : val.semester}
                        <Tooltip
                            title="Deadline"
                            //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "80px", marginRight: '15px', float: 'left' }}
                            open={open_deadline} onClose={handleCloseDeadline} onOpen={handleOpenDeadline}
                        >
                            <span style={{ color: "red", fontWeight: "bold" }} onClick={() => setOpenDeadline(!open_deadline)}>  ⏰ {Date ? moment(Date).format("ll") : ""}</span>
                        </Tooltip>
                    </Typography>

                    {/* <Typography style={{ position: "absolute", left: "15px", bottom: "-1px", zIndex: "9", fontSize: "12px", color: "#fff", fontWeight: "bold" }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography> */}
                    {/* <LinearProgress variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? val.success_rate : val.acceptance_rate} style={{ marginTop: '15px', maxWidth: "40%", width: '40%', height: "18px", borderRadius: "50px", float: 'left' }} />
                    <CircularProgress style={{ width: "22px"}} variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? val.success_rate : val.acceptance_rate} style={{ marginTop: '15px', maxWidth: "40%", width: '40%', height: "18px", borderRadius: "50px", float: 'left' }}/> */}


                </>}
                
                <div style={{
                    display: "flex",
                    position: "absolute",
                    bottom: "-15px",
                    width: "100%",
                }} >
                   

                    {
                        val?.app_fee_waiver && val?.app_fee_waiver === '1' ?
                            <Tooltip
                                title="Application Fee Waiver"

                                //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "80px", marginRight: '15px', float: 'left' }}
                                open={open_app} onClose={handleCloseApp} onOpen={handleOpenApp}
                            >
                                <Button size="small" onClick={() => setOpenApp(!open)} style={{ minWidth: "auto", paddingLeft: "0px" }}
                                    sx={{ textDecoration: 'line-through', color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-2px', left: "40px", marginRight: '15px', float: 'left' }}
                                >{getCurrencySymbol(val.country)}{val?.international_app_fee}</Button>

                            </Tooltip>
                            :
                            <Tooltip
                                title="Application Fee"

                                //  sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "110px", marginRight: '15px', float: 'left' }}

                                open={open_app} onClose={handleCloseApp} onOpen={handleOpenApp}
                            >
                                <Button style={{ minWidth: "auto", paddingLeft: "0px" }} size="small" onClick={() => setOpenApp(!open)}>{getCurrencySymbol(val.country)}{val?.international_app_fee}</Button>
                            </Tooltip>
                    }

                    {
                        getUser() ?
                            user_contact_type !== "Student" &&
                            // <Chip
                            //     label="+ Add Student"
                            //     sx={{ bgcolor: '#1B6CFC', color: '#fff', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "40px", marginRight: '15px', float: 'left' }}
                            //     onClick={(e) => {
                            //         e.stopPropagation();
                            //         setApplyData(val)
                            //         setSource('add_student')
                            //         setAddStudentOpen(true)
                            //     }}
                            // />

                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                // aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            // sx={{  color: '#1B6CFC', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "50px", marginRight: '15px', float: 'left' }}
                            >
                                <PersonAddOutlinedIcon
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setApplyData(val)
                                        setSource('add_student')
                                        setAddStudentOpen(true)
                                    }}
                                />

                            </IconButton>
                            :
                            ''

                    }


                    <div

                        style={{
                            display: "flex",
                            float: "right",
                            border: `${isApplied ? '3px solid #239e4c' : '3px solid #1986f6'}`,
                            borderRadius: "16px 22px 22px 16px",
                            // marginRight: "12px",
                            flexDirection: "row",
                            position: "absolute",
                            bottom: "0px",
                            right: "8px"
                        }}

                    >
                        {!val.sop_title && <>
                            <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', margin: " 4px 7px", fontWeight: " 700", color: "#2f86f6", }}>
                                {getCurrencySymbol(val.country)}
                                {Math.round(val.scho_amt ? val.scho_amt : val.international_tuition_fee_yr)} <span style={{ fontWidth: "100" }} >/yr</span> </Typography>
                        </>}

                        {val.university ?
                            setOpenApply ?
                                isApplied
                                    ?
                                    <Chip
                                        label="Applied"
                                        style={{ borderRadius: "12px !important", backgroundColor: "#239e4c", color: "#fff" }}
                                    // sx={{ bgcolor: '#000', color: '#fff', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "40px", marginRight: '15px', float: 'left' }}
                                    />
                                    :
                                    <Chip
                                        label="Apply"
                                        style={{ borderRadius: "12px !important", backgroundColor: "#2f86f6", color: "#fff" }}
                                        // sx={{ bgcolor: '#000', color: '#fff', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "40px", marginRight: '15px', float: 'left' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setApplyData(val)
                                            setOpenApply(true)
                                        }}
                                    />
                                :
                                <>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        aria-label="account of current user"
                                        // aria-controls={menuId}
                                        aria-haspopup="true"
                                        color="inherit"
                                        sx={{ marginRight: '45px', position: 'absolute', bottom: "-6px", right: '0' }}

                                    >
                                        <ArrowDownwardIcon
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setSopData(val)
                                                setOpenDownload(true)
                                            }}
                                        />

                                    </IconButton>
                                </>
                            :
                            <Chip
                                label="Apply"
                                style={{ borderRadius: "12px !important", backgroundColor: "#2f86f6", color: "#fff" }}
                                // sx={{ bgcolor: '#000', color: '#fff', position: 'absolute', marginTop: '5px', bottom: '-7px', right: "40px", marginRight: '15px', float: 'left' }}
                                onClick={(e) => {
                                    history.push({
                                        pathname: '/login'
                                    })
                                }}
                            />
                        }
                    </div>

                </div>

                {val.scholarship_name &&
                    <>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                            sx={{ marginRight: '75px', position: 'absolute', bottom: "-6px", right: '0' }}

                        >
                            < LanguageIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.location.href = `${val.website}`
                                }}
                            />

                        </IconButton>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                            sx={{ marginRight: '45px', position: 'absolute', bottom: "-6px", right: '0' }}
                        >
                            < MailOutlineIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    console.log(val.email)
                                    window.location.href = `mailto:${val.email}?subject=Comments about the color blue`
                                }}

                            />

                        </IconButton>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                            sx={{ marginRight: '105px', position: 'absolute', bottom: "-6px", right: '0' }}

                        >
                            <CallIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    console.log(val)
                                    window.location.href = `tel:${val.mobile_number}`
                                }}
                            />


                        </IconButton>
                      
                    </>

                }

              

            </Grid>
            <Grid container marginTop={2}>
                   <Box sx={{position:"absolute",bottom:"-4px",width:"100%",backgroundColor:"#e0e0e0",borderRadius:"20px"}}>
                   <Stack direction="row" spacing={1} sx={{textAlign:"center",width:"100%"}}>
                   <Grid item  xs={12} >
                   <Stack spacing={0.5} sx={{py:"2.5px",borderRight:"1px solid #aec6cf"}}>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}}><span style={{color:"#137fe8",fontWeight:"bold"}}>Viewed </span></Typography>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}} >{val?.total_view || val?.total_view}</Typography>
                   </Stack></Grid>
                   <Grid item  xs={12} >
                    <Stack spacing={0.5} sx={{py:"2.5px",borderRight:"1px solid #aec6cf"}}>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}}> <span style={{color:"#137fe8",fontWeight:"bold"}}>Shortlisted </span> </Typography>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}} >{val?.total_shortlisted || val?.total_shortlisted}</Typography>
                   </Stack>
                      </Grid>
                   <Grid item  xs={12} >
                   <Stack spacing={0.5} sx={{py:"2.5px",borderRight:"1px solid #aec6cf"}}>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}}> <span style={{color:"#137fe8",fontWeight:"bold"}}>Applied </span> </Typography>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}} >{val?.total_applied || val?.total_applied}</Typography>
                   </Stack></Grid>
                   <Grid item  xs={12}>
                   <Stack spacing={0.5} sx={{py:"2.5px",borderRight:"1px solid #aec6cf"}}>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}}> <span style={{color:"#137fe8",fontWeight:"bold"}}>Admitted </span> </Typography>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}} >{val?.total_admitted || val?.total_admitted}</Typography>
                   </Stack></Grid>
                   <Grid item xs={12} >
                   <Stack spacing={0.5} sx={{py:"2.5px"}}>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}}> <span style={{color:"#137fe8",fontWeight:"bold"}}>Enrolled </span> </Typography>
                    <Typography sx={{fontSize:"10px",fontWeight:"bold"}} >{val?.total_enrolled || val?.total_enrolled}</Typography>
                   </Stack></Grid>
                   
                   </Stack>
                  </Box>
                  </Grid>
                  
                 
        </Grid>
        
    )
}
