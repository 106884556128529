import React, { useState, useEffect } from 'react'
import { Autocomplete,FormControl, Grid, Input, Button, Typography, Select, MenuItem,TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";



import { getSessionId, getUser, getUserId } from '../../../../Controller/localStorageHandler';
import { makeStyles } from "@mui/styles";

import axios from 'axios';

import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import {  toast } from 'react-toastify';



const AddUniversityEvent = (props) => {
    const history = useHistory()

    const classes = useStyles();

    const [campaignName, setCampaignName] = useState('');
    const [representive,setRepresentive] = useState('');
    const [venue, setVenue] = useState('');
    const [campaignStatus,setCampaignStatus] = useState('');
    const [timingFrom,setTimingFrom] = useState('');
    const [timingTo,setTimingTo] = useState('');
    const [date,setDate] = useState('');
    const [zoomLink,setZoomLink] = useState('');
    const [googleMeet,setGoogleMeet] = useState('');
    const [jioMeet,setJioMeet] = useState('');
    const [meetingId,setMeetingId] = useState('');
    const [passcode, setPasscode] = useState('');
    const [campaignType,setCampaignType] = useState('');
    const [closeDate,setCloseDate] = useState('');
    const [descri,setDescri] = useState('');

    const [programList, setProgramList] = React.useState('');

    const [programValue, setprogramValue] = React.useState([])



    const handleChange =(value) =>{
        setTimingFrom(value)
    }

    const handleChangeDate = (value)=>{
        setDate(value)
    }

    const handleChangeCloseDate = (value) =>{
        setCloseDate(value)
    }

    useEffect(() => {
        async function syncData() {
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'related_programs');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                });
         setProgramList(response.data.data);
 
        }
        syncData();
    }, []);


    const submitEvent = () =>{
        let pData = ''
        programValue.map((d)=>{
           let nData = d+'|##|'
            pData = pData+nData
        });
        console.log(pData)
        // return
        var data = JSON.stringify({
            "contact_no":getUser()?.contact_no,
            "campaignName":campaignName,
            "representive":representive,
            "venue":venue,
            "campaignStatus":campaignStatus,
            "timingFrom":timingFrom,
            "timingTo":timingTo,
            "date":date,
            "zoomLink":zoomLink,
            "googleMeet":googleMeet,
            "jioMeet":jioMeet,
            "meetingId":meetingId,
            "passcode":passcode,
            "campaignType":campaignType,
            "closeDate":closeDate,
            "descri":descri,
            "programs":pData,
        });
        console.log(data)
        let config = {
            url: 'https://erp.bscglobaledu.com/mapi/university/ajax_create_new_campaign',
            data: data,
            method: 'post'
        }
        axios(config).then((response) => {
            console.log(response)
            toast.success(`Event Created Successfully`)
        })
    }

    return <div>
        <Button sx={{ minWidth: '0px', paddingLeft: '0px' }} onClick={() => history.goBack()}><ArrowBackIcon />Back</Button>
   {/* <Birthday data={d} countData={EventListData?.count_data || null} /> */}
        <div style={{ paddingLeft: 15, paddingRight: 15, height: "90%", overflowY: "scroll" }}>
  

{/* test form */}
            <Grid
      xs={12}
      sx={{
        p: 3,
        paddingBottom: "80px",
        backgroundColor: "#fff",
        mt: 3,
        pb: 2,
        borderRadius: "10px",
        boxShadow: 3,
      }}
    >
      <Typography > Create New Campaign </Typography>
      <TextField  fullWidth label="Campaign Name" variant="standard"
      onChange={(e)=>setCampaignName(e.target.value)}
      >
        <Input type="text" 
        value={campaignName}
        />
        </TextField>

        <TextField fullWidth label="University Representative" variant="standard"
        onChange={(e)=>setRepresentive(e.target.value)}
        >
        <Input type="text" 
        value={representive}
 />
        </TextField>

        <TextField fullWidth label="Venue" variant="standard"
        onChange={(e)=>setVenue(e.target.value)}
        >
        <Input type="text" 
        value={venue}
        placeholder={"Venue"} />
        </TextField>


        <FormControl sx={{ minWidth: "100%" }}>
        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            name="program"
                            className="radius-select"
                            value={programValue}
                            onChange={(e, val) => setprogramValue(val)}
                            options={programList && programList.map((option) => option)}
                            renderInput={(params) => <TextField  fullWidth variant="standard" {...params} label="Select Program" />}
                        />
        </FormControl>


     

      <FormControl sx={{ minWidth: "100%" }} variant="standard" >
        <InputLabel id="demo-simple-select-standard-label">Campaign Status</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          onChange={(e)=>setCampaignStatus(e.target.value)}
          value={campaignStatus}
          label="Campaign Status"
        >
         <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
          <MenuItem value="Drafts">Drafts</MenuItem>
          <MenuItem value="Closed">Closed</MenuItem>
        </Select>
      </FormControl>

    


   
      {/* <TextField fullWidth label="Timing From" variant="standard"> */}
        {/* <Input type="text" 
        value={timingFrom}
        onChange={(e)=>setTimingFrom(e.target.value)}
        /> */}
        <LocalizationProvider  dateAdapter={AdapterDateFns}>
        <Stack spacing={3} >
        <TimePicker
        
          label="Event Time"
          value={timingFrom}
          onChange={handleChange}
          renderInput={(params) => <TextField  fullWidth label="Event Time" variant="standard" {...params} />}
        />

        <DesktopDatePicker
          label="Event Date"
          inputFormat="dd/MM/yyyy"
          value={date}
          onChange={handleChangeDate}
          renderInput={(params) => <TextField fullWidth label="Event Date" variant="standard" {...params} />}
        />
        </Stack>


        </LocalizationProvider>
        {/* </TextField> */}
{/*    
      <TextField fullWidth label="Timing To" variant="standard">
        <Input type="text" 
        value={timingTo}
        onChange={(e)=>setTimingTo(e.target.value)}
     />
        </TextField> */}
     
{/* 
        <TextField fullWidth label="Event Date" variant="standard">
        <Input type="text"
        value={date}
        onChange={(e)=>setDate(e.target.value)}
    />
        </TextField> */}

        <TextField fullWidth label="Zoom Link" variant="standard"
        onChange={(e)=>setZoomLink(e.target.value)}
        >
        <Input type="text"
        value={zoomLink}
      />
        </TextField>

        <TextField fullWidth label="Google Meet" variant="standard"
        onChange={(e)=>setGoogleMeet(e.target.value)}
        >
        <Input type="text"
        value={googleMeet}
         />
        </TextField>

        <TextField fullWidth label="Jio Meet" variant="standard"
        onChange={(e)=>setJioMeet(e.target.value)}
        >
        <Input type="text"
        value={jioMeet}
         />
        </TextField>

        <TextField fullWidth label="Meeting Id" variant="standard"
        onChange={(e)=>setMeetingId(e.target.value)}
        >
        <Input type="text"
        value={meetingId}
        />
        </TextField>

        <TextField fullWidth label="Passcode" variant="standard"
        onChange={(e)=>setPasscode(e.target.value)}
        >
        <Input type="text"
        value={passcode}
         />
        </TextField>

        <TextField fullWidth label="Campaign Type" variant="standard"
        onChange={(e)=>setCampaignType('Spot Admissions')}
        >
        <Input type="text"
        value={campaignType}
         />
        </TextField>

        <LocalizationProvider  dateAdapter={AdapterDateFns}>
        <Stack spacing={3} >
        <DesktopDatePicker
          label="Expected Close Date"
          inputFormat="dd/MM/yyyy"
          value={closeDate}
          onChange={handleChangeCloseDate}
          renderInput={(params) => <TextField fullWidth label="Expected Close Date" variant="standard" {...params} />}
        />
        </Stack>


        </LocalizationProvider>

        <TextField fullWidth label="Description" variant="standard"
        onChange={(e)=>setDescri(e.target.value)}
        >
        <TextareaAutosize
      aria-label="Description"
      minRows={3}

      style={{  }}
      value={descri}
    />
        </TextField>


    </Grid>

        

            <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">


                {/* <Grid xs={12} sx={{ display: "flex", margin: "20px 0", }}>
                    <input type="checkbox"
                        //   {...label}
                        //   defaultChecked
                        style={{ height: "22px", width: "30px", background: "orange", border: " 1px solid #DCE8FF" }}
                    //    onChange={handleChange}
                    // onChange={(e) => setcheck(e.target.checked)}
                    />
                    <Typography
                        sx={{ color: "#666666", textAlign: "left", fontSize: "16px", lineHeight: "20px", marginLeft: "5px" }}
                    >
                        By submiting you agree with  our <a href="#" style={{ color: "#1b6cfc" }}
                            className={classes.Terms}>Terms</a> and <a href="#" style={{ color: "#1b6cfc" }} className={classes.Terms}>Conditions</a>
                    </Typography>
                </Grid> */}
                {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '5px', marginTop: '20px' }}>
                    <Button className={classes.resetBtn}>Reset</Button>
                    <Button
                        style={{ background: true ? "#666666" : "#1b6cfc", height: 50, color: "white", borderRadius: 25 }}
 
                        onClick={()=>submitEvent()}
                        className={classes.submitBtn}
                        type={'submit'}>
                        Submit
                    </Button>
                </Grid>
            </div>


        </div>
    </div>

}

export default AddUniversityEvent;

const useStyles = makeStyles(() => ({
    timepicker:{
        color:'#green',
        backgroundColor:'green',
    
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));