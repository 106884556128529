import React, { useState } from "react";
import { Grid , Button, Alert   } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { OPERATION, operationValue } from "../../../../Controller/Global";
import axios from "axios";
import { useFormik } from 'formik';
import {useHistory} from "react-router-dom";

// import SearchIcon from '@mui/icons-material/Search';

const ForgetForm = () =>{
  const history = useHistory()
     const classes = useStyles()
     let [error,setError]=useState('');
     let [success,setSuccess]=useState('');
     const formik = useFormik({
      initialValues: {
        username:'',
      },
      onSubmit: async (values) => {
          values[OPERATION]=operationValue.ForgotPassword
        console.log(values,'formik')
        const formData= new FormData()
        for(let val in values){
          formData.append(val, values[val])
        }
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",formData)
        .then(response => {
            if(response.data.status === 'success'){
              setSuccess('Forgotten Password after submission let the following message be displayed New Password for ISHVI Free Study Abroad is been sent to your email for Login')
              setTimeout(function(){ history.push('/') },3000)
            }else{
              setError(response.data.msg)
            }
        })
      },
    });

    if(success){
      return <Alert style={{marginBottom:'100px'}} severity="success">{success}</Alert>
  }else{
    return(
      <>
      { error && <Alert severity="error">{error}</Alert>}
        <form  onSubmit={formik.handleSubmit}>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={11}
            sx={{ margin :"auto" }}
          >
           
            <div>
      <main className={classes.inputContainer}>
      <img
                src={`${process.env.PUBLIC_URL}/img/signIcons/Mail.png`}
                style={{ marginTop:"7px"}}
                alt="mail"
              />
           
            <input 
              type="email" 
              name="username"
              className={classes.input} autoFocus  
              placeholder='Email@gmail.com'
              value={formik.values.username}
              onChange={formik.handleChange}
            /> 
            </main>
      </div>
     
            
          </Grid>
          <Grid xs={11} sx={{margin:"auto", mt:2}}>
          <Button
            type="submit"
            sx={{
              fontSize: "14px",
              fontWaight: "600",
              p: 1,
              color: "#fff",
              backgroundColor: "#1b6cfc",
              my: 2,
              borderRadius: "100px",
              width: "100%",
            }}
          >
           Send Password
          </Button>
          </Grid>
        </Grid>
        
        </form>
      </>
    )
  }
}
export default ForgetForm


const useStyles = makeStyles(() => ({
  inputContainer : {
     display : 'flex',
     padding : '12px',
     width : '90%',
     height : "20%",
     margin : "auto",
     border : '1px solid transparent',
     borderRadius : '25px',
     boxShadow : '2px 2px 10px #DCDCDC',
  },
  input : {
     borderColor : 'transparent',
     outline : 'none',
     marginLeft : '10px',
     fontSize : '16px',
     opacity : 0.7,
     letterSpacing : 0.7
  },
  filterIcon : {
      position : 'relative',
      top : 10,
      height : '75px',
      width : '75px',
     //  boxShadow : '2px 2px 10px #DCDCDC','
  }
 }))
