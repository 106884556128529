import React, { useEffect, useState } from 'react'
import Header from './Header'
import { getSessionId, getUserId } from '../../../Controller/localStorageHandler'
import { fetchApplyIntenshipListAction } from '../../Action'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useHistory } from 'react-router-dom'
import ApplicationStatus from '../../../components/ApplicationStatus';

import { makeStyles } from '@mui/styles'
import PageTitle from '../../PageTitle'

const UniverSityApplication = () => {
    const history = useHistory()
    const classes = useStyles();

    const [ApplyIntenshipData, setApplyIntenshipData] = useState([])

    useEffect(() => {
        var data = new FormData();
        data.append('userid', getUserId());
        data.append('sessionid', getSessionId());
        data.append('operation', 'get_internship_details');

        fetchApplyIntenshipListAction(data).then((response) => {
            setApplyIntenshipData(response?.data || [])
        }).catch((error) => {
            setApplyIntenshipData([])
        })
    }, [])

    return (
        <div>
            <div className='global-container' style={{ padding: 0 }}>
           

                <Tabs style={{ marginTop: 10 }}>
                    <TabList style={{ paddingLeft: 0, marginLeft: 0, justifyContent: 'start' }} className="tab_class tab_class_fav">
                        <Tab className="dashboard_category">Under Process </Tab>
                        <Tab className="dashboard_category">Admitted </Tab>
                        <Tab className="dashboard_category">Rejected </Tab>
                        <Tab className="dashboard_category">Incomplete </Tab>
                    </TabList>
                    <TabPanel> 
                        <ApplicationStatus source="university" tab="under process" />
                    </TabPanel>
                    <TabPanel>
                        <ApplicationStatus source="university" tab="under process"/>
                    </TabPanel>
                    <TabPanel>
                        <ApplicationStatus source="university" tab="under process"/>
                    </TabPanel>
                    <TabPanel>
                        <ApplicationStatus source="university" tab="under process"/>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default UniverSityApplication


const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',

    },

    img: {
        display: 'flex',
        justifyContent: 'center'
    },

}));
