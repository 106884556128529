import { Autocomplete, Button, Chip, FormControl, Grid, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import { makeStyles } from "@mui/styles";
import Slider from '@mui/material/Slider';

export default function PrerequisiteFilter() {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            gre:[0, 340],
            gmat:[0, 800],
            sat:[0, 1600],
            toefl:[0, 120],
            pte:[0, 120],
            act:[0, 9],
            ielts:[0, 9],
            duolingo:[0, 9],
        },
        onSubmit: async (values) => {
            console.log(values)
        }
    })
    
    const valuetext = (value) => {
        return `${value}`
    }
    const [value, setValue] = React.useState(null);

    return (
        <form onSubmit={formik.handleSubmit} className={`${classes.cardBox} select-label-rmv`}>

                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> GRE :</Typography>
                        <Chip 
                                label={0}
                                sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                            />
                            <Chip 
                                label={340}
                                sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                            />
                        <Slider
                            value={formik.values.application_fee}
                            min={0}
                            step={1}
                            max={340}
                            name="gre"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> GMAT :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={800}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.tution_fee}
                            min={0}
                            step={1}
                            max={800}
                            name="gmat"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> SAT :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={1600}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={1600}
                            name="sat"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> TOEFL :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={120}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={120}
                            name="toefl"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> PTE :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={120}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "55px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={120}
                            name="pte"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> IELTS :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={9}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={9}
                            name="ielts"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> ACT :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={9}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={9}
                            name="act"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <Typography sx={{ mt:1, mb:1, minWidth: "100%" }}> DUOLINGO :</Typography>
                    <Chip 
                            label={0}
                            sx={{ bgcolor: '#fff',color: 'blue', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px",}}

                        />
                        <Chip 
                            label={9}
                            sx={{ bgcolor: '#fff',color: 'blue',position:'absolute', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)", width: "35px", right:0, bottom:46}}

                        />
                        <Slider
                            value={formik.values.commission_amt}
                            min={0}
                            step={1}
                            max={9}
                            name="duolingo"
                            // onChange={handleOptionDataChange}
                            onChange={formik.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            getAriaValueText={valuetext}
                        />
                    </FormControl>

                    <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '5px' }}>
                        <Button className={classes.resetBtn}>Reset</Button>
                        <Button
                            // onClick={()=>history.push('/SopSearchResult')}
                            className={classes.submitBtn}
                            type={'submit'}
                        >Filter</Button>
                    </Grid>
        </form>
    )
}
const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
}));
