import React from "react";

import Head from "./Head";
import SignInForm from "./Form";
import Grid from "@mui/material/Grid";

const SignIn = () => {
  return (
    <Grid sx={{ backgroundColor: "#fafafa" }}>
      <div style={{ position: "absolute", width: "90%", bottom: 50 }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 50 }}>
          <img src={`${process.env.PUBLIC_URL}/img/LoginLogo.png`} style={{height: "88px"}} alt="" />
        </div>
        <Head />
        <SignInForm />
      </div>
      {/* <Buttons /> */}
    </Grid>
  );
};
export default SignIn;
