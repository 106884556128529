import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'

import axios from 'axios';
import _ from 'lodash'

import Box from '@mui/material/Box';
import { fetchComoonPickerListAction } from '../Action';
import { getSessionId, getUserType, getUserId, SESSION_ID, USER_ID } from '../../Controller/localStorageHandler';
import { OPERATION, operationValue } from '../../Controller/Global';

import useAppContext from "../../useContext/userContext"

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    pt: 3,
    pb: 1,
    px: 0,
    overflow: 'scroll',
    height: "60%",
    pl: 3,
    pr: 3
};

export default function FilterModal(props) {
    // const classes = useStyles();
    const { open, handleClose, SubmitFilter, dropDownData, univerSityList } = props
    const {
        getRelatedProgramsPickList,related_programs_picklist,
        getCountryPickList,country_picklist,
        getSemesterPickList,semester_picklist,
        getYearPickList,year_picklist,
    } = useAppContext()
    const [selectedSemester, SetSelectedSemester] = React.useState([])
    const [selectedYear, SetSelectedYear] = React.useState([])
    const [selectedStatus, SetselectedStatus] = React.useState([])
    const [countryValue, setcountryValue] = React.useState([])


    const [universityList, setUniversityList] = React.useState([]);
    const [universityValue, setuniversityValue] = React.useState([]);

    const [reset, setreset] = React.useState(false);
    const [mentorList,setMentorList] = React.useState([]);
    const [selectedMentor,setSelectedMentor] = React.useState('');
    

 

    const statusList = ['Info/Doc Pending', 'Yet to Fill', 'Application Fee Due', 'Ready for Submission', 'Eligibility Checking', 'Portal Not Opened', 'Application Review', 'Conditional Admission', 'Admitted', 'I-20/Offer/CAS/COE', 'University Rejected', 'Deposit Due', 'Visa Yet To Apply', 'Visa Applied', 'Visa Approved', 'Visa Denied', 'Application Incomplete']; 

 


    const GetUniversityList = () => {
        if(univerSityList && univerSityList.length > 0){
            let uniArray = [];
            univerSityList.map((val,i)=>{
                // setUniversityList([...universityList,val?.university])
                if(val?.university !== null){
                    uniArray.push(val?.university)
                }
            }
            )
            setUniversityList(uniArray)
        }
        else{
        // const university = new FormData()
        // university.append(OPERATION, operationValue.FetchUniversity)
        // university.append(USER_ID, getUserId())
        // university.append(SESSION_ID, getSessionId())
        // university.append('search', 'UNIVER')
        // university.append('degreeType', 'Masters')
        // axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
        //     .then((response) => {
        //         setUniversityList(response.data.data)
        //     })
            // console.log('university');
        }
    }

    const GetMentorList = () =>{
        const data = new FormData();
        data.append('operation','get_mentor_list');
        data.append('user_type',getUserType());
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",data).then((res)=>{
            setMentorList(res?.data?.data)    
        // console.log(res?.data?.data);
        })
    }

   

    useEffect(() => {
   
        GetUniversityList()
 
        GetMentorList()

        getCountryPickList()
        getSemesterPickList()
        getYearPickList()
    }, [])

 




    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => handleClose()}
        >
            <Box sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl style={{ width: "48%", marginBottom: 20 }}>
                        <Autocomplete
                            name='Semester'
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            value={selectedSemester}
                            onChange={(e, val) => SetSelectedSemester(val)}
                            options={semester_picklist?.semester_picklist && semester_picklist?.semester_picklist?.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Semester" />}
                        />
                    </FormControl>
                    <FormControl style={{ width: "48%", marginBottom: 20 }}>
                        <Autocomplete
                            name='Year'
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            value={selectedYear}
                            onChange={(e, val) => SetSelectedYear(val)}
                            options={year_picklist?.year_picklist && year_picklist?.year_picklist?.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Year" />}
                        />
                    </FormControl>

                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl style={{ width: "60%", marginBottom: 20 }}>
                    <Autocomplete
                        name='Status'
                        id="free-solo-demo"
                        freeSolo
                        multiple
                        value={selectedStatus}
                        onChange={(e, val) => SetselectedStatus(val)}
                        options={statusList}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                </FormControl>
                <FormControl style={{ width: "35%", marginBottom: 20 }}>

                    <Autocomplete
                        name='Status'
                        id="free-solo-demo"
                        freeSolo
                        multiple
                        value={countryValue}
                        onChange={(e, val) => setcountryValue(val)}
                        options={country_picklist?.country_picklist && country_picklist?.country_picklist?.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                </FormControl>
                </div>

                <FormControl style={{ width: "100%", marginBottom: 20 }}>
                    <Autocomplete
                        name='University'
                        id="free-solo-demo"
                        freeSolo
                        // multiple
                        value={universityValue}
                        onChange={(e, val) => setuniversityValue(val)}
                        options={universityList && universityList.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="University" />}
                    />
                </FormControl>
                {
                   getUserType() === 'Associate' 
                   ? <FormControl style={{ width: "100%", marginBottom: 20 }}>
                    <InputLabel id="demo-simple-select-label">Mentor</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedMentor}
                        label="Mentor"
                        onChange={(e)=>setSelectedMentor(e.target.value)}
                    >
                        {
                            mentorList && mentorList.map((val)=>{
                               return <MenuItem value={val?.id}>{val?.first_name} {" "} {val?.last_name}</MenuItem>
                            })
                        }
                        
                    </Select>
{/* 
                   <Autocomplete
                       name='Mentor'
                       id="free-solo-demo"
                       freeSolo
                       // multiple
                       value={mentorList}
                       onChange={(e, val) => setuniversityValue(val)}
                       options={mentorList && mentorList.map((option) => option.first_name)}
                       renderInput={(params) => <TextField {...params} label="Mentor" />}
                   /> */}
               </FormControl> 
               : ''
                }

                <div style={{ width: "100%", marginBottom: 20, textAlign: "center" }}>
                    <Button
                        onClick={() => {
                            handleClose()
                            setreset(true)
                            props.SubmitFilter(selectedSemester, selectedYear, selectedStatus, universityValue, countryValue,selectedMentor, false)
                        }}
                        type="submit"
                        style={{
                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                            borderRadius: '30px',
                            height: '45px',
                            width: '80%',
                            backgroundColor: '#1B6CFC',
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            lineHeight: '20px',
                            marginLeft: '7px'
                        }}
                    >
                        Submit
                    </Button>
                    {reset &&
                        <Button
                            onClick={() => {
                                handleClose()
                                SetSelectedSemester([])
                                SetSelectedYear([])
                                SetselectedStatus([])
                                setuniversityValue([])
                                setcountryValue([])
                                setreset(false)
                                props.SubmitFilter(selectedSemester, selectedYear, selectedStatus, universityValue, countryValue,selectedMentor, true)
                            }}
                            type="Reset"
                            style={{
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                                borderRadius: '30px',
                                height: '45px',
                                width: '80%',
                                backgroundColor: '#1B6CFC',
                                color: '#ffffff',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                lineHeight: '20px',
                                marginLeft: '7px',
                                marginTop: "10px"
                            }}
                        >
                            Reset
                        </Button>
                    }
                </div>
            </Box>
        </Modal>
    )
}

