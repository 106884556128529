import React, { useEffect, useState } from 'react'
import Header from './Header'
import Card from './Card'
import { getSessionId, getUserId } from '../../../Controller/localStorageHandler'
import { fetchApplyIntenshipListAction } from '../../Action'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import PageTitle from '../../PageTitle'
import StudentList from '../../../components/Student/StudentList/StudentList';

const Candidate = () => {

    return (
        <div>
            <div className='global-container' style={{ padding: 0 }}>

                <Tabs style={{ marginTop: 10 }}>
                    <TabList style={{ paddingLeft: 0, marginLeft: 0, justifyContent: 'start' }} className="tab_class tab_class_fav">
                        <Tab className="dashboard_category">All </Tab>
                        <Tab className="dashboard_category">Shortlisted </Tab>
                        <Tab className="dashboard_category">Hired </Tab>
                        <Tab className="dashboard_category">Rejected </Tab>
                    </TabList>
                    <TabPanel>
                        <StudentList />
                    </TabPanel>
                    <TabPanel>
                        <StudentList />
                    </TabPanel>
                    <TabPanel>
                        <StudentList />
                    </TabPanel>
                    <TabPanel>
                        <StudentList />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default Candidate


const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',

    },

    img: {
        display: 'flex',
        justifyContent: 'center'
    },

}));
