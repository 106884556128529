import React, { useState } from 'react';

import AsyncSelect from 'react-select/async';
import {createFilter} from 'react-select';
import { fetchStudentListForDropDownAction } from '../Action';
// import { ColourOption, colourOptions } from '../data';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterModal from './FilterModal'
import './style.css'
import { getUser, getUserId } from '../../Controller/localStorageHandler';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import { useLocation } from "react-router-dom"

// const filterColors = (inputValue) => {
//     return colourOptions.filter((i) =>
//         i.label.toLowerCase().includes(inputValue.toLowerCase())
//     );
// };


let userData = getUser()
let UserId = getUserId()

const promiseOptions = (inputValue) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (inputValue.length > 4)
                setTimeout(async () => {
                    var data = new FormData();
                    data.append('search[term]', inputValue);
                    data.append('search[_type]', 'query');
                    data.append('userid', UserId);
                    data.append('profile[account_id]', userData?.account_id);
                    data.append('profile[email]', userData?.email);
                    data.append('profile[user_contact_type]', userData?.user_contact_type);
                    let Data = await fetchStudentListForDropDownAction(data)
                    let SelectData = []
                    // console.log(Data.length)
                    // console.log(Data)
                    Data.map((data) => {

                        let temp = {
                            "label": `${data.name}-${data.email}-${data.mobile}`,
                            "value": `${data.contactid}`
                        }
                        SelectData.push(temp)
                    })
                    resolve(SelectData)
                }, 300);
        }
        catch (error) {
            reject(error)
        }
    })
}

// new Promise < ColourOption[] > ((resolve) => {
//     setTimeout(() => {
//         resolve(filterColors(inputValue));
//     }, 1000);
// });

export const AsyncMulti = ({ setSearchSelectId, SubmitFilter, inputData, univerSityList, hideFilter }) => {
    const location = useLocation();
    const classes = useStyles();
    const [OpenFilter, setOpenFilter] = useState(false)
    return (
        <>
            <div className="CommonSearchForNameEmailandMobile">
                <AsyncSelect
                    filterOption={createFilter({ignoreAccents: false})}
                    onChange={({ value }) => { setSearchSelectId(value) }}
                    cacheOptions={false}
                    // defaultOptions
                    placeholder="Name / Email / Mobile."
                    loadOptions={promiseOptions}
                    className="DropDown"
                />
            </div>
            {location.pathname === "/application-status" &&
                <div style={{ position: "absolute", right: 0, top: 55 }}>
                    <Chip icon={<FilterAltOutlinedIcon />} label="Filter" sx={{ bgcolor: '#fff', float: 'right', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} onClick={() => setOpenFilter(true)} />
                    <Chip icon={<SortOutlinedIcon />} label="Sort" sx={{ bgcolor: '#fff', float: 'right', mx: 1, boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }}
                    // onClick={() => setopenSortlist(true)}
                    />
                </div>
            }
            <FilterModal open={OpenFilter} handleClose={() => setOpenFilter(false)} SubmitFilter={SubmitFilter} univerSityList={univerSityList}/>
        </>
    );
}
const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        padding: '10px',
        width: '100%',
        height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        boxShadow: '2px 2px 10px #DCDDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7
    },
    dropdownContainer: {
        margin: '10% 0 6% 0'
    },
    squareIcon: {
        opacity: 0.5
    },
    addIcon: {
        opacity: 0.9,
        color: '#1b6cfc',
        border: '1px solid',
        padding: '5px',
        borderRadius: '20px',
        borderColor: 'lightgray',
        boxShadow: '1px 2px 3px lightgray'
    },
    student: {
        fontWeight: 'bold',
        marginLeft: '3%',
        width: '300px'
    },
    addContact: {
        display: 'flex',
        alignItems: 'center'
    }
}))