export const SESSION_ID = "sessionid";
export const USER_ID = "userid"
export const USER = 'user'
export const USER_NAME = "username"
export const Contact_Id = "contactid"

export function logout() {
  clearUserData();
}

export function setFCM_Token(data){
  localStorage.setItem("FCM_TOKEN",data);
}

export function getFCM_Token(){
  return localStorage.getItem("FCM_TOKEN");
}

export function setSessionId(data) {
  localStorage.setItem(SESSION_ID, data);
}

export function setUserId(data) {
  localStorage.setItem(USER_ID, data);
}

export function getSessionId() {
  return localStorage.getItem(SESSION_ID);
}

export function getUserId() {
  return localStorage.getItem(USER_ID);
}


export function clearUserData() {
  localStorage.removeItem(SESSION_ID);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(USER);
  localStorage.removeItem('ProfileDetails')
  localStorage.removeItem('user_type')
  localStorage.clear()
}

export function isLoggedIn() {
  const accessToken = getSessionId();
  return !!accessToken;
}

export function setUser(data) {
  localStorage.setItem(USER, JSON.stringify(data))
}

export function getUser() {
  return JSON.parse(localStorage.getItem(USER));
}

export function setProfileDetails(data) {
  localStorage.setItem('ProfileDetails', JSON.stringify(data))
}

export function getProfileDetails() {
  return JSON.parse(localStorage.getItem('ProfileDetails'));
}

export function setUserType(data) {
  localStorage.setItem('user_type', data)
}
export function getUserType(){
  return localStorage.getItem('user_type');
}

// ------------- used in student list page ------------ //
export function setCookie_type(data){
  localStorage.setItem('current_type', JSON.stringify(data))
}

export function getCookie_type(){
  return JSON.parse(localStorage.getItem('current_type'));
}

export function setCookie_status(data){
  localStorage.setItem('current_status', JSON.stringify(data))
}

export function getCookie_status(){
  return JSON.parse(localStorage.getItem('current_status'));
}

export function setShowType(data){
  localStorage.setItem('currentShowType',JSON.stringify(data))
}

export function getShowType(){
  return JSON.parse(localStorage.getItem('currentShowType'))
}

// end

export function setCookie_for_popup(type,data){
  localStorage.setItem(type, JSON.stringify(data))
}

export function getCookie_for_popup(type){
  return JSON.parse(localStorage.getItem(type));
}



// ----------------- used in college details page ------------------ //
export function setCurrentPageVal(data){
  localStorage.setItem('collegeDetails', JSON.stringify(data))
}

export function getCurrentPageVal(){
  return JSON.parse(localStorage.getItem('collegeDetails'));
}


// ---------------------- used in status student list ----------------- //
export function setStatusStudents(data){
  localStorage.setItem('status_students', JSON.stringify(data))
}

export function getStatusStudents(){
  return JSON.parse(localStorage.getItem('status_students'));
}


// UniversitySearchResult page
export function setSearchData(data){
  localStorage.setItem('uni_search_data', JSON.stringify(data))
}

export function getSearchData(){
  return JSON.parse(localStorage.getItem('uni_search_data'));
}


// set search selected user 
export function setSearchSelectedUser(data){
  localStorage.setItem('set_search_selected_users', JSON.stringify(data))
}

export function getSearchSelectedUser(){
  return JSON.parse(localStorage.getItem('set_search_selected_users'))
}