import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import { Box } from '@mui/system';
import { Chip, Grid, LinearProgress, IconButton, Typography } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { Link } from 'react-router-dom';
import ProgramModal from './ProgramModal';
import FavouriteModal from '../University/FavouriteModal';
import { useHistory } from 'react-router-dom'
import { USER_ID, getUserId, SESSION_ID, getSessionId, getUser, setSearchData, getSearchData } from '../../Controller/localStorageHandler';
import { OPERATION, operationValue, getCurrencySymbol } from '../../Controller/Global';
import axios from 'axios';
import PopUpModal from './ApplyModal';
import BookMarkMessageModal from './BookMarkMessageModal';
import ResultCard from './ResultCard';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import SortModal from './SortModal';
import FilterModal from './FilterModal';
import DownloadModal from './DownloadModal';
import AddStudentList from './AddStudentList';
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from "moment"
import useAppContext from '../../useContext/userContext';


const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));



const SearchUniversityResult = (props) => {

    const { sessionInvalid, user, getUserContext, getFavouriteUniversities, favourite_universities, getParteneredUniversities, partenered_universities, getMatchingUniversities, matching_universities, getApplicationStatus, application_status_student } = useAppContext()
    let stateData = [];
    if(props?.location?.state){
        stateData = props?.location?.state
        setSearchData(stateData)
    }
    else{
        stateData = getSearchData()
    }

    // console.log(stateData)

    let Data = ["All", "Dream", "Moderate", "Reach", "Safe", "Top Rank", "Low Fee", "Recommend", "Application Fee Waiver", "STEM", "Placement", "Co-Op", "Internship", "15 Years Accepted", "Duolingo Accepted", "Backlogs Accepted", "SAT/ACT Wavier", "GRE Wavier", "GMAT Wavier", "IELTS / English Wavier","75% Fee Discount","50% Fee Discount"]

    const initialValues = {
        'All':true,
        'Dream':false,
        'Moderate':false,
        'Reach':false,
        'Safe':false,
        'Top Rank':false,
        'Low Fee':false,
        'Recommend':false,
        'Application Fee Waiver':false,
        'STEM':false,
        'Placement':false,
        'Co-Op':false,
        'Internship':false,
        '15 Years Accepted':false,
        'Duolingo Accepted':false,
        'Backlogs Accepted':false,
        'SAT/ACT Wavier':false,
        'GRE Wavier':false,
        'GMAT Wavier':false,
        'IELTS / English Wavier':false,
         'Possible Options':false,
         '75% Fee Discount':false,
         '50% Fee Discount':false,
    }

    const [selected, setSelected] = React.useState(initialValues);

    const classes = useStyles();
    const history = useHistory()
    const [open, setOpenProgram] = React.useState(false)
    const [openFav, setOpenFav] = React.useState(false)
    const [openMsg, setOpenMsg] = React.useState(false)
    const [bookmarkMsg, setBookmarkMsg] = React.useState('')
    const [symbol, setSymbol] = React.useState('')
    const [bookmarkId, setBookmarkId] = React.useState('')
    const [openApply, setOpenApply] = React.useState(false);
    const [applyData, setApplyData] = React.useState('')
    const [searchList, setSearchList] = React.useState(stateData?.data?.data || [])
    const [loading, setLoading] = React.useState(false)
    const [page, setPage] = React.useState(1)
    const [openSortlist, setopenSortlist] = React.useState(false)
    const [type, setType] = React.useState('')
    const [openFilter, setOpenFilter] = React.useState(false)
    const [openDownload, setOpenDownload] = React.useState(false)
    const [SopData, setSopData] = React.useState('')
    const [AddStudentOpen, setAddStudentOpen] = React.useState(false)
    const [SelectedStudentList, SetSelectedStudentList] = React.useState([])
    const [source,setSource] = React.useState('');
    const [isCollapsed,setCollapsed] = React.useState(true)
    const [isFilter,setIsFilter] = React.useState(false)

    const [filterValues, setFilterValues] = useState([]); 
    const [isBookMark, setIsBookMark] = React.useState(false);

    

    

    // console.log("stateData", stateData)
    

    const handleProgramList = (data) => {
        // console.log(data)
        // let newList = stateData.data.data.filter(o => o.program === data)
        setLoading(true)
        setOpenProgram(false)
        const page = JSON.parse(stateData.page)
        // console.log(page)
        const formData = new FormData()
        for (let val in page) {
            formData.append(val, page[val])
        }
        formData.append('fprogram', data)
        formData.append('addonConditionType', JSON.stringify(selected))
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            // console.log(response)
            if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                setSearchList(response)
                setOpenProgram(false)
                setLoading(false)

            } else {
                alert(response.data.msg || response.data.message)
            }
        })
        // setSearchList(newList)

    }

    const setBookmarkByEmployee = () =>{
        // console.log(bookmarkId)
    }

    const handleConfirm = async (value) => {
        // console.log(value)
        const bookmark = new FormData()
        bookmark.append(USER_ID,  getUserId())
        bookmark.append(SESSION_ID, getSessionId())
        bookmark.append(OPERATION, operationValue.Bookmark)
        bookmark.append('universitiesid', bookmarkId)

        
        bookmark.append('btype', 'University')
       
        bookmark.append('studentid', getUserId())
        if (value === 'add') {
            bookmark.append('bookmarkstatus', 1)

        } else {
            bookmark.append('bookmarkstatus', 0)
        }
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", bookmark)
            .then(response => {
                // console.log(response)
                if (response.data.status === 'success') {
                    setOpenFav(false)
                    setOpenMsg(true)
                    setBookmarkMsg(value === 'add' ? 'Bookmark successfully added' : 'Bookmark successfully removed')
                    // true for force refresh
                    getFavouriteUniversities(`All`, `Universities`, `${JSON.stringify(getUser())}`, true)

                }
            })
    }

    const screenName = (v) => {
        if (v === 'profileEvaluationDetails') {
            return 'Universities'
        } else {
            return v
        }
    }

    const loadMore = (p) => {
        setLoading(true)
        let page 
        const formData = new FormData()
        if(stateData?.page){
             page = JSON.parse(stateData?.page)
        }
        // console.log(page)
        for (let val in page) {
            if (val === 'page') {
                formData.append(val, p)
            }
            else {
                formData.append(val, page[val])
            }
        }
        formData.append('addonConditionType', JSON.stringify(selected))
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
                // console.log(response)
            if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                setSearchList(response)
                setPage(p)
                setLoading(false)
            } else {
                alert(response.data.msg || response.data.message)
            }
        })
    }

    const handleSortList = (sortData,multiType) => {
        // console.log(sortData)
        setLoading(true)
        const page = JSON.parse(stateData?.page)
        const formData = new FormData()
        for (let val in page) {
            if (type === 'Possible Options' || type === 'profileEvaluationDetails') {
                if (val === 'addonConditionType') {
                    formData.append('addonConditionType', sortData)
                } else {
                    formData.append(val, page[val])
                }
            } else {
                formData.append(val, page[val])
            }
        }
        // if (sortData.label !== sortData.value) {
        //     formData.append('sort_name', sortData.label)
        // }
        if (type !== 'Possible Options' && type !== 'profileEvaluationDetails') {
            formData.append('addonConditionType', sortData)
        }
        else if(multiType === 'multiFilter'){
            formData.append('addonConditionType', sortData)
        }
         else {
            formData.append('Sort', sortData)
        }

        setopenSortlist(false)
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            // console.log(response)
            if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                setSearchList(response)
                setLoading(false)

            } else {
                alert(response.data.msg || response.data.message)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleFilterList = (values) =>{
        // console.log(values)
        setFilterValues(values)
        setLoading(true)
        const page = JSON.parse(stateData?.page)
        const formData = new FormData()
        for (let val in page) {  
            formData.append(val, page[val])
        }
        for (let val in values){
            formData.append(val, values[val])
        }
        // if(program !== ''){
        //     formData.append('program', program)
        // }
        // if(country !== ''){
        //     formData.append('country', country)
        // }
        // if (sortData.label !== sortData.value) {
        //     formData.append('sort_name', sortData.label)
        // }


        setOpenFilter(false)
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            // console.log(response)
            if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                setSearchList(response)
                setLoading(false)

            } else {
                alert(response.data.msg || response.data.message)
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        // console.log(stateData.screen, 'screen')
        if (stateData.screen === 'Scholarship') {
            setType('Scholarship')
        } else if (stateData.screen === 'Possible Options') {
            setType('Possible Options')
        } else if (stateData.screen === 'profileEvaluationDetails') {
            setType('profileEvaluationDetails')
        } else {
            setType('University')
        }
    }, [])

    useEffect(() => {
        // console.log(stateData?.screen, 'screen')
        if (props.location?.state?.screen === 'Scholarship') {
            setType('Scholarship')
        } else if (stateData?.screen === 'Possible Options') {
            setType('Possible Options')
        } else if (stateData?.screen === 'profileEvaluationDetails') {
            setType('profileEvaluationDetails')
        } else {
            setType('University')
        }
        setSearchList(stateData)
    }, [props])

    const selectedFilter = (data,stat) =>{
        setIsFilter(true)
        setSelected({...selected, [data]: stat})
    }

    useEffect(()=>{
        if(isFilter){
            handleSortList(JSON.stringify(selected),'multiFilter')
        }
    },[selected])

    const getString = () => {
   
        let TempData = `Dear Student %0D%0A%0D%0AGreetings from *BRAINSTORM CONSULTING *%0D%0A%0D%0APlease find the *${searchList?.data?.TotalRecords}* Universities 🏫  based on your Search in our App. %0D%0A%0D%0A Please *confirm and Upload Documents* for processing your application.%0D%0A%0D%0AExplore 🏫 *60000+* Programs and Universities, 10000+ Scholarship, 5000+ SOP and LOR Samples. 
        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
        %0D%0A iOS: https://apple.co/33ujR4Q
        %0D%0A ${GetUniverCityProgramStatusRemarData()}%0D%0A%0D%0A 

      
        %0D%0A%0D%0A*BRAINSTORM CONSULTING*
        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
        %0D%0A iOS: https://apple.co/33ujR4Q`

        return `https://api.whatsapp.com/send?text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")} `
    }
    const GetUniverCityProgramStatusRemarData = () => {
        let String = ''
        if(searchList?.data?.data?.length > 0){
            searchList?.data?.data?.slice(0, 30)?.map((val,index) => {
        // _.map(data, (val, index) => {
            let scholarship_name = (val.scholarship_name ? val.scholarship_name : val.university).replace("&", " AND ");
            // let related_programs = ((val.program ? val.program : val.related_programs).split("|##|").join(",")).replace("&", " AND ");
            let related_programs = (val.program).replace("&", " AND ");
            let funding_type = (val.funding_type ? val.funding_type : val.semester).replace("&", " AND ");
            let success_rate = (val?.success_rate ? val?.success_rate : val?.acceptance_rate).replace("&", " AND ");
            let scho_amt = Math.round(val?.scho_amt ? val?.scho_amt : val?.international_tuition_fee_yr)
            let Date = val.app_end_date ? val.app_end_date : val.inter_adm_deadline
            let application_fee = val?.app_fee_waiver && val?.app_fee_waiver === '1' ? 'waiver' : `💵 ${getCurrencySymbol(val.country)} ${val?.international_app_fee}`
            String = String + `%0D%0A%0D%0A ${index + 1}).*${scholarship_name}*,%0D%0A ${related_programs}%0D%0A*${funding_type}* *Deadline*⏰ ${Date ? moment(Date).format('ll') : ""}%0D%0A*Acceptance*: ${success_rate}%%0D%0A*Tuition Fee*:💵 ${getCurrencySymbol(val.country)}${scho_amt}/yr %0D%0A*Application Fee*:${application_fee} %0D%0A*Apply Now*:https%3A%2F%2Fapp.bscglobaledu.com%2F%23%2FCollegeDetails%2F${val?.universitiesid}`
        })
        if(searchList?.data?.data?.length > 30){
            String = String + `%0D%0A%0D%0AClick here for all Universities: https://bit.ly/3A2yFFA`
        }
        return String
        }
        else{
            return
        }

    }


    let programValue = history?.location?.state?.programValue || null
    let countryValue = history?.location?.state?.countryValue || null
    let tabName = history?.location?.state?.tabName || null
    const TotalRecors = stateData?.data?.TotalRecords || stateData?.TotalRecords || 0

    return (
        <Box className={classes.root} sx={{ bgcolor: '#fafafa' }}>
            <Box sx={{ marginLeft: 0, marginRight: 0, borderRadius: 2 }}>
                <Grid container spacing={1}>
                    
                        <Grid item xs={11} sx={{ mt: 1, fontWeight: 'bold' }}>
                        <Chip label={`${screenName(stateData?.screen || stateData?.screenName)} Result` || "Universities"} variant="filled" color="primary"  />
                            {/* {`${screenName(stateData?.screen || stateData?.screenName)} Result ` || "Universities"} */}
                            {/* <span style={{ color: '#1B6CFC' }}>{searchList?.data?.TotalRecords || stateData?.data?.TotalRecords}</span> */}
                            <Chip label={searchList?.data?.TotalRecords || stateData?.data?.TotalRecords} color="error"/>
                        </Grid>
                    <Grid item xs={1} sx={{ textAlign: 'end' }}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <a
                               href={getString()} target="_blank"
                                rel="noreferrer"
                            >
                                <ShareOutlinedIcon />
                            </a>
                        </IconButton>
                    </Grid>

                </Grid>
                <hr />


            </Box>

            <Box sx={{
                marginLeft: 0,
                marginRight: 0,
                borderRadius: 2,
                marginTop: 3,
                display: "inline-block", width: "100%"
            }}>
                {stateData?.data?.RelatedPrograms ? <Chip label={`Related Programs : ${stateData?.data?.RelatedPrograms?.length}`} color="success" sx={{  boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} onClick={() => setOpenProgram(true)} /> : ''}
                <Chip icon={<FilterAltOutlinedIcon />} label="Filter" sx={{ bgcolor: '#fff', float: 'right', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} onClick={() => setOpenFilter(true)} />
                <Chip avatar={<img alt="Natacha" src={`${process.env.PUBLIC_URL}/img/sort.png`} />} label="Sort" sx={{ bgcolor: '#fff', float: 'right', mx: 1, boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} onClick={() => setopenSortlist(true)} />
            </Box>

           {/* checkbox test */} 
                        <div>


                        <FormControl component="fieldset" style={{ width: "100%",display:'block',width:'100%', backgroundColor: '#fff', padding: '6px 12px 12px 6px', borderRadius: '8px', boxShadow: '0 10px 20px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%)', paddingBottom: '12px',height:`${isCollapsed === false ? '42px':''}`, position:'relative', overflowY:'hidden' }}>
                        {isCollapsed ?<div style={{float:'right', marginTop : '3px', backgroundColor: '#ebebeb', borderRadius: '5px'}} > <ExpandLessIcon onClick={()=>setCollapsed(v=>!v)} sx={{}} /></div> : <div style={{float:'right', marginTop : '3px', backgroundColor: '#ebebeb', borderRadius: '5px'}}><ExpandMoreIcon onClick={()=>setCollapsed(v=>!v)} sx={{}}/></div> }

                        {Data?.map((d,i) => {
                            if(selected[d] === true){
                            return <Chip key={d} label={`${d} ✖️`} onClick={()=>selectedFilter(d,selected[d] === true ? false : true)} sx={{m:1,fontSize:'13px'}} style={{cursor:'pointer', margin:'6px 0px 0px 6px', borderRadius: '4px'}} size="small"  variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                            }
                        })}
                        {Data?.map((d,i) => {
                            if(selected[d] === false){
                            return <Chip key={d} label={d} onClick={()=>selectedFilter(d,selected[d] === true ? false : true)} sx={{m:1,fontSize:'13px'}} style={{cursor:'pointer', margin:'6px 0px 0px 6px', borderRadius: '4px'}} size="small"  variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                            }
                        })}
                        </FormControl>
                        </div>
                        {/* checkbox end */}

            <Grid sx={{
                marginLeft: 0,
                marginRight: 0,
                borderRadius: 2,
                marginTop: 3,
            }}
            container spacing={2}
            >
                {TotalRecors === 0 ?
                    <h6 style={{ fontWeight: 'bold' }}>No Data</h6>
                    :
                    loading ?
                        <div style={{ textAlign: 'center',marginRight:'auto',marginLeft:'auto', marginTop: '20%' }}>

                            <CircularProgress />
                     
                        </div>
                        :
                        searchList?.data?.TotalRecords > 0 && searchList?.data?.data?.map((val,i) => {


                            return (
                                <Grid sx={{
                                    bgcolor: '#fff',
                                    borderRadius: 3,
                                    pt: 2,
                                    pb: 1,
                                    mb: 2,
                                    pl:1,
                                    pr:1,
                                    // paddingBottom: "30px",
                                    height: 'auto', boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)"
                                }}
                                item
                                md={4}
                                xs={12}
                                key={i}
                                // onClick={() => history.push(`/CollegeDetails/`, val)}
                                >
                                    <ResultCard
                                        val={val}
                                        setBookmarkId={setBookmarkId}
                                        setApplyData={stateData?.data?.RelatedPrograms ? setApplyData : null}
                                        setOpenApply={stateData?.data?.RelatedPrograms ? setOpenApply : null}
                                        setOpenFav={setOpenFav}
                                        setOpenDownload={setOpenDownload}
                                        setSopData={setSopData}
                                        setAddStudentOpen={setAddStudentOpen}
                                        setSource={setSource}
                                    />
                                </Grid>
                            )
                        })
                }

            </Grid>
            {!loading && <Box display="flex"
                                justifyContent="center"
                                alignItems="center"
                                style={{width:'100%'}}>
                <Pagination
                    defaultPage={1}
                    page={page}
                    count={Math.ceil((searchList?.data?.TotalRecords || stateData?.data?.TotalRecords) / 50)}
                    color="primary"
                    onChange={(e, p) => loadMore(p)}
                />
            </Box>}

            <ProgramModal
                open={open}
                handleClose={() => setOpenProgram(false)}
                data={stateData.data.RelatedPrograms ? stateData.data.RelatedPrograms : 'No data'}
                handleProgramList={(program) => handleProgramList(program)}
            />
            <FavouriteModal open={openFav} isBookMark={isBookMark} setIsBookMark={setIsBookMark} handleClose={() => setOpenFav(false)} confirm={(value) => handleConfirm(value)}  />
            <PopUpModal studentData={SelectedStudentList} selectedUniverSity={bookmarkId} open={openApply} handleClose={() => setOpenApply(false)} data={applyData} />
            <BookMarkMessageModal open={openMsg} handleClose={() => setOpenMsg(false)} data={bookmarkMsg} />
            {openSortlist && <SortModal
                open={openSortlist}
                handleSortList={(value) => handleSortList(value)}
                handleClose={() => setopenSortlist(false)}
                type={type}
            />}
            {openFilter && <FilterModal
                open={openFilter}
                // handleSortList={(value) => handleSortList(value)}
                handleFilterList = {handleFilterList}
                handleClose={() => setOpenFilter(false)}
                filterValues={filterValues}
            />}
            {<DownloadModal open={openDownload} handleClose={() => setOpenDownload(false)} SopData={SopData} />}
            {/* <PopUpModal SelectedStudentList={SelectedStudentList} open={openApply} handleClose={() => setOpenApply(false)} data={applyData} /> */}
            <AddStudentList SelectedStudentList={SelectedStudentList} SetSelectedStudentList={SetSelectedStudentList} handleOpenAndClose={() => setOpenApply(!openApply)} AddStudentOpen={AddStudentOpen} handleCloseAddStudentModal={() => setAddStudentOpen(false)} source={source} />
        </Box>
    )
}

export default SearchUniversityResult;
