import React, { useEffect, useState, useRef } from "react";
import { Button, TextField, Typography, Box, Paper } from "@mui/material";
import axios from "axios";
import { withRouter, useHistory, Link, useParams } from 'react-router-dom'
import './custom.css'
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { getUserType, setFCM_Token, getUser } from "../../../Controller/localStorageHandler";
import { CommonLogin } from "../../Forms/CommonLogin";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import NorthWestOutlinedIcon from '@mui/icons-material/NorthWestOutlined';
import _ from 'lodash'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CheckIcon from '@mui/icons-material/Check';
import FlightIcon from '@mui/icons-material/Flight';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { TypeAnimation } from 'react-type-animation';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import CountUp from 'react-countup';

let searchData = require('./unisearchdata.json');



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const UniversitySearch = () => {
    // console.log(searchData.country)
    let textInput = useRef(null);
    const history = useHistory();
    const { token } = useParams()

    const [searchKey, setSearchKey] = useState('');
    const [open, setOpen] = useState(false);
    const [searchErr, setSearchErr] = useState(false);
    const [openLogin, setOpenLogin] = useState(false)

    const [searchOpen, setSearchOpen] = useState(false);

    const [suggetion, setSuggetion] = useState([]);

    // flags to identify selected key
    const [programFlag, setProgramFlag] = useState('')
    const [countryFlag, setCountryFlag] = useState('')
    const [addonFlag, setAddonflag] = useState('')
    const [semesterFlag, setSemesterFlag] = useState('')
    const [uniFlag, setUniFlag] = useState('')

    const [countData, setCountData] = useState([])

    const inputRef = React.useRef < HTMLInputElement > (null);

    const handleSearchClickOpen = () => {
        setSearchOpen(true);
    };

    const handleSearchClose = () => {
        setSearchOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSearchErr(false)
    };
    const onChangeSearchInput = (key) => {
        if (key === '') {
            setProgramFlag('')
            setCountryFlag('')
            setAddonflag('')
            setSemesterFlag('')
            setUniFlag('')
        }
        // let ResultData = _.filter((searchData.country), (d) =>  { return key.toLowerCase() === d.toLowerCase() })
        let ResultData
        if (programFlag || semesterFlag || uniFlag || addonFlag || countryFlag) {
            if (programFlag) {
                let rmProgram = key.replace(programFlag, '').trim()
                const programData = searchData.semester.filter(o => o.toLowerCase().includes(rmProgram.toLowerCase()))
                ResultData = programData

            }
            if (semesterFlag) {
                let rmSemester = key.replace(semesterFlag, '').trim()
                const countryData = searchData.programs.filter(o => o.toLowerCase().includes(rmSemester.toLowerCase()))
                ResultData = countryData

            }
            if (uniFlag) {
                let rmUni = key.replace(uniFlag, '').trim()
                const programData = searchData.programs.filter(o => o.toLowerCase().includes(rmUni.toLowerCase()))
                ResultData = programData

            }
            if (addonFlag) {
                let rmAddon = key.replace(addonFlag, '').trim()
                const programData = searchData.programs.filter(o => o.toLowerCase().includes(rmAddon.toLowerCase()))
                ResultData = programData

            }
            if (countryFlag) {
                let rmCountry = key.replace(countryFlag, '').trim()
                const programData = searchData.programs.filter(o => o.toLowerCase().includes(rmCountry.toLowerCase()))
                ResultData = programData

            }
            if (programFlag && semesterFlag) {
                let rmSemester = key.replace(programFlag, '').replace(semesterFlag, '').trim()
                // console.log(rmSemester)
                const countryData = searchData.country.filter(o => o.toLowerCase().includes(rmSemester.toLowerCase()))
                ResultData = countryData

            }
            if (programFlag && uniFlag) {

                let rmProUni = key.replace(programFlag, '').replace(uniFlag, '').trim()
                const semData = searchData.semester.filter(o => o.toLowerCase().includes(rmProUni.toLowerCase()))
                ResultData = semData

            }

            if (addonFlag && programFlag) {
                let rmProAddon = key.replace(addonFlag, '').replace(programFlag, '').trim()
                const semData = searchData.semester.filter(o => o.toLowerCase().includes(rmProAddon.toLowerCase()))
                ResultData = semData
            }
        }
        else {
            const programData = searchData.programs.filter(o => o.toLowerCase().includes(key.toLowerCase()))
            const uniData = searchData.university.filter(o => o.toLowerCase().includes(key.toLowerCase()))
            const countryData = searchData.country.filter(o => o.toLowerCase().includes(key.toLowerCase()))
            const addonCondition = searchData.addonCondition.filter(o => o.toLowerCase().includes(key.toLowerCase()))
            const semester = searchData.semester.filter(o => o.toLowerCase().includes(key.toLowerCase()))
            // console.log(uniData)
            ResultData = programData.concat(uniData.concat(countryData.concat(addonCondition.concat(semester))))
        }

        setSuggetion(ResultData)
        setSearchKey(key)
    }

    const appendKey = (val, type) => {
        let keyToShow = ''

        if (programFlag) {
            keyToShow = keyToShow + ' ' + programFlag
        }
        if (countryFlag) {
            keyToShow = keyToShow + ' ' + countryFlag
        }
        if (addonFlag) {
            keyToShow = keyToShow + ' ' + addonFlag
        }
        if (semesterFlag) {
            keyToShow = keyToShow + ' ' + semesterFlag
        }
        if (uniFlag) {
            keyToShow = keyToShow + ' ' + uniFlag
        }

        if (_.includes(searchData.programs, val)) {
            setProgramFlag(val)
            keyToShow = keyToShow + ' ' + val
        }
        else if (_.includes(searchData.university, val)) {
            setUniFlag(val)
            keyToShow = keyToShow + ' ' + val
        }
        else if (_.includes(searchData.country, val)) {
            setCountryFlag(val)
            keyToShow = keyToShow + ' ' + val
        }
        else if (_.includes(searchData.addonCondition, val)) {
            setAddonflag(val)
            keyToShow = keyToShow + ' ' + val
        }
        else if (_.includes(searchData.semester, val)) {
            setSemesterFlag(val)
            keyToShow = keyToShow + ' ' + val
        }
        else {

        }

        setSearchKey(keyToShow)
        // onChangeSearchInput(keyToShow)
        setSuggetion([])
        textInput.current.focus()
        var e = new Event('keydown');
        e.which = e.keyCode = 32;
        e.code = "Space"
        textInput.current.dispatchEvent(e)
        if (type === 'search') {
            submitSearch(keyToShow)
        }
    }

    useEffect(() => {
        landing_page_values()
        if (token) {
            setFCM_Token(token)
        }
    }, [])

    const dashRedirect = () => {
        const user_type = getUserType();
        if (user_type === "Accommodation") {
            history.push('/accommodationDashboard')
        }
        else if (user_type === "University") {
            history.push('/universityDashboard')
        }
        else if (user_type === "Partner") {
            history.push('/educationDashboard')
        }
        else if (user_type === "Internship/Job") {
            history.push('/internshipDashboard')
        }
        else if (user_type === "Employee" || user_type === "Associate" || user_type === "Associate Admin") {
            history.push('/associates')
        }
        else if (user_type === 'Student') {
            history.push('/dashboard')
        }
        else {

        }
    }
    const submitSearch = async (keyToShow) => {

        setSearchOpen(false)
        handleClickOpen()
        if (searchKey === '') {
            setSearchErr(true);
            return
        }

        // console.log(searchKey)
        const formData = new FormData()
        formData.append('operation', 'search_university')
        formData.append('search_key', keyToShow || searchKey)
        formData.append('page', 1)
        formData.append('secret_key', '5s5q1oBx1h9Wz7mPLuIg8f3uikY2H71N')
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            // console.log(response)
            if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                // history.push('/UniversitySearchResult', response.data)

                // if (!move) {
                history.push({
                    pathname: '/SearchUniversityResult',
                    state: {
                        data: response.data, screen: keyToShow || searchKey,
                        page: JSON.stringify(Object.fromEntries(formData)),

                    }
                })

            } else {
                setSearchErr(true)
            }
        }).finally(() => {

        })
    }

    const landing_page_values = async () => {
        const formData = new FormData()
        formData.append('operation', 'landing_page_values')
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response => {
            //    console.log(response)
            setCountData(response?.data?.count)
        }).finally(() => {

        })
    }
    return (
        <>
            <Box sx={{ textAlign: 'center' }}>
                <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    {/* <Button variant="outlined" size="small" onClick={() => history.push({
                        pathname: '/login',
                    })}>Login</Button>
                    &nbsp; */}
                    {
                        getUser()
                            ?
                            <Button variant="contained" size="small" onClick={() => dashRedirect()}>DashBoard</Button>
                            :
                            <Button variant="contained" size="small" onClick={() => setOpenLogin(true)}>Login / Signup</Button>
                    }

                </div>
                <TypeAnimation
                    sequence={[
                        'ISHVI: UNIVERSITY SEARCH', // Types 'One'
                        1000, // Waits 1s
                        'ISHVI: PROGRAM SEARCH', // Deletes 'One' and types 'Two'
                        1000, // Waits 2s
                        'ISHVI: SOP SEARCH', // Types 'Three' without deleting 'Two'
                        1000,
                        'ISHVI: SCHOLARSHIP SEARCH', // Types 'Three' without deleting 'Two'
                        1000,
                        'ISHVI: PROFILE EVALUATION',
                        1000,
                        'ISHVI: APPLICATION STATUS',
                        1000,
                        () => {
                            // console.log('Sequence completed'); // Place optional callbacks anywhere in the array
                        }
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{ fontSize: '15px', marginTop: '200px', display: 'inline-block', fontWeight: 'bold' }}
                />
                <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '80px' }}>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'inline-flex', alignItems: 'center', marginRight:'5%', marginLeft:'5%', width:'90%' }}
                    >

                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="University name/ Program name/ Country"
                            inputProps={{ 'aria-label': 'University name/ Program name/ Country' }}
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.target.value)}
                            onClick={handleSearchClickOpen}
                            autoFocus={true}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => submitSearch()}>
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
                    </Paper>
                </div>



                {
                    countData
                        ?
                        <Box sx={{ borderRadius: '10px', display: 'inline', width: '100%', margin: 'auto', textAlign: 'center', marginTop: '50px' }}>

                            <Grid container spacing={2}>

                                <Grid item xs={6} sm={3}>
                                    <Typography sx={{ fontSize: '14px', display: 'inline-block', width: '100%', borderRight: '1px solid #fff' }}

                                    >
                                        <span style={{ color: '#7e8185', fontSize: '30px', fontWeight: 'bold' }}><CountUp end={countData?.total_contact} style={{ fontWeight: 'bold' }} duration={5} /></span>
                                        <br />

                                        Downloads </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography sx={{ fontSize: '14px', display: 'inline-block', width: '100%', borderRight: '1px solid #fff' }}

                                    >
                                        <span style={{ color: '#7e8185', fontSize: '30px', fontWeight: 'bold' }}><CountUp end={countData?.acs_registered} style={{ fontWeight: 'bold' }} duration={5} /></span>
                                        <br />

                                        Registered </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography sx={{ fontSize: '14px', display: 'inline-block', width: '100%', borderRight: '1px solid #fff' }}

                                    >
                                        <span style={{ color: '#7e8185', fontSize: '30px', fontWeight: 'bold' }}><CountUp end={countData?.projectTasks} style={{ fontWeight: 'bold' }} duration={5} /></span>
                                        <br />
                                        Applications </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography sx={{ fontSize: '14px', display: 'inline-block', width: '100%' }}

                                    >
                                        <span style={{ color: '#7e8185', fontSize: '30px', fontWeight: 'bold' }}><CountUp end={countData?.admitted} style={{ fontWeight: 'bold' }} duration={5} /></span>
                                        <br />
                                        Admitted </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                        :
                        ''
                }

            </Box>

            

            {/* search modal */}
            {
                searchOpen
                    ?
                    <Dialog
                        fullScreen
                        open={searchOpen}
                        onClose={handleSearchClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleSearchClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <TextField
                                    sx={{ bgcolor: '#fff', marginBottom: '5px', marginTop: '5px' }}
                                    label="University name/ Program name/ Country"
                                    variant="filled"
                                    fullWidth
                                    value={searchKey}
                                    onChange={(e) => onChangeSearchInput(e.target.value)}
                                    autoFocus
                                    inputRef={textInput}
                                />
                                {/* <input style={{marginTop:'10px',marginBottom:'10px'}} inputRef={inputRef}/> */}
                                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Sound
                        </Typography> */}

                                <Button color="inherit" onClick={() => submitSearch()}>
                                    Search
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <List>
                            {
                                suggetion
                                    ?
                                    suggetion?.slice(0, 10)?.map?.((val) => {
                                        return <><ListItem
                                            secondaryAction={
                                                <IconButton onClick={() => appendKey(val)} edge="end" aria-label="Append">
                                                    <NorthWestOutlinedIcon />
                                                </IconButton>
                                            }>
                                            <ListItemText onClick={() => appendKey(val, 'search')} primary={`${programFlag || semesterFlag || uniFlag || addonFlag || countryFlag ? '...' + val : val}`} />
                                        </ListItem>
                                            <Divider sx={{ color: '#c2bebe' }} />
                                        </>
                                    })
                                    :
                                    ''
                            }


                            {/* <ListItem button>
                        <ListItemText
                            primary="Default notification ringtone"
                        />
                    </ListItem> */}
                        </List>
                    </Dialog>
                    :
                    ""
            }

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {
                    searchErr ?
                        <>
                            <DialogTitle>{`Opps!!!`}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    <h4>Try Searching</h4>

                                    <p>Master in Computer Science in USA</p>
                                    <p>MBA in UK</p>
                                    <p>STEM MBA </p>
                                    <p>STEM CS in USA</p>
                                    <p>Application Fee Waiver in USA for CS</p>
                                    <p>GRE Waiver in USA</p>
                                    <p>Low Tuition Fee in UK</p>
                                    <p>Backlogs Accepting in USA</p>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Okay</Button>
                            </DialogActions>
                        </>
                        :
                        <div className={`lds-hourglass`}></div>
                }

            </Dialog>
            <CommonLogin openLogin={openLogin} handleClose={setOpenLogin} />
        </>
    )
}

export default UniversitySearch;