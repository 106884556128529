import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import "./commonType.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Internship = ({ HandlePrev, HandleNext }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample1" style={{ minWidth: "100% !important" }}>
      <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
        <span style={{ color: "white" }} onClick={() => HandlePrev()}>     Prev</span>
        <span style={{ marginBottom: 0, color: "white" }}>  Internship start date</span>
        <span style={{ color: "white" }} onClick={() => HandleNext()}> Next</span>
      </div>

      <div className="collapse_check_item">
        <FormControl component="fieldset" className="radio_font">
          <RadioGroup
            aria-label="gender"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <div className="d-flex justify-content-start align-items-center">
              <input
                className="radio_style"
                type="radio"
                name="selector"
                id=""
              />
              <label className="details_tag_size" htmlFor="">
                Immediately (within next 30 days)
              </label>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <input
                className="radio_style"
                type="radio"
                name="selector"
                id=""
              />
              <label className="details_tag_size" htmlFor="">
                Later
              </label>
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default Internship;
