import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

export default function UploadDocumentModal(props) {
    const { handleClose, open, save } = props
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Upload Document
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                <label htmlFor="contained-button-file">
                    <Input onChange={({ target }) => {
                        props.ActiveUploadingData.file = target.files[0]
                        props?.SetUploadingFileData(props.ActiveUploadingData)
                    }} accept="image/*" id="contained-button-file"
                        //  multiple
                        type="file" />
                    {/* <Button variant="contained" component="span">
                        Upload
                    </Button> */}
                </label>
            </DialogContent>
            <DialogActions>
                <Button disabled={props?.ActiveUploadingData?.file ? false : true} autoFocus onClick={()=>save(props.ActiveUploadingData.id)} style={{ background: props?.ActiveUploadingData?.file ? "#1b6cfc" : "#666666", color: "white" }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
