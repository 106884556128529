import React, { useEffect } from "react";
import PageTitle from "../../../PageTitle";
import { makeStyles, propsToClassKey } from "@mui/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import PublicIcon from '@mui/icons-material/Public';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const Contents = ({ DocumentCheckListData, seletedCheckList, setseletedCheckList }) => {
  const classes = useStyles();

  const [viewImage,setViewImage] = React.useState(false)
  const [doc_type,setDoc_type] = React.useState('');
  const [image_url,setImage_url] = React.useState('');


  const openImage = (name,url) => {
    setImage_url(url)
    setDoc_type(name)
    setViewImage(true);
  };
  const closeImage = () =>{
    setViewImage(false);
    setImage_url('')
    setDoc_type('')
  }

  return (
    <div>
     <span style={{margin:'auto',width:'auto', display:'flex', alignItems:'center',justifyContent:'center'}}>
          <Chip color="success" label="Documents Checklist" />
        </span>
      {/* <p>Welcome to our medical clinic</p> */}
      {/* <img src={`${process.env.PUBLIC_URL}/img/documents/stayHome.png`} /> */}


      <div style={{ display: 'inline-block', marginBottom: '20px', width: '100%' }}>
        <div className={classes.cardMainCountries} >
          <div className={classes.cardDetails} onClick={() => setseletedCheckList('Academic')}>
            <div className={classes.cardMain} style={{ background: seletedCheckList === "Academic" ? "#1b6cfc" : "white" }}>
              <CalendarTodayIcon className={classes.iconImage} />
            </div>
            <p>Academics</p>
          </div>
          <div className={classes.cardDetails} onClick={() => setseletedCheckList('Visa')}>
            <div className={classes.cardMain} style={{ background: seletedCheckList === "Visa" ? "#1b6cfc" : "white" }}>
              <InsertDriveFileIcon className={classes.iconImage} />
            </div>
            <p>Visa</p>
          </div>
          <div className={classes.cardDetails} onClick={() => setseletedCheckList('General')}>
            <div className={classes.cardMain} style={{ background: seletedCheckList === "General" ? "#1b6cfc" : "white" }}>
              <MoreHorizIcon className={classes.iconImage} />
            </div>
            <p>General</p>
          </div>
          <div className={classes.cardDetails} onClick={() => setseletedCheckList('Document')}>
            <div className={classes.cardMain} style={{ background: seletedCheckList === "Document" ? "#1b6cfc" : "white" }}>
              <DescriptionIcon className={classes.iconImage} />
            </div>
            <p>Document</p>
          </div >
          <div className={classes.cardDetails} onClick={() => setseletedCheckList('Immigration')}>
            <div className={classes.cardMain} style={{ background: seletedCheckList === "Immigration" ? "#1b6cfc" : "white" }}>
              <PublicIcon className={classes.iconImage} />
            </div>
            <p>Immigration</p>
          </div >
          <div className={classes.cardDetails} onClick={() => setseletedCheckList('Funding')}>
            <div className={classes.cardMain} style={{ background: seletedCheckList === "Funding" ? "#1b6cfc" : "white" }}>
              <DoneIcon className={classes.iconImage} />
            </div>
            <p>Funding</p>
          </div >
        </div >
      </div >



      <div className={classes.container} style={{ justifyContent: "space-between", width: "100%", marginTop: 10 }}>

        {/* <div style={{ width: "33%", textAlign: "center", background: seletedCheckList === "Academic" ? "#1b6cfc" : "white" }} onClick={() => setseletedCheckList('Academic')}>
          <CalendarTodayIcon className={classes.iconImage} />
          <p>Academics</p>
        </div> */}

        {/* <div style={{ width: "33%", textAlign: "center", background: seletedCheckList === "Visa" ? "#1b6cfc" : "white" }} onClick={() => setseletedCheckList('Visa')}>
          <InsertDriveFileIcon className={classes.iconImage} />
          <p>Visa</p>
        </div>

        <div style={{ width: "33%", textAlign: "center", background: seletedCheckList === "General" ? "#1b6cfc" : "white" }} onClick={() => setseletedCheckList('General')}>
          <MoreHorizIcon
            className={classes.iconImage} />
          <p>General</p>
        </div> */}
        {/* <main style={{ width: "33%", textAlign: "center", background: seletedCheckList === "Document" ? "#1b6cfc" : "white" }} onClick={() => setseletedCheckList('Document')}>
          <DescriptionIcon className={classes.iconImage} />
          <p>Document</p>
        </main> */}

        {/* <main style={{ width: "33%", textAlign: "center", background: seletedCheckList === "Immigration" ? "#1b6cfc" : "white" }} onClick={() => setseletedCheckList('Immigration')}>
          <PublicIcon className={classes.iconImage} />
          <p>Immigration</p>
        </main>

        <main style={{ width: "33%", textAlign: "center", background: seletedCheckList === "Funding" ? "#1b6cfc" : "white" }} onClick={() => setseletedCheckList('Funding')}>
          <DoneIcon className={classes.iconImage} />
          <p>Funding</p>
        </main> */}
      </div>


      {/* 
      <p style={{ textAlign: 'justify' }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the
        1500s, when an unknown printer took a galley of type and scrambled it to
        make a type specimen book.
      </p> */}


      <PageTitle pageTitle={`Documents ${DocumentCheckListData?.length}`} />

      {
        (DocumentCheckListData || []).map((d) => {
          return <div className='flexCenterJcB'>
            <main className='flexCenter'>
              <CheckBoxOutlinedIcon />
              <p style={{ marginLeft: '15px', marginTop:'10px' }}>{d?.document_type || ''}</p>
            </main>
            <RemoveRedEyeOutlinedIcon onClick={()=>openImage(d?.document_type,`https://erp.bscglobaledu.com/${d.path}/${d.attachmentsid}_${d.filename}` )}  />
          </div>
        })
      }

    <Dialog
        open={viewImage}
        onClose={closeImage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {setDoc_type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <img src={image_url} height="300px" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={closeImage}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
};

export default Contents;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  insideContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  moreDiv: {
    border: '1px solid',
    borderRadius: '120%',
    alignSelf: 'center'
  },
  iconImage: {
    height: "60px",
    width: "40px",
  },
  cardMainCountries: {
    display: 'inline-block',
    marginTop: '15px'
  },
  cardDetails: {
    textAlign: 'center',
    width: '33.33%',
    float: 'left'
  },
  cardMain: {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px',
  },
}));
