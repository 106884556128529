import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

export default function BookMarkMessageModal(props) {
    const {open, handleClose, data, handleProgramList} = props
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            // scroll={'body'}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                <p style={{marginTop: '15px', marginBottom:'15px'}}>{data}</p>
            </DialogContent>
        </Dialog>
    )
}
