import { Alert, Card, CardActions, CardContent, CircularProgress, Collapse, Grid, IconButton, Chip } from "@mui/material";
import React, { useEffect, useState } from "react"
import { RiSearchLine } from "react-icons/ri"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import rightArrow from "./../../static/icons/rightArrow.png";
import sort from "./../../static/icons/sortAscending.svg";
import "./faq.css";
import Filter from "../../static/img/Mentor/Filter.png"
import { fetchFaqListAction } from "../Action";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, styled } from "@mui/system";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import _ from 'lodash'


const Faq = () => {
  const [FaqDataList, setFaqDataList] = useState([])
  const [ActiveTab, setActiveTab] = React.useState('Admission')
  const [loader, setloader] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [allFaq,setAllFaq] = useState([])
  const [searchList,setSearchList] = useState([])
  const [admissionCount,setAdmissionCount] = useState(0)
  const [visaCount,setVisaCount] = useState(0)
  const [generalCount,setGeneralCount] = useState(0)

  const GetFaqData = (tabName) => {
    setloader(true)
    var data = `{\n"bypass":"validation"\n}`;
    fetchFaqListAction(data).then((data) => {
      // console.log(data)
      if (data.status === 0) {
        setErrorMessage(data.message)
        setFaqDataList([])
      } else {
        setErrorMessage(null)
        setAllFaq(data?.data)
        const ResultData = _.filter((data?.data), (d) => ActiveTab.includes(d?.category))
        setFaqDataList(ResultData)
        setAdmissionCount(_.filter((data?.data), (d) => 'Admission'.includes(d?.category))?.length)
        setVisaCount(_.filter((data?.data), (d) => 'Visa'.includes(d?.category))?.length)
        setGeneralCount(_.filter((data?.data), (d) => 'General'.includes(d?.category))?.length)
        // setAdmissionCount()
      }
      setloader(false)
    }).catch((error) => {
      setloader(false)
      setFaqDataList([])
    })
  }
  useEffect(() => {
    GetFaqData(ActiveTab)
  }, [])

  useEffect(() => {
    // GetFaqData(ActiveTab)
    const ResultData = _.filter((allFaq), (d) => ActiveTab.includes(d?.category))
    setFaqDataList(ResultData)
  }, [ActiveTab])


  const filterResult = (val) =>{
    // console.log(val)
    if(val === null || val === ''){
        setSearchList([])
    }
    else{

      var ResultData = _.filter(allFaq, function (item) {
          var upperText = item?.question?.toUpperCase()
          return upperText?.indexOf(val?.toUpperCase()) > -1;
      });
        // console.log(ResultData)
        setSearchList(ResultData)
        setFaqDataList(ResultData)
    }
}
 
  return (
    <div>
      <div>
      <span style={{margin:'auto',width:'auto', display:'flex', alignItems:'center',justifyContent:'center'}}>
          <Chip color="success" label="How can we help you?" />
        </span>
        <br/>
        <Grid>
          <Grid sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }} >
            <main style={{ backgroundColor: '#FFF' }} className="input_container">
              <RiSearchLine style={{ fontSize: '30px' }} />
              <input autoFocus placeholder='Search FAQ...' onChange={(e) => filterResult(e.target.value)}/>
            </main>
            {/* <div className="arrow_icon">
              <img src={Filter} alt="" />
            </div> */}
          </Grid>
        </Grid>
        <div className="d-flex align-items-center my-3 justify-content-between">
          <p className="top_question">Top Questions</p>
          {/* <button className="sort_btn">
            <img src={sort} alt="" />
            sort
          </button> */}
        </div>
      </div>

      <Tabs>
        <TabList className="faq_tab_class my-3 px-1">
          {
            searchList?.length > 0 
            ?
            <Tab className=" faq_category" onClick={() => setActiveTab('Admission')}>All {FaqDataList.length}</Tab>
            :
          <>
          <Tab className=" faq_category" onClick={() => setActiveTab('Admission')}>Admission {admissionCount ? admissionCount : ''}</Tab>
          <Tab className=" faq_category" onClick={() => setActiveTab('Visa')}>Visa {visaCount ? visaCount : ''}</Tab>
          <Tab className=" faq_category" onClick={() => setActiveTab('General')}>General {generalCount ? generalCount : ''}</Tab>
          </>
          }
          {/* <Tab className="faq_category">Reservasions</Tab> */}
        </TabList>
        <TabPanel>
          {errorMessage ? <Alert severity="error" color="error">
            {errorMessage}
          </Alert>
            : loader ?
              <Box sx={{ display: 'flex', height: window.innerHeight - 400, justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box> :
              FaqDataList.map((d) => {
                return <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{color:'#92A8D1 !important'}}>{d?.question || ''}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  {d?.answer || ''}
                  </Typography>
                </AccordionDetails>
              </Accordion>
                
              })
          }
          
        </TabPanel>
        <TabPanel>
          {errorMessage ? <Alert severity="error" color="error">
            {errorMessage}
          </Alert>
            : loader ?
              <Box sx={{ display: 'flex', height: window.innerHeight - 400, justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box> :
              FaqDataList.map((d) => {
                return <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{color:'#92A8D1 !important'}}>{d?.question || ''}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  {d?.answer || ''}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              })
          }
          
        </TabPanel>
        <TabPanel>
          {errorMessage ? <Alert severity="error" color="error">
            {errorMessage}
          </Alert>
            : loader ?
              <Box sx={{ display: 'flex', height: window.innerHeight - 400, justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box> :
              FaqDataList.map((d) => {
                return <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{color:'#92A8D1 !important'}}>{d?.question || ''}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  {d?.answer || ''}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              })
          }
          
        </TabPanel>
        
      </Tabs>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Faq;
