import { Chip, Grid, IconButton, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useState } from 'react'
import RoomIcon from "@mui/icons-material/Room";
import moment from 'moment';
import Calling from '../../../img/Calling.png';
import whatsapp from '../../../img/whatsapp.png';
import Message from '../../../img/Message.png';
import { useHistory } from 'react-router-dom';

export default function AccomodationCard(props) {
    const histroy = useHistory()

    const [open, setOpen] = useState(false)
    const { property_name, location, rent_week, rent_currency, available_from } = props.val
    let { property_amenities, property_type, accommidationid, university } = props.val
    property_amenities = property_amenities?.split("|##|").join(",")
    property_type = property_type?.split("|##|").join(",")
    let is_hide = props?.hide

    return (
        <Grid container style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 25, marginTop: 10, boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 0.2), 0px 3px 4px 0px rgba(0 0 0 / 0.14), 0px 1px 8px 0px rgba(0 0 0 / 0.12)" }}>
            <Grid item xs={3} >
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/books.png`} style={{ maxWidth: "100%", margin: 'auto', height: '70px', width: 100, marginTop: '12px' }} alt='college' />
                </div>
                <div>
                    <span style={{ fontWeight: 'bold', fontSize: '16px', position: "relative", bottom: -30, marginLeft: 5 }}>
                        {/* {getCurrencySymbol(rent_currency)} */}
                        {Math.round(rent_week)}/ week</span>
                </div>
            </Grid>
            <Grid item xs={9} sx={{ pl: 1, position: 'relative', pb: 2 }}>
                <div style={{ display: "flex" }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '13px', mt: 1, width: "50%" }}>{property_name}</Typography>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 30,
                        height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                    }}>
                        <a
                        // href={`mailto:${mentorData?.data?.mentor?.email || ''}`}
                        ><img src={`https://previews.123rf.com/images/martialred/martialred1707/martialred170700026/81572494-clique-para-ir-para-o-site-on-line-ou-%C3%ADcone-de-vetor-plana-da-internet-para-aplicativos-e-sites.jpg`} style={{ height: 30, width: 30 }} /></a>

                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 30,
                        height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                    }}>
                        <a
                        // href={`mailto:${mentorData?.data?.mentor?.email || ''}`}
                        ><img src={`${Message}`} style={{ height: 30, width: 30 }} /></a>
                    </div>
                    <div style={{ display: "flex", width: "30%" }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>
                            <a
                            // href={`https://api.whatsapp.com/send?phone=${data.mobile || ''}&text=Hi`}
                            > <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                        </div>
                        <div
                            onClick={() => {
                                // window.open(`tel:${data.mobile}`);
                            }}
                            style={{
                                marginLeft: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                                height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                            }}>
                            <img src={`${Calling}`} style={{ height: 30, width: 30 }} />
                        </div>
                    </div>
                </div>
                <Typography sx={{ fontSize: '13px', display: "flex", alignItems: "center" }}><IconButton>
                    <RoomIcon sx={{ fontSize: 12, opacity: 0.7 }} />
                </IconButton>{location}</Typography>
                <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>
                        <span style={{ color: "rgba(27, 108, 252)", fontWeight: "bold" }}>  {university}</span>  </Typography>

                <hr style={{ maxWidth: '70%', marginRight: '30%', color: '#cfcaca', width: '70%' }} />
                <div style={{ paddingBottom: 20 }}>
                    <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>
                        <span style={{ color: "rgba(27, 108, 252)", fontWeight: "bold" }}>  {property_type}</span>  </Typography>
                    <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>
                        {"Amenities "}   <span style={{ color: "rgba(27, 108, 252)", fontWeight: "bold" }}>  {property_amenities}</span>  </Typography>
                    <Typography sx={{ mt: 0, fontSize: '15px', display: 'inline-block', width: '100%' }}>
                        {"Available From "} <span style={{ color: "red", fontWeight: "bold" }}>  {moment(available_from).format('ll')}</span>
                    </Typography>
                </div>
                {
                    is_hide 
                    ? 
                    '' 
                    : 
                    <>
                    <Chip
                        label="Enquiry"
                        sx={{ bgcolor: '#1B6CFC', color: '#fff', marginRight: '15px', float: 'left' }}
                        onClick={(e) => histroy.push('/apply-accomodation/' + accommidationid)}
                    />
                    <IconButton
                    size="small"
                    edge="end"
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => { }}

                >
                    {/* {val.bookmark === "1" ? */}
                    {/* <FavoriteIcon
                        style={{ color: 'red' }}
                        onClick={(e) => {

                        }
                        }
                    /> */}
                    {/* : */}
                    <FavoriteBorderIcon onClick={(e) => {

                    }}
                    />
                    {/* } */}
                </IconButton>
                    </>
                }

                

            </Grid>
        </Grid>
    )
}
