import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


 function BasicSelect({onChangeHandler,naam,totals}) {
  return (
      <Autocomplete
        multiple
        id="tags-outlined"
        onChange={ onChangeHandler}
        options={totals}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label={naam}
            placeholder=""
            
          />
        )}
      />
     
  );
}


export default BasicSelect;