import React, { useEffect } from 'react';
import {Grid,Input,Typography} from "@mui/material";
import Radio from '@mui/material/Radio';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import {makeStyles} from "@mui/styles";
import Slider from '@mui/material/Slider';


const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
      },
    }),
  );
  
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  const PossiblePrerequisiteForm = (props) => {
    const {values,handleChange,countryValue,
    greData,gmatData,actData,satData,ieltsData,duolingoData,pteData,toeflData,
    setgmatData,setgreData,setsatData,setactData,settoeflData,setieltsData,setduolingoData,setpteData,
    setFirst,setSecond,
    } = props;
    const classes = useStyles();
    const [optionDegree,setoptionDegree]=React.useState('')
    const [optionData,setoptionData]=React.useState('')

    const rangeValue = [
        {
            'label': 'English',
            'from': 0,
            'to': 100
        },
        {
            'label': 'Ielts',
            'from': 0,
            'to': 9
        },
        {
            'label': 'Toefl',
            'from': 0,
            'to': 120
        },
        {
            'label': 'Pte',
            'from': 0,
            'to': 90
        },
        {
            'label': 'Duolingo',
            'from': 10,
            'to': 160
        },
        {
            'label': 'gre',
            'from': 260,
            'to': 340
        },
        {
            'label': 'gmat',
            'from': 200,
            'to': 800
        },
        {
            'label': 'act',
            'from': 1,
            'to': 36
        },
        {
            'label': 'sat',
            'from': 400,
            'to': 1600
        },
    ]

    useEffect(() => {
        if (values.degree_looking_for === 'Masters' || values.degree_looking_for === 'Phd') {
            countryValue.length === 1 && countryValue[0] === "USA" && setoptionData('gre')
        } else if (values.degree_looking_for === 'Bachelors') {
            setoptionData('act')
        }
    }, [])

    const handleOptionDegreeChange = (event, newValue) => {
        if (optionDegree === 'Ielts') {
            setieltsData(newValue)
        } else if (optionDegree === 'Toefl') {
            settoeflData(newValue)
        } else if (optionDegree === 'Pte') {
            setpteData(newValue)
        } else if (optionDegree === 'Duolingo') {
            setduolingoData(newValue)
        }
    };
    const handleOptionDataChange = (event, newValue) => {
        if (optionData === 'act') {
            setactData(newValue)
        } else if (optionData === 'sat') {
            setsatData(newValue)
        } else if (optionData === 'gre') {
            setgreData(newValue)
        } else if (optionData === 'gmat') {
            setgmatData(newValue)
        }
    }
    const valuetext = (value) => {
        return `${value}`
    }

    const setoptionDegreeFun = (d) => {
        setoptionDegree(d)
        setFirst(d)
    }

    const setoptionDataFun = (data) => {
        setoptionData(data)
        setSecond(data)
    }
    return (
        <div>
            <Grid
                xs={12}
                sx={{
                    p: 3,
                    paddingBottom: "80px",
                    backgroundColor: "#fff",
                    mt: 3,
                    pb: 2,
                    borderRadius: "10px",
                    boxShadow: 3,
                }}
            >
                <Typography>English Requirement</Typography>
                <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <RadioGroup
                        className={classes.radioGroup}
                        row name="optionDegree_type"
                        value={optionDegree}
                        onChange={(e) => setoptionDegreeFun(e.target.value)}
                    >
                        <MyFormControlLabel
                            className={"radioLabel"}
                            value="English"
                            label="12th English"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />
                        <MyFormControlLabel
                            className={"radioLabel"}
                            value="Toefl"
                            label="TOEFL"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />
                        <MyFormControlLabel
                            className={"radioLabel"}
                            value="Ielts"
                            label="IELTS"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />
                        <MyFormControlLabel
                            className={"radioLabel"}
                            value="Pte"
                            label="PTE"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />
                        <MyFormControlLabel
                            className={"radioLabel"}
                            value="Duolingo"
                            label="DUOLINGO"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />
                    </RadioGroup>
                </FormControl>
                {optionDegree && <FormControl fullWidth sx={{ mt: 1.5 }}>
                    {/* {optionDegree === 'English' && <Input
                        type="number"
                        value={values.English}
                        placeholder={optionDegree}
                        onChange={handleChange}
                    />} */}
                    {optionDegree === 'English' && <Slider 
                        value={values.English}
                        min={0}
                        step={1}
                        max={100}
                        onChange={handleChange}
                        getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                    />}
                    {optionDegree === 'Toefl' && <Slider
                        value={toeflData}
                        min={0}
                        step={1}
                        max={120}
                        onChange={handleOptionDegreeChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                    {optionDegree === 'Ielts' && <Slider
                        value={ieltsData}
                        min={0}
                        step={0.5}
                        max={9}
                        onChange={handleOptionDegreeChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                    {optionDegree === 'Duolingo' && <Slider
                        value={duolingoData}
                        min={10}
                        step={1}
                        max={160}
                        onChange={handleOptionDegreeChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                    {optionDegree === 'Pte' && <Slider
                        value={pteData}
                        min={0}
                        step={1}
                        max={90}
                        onChange={handleOptionDegreeChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                </FormControl>}
                {optionDegree && <Typography>{(rangeValue.find(o => o.label === optionDegree)).from}</Typography>}
                {optionDegree && <Typography sx={{ position: 'absolute', right: '30px', marginTop: '-26px !important' }}>{(rangeValue.find(o => o.label === optionDegree)).to}</Typography>}

                <Typography sx={{ mt: 2, mb: 1 }}>Prerequisite</Typography>
                {optionData && <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                    <RadioGroup
                        className={classes.radioGroup}
                        row name="optionData"
                        value={optionData}
                        onChange={(e) => setoptionDataFun(e.target.value)}
                    >
                        {(optionData === 'gre' || optionData === 'gmat') && <MyFormControlLabel
                            className={"radioLabel"}
                            value="gre"
                            label="GRE"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />}
                        {(optionData === 'gre' || optionData === 'gmat') && <MyFormControlLabel
                            className={"radioLabel"}
                            value="gmat"
                            label="GMAT"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />}

                        {(optionData === 'act' || optionData === 'sat') && <MyFormControlLabel
                            className={"radioLabel"}
                            value="act"
                            label="ACT"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />}
                        {(optionData === 'act' || optionData === 'sat') && <MyFormControlLabel
                            className={"radioLabel"}
                            value="sat"
                            label="SAT"
                            style={{ padding: "5px 17px", marginBottom: 5 }}
                            control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                        />}
                    </RadioGroup>
                </FormControl>}
                {optionData && <FormControl fullWidth sx={{ mt: 1.5 }}>
                    {optionData === 'gre' && <Slider
                        value={greData}
                        min={260}
                        step={1}
                        max={340}
                        onChange={handleOptionDataChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                    {optionData === 'gmat' && <Slider
                        value={gmatData}
                        min={200}
                        step={10}
                        max={800}
                        onChange={handleOptionDataChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                    {optionData === 'act' && <Slider
                        value={actData}
                        min={1}
                        step={1}
                        max={36}
                        onChange={handleOptionDataChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                    {optionData === 'sat' && <Slider
                        value={satData}
                        min={400}
                        step={10}
                        max={1600}
                        onChange={handleOptionDataChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                        getAriaValueText={valuetext}
                    />}
                </FormControl>}
                {optionData && <Typography>{(rangeValue.find(o => o.label === optionData)).from}</Typography>}
                {optionData && <Typography sx={{ position: 'absolute', right: '30px', marginTop: '-26px !important' }}>{(rangeValue.find(o => o.label === optionData)).to}</Typography>}
                {/* <FormControl fullWidth sx={{mt: 1.5}}>
                    <InputLabel>PUC/12th/Inter %</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value=""
                        label="Program"
                        onChange="handleChange"
                    >
                        <MenuItem value={10}>Education</MenuItem>
                        <MenuItem value={20}>Education</MenuItem>
                        <MenuItem value={30}>Education</MenuItem>
                    </Select>
                </FormControl> */}
            </Grid>
        </div>
    );
};

export default PossiblePrerequisiteForm;

const useStyles = makeStyles(() => ({
    radioGroup:{
        marginBottom:'5px'
    },
}));