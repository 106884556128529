import { Route, Redirect} from "react-router-dom";
import BottomNav from "../../components/Common/BottomNav";
import Navbar from "../../components/Common/Navbar";
import { isLoggedIn, getUserType } from "../localStorageHandler";


export default function AuthAssociates({ component: Component,role, ...rest }) {

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        (isLoggedIn() &&  getUserType() ==='Employee') || getUserType() ==='Associate' || getUserType() === 'Associate Admin' ?
          <>

              <Component {...routeProps} />
      
          </>
          :
          <Redirect to={{pathname: '/', state: {from: routeProps.location}}}/>
      )}
    />
  );
}

