import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Alert, Button, CircularProgress, Grid, IconButton } from "@mui/material";
import PageTitle from "../PageTitle";
import Colors from "../../static/layout/Colors";
import { fetchServiceListAction } from "../Action";
import { Box } from "@mui/system";
import { useHistory } from 'react-router-dom';

import useAppContext from "../../useContext/userContext"

const ServicesList = () => {
    const history = useHistory()

    const {getServiceList,service_list} = useAppContext()

    console.log(service_list?.service_list)
    const classes = useStyles();
    const [serviceData, setserviceData] = useState([])
    const [loader, setloader] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)

    useEffect(() => {
        getServiceList()
    }, [])
    // console.log("serviceData", serviceData)
    if (errorMessage) {
        return <Alert severity="error" color="error">
            {errorMessage}
        </Alert>
    } else if (loader) {
        return <Box sx={{ display: 'flex', height: window.innerHeight - 400, justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </Box>
    } else
        return (
            <div>
                <div className={classes.topHeadingSorter}>
                    <Grid container sx={{ justifyContent: "space-between", }}>
                        <Grid
                            item
                            xs={9}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "start",
                                borderRadius: "100px",
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                                padding: "10px",
                            }}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/img/Search.png`}
                                alt='Mail'
                                style={{ marginTop: "4px", height: '20px' }} />

                            <input
                                type="email"
                                name={'email'}
                                className={classes.input}
                                autoFocus
                                placeholder="Search Service.."
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "start",
                                borderRadius: "100px",
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                                padding: "0",
                                width: '56px',
                                height: '56px'
                            }}
                        >
                            <Button
                                sx={{
                                    padding: 0,
                                }}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/img/Filter.png`}
                                    alt='Mail'
                                    style={{ marginTop: "4px", height: '20px' }} />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.topHeadingSorter}>
                    <PageTitle pageTitle={`Service list`} bold />
                    <Button className={classes.sortBtn}>
                        <img style={{ paddingRight: '5px' }} alt={''}
                            src={`${process.env.PUBLIC_URL}/img/sort-ascending.png`} />
                        Sort</Button>
                </div>
                {service_list?.service_list?.map((d) => {
                    return <div className="global-container mt-3 p-0">

                        <div className={classes.eventCard}>
                            <div className={classes.eventListContainer}>
                                <div className={classes.customTag}>$ {Math.round(d.unit_price)}</div>
                                <img alt={''} className={classes.partyImage} src={`https://mobile.ishvi.com/img/pack/${d?.servicename}.png`} />
                            </div>

                            <div style={{ marginTop: '16px', marginBottom: '16px' }} className={classes.container2}>
                                <div>
                                    <PageTitle bold pageTitle={d?.servicename || ''} />
                                    <div style={{ marginTop: '14px' }} className={classes.location}>
                                        <IconButton sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                        }}>
                                            <img alt={''} src={`${process.env.PUBLIC_URL}/img/thumb-icon.png`} />
                                        </IconButton>
                                        <p className="mb-0"
                                            style={{ fontSize: 14, lineHeight: '17px', fontWeight: 'normal', color: '#1B6CFC', }}>
                                            50 Likes
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.container3}>
                                <main className={classes.joined}>{d?.service_details || ''}</main>
                                <button className={classes.inviteMore} onClick={()=>{
                                    history.push({
                                        pathname: '/ServicesDetails',
                                        state: {
                                            'serviceid': d?.serviceid || '',
                                        }
                                    })
                                }}>View Pack</button>
                            </div>
                        </div>
                    </div>
                })}
            </div>

        );
};

export default ServicesList;

const useStyles = makeStyles((theme) => ({
    eventCard: {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        minHeight: '350px',
        padding: '18px 15px',
        marginBottom: '20px'
    },
    container2: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
    },
    container3: {
        marginTop: "2%",
    },
    eventListContainer: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        borderRadius: '20px',
        backgroundColor: '#F9F9F9',
        position: 'relative'
    },
    location: {
        display: 'flex',
        alignItems: 'center'
    },
    partyImage: {
        borderRadius: '20px',
        height: '182px'
    },
    joined: {
        fontWeight: "normal",
    },
    img: {
        height: "30px",
        width: "30px",
        borderRadius: "100%",
        marginLeft: "10px",
    },


    topHeadingSorter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    sortBtn: {
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        color: '#1A1A1A',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 'normal',
        padding: '10px'
    },
    inviteMore: {
        border: '0',
        color: '#ffffff',
        borderRadius: '52px',
        backgroundColor: '#1B6CFC',
        boxShadow: ' 0px 4px 19px rgba(27, 108, 252, 0.21)',
        width: '100%',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 'bold',
        marginTop: "20px",
        marginBottom: "10px",
        padding: '18px 0'
    },
    input: {
        borderColor: "transparent",
        outline: "none",
        marginLeft: "10px",
        fontSize: "16px",
        width: "80%",
        display: "flex",
        opacity: 0.7,
        letterSpacing: 0.7,
    },
    customTag: {
        color: Colors.blue,
        fontSize: '16px',
        backgroundColor: '#DCE8FF',
        borderRadius: '23px',
        fontWeight: '700',
        lineHeight: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '5px 10px'
    }
}));
