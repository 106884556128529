import React, { useState } from 'react'
import { FormControl, Grid, Input, Button, Typography, Select, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import whatsapp from '../../../img/whatsapp.png';
import Message from '../../../img/Message.png';
import SendMessage from '../../../img/sendMessage.png';
import { render } from '@testing-library/react';
import { champianInsertAction, fetchRequiredDataAction, fetchStudentUploadDocumentAction, UploadDocumentAction } from '../../Action/index.jsx'
import { ToastContainer, toast } from 'react-toastify';
import { getSessionId, getUser, getUserId } from '../../../Controller/localStorageHandler';
import { makeStyles } from "@mui/styles";
import UploadDocumentModal from '../../Zolve/UploadDocumentModal';
import PreviewDocumentModal from '../../Zolve/PreviewDocumentModal';
import Colors from '../../../static/layout/Colors';
import DashStepper from '../../Dashboards/Dashfor/DashStepper';
import PageTitle from '../../PageTitle';
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import uniqid from 'uniqid';
import Birthday from '../../Dashboards/Cards/Birthday';


const DataMapping = [
    {
        "document": "10th and 12th Marksheet",
        'title': '10th'
    },
    {
        "document": "Accommodation Confirmation",
        'title': 'Accommodation'
    },
    {
        "document": "ACT",
        'title': 'ACT'
    },
    {
        "document": "Admission Essay",
        'title': 'Admission'
    },
    {
        "document": "Affidavit Of Support",
        'title': 'Affidavit'
    },
    {
        "document": "Agent Authorization",
        'title': 'Agent'
    },
    {
        "document": "Air Ticket Confirmation",
        'title': 'Air'
    },
    {
        "document": "All Semester Marksheet",
        'title': 'All'
    },
    {
        "document": "Application Fee",
        'title': 'Application'
    },
    {
        "document": "ATAS",
        'title': 'ATAS'
    },
    {
        "document": "Bank Certificate",
        'title': 'Bank'
    },
    {
        "document": "Birth Certificate",
        'title': 'Birth'
    },
    {
        "document": "Blocked Account",
        'title': 'Blocked'
    },
    {
        "document": "Bonafied Certificate",
        'title': 'Bonafied'
    },
    {
        "document": "CAQ",
        'title': 'CAQ'
    },
    {
        "document": "Charted Account Report",
        'title': 'Charted'
    },
    {
        "document": "Confirmation Of Acceptance For Studies-CAS",
        'title': 'Confirmation'
    },
    {
        "document": "Confirmation Of Enrolment-COE",
        'title': 'Confirmation'
    },
    {
        "document": "Course Completion Certificate",
        'title': 'Course'
    },
    {
        "document": "Covering Letter",
        'title': 'Covering'
    },
    {
        "document": "Department Essay",
        'title': 'Department'
    },
    {
        "document": "Duolingo",
        'title': 'Duolingo'
    },
    {
        "document": "Education Loan Sanction",
        'title': 'Education'
    },
    {
        "document": "Fee Payment Receipt",
        'title': 'Fee'
    },
    {
        "document": "Financial Matrix",
        'title': 'Financial'
    },
    {
        "document": "Financial Support Form",
        'title': 'Financial'
    },
    {
        "document": "French Language Certificate",
        'title': 'French'
    },
    {
        "document": "GATE",
        'title': 'GATE'
    },
    {
        "document": "German Language Certificate",
        'title': 'German'
    },
    {
        "document": "GIC",
        'title': 'GIC'
    },
    {
        "document": "GMAT",
        'title': 'GMAT'
    },
    {
        "document": "GRE",
        'title': 'GRE'
    },
    {
        "document": "I-20",
        'title': 'I-20'
    },
    {
        "document": "IELTS",
        'title': 'IELTS'
    },
    {
        "document": "IHS Health Insurance",
        'title': 'IHS'
    },
    {
        "document": "ITRs Of Sponsors",
        'title': 'ITRs'
    },
    {
        "document": "LOR-1",
        'title': 'LOR-1'
    },
    {
        "document": "LOR-2",
        'title': 'LOR-2'
    },
    {
        "document": "LOR-3",
        'title': 'LOR-3'
    },
    {
        "document": "Medical Certificate",
        'title': 'Medical'
    },
    {
        "document": "Medium Of Instruction",
        'title': 'Medium'
    },
    {
        "document": "Motivational Letter",
        'title': 'Motivational'
    },
    {
        "document": "NOC",
        'title': 'NOC'
    },
    {
        "document": "Offer Letter",
        'title': 'Offer'
    },
    {
        "document": "Official Transcript",
        'title': 'Official'
    },
    {
        "document": "Passport Copy",
        'title': 'Passport'
    },
    {
        "document": "PDC/Degree Certificate",
        'title': 'PDC/Degree'
    },
    {
        "document": "PF/PPF Withdraw Certificate",
        'title': 'PF/PPF_file'
    },
    {
        "document": "Photo",
        'title': 'Photo'
    },
    {
        "document": "Police Clearance Certificate",
        'title': 'Police'
    },
    {
        "document": "Property Evaluation",
        'title': 'Property'
    },
    {
        "document": "PTE",
        'title': 'PTE'
    },
    {
        "document": "Research Proposal-1",
        'title': 'Research'
    },
    {
        "document": "Research Proposal-2",
        'title': 'Research'
    },
    {
        "document": "Research Proposal-3",
        'title': 'Research'
    },
    {
        "document": "Research Proposal-4",
        'title': 'Research'
    },
    {
        "document": "Research Proposal-5",
        'title': 'Research'
    },
    {
        "document": "Resume",
        'title': 'Resume'
    },
    {
        "document": "SAT",
        'title': 'SAT'
    },
    {
        "document": "SOP",
        'title': 'SOP'
    },
    {
        "document": "TOEFL",
        'title': 'TOEFL'
    },
    {
        "document": "Visa  Checklist",
        'title': 'Visa'
    },
    {
        "document": "Visa Application",
        'title': 'Visa'
    },
    {
        "document": "Visa Copy",
        'title': 'Visa'
    },
    {
        "document": "WES",
        'title': 'WES'
    },
    {
        "document": "Work Experience",
        'title': 'Work'
    }
]
export const AddEvent = (props) => {
    const history = useHistory()

    const classes = useStyles();
    const [openUpload, setOpenUpload] = React.useState(false);
    const [openPreview, setOpenPreview] = React.useState(false);

    const [NeedDocumentList, _NeedDocumentList] = useState([])
    const [DocumentDataList, setDocumentDataList] = React.useState(false);
    const [UploadingFileData, SetUploadingFileData] = React.useState([]);
    const [ActiveUploadingData, SetActiveUploadingData] = React.useState({});
    const [SubmitButtonDisable, setSubmitButtonDisable] = React.useState(false)
    const [uploadFromRequired, setUploadFromRequired] = React.useState(false)
    const [RequiredLength, setRequiredLength] = React.useState(3)
    const [UploadedDocuments, setUploadedDocuments] = React.useState(3)
    const handleOpenUpload = () => {
        setOpenUpload(true);
    };
    const handleCloseUpload = () => {
        setOpenUpload(false);
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    };
    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    const GetUploadDocument = () => {
        var data = `{\n"contact_id":"${props?.userId || getUserId()}","bypass":"validation"\n}`;
        fetchStudentUploadDocumentAction(data).then((data1) => {
            setDocumentDataList(data1)
        }).catch((error) => {
            console.log("error", error)
        })
    }

    React.useEffect(() => {
        GetUploadDocument()
        fetchRequiredDataAction().then((reponse) => {
            _NeedDocumentList(reponse || [])
        }).catch((error) => {
            _NeedDocumentList([])
            console.log(error)
        })

    }, []);

    const handleSubmitButton = () => {
        let campaignid = history?.location?.state?.data?.campaignid || null
        let userId = props?.userId || getUserId()
        let sessionId = getSessionId()

        setUploadFromRequired(false)
        var data = new FormData();
        data.append('contact_id', props?.userId || getUserId());
        data.append('submit', 'a');
        data.append('from', 'react');

        // data.append('contact_type', 'student');
        // data.append('Air', 'Air Ticket Confirmation');

        _.map(_.cloneDeep(UploadingFileData), (d) => {
            if (d.file) {
                let Data = _.find(DataMapping, (d) => d.document === d.title)
                return data.append(Data.title, d.file);
            }
        })
        UploadDocumentAction(data).then((d) => {
            GetUploadDocument()
            SetUploadingFileData([])
        }).catch((error) => {
            GetUploadDocument()
            SetUploadingFileData([])
        })
        GetUploadDocument()

        var data = new FormData();
        data.append('campaignid', campaignid);
        data.append('contactid', userId);
        data.append('userid', userId);
        data.append('sessionid', sessionId);
        champianInsertAction(data).then(res => {
            if (res?.msg === "Success") {
                toast.success(`Event Add Successfully`)
            } else {
                toast.error(`${res?.msg}`)
            }
        }).catch((erro) => {
            toast.error(`Event Add failed`)
        })
    }

    let Data = getUser()

    return <div>
        <Button sx={{ minWidth: '0px', paddingLeft: '0px' }} onClick={() => history.goBack()}><ArrowBackIcon />Back</Button>
        <Birthday data={history?.location?.state?.data} countData={history.location.state?.countData || null} />
        {/* <Birthday data={d} countData={EventListData?.count_data || null} /> */}
        <div style={{ paddingLeft: 15, paddingRight: 15, height: "90%", overflowY: "scroll" }}>
            <div className={classes.cardBox}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl sx={{ my: 1, width: "15%" }}>
                        <Input type="text" placeholder={"First Name"} value={`${Data?.firstname || ''}  `} />
                    </FormControl>
                    <FormControl sx={{ my: 1, width: "40%" }}>
                        <Input type="text" placeholder={"First Name"} value={`${Data?.firstname || ''}  `} />
                    </FormControl>
                    <FormControl sx={{ my: 1, width: "40%" }}>
                        <Input type="text" placeholder={"last Name"} value={`${Data?.lastname || ''}`} />
                    </FormControl>
                </div>
                <FormControl sx={{ my: 1, width: "100%" }}>
                    <Input type="number"
                        class="form-control inputField"
                        placeholder="Bachelors Percentage"
                        name="bachelors_percentage"
                        id="bachelors_percentage"
                        value={Data?.mobile || ''}
                        onChange={(e) => { }}
                    />
                </FormControl>
                <div style={{ display: "flex", justifyContent: "space-between" }}>

                    <FormControl sx={{ my: 1, width: "48%" }}>
                        <Input type="text" placeholder={"Bachelors Percentage"} defaultValue={Data?.number_backlogs || ''} />
                    </FormControl>
                    <FormControl sx={{ my: 1, width: "48%" }}>
                        <Input type="text" placeholder={"Backlogs"} defaultValue={Data?.number_backlogs || ''} />
                    </FormControl>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl sx={{ my: 1, width: "48%" }}>
                        <Input type="text" placeholder={"Degree"} value={Data?.degree_looking_for || ''} />
                    </FormControl>

                    <FormControl sx={{ my: 1, width: "48%" }}>
                        <Input type="text" placeholder={"Program"} value={Data?.related_programs || ''} />
                    </FormControl>
                </div>

            </div>
            <DashStepper moduleEvent={true} />

            {/****************************************  document section start ********************************* */}
            <div>
                <div className="global-container p-0">
                    {DocumentDataList?.list?.length > 0 &&
                        <Grid
                            xs={12}
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                            <Grid item xs={9} >
                                <PageTitle pageTitle="Uploaded Documents" bold />
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between" }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 30,
                                    height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                }}>
                                    {/* <a href={`mailto:${mentorData?.data?.mentor?.email || ''}`}> */}
                                    <img src={`${Message}`} style={{ height: 30, width: 30 }} />
                                    {/* </a> */}
                                </div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 30,
                                    height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                }}>
                                    {/* <a href={`https://api.whatsapp.com/send?phone=${mentorData?.data?.mentor?.mobile || ''}&text=Hi`}> */}
                                    <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                                    {/* </a> */}
                                </div>
                            </Grid>
                        </Grid>}

                    {_.cloneDeep((DocumentDataList?.list || [])).splice(0, UploadedDocuments).map((d, i) => {
                        return <div className={`${classes.header} flexCenterJcB`}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <main style={{
                                    marginBottom: '0', height: 30, width: 30,
                                    border: "1px solid transparent",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50px",
                                    justifyContent: "center",
                                    backgroundColor: "#46a719"
                                }}>
                                    <p style={{ marginBottom: '0' }} className={classes.no}>{i + 1} </p>
                                </main>
                                <span style={{ marginLeft: 10 }}>{d?.document_type || ''}</span>
                            </div>
                            <main className={classes.viewDiv}
                            // onClick={() => setViewDocumentModal(true)}
                            >
                                <a target={"_blank"} href={`https://erp.bscglobaledu.com/${d.path}${d.filename}`} style={{ marginBottom: '0' }} className={classes.view}>View</a>
                            </main>
                        </div>
                    })}
                    {DocumentDataList?.list?.length > 5 &&
                        <>
                            {DocumentDataList?.list?.length !== UploadedDocuments ? <div onClick={() => {
                                setUploadedDocuments(Data.length)
                            }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show More</div>
                                : <div onClick={() => {
                                    setUploadedDocuments(3)
                                }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show Less</div>
                            } </>
                    }
                    {Data.length > 0 &&
                        <div style={{ margin: '8% 0px 2% 0px' }} >
                            <PageTitle pageTitle='Required Documents' bold />
                            <>
                                {_.cloneDeep(Data)?.splice(0, RequiredLength).map((d, i) => {
                                    return <div className={`${classes.header} flexCenterJcB`}>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <main style={{
                                                marginBottom: '0', height: 30, width: 30,
                                                border: "1px solid transparent",
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: "50px",
                                                justifyContent: "center",
                                                backgroundColor: "orange"
                                            }}>
                                                <p style={{ marginBottom: '0' }} className={classes.no}>{i + 1} </p>
                                            </main>
                                            <span style={{ marginLeft: 10 }}>{d}</span>
                                        </div>
                                        <main className={classes.viewDiv}
                                        // onClick={() => setViewDocumentModal(true)}
                                        >
                                            <div onClick={() => {
                                                let newdata = {}
                                                newdata[`id`] = uniqid()
                                                newdata[`title`] = d
                                                newdata["active"] = false
                                                newdata["file"] = null
                                                UploadingFileData.push(newdata)
                                                SetUploadingFileData([...UploadingFileData])

                                                SetActiveUploadingData(newdata)
                                                handleOpenUpload()
                                                setUploadFromRequired(true)
                                            }} style={{ marginBottom: '0' }} className={classes.view}>Upload</div>
                                        </main>
                                    </div>
                                })
                                }
                            </>
                            {Data?.length > 5 &&
                                <>
                                    {Data.length !== RequiredLength ? <div onClick={() => {
                                        setRequiredLength(Data.length)
                                    }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show More</div>
                                        : <div onClick={() => {
                                            setRequiredLength(3)
                                        }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show Less</div>
                                    } </>
                            }
                        </div>
                    }
                    <FormControl style={{ marginTop: '3%', borderTop: "1px solide black" }} fullWidth>
                        <Select style={{ border: "1px solid" }} defaultValue="Upload Document" onChange={({ target }) => {
                            let newdata = {}
                            newdata[`id`] = uniqid()
                            newdata[`title`] = target.value
                            newdata["active"] = false
                            newdata["file"] = null
                            UploadingFileData.push(newdata)
                            SetUploadingFileData([...UploadingFileData])
                        }}>
                            <MenuItem value={'Upload Document'} inset>Upload Document</MenuItem>
                            {NeedDocumentList && NeedDocumentList.length > 0 && NeedDocumentList.map((d) => {
                                return <MenuItem value={d?.document} inset>{d?.document}</MenuItem>
                            })
                            }
                        </Select>
                    </FormControl>
                    {!uploadFromRequired && UploadingFileData.length > 0 ?
                        <>
                            <div>
                                <PageTitle pageTitle='Upload Documents' bold />
                            </div>
                            {!uploadFromRequired ? UploadingFileData.map((d) => {
                                return <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                                    <main style={{ marginTop: '4%', display: "inline-block" }}>
                                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                                    </main>
                                    <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                                        <nav>
                                            <div className={classes.bar}>
                                                <p style={{
                                                    fontWeight: 'bold', fontSize: "13px"
                                                }}> {d.title}</p>
                                                {/* <Box sx={{ width: '140%' }}>
                                        <LinearProgress variant="determinate" value={progress} />
                                    </Box> */}
                                            </div>
                                        </nav>
                                    </main>
                                    <p style={{ fontWeight: 'normal', color: '#888888', display: "inline-block" }}>
                                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={() => {

                                            SetActiveUploadingData(d)
                                            handleOpenUpload()
                                        }} />
                                        <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} onClick={() => {
                                            let selectedNeedDocumentList1 = UploadingFileData.filter((d1) => d1.id !== d.id)
                                            SetUploadingFileData([...selectedNeedDocumentList1])
                                        }} />
                                        <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} />
                                    </p>
                                </div>
                            }) : null}
                        </>
                        : ""}
                    {/* <div className={classes.submitImage}>
                        <Button onClick={() => handleSubmitButton()} disabled={SubmitButtonDisable} style={{ background: SubmitButtonDisable ? "#666666" : "#1b6cfc", width: "95%", height: 50, color: "white", borderRadius: 25 }}>
                            Submit
                        </Button>
                    </div> */}
                </div>
                <UploadDocumentModal open={openUpload} handleClose={handleCloseUpload} ActiveUploadingData={ActiveUploadingData} SetUploadingFileData={(data) => {
                    let Data = UploadingFileData.map((d) => {
                        if (d.id === data.id) {
                            return data
                        } else {
                            return d
                        }
                    })
                    SetUploadingFileData(Data)
                }} />
                <PreviewDocumentModal open={openPreview} handleClose={handleClosePreview} />
            </div >
            {/****************************************  document section  end********************************* */}


            <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">


                <Grid xs={12} sx={{ display: "flex", margin: "20px 0", }}>
                    <input type="checkbox"
                        //   {...label}
                        //   defaultChecked
                        style={{ height: "22px", width: "30px", background: "orange", border: " 1px solid #DCE8FF" }}
                    //    onChange={handleChange}
                    // onChange={(e) => setcheck(e.target.checked)}
                    />
                    <Typography
                        sx={{ color: "#666666", textAlign: "left", fontSize: "16px", lineHeight: "20px", marginLeft: "5px" }}
                    >
                        By submiting you agree with  our <a href="#" style={{ color: "#1b6cfc" }}
                            className={classes.Terms}>Terms</a> and <a href="#" style={{ color: "#1b6cfc" }} className={classes.Terms}>Conditions</a>
                    </Typography>
                </Grid>
                {/*<CancelPresentationRoundedIcon sx={{opacity: 0.5}}/>*/}
                <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '5px', marginTop: '20px' }}>
                    <Button className={classes.resetBtn}>Reset</Button>
                    <Button
                        style={{ background: SubmitButtonDisable ? "#666666" : "#1b6cfc", height: 50, color: "white", borderRadius: 25 }}
                        onClick={() => handleSubmitButton()}
                        // onClick={()=>history.push('/ScholarshipSearchResult')}
                        className={classes.submitBtn}
                        type={'submit'}>
                        Submit
                    </Button>
                </Grid>
            </div>


        </div>
    </div>

}
const useStyles = makeStyles(() => ({
    docNo: {
        border: "1px solid transparent",
        borderRadius: "50px",
        height: "50px",
        width: "50px",
        backgroundColor: "#46a719",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        marginTop: '5%',
        backgroundColor: '#ffffff',
        borderRadius: '30px',
        padding: '3px 15px',
    },
    no: {
        textAlign: "center",
        fontSize: "17px",
        color: "white",
        fontWeight: "bold",
    },
    viewDiv: {
        border: "1px solid transparent",
        width: "fit-content",
        padding: "0px 15px 0px 15px",
        borderRadius: '25px',
        backgroundColor: Colors.lightBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    view: {
        fontWeight: 'bold',
        color: Colors.blue,
        padding: '5px',
    },
    submitImage: {
        marginTop: '10%',
        display: 'flex',
        justifyContent: 'center'
    },
    header: {
        marginTop: '5%',
        backgroundColor: '#ffffff',
        borderRadius: '30px',
        padding: '3px 15px',
    },
    bar: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '56px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));