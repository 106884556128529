
import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, Button, CircularProgress,Chip } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { makeStyles } from "@mui/styles";
import { barColor, getCurrencySymbol } from "../../../Controller/Global";

import moment from "moment";
import _ from 'lodash'

import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function TopStepper({ val, ApplicationStatus, TYPE, open, setOpen, extra_condition, setApplyData, setOpenApply }) {
  const classes = useStyles();
  const handleOpenAndClose = () => setOpen(!open);

  const ApplyButtonHandler = (e) => {
    e.stopPropagation();
    setApplyData(val)
    setOpenApply(true)
  }

  const [ActiveStepIndictor, _ActiveStepIndictor] = React.useState(0)
  const [stepColor, _ActiveStepColor] = React.useState(0)

  
  // const style = {
  //   bgcolor: 'background.paper',
  //   p: 2,
  //   px: 4,
  //   pb: 3,
  //   height: "66%",
  //   marginTop: "83px",
  //   width: "96%",
  //   borderRadius: "26px",
  // };
  const steps = ["Filling", "Application Reveiws", "Deposit Due", "Visa"];


  let RedColor = ["Application Fee Due", "Yet to Confirm", "Info/Doc Pending", "Courier Fee Pending",
    "Financial doc Pending", "Visa Yet To Apply", "Application Incomplete", "Deposit Due", "Conditional Admission"]
  let greenColo = ["Admitted", "I-20/Offer/CAS/COE", "Visa Approved"]
  let greyColor = ["Not Started", "University Rejected", "Visa Denied"]
  let OrangeColor = ["Yet to Fill", "University Acknowledged", "Application Review", "Univ Confirmation Due", "Re - Selection",
    "Visa Applied", "Ready for Submission", "Eligibility Checking", "Portal Not Opened", "In Progress", "Deferred", "Open", "Canceled",
    "Completed"]

  React.useEffect(() => {
   

    if (RedColor.includes(val?.projecttaskstatus)) {
      _ActiveStepColor("#FF0000")
    }
    else if (greenColo.includes(val?.projecttaskstatus)) {
      _ActiveStepColor("#006400")
    }
    else if (greyColor.includes(val?.projecttaskstatus)) {
      _ActiveStepColor("#808080")
    }
    else if (OrangeColor.includes(val?.projecttaskstatus)) {
      _ActiveStepColor("#FFA500")
    } else {
      _ActiveStepColor("#FFA500")
    }


    let Filling = ['Open', 'Canceled', 'Completed', 'Yet to Confirm', 'Not Started', 'Re-Selection',
      'Info/Doc Pending', 'Yet to Fill', 'Application Fee Due', 'Ready for Submission',
      'Eligibility Checking', 'Portal Not Opened', 'Courier Fee Pending']

    let ApplicationReview = ["Univ Confirmation Due", "University Acknowledged", "Financial doc Pending", "Application Review",
      "University Rejected", "Application Incomplete", "In Progress", "Deferred"]

    let TuitionDeposit = ["Deposit Due", "Deposit Due", "Conditional Admission", "Admitted", "I-20/Offer/CAS/COE"]
    let Visa = ["Visa Yet To Apply", " Visa Applied", "Visa Approved", "Visa Denied"]
    if (Filling.includes(val?.projecttaskstatus)) {
      _ActiveStepIndictor(0)
    }
    else if (ApplicationReview.includes(val?.projecttaskstatus)) {
      _ActiveStepIndictor(1)
    }
    else if (TuitionDeposit.includes(val?.projecttaskstatus)) {
      _ActiveStepIndictor(2)

    }
    else if (Visa.includes(val?.projecttaskstatus)) {
      _ActiveStepIndictor(3)
    } else {
      _ActiveStepIndictor(0)
    }
  }, [val])

  let Date = val?.app_end_date ? val?.app_end_date || null : val?.inter_adm_deadline || null
  let ApplicationFee = val?.international_app_fee ? val?.international_app_fee : 0
  return (
    <>
      <Box sx={{ width: "100%", margin: "auto", lebalColor: "orange", mb: 2, position: "relative" }}>
        {
          extra_condition === 'hide' ? '' :
        <Stepper activeStep={ActiveStepIndictor ? ActiveStepIndictor : 0} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={label}>
              <StepLabel icon={<div style={{ background: ActiveStepIndictor >= i ? "#00C953" : "#C5D8FA", borderRadius: 62, width: 28, height: 28, display: "flex", justifyContent: "center", color: "white" }}>{i + 1}</div>}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        }
           {
            extra_condition === 'hide' ? '' :
        <Box sx={{ mt: 0.3, ml: 1, position: "absolute", right: 32, top: 59 }}>
          <span onClick={() => handleOpenAndClose()}>
            <LazyLoadImage src={`${process.env.PUBLIC_URL}/img/SidebarIcon/chat.png`} style={{ height: 20, width: 20, marginTop: 5 }} />
            {/* <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.99328 10.5308V7.58478" stroke="#1B6CFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.99327 5.46956H7.99994" stroke="#1B6CFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8897 1.8335H5.11041C3.09641 1.8335 1.83374 3.2595 1.83374 5.2775V10.7228C1.83374 12.7408 3.09041 14.1668 5.11041 14.1668H10.8891C12.9097 14.1668 14.1671 12.7408 14.1671 10.7228V5.2775C14.1671 3.2595 12.9097 1.8335 10.8897 1.8335Z" stroke="#1B6CFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg> */}
          </span>

        </Box>
          }
      </Box>
      <Grid container>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <LazyLoadImage
            onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
            src={val?.university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${val?.university}.png` : ''}
            style={{
              backgroundColor: "#fff",
              padding: "7px",
              width: "80px",
            }}
            alt='trinity'
          />
          {
            extra_condition === 'hide' ? '' :
          <Typography
            sx={{
              fontWeight: "800",
              fontSize: "16px",
              ml: 2,
              display: "flex",
              color: "rgba(27, 108, 252)",
              paddingTop: 3
            }}
          >
            {val?.country ? getCurrencySymbol(val?.country) : ''}
            {val.international_tuition_fee_yr ? parseFloat(val.international_tuition_fee_yr).toFixed(0) : 0}
            /Yr
          </Typography>
          }
          {/* <img src={`${process.env.PUBLIC_URL}/img/college.png`} alt='College' /> */}
        </Grid>
        <Grid item xs={9} sx={{ pl: 1, position: "relative" }}>
          <Typography
            sx={{
              ml: 1, fontWeight: "bold", fontSize: "16px", mt: 1, width: "95%",
              height: "auto"
              // whiteSpace: "nowrap",
              // overflow: "hidden !important",
              // textOverflow: "ellipsis"
            }}
          >
            {val?.scholarship_name ? val?.scholarship_name : val?.university}
          </Typography>
          <Typography sx={{ ml: 1, fontSize: "14px" }}>
            {val?.program}
          </Typography>

          {/* <hr  color: '#dbedfa',  backgroundColor:"#fff" }} /> */}
          {/* <Divider style={{ maxWidth: "70%", marginRight: "25%", ml: 1 }} /> */}


          <Grid xs={12} item sx={{ borderTop: '1px solid #EBF2FF', width: "100%", display: "flex", paddingRight: 2 }}>
            <Typography sx={{ mt: 1, fontSize: "16px", fontWeight: "500" }}>
              {val?.funding_type ? val?.funding_type : val?.semester} &nbsp;
              
              {
            extra_condition === 'hide' ? 
            val?.proTaskYear :
              <span style={{ color: "red", fontWeight: "bold" }}> ⏰ {Date ? moment(Date).format('ll') : ""}</span>
              }
              {TYPE === "STATUS" && extra_condition !== 'hide'  && ApplicationFee > 0 ? <span style={{ float: "right", textAlign: "right", paddingLeft: 15 }}>Application Fee :   {val?.country ? getCurrencySymbol(val?.country) : ''} {Math.round(val?.international_app_fee ? val?.international_app_fee : 0)}</span> : ""}
            </Typography>
          </Grid>
            
          <Grid xs={12} item sx={{ display: "flex", mt: 0.5, alignItems: "center", paddingRight: 2 }}  >
            <Grid xs={12} item>
              {/* <Button variant="contained" sx={{ width: "100%", padding: "0px", color: "white", borderRadius: 3, fontWeight: "bold", fontSize: "12px", pl: 1, pr: 1, height: "20px", textTransform: "none", backgroundColor: stepColor }}> {ApplicationStatus}</Button> */}
              <Chip variant="filled" label={ApplicationStatus} sx={{color: "white",backgroundColor: stepColor}} size="small"/>
            </Grid>

            {
            extra_condition === 'hide' ? '' :
            <Grid xs={6} item style={{ position: "relative" }}>
              {/* <Typography style={{ position: "absolute", left: 30, zIndex: "9", fontSize: "12px", color: "#fff", fontWeight: "bold" }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography>
              <LinearProgress
                variant="determinate"
                color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)}
                value={val.success_rate ? val.success_rate : val.acceptance_rate ? val.acceptance_rate : 0}
                style={{ height: "18px", borderRadius: "50px", marginLeft: 10 }} /> */}
                <Typography style={{ position: "absolute", right: "18px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(val.success_rate ? val.success_rate : val.acceptance_rate)}%</Typography>
                <CircularProgress onClick={(e) => ApplyButtonHandler(e)} variant="determinate" color={barColor(val.success_rate ? val.success_rate : val.acceptance_rate)} value={val.success_rate ? val.success_rate : val.acceptance_rate} style={{ backgroundColor:"#edb28e", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />
            </Grid>
            }

          </Grid>
        </Grid>
      </Grid>

      {/* <ChatModal val={val} open={open} onClose={(handleOpenAndClose)} /> */}
    </>
  );
}

const useStyles = makeStyles(() => ({
  percentage: {
    width: "55%",
    backgroundColor: "#fc5602",
    borderRadius: "20px",
    paddingLeft: "20px",
    color: "#fff",
    height: "18px",
    fontSize: "14px",
    paddingTop: "2px",
  },
  containerPer: {
    backgroundColor: "#d1e9f9",
    borderRadius: "20px",
    width: "30%",
    height: "20px",
    marginLeft: "10px",
  },
  conforBtn: {
    textTransform: "none",
    backgroundColor: "#1b6cfc",
    color: "#fff",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "100",
    border: "none",
    float: "right",
    padding: "0px 5px",
    height: "20px",
  }
}));

