import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';
import { Bar, Doughnut } from "react-chartjs-2";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import chart1 from '../../../img/Associate/image/chart1.png';
import RegistratedCard from './RegistratedCard';
import './Registrated.css';
// import RegisterdData from "./RegisterdData"
import _ from 'lodash'
import whatsapp from '../../../img/whatsapp.png';


const Registrated = ({ RegistratedData, DataNameChange, employNameChanging, AssociateNameChanging, getString, TotalValue }) => {

    let _todaymodifiedArray = []
    _.map(Object.keys(RegistratedData), (d) => {
        if (`${d}`.replace('_todaymodified', " ") !== d) {
            _todaymodifiedArray.push(parseInt(RegistratedData[d]))
        }
    })

    let _todaymodified = _.sum(_todaymodifiedArray)

    delete RegistratedData.status




    return (
        <div className="mt-4 container register_section" style={{ borderRadius: 25, margin: 0, boxShadow: "0px 10px 20px rgba(0 0 0 / 0.4), 0px 2px 6px rgba(0 0 0 / 0.4), 0px 0px 1px rgba(0 0 0 / 0.4)" }}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingRight: 10 }}>
                <h3 className="student_title">Registered</h3>
                <a
                    href={getString()}
                >
                    <img alt="Whatsapp" src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                </a>
                <span style={{ fontSize: 20, fontWeight: "bold" }}>{TotalValue}</span>
            </div>
            <div className="students_anaylysics_section">
                <div className="">
                    <p className="normal_title">Spent this week</p>
                    <h4>$1,000</h4>
                    <h3 className="title"><ArrowUpwardIcon />$100</h3>
                </div>
                <div className="">
                    <Bar
                        data={{
                            labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
                            datasets: [
                                {
                                    label: "Register",
                                    backgroundColor: "#1B6CFC",
                                    borderColor: "#F2F4F5",
                                    borderRadius: 20,
                                    borderWidth: 5,
                                    data: [35, 35, 81, 22, 22],
                                },
                            ],
                        }}
                        height={200}
                        width={250}
                        options={{
                            maintainAspectRatio: false,
                        }}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-around student_budget">
                <div>
                    {/* <img src={chart1} alt="" /> */}
                    <div className="chart_1">
                        <Doughnut
                            data={{
                                datasets: [
                                    {
                                        data: [10, 10],
                                        backgroundColor: ["#1b6cfc", "#F2F4F5"],
                                    },
                                ],
                            }}
                            height={20}
                            width={20}
                            options={{
                                maintainAspectRatio: true,
                            }}
                        />
                    </div>
                </div>
                <div>
                    <h3 className="budget_text">Monthly Budget</h3>
                    <h4 className="budget_title">$140/day</h4>

                </div>
                <div>
                    <h3 className="budget_text">$1000 left</h3>
                    <h4 className="budget_title">of $2000</h4>
                </div>
            </div>

            <Tabs>
                <TabList className="register_tab_class my-3">
                    <Tab className="dashboard_category" style={{ width: "30%" }}> All </Tab>
                    <Tab className="dashboard_category" style={{ width: "35%" }}>Today follow up</Tab>
                    <Tab className="dashboard_category" style={{ width: "35%" }}>Missed follow up</Tab>
                </TabList>

                <TabPanel>
                    <RegistratedCard
                        name={'Total'}
                        trans={`${_todaymodified} transactions`}
                        dollar={TotalValue}
                        persent={`${Math.round(parseFloat(parseInt(TotalValue) * 100 / TotalValue))}%`}
                    />
                    {
                        // [...new Array(10)].map((res, index) => (
                        //     <RegistratedCard key={index} />
                        // ))
                        Object.keys(DataNameChange)?.map((register, id) => {
                            return <RegistratedCard
                                key={id}
                                name={AssociateNameChanging[register]}
                                dollar={`${RegistratedData[`${register}`]}`}
                                trans={`${RegistratedData[`${AssociateNameChanging[register]}_todaymodified`]} transactions`}
                                persent={`${Math.round(parseFloat(parseInt(RegistratedData[`${register}`]) * 100 / TotalValue))}%`}
                            />
                        })
                    }
                </TabPanel>
                {/* <TabPanel>
                    {
                        [...new Array(10)].map((res, index) => (
                            <RegistratedCard key={index} />
                        ))
                    }
                </TabPanel>
                <TabPanel>
                    {
                        [...new Array(10)].map((res, index) => (
                            <RegistratedCard key={index} />
                        ))
                    }
                </TabPanel> */}
            </Tabs>

        </div>
    );
};

export default Registrated;