import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {makeStyles} from "@mui/styles";
import { styled } from '@mui/material/styles';
import {Autocomplete, Button, TextField,FormControlLabel, Grid, Radio, RadioGroup, Typography, useRadioGroup} from "@mui/material";
import { OPERATION, operationValue } from '../../../Controller/Global';
import axios from 'axios';
import { useFormik } from 'formik';
import { getSessionId, getUserId, SESSION_ID, USER_ID } from '../../../Controller/localStorageHandler';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import AsyncSelect from 'react-select/async';
import {createFilter} from 'react-select';
import Chip from '@mui/material/Chip';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
      },
    }),
  );

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }
const SearchProgram = ({history}) => {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
          degreeType:'Masters',
          University:'',
        },
        onSubmit: (values) => {
            values[OPERATION]= operationValue.FetchProgramUniversityResult
            const formData= new FormData()
            for(let val in values){
                if(val === 'University'){
                    formData.append(val, universityValue)
                }
                else{
                    formData.append(val, values[val])
                }
            }
            formData.append(USER_ID,getUserId())
            formData.append(SESSION_ID,getSessionId())
            formData.append('page','1')
            setLoading(true)
            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
            .then(response => {
                // console.log(response)
                // return
                if(response.data.status==='Success'){
                    history.push({ pathname: '/UniversitySearchResult', state: {data: response.data, screen:"Program",page:JSON.stringify(Object.fromEntries(formData))}})
                }
            })
            .finally(() => {
                setLoading(false)
            })
        },
      });

    const [universityList, setUniversityList] = React.useState([]);
    const [universityValue, setuniversityValue] = React.useState('');
    const [loading,setLoading] = React.useState('');

      
      useEffect (()=>{ 
            const university = new FormData()
            university.append(OPERATION,operationValue.FetchUniversity)
            university.append(USER_ID,getUserId())
            university.append(SESSION_ID,getSessionId())
            university.append('search','UNIVER')
            university.append('degreeType',formik.values.degreeType)
            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
            .then((response)=>{
                setUniversityList(response.data.data)
                // console.log(response.data.data)
            })
    }, []);

    const promiseOptions = (inputValue) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (inputValue.length > 2)
                    setTimeout(async () => {
                        const university = new FormData()
                        university.append(OPERATION,operationValue.FetchUniversity)
                        university.append(USER_ID,getUserId())
                        university.append(SESSION_ID,getSessionId())
                        university.append('search',inputValue)
                        university.append('degreeType',formik.values.degreeType)
                        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
                        .then((response)=>{
                            console.log(response)
                            let SelectData = []
                            if(Array.isArray(response?.data?.data)){
                                response?.data?.data?.map((data) => {
    
                                    let temp = {
                                        "label": `${data}`,
                                        "value": `${data}`
                                    }
                                    SelectData.push(temp)
                                })
                            }
                            else{
                                let temp = {
                                    "label": `${response?.data?.data}`,
                                    "value": `${response?.data?.data}`
                                }
                                SelectData.push(temp)
                            }
                            
                            resolve(SelectData)
                        })
                    }, 3000);
            }
            catch (error) {
                reject(error)
            }
        })
    }


    if(loading){
        return( <div style={{textAlign: 'center', marginTop:'55%'}}>
            <CircularProgress />
            </div>)
    }else{
        return (
        <>
            <p style={{fontSize:'14px',textAlign:'center',fontWeight:'bold',margin: '11px 0 -13px 0'}}> <Chip label="Program" variant="filled" color="primary" /></p>
            <hr />
            <form onSubmit={formik.handleSubmit}  className={classes.cardBox}>
                {/* <FormControl sx={{m: 1, minWidth: "100%"}}>
                    <InputLabel id="demo-simple-select-helper-label">Select course</InputLabel>
                    <Select 
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Select course"
                        name="degreeType"
                        value={formik.values.degreeType}
                        onChange={formik.handleChange}

                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'diploma'}>Diploma</MenuItem>
                        <MenuItem value={'bachelors'}>Bachelors</MenuItem>
                        <MenuItem value={'Masters'}>Masters</MenuItem>
                        <MenuItem value={'phd'}>PHD</MenuItem>
                    </Select>
                </FormControl> */}
                <FormControl component="fieldset" sx={{m: 1, minWidth: "100%"}}>
                <RadioGroup
                    className={classes.radioGroup} 
                    row 
                    name="degreeType"
                    value={formik.values.degreeType}
                    onChange={formik.handleChange}
                >
                    <MyFormControlLabel
                        className={"radioLabel"}
                        value="Diploma"
                        label="Diploma"
                        control={<Radio className={"radioRadioMain"}/>}
                    />
                    <MyFormControlLabel
                        className={"radioLabel"}
                        value="Bachelors"
                        label="Bachelors"
                        control={<Radio className={"radioRadioMain"}/>}
                    />
                    <MyFormControlLabel
                         className={"radioLabel"}
                        value="Masters"
                        label="Masters"
                        control={<Radio className={"radioRadioMain"}/>}
                    />
                    <MyFormControlLabel
                        className={"radioLabel"}
                        value="Phd"
                        label="Phd"
                        control={<Radio className={"radioRadioMain"}/>}
                    />
                </RadioGroup>

            </FormControl>
                <FormControl sx={{m: 1, minWidth: "100%"}}>
                    {/* <InputLabel size={'small'} id="demo-simple-select-helper-label">Select University Name</InputLabel>
                    <Select 
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="University"
                        value={formik.values.University}
                        onChange={formik.handleChange}
                        size={'small'} 
                    >
                        {universityList && universityList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                    </Select> */}
                    <AsyncSelect
                    filterOption={createFilter({ignoreAccents: false})}
                    onChange={({value})=>setuniversityValue(value)}
                    cacheOptions
                    defaultOptions
                    placeholder="University"
                    loadOptions={promiseOptions}
                    className="DropDown"
                />
                    {/* <Autocomplete
                        name='University'
                        id="free-solo-demo"
                        freeSolo
                        // multiple
                        value={universityValue}
                        onChange={(e,val)=>setuniversityValue(val)}
                        options={universityList && universityList .map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Select University" />}
                    /> */}
                </FormControl>
                <Grid xs={12} className={classes.gridFlex} style={{marginBottom: '10px', marginTop: '5px'}}>
                    <Button 
                        className={classes.resetBtn}
                    >
                        Reset
                    </Button>
                    <Button 
                        // onClick={()=>history.push('/ProgrammerSearchResult')}
                        type="submit"
                        className={classes.submitBtn} 
                    >
                        Search
                    </Button>
                </Grid>
            </form>
            <Grid className={classes.sxGrid_1Top}>
                <Grid className={classes.sxGrid_3}>
                    <Button sx={{
                        padding: 0,
                    }}
                            onClick={()=>history.push('/SearchScholarship')}
                    >
                        <img src={`${process.env.PUBLIC_URL}/img/DashBoard/scholarship.png`} alt="web search"
                             width="80%"/>
                    </Button>
                    <Button onClick={()=>history.push('/SearchScholarship')} variant="text" disableElevation className={classes.viewBtn}>View</Button>
                </Grid>
                <Grid className={classes.sxGrid_3}
                >

                    <Button
                        sx={{
                            padding: 0,
                        }}
                        onClick={()=>history.push('/SearchSop')}
                    >
                        <img src={`${process.env.PUBLIC_URL}/img/DashBoard/sop.png`} alt="web search" width="80%"/>
                    </Button>
                    <Button onClick={()=>history.push('/SearchSop')}variant="text" disableElevation className={classes.viewBtn}>View</Button>

                </Grid>
                <Grid
                    className={classes.sxGrid_3}
                >

                    <Button
                        sx={{
                            padding: 0,
                        }}
                        onClick={()=>history.push('/SearchUniversity')}
                    >
                        <img src={`${process.env.PUBLIC_URL}/img/DashBoard/web.png`} alt="web search" width="80%"/>
                    </Button>
                    <Button
                        className={classes.viewBtn}
                        onClick={()=>history.push('/SearchUniversity')}
                        variant="text" disableElevation>View</Button>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    fontSize: "16px",
                    display: "flex",
                    textAlign: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                    justifyContent: "space-around",
                }}
            >
                <Typography sx={{
                    fontSize: "16px",
                    lineHeight: '20px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    color: '#1A1A1A'
                }}> Search <br/> Scholarship </Typography>
                <Typography sx={{
                    fontSize: "16px",
                    lineHeight: '20px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    color: '#1A1A1A'
                }}> Search <br/> SOP </Typography>
                <Typography sx={{
                    fontSize: "16px",
                    lineHeight: '20px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    color: '#1A1A1A'
                }}> Search <br/> University </Typography>
            </Grid>
        </>
    )}
};

export default withRouter(SearchProgram)
const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));