import React from 'react'


const ChatCard = (props) => {
    console.log("-=-=-=-=-=", props)
    return (
        <div className={`chat-screen-Sender__Main`}>
            {
                props.sender ?
                    <div className="chat-screen-Sender__Main__Wrapper" style={{
                        display: "flex", paddingTop: "10px",
                        justifyContent: "flex-end"
                    }}>
                        <div>
                            <div style={{
                                background: "#1B6CFC",
                                boxShadow: "0px 5px 21px rgba(28, 29, 90, 0.03)",
                                borderRadius: "12px 12px 29px 12px",
                                fontFamily: "Gilroy",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontsize: "8px",
                                lineHeight: "144.5%",
                                color: "#ffffff",
                                display: "flex",
                                justifyContent: "center",
                                paddingLeft: "16px",
                                paddingRight: "24px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                maxWidth: 267
                            }}>
                                {props.commentcontent}
                            </div>
                            <div style={{
                                paddingLeft: "13px",
                                paddingTop: "4px",
                                fontFamily: "Gilroy",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "10px",
                                lineHeight: "13px",
                                color: "rgba(28, 29, 90, 0.5)",
                                textAlign: "end"
                            }}>
                                {"time"}
                            </div>
                        </div>
                    </div> : <div className="chat-screen-Reciver__Main__Wrapper" style={{
                        display: "flex", paddingTop: "10px",
                        justifyContent: "flex-start"
                    }}>
                        <div>
                            <div style={{
                                background: "#DCE8FF",
                                boxShadow: "0px 5px 21px rgba(28, 29, 90, 0.03)",
                                borderRadius: "9px 9px 9px 29px",
                                fontFamily: "Gilroy",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontsize: "8px",
                                lineHeight: "144.5%",
                                color: "#ffffff",
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: "16px",
                                paddingRight: "24px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                maxWidth: 267
                            }}
                            >
                                {props.commentcontent}
                            </div>
                            <div style={{
                                paddingLeft: "13px",
                                paddingTop: "4px",
                                fontFamily: "Gilroy",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "10px",
                                lineHeight: "13px",
                                color: "rgba(28, 29, 90, 0.5)",
                            }}>
                                {"time"}
                            </div>
                        </div>
                    </div>
            }


        </div >
    )
};



export default ChatCard;
