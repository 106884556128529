import React from "react";
import { makeStyles } from "@mui/styles";
import PageTitle from "../PageTitle";
import Colors from "../../static/layout/Colors";
import ShareIcon from "@mui/icons-material/Share";
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";

function CardSlider() {
    return null;
}

const ServicesDetails = () => {
    const history = useHistory()
    const classes = useStyles();
    console.log("history", history.location.state.serviceid)
    return (
        <div className="global-container mt-3 p-0">
            <Button sx={{ minWidth: '0px', paddingLeft: '0px' }} onClick={() => history.goBack()}><ArrowBackIcon /></Button>
            <div className={classes.topHeadingSorter}>
                <div className={classes.customTag}>$ 1200</div>
            </div>
            <div style={{ marginBottom: '0' }} className={classes.topHeadingSorter}>
                <PageTitle pageTitle={`Premium Pack Pack Name`} bold />
            </div>
            <div style={{ position: 'relative' }} className={classes.topHeadingSorter}>
                <div className={classes.shareIconDiv}>
                    <ShareIcon sx={{
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                        padding: '7px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
                    }} />
                </div>
                <p style={{
                    color: '#666666',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: 'normal'
                }}>
                    Upto 1 Universities
                </p>
            </div>
            <div style={{
                minHeight: '0',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
            }} className={classes.eventCard}>
                <div style={{ marginTop: '5px', marginBottom: '5px' }} className={classes.container2}>
                    <div>
                        <PageTitle bold pageTitle={`Countries`} />
                    </div>
                </div>
                <div style={{
                    paddingBottom: '5px'
                }} className={classes.container3}>
                    <main className={classes.joined}>UK, Ireland, Australia, New Zealand, France</main>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginBottom: '20px', width: '100%' }}>
                <div className={classes.cardMainCountries}>
                    <div className={classes.cardDetails}>
                        <div className={classes.cardMain}>
                            <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/profile-analysis.png`} />
                        </div>
                        <p>Profile Analysis</p>
                    </div>
                    <div className={classes.cardDetails}>
                        <div className={classes.cardMain}>
                            <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/Shortlisting.png`} />
                        </div>
                        <p>Shortlisting</p>
                    </div>
                    <div className={classes.cardDetails}>
                        <div className={classes.cardMain}>
                            <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/document-preparation.png`} />
                        </div>
                        <p>Document
                            Preparation</p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'inline-block', marginBottom: '20px', width: '100%' }}>
                <p style={{
                    color: '#666666',
                    fontWeight: "normal",
                    fontSize: '16px',
                    lineHeight: '20px',
                    textAlign: 'justify',
                }}>Totam rem aperiam, eaque ipsa quae ab illo inventored ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, veritatis et quasi architecto beatae vitae dicta sunt
                    explicabo.</p>
            </div>
            <div style={{ display: 'inline-block', marginBottom: '20px', width: '100%' }}>
                <PageTitle pageTitle={`Main Specialties`} bold />
            </div>
            <div style={{ display: 'inline-block', marginBottom: '20px', width: '100%' }}>
                <div style={{
                    padding: '10px 15px',
                    width: '100%',
                    minHeight: '0',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
                }} className={classes.eventCard}>
                    <div style={{ marginTop: '5px', marginBottom: '5px' }} className={classes.container2}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img className={classes.img} alt={''} src={`${process.env.PUBLIC_URL}/img/documents/Tick-Square.png`} />
                            <p style={{ marginBottom: '0' }}>Internal Medicine</p>
                        </div>
                        <div style={{
                            padding: '10px 18px'
                        }} className={classes.customTag}>View</div>
                    </div>
                </div>
                <div style={{
                    padding: '10px 15px',
                    width: '100%',
                    minHeight: '0',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
                }} className={classes.eventCard}>
                    <div style={{ marginTop: '5px', marginBottom: '5px' }} className={classes.container2}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img className={classes.img} alt={''} src={`${process.env.PUBLIC_URL}/img/documents/Tick-Square.png`} />
                            <p style={{ marginBottom: '0' }}>Pediatrician</p>
                        </div>
                        <div style={{
                            padding: '10px 18px'
                        }} className={classes.customTag}>View</div>
                    </div>
                </div>
                <div style={{
                    padding: '10px 15px',
                    width: '100%',
                    minHeight: '0',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
                }} className={classes.eventCard}>
                    <div style={{ marginTop: '5px', marginBottom: '5px' }} className={classes.container2}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img className={classes.img} alt={''} src={`${process.env.PUBLIC_URL}/img/documents/Tick-Square.png`} />
                            <p style={{ marginBottom: '0' }}>Cardiology</p>
                        </div>
                        <div style={{
                            padding: '10px 18px'
                        }} className={classes.customTag}>View</div>
                    </div>
                </div>
            </div>
            <div className={classes.topHeadingSorter}>
                <PageTitle pageTitle={`Featured Doctors`} bold />
                <div>See all</div>
            </div>
            <div className={classes.topHeadingSorter}>
                <CardSlider />
            </div>
        </div>
    );
};

export default ServicesDetails;

const useStyles = makeStyles((theme) => ({
    eventCard: {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        minHeight: '350px',
        padding: '18px 15px',
        marginBottom: '20px'
    },
    container2: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
    },
    container3: {
        marginTop: "2%",
    },
    joined: {
        fontWeight: "normal",
    },
    img: {
        height: "24px",
        width: "24px",
        marginRight: "15px",
    },

    topHeadingSorter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    sortBtn: {
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        color: '#1A1A1A',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 'normal',
        padding: '10px'
    },
    inviteMore: {
        border: '0',
        color: '#ffffff',
        borderRadius: '52px',
        backgroundColor: '#1B6CFC',
        boxShadow: ' 0px 4px 19px rgba(27, 108, 252, 0.21)',
        width: '100%',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 'bold',
        marginTop: "20px",
        marginBottom: "10px",
        padding: '18px 0'
    },
    input: {
        borderColor: "transparent",
        outline: "none",
        marginLeft: "10px",
        fontSize: "16px",
        width: "80%",
        display: "flex",
        opacity: 0.7,
        letterSpacing: 0.7,
    },
    customTag: {
        color: Colors.blue,
        fontSize: '16px',
        backgroundColor: '#DCE8FF',
        borderRadius: '38px',
        fontWeight: '700',
        lineHeight: '20px',
        padding: '16px 24px'
    },
    shareIconDiv: {
        position: 'absolute',
        top: '-25px',
        right: '0'
    },
    cardMainCountries: {
        display: 'inline-block',
        marginTop: '15px'
    },
    cardMain: {
        width: '116px',
        height: '116px',
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '15px',
    },
    cardDetails: {
        textAlign: 'center',
        width: '33.33%',
        float: 'left'
    },
}));
