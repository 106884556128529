import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { withRouter } from 'react-router-dom';
import { OPERATION, operationValue } from "../../../../Controller/Global";
import axios from "axios";
import Input from '@mui/material/Input';
import { SESSION_ID, setSessionId, setUser, setUserId, USER_ID, setUserType, getUserType, getFCM_Token } from "../../../../Controller/localStorageHandler";
// import { signIn } from "../../../../Controller/api_functions/authFunction";
// import axios from "axios";
import FormHelperText from '@mui/material/FormHelperText';
import { useHistory } from "react-router-dom";
import useAppContext from "../../../../useContext/userContext"
import PhoneInput from "react-phone-input-2";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import OtpInput from 'react18-input-otp';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignInForm = (props) => {
  const history = useHistory()
  const return_url = history?.location?.state?.return_url
  const { setSessionInvalid } = useAppContext()
  let [values, handleValues] = useState({});
  let [validate, handleValidate] = useState(false);
  let [error, setError] = useState('');
  let [success, setSuccess] = useState('');
  let [portalErr, setPortalErr] = useState('');
  const [email, setEmail] = useState('');
  const [customer_id, setCustomer_id] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const [contact_no, setContact_no] = useState('')

  const [open, setOpen] = React.useState(false);

  const [showOtpBox, setShowOtpBox] = useState(false);

  const [otp, setOtp] = useState('');


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getOtp = async () => {
    setLoading(true)
    const formData = new FormData()

    formData.append('operation', 'sign_in_otp');
    formData.append('phone_mobile', contact_no);


    await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
      .then(response => {
        if (response?.data?.status === 'error') {
          setLoading(false)
          Swal.fire({
            title: response?.data?.msg,
            showCancelButton: true,
            cancelButtonText: 'Try Again !',
            confirmButtonText: 'Sign Up',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              history.push('/SignUp')
            }
          })
        }
        else if (response?.data?.status === 'success') {
          setSuccess('OTP has been sent to ' + contact_no + ' kindly check Whatsapp Or Text sms app')
          setShowOtpBox(true)
          setLoading(false)
        }

      })

  }

  const verifyOtp = async () => {
    setLoading(true)
    if (otp?.length > 3) {
      setError('')
      setSuccess('')
      const formData = new FormData()

      formData.append('operation', 'signin_with_mobile');
      formData.append('phone_mobile', contact_no);
      formData.append('otp', otp)

      await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
        .then(response => {
        // console.log(response);
        
        if (response?.data?.portal?.portal === '0') {
          setEmail(response?.data?.portal?.email);
          setCustomer_id(response?.data?.portal?.customerid);
          // console.log(response?.data?.portal?.portal)
          setPortalErr('Your account is deactivated.. would you like to activate..')
          return
        }
        if (response.data.status === 'success') {
          setSuccess(response.data.msg)
          setSessionId(response.data.sessionid)
          setUserId(response.data.id)
          const profile = new FormData()
          profile.append(OPERATION, operationValue.FetchProfile)
          profile.append(SESSION_ID, response.data.sessionid)
          profile.append(USER_ID, response.data.id)
          axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', profile).then((res) => {
            
          // console.log(res)
          
            if (res.statusText === "OK") {
              let UserData = res?.data
              setUser(res.data)
              // console.log(res?.data)
              let latitudeAndlongitude = JSON.parse(localStorage.getItem('loaction') || `{}`)
              axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitudeAndlongitude?.latitude}&longitude=${latitudeAndlongitude?.longitude}&localityLanguage=en`).then((Data) => {
              // console.log(Data)  
              let NewData = Data?.data?.localityInfo?.administrative || ''
                if (NewData.length > 3 && UserData) {
                  // console.log("res.data", res.data)
                  // console.log(NewData)
                  // console.log("NewData", NewData[2].name, NewData[1].name, NewData[0].name)
                  UserData.mailingcity = NewData[2].name
                  UserData.mailingstate = NewData[1].name
                  UserData.mailingcountry = NewData[0].name
                  UserData.id = response.data.id
                  UserData.latitude = Data?.data?.latitude
                  UserData.longitude = Data?.data?.longitude
                  if (getFCM_Token()) {
                    UserData.fcm_token = getFCM_Token()
                  }
                  // console.log(JSON.stringify(UserData));
                  let config = {
                    url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
                    data: JSON.stringify(UserData),
                    method: 'post'
                  }
                  // debugger
                  axios(config).then((res) => {
                    
                    // console.log(res)

                    setUser(UserData)

                  }).catch((error) => {
                    // debugger

                  })
                }
              }).catch((err) => {
                // console.log("err", err);

              })

              let user_contact_type = res.data?.user_contact_type || null
              setUserType(user_contact_type)

              if (return_url) {
                props.history.push(return_url)
              }
              else if (user_contact_type === "Accommodation") {
                props.history.push('/accommodationDashboard')
              }
              else if (user_contact_type === "University") {
                props.history.push('/universityDashboard')
              }
              else if (user_contact_type === "Partner") {
                props.history.push('/educationDashboard')
              }
              else if (user_contact_type === "Internship/Job") {
                props.history.push('/internshipDashboard')
              }
              else if (user_contact_type === "Employee" || user_contact_type === "Associate" || user_contact_type === "Associate Admin") {
                props.history.push('/associates')
              } else {
                props.history.push('/dashboard')
              }
            } else {
              setError(res.msg)
            }
          })
        }
        else {
          setError(response.data.msg)
        }
        })
    }
    else {
      setSuccess('')
      setError('OTP should be 4 digit')
      setLoading(false)
    }

  }

  const handleSubmit = () => {
    handleValidate(true);
    if (values.username && values.password) {
      values[OPERATION] = operationValue.SIGNIN
      const formData = new FormData()
      for (let val in values) {
        formData.append(val, values[val])
      }
      axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', formData).then((response) => {
        // console.log(response);
        if (response?.data?.portal?.portal === '0') {
          setEmail(response?.data?.portal?.email);
          setCustomer_id(response?.data?.portal?.customerid);
          // console.log(response?.data?.portal?.portal)
          setPortalErr('Your account is deactivated.. would you like to activate..')
          return
        }
        if (response.data.status === 'success') {
          setSuccess(response.data.msg)
          setSessionId(response.data.sessionid)
          setUserId(response.data.id)
          const profile = new FormData()
          profile.append(OPERATION, operationValue.FetchProfile)
          profile.append(SESSION_ID, response.data.sessionid)
          profile.append(USER_ID, response.data.id)
          axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', profile).then((res) => {

            if (res.statusText === "OK") {
              let UserData = res?.data
              setUser(res.data)
              // console.log(res?.data)
              let latitudeAndlongitude = JSON.parse(localStorage.getItem('loaction') || `{}`)
              axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitudeAndlongitude?.latitude}&longitude=${latitudeAndlongitude?.longitude}&localityLanguage=en`).then((Data) => {
                let NewData = Data?.data?.localityInfo?.administrative || ''
                if (NewData.length > 3 && UserData) {
                  // console.log("res.data", res.data)
                  // console.log(NewData)
                  // console.log("NewData", NewData[2].name, NewData[1].name, NewData[0].name)
                  UserData.mailingcity = NewData[2].name
                  UserData.mailingstate = NewData[1].name
                  UserData.mailingcountry = NewData[0].name
                  UserData.id = response.data.id
                  UserData.latitude = Data?.data?.latitude
                  UserData.longitude = Data?.data?.longitude
                  if (getFCM_Token()) {
                    UserData.fcm_token = getFCM_Token()
                  }
                  // console.log(JSON.stringify(UserData));
                  let config = {
                    url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
                    data: JSON.stringify(UserData),
                    method: 'post'
                  }
                  // debugger
                  axios(config).then((res) => {
                    // debugger
                    // console.log(res)

                    setUser(UserData)

                  }).catch((error) => {
                    // debugger

                  })
                }
              }).catch((err) => {
                // console.log("err", err);

              })

              let user_contact_type = res.data?.user_contact_type || null
              setUserType(user_contact_type)

              if (return_url) {
                props.history.push(return_url)
              }
              else if (user_contact_type === "Accommodation") {
                props.history.push('/accommodationDashboard')
              }
              else if (user_contact_type === "University") {
                props.history.push('/universityDashboard')
              }
              else if (user_contact_type === "Partner") {
                props.history.push('/educationDashboard')
              }
              else if (user_contact_type === "Internship/Job") {
                props.history.push('/internshipDashboard')
              }
              else if (user_contact_type === "Employee" || user_contact_type === "Associate" || user_contact_type === "Associate Admin") {
                props.history.push('/associates')
              } else {
                props.history.push('/dashboard')
              }
            } else {
              setError(res.msg)
            }
          })
        }
        else {
          setError(response.data.msg)
        }
      })
        .finally(() => {

        })
    }
  }
  const handleChange = (e) => {
    handleValues(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }
  const activate_acc = async () => {
    const formData = new FormData()
    // values[OPERATION]=operationValue.ForgotPassword
    formData.append('operation', 'forgotPassword');
    formData.append('username', email);
    formData.append('type', 'activate');
    formData.append('customer_id', customer_id);

    await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
      .then(response => {
        // console.log(response)
        if (response.data.status === 'success') {
          setPortalErr('');
          setSuccess('Your account has been activated  New Password is been sent to your email')
          setTimeout(function () { history.push('/login') }, 5000)
        } else {
          setError(response.data.msg)
        }
      })
  }

  useEffect(() => {
    setSessionInvalid(false)
    const user_type = getUserType();
    if (user_type === "Accommodation") {
      props.history.push('/accommodationDashboard')
    }
    else if (user_type === "University") {
      props.history.push('/universityDashboard')
    }
    else if (user_type === "Partner") {
      props.history.push('/educationDashboard')
    }
    else if (user_type === "Internship/Job") {
      props.history.push('/internshipDashboard')
    }
    else if (user_type === "Employee") {
      props.history.push('/associates')
    }
    else if (user_type === "Associate") {
      props.history.push('/associates')
    }
    else if (user_type === 'Student') {
      props.history.push('/dashboard')
    }
    else {

    }
  }, [])

  return (
    <>
      {portalErr &&

        <Typography
          sx={{ color: "blue", textAlign: "center", fontSize: "15px", m: 1 }}
        >
          <Alert severity="error">{portalErr} <Button onClick={() => activate_acc()}>Acivate my account.</Button></Alert>


        </Typography>
      }
      {error && <Alert severity="error" >{error}</Alert>}
      {success && <Alert severity="success" >{success}</Alert>}
      <form>
        {/*<Typography*/}
        {/*  sx={{*/}
        {/*    m: 2,*/}
        {/*    color: "#082e40",*/}
        {/*    fontWeight: "600",*/}
        {/*    fontSize: "14px",*/}
        {/*    my: 1,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  USER NAME*/}
        {/*</Typography>*/}
        <Grid container sx={{ justifyContent: "center", marginY: 3 }}>
          <Grid
            item
            xs={11}
            sx={{
              // display: "flex",
              // justifyContent: "center",
              // textAlign: "start",
              borderRadius: "100px",
              boxShadow: 5,
              padding: "10px",
            }}
          >
            {/* <img 
            src={`${process.env.PUBLIC_URL}/img/signIcons/Mail.png`}
          alt='Mail'
          style={{ marginTop: "2px" }} /> */}
            <Input
              placeholder="Enter Email / Mobile Number with country code"
              style={{ width: "96%", marginLeft: "10px" }}
              type="email"
              name={'username'}
              error={validate && !values.username}
              onChange={handleChange}
            />

            {/* <input
            type="email"
            name={'username'}
            onChange={handleChange}
            className={classes.input}
            autoFocus
            placeholder="Email@gmail.com"
            required={true}
          /> */}
          </Grid>
          {validate && !values.username ? <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please Enter Email/Username/ Mobile Number with country code</FormHelperText> : ''}
        </Grid>

        {/*<Typography*/}
        {/*  sx={{*/}
        {/*    mx: 2,*/}
        {/*    color: "#082e40",*/}
        {/*    fontWeight: "600",*/}
        {/*    fontSize: "14px",*/}
        {/*    my: 1,*/}
        {/*    mt:3,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  PASSWORD*/}
        {/*</Typography>*/}

        <Grid container sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={11}
            sx={{
              // display: "flex",
              // justifyContent: "center",
              // textAlign: "start",
              borderRadius: "100px",
              boxShadow: 5,
              padding: "10px",
            }}
          >
            {/* <img 
            src={`${process.env.PUBLIC_URL}/img/signIcons/lockIcon.png`}
            alt='lockIcon'
             style={{ marginTop: "2px" }} /> */}
            <Input
              placeholder="Enter Password"
              style={{ width: "96%", marginLeft: "10px" }}
              type="password" name={'password'}
              error={validate && !values.password}
              onChange={handleChange}
            />
          </Grid>
          {validate && !values.password ? <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please Enter password</FormHelperText> : ""}
        </Grid>
        <Grid xs={12} >

          <Typography
            sx={{ color: "blue", textAlign: "right", fontSize: "15px", m: 1 }}
          >

            <Button onClick={() => props.history.push('/forgetPassword')}>Forget Password?</Button>
          </Typography>
        </Grid>
        <Grid xs={11} sx={{ margin: "auto", textAlign: 'center' }}>
          <Button
            onClick={handleSubmit}
            sx={{
              fontSize: "14px",
              fontWaight: "600",
              p: "7px",
              color: "#fff",
              backgroundColor: "#1b6cfc",
              my: 2,
              borderRadius: "100px",
              width: "65%",

            }}
          >
            Sign In
          </Button>
          <Typography>OR</Typography>
          <Button
            onClick={handleClickOpen}
            sx={{
              fontSize: "14px",
              fontWaight: "600",
              p: "7px",
              color: "#fff",
              backgroundColor: "#1b6cfc",
              my: 2,
              borderRadius: "100px",
              width: "65%",

            }}
          >
            Sign In with OTP
          </Button>

          <Typography
            sx={{ fontSize: "14px", color: "#000", textAlign: "center" }}
            onClick={() => props.history.push('/SignUp')}
          >
            Don't have an account? -
            <strong className={classes.Sign}>Sign Up</strong>
          </Typography>
        </Grid>
      </form>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {
          !loading ?
            showOtpBox
              ?
              <DialogTitle>{`Enter OTP`}</DialogTitle>
              :
              <DialogTitle>{"Your Mobile number."}</DialogTitle>
            :
            ''
        }

        {
          loading ?
            <Box sx={{ display: 'flex', m: 5 }}>
              <CircularProgress />
            </Box>
            :
            <DialogContent>
              {error && <Alert severity="error" sx={{ mb: 1 }}>{error}</Alert>}
              {success && <Alert severity="success" sx={{ mb: 1 }}>{success}</Alert>}

              {
                showOtpBox ?
                  <OtpInput inputStyle={{ margin: 'auto', textAlign: 'center', padding: '5px', width: '50%', borderRadius: '5px' }} value={otp} onChange={(enteredOtp) => setOtp(enteredOtp)} numInputs={4} />
                  : <PhoneInput
                    country={'in'}
                    name='phone_mobile'
                    placeholder="Enter Number"
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                      borderRadius: "0",
                      opacity: "0.8"
                    }}
                    //   value={phone}
                    searchPlaceholder={'Mobile Number'}
                    inputClass={"mobile-field"}
                    onChange={(phone) => {
                      setContact_no(phone)
                      //   handleChange()
                    }}
                  />
              }



            </DialogContent>
        }
        {
          loading
            ?
            ''
            :

            <DialogActions>
              {
                showOtpBox ?
                  <Button onClick={verifyOtp}>Verify OTP</Button>
                  :
                  <>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={getOtp}>Get OTP</Button>
                  </>
              }

            </DialogActions>
        }
      </Dialog>
    </>
  );
};
export default withRouter(SignInForm);

const useStyles = makeStyles(() => ({
  Sign: {
    color: "#1b6cfc",
    fontSize: "14px",
  },
  input: {
    borderColor: "transparent",
    outline: "none",
    marginLeft: "10px",
    fontSize: "16px",
    width: "80%",
    display: "flex",
    opacity: 0.7,
    letterSpacing: 0.7,
  },
  filterIcon: {
    position: "relative",
    top: 10,
    height: "75px",
    width: "75px",
  },
}));
