import { ArrowDownward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getUser,getSessionId,getUserId } from "../../Controller/localStorageHandler";
import addMony from "../../static/img/Associate/icons/addMoney.png";
import more from "../../static/img/Associate/icons/more.png";
import sendMony from "../../static/img/Associate/icons/sendMoney.png";
import logo1 from "../../static/img/Associate/image/logo1.png";
import usLogo from "../../static/img/Associate/image/usLogo.png";
import {getEducationVendorData, GetApplicationCountDataAction, getAssociateStatusProjecttCountAction, getAssociateStudentListCountStatusAction } from "../Action/AssociateDocumentList";
import BottomNav from "../Common/BottomNav";
// import BottomNav from "../../pages/inc/BottomNav";
import Analysics from "./Analysics/Analysics";
import Applications from "./Applications/Applications";
import "./Dashboard.css";
import Registrated from "./Registrated/Registrated";
import Students from "./Students/Students";
import _ from 'lodash'
import ContentLoader from "react-content-loader";
import { AddStudentModal } from "../Student/StudentList/AddStudentModal";
import moment from "moment";

let Data = {
  "Admitted_count": "Admitted",
  "Application Fee Pending_count": "Application Fee Pending",
  "Application Incomplete_count": "Application Incomplete",
  "Application Review_count": "Application Review",
  "Conditional Admission_count": "Conditional Admission",
  "Eligibility Checking_count": "Eligibility Checking",
  "I-20/Offer/CAS/COE_count": "I-20/Offer/CAS/COE",
  "Info/Doc Pending_count": "Info/Doc Pending",
  "Portal Not Opened_count": "Portal Not Opened",
  "Ready for Submission_count": "Ready for Submission",
  "Tuition Deposit_count": "Tuition Deposit",
  "University Rejected_count": "University Rejected",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
  "Visa Denied_count": "Visa Denied",
  "Visa Yet To Apply_count": "Visa Yet To Apply",
  "Yet to Fill_count": "Yet to Fill"
}

let AssociateNameChanging = {
  "Tuition Deposit_count": "Tuition Deposit",
  "Shortlisting_count": "Shortlisting",
  "Filling_count": "Filling",
  "Completed_count": "Completed",
  "Application Review_count": "Application Review",
  "Profile Analysis_count": "Profile Analysis",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
  "Visa closed_count": "Visa Denied",
  "Visa Yet To Apply_count": "Visa Yet To Apply"
}

let employNameChanging = {
  "Profile Analysis_count": "Profile Analysis",
  "Shortlisting_count": "Shortlisting",
  "Filling_count": "Filling",
  "Application Review_count": "Application Review",
  "Tuition Deposit_count": "Tuition Deposit",
  "Visa Yet To Apply_count": "Visa Yet To Apply",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
}


const Associate = () => {
  let UserLocalstorge = getUser()
  let user_contact_type = UserLocalstorge?.user_contact_type || ''

  const [getListCountByData, setGetListCountByData] = useState([])

  const [eductionListCountData, setEductionListCountData] = useState([])

  const [StudenLoader, setStudenLoader] = useState(false)
  const [RegisteredLoader, setRegisteredLoader] = useState(false)
  const [ApplicationsLoader, setApplicationsLoader] = useState(false)
  const [open, SetOpen] = React.useState(false)


  const AssociateStudentListCountStatus = () => {
    setStudenLoader(true)
    let ProfileData = getUser()
    var data = new FormData();
    data.append('contact_no', ProfileData?.contact_no || '');

    getAssociateStudentListCountStatusAction(data).then((response) => {
      let Order = _.orderBy(_.map(response?.count_data || [], (d) => {
        d.count = parseInt(d.count)
        return d
      }), ['count'], 'desc')
      setStudenLoader(false)
      setGetListCountByData(Order)
    }).catch((error) => {
      setStudenLoader(false)
      setGetListCountByData([])
    })
  }




  const educationVendorData = () =>{
    let ProfileData = getUser()
    let userId = getUserId()
    let sessionId = getSessionId()
    var data1 = `{"contact_no":"${ProfileData?.contact_no || ''}","bypass":"validation","sessionid":"${sessionId}","userid":"${userId}"}`;
    getEducationVendorData(data1).then((response) =>{
      setEductionListCountData(response?.data)
      console.log(response)
    })
  }


  useEffect(() => {
    AssociateStudentListCountStatus()

    educationVendorData()
  }, [])

  if (StudenLoader || RegisteredLoader || ApplicationsLoader) {
    return (<ContentLoader height={window.innerHeight} width={window.innerWidth} viewBox="0 0 265 230">
      <rect x="15" y="15" rx="4" ry="4" width="200" height="25" />
      <rect x="15" y="50" rx="2" ry="2" width="40" height="15" />
      <rect x="75" y="45" rx="16" ry="16" width="55" height="22" />
      <rect x="15" y="75" rx="3" ry="3" width="215" height="15" />
      <rect x="15" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="75" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="135" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="15" y="135" rx="16" ry="16" width="55" height="22" />
      <rect x="15" y="165" rx="2" ry="2" width="150" height="50" />
      <rect x="215" y="180" rx="2" ry="2" width="40" height="20" />
    </ContentLoader>)
  } else {
    return (
      <div className="dashboard_body">
        {/* <div className="d-flex align-items-center justify-content-center"> */}
        {/* <div className="transiction_container">
          <h3 className="dashboard_title">Dashboard</h3>
          
        </div> */}
        {/* </div> */}
        <Analysics />

        <Students studentsData={eductionListCountData} />
     

     
        <AddStudentModal open={open} onClose={() => SetOpen(false)} />

      </div>
    );
  };
}

export default Associate;
