import React, { useEffect } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getUser } from "../../../Controller/localStorageHandler"
import axios from "axios";
import _ from "lodash";
import useAppContext from "../../../useContext/userContext"

const Dashform = (props) => {
  const { setcountryValue, setprogramValue, handleChange, yearValue, semesterValue, setyearValue, setsemesterValue,
    values, countryValue, programValue } = props;
  const {
    getRelatedProgramsPickList,related_programs_picklist,
    getCountryPickList,country_picklist,
    getSemesterPickList,semester_picklist,
    getYearPickList,year_picklist,
  } = useAppContext()
  
  

  useEffect(() => {
    getRelatedProgramsPickList()
    getCountryPickList()
    getSemesterPickList()
    getYearPickList()

  }, []);

  // let country = typeof countryValue == "object" ? countryValue : inputData?.country?.split("|##|")

 
  return (
    <Grid
   
      sx={{
        p: 3,
        paddingBottom: "80px",
        backgroundColor: "#fff",
        mt: 3,
        pb: 2,
        borderRadius: "10px",
        boxShadow: 3,
      }}
    >
      <Typography> Looking for </Typography>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Degree</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // defaultValue={getUser().degree_looking_for}
          value={values.degree_looking_for}
          label="Degree"
          name="degree_looking_for"
          onChange={(e) => handleChange(e)}
        >
          <MenuItem value="Masters">Masters</MenuItem>
          <MenuItem value="Diploma">Diploma</MenuItem>
          <MenuItem value="Bachelors">Bachelors</MenuItem>
          <MenuItem value="Phd">Phd</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 1.5 }}>
        <Autocomplete
          name='country'
          freeSolo
          multiple
          // value={countryValue}
          defaultValue={countryValue?.length > 0 ? countryValue[0]?.split("|##|") : []}
          onChange={(e, val) => setcountryValue(val)}
          options={country_picklist?.country_picklist && country_picklist?.country_picklist.map((option) => option)}
          renderInput={(params) => <TextField {...params} label="Select Country" />}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 1.5 }}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          multiple
          name="related_programs"
          defaultValue={programValue?.length > 0 ? programValue[0]?.split("|##|") : []}
          onChange={(e, val) => setprogramValue(val)}
          options={related_programs_picklist?.related_programs_picklist && related_programs_picklist?.related_programs_picklist.map((option) => option)}
          renderInput={(params) => <TextField {...params} label="Select Program" />}
        />
      </FormControl>
      <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item >
          <FormControl fullWidth sx={{ mt: 1.5 }}>
            <InputLabel>Semester</InputLabel>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={semesterValue}
            label="Semester"
            name="semester_looking_for"
            onChange={(e)=>setsemesterValue(e.target.value)}
          >
            {semester_picklist?.semester_picklist?.length>0 && semester_picklist?.semester_picklist?.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
          </Select>
            {/* <Autocomplete
            fullWidth
              name='semester_looking_for'
              id="free-solo-demo"
              freeSolo
              value={semesterValue}
              onChange={(e, val) => setsemesterValue(val)}
              options={semesterList && semesterList.map((option) => option)}
              renderInput={(params) => <TextField fullWidth {...params} label="Select Semester" />}
            /> */}
          </FormControl>
        </Grid>
        <Grid item >
          <FormControl fullWidth sx={{ mt: 1.5 }}>
            <InputLabel>Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={yearValue}
              label="Year"
              name="year_looking_for"
              onChange={(e)=>setyearValue(e.target.value)}
          >
              {year_picklist?.year_picklist?.length>0 && year_picklist?.year_picklist?.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
          </Select>
            {/* <Autocomplete
            fullWidth
              name='year_looking_for'
              id="free-solo-demo"
              freeSolo
              value={yearValue}
              onChange={(e, val) => setyearValue(val)}
              options={yearList && yearList.map((option) => option)}
              renderInput={(params) => <TextField {...params} fullWidth label="Select Year" />}
            /> */}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default React.memo(Dashform);

