import React from 'react';

import BGImg from "../../img/bgImg.jpg";
import "../Common/CSS/Common.css";

import SelectBox from "../Common/Forms/Select";
import ButtonBox from "../Common/Forms/Button"

const GREAccepted = (props) => {
    return (
        <>
            <div className="row m-0">
                <div className="col-12 gradientBack positionUpRel p-0">
                    <img src={BGImg} width="100%" alt="" />
                </div>
            </div>
            
            <div className="positionUpAb grePosition p-2">
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Country" />
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Degree"/>
                    <SelectBox name="Hello" id="Hello" value="Hello" onChange="" opt="Programm"/>
                    <ButtonBox caption="Search" />
            </div>
        </>
    )
}

export default GREAccepted;