import React from 'react';
import chat from "../../../static/img/Mentor/newchat4.png"
import calling from "../../../static/img/Mentor/call-48.png"
import whatsapp from "../../../static/img/Mentor/whatsapp-48.png"
import Share from "../../../static/img/Mentor/share.svg"

const Icons = ({data}) => {
    const contact_action = (data,action) =>{
        if(action ==='whatsapp'){
        window.location.href="https://api.whatsapp.com/send?phone="+data?.mobile+"&text=Hi"
        }
        else if(action ==='calling'){
            window.location.href="tel:+"+data?.mobile+""
        }
        else if(action ==='share'){
            window.location.href=`https://api.whatsapp.com/send?text=Greetings from Brainstorm Consulting
            %0D%0A%0D%0A *Mentor*: ${data?.firstname} ${data?.lastname} 
            %0D%0A%0D%0A *Experience*: ${data?.work_experience} Years
            %0D%0A%0D%0A *Services*: ${getService(data?.services)}
            %0D%0A *Countries*: ${getService(data?.country)}
            %0D%0A *Location*: Bangalore 
            %0D%0A%0D%0A *Contact Number*: ${data?.mobile} 
            %0D%0A%0D%0A *Android*: https://bit.ly/FreeStudyAbroad
            %0D%0A%0D%0A *iOS*: https://apple.co/33ujR4Q `
        }
    }
    const getService = (list) =>{
        let string = ''
        list && list?.split('|##|').map((con) =>{
            // return con+"\n"
            string = string + `${con}%0D%0A`
        })
        return string
    }
    return (
        <div className="icons_section">
            <img src={whatsapp} className="icon" alt="" onClick={()=>{contact_action(data,'whatsapp')}} />
            <img src={calling} className="icon" alt="" onClick={()=>{contact_action(data,'calling')}}/>
            {/* <img src={chat} className="icon" alt="" onClick={()=>{contact_action(data,'chat')}}/> */}
            <img src={Share} className="icon" alt="" onClick={()=>{contact_action(data,'share')}}/>
        </div>
    );
};

export default Icons;