import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {makeStyles} from "@mui/styles";
import {Autocomplete, Button, Grid, TextField, Typography} from "@mui/material";
import { OPERATION, operationValue } from '../../../Controller/Global';
import axios from 'axios';
import { useFormik } from 'formik';
import { getSessionId, getUserId, SESSION_ID, USER_ID } from '../../../Controller/localStorageHandler';
import { withRouter } from 'react-router-dom';
import RadioGroup, {useRadioGroup} from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {styled} from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({theme, checked}) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}
const SearchSop = ({history}) => {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
          program:'',
          University:[],
          degreeType: 'Masters',
        },
    onSubmit: async(values) => {
        values[OPERATION]= operationValue.FetchSOPResult;
        const formData= new FormData()
        for(let val in values){
            if(val === 'University'){
                const university=universityValue.map(val=>`"${val}"`);
                formData.append(val, university)
            }else if(val === 'program'){
                const program=programValue.map(val=>`"${val}"`);
                formData.append(val, program)
            }
            else{
                formData.append(val, values[val])
            }
        }
        formData.append(USER_ID,getUserId())
        formData.append(SESSION_ID,getSessionId())
        formData.append("page","1")
        setLoading(true)
           await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
           .then(response => {
            if(response.data.msg === 'successfully' || response.data.message === 'Successfully'){
                history.push({ pathname: '/UniversitySearchResult', state: {data: response.data, screen:"Statement of Purpose",page:JSON.stringify(Object.fromEntries(formData))}})
            }
           })
           .finally(() => {
               setLoading(false)
           })
        },
    });
        
    const [programList, setProgramList] = React.useState([]);

    const [universityList, setUniversityList] = React.useState([]);
    const [universityValue, setuniversityValue] = React.useState([]);
    const [loading,setLoading] = React.useState('');
    const [programValue,setprogramValue]= React.useState([])

      useEffect(()=>{ 
        async function syncData() {
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', 'related_programs');
        const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
        , {
            'Access-Control-Allow-Origin': '*',
        })
            setProgramList(response.data.data);

            const university = new FormData()
            university.append(OPERATION,operationValue.FetchUniversity)
            university.append(USER_ID,getUserId())
            university.append(SESSION_ID,getSessionId())
            university.append('search'," ")
            university.append('degreeType',formik.values.degreeType)
            axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
            .then((response)=>{
                setUniversityList(response.data.data)
            })
        }
        syncData();
    }, []);
    const handleData=(value,type)=>{
        if(type==="program"){
            setprogramValue(value)
        }
        else{
            setuniversityValue(value)
        }
    }
    if(loading){
        return( <div style={{textAlign: 'center', marginTop:'55%'}}>
            <CircularProgress />
            </div>)
    }else{
    return (
        <>
            <p style={{fontSize:'14px',textAlign:'center',fontWeight:'bold',margin: '11px 0 -13px 0'}}> <Chip label="Statement of Purpose" variant="filled" color="primary" /></p>
            <hr />
            <form onSubmit={formik.handleSubmit}>
                <div className={classes.cardBox}>
                    <FormControl component="fieldset" sx={{m: 1, minWidth: "100%"}}>
                        <RadioGroup
                            className={classes.radioGroup}
                            row name="degreeType"
                            value={formik.values.degreeType}
                            onChange={formik.handleChange}
                        >
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Diploma"
                                label="Diploma"
                                control={<Radio className={"radioRadioMain"}/>}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Bachelors"
                                label="Bachelors"
                                control={<Radio className={"radioRadioMain"}/>}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Masters"
                                label="Masters"
                                control={<Radio className={"radioRadioMain"}/>}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Phd"
                                label="Phd"
                                control={<Radio className={"radioRadioMain"}/>}
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: "100%"}}>
                        {/* <InputLabel id="demo-simple-select-helper-label">Select Country</InputLabel>
                        <Select 
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Select course"
                            name="University"
                            multiple
                            value={formik.values.University}
                            onChange={formik.handleChange}
                        >
                            {countryList && countryList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> */}
                        <Autocomplete
                            name='University'
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            value={universityValue}
                            onChange={(e,val)=>handleData(val,'university')}
                            options={universityList&&universityList.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Select University" />}
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: "100%"}}>
                        {/* <InputLabel id="demo-simple-select-helper-label">Select Program</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Select course"
                            name="program"
                            value={formik.values.program}
                            onChange={formik.handleChange}
                        >
                            {programList && programList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                        </Select> */}
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            name="program"
                            value={programValue}
                            onChange={(e,val)=>handleData(val,'program')}
                            options={programList && programList.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Select Program" />}
                        />
                    </FormControl>
                    <Grid xs={12} className={classes.gridFlex} style={{marginBottom: '10px', marginTop: '5px'}}>
                        <Button className={classes.resetBtn}>Reset</Button>
                        <Button 
                        className={classes.submitBtn} 
                        type={'submit'}
                        >Search</Button>
                    </Grid>
                </div>
                <Grid className={classes.sxGrid_1Top}>
                    <Grid className={classes.sxGrid_3}>
                        <Button sx={{
                            padding: 0,
                        }}
                                onClick={()=>history.push('/SearchScholarship')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/DashBoard/scholarship.png`} alt="web search"
                                width="80%"/>
                        </Button>
                        <Button onClick={()=>history.push('/SearchScholarship')} variant="text" disableElevation className={classes.viewBtn}>View</Button>
                    </Grid>
                    <Grid className={classes.sxGrid_3}
                    >

                        <Button
                            sx={{
                                padding: 0,
                            }}
                            onClick={()=>history.push('/SearchUniversity')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/DashBoard/sop.png`} alt="web search" width="80%"/>
                        </Button>
                        <Button onClick={()=>history.push('/SearchUniversity')} variant="text" disableElevation className={classes.viewBtn}>View</Button>

                    </Grid>
                    <Grid
                        className={classes.sxGrid_3}
                    >

                        <Button
                            sx={{
                                padding: 0,
                            }}
                            onClick={()=>history.push('/SearchProgram')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/DashBoard/web.png`} alt="web search" width="80%"/>
                        </Button>
                        <Button
                            className={classes.viewBtn}
                            onClick={()=>history.push('/SearchProgram')}
                            variant="text" disableElevation>View</Button>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        fontSize: "16px",
                        display: "flex",
                        textAlign: "center",
                        marginTop: "30px",
                        marginBottom: "30px",
                        justifyContent: "space-around",
                    }}
                >
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: '20px',
                        textAlign: 'center',
                        fontWeight: 'normal',
                        color: '#1A1A1A'
                    }}> Search <br/> Scholarship </Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: '20px',
                        textAlign: 'center',
                        fontWeight: 'normal',
                        color: '#1A1A1A'
                    }}> Search <br/> University </Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: '20px',
                        textAlign: 'center',
                        fontWeight: 'normal',
                        color: '#1A1A1A'
                    }}> Search <br/> Programer </Typography>
                </Grid>
            </form>
        </>
    )}
};

export default withRouter(SearchSop);
const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));