import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';
import { Bar, Doughnut } from "react-chartjs-2";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import StudentsCard from './StudentsCard';
import _ from 'lodash'
import './Students.css';
// import studentsData from "./studentsData"
import logo1 from '../../../static/img/Associate/image/logo1.png';
import { FormControl, InputLabel, Select } from '@mui/material';
import whatsapp from '../../../img/whatsapp.png';
import { getUser } from '../../../Controller/localStorageHandler';
import moment from 'moment';


const Students = ({ studentsData }) => {
    let Data = _.groupBy(studentsData, 'eduloan_status')
    console.log(Data)
    return (
        <div className="container students_section" style={{ borderRadius: 25, margin: 0, boxShadow: "0px 10px 20px rgba(0 0 0 / 0.4), 0px 2px 6px rgba(0 0 0 / 0.4), 0px 0px 1px rgba(0 0 0 / 0.4)" }}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingRight: 10 }}>
                <h3 className="student_title" style={{ color: "#1B6CFC", fontWeight: "bold", fontSize: 20 }}>Students</h3>
                <a   >
                    <img src={`${whatsapp} `} style={{ height: 30, width: 30 }} />
                </a>
                <span style={{ fontSize: 20, fontWeight: "bold", color: "#1B6CFC" }}>10</span>
            </div>
            <div className="students_anaylysics_section">
                <div className="">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                        <p className="normal_title">Student this week</p>
                        {/* <FormControl style={{ width: 90 }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                style={{ height: 50 }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl> */}
                    </div>
                    <h4>$100</h4>
                    <h3 className="title"><ArrowUpwardIcon /> $100</h3>
                </div>
                <div className="students_chart">
                    <Bar
                        data={{
                            labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
                            datasets: [
                                {
                                    label: "Students",
                                    backgroundColor: "#1B6CFC",
                                    borderColor: "#F2F4F5",
                                    borderRadius: 20,
                                    borderWidth: 5,
                                    data: [35, 35, 81, 22, 22],
                                },
                            ],
                        }}
                        height={200}
                        width={250}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            }
                        }}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-around student_budget">
                <div>
                    <div className="chart_1">
                        <Doughnut
                            data={{
                                datasets: [
                                    {
                                        data: [10, 10],
                                        backgroundColor: ["#1b6cfc", "#F2F4F5"],
                                    },
                                ],
                            }}
                            height={20}
                            width={20}
                            options={{
                                maintainAspectRatio: true,
                            }}
                        />
                    </div>
                </div>
                <div>
                    <h3 className="budget_text">Monthly Budget</h3>
                    <h4 className="budget_title">$140/day</h4>

                </div>
                <div>
                    <h3 className="budget_text">$1000 left</h3>
                    <h4 className="budget_title">of $2000</h4>
                </div>
            </div>
            <Tabs>
                <TabList className="student_tab_class">
                    <Tab selectedTabClassName="react-tabs__tab--selected_follow" className="dashboard_category_follow" style={{ width: "30%" }}>All</Tab>
                    <Tab selectedTabClassName="react-tabs__tab--selected_follow" className="dashboard_category_follow" style={{ width: "35%" }}>Missed follow up</Tab>
                    <Tab selectedTabClassName="react-tabs__tab--selected_follow" className="dashboard_category_follow" style={{ width: "35%" }}> Today follow up </Tab>
                </TabList>
                <TabPanel>
                    <StudentsCard
                        studentsData={{
                            "status": "Total",
                            "count": studentsData?.length ,
                        }}
                        persent=""
                        today_modified=""
          
                    />
                    {
                        console.log(Data)
                    }
                    {
                        Data?.length > 0 && Data?.map(([key,value])=>{
                            console.log(key)
                            console.log(value)
                            return <StudentsCard
                            studentsData={{
                                "status": "Prospective",
                                "count": value?.length ,
                                "link": "prospective"
                            }}
                            persent=""
                            today_modified=""
                    
                        />
                        })
                    }
                
                    
                    <StudentsCard
                        studentsData={{
                            "status": "Logged In",
                            "count": studentsData?.total_loggin ,
                            "link": "loggedin"
                        }}
                        persent=""
                        today_modified=""

                    />
                    <StudentsCard
                        studentsData={{
                            "status": "Approved",
                            "count": studentsData?.total_approved ,
                            "link": "approved"
                        }}
                        persent=""
                        today_modified=""
        
                    />

                    {/* {
                        studentsData.map(stData => {
                            return <StudentsCard
                                key={stData.id}
                                studentsData={stData}
                                persent={`${Math.round((parseFloat(stData.count) * 100 / TotalValue).toFixed(2))}% `}
                                today_modified={stData?.today_modified || 0}
                            />
                        })
                    } */}
                </TabPanel>
                <TabPanel>
                    {/* {
                        studentsData.map(stData => (
                            <StudentsCard key={stData.id} studentsData={stData} />
                        ))
                    } */}
                </TabPanel>
                <TabPanel>
                    {/* {
                        studentsData.map(stData => (
                            <StudentsCard key={stData.id} studentsData={stData} />
                        ))
                    } */}
                </TabPanel>
            </Tabs>



        </div>
    );
};

export default Students;