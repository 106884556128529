import React, { useEffect, useRef, useState } from 'react';
import { Box } from "@mui/system";
import { Button, Grid, Input, TextField, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";

// import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
// import Radio from "@mui/material/Radio";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import axios from 'axios';
import { getSessionId, getUser, getUserId, SESSION_ID, USER_ID } from '../../Controller/localStorageHandler';
import { OPERATION, operationValue } from '../../Controller/Global';
import { useHistory } from 'react-router-dom'
// import CircularProgress from '@mui/material/CircularProgress';
// import ResultCard from '../Result/ResultCard';
import { fetchApplicationStatusAction } from '../Action';
import TopStepper from '../Dashboards/Stepper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadDocuments from '../Documents';
import whatsapp from '../../img/whatsapp.png';
import Message from '../../img/Message.png';
import SearchBoxWithFilter from '../SearchBox/SearchBoxWithFilter';
import { AsyncMulti } from '../StudentSearch';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Chat from '../College/Details/Chat';
import PopUpModal from "../Result/ApplyModal";
import _ from 'lodash'

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CheckIcon from '@mui/icons-material/Check';
import FlightIcon from '@mui/icons-material/Flight';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
// const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
//     ({ theme, checked }) => ({
//         '.MuiFormControlLabel-label': checked && {
//             color: theme.palette.primary.main,
//         },

//     }),

// );

import useAppContext from "../../useContext/userContext"




const StatusBar = (props) => {
    const classes = useStyles();
    const ref = useRef()
    const history = useHistory()

    const { getApplicationStatus, application_status_student} = useAppContext()
    

    const status = history?.location?.state?.status;
    const StudentData =  history?.location?.state?.StudentData;

    const [FetchApplicationData, setFetchApplicationData] = useState([]);
    const [ApplicationStatus, setApplicationStatus] = useState('All');
    const [open, setOpen] = useState(false);
    const [SearchSelectId, setSearchSelectId] = React.useState(null)

    const [admitted,setAdmitted] = React.useState('');
    const [enrolled,setEnrolled] = React.useState('');
    const [applied,setApplied] = React.useState('');
    const [rejected,setRejected] = React.useState('');

    const [applyData, setApplyData] = React.useState('')
    const [openApply, setOpenApply] = React.useState(false);

    // name change array
    let nameChange = {'Visa Yet To Apply':'Admitted',
                        'Admitted':'Admitted',
                        'Conditional Admission':'Admitted',
                        'Visa Applied':'Admitted',
                        'Visa Approved':'Enrolled',
                        'Deposit Due':'Admitted',
                        'I-20/Offer/CAS/COE':'Admitted',
                        'Visa Denied':'Admitted',
                        'Deferred':'Admitted',
                        'In Progress':'Admitted',
                        'Not Enrolled':'Admitted',
                        "Completed":'Under Review',
                        'Not Started':'Under Review',
                        'Open':'Under Review',
                        'Re-Selection':'Under Review',
                        'Info/Doc Pending':'Under Review',
                        'Yet to Fill':'Under Review',
                        'Application Fee Due':'Under Review',
                        'Ready for Submission':'Under Review',
                        'Portal Not Opened':'Under Review',
                        'Courier Fee Pending':'Under Review',
                        'Univ Confirmation Due':'Under Review',
                        'Financial doc Pending':'Under Review',
                        'Application Review':'Under Review',
                        'In Progress':'Under Review',
                        'Cancelled':'Under Review',
                        'Application Incomplete':'Under Review',
                        'University Rejected':'Rejected',
                    }

    const fetchApplicationStatus = (confirmstatus) => {
        var formdata = new FormData();
        formdata.append(OPERATION, operationValue.Applicationstatus)
        formdata.append(SESSION_ID, getSessionId())
        formdata.append(USER_ID, StudentData?.contactid || props?.userId || getUserId())
        formdata.append('confirmstatus', status || confirmstatus);

        fetchApplicationStatusAction(formdata).then((data) => {
            setFetchApplicationData(data?.data || [])
            console.log(data)

            // admitted
            var conditionArr = [
                { projecttaskstatus:'Visa Yet To Apply'},
                { projecttaskstatus:'Admitted'},
                { projecttaskstatus:'Conditional Admission'},
                { projecttaskstatus:'Visa Applied'},
                { projecttaskstatus:'Deposit Due'},
                { projecttaskstatus:'I-20/Offer/CAS/COE'},
                { projecttaskstatus:'Visa Denied'},
                { projecttaskstatus:'Not Enrolled'},
              ];

            let admit = filterStatusResult(conditionArr,data?.data?.data)

            setAdmitted(admit?.length)

            // Enrolled
            var conditionArrEnroll = [
                { projecttaskstatus:'Visa Approved'},
            ]
            let enroll = filterStatusResult(conditionArrEnroll,data?.data?.data)

            setEnrolled(enroll?.length)

            // Applied
            var conditionArrApplied = [
                { projecttaskstatus:'Application Review'},
                { projecttaskstatus:'Application Incomplete'},
                { projecttaskstatus:'University Rejected'},
            ]

            let apply = filterStatusResult(conditionArrApplied,data?.data?.data)
            
            setApplied(data?.data?.data?.length)

            // university rejected
            var conditionArrRejected = [
                { projecttaskstatus:'University Rejected'},
            ]

            let reject = filterStatusResult(conditionArrRejected,data?.data?.data)
            
            setRejected(reject?.length)


        }).catch((error) => {
            console.log("error", error)
        })
    }

    const filterStatusResult = (conditionArr,data) =>{
        var cond = _.map(conditionArr, cond => cond.projecttaskstatus); 

        var result = _.filter(data, user => _.indexOf(cond, user.projecttaskstatus) !== -1);

        return result
    }

    useEffect(() => {
        if(history?.location?.state?.status){
            console.log(history?.location?.state?.status)
            fetchApplicationStatus(history?.location?.state?.status)
        }
        else if(history?.location?.state?.StudentData){
            console.log(history?.location?.state?.StudentData)
            fetchApplicationStatus(history?.location?.state?.StudentData)
        }
        else{
            getApplicationStatus(ApplicationStatus)
        }
    }, [status,StudentData])

    useEffect(()=>{
        
        setFetchApplicationData(application_status_student?.application_status_student?.data)
        // admitted
        var conditionArr = [
            { projecttaskstatus:'Visa Yet To Apply'},
            { projecttaskstatus:'Admitted'},
            { projecttaskstatus:'Conditional Admission'},
            { projecttaskstatus:'Visa Applied'},
            { projecttaskstatus:'Deposit Due'},
            { projecttaskstatus:'I-20/Offer/CAS/COE'},
            { projecttaskstatus:'Visa Denied'},
            { projecttaskstatus:'Not Enrolled'},
          ];

        let admit = filterStatusResult(conditionArr,application_status_student?.application_status_student?.data?.data)

        setAdmitted(admit?.length)

        // Enrolled
        var conditionArrEnroll = [
            { projecttaskstatus:'Visa Approved'},
        ]
        let enroll = filterStatusResult(conditionArrEnroll,application_status_student?.application_status_student?.data?.data)

        setEnrolled(enroll?.length)

        // Applied
        var conditionArrApplied = [
            { projecttaskstatus:'Application Review'},
            { projecttaskstatus:'Application Incomplete'},
            { projecttaskstatus:'University Rejected'},
        ]

        let apply = filterStatusResult(conditionArrApplied,application_status_student?.application_status_student?.data?.data)
        
        setApplied(application_status_student?.application_status_student?.data?.data?.length)

        // university rejected
        var conditionArrRejected = [
            { projecttaskstatus:'University Rejected'},
        ]

        let reject = filterStatusResult(conditionArrRejected,application_status_student?.application_status_student?.data?.data)
        
        setRejected(reject?.length)
    },[application_status_student])


    let ProfileData = getUser()
    let user_contact_type = ProfileData?.user_contact_type || ''
    const SubmitFilter = () => {

    }
    
    return (
        <React.Fragment>
            <Box className={classes.root} sx={{ bgcolor: '#fafafa' }}   >


                {
                    status ? <>
                   <Chip onClick={() => history.goBack()} size="small" sx={{marginBottom:'10px'}} icon={<ArrowBackIcon />} label="Back" color="primary" variant="outlined"/>

                    <Chat data={StudentData} /> 
                    <br/>
                    <Typography
                            sx={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "700",
                            }}>
                         <Chip label="Applications" color="success" />
                         <Chip sx={{marginLeft:1}} label={FetchApplicationData?.TotalRecords || 0} size="small" color="error" />
                           
                        </Typography>
                    </>
                    :
                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Grid item xs={9} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            sx={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "700",
                            }}>
                         <Chip label="Applications" color="success" />
                         <Chip sx={{marginLeft:1}} label={FetchApplicationData?.TotalRecords || 0} size="small" color="error" />
                           
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={5} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between" }}>
                        <Button
                            variant="contained"
                            sx={{
                                padding: "0px",
                                borderRadius: 3,
                                fontWeight: "100",
                                fontSize: "14px",
                                pl: 2,
                                pr: 2,
                                textTransform: "none"
                            }}

                        >
                            Yet To Confirm
                        </Button>
                    </Grid> */}
                    <Grid item xs={3} sx={{ textAlign: 'end', display: "flex", justifyContent: "flex-end" }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>
                            {/* <a href={`mailto:${mentorData?.data?.mentor?.email || ''}`}> */}
                            <img src={`${Message}`} style={{ height: 30, width: 30 }} />
                            {/* </a> */}
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>
                            {/* <a href={`https://api.whatsapp.com/send?phone=${mentorData?.data?.mentor?.mobile || ''}&text=Hi`}> */}
                            <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                            {/* </a> */}
                        </div>
                    </Grid>
                </Grid>
                }
                <br/>

                {admitted ? <Chip icon={<CheckIcon/>} variant="outlined" color="success" size='small' label={`Admitted ${admitted}`}/> : '' } &nbsp;
                {enrolled ? <Chip icon={<FlightIcon />} variant="outlined"  color="secondary" size='small' label={`Enrolled ${enrolled}`}/> : '' } &nbsp;
                {applied ? <Chip icon={<LibraryBooksIcon />} variant="outlined" size='small' color="warning"  label={`Applied ${applied}`}/> : '' } &nbsp;
                {rejected ? <Chip icon={<ThumbDownOffAltIcon />} variant="outlined" size='small' label={`Rejected ${rejected}`}/> : '' } &nbsp;

                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>


                </Grid>
                <Grid container spacing={3}>
                    {FetchApplicationData?.data?.length > 0 && FetchApplicationData?.data?.map((d) => {
                        // console.log(nameChange[d?.projecttaskstatus])
                        return <Grid item xs={12} md={4} >
                        <Box
                            sx={{
                                boxShadow: 3,
                                backgroundColor: "#fff",
                                padding: "10px 0px",
                                borderRadius: "10px",
                                width: "100%",
                                mt: 3,
                                boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 0.2), 0px 3px 4px 0px rgba(0 0 0 / 0.4), 0px 1px 8px 0px rgba(0 0 0 / 0.2)"
                            }}
                        >
                            
                            <TopStepper
                                ApplicationStatus={history?.location?.state?.status  ? nameChange[d?.projecttaskstatus] :  d?.projecttaskstatus  }
                                ApplicationFee={d?.international_app_fee || ''}
                                TYPE="STATUS"
                                val={d}
                                setOpen={setOpen}
                                open={open}
                                extra_condition={history?.location?.state ? `hide` : 'no'}
                                status={status}
                                setApplyData={setApplyData}
                                setOpenApply={setOpenApply}
                            />
                          
                          {
                            history?.location?.state ? '' :
                            <div style={{ padding: d?.app_link || d?.online_id || d?.online_pwd ? 20 : 0, borderTopRightRadius: 25, borderTopLeftRadius: 25 }}>
                                
                                            <FormControl fullWidth sx={{ mt: 1.5 }}>
                                                {d?.app_link && <p style={{ marginBottom: 2 }}> Application URL : <a href={d?.app_link || ''} style={{ color: "#15c" }} target="_blank" >  Click here </a></p>}
                                            </FormControl>
                                            {d?.online_id && <FormControl fullWidth sx={{ mt: 1.5 }}>
                                                <Input disabled value={d?.online_id || ''} id="outlined-basic" placeholder="Online ID:" variant="outlined" />
                                            </FormControl>
                                            }
                                            {d?.online_pwd && <FormControl fullWidth sx={{ mt: 1.5 }}>
                                                <Input disabled value={d?.online_pwd || ''} id="outlined-basic" placeholder="Password :" variant="outlined" />
                                            </FormControl>
                                            }
                                        </div>
                            }

                        </Box>
                        </Grid>
                    })
                    }
                </Grid>
                <PopUpModal open={openApply}  handleClose={() => setOpenApply(false)} data={applyData} />
            </Box>
        </React.Fragment>
    )
};

export default StatusBar;
const useStyles = styled((theme) => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));