import React from 'react';
import PossibleStepper from './PossibleStepper';

const PossibleOptions =() => {
    return (
        <>
        <h6 style={{textAlign: 'center'}}>Possible Options</h6>
        <PossibleStepper />
        </>
    );
};

export default PossibleOptions;