import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import "./commonType.css";
import AddIcon from '@mui/icons-material/Add';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

const LastResponsibilities = ({ HandlePrev }) => {
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample3">
            <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                <span style={{ color: "white" }} onClick={() => HandlePrev()}>     Prev</span>
                <span style={{ marginBottom: 0, color: "white" }}>  Intern’s responsibilities </span>
                <span style={{ color: "white" }} >      </span>
            </div>

            <div className="content_res">
                <h3>Question 1</h3>
                <p>Why should you be hired for this role?</p>
            </div>
            <div className="content_res">
                <h3>Question 2</h3>
                <p> Are you available for 2 weeks, starting immediately, for a full-time internship?If not, what is the
                    time period you are available for and the earliest date you can start this internship on?</p>
            </div>
            <div className="d-flex add_option">
                <AddIcon /> <p>Add another question</p>
            </div>

            <div>
                <button className="common_btn1">Save draft</button>
                <button className="common_btn2">Post internship</button>
            </div>
        </div >
    );
};

export default LastResponsibilities;
