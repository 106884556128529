import { Chip, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import React from 'react'
import { barColor, getCurrencySymbol } from '../../../Controller/Global';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import moment from 'moment';
import { getUser } from '../../../Controller/localStorageHandler';

import CheckIcon from '@mui/icons-material/Check';
import FlightIcon from '@mui/icons-material/Flight';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useHistory } from 'react-router-dom'

export default function ResultCard(props) {
    const history = useHistory()
    const {

        related_programs,
        val,
        bookmark_count,enrolled_count,admitted_count,
        applied_count,
        Student
    } = props;
    let Date = val.app_end_date ? val.app_end_date : val.inter_adm_deadline
    let UserLocalstorge = getUser()
    let user_contact_type = Student?.user_contact_type || UserLocalstorge?.user_contact_type || ''

    return (
        <Grid container >
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <img
                    onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
                    src={val.university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${val.university}.png` : ''}
                    style={{ maxWidth: "100%", margin: 'auto', height: '30px', marginTop: '12px' }}
                    alt='college'
                />
            </Grid>
            <Grid item xs={10} sx={{ pl: 1, position: 'relative' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '13px', mt: 1, width: "77%" }}>{val.scholarship_name ? val.scholarship_name : val.university}</Typography>
                <Typography sx={{ fontSize: '13px' }}>{related_programs ? related_programs : val.program}</Typography>
                {!val.sop_title && <>
                    <Typography sx={{ mt: 0, fontSize: '13px', display: 'inline-block', width: '100%' }}>{val.funding_type ? val.funding_type : val.semester} 
                         </Typography>
                </>}
                <hr style={{ maxWidth: '70%', marginRight: '30%', color: '#cfcaca', width: '70%' }} />

          
                
                <Grid container sx={{
                        // paddingLeft: 3,
                        // paddingRight: 3,
                        marginTop: 2,

                    }}  spacing={1}>
                        <Grid
                         onClick={() => {
                            history.push({
                                pathname: '/student-list',
                                state: {
                                    'status': 'All',
                                    type: "University Program Bookmarked",
                                    val:val,
                                }
                            })
                        }}
                         item xs={6}>
                            <Typography
                             sx={{ fontSize: '14px', }}><FavoriteBorderIcon sx={{fontSize:'14px',color:'red' }}  /><span style={{paddingLeft:'10px'}}>Favourite :</span> <span style={{ float: 'center', marginRight: 5 }}>{bookmark_count}</span></Typography>
                        </Grid>

                        <Grid item xs={6}
                        onClick={() => {
                            history.push({
                                pathname: '/student-list',
                                state: {
                                    'status': 'All',
                                    type: "University Program Applied",
                                    val:val,
                                }
                            })
                        }}
                        >
                            <Typography sx={{ fontSize: '14px', }}><LibraryBooksIcon sx={{fontSize:'14px', color:'red'}}/><span style={{paddingLeft:'10px'}}>Applied :</span> <span style={{ float: 'center', marginRight: 5 }}>{applied_count}</span></Typography>
                        </Grid>
                        <Grid item xs={6}
                        onClick={() => {
                            history.push({
                                pathname: '/student-list',
                                state: {
                                    'status': 'All',
                                    type: "University Program Enrolled",
                                    val:val,
                                }
                            })
                        }}
                        >
                            <Typography sx={{ fontSize: '14px', }}><FlightIcon sx={{fontSize:'14px', color:'blue'}}/><span style={{paddingLeft:'10px'}}>Enrolled :</span> <span style={{ float: 'center', marginRight: 5 }}>{enrolled_count}</span></Typography>
                        </Grid>
                        <Grid item xs={6}
                        onClick={() => {
                            history.push({
                                pathname: '/student-list',
                                state: {
                                    'status': 'All',
                                    type: "University Program Admitted",
                                    val:val,
                                }
                            })
                        }}
                        >
                            <Typography sx={{ fontSize: '14px', }}><CheckIcon sx={{fontSize:'14px', color:'green' }}/><span style={{paddingLeft:'10px'}}>Admitted : </span><span style={{ float: 'center', marginRight: 5 }}>{admitted_count}</span></Typography>
                        </Grid>
                        
                        </Grid> 
      
                        
                
            </Grid>

        </Grid>
    )
}
