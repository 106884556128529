import React from "react";
import { Grid, Typography } from "@mui/material";
import "./Card.css";
import Slider from "react-slick";
import silder1 from '../../../img/FeaturedUniversitiesImg/ARIZONA STATE UNIVERSITY,TEMPE.png'
import silder2 from '../../../img/FeaturedUniversitiesImg/CLARK UNIVERSITY.png'
import silder3 from '../../../img/FeaturedUniversitiesImg/COVENTRY UNIVERSITY.png'
import silder4 from '../../../img/FeaturedUniversitiesImg/DUBLIN BUSINESS SCHOOL.png'
import silder5 from '../../../img/FeaturedUniversitiesImg/MIDDLESEX UNIVERSITY.png'
import silder6 from '../../../img/FeaturedUniversitiesImg/SRH BERLIN DRESDEN.png'
import silder7 from '../../../img/FeaturedUniversitiesImg/Trinity College Dublin.png'
import silder8 from '../../../img/FeaturedUniversitiesImg/UNIVERSITY OF GREENWICH.png'
import silder9 from '../../../img/FeaturedUniversitiesImg/UNIVERSITY OF HERTFORDSHIRE.png'
import silder10 from '../../../img/FeaturedUniversitiesImg/UNIVERSITY OF ILLINOIS.png'

var settings = {
    // dots: true,
    centerMode: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
let Data = [
    {
        image: silder1,
        University: 'Arizona State University',
        score: 303
    },
    {
        image: silder2,
        University: 'Clark University',
        score: 26
    },
    {
        image: silder3,
        University: 'COVENTRY UNIVERSITY',
        score: 179
    },
    {
        image: silder4,
        University: 'DUBLIN BUSINESS SCHOOL',
        score: 38
    },
    {
        image: silder5,
        University: 'MIDDLESEX UNIVERSITY',
        score: 118
    },
    {
        image: silder6,
        University: 'SRH BERLIN DRESDEN',
        score: 6
    },
    {
        image: silder7,
        University: 'Trinity College',
        score: 240

    },
    {
        image: silder8,
        University: 'UNIVERSITY OF GREENWICH',
        score: 117
    },
    {
        image: silder9,
        University: 'UNIVERSITY OF HERTFORDSHIRE',
        score: 109
    },
    {
        image: silder10,
        University: 'UNIVERSITY OF ILLINOIS',
        score: 149
    }

]
const CardsOne = () => {
    return (

        <Slider className={'gre-slider second-slider'} style={{ margin: "15px 0", height: 250 }} {...settings}>

            {Data.map((d,i) => {
                return <div key={i} style={{ width: "100%", display: "inline-block" }}>
                    <Grid
                        sx={{
                            borderRadius: 3,
                            boxShadow: 3,
                            width: "95%",
                            margin: "auto",
                            mt: 1,
                            backgroundColor:'#fff'
                        }}
                    >
                        <img src={d.image} alt='Trinity'
                            style={{ borderRadius: "20px", maxHeight: "150px", width: "100%" }} />
                        <Grid sx={{ ml: 3, pb: 2 }}>
                            <Typography
                                sx={{ color: "#000", fontSize: "15px", fontWeight: "700", mt: "10px" }}
                            >
                                {d?.University || ''}
                            </Typography>
                            <p style={{ marginBottom: "0", fontSize: "13px", }} className="Reviews"> Programs {d?.score}</p>
                        </Grid>
                    </Grid>
                </div>
            })}
            {/* <div style={{ width: "100%", display: "inline-block" }}>
                <Grid
                    sx={{
                        borderRadius: 3,
                        boxShadow: 3,
                        width: "90%",
                        margin: "auto",
                        mt: 1,
                    }}
                >
                    <img src={silder1} alt='Trinity'
                        style={{ borderRadius: "20px", maxHeight: "150px", width: "100%" }} />
                    <Grid sx={{ ml: 3, pb: 2 }}>
                        <Typography
                            sx={{ color: "#000", fontSize: "15px", fontWeight: "700", mt: "10px" }}
                        >
                            Trinity Collage Dublin
                        </Typography>
                        <p style={{ marginBottom: "0", fontSize: "13px", }} className="Reviews"> Reviews 50</p>
                    </Grid>
                </Grid>
            </div> */}
        </Slider>
   
    );
};
export default CardsOne;
