import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


export default function FavouriteModal(props) {
    const {open,handleClose,confirm,isBookMark} = props
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            {isBookMark ? 
            <>
            <DialogContent >
                    <div style={{textAlign: 'center'}}>
                        {/* <img src={`${process.env.PUBLIC_URL}/img/`} alt='percentage' style={{ marginTop: '15px',maxWidth:"100%" }} /> */}
                        <FavoriteBorderIcon />
                        {/* <h2>Are you sure?</h2> */}
                        <h2></h2>
                        <p>Do you want to remove from <b>Shortlist</b></p>
                        {/* <b>Favourite</b> */}
                    </div>
            </DialogContent>
            <DialogActions style={{width:'auto',margin:'auto'}}>
            <Button autoFocus onClick={()=>confirm('remove')}>
                Remove
            </Button>
            </DialogActions>
            </>
            :
            <>
            <DialogContent >
                    <div style={{textAlign: 'center'}}>
                        {/* <img src={`${process.env.PUBLIC_URL}/img/`} alt='percentage' style={{ marginTop: '15px',maxWidth:"100%" }} /> */}
                        <FavoriteBorderIcon />
                        <h2></h2>
                        {/* <h2>Are you sure?</h2> */}
                        <p>Do you want to <b>Shortlist</b> ?</p>
                        {/* <b>Favourite</b> */}
                    </div>
            </DialogContent>
            <DialogActions style={{width:'auto',margin:'auto'}}>
            <Button autoFocus onClick={()=>confirm('add')}>
                Add
            </Button>
            </DialogActions>
            </>
            }
            
        </Dialog>
    )
}
