import { ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import City from '../Collaps/City';
import Details from '../Collaps/Details';
import Duaration from '../Collaps/Duaration';
import Internship from '../Collaps/Internship';
import LastResponsibilities from '../Collaps/LastResponsibilities';
import Opinions from '../Collaps/Opinions';
import Responsibilites from '../Collaps/Responsibilites';
import Type from '../Collaps/Type';
import './PostInternshipDetails.css';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";




const PostInternshipDetails = () => {
    const [FormNo, setFormNo] = useState(1);

    const HandleNext = () => {
        setFormNo(FormNo + 1)
    }

    const HandlePrev = () => {
        setFormNo(FormNo - 1)
    }

    return (
        <div className="postInternship_section">
            <p className="faq_question mx-3">Post an internship?</p>
            <div className="d-flex justify-content-between align-items-center mx-3">
                <p className="help_text">Need assistance? Please </p>
                <p>
                    <Link className="help_text text-primary" to="#" >visit Help Center</Link>
                </p>

            </div>

            <div className="post_inernship_type_details py-1">
                {
                    FormNo === 1 ?
                        <Details HandleNext={() => HandleNext()} />
                        : FormNo === 2 ? <Type HandleNext={() => HandleNext()} HandlePrev={() => HandlePrev()} />
                            : FormNo === 3 ? <City HandleNext={() => HandleNext()} HandlePrev={() => HandlePrev()} />
                                : FormNo === 4 ? <Opinions HandleNext={() => HandleNext()} HandlePrev={() => HandlePrev()} />
                                    : FormNo === 5 ? <Internship HandleNext={() => HandleNext()} HandlePrev={() => HandlePrev()} />
                                        : FormNo === 6 ? <Duaration HandleNext={() => HandleNext()} HandlePrev={() => HandlePrev()} />
                                            : FormNo === 7 ? <Responsibilites HandleNext={() => HandleNext()} HandlePrev={() => HandlePrev()} />
                                                : FormNo === 8 ? <LastResponsibilities  HandlePrev={() => HandlePrev()} /> : ""
                }

            </div>
            <br /><br /><br /><br />
        </div >
    );
};

export default PostInternshipDetails;