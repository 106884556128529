import React from "react";
// import party from "${process.env.PUBLIC_URL}/img/party.png";
//mui
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import ShareIcon from "@mui/icons-material/Share";
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import PageTitle from "../../../PageTitle";
import Colors from "../../../../static/layout/Colors";
import moment from "moment";
import Share from '../../../../img/share.png';


const EventsCard = ({ data, countData }) => {
    const classes = useStyles();
    const history = useHistory()

    const getCountData = () => {
        let Data = _.find(Object.keys(countData), (d) => {
            let id = d.replace("count_", "");
            if (id === data.campaignid) {
                return countData[d]
            }
        })
        return `${Data ? countData[`${Data}`] : 0} joined`
    }

    return (
        <div className={classes.eventCard} onClick={() => history.push({
            pathname: '/add-event',
            state: { data: data, countData: countData }
        })}
            style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)', }}
        >
            <div className={classes.eventListContainer}>
                <img alt="" style={{ height: "200px" }} className={classes.partyImage} src={`${process.env.PUBLIC_URL}/img/EVENTS/${data?.campaignname}.png`} />
            </div>

            <div style={{ marginTop: '16px', marginBottom: '16px' }} className={classes.container2}>
                <div>
                    <PageTitle bold sx={{ fontSize: '18px', lineHeight: '22px', color: '#1A1A1A', marginBottom: '3%', }} pageTitle={data?.campaignname || ''} />
                </div>
                <div>
                    <main className={classes.date}>
                        <p className={classes.dateText}>{moment(data?.event_date).format("MMM Do")}</p>
                    </main>
                    {/* <p style={{ marginTop: '14px' }} className={classes.distance}>2km</p> */}
                </div>
            </div>

            <div className={classes.container3}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <main className={classes.joined}>{getCountData()}</main>
                    <img alt={''} className={classes.img} src={`${process.env.PUBLIC_URL}/img/displayPicture.jpg`} />
                    <div className={classes.userLength} style={{ background: "#ffcccb", color: "red", }}>
                        <main style={{ fontSize: "14px", textAlign: "left" }}>
                            +25
                        </main>
                    </div>
                </div>
                <div className="col-5 p-0 pe-2 ps-2">
                    <div className="row m-0 d-flex justify-content-between align-items-center">
                        <a className="col-7 userCount whtApp pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly">
                            <p className="mb-0 join">Join</p>
                        </a>
                        <div className="col-3 share pt-1 pb-1 p-0 d-flex align-items-center justify-content-evenly">
                            <img src={Share} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default EventsCard;

const useStyles = makeStyles((theme) => ({
    eventCard: {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        minHeight: '350px',
        padding: '18px 15px',
        marginBottom: '20px'
    },
    container2: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "2%",
    },
    container3: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
    },
    eventListContainer: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        borderRadius: '20px'
    },
    partyImage: {
        width: '100%',
        borderRadius: '20px'
    },
    location: {
        display: "flex",
        alignItems: "center",
    },
    date: {
        backgroundColor: Colors.lightBlue,
        borderRadius: "10px",
    },
    dateText: {
        fontSize: "16px",
        padding: "8px",
        fontWeight: "bold",
        marginBottom: '2%',
        background: "#ffcccb",
        color: "red",
        borderRadius: 25
    },
    distance: {
        fontSize: "14px",
        display: "flex",
        color: '#CED0D5',
        justifyContent: "flex-end",
        fontWeight: 'bold',
        lineHeight: '20px',
        marginBottom: 0,
    },
    joined: {
        fontWeight: "bold",
    },
    img: {
        height: "30px",
        width: "30px",
        borderRadius: "100%",
        marginLeft: "10px",
    },
    userLength: {
        backgroundColor: Colors.blue,
        borderRadius: "50%",
        padding: "7px",
    },
}));
