import { Route, Redirect} from "react-router-dom";
import BottomNav from "../../components/Common/BottomNav";
import Navbar from "../../components/Common/Navbar";
import { isLoggedIn } from "../localStorageHandler";


export default function MainComponent({ component: Component,role, ...rest }) {

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        isLoggedIn() ?
          <>
              <Component {...routeProps} />
              {/* <BottomNav /> */}
          </>
          :
          <Redirect to={{pathname: '/', state: {from: routeProps.location}}}/>
      )}
    />
  );
}

