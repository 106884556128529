import React from 'react'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = () => {
    const classes = useStyles();
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <main className={classes.inputContainer}>
                    <SearchIcon sx={{ opacity: 0.5 }} />
                    <input style={{ width: "100%" }} className={classes.input} autoFocus placeholder='Search by Name Email Mobile.' />
                </main>
            </div>
        </div>
    )
}

export default SearchBox;

const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        padding: '10px',
        width: '100%',
        height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        boxShadow: '2px 2px 10px #DCDCDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7
    },
    filterIcon: {
        position: 'relative',
        top: 10,
        height: '75px',
        width: '75px',
        //  boxShadow : '2px 2px 10px #DCDCDC','
    },
    tabLink: {
        backgroundColor: 'red',
    }
}))