import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import whatsapp from '../../img/whatsapp.png';
import { getUser, getUserId } from '../../Controller/localStorageHandler';
import { MentorDocumentDetailAction } from '../Action';

export default function DownloadModal(props) {
    const User = getUser()
    const [mentorData, setMentorData] = useState({})
    const { open, handleClose, SopData } = props
    let Heading = `<div>
        <p style={{ color: "#1A1A1A", fontSize: 14, fontWeight: "bold", marginBottom: '0px' }}>${SopData.university}</p>
        <p style={{ color: "#1A1A1A", fontSize: 12, fontWeight: "bold", marginBottom: '0px' }}>${SopData.related_programs}</p>
        ${SopData.sop_detail ? SopData.sop_detail : 'No Data'}
    </div>
    <div>
    <span style={{ display: "flex" }}>${mentorData?.data?.mentor?.name || ''} </span>
    <span style={{ display: "flex" }}>${mentorData?.data?.mentor?.email || ''} </span>
    <span style={{ display: "flex" }}>${mentorData?.data?.mentor?.mobile || ''} </span>
    <span style={{ display: "flex" }}>${User?.account_website || ''} </span>
</div>
    `
    var whatSappText = (Heading).replace(/<[^>]+>/g, '');

    useEffect(() => {
        var data = `{\n"student_id":"${getUserId()}","bypass":"validation"\n}`;
        MentorDocumentDetailAction(data).then((response) => {
            setMentorData(response)
        }).catch((error) => {
            setMentorData([])
        })
    }, [])

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll={'body'}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                <>
                    <Grid sx={{ paddingTop: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }} container>
                        <Grid style={{ textAlign: 'center' }}>
                            <img
                                onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
                                src={SopData.university ? `https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${SopData.university}.png` : ''}
                                style={{ maxWidth: "100%", height: '30px', }}
                                alt='college'
                            />
                        </Grid>
                        <Grid display={{ display: "flex", flexDirection: "column", marginTop: '5px' }}>
                            <p style={{ color: "#1A1A1A", fontSize: 14, fontWeight: "bold", marginBottom: '0px' }}>{SopData.university}</p>
                            <p style={{ color: "#1A1A1A", fontSize: 12, fontWeight: "bold", marginBottom: '0px' }}>{SopData.related_programs}</p>
                        </Grid>
                        <Grid >
                            <a href={`https://api.whatsapp.com/send?phone=${User?.mobile || ''}&text=${whatSappText}`}> <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '45px', textAlign: 'justify' }}>{SopData.sop_detail ? SopData.sop_detail : 'No Data'}</p>
                    <div>
                        <span style={{ display: "flex" }}>{mentorData?.data?.mentor?.name || ''} </span>
                        <span style={{ display: "flex" }}>{mentorData?.data?.mentor?.email || ''} </span>
                        <span style={{ display: "flex" }}>{mentorData?.data?.mentor?.mobile || ''} </span>
                        <span style={{ display: "flex" }}>{User?.account_website || ''} </span>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    )
}
