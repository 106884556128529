import { BottomNavigation, BottomNavigationAction, Paper, Button } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { getUser, getUserType, getSessionId, getUserId } from '../../../Controller/localStorageHandler';
import Badge from '@mui/material/Badge';
import axios from 'axios';
// import {DataContext} from "../../../useContext/DataContext"
import useAppContext from "../../../useContext/userContext"
import _ from "lodash"
import { CommonLogin } from '../../Forms/CommonLogin';
import { FaCalendarAlt, FaWhatsappSquare, FaTelegram, FaFacebookF } from "react-icons/fa";

export default function BottomNav() {
    const { favourite_universities, application_status_student, doc_list, upload_doc_list } = useAppContext()

    const location = useLocation();

    const [value, setValue] = useState('recents');

    const history = useHistory();

    const [openLogin, setOpenLogin] = useState(false)



    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === 0) {
            history.push('/dashboard');
        }
        if (newValue === 1) {

        }
        // 
    };

    const handleChangeFront = (event, newValue) =>{
            history.push(newValue);
       }




    let studentMenuList = [
        {
            label: "Home",
            path: '/dashboard',
            icon: 'home.svg'
        },
        {
            label: "Shortlist",
            path: '/UniversityFavourites',
            icon: 'heart.svg'
        },
        {
            label: "Documents",
            path: '/UploadDocuments',
            icon: 'document.svg'
        },
        {
            label: "Applications",
            path: '/status',
            icon: 'moreCircle.svg'
        },
        {
            label: "Events",
            path: '/EventsList',
            icon: 'event.svg'
        }
    ]

    let employeeMenuList = [
        {
            label: "Students",
            path: '/student-list',
            icon: 'user.svg'
        },
        {
            label: "Registered",
            path: '/student-list',
            icon: 'search.svg'
        },
        {
            label: "Shortlist",
            path: '/UniversityFavourites',
            icon: 'heart.svg'
        },
        {
            label: "Documents",
            path: '/UploadDocuments',
            icon: 'document.svg'
        },
        {
            label: "Applications",
            path: '/application-status',
            icon: 'moreCircle.svg'
        },
    ]

    let UniverCityMenuList = [
        {
            label: "Home",
            path: '/universityDashboard',
            icon: 'home.svg'
        },
        {
            label: "Prospective",
            path: '/universityProspectives',
            icon: 'heart.svg'
        },
        {
            label: "Applications",
            path: '/universityapplication',
            icon: 'book.svg'
        },
        {
            label: "Events",
            path: '/universityeventList',
            icon: 'event.svg'
        },
        {
            label: "Notifications",
            path: '/notifications',
            icon: 'notification.svg'
        },
    ]

    let InternshipMenuList = [
        {
            label: "Home",
            path: '/internshipDashboard',
            icon: 'home.svg'
        },
        {
            label: "Job",
            path: '/internship',
            icon: 'whatsapp.svg'
        },
        {
            label: "Candidates",
            path: '/candidate',
            icon: 'heart.svg'
        },
        {
            label: "Closed",
            path: '/UploadDocuments',
            icon: 'document.svg'
        },
        {
            label: "Notifications",
            path: '/notifications',
            icon: 'moreCircle.svg'
        },
    ]

    let educationLoanMenuList = [
        {
            label: "Home",
            path: '/educationDashboard',
            icon: 'home.svg'
        },
        {
            label: "Prospective",
            path: '/student-list',
            icon: 'home.svg'
        },
        {
            label: "Logged In",
            path: '/student-list',
            icon: 'home.svg'
        },
        {
            label: "Approved",
            path: '/student-list',
            icon: 'home.svg'
        },
        {
            label: "Notifications",
            path: '/notifications',
            icon: 'home.svg'
        },
    ]
    let AccommodationMenuList = [
        {
            label: "Home",
            path: '/accommodationDashboard',
            icon: 'home.svg'
        },
        {
            label: "Prospective",
            path: '/student-list',
            icon: 'home.svg'
        },
        {
            label: "Property",
            path: '/accommodationProperty',
            icon: 'home.svg'
        },
        {
            label: "Vacated",
            path: '/student-list',
            icon: 'home.svg'
        },
        {
            label: "Notifications",
            path: '/notifications',
            icon: 'home.svg'
        },
    ]

    let UserLocalstorge = getUser()
    let user_contact_type = UserLocalstorge?.user_contact_type || ''
    const GetActiveMenu = () => {
        if (user_contact_type === "Student") {
            return studentMenuList
        }
        else if (user_contact_type === "Employee" || user_contact_type === "Associate" || user_contact_type === "Associate Admin") {
            return employeeMenuList
        }
        else if (user_contact_type === "Partner") {
            return educationLoanMenuList
        }
        else if (user_contact_type === "Internship/Job") {
            return InternshipMenuList
        }
        else if (user_contact_type === "University") {
            return UniverCityMenuList
        }
        else if (user_contact_type === "Accommodation") {
            return AccommodationMenuList
        }

    }
    if (location.pathname === "/login" || location.pathname === "/SignUp" || location.pathname === "/forgetPassword" || location.pathname === "/RegisterForm" || location.pathname === "/TransferUniversityForm" ) {
        return <></>
    }
    else if (location.pathname === '/' || location.pathname.includes('/token')) {
        return (
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, pb: 2, pt: 1, zIndex: 1 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    onChange={handleChangeFront}
                    sx={{ fontFamily: 'Montserrat' }}
                >
                    <BottomNavigationAction
                        label="Events"
                        value="/eventsList"
                        icon={<FaCalendarAlt />}
                    />
                    <BottomNavigationAction
                        label="Whatsapp"
                        value="/WhatsappGroup"
                        icon={<FaWhatsappSquare color="#075E54" />}
                    />
                    <BottomNavigationAction
                        label="Telgram"
                        value="/TelegramGroup"
                        icon={<FaTelegram color="#0088cc"/>}
                    />
                    <BottomNavigationAction
                        label="Facebook"
                        value="/FacebookGroup"
                        icon={<FaFacebookF color="#3b5998"/>}
                    />
                </BottomNavigation>
            </Paper>
        )
    }
    else if (getUserId()) {
        return (
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, pb: 2, pt: 1, zIndex: 1 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value} onChange={handleChange}
                    sx={{ fontFamily: 'Montserrat' }}
                >
                    {GetActiveMenu().map((d, i) => {
                        return <BottomNavigationAction
                            key={i}
                            style={{ fontSize: "12px", padding: "8px", minWidth: "auto" }}
                            // to={d.path}
                            // component={Link}
                            // component={Typography}
                            onClick={() => history.push(`${d.path}`)}
                            label={`${d.label}`}

                            icon={
                                getUserType() === 'Student' && d.label === 'Shortlist' && favourite_universities?.favourite_universities?.TotalRecords !== 0
                                    ?
                                    <><Badge badgeContent={favourite_universities?.favourite_universities?.TotalRecords} color="error"><img src={`${process.env.PUBLIC_URL}/img/icons/${d.icon}`} alt="" /></Badge></>
                                    :
                                    getUserType() === 'Student' && d.label === 'Applications' && application_status_student?.application_status_student?.data?.TotalRecords !== '0'
                                        ?
                                        <><Badge badgeContent={application_status_student?.application_status_student?.data?.TotalRecords} color="error"><img src={`${process.env.PUBLIC_URL}/img/icons/${d.icon}`} alt="" /></Badge></>
                                        :
                                        getUserType() === 'Student' && d.label === 'Documents' && doc_list?.doc_list?.length
                                            ?
                                            <><Badge badgeContent={doc_list?.doc_list?.length} color="error"><img src={`${process.env.PUBLIC_URL}/img/icons/${d.icon}`} alt="" /></Badge></>
                                            :
                                            <img src={`${process.env.PUBLIC_URL}/img/icons/${d.icon}`} alt="" />
                            }
                        />
                    })
                    }

                </BottomNavigation>
            </Paper>
        )
    }
    else {
        if(location.pathname === "/accomodation" || location.pathname.includes('/apply-accomodation')){
            return <></>
        }
        return <>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, pb: 2, pt: 1, zIndex: 1 }} elevation={3}>
                <Button onClick={() => setOpenLogin(true)} variant="contained" fullWidth>Login / Signup</Button>
                <CommonLogin openLogin={openLogin} handleClose={setOpenLogin} return_url={location.pathname} />

            </Paper>
        </>
    }
}
