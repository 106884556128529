import React, { useEffect, useState } from "react";
import PageTitle from "../../PageTitle";
import EventsNav from "./EventsNav";
import EventsCard from "./EventsCard";
import { Grid } from "@mui/material";
import { fetchEventListAction } from '../../Action/index'
import { getSessionId, getUserId } from "../../../Controller/localStorageHandler";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useHistory } from 'react-router-dom'

const UniversityEventList = () => {
    const history = useHistory()

    const [EventListData, setEventListData] = useState([])

    useEffect(() => {
        let formData = new FormData()
        formData.append("operation", "get_event_list")
        formData.append("campaigntype", "Spot Admissions")
        formData.append("userid", getUserId())
        formData.append("sessionid", getSessionId())

        fetchEventListAction(formData).then((response) => {
            setEventListData(response)
        }).catch((error) => {
            console.log("error", error)
        })
    }, [])

    const [toggle, setToggle] = useState({
        forYou: false,
        trending: false,
        upcoming: false,
    });

    return (
        <div className="global-container mt-3 p-0">




            <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <PageTitle pageTitle={`Events`} bold />
                <button
                    onClick={() => history.push('/addUniversityEvent')}
                    className="transiction_btn"
                    style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}
                >
                    + Add Event
                </button>
            </div>
            <Tabs style={{ marginTop: 10 }}>
                <TabList style={{ paddingLeft: 0, marginLeft: 0, justifyContent: 'start' }} className="tab_class tab_class_fav">
                    <Tab className="dashboard_category">Active </Tab>
                    <Tab className="dashboard_category">Inactive </Tab>
                    <Tab className="dashboard_category">Draft </Tab>
                    <Tab className="dashboard_category">Closed </Tab>
                </TabList>
                <TabPanel>
                    {EventListData?.data?.length > 0 && EventListData?.data?.map((d) => {
                        return <EventsCard data={d} countData={EventListData?.count_data || null} />
                    })}
                </TabPanel>
                <TabPanel>
                    {EventListData?.data?.length > 0 && EventListData?.data?.map((d) => {
                        return <EventsCard data={d} countData={EventListData?.count_data || null} />
                    })}
                </TabPanel>
                <TabPanel>
                    {EventListData?.data?.length > 0 && EventListData?.data?.map((d) => {
                        return <EventsCard data={d} countData={EventListData?.count_data || null} />
                    })}
                </TabPanel>
                <TabPanel>
                    {EventListData?.data?.length > 0 && EventListData?.data?.map((d) => {
                        return <EventsCard data={d} countData={EventListData?.count_data || null} />
                    })}
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default UniversityEventList;