import { ArrowDownward } from "@mui/icons-material";
import React, { useEffect, useState,Suspense } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getUser } from "../../Controller/localStorageHandler";
import addMony from "../../static/img/Associate/icons/addMoney.png";
import more from "../../static/img/Associate/icons/more.png";
import sendMony from "../../static/img/Associate/icons/sendMoney.png";
import logo1 from "../../static/img/Associate/image/logo1.png";
import usLogo from "../../static/img/Associate/image/usLogo.png";
import { GetApplicationCountDataAction, getAssociateStatusProjecttCountAction, getAssociateStudentListCountStatusAction } from "../Action/AssociateDocumentList";
import BottomNav from "../Common/BottomNav";
// import BottomNav from "../../pages/inc/BottomNav";
import Analysics from "./Analysics/Analysics";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


import "./Dashboard.css";
import _ from 'lodash'
import ContentLoader from "react-content-loader";
import { AddStudentModal } from "../Student/StudentList/AddStudentModal";
import moment from "moment";

const Students = React.lazy(() => import('./Students/Students'));
const Registrated = React.lazy(() => import('./Registrated/Registrated'));
const Applications = React.lazy(() => import('./Applications/Applications'));

let Data = {
  "Admitted_count": "Admitted",
  "Application Fee Due_count": "Application Fee Due",
  "Application Incomplete_count": "Application Incomplete",
  "Application Review_count": "Application Review",
  "Conditional Admission_count": "Conditional Admission",
  "Eligibility Checking_count": "Eligibility Checking",
  "I-20/Offer/CAS/COE_count": "I-20/Offer/CAS/COE",
  "Info/Doc Pending_count": "Info/Doc Pending",
  "Portal Not Opened_count": "Portal Not Opened",
  "Ready for Submission_count": "Ready for Submission",
  "Deposit Due_count": "Deposit Due",
  "University Rejected_count": "University Rejected",
  "Visa Applied_count": "Visa Applied",
  "Visa Approved_count": "Visa Approved",
  "Visa Denied_count": "Visa Denied",
  "Visa Yet To Apply_count": "Visa Yet To Apply",
  "Yet to Fill_count": "Yet to Fill",
  "today_submitted_count" : "Today Submitted",
  "today_filling_count":"Today Filling",
  "today_filled_count":"Today Filled",
  "today_visa_count":"Today Visa",
  "month_submitted_count":"Monthly Submitted",
  "month_filling_count":"Monthly Filling",
  "month_vissa_count":"Monthly Visa",
  "tomorrow_filling_count" : "Tomorrow Filling",
}

let AssociateNameChanging = {
  "Shortlisting_count": "Shortlisting",
  "Filling_count": "Filling",
  "Application_Review_count": "Application Review",
  "Deposit_Due_count": "Deposit Due",
  "Visa_Yet_To_Apply_count": "Visa Yet To Apply",
  "Visa_Applied_count": "Visa Applied",
  "Visa_Approved_count": "Visa Approved",
  "Visa_Denied_count": "Visa Denied",
  "Not_Enrolled_count":"Not Enrolled",

  "Completed_count": "Completed",
  "monthly_project_list":"Monthly Projects",
  "todays_project_list":"Todays Projects",
  "expel_rm":"No Admit"
}

let employNameChanging = {
  "Shortlisting_count": "Shortlisting",
  "Filling_count": "Filling",
  "Application_Review_count": "Application Review",
  "Deposit_Due_count": "Deposit Due",
  "Visa_Yet_To_Apply_count": "Visa Yet To Apply",
  "Visa_Applied_count": "Visa Applied",
  "Visa_Approved_count": "Visa Approved",
  "monthly_project_list":"Monthly Projects",
  "todays_project_list":"Todays Projects",
  "expel_rm":"No Admit",
  "Visa_closed_count": "Visa Denied",
  "Not_Enrolled_count":"Not Enrolled",
}



const Associate = () => {
  let UserLocalstorge = getUser()
  let user_contact_type = UserLocalstorge?.user_contact_type || ''
  
  const [getListCountByData, setGetListCountByData] = useState([])
  const [getListCountByBookmark, setGetListCountByBookmark] = useState([])
  const [GetAssociateProjectStatus, setGetAssociateProjectStatus] = useState({})
  const [GetApplicationCountData, setGetApplicationCountData] = useState({})
  const [mainApplicationData,setMainApplicationData] = useState({})

  const [StudenLoader, setStudenLoader] = useState(false)
  const [RegisteredLoader, setRegisteredLoader] = useState(false)
  const [ApplicationsLoader, setApplicationsLoader] = useState(false)
  const [open, SetOpen] = React.useState(false)
  const [TotalStudentValue,setTotalStudentValue] = useState(0)


  const AssociateStudentListCountStatus = () => {
    setStudenLoader(true)
    let ProfileData = getUser()
    var data = new FormData();
    data.append('contact_no', ProfileData?.contact_no || '');
    // console.log(ProfileData?.contact_no)
    getAssociateStudentListCountStatusAction(data).then((response) => {
      console.log(response?.count_data?.data?.total_contact)
      console.log(response)
      // let Order = _.orderBy(_.map(response?.count_data || [], (d) => {
      //   d.count = parseInt(d.count)
      //   return d
      // }), ['count'], 'desc')
      // console.log(Order)
      setStudenLoader(false)
      setGetListCountByData(response?.count_data?.data)
      setGetListCountByBookmark(response?.count_data?.bookmarked_count) 
      setTotalStudentValue(response?.count_data?.data?.total_contact)
    }).catch((error) => {
      setStudenLoader(false)
      setGetListCountByData([])
      setGetListCountByBookmark([])
    })
  }

  const AssociateStatusProjecttCount = () => {
    setRegisteredLoader(true)
    let ProfileData = getUser()
    var data1 = `{"contact_no":"${ProfileData?.contact_no || ''}","bypass":"validation"}`;
    getAssociateStatusProjecttCountAction(data1).then((response) => {
      console.log(response)
      let Data = []
      let ResponseData = response?.data || {}
      _.map(Object.keys(response?.data || {}), (d) => {
        let temp = { name: '', score: 0 }
        temp.name = d
        temp.score = parseInt(ResponseData[`${d}`])
        Data.push(temp)
      })
      setRegisteredLoader(false)
      setGetAssociateProjectStatus(response?.data || {})
    }).catch((error) => {
      setRegisteredLoader(false)
      setGetAssociateProjectStatus({})
    })
  }

  const ApplicationCountData = () => {
    setApplicationsLoader(true)
    let ProfileData = getUser()

    var data1 = `{"contact_no":"${ProfileData?.contact_no || ''}","bypass":"validation"}`;
    GetApplicationCountDataAction(data1).then((response) => {
      console.log(response)
      let Data = []
      let ResponseData = response?.data || {}
      _.map(Object.keys(response?.data || {}), (d) => {
        let temp = { name: '', score: 0 }
        temp.name = d
        temp.score = Array.isArray(ResponseData[`${d}`]) ? 0 : parseInt(ResponseData[`${d}`])
        Data.push(temp)

      })
      setApplicationsLoader(false)
      setGetApplicationCountData(Data)
      setMainApplicationData(response?.data)
    }).catch((error) => {
      setApplicationsLoader(false)
      setGetApplicationCountData({})
      setMainApplicationData({})
    })
  }


  useEffect(() => {
    AssociateStudentListCountStatus()
    AssociateStatusProjecttCount()
    ApplicationCountData()
  }, [])

  let DataNameChange = user_contact_type === "Employee" ? employNameChanging : AssociateNameChanging
  let GetApplicationCountData1 = _.orderBy(_.filter(GetApplicationCountData, (d) => d.name !== "status"), ['score'], 'desc')
  let ApplicationData1 = _.filter(GetApplicationCountData1, (d) => d?.name?.search("_todaymodified") == -1)

  // let RegisterData = GetAssociateProjectStatus ? GetAssociateProjectStatus : {}

  // console.log(GetAssociateProjectStatus?.count?.total_contact)
  // let _todayRehisteredCountArray = []
  // _.map(Object.keys(RegisterData?.count), (d) => {
  //   if (`${d}`.replace('_todaymodified', " ") == d) {
  //     if(`${d}`.replace('acc_id', " ") == d){
  //       if(`${d}`.replace('_list_count', " ") == d){
  //         if(`${d}`.replace('_rm', " ") == d){
  //           if(`${d}`.replace('weekData', " ") == d){
  //     _todayRehisteredCountArray.push(parseInt(RegisterData[d]))
  //           }
  //         }
  //       }
  //     }
  //   }
  // })
  // let TotalValue = _.sumBy(_todayRehisteredCountArray, (d) => parseInt(d))
  // console.log(TotalValue)


  const getRegisteredData = () => {
    let string = `*Projects* :${GetAssociateProjectStatus?.count?.total_contact}%0D%0A`

    let grouped_data = _.groupBy(GetAssociateProjectStatus?.mentor_wise_rm, 'user_name' )
    // console.log(grouped_data)
    Object.keys(DataNameChange)?.map((register, id) => {
      // console.log(register)
      if(register === 'expel_rm'){
        string = string + `*${AssociateNameChanging?.[register] || ''}:${GetAssociateProjectStatus?.count?.[`${register}`] || GetAssociateProjectStatus?.[`${register}`]}*%0D%0A`
        _.map(grouped_data,function newVal(val,i){
          if(val?.[0]?.['user_name']){
        string = string + `${val?.[0]?.['user_name']}:${val?.length}%0D%0A`
          }
        })
      }
      else{
        string = string + `${AssociateNameChanging?.[register] || ''}:${GetAssociateProjectStatus?.count?.[`${register}`] || GetAssociateProjectStatus?.[`${register}`]}%0D%0A`
      }
    })
    return string
  }

  const getApplicationStuatusData = () => {
    let TotalValue = _.sumBy(ApplicationData1, (d) => parseInt(d.score))
    let string = `*Project Task* :${TotalValue}%0D%0A`
    let today_filling_string = ''
    let today_submitted_string = ''
    let today_filled_string = ''
    let tomorrow_filling_string = ''
    let month_filling_string = ''
    let month_submitted_string = ''
    let ready_for_submission = ''
    if (ApplicationData1.length > 0) {
      ApplicationData1.map((appData, i) => {
        if(appData.name === 'today_filling_count' || appData.name === 'today_filling_skip'){
          appData.name !== 'today_filling_skip'  
          ? today_filling_string = today_filling_string + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
          : mainApplicationData?.today_filling_skip?.map((val)=>{
              today_filling_string = today_filling_string + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
            })
        }
        else if(appData.name === 'today_submitted_count' || appData.name === 'today_submitted_skip'){
          appData.name !== 'today_submitted_skip' 
          ? today_submitted_string = today_submitted_string + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
          : mainApplicationData?.today_submitted_skip?.map((val)=>{
              today_submitted_string = today_submitted_string + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
            })
        
        }
        else if(appData.name === 'today_filled_count' || appData.name === 'today_filled_skip'){
          appData.name !== 'today_filled_skip' 
          ? today_filled_string = today_filled_string + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
          : mainApplicationData?.today_filled_skip?.map((val)=>{
            today_filled_string = today_filled_string + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
            })
        }
        else if(appData.name === 'tomorrow_filling_count' || appData.name === 'tomorrow_filling_skip'){
          appData.name !== 'tomorrow_filling_skip' 
          ? tomorrow_filling_string = tomorrow_filling_string + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
          : mainApplicationData?.tomorrow_filling_skip?.map((val)=>{
            tomorrow_filling_string = tomorrow_filling_string + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
            })
        }
        else if(appData.name === 'month_filling_count' || appData.name === 'month_filling_skip'){
          appData.name !== 'month_filling_skip' 
          ? month_filling_string = month_filling_string + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
          : mainApplicationData?.month_filling_skip?.map((val)=>{
            month_filling_string = month_filling_string + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
            })
      }
      else if(appData.name === 'month_submitted_count' || appData.name === 'month_submitted_skip'){
        appData.name !== 'month_submitted_skip' 
        ? month_submitted_string = month_submitted_string + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
        : mainApplicationData?.month_submitted_skip?.map((val)=>{
          month_submitted_string = month_submitted_string + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
          })
    }
    else if(appData.name === 'Ready for Submission_count' || appData.name === 'ready_for_submission_skip'){
      appData.name !== 'ready_for_submission_skip' 
      ? ready_for_submission = ready_for_submission + `*${Data[appData.name] || ''}*:${appData?.score || 0}%0D%0A`
      : mainApplicationData?.ready_for_submission_skip?.map((val)=>{
        ready_for_submission = ready_for_submission + `${val?.processed_by || ''}:${val?.count || 0}%0D%0A`
        })
  }
        else{
        string = string + `${Data[appData.name] || ''}:${appData?.score || 0}%0D%0A`
        }
      })
    }
    return string + '%0D%0A%0D%0A' + today_filling_string + '%0D%0A%0D%0A' + today_submitted_string + '%0D%0A%0D%0A' + today_filled_string + '%0D%0A%0D%0A' + tomorrow_filling_string + '%0D%0A%0D%0A' + month_filling_string + '%0D%0A%0D%0A' +  month_submitted_string + '%0D%0A%0D%0A' + ready_for_submission
  }

  const getString = () => {
    const UserData = getUser()
    let RegisteredStuatus = getRegisteredData()
    let ApplicationStuatus = getApplicationStuatusData()
    let TempData = `Dear Sir
    %0D%0A%0D%0A Please find the Daily Report ${moment().format('MMMM Do YYYY, h:mm:ss a')} 
    %0D%0A ${UserData?.firstname || ''} ${UserData?.lastname || ''} 
    %0D%0A
    %0D%0A%0D%0A ${RegisteredStuatus}
    %0D%0A%0D%0A ${ApplicationStuatus}
    %0D%0A
    %0D%0A
    %0D%0A ${UserData?.firstname || ''} ${UserData?.lastname || ''} 
    ${UserData?.assigned_phone_mobile ? `%0D%0A${UserData?.assigned_phone_mobile}` : ''}
    ${UserData?.assigned_phone_work ? `%0D%0A${UserData?.assigned_phone_work}` : ''}
    ${UserData?.assistantphone ? `%0D%0A${UserData?.assistantphone}` : ''}
    ${UserData?.assigned_email1 ? `%0D%0A${UserData?.assigned_email1}` : ''}
    ${UserData?.reports_to_email1 ? `%0D%0A${UserData?.reports_to_email1}` : ''}
    ${UserData?.account_website ? `%0D%0A${UserData?.account_website}` : ''}
   %0D%0A iOS: https://apple.co/33ujR4Q
   %0D%0A Android: https://bit.ly/FreeStudyAbroad`
    return `https://api.whatsapp.com/send?text=%0D%0A%0D%0A ${TempData.replaceAll('&', "and")}`
  }



  if (StudenLoader || RegisteredLoader || ApplicationsLoader) {
    // if (false) {
    return (<ContentLoader height={window.innerHeight} width={window.innerWidth} viewBox="0 0 265 230">
      <rect x="15" y="15" rx="4" ry="4" width="200" height="25" />
      <rect x="15" y="50" rx="2" ry="2" width="40" height="15" />
      <rect x="75" y="45" rx="16" ry="16" width="55" height="22" />
      <rect x="15" y="75" rx="3" ry="3" width="215" height="15" />
      <rect x="15" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="75" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="135" y="105" rx="3" ry="3" width="50" height="15" />
      <rect x="15" y="135" rx="16" ry="16" width="55" height="22" />
      <rect x="15" y="165" rx="2" ry="2" width="150" height="50" />
      <rect x="215" y="180" rx="2" ry="2" width="40" height="20" />
    </ContentLoader>)
  } else {
    return (
      <div className="dashboard_body container">
        {/* <div className="d-flex align-items-center justify-content-center"> */}
        <div className="transiction_container container" style={{margin:'auto'}}>
          {/* <h3 className="dashboard_title" style={{fontSize:'15px'}}>Dashboard</h3> */}
          <Tabs>
            <TabList className="tab_class" style={{ paddingLeft: 0, margin: 0 }} >
              <Tab className="dashboard_category" style={{ width: "25%" }}>Students</Tab>
              <Tab className="dashboard_category" style={{ width: "25%" }}>Registered</Tab>
              <Tab className="dashboard_category" style={{ width: "25%" }}>Applications</Tab>
              {/* <Tab className="dashboard_category" style={{ width: "25%" }}>Others</Tab> */}
            </TabList>
            <TabPanel>
              <>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className="transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title" style={{fontSize:'15px'}}>
                          {TotalStudentValue} 
                          {/* <ArrowDownward />{" "} */}
                        </h2>
                        {/* <h5 className="dollar_title">US dollar</h5> */}
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn"
                        style={{ fontWeight: "bold", fontSize: 13, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}
                        onClick={() => SetOpen(true)}>
                        + Add Student
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title" style={{fontSize:'15px'}}>Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title" style={{fontSize:'13px'}}>Students</h4>
                          <p className="dollar_title" style={{fontSize:'13px'}}>12:10</p>
                        </div>
                      </div>

                      <h4 className="dashboard_title" style={{fontSize:'13px'}}>{TotalStudentValue}</h4>
                    </div>
                  </div>
                </div>
              
              
              </section>
              <Suspense fallback={<div>Loading...</div>}>
              <Box display={{ xs: 'block', md: 'none' }}>
              <Analysics />
              
              <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
              <Students studentsData={getListCountByData} header="Students" />
              <br/>
              <Students studentsData={getListCountByBookmark} header="Bookmarked Students" type="bookmark" />
              <br/>
              <Students studentsData={getListCountByBookmark} header="Active Students" type="bookmark" />
              </Grid>   
            </Grid>
        
            </Box>
            </Suspense>
            </>
            </TabPanel>
            <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className=" transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $999 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn" style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}>
                        + Add
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Registered</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>
                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>

                <Suspense fallback={<div>Loading...</div>}>
              <Box display={{ xs: 'block', md: 'none' }}>
              <Analysics />
           
              <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
              <Registrated RegistratedData={GetAssociateProjectStatus ? GetAssociateProjectStatus : {}}
          DataNameChange={DataNameChange}
          employNameChanging={employNameChanging}
          AssociateNameChanging={AssociateNameChanging}
          getString={getString}
          TotalValue={GetAssociateProjectStatus?.count?.total_contact}
            />
              </Grid>   
            </Grid>
         
            </Box>
            </Suspense>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className=" transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $1111 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn" style={{ fontWeight: "bold", fontSize: 18, background: "#ebf2ff", borderRadius: 15, paddingLeft: 10, paddingRight: 10, color: "#266cfc" }}>
                        + Add Application
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Applications</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>

                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
              <Box  display={{ xs: 'block', md: 'none' }}>
              <Analysics />
           
              <Grid container>
              <Grid item xs={12} md={4}>
              <Applications
              getString={getString}
              ApplicationData={GetApplicationCountData}
              Data={Data}
              mainApplicationData={mainApplicationData}
              />
              </Grid>   
            </Grid>
         
            </Box>
            </Suspense>
              </section>
            </TabPanel>
            {/* <TabPanel>
              <section className="container" style={{ margin: 0, width: "100%", padding: 0 }}>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className=" transiction_box">
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <div>
                        <h2 className="dashboard_title">
                          $1200 <ArrowDownward />{" "}
                        </h2>
                        <h5 className="dollar_title">US dollar</h5>
                      </div>
                      <div>
                        <img src={usLogo} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-evenly">
                      <button className="transiction_btn">
                        <img src={addMony} alt="" />
                      </button>
                      <button className="transiction_btn">
                        <img
                          className="cursor_style"
                          src={sendMony}
                          alt=""
                        />
                      </button>
                      <button className="transiction_btn">
                        <img className="cursor_style" src={more} alt="" />
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <p className="dollar_title">Transictions</p>
                      <Link className="text-primary" to="#">
                        See all
                      </Link>
                    </div>
                    <div className="d-flex align-items-start justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img src={logo1} alt="" />
                        <div>
                          <h4 className="dashboard_title">Invoice</h4>
                          <p className="dollar_title">12:10</p>
                        </div>
                      </div>

                      <h4 className="dashboard_title">$99</h4>
                    </div>
                  </div>
                </div>
              </section>
            </TabPanel> */}
          </Tabs>
        </div>
        {/* </div> */}
        
        
        <Suspense fallback={<div>Loading...</div>}>
        <Box display={{ xs: 'none', md: 'block' }}>
        <Analysics />
        <div className="container-md" >
        <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
        <Students studentsData={getListCountByData} header="Students" />
        <br/>
        <Students studentsData={getListCountByBookmark} header="Bookmarked Students" type="bookmark" />
        <br/>
        <Students studentsData={getListCountByBookmark} header="Active Students" type="bookmark" />
        <br/>
        </Grid>
        <Grid item xs={12} md={4}>
        <Registrated RegistratedData={GetAssociateProjectStatus ? GetAssociateProjectStatus : {}}
          DataNameChange={DataNameChange}
          employNameChanging={employNameChanging}
          AssociateNameChanging={AssociateNameChanging}
          getString={getString}
          TotalValue={GetAssociateProjectStatus?.count?.total_contact}
        />
        </Grid>
        <Grid item xs={12} md={4}>
        <Applications
          getString={getString}
          ApplicationData={GetApplicationCountData}
          Data={Data}
          mainApplicationData={mainApplicationData}
        />
        </Grid>
     
      </Grid>
      </div>
      </Box>

     
       
        
        </Suspense>

        <BottomNav />
        <AddStudentModal open={open} onClose={() => SetOpen(false)} />

      </div>
    );
  };
}

export default Associate;
