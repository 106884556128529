import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Colors from "../../static/layout/Colors";
import PageTitle from "../PageTitle";
import { FormControl, InputLabel, MenuItem, Select, LinearProgress, Box, Button, Grid, Autocomplete, TextField, Chip, Stack } from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { CommonEmailSendAction, fetchRequiredDataAction, fetchStudentUploadDocumentAction, UploadDocumentAction } from "../Action";
import UploadDocumentModal from '../../components/Zolve/UploadDocumentModal'
import PreviewDocumentModal from '../../components/Zolve/PreviewDocumentModal'
import uniqid from 'uniqid';
import _ from 'lodash'
import { getUser, getUserId, getUserType } from "../../Controller/localStorageHandler";
import whatsapp from '../../img/whatsapp.png';
import Message from '../../img/Message.png';
import SearchBoxWithFilter from "../SearchBox/SearchBoxWithFilter";
import { AsyncMulti } from "../StudentSearch";
import { getStudentCountListUsingStatusAction1 } from "../Action/AssociateDocumentList";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const DataMapping = [
    {
        "document": "10th and 12th Marksheet",
        "title": "10th and 12th Marksheet",
        "category": "Academic",
    },
    {
        "document": "Accommodation Confirmation",
        "title": "Accommodation Confirmation",
        "category": "General",
    },
    {
        "document": "ACT",
        "title": "ACT",
        "category": "Academic",
    },
    {
        "document": "Admission Essay",
        "title": "Admission Essay",
        "category": "Academic",
    },
    {
        "document": "Affidavit Of Support",
        "title": "Affidavit Of Support",
        "category": "Visa",
    },
    {
        "document": "Agent Authorization",
        "title": "Agent Authorization",
        "category": "Document",
    },
    {
        "document": "Air Ticket Confirmation",
        "title": "Air Ticket Confirmation",
        "category": "Visa",
    },
    {
        "document": "All Semester Marksheet",
        "title": "All Semester Marksheet",
        "category": "Academic",
    },
    {
        "document": "Application Fee",
        "title": "Application Fee",
        "category": "General",
    },
    {
        "document": "Application Form",
        "title": "Application Form",
        "category": "Academic",
    },
    {
        "document": "ATAS",
        "title": "ATAS",
        "category": "Academic",
    },
    {
        "document": "Bank Certificate",
        "title": "Bank Certificate",
        "category": "Funding",
    },
    {
        "document": "Birth Certificate",
        "title": "Birth Certificate",
        "category": "Visa",
    },
    {
        "document": "Blocked Account",
        "title": "Blocked Account",
        "category": "Visa",
    },
    {
        "document": "Bonafied Certificate",
        "title": "Bonafied Certificate",
        "category": "Academic",
    },
    {
        "document": "CAQ",
        "title": "CAQ",
        "category": "Visa",
    },
    {
        "document": "Charted Account Report",
        "title": "Charted Account Report",
        "category": "Visa",
    },
    {
        "document": "Confirmation Of Acceptance For Studies-CAS",
        "title": "Confirmation Of Acceptance For Studies-CAS",
        "category": "Academic",
    },
    {
        "document": "Confirmation Of Enrolment-COE",
        "title": "Confirmation Of Enrolment-COE",
        "category": "Academic",
    },
    {
        "document": "Course Completion Certificate",
        "title": "Course Completion Certificate",
        "category": "Academic",
    },
    {
        "document": "Covering Letter",
        "title": "Covering Letter",
        "category": "Academic",
    },
    {
        "document": "Department Essay",
        "title": "Department Essay",
        "category": "Academic",
    },
    {
        "document": "Duolingo",
        "title": "Duolingo",
        "category": "Academic",
    },
    {
        "document": "Education Loan Sanction",
        "title": "Education Loan Sanction",
        "category": "Academic",
    },
    {
        "document": "Fee Payment Receipt",
        "title": "Fee Payment Receipt",
        "category": "Academic",
    },
    {
        "document": "Financial Matrix",
        "title": "Financial Matrix",
        "category": "Academic",
    },
    {
        "document": "Financial Support Form",
        "title": "Financial Support Form",
        "category": "Academic",
    },
    {
        "document": "French Language Certificate",
        "title": "French Language Certificate",
        "category": "Academic",
    },
    {
        "document": "GATE",
        "title": "GATE",
        "category": "Academic",
    },
    {
        "document": "German Language Certificate",
        "title": "German Language Certificate",
        "category": "Academic",
    },
    {
        "document": "GIC",
        "title": "GIC",
        "category": "Academic",
    },
    {
        "document": "GMAT",
        "title": "GMAT",
        "category": "Academic",
    },
    {
        "document": "GRE",
        "title": "GRE",
        "category": "Academic",
    },
    {
        "document": "I-20",
        "title": "I-20",
        "category": "Academic",
    },
    {
        "document": "I-94",
        "title": "I-94",
        "category": "Visa",
    },
    {
        "document": "IELTS",
        "title": "IELTS",
        "category": "Academic",
    },
    {
        "document": "IHS Health Insurance",
        "title": "IHS Health Insurance",
        "category": "Academic",
    },
    {
        "document": "ITRs Of Sponsors",
        "title": "ITRs Of Sponsors",
        "category": "Academic",
    },
    {
        "document": "LOR-1",
        "title": "LOR-1",
        "category": "Academic",
    },
    {
        "document": "LOR-2",
        "title": "LOR-2",
        "category": "Academic",
    },
    {
        "document": "LOR-3",
        "title": "LOR-3",
        "category": "Academic",
    },
    {
        "document": "Medical Certificate",
        "title": "Medical Certificate",
        "category": "Academic",
    },
    {
        "document": "Medium Of Instruction",
        "title": "Medium Of Instruction",
        "category": "Academic",
    },
    {
        "document": "Motivational Letter",
        "title": "Motivational Letter",
        "category": "Academic",
    },
    {
        "document": "NOC",
        "title": "NOC",
        "category": "Academic",
    },
    {
        "document": "Offer Letter",
        "title": "Offer Letter",
        "category": "Academic",
    },
    {
        "document": "Official Transcript",
        "title": "Official Transcript",
        "category": "Academic",
    },
    {
        "document": "Passport Copy",
        "title": "Passport Copy",
        "category": "Academic",
    },
    {
        "document": "PDC/Degree Certificate",
        "title": "PDC/Degree Certificate",
        "category": "Academic",
    },
    {
        "document": "PF/PPF Withdraw Certificate",
        "title": "PF/PPF Withdraw Certificate",
        "category": "Academic",
    },
    {
        "document": "Photo",
        "title": "Photo",
        "category": "Academic",
    },
    {
        "document": "Police Clearance Certificate",
        "title": "Police Clearance Certificate",
        "category": "Academic",
    },
    {
        "document": "Property Evaluation",
        "title": "Property Evaluation",
        "category": "Academic",
    },
    {
        "document": "PTE",
        "title": "PTE",
        "category": "Academic",
    },
    {
        "document": "Research Proposal-1",
        "title": "Research Proposal-1",
        "category": "Academic",
    },
    {
        "document": "Research Proposal-2",
        "title": "Research Proposal-2",
        "category": "Academic",
    },
    {
        "document": "Research Proposal-3",
        "title": "Research Proposal-3",
        "category": "Academic",
    },
    {
        "document": "Research Proposal-4",
        "title": "Research Proposal-4",
        "category": "Academic",
    },
    {
        "document": "Research Proposal-5",
        "title": "Research Proposal-5",
        "category": "Academic",
    },
    {
        "document": "Resume",
        "title": "Resume",
        "category": "Academic",
    },
    {
        "document": "SAT",
        "title": "SAT",
        "category": "Academic",
    },
    {
        "document": "SOP",
        "title": "SOP",
        "category": "Academic",
    },
    {
        "document": "TOEFL",
        "title": "TOEFL",
        "category": "Academic",
    },
    {
        "document": "Visa Checklist",
        "title": "Visa Checklist",
        "category": "Academic",
    },
    {
        "document": "Visa Application",
        "title": "Visa Application",
        "category": "Academic",
    },
    {
        "document": "Visa Copy",
        "title": "Visa Copy",
        "category": "Academic",
    },
    {
        "document": "WES",
        "title": "WES",
        "category": "Academic",
    },
    {
        "document": "Work Experience",
        "title": "Work Experience",
        "category": "Academic",
    }
]
const UploadDocuments = (props) => {


    const histroy = useHistory()

    const classes = useStyles();
    const [NeedDocumentList, _NeedDocumentList] = useState([])
    const [openUpload, setOpenUpload] = React.useState(false);
    const [openPreview, setOpenPreview] = React.useState(false);
    const [DocumentDataList, setDocumentDataList] = React.useState(false);
    const [UploadingFileData, SetUploadingFileData] = React.useState([]);
    const [ActiveUploadingData, SetActiveUploadingData] = React.useState({});
    const [SubmitButtonDisable, setSubmitButtonDisable] = React.useState(false)
    const [uploadFromRequired, setUploadFromRequired] = React.useState(false)
    const [RequiredLength, setRequiredLength] = React.useState(3)
    const [UploadedDocuments, setUploadedDocuments] = React.useState(3)
    const [SearchSelectId, setSearchSelectId] = React.useState(null)
    const [GetProfileRequiredDocument, setProfileRequiredDocument] = React.useState([])

    const [selectRequiredDocument, setselectRequiredDocument] = React.useState()

    // preview image
    const [viewImage,setViewImage] = React.useState(false)
    const [doc_type,setDoc_type] = React.useState('');
    const [image_url,setImage_url] = React.useState('');
    const [Student,setStudent] = React.useState([]);


    // backdrop
    const [backDrop,setBackDrop] = React.useState(false);

    useEffect(() => {
        setStudent(props?.user || getUser())
    }, [props])

    const handleOpenUpload = () => {
        setOpenUpload(true);
    };

    const handleCloseUpload = () => {
        setOpenUpload(false);
    };

    const handleOpenPreview = () => {
        setOpenPreview(true);
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    const saveDoc = (data) =>{
        handleCloseUpload();
        if(uploadFromRequired){
            handleSubmitButton()
        }
    }

    const GetUploadDocument = (contactid) => {
        console.log(Student?.contactid )
        var data = `{\n"contact_id":"${contactid ? contactid : Student?.contactid || getUserId()}","bypass":"validation"\n}`;
        fetchStudentUploadDocumentAction(data).then((data1) => {
            console.log(data1)
            setDocumentDataList({ ...data1 })
        }).catch((error) => {
            console.log("error", error)
        })
    }

    React.useEffect(() => {
        GetUploadDocument()
        fetchRequiredDataAction().then((reponse) => {
            _NeedDocumentList(reponse || [])
        }).catch((error) => {
            _NeedDocumentList([])
            console.log(error)
        })
        let ProfileData = getUser()
        GetReuiredDocumentFromProfile(ProfileData)
    }, []);

    React.useEffect(() => {
        if (props?.user) {
     
            GetReuiredDocumentFromProfile(props?.user)
            GetUploadDocument(props?.user?.contactid || '')
        }
    }, [props]);

    React.useEffect(() => {
        if (UploadingFileData <= 0) {
            setSubmitButtonDisable(true)
        } else {
            setSubmitButtonDisable(false)
        }
        _.map(_.cloneDeep(UploadingFileData), (d) => {
            if (!d.file) {
                setSubmitButtonDisable(true)
            }
        })
    }, [UploadingFileData])

    const handleSubmitButton = () => {
        setBackDrop(true)
        setUploadFromRequired(false)
        var data = new FormData();
        data.append('contact_id', Student?.contactid || getUserId());
        data.append('submit', 'a');
        data.append('from', 'react');
        data.append('contact_type', 'student');
        // data.append('Air', 'Air Ticket Confirmation');
        _.map(_.cloneDeep(UploadingFileData), (d) => {
            if (d.file) {
                let Data = _.find(DataMapping, (d1) => d1.document === d.title)
                data.append(Data.title, d.file); // Data.title 10th 
                data.append(Data.title, d.title); // Data.title 10th
            }
        })

        UploadDocumentAction(data).then((d) => {
            console.log(d)
            GetUploadDocument()
            SetUploadingFileData([])
            setBackDrop(false)
        }).catch((error) => {
            SetUploadingFileData([])
            GetUploadDocument()
            setBackDrop(false)
            console.log(error)
        })
    }



    const SubmitFilter = (DataId) => {

    }
    // setStudent(contact_no)
    const GetReuiredDocumentFromProfile = (ProfileData) => {
        let Data = ProfileData?.documents_required ? ProfileData.documents_required.split(" |##| ") : []
        setProfileRequiredDocument(Data)
    }

    let ProfileData = getUser()
    let user_contact_type = ProfileData?.user_contact_type || ''

    const getContactId = (DataId) => {
        var data = JSON.stringify({
            "contact_no": "",
            "status": "All",
            "year": "All",
            "semester": "All",
            "daterange": "",
            "limit_start": 0,
            "limit_end": 9,
            "search": DataId,
            "bypass": "validation"
        });
        getStudentCountListUsingStatusAction1(data).then((Data) => {
            if (Data?.data?.total > 0) {
                let contact_no = Data?.data?.list[0]
                setStudent(contact_no)
                GetReuiredDocumentFromProfile(contact_no)
                GetUploadDocument(contact_no?.contactid || '')
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }

    const getEmailString = () => {
        var data = new FormData();
        data.append('type', 'DocPend');
        data.append('contactid', Student?.contactid || getUserId());
        data.append('module', 'Emails');
        data.append('action', 'mailsend');
        data.append('frm_name', 'company_name');
        CommonEmailSendAction(data).then((data) => {
            toast.success(`email send Successfully`)
        }).catch((error) => {
            toast.error(`${error.message}`)
        })
    }

    const GetDocumentRequiredList = (User) => {
        let documents_required = User?.documents_required ? User.documents_required.split(" |##| ") : []
        let String = ''
        if (documents_required?.length > 0) {
            _.map((documents_required), (d, index) => {
                String = String + `${index + 1}) ${d}%0D%0A`
            })
            return String
        }
        return String
    }
     
    const getString = (Data) => {
        const UserData = getUser()
        const documents_required = GetDocumentRequiredList(Student ? Student : UserData)
        let TempData = `Dear ${Student?.firstname} ${Student?.lastname}  
        %0D%0A%0D%0AGreetings from *BRAINSTORM CONSULTING*
        %0D%0A%0D%0APlease find the list of *Documents Required*.*
        %0D%0A%0D%0APlease update your profile in our app for submitting your application to University after checking the eligibility.
        %0D%0A%0D%0AExplore 🏫 *60000* +  Programs and Universities, 10000 +  Scholarship, 5000 + SOP and LOR Samples. 
        %0D%0A%0D%0AAndroid: https://bit.ly/FreeStudyAbroad
        %0D%0AiOS: https://apple.co/33ujR4Q 
        ${documents_required ? `%0D%0A%0D%0A*Document Required*📑 %0D%0A ${documents_required}` : ''} 
        %0D%0A%0D%0A *Upload* : https://youtu.be/ix173Ac0hQA  
        %0D%0A%0D%0A *Mentor*:${UserData?.firstname || ''} ${UserData?.lastname || ''} 
         ${UserData?.assigned_phone_mobile ? `%0D%0A${UserData?.assigned_phone_mobile}` : ''}
         ${UserData?.assigned_phone_work ? `%0D%0A${UserData?.assigned_phone_work}` : ''}
         ${UserData?.assistantphone ? `%0D%0A${UserData?.assistantphone}` : ''}
         ${UserData?.assigned_email1 ? `%0D%0A${UserData?.assigned_email1}` : ''}
         ${UserData?.reports_to_email1 ? `%0D%0A${UserData?.reports_to_email1}` : ''}
         ${UserData?.account_website ? `%0D%0A${UserData?.account_website}` : ''}  
         %0D%0A%0D%0A*BRAINSTORM CONSULTING*
         %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
         %0D%0A iOS: https://apple.co/33ujR4Q        
        `
        return `https://api.whatsapp.com/send?phone=${Student?.mobile || UserData?.mobile || ''}&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")}`
    }

    const openImage = (name,url) => {
        setImage_url(url)
        setDoc_type(name)
        setViewImage(true);
      };
      const closeImage = () =>{
        setViewImage(false);
        setImage_url('')
        setDoc_type('')
      }
      if(backDrop){
    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDrop}
        onClick={()=>setBackDrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      }
    return (
        <div className="">
            {getUserType() !== "Student" ?
            <>   
           
                <div style={{ position: "relative", marginBottom: 20 }}>
                    {getUserType !== "Student" && <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <AsyncMulti inputData={Student} setSearchSelectId={getContactId} SubmitFilter={SubmitFilter} />
                    </div>}
                    <h3 >Documents</h3>
                </div>
                <Chip label="Upload Documents" color="warning"/>
                <FormControl style={{ marginTop: '3%', borderTop: "1px solide black" }} fullWidth>
                
                    {/* <label style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10, color: "orange" }}>Upload Document</label> */}
                    <Select style={{ border: "1px solid" }} defaultValue="Select your Document" onChange={({ target }) => {
                        let newdata = {}
                        newdata[`id`] = uniqid()
                        newdata[`title`] = target.value
                        newdata["active"] = false
                        newdata["file"] = null
                        UploadingFileData.push(newdata)
                        SetUploadingFileData([...UploadingFileData])
                    }}>
                        <MenuItem value={'Select your Document'} inset>Select your Document</MenuItem>
                        {NeedDocumentList && NeedDocumentList.length > 0 && NeedDocumentList.map((d) => {
                            return <MenuItem value={d?.document} inset>{d?.document}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                {!uploadFromRequired && UploadingFileData.length > 0 ?
                    <>
                        <div style={{marginTop:"10px"}}>
                            {/* <Chip label="Upload Documents" color="warning"/> */}
                            <PageTitle pageTitle='Upload Documents' bold />
                        </div>
                        {!uploadFromRequired ? UploadingFileData.map((d) => {
                            return <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                                <main style={{ marginTop: '4%', display: "inline-block" }}>
                                    <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                                </main>
                                <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                                    <nav>
                                        <div className={classes.bar}>
                                            <p style={{
                                                fontWeight: 'bold', fontSize: "13px"
                                            }}> {d.title}</p>
                                            {/* <Box sx={{ width: '140%' }}>
                                        <LinearProgress variant="determinate" value={progress} />
                                    </Box> */}
                                        </div>
                                    </nav>
                                </main>
                                <p style={{ fontWeight: 'normal', color: '#888888', display: "inline-block" }}>
                                    <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={() => {

                                        SetActiveUploadingData(d)
                                        handleOpenUpload()
                                    }} />
                                    <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} onClick={() => {
                                        let selectedNeedDocumentList1 = UploadingFileData.filter((d1) => d1.id !== d.id)
                                        SetUploadingFileData([...selectedNeedDocumentList1])
                                    }} />
                                    <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} />
                                </p>
                            </div>
                        }) : null}
                    </>
                    : ""}
            <Box sx={{ marginLeft: 0, marginRight: 0, borderRadius: 2 }}>
                <Grid container spacing={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
                    <Grid item container xs={12} sx={{ width: "55%", display: "flex", justifyContent: "left", alignItems: "left",flexDirection:"column" }} onClick={() => {
                        histroy.push({ pathname: '/Profile', state: { StudentData: Student, formNo: 1 } })
                    }}> 

                        <Stack direction="row" spacing={0.5} marginBottom={2}>
                        <Grid item xs={4}>
                        <Stack sx={{bgcolor:"#00008B",textAlign:"center",borderRadius:"20px",maxWidth:"200px"}}>
                        <p style={{  fontSize: 20, fontWeight: "bold",color: "#FCEDDA", cursor: "pointer",height:"13px"  }} ><span> {Student && Student?.firstname} {Student && Student?.lastname}</span></p>
                        </Stack>
                        </Grid>
                        <Grid item xs={8}>
                        <Stack sx={{bgcolor:"#002147",textAlign:"center",borderRadius:"20px",maxWidth:"150px"}}>
                        <p style={{ fontSize: 20, fontWeight: "bold", color: "#FCEDDA", cursor: "pointer",height:"13px" }} ><span> {Student && Student?.mobile}</span></p>
                        </Stack>
                        </Grid>
                        </Stack>
                        <Grid item xs={12}>
                        <Stack sx={{bgcolor:"white",textAlign:"center",borderRadius:"20px",maxWidth:"300px"}}>
                        <Typography style={{ fontSize: 20, fontWeight: "bold", color: "#00008B", cursor: "pointer",paddingLeft:"5px",paddingRight:"5px" }} ><span> {Student && Student?.email}</span></Typography>
                        </Stack>
                        </Grid>
                    </Grid>
                    {/* {user_contact_type !== "Student" &&
                        <Grid item xs={3}  sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between",marginTop:"120px" }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                                height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                            }}>
                                <span onClick={() => getEmailString()}  >

                                    <img src={`${Message}`} style={{ height: 30, width: 30 }} />
                                </span>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                                height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                            }}>
                                <a href={getString()} target="_blank" >
                                    <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                                </a>
                            </div>
                        </Grid>
                    } */}
                </Grid>
                <hr />
            </Box>
            </>
            :
            <>
            <Chip label="Upload Documents" color="warning"/>
                <FormControl style={{ marginTop: '3%', borderTop: "1px solide black" }} fullWidth>
                
                    {/* <label style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10, color: "orange" }}>Upload Document</label> */}
                    <Select style={{ border: "1px solid" }} defaultValue="Select your Document" onChange={({ target }) => {
                        let newdata = {}
                        newdata[`id`] = uniqid()
                        newdata[`title`] = target.value
                        newdata["active"] = false
                        newdata["file"] = null
                        UploadingFileData.push(newdata)
                        SetUploadingFileData([...UploadingFileData])
                    }}>
                        <MenuItem value={'Select your Document'} inset>Select your Document</MenuItem>
                        {NeedDocumentList && NeedDocumentList.length > 0 && NeedDocumentList.map((d) => {
                            return <MenuItem value={d?.document} inset>{d?.document}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl> 
                {!uploadFromRequired && UploadingFileData.length > 0 ?
                    <>
                        <div style={{marginTop:"10px"}}>
                            {/* <Chip label="Upload Documents" color="warning"/> */}
                            <PageTitle pageTitle='Upload Documents' bold />
                        </div>
                        {!uploadFromRequired ? UploadingFileData.map((d) => {
                            return <div style={{ display: "inline-block", width: "100%" }} className="flexCenterJcB">
                                <main style={{ marginTop: '4%', display: "inline-block" }}>
                                    <img alt={''} src={`${process.env.PUBLIC_URL}/img/documents/pdf.png`} />
                                </main>
                                <main className="flexCenterJcB" style={{ display: "inline-block", width: "60%", paddingLeft: "10px" }}>
                                    <nav>
                                        <div className={classes.bar}>
                                            <p style={{
                                                fontWeight: 'bold', fontSize: "13px"
                                            }}> {d.title}</p>
                                            {/* <Box sx={{ width: '140%' }}>
                                        <LinearProgress variant="determinate" value={progress} />
                                    </Box> */}
                                        </div>
                                    </nav>
                                </main>
                                <p style={{ fontWeight: 'normal', color: '#888888', display: "inline-block" }}>
                                    <img alt={''} src={`${process.env.PUBLIC_URL}/img/Paper-Upload.png`} onClick={() => {

                                        SetActiveUploadingData(d)
                                        handleOpenUpload()
                                    }} />
                                    <img style={{ marginRight: "5px", marginLeft: "5px" }} alt={''} src={`${process.env.PUBLIC_URL}/img/Close-Square.png`} onClick={() => {
                                        let selectedNeedDocumentList1 = UploadingFileData.filter((d1) => d1.id !== d.id)
                                        SetUploadingFileData([...selectedNeedDocumentList1])
                                    }} />
                                    <img alt={''} src={`${process.env.PUBLIC_URL}/img/Show.png`} onClick={handleOpenPreview} />
                                </p>
                            </div>
                        }) : null}
                    </>
                    : ""} 
            </>
            }
            
            <div className="global-container p-0">
                {DocumentDataList?.data?.list?.length > 0 &&
                    <Grid
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}>

                        <Grid item xs={9} >
                            <Chip label="Uploaded Documents" color="success" />
                            {/* <PageTitle pageTitle="Uploaded Documents" bold /> */}
                        </Grid>
                       
                    </Grid>}

                {_.cloneDeep((DocumentDataList?.data?.list || [])).splice(0, UploadedDocuments).map((d, i) => {
                    return <div className={`${classes.header} flexCenterJcB`}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <main style={{
                                marginBottom: '0', height: 30, width: 30,
                                border: "1px solid transparent",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "50px",
                                justifyContent: "center",
                                backgroundColor: "#46a719"
                            }}>
                                <p style={{ marginBottom: '0' }} className={classes.no}>{i + 1} </p>
                            </main>
                            <span style={{ marginLeft: 10 }}>{d?.document_type || ''}</span>
                        </div>
                        <main className={classes.viewDiv}
                        // onClick={() => setViewDocumentModal(true)}
                        >
                            <a onClick={()=>openImage(d?.document_type,`https://erp.bscglobaledu.com/${d.path}/${d.attachmentsid}_${d.name}` )} 
                            // href={`https://erp.bscglobaledu.com/${d.path}/${d.attachmentsid}_${d.name}`} 
                            style={{ marginBottom: '0' }} className={classes.view}>View</a>
                        </main>
                    </div>
                })}
                {DocumentDataList?.data?.list?.length > 3 &&
                    <>
                        {DocumentDataList?.data?.list?.length !== UploadedDocuments ? <div onClick={() => {
                            setUploadedDocuments(DocumentDataList?.data?.list?.length)
                        }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show More</div>
                            : <div onClick={() => {
                                setUploadedDocuments(3)
                            }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show Less</div>
                        } </>
                }
                {GetProfileRequiredDocument?.length > 0 &&
                    <div style={{ margin: '8% 0px 2% 0px' }} >
                        <Chip label="Required Documents" color="error" />
                        {/* <PageTitle pageTitle='Required Documents' bold /> */}
                        <>
                            {_.cloneDeep(GetProfileRequiredDocument)?.splice(0, RequiredLength).map((d, i) => {
                                // console.log(d)
                                return <div className={`${classes.header} flexCenterJcB`}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <main style={{
                                            marginBottom: '0', height: 30, width: 30,
                                            border: "1px solid transparent",
                                            display: "flex",
                                            alignItems: "center",
                                            borderRadius: "50px",
                                            justifyContent: "center",
                                            backgroundColor: "orange"
                                        }}>
                                            <p style={{ marginBottom: '0' }} className={classes.no}>{i + 1} </p>
                                        </main>
                                        <span style={{ marginLeft: 10 }}>{d}</span>
                                    </div>
                                    <main className={classes.viewDiv}
                                    // onClick={() => setViewDocumentModal(true)}
                                    >
                                        <div onClick={() => {
                                            let newdata = {}
                                            newdata[`id`] = uniqid()
                                            newdata[`title`] = d
                                            newdata["active"] = false
                                            newdata["file"] = null
                                            UploadingFileData.push(newdata)
                                            SetUploadingFileData([...UploadingFileData])
                                
                                            SetActiveUploadingData(newdata)
                                            handleOpenUpload()
                                            setUploadFromRequired(true)

                                        }} style={{ marginBottom: '0' }} className={classes.view}>Upload</div>
                                    </main>
                                </div>
                            })
                            }
                        </>
                        {GetProfileRequiredDocument?.length > 5 &&
                            <>
                                {GetProfileRequiredDocument.length !== RequiredLength ? <div onClick={() => {
                                    setRequiredLength(GetProfileRequiredDocument.length)
                                }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show More</div>
                                    : <div onClick={() => {
                                        setRequiredLength(3)
                                    }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show Less</div>
                                } </>
                        }
                    </div>
                }


            
               
               
                    
                <div className={classes.submitImage}>
                    <Button onClick={() => handleSubmitButton()} disabled={SubmitButtonDisable} style={{ background: SubmitButtonDisable ? "#666666" : "#1b6cfc", width: "95%", height: 50, color: "white", borderRadius: 25 }}>
                        Submit
                        {/* <img alt={''} style={{ maxWidth: "100%" }} src={`${process.env.PUBLIC_URL}/img/documents/submit.png`} /> */}
                    </Button>
                </div>
            </div>
            <UploadDocumentModal open={openUpload} handleClose={handleCloseUpload} save={saveDoc} ActiveUploadingData={ActiveUploadingData} SetUploadingFileData={(data) => {
                let Data = UploadingFileData.map((d) => {
                    if (d.id === data.id) {
                        return data
                    } else {
                        return d
                    }
                })
                SetUploadingFileData(Data)
                // console.log(Data)
            }} />
            <PreviewDocumentModal open={openPreview} handleClose={handleClosePreview} />

            {/* view image */}

      <Dialog
        open={viewImage}
        onClose={closeImage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {setDoc_type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <img src={image_url} height="300px" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={closeImage}>
            Close
          </Button>
        </DialogActions>
      </Dialog>



        </div >
    );
};

export default UploadDocuments;

const useStyles = makeStyles(() => ({
    docNo: {
        border: "1px solid transparent",
        borderRadius: "50px",
        height: "50px",
        width: "50px",
        backgroundColor: "#46a719",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        marginTop: '5%',
        backgroundColor: '#ffffff',
        borderRadius: '30px',
        padding: '3px 15px',
    },
    no: {
        textAlign: "center",
        fontSize: "17px",
        color: "white",
        fontWeight: "bold",
    },
    viewDiv: {
        border: "1px solid transparent",
        width: "fit-content",
        padding: "0px 15px 0px 15px",
        borderRadius: '25px',
        backgroundColor: Colors.lightBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    view: {
        fontWeight: 'bold',
        color: Colors.blue,
        padding: '5px',
    },
    submitImage: {
        marginTop: '10%',
        display: 'flex',
        justifyContent: 'center'
    },
    bar: {
        display: 'flex',
        flexDirection: 'column'
    }
}));
