import React from 'react' 
import SignUpForm from './Form'
import Head from './Head'
import { Grid } from '@mui/material'

const SignUp = () => {
    return (
        <Grid sx={{ backgroundColor:"#fafafa"}}>
        <div style={{ position: "absolute", width: "90%", bottom: 50 }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 50 }}>
                <img src={`${process.env.PUBLIC_URL}/img/LoginLogo.png`} style={{height: "88px"}} alt="" />
            </div>
            <Head />
            <SignUpForm />
        </div>
        </Grid>
    )
}
export default SignUp