import AUSTRALIA from '../../../../img/country images/AUSTRALIA.png'
import AUSTRIA from '../../../../img/country images/AUSTRIA.png'
import BELGIUM from '../../../../img/country images/BELGIUM.png'
import CANADA from '../../../../img/country images/CANADA.png'
import CROATIA from '../../../../img/country images/CROATIA.png'
import CYPRUS from '../../../../img/country images/CYPRUS.png'
import CZECH from '../../../../img/country images/CZECH REPUBLIC.png'
import DENMARK from '../../../../img/country images/DENMARK.png'
import ESTONIA from '../../../../img/country images/ESTONIA.png'
import FINLAND from '../../../../img/country images/FINLAND.png'
import FRANCE from '../../../../img/country images/FRANCE.png'
import GERMANY from '../../../../img/country images/GERMANY.png'
import GREECE from '../../../../img/country images/GREECE.png'
import HUNGARY from '../../../../img/country images/HUNGARY.png'
import ICELAND from '../../../../img/country images/ICELAND.png'
import IRELAND from '../../../../img/country images/IRELAND.png'
import ISRAEL from '../../../../img/country images/ISRAEL.png'
import ITALY from '../../../../img/country images/ITALY.png'
import LATVIA from '../../../../img/country images/LATVIA.png'
import LITHUANIA from '../../../../img/country images/LITHUANIA.png'
import MALAYSIA from '../../../../img/country images/MALAYSIA.png'
import MALTA from '../../../../img/country images/MALTA.png'
import MAURITIUS from '../../../../img/country images/MAURITIUS.png'
import NETHERLAND from '../../../../img/country images/NETHERLAND.png'
import NEW from '../../../../img/country images/NEW ZEALAND.png'
import NORWAY from '../../../../img/country images/NORWAY.png'
import POLAND from '../../../../img/country images/POLAND.png'
import RUSSIA from '../../../../img/country images/RUSSIA.png'
import SINGAPORE from '../../../../img/country images/SINGAPORE.png'
import SOUTH from '../../../../img/country images/SOUTH KOREA.png'
import SPAIN from '../../../../img/country images/SPAIN.png'
import SWEDEN from '../../../../img/country images/SWEDEN.png'
import SWITZERLAND from '../../../../img/country images/SWITZERLAND.png'
import UAE from '../../../../img/country images/UAE.png'
import UK from '../../../../img/country images/UK.png'
import USA from '../../../../img/country images/USA.png'

export const SliderData = [
    {
        image: USA,
        content: "USA",
        Scontent: "50 Reviews",
    },
    {
        image: UAE,
        content: "UAE",
        Scontent: "50 Reviews",
    },
    {
        image: UK,
        content: "UK",
        Scontent: "50 Reviews",
    },
   
    {
        image: POLAND,
        content: "POLAND",
        Scontent: "50 Reviews",
    },
    {
        image: RUSSIA,
        content: "RUSSIA",
        Scontent: "50 Reviews",
    },
    {
        image: SINGAPORE,
        content: "SINGAPORE",
        Scontent: "50 Reviews",
    },
    {
        image: SOUTH,
        content: "SOUTH",
        Scontent: "50 Reviews",
    },
    {
        image: SPAIN,
        content: "SPAIN",
        Scontent: "50 Reviews",
    },
    {
        image: SWEDEN,
        content: "SWEDEN",
        Scontent: "50 Reviews",
    },
    {
        image: SWITZERLAND,
        content: "SWITZERLAND",
        Scontent: "50 Reviews",
    },
    {
        image: CZECH,
        content: "CZECH",
        Scontent: "50 Reviews",
    },
    {
        image: DENMARK,
        content: "DENMARK",
        Scontent: "50 Reviews",
    },
    {
        image: ESTONIA,
        content: "ESTONIA",
        Scontent: "50 Reviews",
    },
    {
        image: FINLAND,
        content: "FINLAND",
        Scontent: "50 Reviews",
    },
    {
        image: FRANCE,
        content: "FRANCE",
        Scontent: "50 Reviews",
    },
    {
        image: GERMANY,
        content: "GERMANY",
        Scontent: "50 Reviews",
    },
    {
        image: GREECE,
        content: "GREECE",
        Scontent: "50 Reviews",
    },
    {
        image: HUNGARY,
        content: "HUNGARY",
        Scontent: "50 Reviews",
    },
    {
        image: ICELAND,
        content: "ICELAND",
        Scontent: "50 Reviews",
    },
    {
        image: IRELAND,
        content: "IRELAND",
        Scontent: "50 Reviews",
    },
    {
        image: ISRAEL,
        content: "ISRAEL",
        Scontent: "50 Reviews",
    },
    {
        image: ITALY,
        content: "ITALY",
        Scontent: "50 Reviews",
    },
    {
        image: LATVIA,
        content: "LATVIA",
        Scontent: "50 Reviews",
    },
    {
        image: LITHUANIA,
        content: "LITHUANIA",
        Scontent: "50 Reviews",
    },
    {
        image: MALAYSIA,
        content: "MALAYSIA",
        Scontent: "50 Reviews",
    },
    {
        image: MALTA,
        content: "MALTA",
        Scontent: "50 Reviews",
    },
    {
        image: MAURITIUS,
        content: "MAURITIUS",
        Scontent: "50 Reviews",
    },
    {
        image: NETHERLAND,
        content: "NETHERLAND",
        Scontent: "50 Reviews",
    },
    {
        image: NEW,
        content: "NEW",
        Scontent: "50 Reviews",
    },
    {
        image: NORWAY,
        content: "NORWAY",
        Scontent: "50 Reviews",
    },
    {
        image: CROATIA,
        content: "CROATIA",
        Scontent: "50 Reviews",
    },
    {
        image: AUSTRALIA,
        content: "AUSTRALIA",
        Scontent: "50 Reviews",
    },
    {
        image: AUSTRIA,
        content: "AUSTRIA",
        Scontent: "50 Reviews",
    },
    {
        image: BELGIUM,
        content: "BELGIUM",
        Scontent: "50 Reviews",
    },
    {
        image: CANADA,
        content: "CANADA",
        Scontent: "50 Reviews",
    },


]