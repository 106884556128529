import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styled} from "@mui/system";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function BasicSelect1({inputData,onChangeHandler,naam,totals}) {
  const theme = useTheme();
 

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   settoeflTotalList(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
      
  //   );
  // };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="label">{naam}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={inputData}
          onChange={onChangeHandler}
          defaultValue="Options"
          input={<OutlinedInput label={naam}/>}
          MenuProps={MenuProps}
        >
          {totals.map((name) => (
            <MenuItem
              key={name}
              value={name+""}
              style={getStyles(name, inputData, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}



function getStyles(name, toeflTotalList, theme) {
  return {
    fontWeight:
    toeflTotalList.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default BasicSelect1;