import React, { useEffect } from 'react';
import {  Route, Switch } from "react-router-dom";

// import context
import {AppProvider} from "./useContext/userContext"

import "./App.css";
import CollegeDetails from './components/College/Details';
import UniversitySearchResult from './components/Result/UniversitySearchResult';
import Notifications from './components/Notification'
import Accomodation from "./components/Accomodation";
import SignIn from './components/Forms/SignIn';
import SignUp from './components/Forms/SignUp'
import ForgetPassword from './components/Passwords/Forget';
import Otp from './components/Otp';
import RecoverPassword from './components/Passwords/RecoverPass';
import MentorList from './components/Mentors';
import MentorDetails from './components/Mentors/MentorDetails';
import DashBoard from './components/Dashboards';
import EducationLoanList from './components/Education';
import StudentList from './components/Student/StudentList/StudentList';
import Associate from './components/Associate';
import Faq from './components/Faq'

import ComingSoon from "./components/Global/ComingSoon";
import SearchProgram from "./components/Common/Search/SearchProgram";
import SearchScholarship from "./components/Common/Search/SearchScholarship";

// auth components
import MainComponent from "./Controller/auth/MainComponent";
import AuthAssociates from "./Controller/auth/Associates";


import SearchSop from "./components/Common/Search/SearchSop";
import SearchUniversity from "./components/Common/Search/SearchUniversity";
import ProgramSearchResult from "./components/Result/ProgramSearchResult";
import SopSearchResult from "./components/Result/SopSearchResult";
import ScholarshipSearchResult from "./components/Result/ScholarshipSearchResult";
import InviteShare from "./components/InviteShare";
// import UniversityFavourites from "./components/University/UniversityFavourites";
import EventsList from "./components/Event";
import EventDetails from "./components/Event/List-Details/EventDetails";
import EventsCardDetails from "./components/Event/List-Details/EventsCardDetails";
import ServicesList from "./components/Services/ServicesList";
import ScholarshipDetails from "./components/Result/ScholarshipDetails";
import ProfileEvaluation from "./components/ProfileEvaluation";
import PossibleOptions from "./components/PossibleOptions";
import UploadDocuments from "./components/Documents";
import ServicesDetails from "./components/Services/ServicesDetails";
import Profile from './components/Profile/ProfilePage';
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import WhatsappGroup from './components/WhatsAppGroup';
import TelegramGroup from './components/TelegramGroup';
import FacebookGroup from './components/FacebookGroup';
import Zolve from "./components/Zolve";
import LowTutionFee from './components/LowTutionFee';
import GREAccepted from './components/GREAccepted/Index';
import Status from './components/Status/index';
// import { fetchProfileDetails } from './components/Action';
// import { OPERATION, operationValue } from './Controller/Global';
// import { Contact_Id, getSessionId, getUserId, SESSION_ID, setProfileDetails, setUser, USER_ID } from './Controller/localStorageHandler';
import 'react-toastify/dist/ReactToastify.css';
import { Onboard } from './components/Onbaord';
import TransferUniversity from './components/Common/Search/TransferUniversity';
import CurrentUniversity from './components/Common/Search/CurrentUniversity';
import DocumentCheckList from './components/Documents/DocumentCheck';
// import axios from 'axios';
import ApplyIntenship from './components/applyinternship';
import { AddAccomodation } from './components/Accomodation/AddAccomodationForm';
import { AddEvent } from './components/Event/EventForm';
// import { AddEducationForm } from './components/Education/EducationLoanForm';
import { Agreement } from './components/Agreement';
import { RelatedProgrames } from './components/RelatedPrograms';
import ApplicationStatus from './components/ApplicationStatus';
import { HashRouter } from 'react-router-dom';
import AssociateUniversityFavourites from './components/University/AssociateUniversityFavourites';

import InternshipDashboard from './components/InternshipDashboard/index';
import EducationLoanDashBoard from './components/EducationLoanDashboard';
import UniversityDashBoard from './components/UniversityDashBoard';
import AccommodationDashBoard from './components/AccommodationDashBoard';
import Intenship from './components/InternshipAllModule/Intenship';
import AddInternshipDetails from './components/InternshipAllModule/AddInternshipDetails';
import Candidate from './components/InternshipAllModule/Candidate';
import UniverSityApplication from './components/UniversitiesPages/Applicatios';
import Property from './components/AccommodationPages/Property/index';
import UniversityEventList from './components/UniversitiesPages/Event';
import UniversityProspectives from './components/UniversitiesPages/Prospectives';
import AddUniversityEvent from './components/UniversitiesPages/Event/AddEvent';
// var CronJob = require('cron').CronJob;
import University_Front from './components/University_front/index';
import BottomNav from "./components/Common/BottomNav";
import Students from "./components/College/Details/StudentList.js";
import Navbar from "./components/Common/Navbar";
import RegisterFrom from './components/RegisterFrom';
import TransferUniversityForm from './components/TransferUniversityForm';
import VisaMock from './components/VisaMock';
import UniversitySearch from './components/Common/Search/UniversitySearch';
import SearchUniversityResult from './components/Result/SearchUniversityResult';



const theme = createTheme({
    typography: {
        fontFamily: [
            'gothic',
            'sans-serif'
        ].join(','),
    }
});


function App() {


    // const _geoCodeCallback = (results, status, event) => {
    //     const google = window.google; // eslint-disable-line
    //     if (status === google.maps.GeocoderStatus.OK) {
    //         if (results[0]) {
    //             const add = results[0].formatted_address;
    //             const value = add.split(",");
    //             const count = value.length;
    //             const city = value[count - 3];
    //             debugger
    //             // here we can dispatch action to search by city name and autofill the autocomplete
    //         } else {
    //             console.log("address not found");
    //         }
    //     } else {
    //         console.log(status);
    //     }
    // }
    // const _geocodeAddress = (geocoder, latlng) => {
    //     debugger
    //     geocoder.geocode({ location: latlng }, _geoCodeCallback);
    // }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            localStorage.setItem('loaction', JSON.stringify({ latitude: position.coords.latitude, longitude: position.coords.longitude }))
        });
    }, [])
    // useEffect(() => {
    //     var job = new CronJob('* * * * * *', function () {
    //         console.log('You will see this message every second');
    //     }, null, true, 'America/Los_Angeles');
    //     job.start();
    // }, [])

    // useEffect(() => {
    //     let userId = getUserId()
    //     if (userId) {
    //         var formdata = new FormData();
    //         formdata.append(Contact_Id, getUserId());
    //         formdata.append(SESSION_ID, getSessionId())
    //         formdata.append(USER_ID, getUserId())
    //         formdata.append(OPERATION, operationValue.Fetch_profile_details)

    //         // fetchProfileDetails(formdata).then((d) => {
    //         //     setUser(d)
    //         // }).catch((error) => {

    //         // })
    //         axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', formdata)
    //         .then((response) => {
    //             setUser(response.data)
    //         })
    //     }

    // }, [])
    return (
        <React.Fragment>
            <AppProvider>
            {/* <DataContext> */}
            <ThemeProvider theme={theme}>
                <div className="app_body">
                    <HashRouter fallback={<span />}>
                    <Navbar />
                        <Switch>
                            <div className="global-container">
                                <Route restricted path="/login" exact component={SignIn} />
                                <Route restricted path="/SignUp" exact component={SignUp} />
                                <Route restricted path="/SignUp/:code" exact component={SignUp} />
                                <Route restricted path="/" exact component={UniversitySearch} />
                                <Route restricted path="/token/:token" exact component={UniversitySearch} />
                                <Route restricted path="/forgetPassword" component={ForgetPassword} />
                                <Route exact path="/privacy-policy-front" component={Agreement} />
                                <Route exact path="/EventsList" component={EventsList} />
                                <Route exact path="/EventDetails/" component={EventDetails} />
                                <Route exact path="/EventDetails/:id" component={EventDetails} />
                                <Route exact path="/WhatsappGroup" component={WhatsappGroup} />
                                <Route exact path="/WhatsappGroup/:id" component={WhatsappGroup} />
                                <Route exact path="/TelegramGroup" component={TelegramGroup} />
                                <Route exact path="/TelegramGroup/:id" component={TelegramGroup} />
                                <Route exact path="/FacebookGroup" component={FacebookGroup} />
                                <Route exact path="/FacebookGroup/:id" component={FacebookGroup} />
                                <Route exact path="/RegisterForm" component={RegisterFrom} />
                                <Route exact path="/RegisterForm/:number" component={RegisterFrom} />
                                <Route exact path="/schedule/:type" component={VisaMock} />
                                <Route exact path="/TransferUniversityForm" component={TransferUniversityForm} />
                                <Route exact path="/TransferUniversityForm/:number" component={TransferUniversityForm} />
                                <Route exact path="/UniversitySearch" component={UniversitySearch}/>
                                <Route exact path="/SearchUniversityResult" component={SearchUniversityResult}/>

                                <MainComponent exact path="/recoverPassword" component={RecoverPassword} />
                                <MainComponent path="/dashboard" exact component={DashBoard} />
                                <MainComponent exact path="/student-list" component={StudentList} />
                                <MainComponent exact path="/student-list/:id" component={StudentList} />

                                {/* student list from college details page */}
                                <MainComponent exact path="/students" component={Students} />
                                <MainComponent exact path="/application" component={Students} />


                                <MainComponent exact path="/EducationLoanList" component={EducationLoanList} />
                                <MainComponent exact path="/UniversitySearchResult" component={UniversitySearchResult} />
                                <MainComponent exact path="/ScholarshipSearchResult" component={ScholarshipSearchResult} />
                                <MainComponent exact path="/SopSearchResult" component={SopSearchResult} />
                                <MainComponent exact path="/ProgramSearchResult" component={ProgramSearchResult} />
                                {/* <MainComponent exact path="/University/:name" component={University_Front} /> */}

                                {/* associates */}
                                <AuthAssociates exact path="/associates" component={Associate} />
                                <AuthAssociates exact path="/application-status" component={ApplicationStatus} />

                                <Route exact path="/accomodation" component={Accomodation} />
                                <MainComponent exact path="/applyinternship" component={ApplyIntenship} />
                                <MainComponent exact path="/Otp" component={Otp} />
                                <MainComponent exact path="/mentorList" component={MentorList} />
                                <MainComponent exact path="/mentorDetails" component={MentorDetails} />
                                <Route exact path="/CollegeDetails/" component={CollegeDetails} />
                                <Route exact path="/CollegeDetails/:id" component={CollegeDetails} />
                                <MainComponent exact path="/notifications" component={Notifications} />
                                <MainComponent exact path="/faq" component={Faq} />
                                <MainComponent exact path="/SearchUniversity" component={SearchUniversity} />
                                <MainComponent exact path="/SearchScholarship" component={SearchScholarship} />
                                <MainComponent exact path="/SearchSop" component={SearchSop} />
                                <MainComponent exact path="/SearchProgram" component={SearchProgram} />
                                <MainComponent exact path="/ComingSoon" component={ComingSoon} />
                                <MainComponent exact path="/InviteShare" component={InviteShare} />
                                <MainComponent exact path="/UniversityFavourites" component={AssociateUniversityFavourites} />
                                {/* <MainComponent exact path="/EventsList" component={EventsList} /> */}
                                <MainComponent exact path="/EventsDetails" component={EventsCardDetails} />
                                <MainComponent exact path="/ServicesLists" component={ServicesList} />
                                <MainComponent exact path="/ScholarshipDetails" component={ScholarshipDetails} />
                                <MainComponent exact path="/ProfileEvaluation" component={ProfileEvaluation} />
                                <MainComponent exact path="/PossibleOptions" component={PossibleOptions} />
                                <MainComponent exact path="/ServicesDetails" component={ServicesDetails} />
                                <MainComponent exact path="/UploadDocuments" component={UploadDocuments} />
                                <MainComponent exact path="/Profile" component={Profile} />
                                
                                <Route exact path="/University/:id/:name" component={Zolve} />
                                <MainComponent exact path="/LowTutionFee" component={LowTutionFee} />
                                <MainComponent exact path="/GREAccepted" component={GREAccepted} />
                                <MainComponent exact path="/status" component={Status} />
                                
                                <MainComponent exact path="/TransferUniversity" component={TransferUniversity} />
                                <MainComponent exact path="/CurrentUniversity" component={CurrentUniversity} />
                                <MainComponent exact path="/document-checklist" component={DocumentCheckList} />
                                <Route exact path="/apply-accomodation/:id" component={AddAccomodation} />
                                <MainComponent exact path="/add-event" component={AddEvent} />
                                {/* <MainComponent exact path="/add-education-loan" component={AddEducationForm} /> */}
                                <MainComponent exact path="/privacy-policy" component={Agreement} />
                                <MainComponent exact path="/related-Program" component={RelatedProgrames} />

                                <MainComponent exact path="/internshipDashboard" component={InternshipDashboard} />
                                <MainComponent exact path="/educationDashboard" component={EducationLoanDashBoard} />
                                <MainComponent exact path="/universityDashboard" component={UniversityDashBoard} />
                                <MainComponent exact path="/universityProspectives" component={UniversityProspectives} />
                                
                                <MainComponent exact path="/accommodationDashboard" component={AccommodationDashBoard} />
                                <MainComponent exact path="/internship" component={Intenship} />
                                <MainComponent exact path="/addInternshipDetails" component={AddInternshipDetails} />
                                <MainComponent exact path="/candidate" component={Candidate} />
                                <MainComponent exact path="/accommodationProperty" component={Property} />
                                <MainComponent exact path="/universityapplication" component={UniverSityApplication} />
                                <MainComponent exact path="/universityeventList" component={UniversityEventList} />
                                <MainComponent exact path="/addUniversityEvent" component={AddUniversityEvent} />
                            </div>
                        </Switch>
                        <BottomNav />
                    </HashRouter>
                </div>
            </ThemeProvider>
            </AppProvider>
            {/* </DataContext> */}
        </React.Fragment>
    );
}

export default App;