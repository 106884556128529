import React, { useState } from 'react';
import "./Form.css";

const Select = (props) => {
    
    const [inputData, setInputData] = useState({ });

    const onChangeHandler = (name, value) => {
        setInputData({
            ...inputData, [name]: value
        })
    }

    return (
        <select class="form-select inputField"
                aria-label="Default select example"
                name={props.name}
                id={props.id}
                value={props.value}
                onChange={(e) => onChangeHandler("gender", e.target.value)}
            >
            <option className="selectOpt" selected>{props.opt}</option>
            <option className="selectOpt" value="1">Male</option>
            <option className="selectOpt" value="2">Female</option>
            <option className="selectOpt" value="3">Other</option>
        </select>)
}

export default Select;