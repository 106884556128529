import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@mui/styles";
import { Autocomplete, Button, Grid, Input, TextField, Typography } from "@mui/material";
import { OPERATION, operationValue } from '../../Controller/Global';
import axios from 'axios';
import { useFormik } from 'formik';
import { getSessionId, getUserId, SESSION_ID, USER_ID } from '../../Controller/localStorageHandler';
import { fetchAddStudentAction, fetchComoonPickerListAction } from '../Action';
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from '@mui/material/CircularProgress';
import AsyncSelect from 'react-select/async';
import { createFilter } from 'react-select';
import PhoneInput from "react-phone-input-2";
import {  useParams } from 'react-router-dom';


const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

const MyFormControlLabel = (props) => {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}



const TransferUniversityForm = () => {
    const classes = useStyles();

    const { number } = useParams()

    const [programList, setProgramList] = React.useState('');
    const [universityList, setUniversityList] = React.useState('');
    const [currentUniversityValue, setcurrentUniversityValue] = React.useState([]);
    const [transferUniversityValue, settransferUniversityValue] = React.useState([]);
    const [loading, setLoading] = React.useState('');
    const [currentProgramValue, setcurrentProgramValue] = React.useState([])
    const [transferProgramValue, settransferProgramValue] = React.useState([])
    const [currentSemesterValue, setcurrentSemesterValue] = React.useState('')
    const [transferSemesterValue, settransferSemesterValue] = React.useState('')
    const [currentYearValue, setcurrentYearValue] = React.useState('')
    const [transferYearValue, settransferYearValue] = React.useState('')

    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const [degree, setDegree] = React.useState('Masters');

    const [credit, setCredit] = React.useState('none');
    const [GPA, setGPA] = React.useState('none');

    const [yearList, setYearList] = React.useState([]);
    const [semesterList, setSemesterList] = React.useState([]);

    var data = {
        "related_to": '',
        "desc": '',
    }
    const [inputData, setInputData] = React.useState(data);

    useEffect(() => {
        async function syncData() {
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'related_programs');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                })
            setProgramList(response.data.data);

            params.append('name', 'semester');
            const semesterRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'year');
            const yearRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            setSemesterList(semesterRes.data.data);
            setYearList(yearRes.data.data);

        }
        syncData();
    }, []);


    const promiseOptions = (inputValue) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (inputValue.length > 2)
                    setTimeout(async () => {
                        const university = new FormData()
                        university.append(OPERATION, operationValue.FetchUniversity)
                        university.append(USER_ID, getUserId())
                        university.append(SESSION_ID, getSessionId())
                        university.append('search', inputValue)
                        university.append('degreeType', '')
                        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
                            .then((response) => {
                                console.log(response)
                                let SelectData = []
                                if (Array.isArray(response?.data?.data)) {
                                    response?.data?.data?.map((data) => {

                                        let temp = {
                                            "label": `${data}`,
                                            "value": `${data}`
                                        }
                                        SelectData.push(temp)
                                    })
                                }
                                else {
                                    let temp = {
                                        "label": `${response?.data?.data}`,
                                        "value": `${response?.data?.data}`
                                    }
                                    SelectData.push(temp)
                                }

                                resolve(SelectData)
                            })
                    }, 3000);
            }
            catch (error) {
                reject(error)
            }
        })
    }

    const listInput = () => {
        let String = ''
        String = String + `%0D%0A First Name: ${firstname}`
        String = String + `%0D%0A Last Name: ${lastname}`
        String = String + `%0D%0A Email : ${email}`
        String = String + `%0D%0A Mobile No : ${phone}`

        String = String + `%0D%0A *Current University*`

        String = String + `%0D%0A Degree : ${degree}`
        String = String + `%0D%0A Semester : ${currentSemesterValue}`
        String = String + `%0D%0A Year : ${currentYearValue}`
        String = String + `%0D%0A program : ${currentProgramValue}`

        String = String + `%0D%0A%0D%0A Credit : ${credit}`
        String = String + `%0D%0A GPA : ${GPA}`

        String = String + `%0D%0A%0D%0A *Transfer University*`

        String = String + `%0D%0A Degree : ${degree}`
        String = String + `%0D%0A Semester : ${transferSemesterValue}`
        String = String + `%0D%0A Year : ${transferYearValue}`
        String = String + `%0D%0A program : ${transferProgramValue}`


        return String
    }

    const submitForm = () => {
        // let inputData = []
        var formData = new FormData();
        formData.append('degree_looking_for', degree);
        formData.append('operation', 'Add_student');
        formData.append('email_address', email);
        formData.append('phone_mobile', phone);
        formData.append('year_looking_for', currentYearValue);
        formData.append('semester_looking_for', currentSemesterValue);
        formData.append('username', `${firstname} ${lastname}`);
        formData.append('related_programs', currentProgramValue);


        fetchAddStudentAction(formData).then((res) => {
            console.log(res) 
            if (res?.data !== "") {
                inputData['related_to'] = res?.data
                inputData['desc'] = `First Name: ${firstname} \n
                                     Last Name: ${lastname} \n
                                     Email : ${email} \n
                                     Mobile No : ${phone} \n
                                     Current University \n
                                     Degree : ${degree} \n
                                     Semester : ${currentSemesterValue} \n
                                     Year : ${currentYearValue} \n
                                     program : ${currentProgramValue} \n\n
                                     Credit : ${credit} \n
                                     GPA : ${GPA} \n\n
                                     Transfer University \n
                                     Degree : ${degree} \n
                                     Semester : ${transferSemesterValue} \n
                                     Year : ${transferYearValue} \n
                                     program : ${transferProgramValue}
                                     `
                let config = {
                    url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
                    data: JSON.stringify(inputData),
                    method: 'post'
                }
                axios(config).then((response) => {
                    console.log(response)
                    let TempData = `${listInput()}
                        %0D%0A%0D%0A*BRAINSTORM CONSULTING*
                        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
                        %0D%0A iOS: https://apple.co/33ujR4Q`
                    // console.log(TempData)
                    if(number){
                    window.location.href = `https://api.whatsapp.com/send?phone=${number}&text=%0D%0A ${TempData.replaceAll("&", "and")} `
                    }
                    else{
                    window.location.href = `https://api.whatsapp.com/send?phone=918861216666&text=%0D%0A ${TempData.replaceAll("&", "and")} `
                    }

                })

            } else {
                console.log('something went wrong')
            }
        }).catch((error) => {

        })



        // return `https://api.whatsapp.com/send?phone=918861216666&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")} `
    }

    if (loading) {
        return (<div style={{ textAlign: 'center', marginTop: '55%' }}>
            <CircularProgress />
        </div>)
    } else {
        return (
            <>
                <p style={{ fontWeight: "bold", fontSize: '20px', textAlign: 'center', fontWeight: 'bold', margin: '11px 0 -13px 0' }}>Explore Uni. Transfer Options</p>
                <hr />
                <form>
                    <div className={classes.cardBox}>
                        <p style={{ fontWeight: "bold", fontSize: '18px', fontWeight: 'bold', margin: '11px 0 -13px 0', paddingBottom: 10 }}>Current  University</p>
                        <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                            <RadioGroup
                                className={classes.radioGroup}
                                row name="degreeType"
                                value={degree}
                                onChange={(e) => setDegree(e.target.value)}
                            >
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Diploma"
                                    label="Diploma"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Bachelors"
                                    label="Bachelors"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Masters"
                                    label="Masters"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                                <MyFormControlLabel
                                    className={"radioLabel"}
                                    value="Phd"
                                    label="Phd"
                                    control={<Radio className={"radioRadioMain"} />}
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: "100%" }}>

                            <AsyncSelect
                                filterOption={createFilter({ ignoreAccents: false })}
                                onChange={({ value }) => setcurrentUniversityValue(value)}
                                cacheOptions
                                defaultOptions
                                placeholder="University"
                                loadOptions={promiseOptions}
                                className="DropDown"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, width: "100%" }}>

                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                name="program"
                                value={currentProgramValue}
                                onChange={(e, val) => setcurrentProgramValue(val)}
                                options={programList && programList.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select Program" />}
                            />
                        </FormControl>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, width: "100%" }}>
                                    <Autocomplete
                                        name='semester_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        fullWidth
                                        value={currentSemesterValue}
                                        onChange={(e, val) => setcurrentSemesterValue(val)}
                                        options={semesterList && semesterList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Semester" />}
                                    />
                                </FormControl>

                            </Grid>


                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='year_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={currentYearValue}
                                        onChange={(e, val) => setcurrentYearValue(val)}
                                        options={yearList && yearList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Year" />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, width: "100%" }}>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={credit}
                                        label="Credit"
                                        onChange={(e) => setCredit(e.target.value)}
                                    >
                                        <MenuItem value={'none'}>Select Credit</MenuItem>
                                        {[...new Array(101)].map((each, index) => <MenuItem value={index}>{index}</MenuItem>)
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, width: "100%" }}>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={GPA}
                                        label="Current GPA"
                                    onChange={(e)=>setGPA(e.target.value)}
                                    >
                                        <MenuItem value={'none'}>Select GPA</MenuItem>
                                        <MenuItem value={0.0}>0.0</MenuItem>
                                        <MenuItem value={1.3}>1.3</MenuItem>
                                        <MenuItem value={1.7}>1.7</MenuItem>
                                        <MenuItem value={2.0}>2.0</MenuItem>
                                        <MenuItem value={2.3}>2.3</MenuItem>
                                        <MenuItem value={2.7}>2.7</MenuItem>
                                        <MenuItem value={3.0}>3.0</MenuItem>
                                        <MenuItem value={3.3}>3.3</MenuItem>
                                        <MenuItem value={3.7}>3.7</MenuItem>
                                        <MenuItem value={4.0}>4.0</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <p style={{ fontWeight: "bold", fontSize: '18px', fontWeight: 'bold', margin: '11px 0 -13px 0', paddingBottom: 20, paddingTop: 15 }}>Transfer  University</p>

                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                            <AsyncSelect
                                filterOption={createFilter({ ignoreAccents: false })}
                                onChange={({ value }) => settransferUniversityValue(value)}
                                cacheOptions
                                defaultOptions
                                placeholder="University"
                                loadOptions={promiseOptions}
                                className="DropDown"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: "100%" }}>

                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                name="program"
                                value={transferProgramValue}
                                onChange={(e, val) => settransferProgramValue(val)}
                                options={programList && programList.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select Program" />}
                            />
                        </FormControl>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='semester_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={transferSemesterValue}
                                        onChange={(e, val) => settransferSemesterValue(val)}
                                        options={semesterList && semesterList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Semester" />}
                                    />
                                </FormControl>

                            </Grid>


                            <Grid xs={5.8}>
                                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                    <Autocomplete
                                        name='year_looking for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiple
                                        value={transferYearValue}
                                        onChange={(e, val) => settransferYearValue(val)}
                                        options={yearList && yearList.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Year" />}
                                    />
                                </FormControl>

                            </Grid>


                        </Grid>

                        <FormControl fullWidth sx={{ mt: 1.5 }}>
                            <div style={{ width: "100%", display: "flex" }}>

                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setFirstname(e.target.value)}
                                    value={firstname}
                                />

                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setLastname(e.target.value)}
                                    value={lastname}
                                />



                            </div>
                            <div style={{ width: "100%", display: "flex", marginTop: '15px', justifyContent: "space-between" }}>

                                <TextField
                                    label="Email Id"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <div style={{ width: "100%", marginTop: '10px' }}>

                                <PhoneInput
                                    country={'in'}
                                    name='phone_mobile'
                                    placeholder="Mobile"

                                    inputProps={{

                                        required: true,
                                        autoFocus: true,
                                    }}
                                    searchPlaceholder={'Mobile'}

                                    onChange={(phone) => {
                                        setPhone(phone)
                                    }}
                                />


                            </div>
                        </FormControl>

                        <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '5px' }}>

                            <Button
                                className={classes.submitBtn}
                                onClick={()=>submitForm()}
                            >Submit</Button>
                        </Grid>
                    </div>


                </form>
            </>
        )
    }
};

export default TransferUniversityForm;

const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '100%',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',
        marginTop: '10px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
}));

