import React, { useEffect, useState } from 'react'
import Header from './Header'
import Card from './Card'
import { getSessionId, getUserId } from '../../Controller/localStorageHandler'
import { fetchApplyIntenshipListAction } from '../Action'
import { makeStyles } from '@mui/styles'
import PageTitle from '../PageTitle'
const ApplyIntenship = () => {
    const classes = useStyles();

    const [ApplyIntenshipData, setApplyIntenshipData] = useState([])

    useEffect(() => {
        var data = new FormData();
        data.append('userid', getUserId());
        data.append('sessionid', getSessionId());
        data.append('operation', 'get_internship_details');

        fetchApplyIntenshipListAction(data).then((response) => {
            setApplyIntenshipData(response?.data || [])
        }).catch((error) => {
            setApplyIntenshipData([])
        })
    }, [])

    return (
        <div>
            <div className='global-container' style={{ padding: 0 }}>
                <Header />
                <div>
                    <div className={classes.container}>
                        <PageTitle pageTitle={`Intenships`} bold />
                        <img style={{ marginTop: '10px' }} src={`${process.env.PUBLIC_URL}/img/sort.png`} alt="" />
                    </div>
                </div>
                {
                    ApplyIntenshipData.length > 0 && ApplyIntenshipData.map((d) => {
                        return <Card data={d} />
                    })
                }
            </div>
        </div>
    )
}

export default ApplyIntenship


const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',

    },

    img: {
        display: 'flex',
        justifyContent: 'center'
    },

}));
