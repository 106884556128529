import React from 'react';
// import BottomNav from '../../Common/BottomNav';
import { styled } from "@mui/material/styles";
import { Box } from '@mui/system';
import { Chip, Grid, IconButton, LinearProgress, Typography, Button, Dialog, DialogTitle } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams } from 'react-router-dom';
import { barColor, imageExists, getCurrencySymbol } from '../../../Controller/Global';
import PopUpModal from '../../Result/ApplyModal';
// import { useParams } from 'react-router-dom';
import { MdOutlineGrade, MdOutlineDateRange, MdDateRange, MdDriveFileMoveOutline } from "react-icons/md";
import { FaUniversity, FaHackerrank, FaExternalLinkAlt, FaPercent, FaBox } from "react-icons/fa";
import { AiOutlineFileAdd, AiOutlineFileDone, AiOutlinePoweroff } from "react-icons/ai";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CheckIcon from '@mui/icons-material/Check';
import FlightIcon from '@mui/icons-material/Flight';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import moment from "moment";
import _ from 'lodash'
import axios from 'axios';
import University from "../../Dashboards/University";
import { getUser,getUserType, getSessionId, getUserId, setCurrentPageVal, getCurrentPageVal } from '../../../Controller/localStorageHandler';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import './details.css'
import { FaWhatsappSquare } from "react-icons/fa";
import { FiMail } from "react-icons/fi"

import parse from 'html-react-parser';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';



const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));

const CollegeDetails = (props) => {

    // console.log("props", props)
    const history = useHistory()
    const classes = useStyles();
    let { id } = useParams();
    // console.log(getUser())

    // console.log(props?.location?.state?.data);
    const data = props?.location?.state?.data ? props?.location?.state?.data : getCurrentPageVal()
    // console.log('data',data)
    React.useEffect(() => {
        if (props?.location?.state?.data) {
            setCurrentPageVal(props?.location?.state?.data)
            viewIncrement(props?.location?.state?.data?.universitiesid)
        }
        else {

        }
        if (id) {
            get_university_by_id()
            viewIncrement(id)
        }
    }, [])

    const [openApply, setOpenApply] = React.useState(false);
    const [applyData, setApplyData] = React.useState('')
    const [documentsRequired, setDocumentsRequired] = React.useState([]);
    const [RequiredLength, setRequiredLength] = React.useState(3)
    const [scholarShipList, setScholarShipList] = React.useState([]);

    // const [enrolled, setEnrolled] = React.useState('');
    // const [admitted, setAdmitted] = React.useState('');
    // const [applied, setApplied] = React.useState('');

    const [backImage, setBackImg] = React.useState('');

    const [dataWithId, setDataWithId] = React.useState([]);

    const [dialogOpen,setDialogOpen] = React.useState(false)

    const handleClose = ()=>{
        setDialogOpen(false)
    }

    const get_university_by_id = async () => {
        const params = new URLSearchParams();
        params.append('operation', 'get_university_by_id');
        params.append('userid', getUserId());
        params.append('sessionid', getSessionId());
        params.append('uni_id', id);
        const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params, {
            'Access-Control-Allow-Origin': '*',
        });

        // console.log(response)
        // data = response?.data?.data
        setDataWithId(response?.data?.data)

    }
    
    const viewIncrement = async (uni_id) => {
        const params = new URLSearchParams();
        params.append('operation', 'total_view');
        params.append('uni_id', uni_id);
        const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params, {
            'Access-Control-Allow-Origin': '*',
        });
          
    }

    const momentDate = () => {
        let eventDate = moment(dataWithId?.inter_adm_deadline || data?.inter_adm_deadline)
        let todayDate = moment()

        // return new moment().to(moment(data?.inter_adm_deadline))
        return eventDate.diff(todayDate, 'days')
    }

    // console.log("data?.university", data)



    const GetReuiredDocumentFromUniversity = () => {
        let Data = dataWithId?.doc_required ? dataWithId?.doc_required.split(" |##| ") : data?.doc_required ? data?.doc_required.split(" |##| ") : []
        setDocumentsRequired(Data)
        // console.log(Data)
    }

    // const getStatusCount = async () => {
    //     const params = new URLSearchParams();
    //     params.append('operation', 'get_status_count');
    //     params.append('userid', getUserId());
    //     params.append('sessionid', getSessionId());
    //     params.append('uni_id', dataWithId?.universitiesid || data?.universitiesid);
    //     params.append('validation', 'bypass');
    //     params.append('university', dataWithId?.university || data?.university);
    //     params.append('program', dataWithId?.program || data?.program);
    //     params.append('degree_type', dataWithId?.degree_type || data?.degree_type);

    //     const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params, {
    //         'Access-Control-Allow-Origin': '*',
    //     });

    //     setEnrolled(_.sum(response?.data?.enrolled))
    //     setAdmitted(_.sum(response?.data?.admitted))
    //     setApplied(_.sum(response?.data?.applied))
    //     // console.log(response)
    // }
    const imageUrl = (university) => {
        // setBackImg('./img/University_of_Huston_414x232.jpeg')
        // return
        var image_png = `https://app.bscglobaledu.com/uniImage/university/USA/${university}.png`
        var image_jpg = `https://app.bscglobaledu.com/uniImage/university/USA/${university}.jpg`
        var default_img = 'https://app.bscglobaledu.com/uniImage/university/default.jpg'

        fetch(image_png, { method: 'HEAD' }).then(res => {
            // console.log('Res', res);
            if (res.status === 200) {
                setBackImg(image_png)
            } else {
                fetch(image_jpg, { method: 'HEAD' }).then(res => {
                    // console.log('Res', res);
                    if (res.status === 200) {
                        setBackImg(image_jpg)
                    } else {
                        setBackImg(default_img)
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        async function syncData() {

            const params = new URLSearchParams();
            params.append('operation', 'get_university_scholarships');
            params.append('userid', getUserId());
            params.append('sessionid', getSessionId());
            params.append('uni_id', dataWithId?.universitiesid || data?.universitiesid);
            params.append('validation', 'bypass');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                });
            setScholarShipList(response?.data?.data)
            // console.log(response)
        }
        GetReuiredDocumentFromUniversity()
        syncData()
        // getStatusCount()
        imageUrl(dataWithId?.university || data?.university)
        window.scrollTo(0, 0)

    }, [dataWithId])



    return (
        <Box className={`${classes.root}`} sx={{ bgcolor: '#fafafa', }}>
            <Box sx={{
                // marginLeft: 2,
                // marginRight: 2,
                borderRadius: 2,
                position: 'relative',
            }}>
                <Chip onClick={() => history.goBack()} size="small" sx={{ marginBottom: '10px' }} icon={<ArrowBackIcon />} label="Back" color="primary" variant="outlined" />


                <Box sx={{ height: '230px', backgroundImage: `url('${backImage}')`, backgroundSize: 'contain', position: 'relative' }}>
                    {/* <Box sx={{ height: '230px', backgroundImage: `url(https://erp.bscglobaledu.com/mapp/ishvi/website/back_up/img/university/USA/${data?.university}.png)` }}> */}
                    {/* src={imageExists(`https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${data?.university}.png`)} */}
                    <br />
                    <Box
                     sx={{border:'1px solid transparent',backgroundColor:'#ef5350',color:'#fff',width:'130px',borderTopRightRadius:'50px', borderBottomRightRadius:'50px', fontSize:'13px'}}
                     onClick={()=>setDialogOpen(true)}>
                    Application Fee Wavier Code
                    </Box>
                    <Box sx={{width:"60px",height:"40px",backgroundColor:"#ef5350",color:'#fff',position:"absolute",top:"25px",right:"0px",paddingLeft:"5px",paddingTop:"10px",borderBottomLeftRadius:"20px",borderTopLeftRadius:"20px"}}>
                         <Typography sx={{textAlign:"center",fontSize:'13px'}}><RemoveRedEyeOutlinedIcon sx={{fontSize:'13px'}}/> {dataWithId?.total_view || data?.total_view}</Typography> 
                    </Box>
                    {/* <Button variant="contained" size="small" color="error">Contained</Button> */}
                </Box>
                <Box sx={{ borderRadius: '10px', display: 'inline', width: '100%', margin: 'auto', textAlign: 'center' }}>

                    <Typography sx={{ backgroundColor: '#e0e0e0', fontSize: '14px', display: 'inline-block', width: '25%', borderRight: '1px solid #fff' }}
                    // onClick={() => {
                    //     history.push({
                    //         pathname: '/students',
                    //         state: {
                    //             'status': `${data?.university} Bookmarked`,
                    //             type: "bookmark",
                    //             val:data,
                    //         }
                    //     })
                    // }}
                    >
                        {/* <AiOutlineFileAdd style={{fontSize:'15px',marginRight: 10, color:'#ff9800'}} /> */}
                        <FavoriteBorderIcon style={{ fontSize: '15px', color: 'red', marginRight: 10 }} /> <span style={{ float: 'right', paddingRight: 20, color: 'red', fontWeight: 'bold' }}>{dataWithId?.total_shortlisted || data?.total_shortlisted}</span>
                        <br />
                        {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/enroll.png`} height="14px" /> */}
                        Shortlisted </Typography>

                    <Typography sx={{ backgroundColor: '#e0e0e0', fontSize: '14px', display: 'inline-block', width: '25%', borderRight: '1px solid #fff' }}
                        onClick={() => {
                            history.push({
                                pathname: '/students',
                                state: {
                                    'status': `${data?.university} Applied`,
                                    type: "applied",
                                    val: data,
                                }
                            })
                        }}
                    >
                        {/* <MdDriveFileMoveOutline style={{fontSize:'15px',marginRight: 10, color:'pink'}} /> */}
                        <LibraryBooksIcon style={{ fontSize: '15px', marginRight: 10, color: 'red' }} /><span style={{ float: 'right', paddingRight: 20, color: 'red', fontWeight: 'bold' }}>{dataWithId?.total_applied || data?.total_applied}</span>
                        <br />
                        {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/file.png`} height="14px" /> */}
                        Applied </Typography>


                    <Typography sx={{ backgroundColor: '#e0e0e0', fontSize: '14px', display: 'inline-block', width: '25%', borderRight: '1px solid #fff' }}
                        onClick={() => {
                            history.push({
                                pathname: '/students',
                                state: {
                                    'status': `${data?.university} Admitted`,
                                    type: "admitted",
                                    val: data,
                                }
                            })
                        }}
                    >
                        <CheckIcon style={{ fontSize: '15px', color: 'green', marginRight: 10, }} /> <span style={{ float: 'right', paddingRight: 20, color: 'green', fontWeight: 'bold' }}>{dataWithId?.total_admitted
 || data?.total_admitted
}</span>
                        <br />
                        {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/admit.png`} height="14px" /> */}
                        Admitted </Typography>

                    <Typography sx={{ backgroundColor: '#e0e0e0', fontSize: '14px', display: 'inline-block', width: '25%' }}
                        onClick={() => {
                            history.push({
                                pathname: '/students',
                                state: {
                                    'status': `${data?.university} Enrolled`,
                                    type: "enrolled",
                                    val: data,
                                }
                            })
                        }}
                    >
                        {/* <AiOutlineFileAdd style={{fontSize:'15px',marginRight: 10, color:'#ff9800'}} /> */}
                        <FlightIcon style={{ fontSize: '15px', color: 'blue', marginRight: 10 }} /><span style={{ float: 'right', paddingRight: 20, color: 'blue', fontWeight: 'bold' }}>{dataWithId?.total_enrolled
 || data?.total_enrolled
}</span>
                        <br />
                        {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/enroll.png`} height="14px" /> */}
                        Enrolled </Typography>


                </Box>
                <Box sx={{ float: 'right' }}>
                    <Chip
                        label="Apply"
                        sx={{ color: '#fff'}}
                        onClick={(e) => {
                            e.stopPropagation();
                            setApplyData(dataWithId || data)
                            setOpenApply(true)
                        }}
                        className={`ripple`}
                    />
                    {/* {
                        getUserId() ? 
                        <Chip
                        label="Apply"
                        sx={{ bgcolor: '#1B6CFC', color: '#fff', }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setApplyData(dataWithId || data)
                            setOpenApply(true)
                        }}
                    />
                    :
                    <Chip
                        label="Apply"
                        sx={{ bgcolor: '#1B6CFC', color: '#fff', }}
                        onClick={(e) => {
                            e.stopPropagation();
                            history.push('/login')
                        }}
                    />
                    } */}

                </Box>
                <Box sx={{ backgroundColor: '#fff', borderRadius: '10px', width: '100%' }}>
                    <Grid container>
                        <Grid item xs={2}><img alt='' style={{ width: '60px' }} src={imageExists(`https://erp.bscglobaledu.com/mapp/ishvi/img/UNIVERSITYimagess/${data?.university}.png`)} /></Grid>
                        <Grid item xs={10} sx={{ pl: 1, position: 'relative' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '15px', mt: 1 }}>{dataWithId?.university || data?.university || data?.scholarship_name || ''} <FaExternalLinkAlt style={{ color: '#ef5350', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.uni_website || data?.uni_website} /></Typography>
                            <Typography sx={{ fontSize: '13px' }}>{dataWithId?.degree_type || data?.degree_type} {'  '} {dataWithId?.program || data?.program} <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.depar_address_link || data?.depar_address_link} /> </Typography>
                            <span style={{ fontSize: '12px' }}><Chip label={dataWithId?.semester || data?.semester} size="small" color="success" /> {' '} {moment(dataWithId?.inter_adm_deadline).format("ll")}   {dataWithId?.inter_adm_deadline || data?.inter_adm_deadline ? `⏰  ${momentDate()}  Days` : ''}</span>
                            <br />
                            {/* <hr style={{ maxWidth: '70%', marginRight: '30%', color: '#fff' }} /> */}

                            {/* <Chip label={`$ ${Math.round(data?.international_tuition_fee_yr)} / Yr`} size="small" color="success" sx={{ float: 'right', marginRight: '10px', fontWeight: 'bold', fontSize: '16px' }} /> */}

                            {/* <img alt='' src={`${process.env.PUBLIC_URL}/img/percenatge.png`} style={{ marginTop: '15px' }} /> */}

                            <br />




                        </Grid>
                    </Grid>
                    
                  
                    {
                        getUserType() === 'Associate Admin' 
                        ? 
                        <div style={{textAlign:'center'}}>
                        <Chip size="small" color="success" label={`Commission Amount : $ ${(parseInt(dataWithId?.commission_amount || data?.commission_amount) * parseInt(getUser()?.commission))/100} `} /> 
                        </div>
                        :
                        ''
                    }
                    
                           
                    <Box sx={{ width: '100%', marginTop: 1 }}>
                        <Grid container sx={{
                            paddingLeft: 3,
                            paddingRight: 3,
                            marginTop: 1,

                        }} spacing={1}>
                            <Grid item xs={6}>
                                <Chip size="small" color="warning" label={`Application Fee : ${getCurrencySymbol(dataWithId?.country || data?.country || 'USA')}${parseInt(dataWithId?.international_app_fee || data?.international_app_fee)} ${dataWithId?.app_fee_waiver || data?.app_fee_waiver === '1' ? 'Waiver' : ''}`} />
                            </Grid>
                            <Grid item xs={6}>

                                <Chip size="small" color="warning" label={`Tution Fee : ${getCurrencySymbol(dataWithId?.country || data?.country || 'USA')}${parseInt(dataWithId?.international_tuition_fee_yr || data?.international_tuition_fee_yr)}`} />

                            </Grid>
                            <Grid item xs={6}>
                                <Chip size="small" color="info" label={`Application Fee : ${getCurrencySymbol(dataWithId?.country || data?.country || 'USA')}${parseInt(dataWithId?.international_app_fee || data?.international_app_fee)} ${dataWithId?.app_fee_waiver || data?.app_fee_waiver === '1' ? 'Waiver' : ''}`} />
                            </Grid>
                            <Grid item xs={6}>
                                <Chip size="small" color="info" label={`Turnaround Time : ${dataWithId?.turn_around_time || data?.turn_around_time} `} />
                            </Grid>

                        </Grid>


                    </Box>
                    <Grid container sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginTop: 1,

                    }} spacing={1}>
                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '14px', }}>
                                <MdOutlineGrade style={{ fontSize: '15px', marginRight: 10, color: 'red' }} />
                                {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/gpa.png`} height="14px" /> */}
                                GPA % : <span style={{ float: 'right', paddingRight: 10, color: 'red' }}>{dataWithId?.academic_requirements_gpa || data?.academic_requirements_gpa}</span></Typography>
                        </Grid>
                        <Grid item xs={6}>

                            <Typography sx={{ fontSize: '14px', }}>
                                <FaUniversity style={{ fontSize: '15px', marginRight: 10, color: 'blue' }} />
                                Rank  : <span style={{ float: 'right', paddingRight: 10, color: 'blue' }}>{dataWithId?.uni_rank || data?.uni_rank}</span></Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '14px', }}>
                                <MdOutlineGrade style={{ fontSize: '15px', marginRight: 10, color: 'red' }} />
                                {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/gpa.png`} height="14px" /> */}
                                BackLogs : <span style={{ float: 'right', paddingRight: 10, color: 'red' }}>{dataWithId?.num_backlogs || data?.num_backlogs}</span></Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '14px', }}>
                                <FaHackerrank style={{ fontSize: '15px', marginRight: 10, color: 'skyblue' }} />
                                {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/qs.png`} height="14px" /> */}
                                QS World Rank : <span style={{ float: 'right', paddingRight: 10, color: 'skyblue' }}>{dataWithId?.qs_world_univ_rankings || data?.qs_world_univ_rankings}</span></Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '14px', }}>
                                <FaPercent style={{ fontSize: '15px', marginRight: 10, color: 'green' }} />
                                {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/qs.png`} height="14px" /> */}
                                Acceptance : <span style={{ float: 'right', paddingRight: 10, color: 'green' }}>{parseInt(dataWithId?.acceptance_rate || data?.acceptance_rate)} {`%`}</span></Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '14px', }}>
                                <FaUniversity style={{ fontSize: '15px', marginRight: 10, color: 'violet' }} />
                                {/* <img alt='' style={{ marginRight: 10 }} src={`${process.env.PUBLIC_URL}/img/product_detail/university.png`} height="14px" /> */}
                                US News Rank : <span style={{ float: 'right', paddingRight: 10, color: 'violet' }}>{dataWithId?.us_news_rankings || data?.us_news_rankings}</span></Typography>
                        </Grid>


                    </Grid>
                    <hr style={{ marginTop: '15px', marginRight: '5%', marginLeft: '5%', color: '#fff' }} />

                    <Box>
                        <Grid container sx={{
                            paddingLeft: 3,
                            paddingRight: 3,
                            marginTop: 2,
                            fontSize: '14px',
                        }} spacing={2}>
                            <Grid item >ILTES : <span style={{ color: '#1B6CFC', fontWeight: 'bold' }}>{dataWithId?.ielts || data?.ielts}</span></Grid>
                            <Grid item >PTE : <span style={{ color: '#1B6CFC', fontWeight: 'bold' }}>{dataWithId?.pte || data?.pte}</span></Grid>
                            <Grid item >TOEFL : <span style={{ color: '#1B6CFC', fontWeight: 'bold' }}>{dataWithId?.toefl || data?.toefl}</span></Grid>
                            {/* <Grid item >WES : <span style={{ color: '#1B6CFC', fontWeight: 'bold' }}>{data?.wes_requirement}</span></Grid> */}
                            <Grid item >Duolingo : <span style={{ color: '#1B6CFC', fontWeight: 'bold' }}>{dataWithId?.duolingo || data?.duolingo}</span></Grid>
                            {dataWithId?.gre_total !== "Not Applicable" || data?.gre_total !== "Not Applicable" ? <Grid item >GRE :  <span style={{ color: '#FC5602', }}>{dataWithId?.gre_total || data?.gre_total}</span></Grid> : ''}
                            {dataWithId?.gmat_total !== "Not Applicable" || data?.gmat_total !== "Not Applicable" ? <Grid item >GMAT : <span style={{ color: '#FC5602', }}>{dataWithId?.gmat_total || data?.gmat_total}</span></Grid> : ''}
                        </Grid>
                    </Box>
                    <hr style={{ marginTop: '15px', marginRight: '5%', marginLeft: '5%', color: '#fff' }} />
                    <Box sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginTop: '20px',
                    }}>
                        <Typography sx={{ color: '#1A1A1A', fontSize: '16px', fontWeight: 'bold' }}>
                            All Details
                            <span style={{ float: 'right', fontWeight: 'normal' }}>
                                See all
                            </span>
                        </Typography>

                        {/* University   */}
                        {
                            dataWithId?.about !== null || data?.about !== null
                                ?
                                <>
                                    <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                                        <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>About University</Typography>
                                    </Box>
                                    <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                                        <Typography sx={{ paddingY: '5px' }}>{dataWithId?.about || data?.about ? parse(dataWithId?.about || data?.about) : ''}</Typography>
                                    </Box>
                                </>
                                :
                                ''
                        }

                        {/* Why This University   */}
                        {
                            dataWithId?.why_this_university !== null || data?.why_this_university !== null
                                ?
                                <>
                                    <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                                        <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Why This University</Typography>
                                    </Box>
                                    <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                                        <Typography sx={{ paddingY: '5px' }}>{dataWithId?.why_this_university || data?.why_this_university ? parse(dataWithId?.why_this_university || data?.why_this_university) : ''}</Typography>
                                    </Box>
                                </>
                                :
                                ''
                        }

                        {/* How to apply   */}

                        {
                            dataWithId?.how_to_apply !== null || data?.how_to_apply !== null
                                ?
                                <>
                                    <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                                        <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>How To Apply</Typography>
                                    </Box>
                                    <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                                        <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>{dataWithId?.how_to_apply || data?.how_to_apply ? parse(dataWithId?.how_to_apply || data?.how_to_apply) : ''}</Typography>
                                        {/* <div style={{textAlign:'center'}}><Button variant="contained" color="success" onClick={()=>window.open(data?.how_to_apply)}>Visit here</Button></div> */}
                                    </Box>
                                </>
                                :
                                ''
                        }


                        <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Program Details</Typography>
                        </Box>

                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Program Requirements: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.program_requirements || data?.program_requirements ? parse(dataWithId?.program_requirements || data?.program_requirements) : ''}</span></Typography>
                        </Box>


                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Program Comments: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.program_comments || data?.program_comments ? parse(dataWithId?.program_comments || data?.program_comments) : ''}</span></Typography>
                        </Box>



                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Program Remarks: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.program_remarks || data?.program_remarks ? parse(dataWithId?.program_remarks || data?.program_remarks) : ''}</span></Typography>
                        </Box>


                        {/* financial detaisl */}
                        <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Financial Details</Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>International Application Fee <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.international_app_fee_link || data?.international_app_fee_link} />: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.international_app_fee || data?.international_app_fee}</span></Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Domestic Application Fee: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.domestic_app_fee || data?.domestic_app_fee}</span></Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>International Tuition Fee/Yr <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.tuition_fee_link || data?.tuition_fee_link} />: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.international_tuition_fee_yr || data?.international_tuition_fee_yr}</span></Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>International Tuition Fee/Cr Hr <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.tuition_fee_link || data?.tuition_fee_link} />: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.inter_tuition_fee_cr_hr || data?.inter_tuition_fee_cr_hr}</span></Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Estimated Living Cost <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.estimated_living_cost_link || data?.estimated_living_cost_link} />: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.estimated_living_cost || data?.estimated_living_cost}</span></Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Department App Fee: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.domestic_app_fee || data?.domestic_app_fee}</span></Typography>
                        </Box>

                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Application Fee Wavier Code: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.app_fee_wavier_code || data?.app_fee_wavier_code ? parse(dataWithId?.app_fee_wavier_code || data?.app_fee_wavier_code) : ''}</span></Typography>
                        </Box>

                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Domestic Tuition Fee/Yr <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.tuition_fee_link || data?.tuition_fee_link} />: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>{dataWithId?.domestic_tuition_fee_yr || data?.domestic_tuition_fee_yr}</span></Typography>
                        </Box>


                        <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Important Dates</Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton selected>
                                        {/* <ListItemText primary="Trash" /> */}
                                        International Admission Deadline : {dataWithId?.inter_adm_deadline || data?.inter_adm_deadline ? moment(dataWithId?.inter_adm_deadline || data?.inter_adm_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '} <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.application_deadline_link || data?.application_deadline_link} />
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton selected>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Documents Deadline : {dataWithId?.documents_deadline || data?.documents_deadline ? moment(dataWithId?.documents_deadline || data?.documents_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '} <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.doc_deadline_link || data?.doc_deadline_link} />
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        International Department Deadline : {dataWithId?.inter_depart_deadline || data?.inter_depart_deadline ? moment(dataWithId?.inter_depart_deadline || data?.inter_depart_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '} <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.depart_deadline_link || data?.depart_deadline_link} />
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton selected>
                                        {/* <ListItemText primary="Trash" /> */}
                                        International Financial Deadline : {dataWithId?.inter_fin_deadline || data?.inter_fin_deadline ? moment(dataWithId?.inter_fin_deadline || data?.inter_fin_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '} <FaExternalLinkAlt style={{ color: '#42a5f5', marginLeft: '10px', fontSize: '12px' }} onClick={() => window.location.href = dataWithId?.fin_scho_deadline_link || data?.fin_scho_deadline_link} />
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Portal Open Date : {dataWithId?.portal_open_date || data?.portal_open_date ? moment(dataWithId?.portal_open_date || data?.portal_open_date, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Deposit Deadline : {dataWithId?.uni_deposit_deadline || data?.uni_deposit_deadline ? moment(dataWithId?.uni_deposit_deadline || data?.uni_deposit_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Enrollment Deadline : {dataWithId?.uni_enroll_deadline || data?.uni_enroll_deadline ? moment(dataWithId?.uni_enroll_deadline || data?.uni_enroll_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        CAS Deadline : {dataWithId?.uni_cas_deadline || data?.uni_cas_deadline ? moment(dataWithId?.uni_cas_deadline || data?.uni_cas_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Department Deadline : {dataWithId?.inter_depart_deadline || data?.inter_depart_deadline ? moment(dataWithId?.inter_depart_deadline || data?.inter_depart_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Domestic Admission Deadline : {dataWithId?.domestic_admission_deadline || data?.domestic_admission_deadline ? moment(dataWithId?.domestic_admission_deadline || data?.domestic_admission_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Domestic Department Deadline : {dataWithId?.domestic_depart_deadline || data?.domestic_depart_deadline ? moment(dataWithId?.domestic_depart_deadline || data?.domestic_depart_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {/* <ListItemText primary="Trash" /> */}
                                        Domestic Financial Deadline : {dataWithId?.domestic_financial_deadline || data?.domestic_financial_deadline ? moment(dataWithId?.domestic_financial_deadline || data?.domestic_financial_deadline, 'DD-MM-YYYY').format("MMMM Do YYYY") : ''} {' '}
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>

                        {/* Scholarships  */}
                        <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Scholarships</Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px' }}>
                            <div style={{ display: "flex", overflowX: "scroll", width: "100%", whiteSpace: "nowrap", paddingBottom: 15 }}>
                                {_.chunk(scholarShipList || [], 1).map((d, i) => {
                                    return <div key={i} >
                                        {
                                            d.map((val) => {
                                                return <University
                                                    type="scholarship"
                                                    university={val?.university}
                                                    universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                    funding_type={val?.funding_type || ''}
                                                    bookmark={val?.bookmark || ''}
                                                    schollrshipDates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                    val={val}
                                                    setBookmarkId={1}
                                                    setOpenFav={1}
                                                    setStatus={1}
                                                    setIsBookMark={1}
                                                />
                                            })
                                        }
                                    </div>
                                })
                                }
                            </div>

                        </Box>


                        {/* Documents Required  */}
                        <Box sx={{ marginTop: '20px', bgcolor: '#1B6CFC', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingX: '10px', paddingY: '5px' }}>
                            <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Documents Required</Typography>
                        </Box>
                        <Box sx={{ paddingX: '10px', paddingY: '5px', textAlign: 'center' }}>

                            <List>
                                {
                                    _.cloneDeep(documentsRequired)?.splice(0, RequiredLength).map((d, i) => {
                                        return <ListItem disablePadding>
                                            <ListItemButton selected={i % 2 == 0}>
                                                <div style={{ display: "flex" }}>
                                                    <main style={{
                                                        marginBottom: '0',
                                                        height: 20,
                                                        width: 20,
                                                        border: "1px solid transparent",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: "50px",
                                                        justifyContent: "center",
                                                        backgroundColor: "orange",
                                                        fontSize: '12px'
                                                    }}>
                                                        <p style={{ marginBottom: '0' }} >{i + 1} </p>
                                                    </main>
                                                    <span style={{ marginLeft: 10, fontSize: '14px' }}>{d}</span>
                                                </div>
                                            </ListItemButton>
                                        </ListItem>
                                    })
                                }
                            </List>
                            {documentsRequired?.length > 5 &&
                                <>
                                    {documentsRequired.length !== RequiredLength ? <div onClick={() => {
                                        setRequiredLength(documentsRequired.length)
                                    }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show More</div>
                                        : <div onClick={() => {
                                            setRequiredLength(3)
                                        }} style={{ textAlign: "end", paddingRight: 20, paddingTop: 10, color: "red" }}>Show Less</div>
                                    } </>
                            }
                            {/* <Typography sx={{ paddingY: '5px', fontWeight: 'bold' }}>Program: <span style={{ fontWeight: 'normal', fontSize: '15px' }}>Computer Information systems</span></Typography> */}
                        </Box>


                    </Box>


                    <br />
                    <br />

                </Box>
            </Box>

            <PopUpModal open={openApply} handleClose={() => setOpenApply(false)} data={applyData} />
            <Dialog onClose={handleClose} open={dialogOpen} >
                <Box sx={{padding:'20px',display:'flex'}}>
                <a href={`mailto:info@bscglobaledu.com?subject=I%20am%20looking%20for%20%20${dataWithId?.university || data?.university}%20%20Application%20Fee%20Waiver!&amp;body=%20I%20am%20looking%20for%20%20UNIVERSITY%20OF%20TEXAS,%20ARLINGTON%20%20%20Application%20Fee%20Waiver`} >
                <FiMail color="#c71610" style={{fontSize:'35px',margin:'5px'}} />
                </a>
                <a href={`https://wa.me/+918861216666?text=I+am+looking+for++${dataWithId?.university || data?.university}++Application+Fee+Waiver`}>
                <FaWhatsappSquare color="#075E54" style={{fontSize:'35px',margin:'5px'}} />
                </a>
                </Box>
                </Dialog>
                
        </Box>
    )
}

export default CollegeDetails;
