
import React, { useEffect } from "react";
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from "@mui/styles";
import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { OPERATION } from "../../../Controller/Global";
import axios from "axios";
import { useFormik } from 'formik';
import { withRouter, useHistory } from 'react-router-dom'
import { getUserId, getSessionId, SESSION_ID, USER_ID } from "../../../Controller/localStorageHandler";
import CircularProgress from '@mui/material/CircularProgress';
import ExtraField from "./ExtraField";
import MultipleExtraField from "./MultipleExtraField";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import GreCard from "../../Dashboards/Cards/Gre";
import Slider from "react-slick";
import Chip from '@mui/material/Chip';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const SearchUniversity = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [countryValue, setcountryValue] = React.useState([])
    const [programValue, setprogramValue] = React.useState([])
    const [countryList, setCountryList] = React.useState([]);
    const [programList, setProgramList] = React.useState([]);
    const [optionValue, setOptionValue] = React.useState('');
    const [interPercent, setinterPercent] = React.useState(0.00);
    const [backlogs, setbacklogs] = React.useState(10);
    const [TabSelected, setTabSelected] = React.useState('Master');
    const [bachelorPercent, setbachelorPercent] = React.useState(60);
    const { option, extraoption, move, heading } = props.location.state ? props.location.state : ''



    const formik = useFormik({
        initialValues: {
            country: [],
            program: '',
            degree_type: 'Masters',
        },
        onSubmit: async (values) => {
            values[OPERATION] = move ? "profileEvaluationDetails_new" : "profileEvaluationDetails"
            const formData = new FormData()
            for (let val in values) {
                if (val === 'country') {
                    countryValue.map(o => formData.append(`${val}[]`, o))
                    formData.append(USER_ID, getUserId())
                    formData.append(SESSION_ID, getSessionId())
                    formData.append('page', 1)

                } else if (val === 'program') {
                    programValue.map(o => formData.append(`${val}[]`, o))
                }
                else {
                    formData.append(val, values[val])
                }
            }

            formData.append('call_frm', move ? 'website' : 'mobile')
            option && formData.append(option, move)
            if (extraoption) {
                move === 'possible_options' ?
                    (getOptionKey.find(o => o.move === 'possible_options')).value.map(o => formData.append(Object.keys(o), Object.values(o)))
                    : formData.append((getOptionKey.find(o => o.move === move)).value, optionValue)
            }
            setLoading(true)
            for (var value of formData.values()) {
                console.log(value); 
             }
            await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
                .then(response => {
                    if (response.data.msg === 'successfully' || response.data.message === 'Successfully') {
                        // history.push('/UniversitySearchResult', response.data)

                        // if (!move) {
                        history.push({
                            pathname: '/UniversitySearchResult',
                            state: {
                                data: response.data, screen: move ? heading : 'profileEvaluationDetails',
                                page: JSON.stringify(Object.fromEntries(formData)),
                                programValue: programValue.join(','),
                                countryValue: countryValue.join(','),
                                tabName: TabSelected
                            }
                        })
                        // } else
                        //     history.push({
                        //         pathname: '/UniversitySearchResult',
                        //         state: {
                        //             data: response.data, screen: move ? heading : 'profileEvaluationDetails',
                        //             page: JSON.stringify(Object.fromEntries(formData))
                        //         }
                        //     })
                    } else {
                        alert(response.data.msg || response.data.message)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        },
    });

    useEffect(() => {
        async function syncData() {
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'related_programs');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                });

            params.append('name', 'country');
            const countryRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);
            setProgramList(response.data.data);
            setCountryList(countryRes.data.data);
        }
        syncData();
    }, []);
    var settings = {
        // dots: true,
        centerMode: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        rows: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
              rows: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              rows: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 1,
            }
          }
        ]
      };
    const getOptionKey = [
        {
            'move': 'low-tution',
            'value': 'lowrange'
        },
        {
            'move': 'duolingo',
            'value': 'duolingorange'
        },
        {
            'move': 'state',
            'value': 'state'
        },
        {
            'move': 'backlogs',
            'value': 'backlogs'
        },
        {
            'move': 'pte',
            'value': 'englishrange'
        },
        {
            'move': 'english',
            'value': 'englishrange'
        },
        {
            'move': 'Internships',
            'value': 'internships'
        },
        {
            'move': 'possible_options',
            'value': [{ 'uni_puc_inter_12': interPercent }, { 'num_backlogs': backlogs }, { 'academic_requirements_gpa': bachelorPercent }]
        },
        {
            'move': 'Internships',
            'value': 'internships'
        },
    ]
    console.log("move", move)
    if (loading) {
        return (<div className="gooey">
            <span className="dot"></span>
            <div className="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>)
    } else {
        return (
            <>
                <p style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold', margin: '11px 0 -13px 0', color: "#1B6CFC" }}><Chip label={move ? heading : 'University'} variant="filled" color="primary" /></p>
                <hr />
                <form onSubmit={formik.handleSubmit} className={`${classes.cardBox} select-label-rmv`}>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }} onChange={(e) => setTabSelected(e.target.value)}>
                        <RadioGroup
                            className={classes.radioGroup}
                            row name="degree_type"
                            defaultValue={formik.values.degree_type}
                            onChange={formik.handleChange}
                        >
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Diploma"
                                label="Diploma"
                                style={{ padding: "5px 17px", marginBottom: 5 }}
                                control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Bachelors"
                                label="Bachelors"
                                style={{ padding: "5px 17px", marginBottom: 5 }}
                                control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Masters"
                                label="Masters"
                                style={{ padding: "5px 17px", marginBottom: 5 }}
                                control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                            />
                            <MyFormControlLabel
                                className={"radioLabel"}
                                value="Phd"
                                label="Phd"
                                style={{ padding: "5px 17px", marginBottom: 5 }}
                                control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                            />
                        </RadioGroup>

                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            name="program"
                            className="radius-select"
                            value={programValue}
                            onChange={(e, val) => setprogramValue(val)}
                            options={programList && programList.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Select Program" />}
                        />
                        {/* <InputLabel 
                    size={'small'} 
                    id="demo-simple-select-helper-label"

                > 
                    Select Program Name
                </InputLabel> */}
                        {/* <Select size={'small'}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="program"
                        value={formik.values.program}
                        onChange={formik.handleChange}
                >   
                    {programList && programList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}

                </Select> */}

                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <Autocomplete
                            name='country'
                            freeSolo
                            multiple
                            value={countryValue}
                            className="radius-select"
                            onChange={(e, val) => setcountryValue(val)}
                            options={countryList && countryList.map((option) => option)}
                            renderInput={(params) => <TextField {...params} label="Select Country" />}
                        />
                        {/* <Select 
                    
                    name='country'
                    size={'small'} 
                    multiple
                    defaultValue={'select'}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                >
                    {countryList && countryList.map((val)=> <MenuItem value={val}>{val}</MenuItem>)}
                </Select> */}
                    </FormControl>


                    {extraoption && move !== 'possible_options' && move !== 'Profile' &&
                        <ExtraField optionValue={optionValue} setOptionValue={(o) => setOptionValue(o)} move={move} />}

                    {extraoption && (move === 'possible_options' || move === 'Profile') && <MultipleExtraField
                        interPercent={interPercent}
                        bachelorPercent={bachelorPercent}
                        backlogs={backlogs}
                        setinterPercent={(o) => setinterPercent(o)}
                        setbacklogs={(o) => setbacklogs(o)}
                        setbachelorPercent={(o) => setbachelorPercent(o)}
                    />
                    }
                    <Grid xs={12} className={classes.gridFlex} style={{ marginBottom: '10px', marginTop: '5px' }}>
                        <Button className={classes.resetBtn}>Reset</Button>
                        <Button
                            // onClick={()=>history.push('/SopSearchResult')}
                            className={classes.submitBtn}
                            type={'submit'}
                        >Search</Button>
                    </Grid>
                </form>
                {/* <Grid xs={12} className={classes.gridFlex} style={{marginBottom: '10px', marginTop: '10px'}}>
                <Button className={classes.resetBtn}>Reset</Button>
                <Button 
                    // onClick={() => history.push('/UniversitySearchResult')}
                    onClick={handleSubmit}
                    className={classes.submitBtn}
                        htmlType="button" type={'primary'}>Submit</Button>
            </Grid> */}
                <Slider className={'gre-slider first-slider'} style={{ margin: "15px 0", height: 130, }} {...settings}>
                    <GreCard count={120} slideImage={`url(${process.env.PUBLIC_URL}/img/DashBoard/university.jfif)`} heading={'GRE Waiver Universities'} text={'Universities for MS,MBA & PhD'} move={'grewaiver'} option={'filteroption'} />
                    <GreCard count={200} slideImage={`url(${process.env.PUBLIC_URL}/img/possible-options.jpeg)`} heading={'Possible Options Universities'} text={'Universities available nearest intake'} move={'possible_options'} option={'filteroption'} extraoption={true} url={'/PossibleOptions'} />
                    <GreCard count={250} slideImage={`url(${process.env.PUBLIC_URL}/img/Low-Tuition-Fee.jpeg)`} heading={'Low Tuition Fee Universities'} text={'Lowest Tuition Fee Universities'} move={'low-tution'} option={'filteroption'} extraoption={true} />
                    <GreCard count={270} slideImage={`url(${process.env.PUBLIC_URL}/img/plus-accpeted.jpg)`} heading={'10+2+3-Accepting Universities'} text={'15 Years Accepted for Masters'} move={'10+2+3'} option={'addonConditionType'} />
                    <GreCard count={340} slideImage={`url(${process.env.PUBLIC_URL}/img/chance-program.png)`} heading={'Major Change Accepting Universities'} text={'Undegrad different program to Masters'} move={'COP'} option={'addonConditionType'} />
                    <GreCard count={240} slideImage={`url(${process.env.PUBLIC_URL}/img/dulingo-accpeted.jpeg)`} heading={'Duolingo Accepting Universities'} text={'Universities'} move={'duolingo'} option={'filteroption'} extraoption={true} />
                    <GreCard count={240} slideImage={`url(${process.env.PUBLIC_URL}/img/dulingo-accpeted.jpeg)`} heading={'State wise Universities'} text={'Universities'} move={'state'} option={'filteroption'} extraoption={true} />
                    <GreCard count={170} slideImage={`url(${process.env.PUBLIC_URL}/img/backlog-accepted.jpeg)`} heading={'Backlogs Accepting Universities'} text={'Universities'} move={'backlogs'} option={'filteroption'} extraoption={true} />
                    <GreCard count={200} slideImage={`url(${process.env.PUBLIC_URL}/img/sat-act.jpg)`} heading={'SAT/ ACT Waiver Universities'} text={'Universities for Bachelors'} move={'SAT-ACT'} option={'filteroption'} />
                    <GreCard count={200} slideImage={`url(${process.env.PUBLIC_URL}/img/pte-accpeted.jpeg)`} heading={'PTE Accepting Universities'} text={'Universities Accepting	'} move={'pte'} option={'filteroption'} />
                    <GreCard count={300} slideImage={`url(${process.env.PUBLIC_URL}/img/gmat-waiver.jpeg)`} heading={'GMAT Waiver Universities'} text={'Universities Offering'} move={'gmatwaiver'} option={'filteroption'} />
                    <GreCard count={400} slideImage={`url(${process.env.PUBLIC_URL}/img/emglish-waiver.jpeg)`} heading={'IELTS / English Waiver Universities'} text={'Universities Accepting 12th English'} move={'english'} option={'filteroption'} extraoption={true} />
                    <GreCard count={250} slideImage={`url(${process.env.PUBLIC_URL}/img/application-free.jpeg)`} heading={'Application Fee Waiver Universities'} text={'Universities Offering'} move={'FeeWaiver'} option={'addonConditionType'} />
                    <GreCard count={320} slideImage={`url(${process.env.PUBLIC_URL}/img/stem-university.jpeg)`} heading={'STEM Universities'} text={'Approved Program for OPT'} move={'STEM'} option={'addonConditionType'} />
                    <GreCard count={340} slideImage={`url(${process.env.PUBLIC_URL}/img/copo-university.jpeg)`} heading={'CO-OP Offering Universities'} text={'Canada'} move={'Co-Op'} option={'addonConditionType'} />
                    <GreCard count={100} slideImage={`url(${process.env.PUBLIC_URL}/img/placement-university.jpeg)`} heading={'Placement Offering Universities'} text={'Integrated with Course'} move={'Placement'} option={'addonConditionType'} />
                    <GreCard count={150} slideImage={`url(${process.env.PUBLIC_URL}/img/internship.jpeg)`} heading={'Internship Offering Universities'} text={'Universities Offering'} move={'Internships'} option={'filteroption'} extraoption={true} />
                    <GreCard count={180} slideImage={`url(${process.env.PUBLIC_URL}/img/profile-evaluation.jpeg)`} heading={'Profile Evaluation'} text={'Get the Right Universities'} move={'Profile'} option={'addonConditionType'} url={'/ProfileEvaluation'} />
                    <GreCard count={0} slideImage={`url(${process.env.PUBLIC_URL}/img/profile-evaluation.jpeg)`} heading={'Transfering Options'} text={'Get the Right Universities'} url={'./TransferUniversity'} />
                </Slider>

                <Typography sx={{marginTop:"30px"}}></Typography>
                <Grid className={classes.sxGrid_1Top}>
                    <Grid className={classes.sxGrid_3}>
                        <Button sx={{
                            padding: 0,
                        }}
                            onClick={() => history.push('/SearchScholarship')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/DashBoard/scholarship.png`} alt="web search"
                                width="80%" />
                        </Button>
                        <Button onClick={() => history.push('/SearchScholarship')} variant="text" disableElevation
                            className={classes.viewBtn}>View</Button>
                    </Grid>
                    <Grid className={classes.sxGrid_3}
                    >

                        <Button
                            sx={{
                                padding: 0,
                            }}
                            onClick={() => history.push('/SearchSop')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/DashBoard/sop.png`} alt="web search" width="80%" />
                        </Button>
                        <Button onClick={() => history.push('/SearchSop')} variant="text" disableElevation
                            className={classes.viewBtn}>View</Button>

                    </Grid>
                    <Grid
                        className={classes.sxGrid_3}
                    >

                        <Button
                            sx={{
                                padding: 0,
                            }}
                            onClick={() => history.push('/SearchProgram')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/img/DashBoard/web.png`} alt="web search" width="80%" />
                        </Button>
                        <Button
                            className={classes.viewBtn}
                            onClick={() => history.push('/SearchProgram')}
                            variant="text" disableElevation>View</Button>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        fontSize: "16px",
                        display: "flex",
                        textAlign: "center",
                        marginTop: "30px",
                        marginBottom: "30px",
                        justifyContent: "space-around",
                    }}
                >
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: '20px',
                        textAlign: 'center',
                        fontWeight: 'normal',
                        color: '#1A1A1A'
                    }}> Search <br /> Scholarship </Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: '20px',
                        textAlign: 'center',
                        fontWeight: 'normal',
                        color: '#1A1A1A'
                    }}> Search <br /> SOP </Typography>
                    <Typography sx={{
                        fontSize: "16px",
                        lineHeight: '20px',
                        textAlign: 'center',
                        fontWeight: 'normal',
                        color: '#1A1A1A'
                    }}> Search <br /> Program </Typography>
                </Grid>
            </>
        )
    }

};

export default withRouter(SearchUniversity)
const useStyles = makeStyles(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    submitBtn: {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '167px',
        backgroundColor: '#1B6CFC',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '7px',

    },
    resetBtn: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px',
        height: '45px',
        width: '160px',
        marginRight: '7px',
        color: '#666666',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
    },
    gridFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    sxGrid_1Top: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center",
        mt: 3,
    },
    sxGrid_3: {
        border: '1px solid #DCE8FF',
        boxSizing: "border-box",
        backgroundColor: '#ffffff',
        width: '116px',
        height: '116px',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
        borderRadius: "50%",
        position: "relative",
    },
    viewBtn: {
        backgroundColor: "#fff",
        position: "absolute",
        marginTop: "75px",
        padding: "7px 25px",
        fontWeight: "600",
        color: "#1b6cfc",
        border: " 1px solid #DCE8FF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "30px",
        bottom: '-20px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
}));