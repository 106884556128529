import React from "react";
import { Grid, Typography, LinearProgress, Chip, IconButton, Alert } from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { barColor, getCurrencySymbol } from '../../../Controller/Global';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { getProfileDetails, USER_ID } from "../../../Controller/localStorageHandler";
import { ToastContainer, toast } from 'react-toastify';
import PopUpModal from "../../Result/ApplyModal";
import { useHistory } from "react-router-dom";
import FavouriteModal from "../../University/FavouriteModal";
import BookMarkMessageModal from "../../Result/BookMarkMessageModal";
import moment from "moment";
import _ from 'lodash'
import { makeStyles } from "@mui/styles";
import Colors from "../../../static/layout/Colors";


const Card = (props) => {
    const history = useHistory()
    const classes = useStyles();
    return (
        <div>

            <Grid
                xs={12}
                sx={{
                    justifyContent: "space-between",
                    background: "#fff",
                    mt: 3,
                    mr: 2,
                    pb: 3,
                    borderRadius: '25px',
                    display: "flex",
                    boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 20%), 0px 3px 4px 0px rgba(0 0 0 / 14%), 0px 1px 8px 0px rgba(0 0 0 / 12%)",
                }}
            >
                <Grid
                    xs={2}
                    sx={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: "center",
                        pl: 5
                    }}>

                    <img
                        onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/img/university-default.png` }}
                        src={`${process.env.PUBLIC_URL}/img/EDUCATION_LOAN/${props?.ImgName?.img}`}
                        style={{
                            backgroundColor: "#fff",
                            padding: "7px",
                            height: "60px",
                        }}
                        alt='trinity'
                    />

                </Grid>
                <Grid xs={9} sx={{ paddingLeft: "4%", paddingRight: 5 }} style={{ width: 200 }}>
                    <Typography style={{ fontSize: 18, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} sx={{ fontWeight: "bold", fontSize: "14px", mt: 1 }}>
                        {props.value.vendorname}
                    </Typography>

                    <hr />
                    <Grid xs={12} style={{ display: "flex", alignItems: "center" }}>

                        <Grid xs={3}>
                            <img onClick={() => {
                                history.push({
                                    pathname: '/add-education-loan',
                                    state: {
                                        'data': props,}
                                })
                            }} src={`${process.env.PUBLIC_URL}/img/education/apply.png`} alt='' style={{ width: "100%", height: "100%" }} />
                        </Grid>
                        <Grid xs={3}>
                            <a href={`https://${props.value?.website}`} target='_blank'>
                                <img src={`${process.env.PUBLIC_URL}/img/education/earth.png`} alt='' style={{ width: "100%", height: "100%" }} />
                            </a>
                        </Grid>
                        <Grid xs={3}>
                            <img src={`${process.env.PUBLIC_URL}/img/education/whatsapp.png`} alt='' style={{ width: "100%", height: "100%" }} />
                        </Grid>
                        <Grid xs={3}>
                            <a href={`tel:${props.value?.phone}`}>
                                <img src={`${process.env.PUBLIC_URL}/img/education/call.png`} alt='' style={{ width: "100%", height: "100%" }} />
                            </a>
                            {/* <a href={`mailto:${props.value?.email}`}>
                                <img src={`${process.env.PUBLIC_URL}/img/education/msg.png`} alt='' />
                            </a> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
};
export default Card;


const useStyles = makeStyles(() => ({

    icons: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}));