import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import { SliderData } from './SliderData'
import { Box } from '@mui/system';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TopSlider extends Component {
    render() {
        return (
            <div className="container" style={{ maxHeight: 150 }}>
                {/* <Slider {...settings}> */}
                <div style={{ display: "-webkit-box", overflowY: "scroll", width: "100%", whiteSpace: "nowrap", justifyContent: "space-between" }}>
                    {SliderData.map((data, ind) =>
                        <Box sx={{
                            bgcolor: '#fff',
                            borderRadius: 3,
                            pt: 2,
                            pb: 2,
                            mb: 2,
                            boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)",
                            width: "95%",
                            marginRight: 2,
                            position: "relative"
                        }}
                        // onClick={() => history.push(`/CollegeDetails/`, val)}
                        >
                            <div className="SmallCon" style={{ paddingLeft: 10, display: "flex", alignItems: "center", width: "100%", }}>
                                <LazyLoadImage src={data.image} style={{ borderRadius: 10 }} width={"50%"} height={130} alt="Credit to Joshua Earle on Unsplash" />
                                <h2 style={{ whiteSpace: "nowrap", overflow: "hidden!important", textOverflow: "ellipsis", width: "50%", marginTop: 0, display: "flex", justifyContent: "center" }} className="txtData">{data.content}</h2>
                            </div>
                        </Box>
                    )}
                </div>

                {/* </Slider> */}
            </div >
        );
    }
}

export default TopSlider;
