import { Autocomplete, FormControl, Input, TextField } from '@mui/material'
import React from 'react'

export default function MultipleExtraField(props) {
    const{interPercent,backlogs,bachelorPercent}= props
    return (
        <div>
            <FormControl sx={{m: 1, minWidth: "100%"}}>
                <TextField 
                    type="number"
                    InputProps={{
                        inputProps: { 
                            max: 100, min: 40 
                        }
                    }}
                    value={bachelorPercent}
                    onChange={(e)=>props.setbachelorPercent(e.target.value)}
                    label= "Bachelors %"
                />
        </FormControl>
        <FormControl sx={{m: 1, minWidth: "100%"}}>
            <TextField 
                    type="number"
                    InputProps={{
                        inputProps: { 
                            max: 100, min: 0 
                        }
                    }}
                    value={backlogs}
                    onChange={(e)=>props.setbacklogs(e.target.value)}
                    label= "Bachelors"
                />
        </FormControl>
        <FormControl sx={{m: 1, minWidth: "100%"}}>
            <TextField 
                    type="number"
                    InputProps={{
                        inputProps: { 
                            max: 100, min: 40 
                        }
                    }}
                    value={interPercent}
                    onChange={(e)=>props.setinterPercent(e.target.value)}
                    label= "PUC/12th/Inter %"
                />
        </FormControl>
        </div>
    )
}
