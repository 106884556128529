import React from 'react';
import { useHistory } from 'react-router-dom';

const ApplicationsCard = ({ name, trans, dollar, persent }) => {
    const history = useHistory()
    // const { name, trans, dollar, persent } = ApplicationData;

    return (
        <div className="d-flex align-items-center follow_up_card" style={{ boxShadow: "0px 10px 20px rgba(0 0 0 / 0.4), 0px 2px 6px rgba(0 0 0 / 0.4), 0px 0px 1px rgba(0 0 0 / 0.4)", justifyContent: "space-between", paddingLeft: 20, paddingRight: 30 }}
            onClick={() => {
                history.push({
                    pathname: '/application-status',
                    state: {
                        'status': name !== "Total" ? name : 'All',
                        "type": "Application"
                    }
                })
            }
            }
        >
            {/* <div>
                <img src={logo1} alt="" />
            </div> */}
            <div>
                <h3 className="budget_text">{name}</h3>
                <h4 className="budget_title">{trans}</h4>

            </div>
            <div>
                <h3 className="budget_text">{dollar}</h3>
                <h4 className="budget_title">{persent}</h4>
            </div>
        </div>
    );
};

export default ApplicationsCard;