import React, { useEffect } from 'react'
import {
    Alert,
    Autocomplete,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography
} from "@mui/material"
import { Box } from '@mui/system'
import { Contact_Id, getSessionId, getUser, getUserId, logout, SESSION_ID, setUser, USER_ID } from '../../Controller/localStorageHandler';
import axios from 'axios';
import { useFormik } from 'formik';
import { fetchProfileDetails } from '../Action';
import { OPERATION, operationValue } from '../../Controller/Global';
import useAppContext from "../../useContext/userContext"

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    pt: 1,
    pb: 1,
    px: 0,
    overflow: 'hidden',
};
export const MatchingUnivercityPopUp = ({ matchingUniverCity, onclose, setLoading }) => {

    const {
        getRelatedProgramsPickList,related_programs_picklist,
        getCountryPickList,country_picklist,
        getSemesterPickList,semester_picklist,
        getYearPickList,year_picklist,
    } = useAppContext()


    const [countryValue, setcountryValue] = React.useState(getUser().country ? [getUser().country] : [])
    const [programValue, setprogramValue] = React.useState(getUser().related_programs ? [getUser().related_programs] : [])
    const [semesterValue, setsemesterValue] = React.useState(getUser().semester_looking_for)
    const [yearValue, setyearValue] = React.useState(getUser().year_looking_for)
    const [success, setSuccess] = React.useState('');
    const [checkValid, setCheckValid] = React.useState(false)
    const formik = useFormik({
        initialValues: {
            degree_looking_for: getUser().degree_looking_for,
        },
        onSubmit: (values) => {
            setLoading(true)
            const popupData = new FormData()
            popupData.append('module', 'Contacts')
            popupData.append(SESSION_ID, getSessionId())
            popupData.append(USER_ID, getUserId())
            popupData.append('operation', 'updateRecord')
            popupData.append('values[redirectpage]', 'dashboard')
            for (let val in values) {
                popupData.append(`values[${val}]`, values[val])
            }
            programValue.length > 0 ? programValue.map(o => popupData.append(`values[related_programs][]`, o)) : popupData.append(`values[related_programs][]`, '')
            countryValue.length > 0 ? countryValue.map(o => popupData.append(`values[country][]`, o)) : popupData.append(`values[country][]`, '')
            popupData.append('values[year_looking_for]', yearValue)
            popupData.append('values[semester_looking_for]', semesterValue)
            if (yearValue && semesterValue && values && programValue.length > 0 && countryValue.length > 0) {
                setCheckValid(false)
                axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", popupData)
                    .then((response) => {
                let userId = getUserId()
                if (userId) {
                    var formdata = new FormData();
                    formdata.append(Contact_Id, getUserId());
                    formdata.append(SESSION_ID, getSessionId())
                    formdata.append(USER_ID, getUserId())
                    formdata.append(OPERATION, operationValue.Fetch_profile_details)

                    axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', formdata)
                        .then((response) => {
                            if (response.statusText === "OK") {
                                setSuccess('Details Updated Successfully')
                                setUser(response.data.data[0])
                                setTimeout(function () {
                                    onclose()
                                    setLoading(false)
                                    window.location.reload()
                                }, 1000)
                            } else {
                                logout()
                            }
                        })
                }
                })
            }
            else {
                setCheckValid(true)
            }

        }
    })

    useEffect(() => {
        getRelatedProgramsPickList()
        getCountryPickList()
        getSemesterPickList()
        getYearPickList()
    }, []);


    return <Modal
        open={matchingUniverCity}
        // onClose={onclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        {success ?
            <Alert style={{ margin: '150px auto 0', width: "80%" }} severity="success">{success}</Alert>
            :

            <form onSubmit={formik.handleSubmit} >
                <Box sx={style}>
                    {checkValid && <p style={{ color: "red", padding: '0 20px', margin: '0' }}>Please fill all the information</p>}
                    <Grid
                    item
                        xs={12}
                        sx={{
                            p: 3,
                            paddingBottom: "80px",
                            backgroundColor: "#fff",
                            // mt: 3,
                            pb: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                marginBottom: '10px',
                                fontWeight: '600',
                                fontSize: '16px'
                            }}
                        >
                            Looking for
                        </Typography>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <Select
                                sx={{
                                    height: '52px',
                                    lineHeight: '20px'
                                }}
                                required={true}
                                label="Degree"
                                name="degree_looking_for"
                                value={formik.values.degree_looking_for}
                                onChange={(e) => formik.handleChange(e)}
                            >
                                <MenuItem value="Masters">Masters</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Bachelors">Bachelors</MenuItem>
                                <MenuItem value="Phd">Phd</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 3 }}>
                            <Autocomplete
                                name='country'
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                value={countryValue}
                                onChange={(e, val) => setcountryValue(val)}
                                options={country_picklist?.country_picklist && country_picklist?.country_picklist.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select Country" />}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 3 }}>
                            {/*<InputLabel>Program</InputLabel>*/}
                            <Autocomplete
                                name="related_programs"
                                id="free-solo-demo"
                                freeSolo
                                multiple
                                value={programValue}
                                onChange={(e, val) => setprogramValue(val)}
                                options={related_programs_picklist?.related_programs_picklist && related_programs_picklist?.related_programs_picklist.map((option) => option)}
                                renderInput={(params) => <TextField {...params} label="Select Program" />}
                            />
                        </FormControl>
                        <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid item xs={5.8}>
                                <FormControl fullWidth sx={{ mt: 3 }}>
                                   
                                    <Autocomplete
                                        name='semester_looking_for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiples
                                        value={semesterValue}
                                        onChange={(e, val) => setsemesterValue(val)}
                                        options={semester_picklist?.semester_picklist && semester_picklist?.semester_picklist.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Semester" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5.8}>
                                <FormControl fullWidth sx={{ mt: 3 }}>
                                  
                                    <Autocomplete
                                        name='year_looking_for'
                                        id="free-solo-demo"
                                        freeSolo
                                        // multiples
                                        value={yearValue}
                                        onChange={(e, val) => setyearValue(val)}
                                        options={year_picklist?.year_picklist && year_picklist?.year_picklist.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Year" />}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 1, justifyContent: "center" }}>
                                    <button type='submit' className="submitBtn mt-4" >Submit</button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        }
    </Modal>
}
