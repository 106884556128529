import { Autocomplete, FormControl, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'

export default function ExtraField(props) {
    const [findMove, setfindMove] = React.useState('')
    const [optionData, setOtionData] = React.useState([])

    const datavalues = [
        {
            move: 'low-tution',
            label: 'Tuition Fee',
            obj: ''

        },
        {
            move:'duolingo',
            label:'Duolingo',
            obj:''
        },
        {
            move: 'Internships',
            label: 'Internship',
            obj: 'internship'
        },
        {
            move: 'state',
            label: 'Select State',
            obj: ''
        },
        {
            move: 'backlogs',
            label: 'Backlogs',
            obj: ''
        },
        {
            move: 'pte',
            label: 'PTE Score',
            obj: ''
        },
        {
            move: 'english',
            label: 'English %',
            obj: ''
        },

    ]
    const getOptions = (o) => {
        if (o === 'low-tution') {
            const fee = ['0-10000',
                '10000-15000',
                '15000-20000',
                '20000-25000',
                '25000-30000',
                '30000-35000',
                '35000-40000',
                '40000 +']
            return setOtionData(fee)
        } else if (o === 'Internships') {
            axios.get('https://erp.bscglobaledu.com/mapi/search/ajax_get_university_internship')
                .then((res) => {
                    const array = []
                    res.data.data.map(o => array.push(o.internship))
                    return setOtionData(array)
                })
        }
        else if(o === 'duolingo'){
            axios.get('https://erp.bscglobaledu.com/mapi/search/ajax_get_university_duolingo')
            .then((res)=>{
                // const array = []
                // console.log(res.data)
                // res.data.data.map(o => array.push(o.internship))
                return setOtionData(res.data.data)
            })
        }
        else if (o === 'state') {
            var data = new FormData();
            data.append('operation', 'fetchPicklist');
            data.append('name', 'state');

            var config = {
                method: 'post',
                url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
                data: data
            };

            axios(config)
                .then(function (response) {
                    // const array = []
                    // console.log(res.data)
                    // res.data.data.map(o => array.push(o.internship))
                    return setOtionData(response.data.data)
                })
                .catch(function (error) {
                    console.log(error);
                });

            // axios.get('https://erp.bscglobaledu.com/mapi/search/ajax_get_university_duolingo')
            //     .then((res) => {
            //         // const array = []
            //         // console.log(res.data)
            //         // res.data.data.map(o => array.push(o.internship))
            //         return setOtionData(res.data.data)
            //     })
        }
        else if (o === 'backlogs') {
            axios.get('https://erp.bscglobaledu.com/mapi/search/ajax_get_university_backlogs')
                .then((res) => {
                    console.log("res", res)
                    const array = []
                    res.data.data.map(o => array.push(o.backlogs))
                    return setOtionData(array)
                })
        }
        else if (o === 'pte' || o === 'english') {
            axios.get('https://erp.bscglobaledu.com/mapi/search/ajax_get_university_english')
                .then((res) => {
                    const array = []
                    res.data.data.map(o => array.push(o.english))
                    return setOtionData(array)
                })
        }
    }
    useEffect(() => {
        const o = datavalues.find(obj => obj.move === props.move)
        setfindMove(o)
        getOptions(o.move)
    }, [])
    return (
        <FormControl sx={{ m: 1, minWidth: "100%" }}>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                // multiple
                value={props.optionValue}
                onChange={(e, val) => props.setOptionValue(val)}
                options={optionData && optionData.map((option) => option)}
                renderInput={(params) => <TextField {...params} label={findMove.label} />}
            />
        </FormControl>
    )
}
