import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import Calling from '../../../img/Calling.png';
import whatsapp from '../../../img/whatsapp.png';
import { useHistory } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import ChatModal from "./ChatModal";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { getUser } from '../../../Controller/localStorageHandler';
import { getCurrencySymbol } from "../../../Controller/Global";
import moment from 'moment';
import Chip from '@mui/material/Chip';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Checkbox from '@mui/material/Checkbox';

const Chat = ({ data,checkedList,setCheckedList, refreshPage, programList, countryList, semesterList, type, convertByList }) => {
  // console.log(data)
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleOpenAndClose = () => setOpen(!open);

  const classes = useStyles();

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setCheckedList([...checkedList, value]); //*1 해줘야 number로 들어가서 type 호환이 됨.
    } else {
      setCheckedList(checkedList.filter((item) => item != value));
    }
  };

  const getString = (Data) => {
    const UserData = getUser()
    let TempData = ''

    if (Data?.projectstatus === 'Deposit Due' 
    || Data?.projectstatus === 'Visa Yet To Apply' 
    || Data?.projectstatus === 'Visa Applied' 
    || Data?.projectstatus === 'Visa Denied' 
    || Data?.projectstatus === 'Visa Approved' 
    || Data?.projectstatus === 'Not Enrolled' 
    || Data?.projectstatus === "Shortlisting" 
    || Data?.projectstatus === "Filling"
    || Data?.projectstatus === "Application Review") {
      let Date = Data?.uni_deposit_deadline

      TempData = `Dear ${Data.lastname} ${Data.firstname} 
    %0D%0A%0D%0AGreetings from *${UserData.account_accountname}*
    ${Data?.projectstatus === 'Deposit Due' || Data?.projectstatus === 'Visa Yet To Apply' || Data?.projectstatus === 'Visa Applied' || Data?.projectstatus === 'Visa Denied' 
    ?
    `%0D%0A%0D%0A*Schedule Free Visa Mock* : https%3A%2F%2Fapp.bscglobaledu.com%2F%23%2Fschedule%2Fvisa_mock`
    :
      ``
    }
    %0D%0A%0D%0APlease find the Status of your Applications. 
    %0D%0A%0D%0AExplore 🏫 *60000*+  Programs and Universities, 10000+  Scholarship, 5000+  SOP and LOR Samples. 
    %0D%0A%0D%0AAndroid: https://bit.ly/FreeStudyAbroad
    %0D%0A%0D%0AiOS: https://apple.co/33ujR4Q 
    %0D%0A%0D%0A*Remarks* : ${Data.project_stu_remarks || ''}
    %0D%0A%0D%0APlease find the email and password which we have used for your University Applications 
    %0D%0A*Email* : ${Data.bsc_mail_id || ''}
    %0D%0A*Password* : ${Data.bsc_pwd || ''}

    %0D%0A%0D%0A *University:${Data.university}*
    %0D%0A ${Data?.program ? Data?.program : Data?.related_programs}            
    ${Data?.projectstatus === 'Deposit Due' ? `%0D%0A *Deposit Amount*: ${Data?.country ? getCurrencySymbol(Data?.country) : ''}${Math.ceil(Data?.uni_tuition_deposit)}` : ''}
    ${Data?.projectstatus === 'Deposit Due' ? `%0D%0A *Deadline*: ⏰${Date ? moment(Date).format('ll') : ""}` : ''}   
    %0D%0A *Intake*: ${Data?.funding_type ? Data?.funding_type : Data?.pro_task_semester} ${Data?.year}
    %0D%0A *Status:* ${Data?.projecttaskstatus || ''}
    ${Data?.application_link ? `%0D%0A Application url : ${encodeURIComponent(Data?.application_link)}` : ""} 
    ${Data?.online_id ? `%0D%0A Login Id : ${encodeURIComponent(Data?.online_id)}` : ""} 
    ${Data?.online_pwd ? `%0D%0A Password : ${encodeURIComponent(Data?.online_pwd)}` : ""} 
    %0D%0A%0D%0A
    %0D%0A *Remarks*: ${Data.stu_remarks}
    %0D%0A%0D%0A
    %0D%0A${Data?.projecttaskstatus === 'Deposit Due' ? '*Kind Note: Deposit Amount and ⏰ Deposit Deadline may change, for exact amount and date contact your Mentor*' : ''}

    %0D%0A%0D%0A *Mentor*:${UserData?.firstname || ''} ${UserData?.lastname || ''} 
    ${UserData?.assigned_phone_mobile ? `%0D%0A${UserData?.assigned_phone_mobile}` : ''}
    ${UserData?.assigned_phone_work ? `%0D%0A${UserData?.assigned_phone_work}` : ''}
    ${UserData?.assistantphone ? `%0D%0A${UserData?.assistantphone}` : ''}
    ${UserData?.assigned_email1 ? `%0D%0A${UserData?.assigned_email1}` : ''}
    ${UserData?.reports_to_email1 ? `%0D%0A${UserData?.reports_to_email1}` : ''}
    ${UserData?.account_website ? `%0D%0A${UserData?.account_website}` : ''}  
    %0D%0A%0D%0A*BRAINSTORM CONSULTING*
    %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
    %0D%0A iOS: https://apple.co/33ujR4Q             
    `
    }
    else {
      TempData = `Hello ${Data.lastname} ${Data.firstname}`
    }

    return `https://api.whatsapp.com/send?phone=${Data?.mobile || ''}&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")}`
  }

  return (
    <div className={`${classes.MainDiv}`} style={{ backgroundColor: '#fff' }}>
      <div className={`${classes.container} flexCenterJcB`} style={{ width: "100%", padding: 15, backgroundColor: '#EBF2FF' }}>
        <div style={{ width: "5%" }} >
          {/* <CheckBoxOutlineBlankOutlinedIcon className={classes.squareIcon} /> */}
          <Checkbox 
          onClick={handleCheckboxClick} 
          value={data?.contactid}
          checked={checkedList.includes(data?.contactid)} 
          defaultChecked />

        </div>
        <div style={{ display: "flex" }} onClick={() => history.push({ pathname: '/Profile', state: { StudentData: data, formNo: 1 } })}>
          {/* <img
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/img/SidebarIcon/user.png`}
            alt=""
          /> */}
          <AccountCircleOutlinedIcon sx={{ fontSize: '40px', color: "#24bcc9", display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px', mr: '10px' }} />
          <main className={classes.nameDetails}>
            <p className={classes.name}>{data.firstname} {data.lastname}
              &nbsp; <InstagramIcon sx={{ fontSize: '25px', color: '#bc2a8d', cursor: 'pointer' }} />
              &nbsp; <LinkedInIcon sx={{ fontSize: '25px', color: '#0072b1', cursor: 'pointer' }} />
            </p>
            {type === 'campaign' ?
              <>
                {
                  data.bachelors_percentage ? <Chip size="small" label={`Bachelors % : ${data?.bachelors_percentage}%`} color="primary" /> : ''
                }
                {
                  data.number_backlogs > 0 ? <Chip size="small" label={`Backlogs : ${data?.number_backlogs}`} color="success" /> : ''
                }
                {
                  data.gmat_total_1st_attempt ? <Chip size="small" label={`Gre : ${data?.gmat_total_1st_attempt}`} color="error" /> : ''
                }
                {
                  data.toefl_total_1st_attempt ? <Chip size="small" label={`Toefl : ${data?.toefl_total_1st_attempt}`} color="secondary" /> : ''
                }
                {
                  data.pte_total_1st_attempt ? <Chip size="small" label={`Pte : ${data?.pte_total_1st_attempt}`} color="primary" /> : ''
                }
                {
                  data.duolingo_total_1st_attempt ? <Chip size="small" label={`Duolingo : ${data?.duolingo_total_1st_attempt}`} color="success" /> : ''
                }
              </>
              :
              ''
            }
            {
              data.university ? <p style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#4caf50', color: '#fff', whiteSpace: 'nowrap', width: '200px', overflow: 'hidden' }}>{data?.university}</p> : ''
            }
            &nbsp;
            {
              data.pro_task_semester ? <Chip size="small" label={data?.pro_task_semester} color="primary" /> : ''
            }
            &nbsp;
            {
              data.year ? <Chip size="small" label={data?.year} color="warning" /> : ''
            }
            &nbsp;
            {
              data.projecttaskpriority ? <Chip size="small" label={data?.projecttaskpriority} color="error" /> : ''
            }
            &nbsp;
            {
              data?.projectstatus === 'Deposit Due' || data?.projectstatus === 'Visa Yet To Apply' || data?.projectstatus === 'Visa Applied' || data?.projectstatus === 'Visa Denied' || data?.projectstatus === 'Visa Approved' || data?.projectstatus === 'Not Enrolled'
                ?
                data?.projectstatus === 'Visa Applied' || data?.projectstatus === 'Visa Yet To Apply' || data?.projectstatus === 'Visa Approved' || data?.projectstatus === 'Not Enrolled'
                  ?
                  <>
                    <br />
                    {
                      data?.visa_date
                        ?
                        <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ff9800', color: '#fff' }}>
                          {data?.visa_date}
                        </span>
                        :
                        ''
                    }


                    {data?.pref_time_to_call
                      ?
                      <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#0288d1', color: '#fff' }}>
                        {data?.pref_time_to_call}
                      </span>
                      :
                      ''
                    }

                  </>
                  :
                  <>
                    {
                      data?.country
                        ?
                        <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ff9800', color: '#fff' }}>
                          {data?.country ? getCurrencySymbol(data?.country) : ''}
                          {data?.uni_tuition_deposit ? Math.ceil(data?.uni_tuition_deposit) : ''}
                        </span>
                        :
                        ''
                    }
                    {
                      data?.uni_deposit_deadline
                        ?
                        <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ef5350', color: '#fff' }}>
                          ⏰ {data?.uni_deposit_deadline ? moment(data?.uni_deposit_deadline).format('ll') : ""}
                        </span>
                        :
                        ''
                    }

                  </>
                :
                data?.eduloan_status
                  ?
                  <p className={classes.details}>{data?.eduloan_status}</p>
                  :
                  data.related_programs ?
                    <span style={{ border: '1px solid #fff', fontSize: '13px', borderRadius: '10px', padding: '4px', backgroundColor: '#ff9800', color: '#fff' }}>{data.related_programs && data.related_programs.split('|##|').map((con) => {
                      return con + ","
                    }) || ''}</span> : ''

            }

          </main>
        </div>
        {/* <nav className="flexCenterJcB"> */}
        <div style={{ display: "flex", flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
          <div

            style={{

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
              height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
            }}>
            <CommentIcon onClick={() => setOpen(true)} sx={{ fontSize: '30px', color: '#00acee', cursor: 'pointer' }} />
          </div>
          <div style={{
            marginLeft: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 30,
            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          }}>
            <a href={getString(data)} target="_blank">
              <WhatsAppIcon sx={{ fontSize: '30px', color: '#4FCE5D', cursor: 'pointer' }} />
            </a>
          </div>
          <div
            onClick={() => {
              window.open(`tel:+${data.mobile}`);
            }}
            style={{
              marginLeft: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
              height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
            }}>
            <CallIcon sx={{ fontSize: '30px', color: '#229ED9', cursor: 'pointer' }} />
          </div>

        </div>
        {/* </nav> */}
        <ChatModal open={open} onClose={(handleOpenAndClose)} val={data} refreshPage={refreshPage} programList={programList} countryList={countryList} semesterList={semesterList} convertByList={convertByList}/>
      </div>
    </div>
  );
};

export default Chat;

const useStyles = makeStyles(() => ({

  container: {
    // background: "#fff",
    // boxShadow: "rgba(0 0 0 / 0.4) 0px 10px 20px, rgba(0 0 0 / 0.4) 0px 2px 6px, rgba(0 0 0 / 0.4) 0px 0px 1px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "0 3% 0 5%",
    height: '10%'
  },
  image: {
    height: "45px",
    width: "45px",
    borderRadius: "30px",
  },
  nameDetails: {
    marginTop: "15px",
  },
  name: {
    // fontWeight: "bold",
    //   minWidth: "200px",
    fontSize: '14px',
    flexGrow: 1,
    '@media (max-width: 472px)': {
      fontSize: '14px',
      wordBreak: 'break-all'
    }
  },
  details: {
    // fontWeight: '500',
    // marginTop: '-2%',
    fontSize: '11px',
    '@media (max-width: 472px)': {
      fontSize: '11px',
      wordBreak: 'break-all'
    }
  },
  squareIcon: {
    opacity: 0.5,
    fontSize: '20px'
  },
  logos: {
    alignSelf: 'flex-end',
    '@media (max-width: 472px)': {
      justifyContent: 'center',
      //  margin : 'auto'
    }
  }
}));
