import axios from "axios";

export const getAssociateStudentListCountStatusAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/student/get_student_status_count',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    })
}

export const getAssociateStatusProjecttCountAction = (data) => {
    return new Promise((resolve, reject) => {


        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/associatestatus/ajax_student_project_count_new',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    })
}

export const getEducationVendorData = (data) =>{
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/dashboard/ajax_vendor_dashboard_total',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    })
}

export const getEducationLoanStudents = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/vendor/ajax_get_vendor_studentList',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    })
}

export const getStudentCountListUsingStatusAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/student/ajax_get_studentList',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

    })
}

export const getStudentCountListUsingStatusAction1 = (data) => {
    return new Promise((resolve, reject) => {

        
        var config = {
          method: 'post',
          url: 'https://erp.bscglobaledu.com/mapi/student/ajax_get_studentList', 
          data : data
        };
        
        axios(config)
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
        

    })
}

export const ajax_get_recentComments = (data) =>{
    return new Promise((resolve,reject) =>{

        var config = {
            method: 'post',
            url : 'https://erp.bscglobaledu.com/mapi/student/ajax_get_recentCommends',
            data : data
        }

        axios(config)
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
}

export const ajax_update_Comment = (data) =>{
    return new Promise((resolve,reject) =>{

        var config = {
            method: 'post',
            url : 'https://erp.bscglobaledu.com/mapi/student/ajax_update_Commend',
            data : data
        }

        axios(config)
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
}

export const GetApplicationCountDataAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/associatestatus/ajax_project_task_count',
            data: data
        };
// today sumbitted end date is today
// today filling start date is today 
// today visa visa date is today
        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

    })
}

export const GetRegisterCountDataAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/associatestatus/ajax_student_project_status_new',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export const GetEventJoinedListAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/associatestatus/ajax_event_student_list',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export const GetUniversityProgramStudents = (data) => {
    return new Promise((resolve,reject) =>{
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/associatestatus/get_university_program_students',
            data: data
        };

        axios(config)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}
