import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, Chip,Typography,Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Chat from './Chat';
import { useHistory } from 'react-router-dom';
import { GetUniversityProgramStudents } from '../../Action/AssociateDocumentList';
import { getUser,getUserType,getSessionId,getUserId, setCookie_type, getCookie_type,setCookie_status,getCookie_status ,setStatusStudents,getStatusStudents } from '../../../Controller/localStorageHandler';
import { Box } from '@mui/system';
import { fetchComoonPickerListAction } from '../../Action';
// import { AsyncMulti } from '../../StudentSearch';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import axios from "axios";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash'

import useAppContext from "../../../useContext/userContext"


const Students = () => {
    const history = useHistory();
    const classes = useStyles();

    const {
        getSemesterPickList,
        semester_picklist,
        getYearPickList,
        year_picklist
    } = useAppContext()

    console.log(semester_picklist)

    const [StudentList, SetStudentList] = useState([])
    const [allStudentList, SetallStudentList] = useState([])
    const [loading, Setloading] = useState(false)
    const [page, setPage] = React.useState(1)
    const [semesterlist, SetsemesterList] = React.useState([])
    const [yearList, SetyearList] = React.useState([])
    const [leadSourceList,setLeadSourceList] = useState([]);
    const [selectedSemester, SetSelectedSemester] = React.useState('')
    const [selectedYear, SetSelectedYear] = React.useState('')
    const [selectedLeadsource,setSelectedLeadsource] = React.useState('')
    const [open, SetOpen] = React.useState(false)
    const [search, SetSearch] = React.useState('')
    const [SearchSelectId, setSearchSelectId] = React.useState(null)
    const [Student, setStudent] = React.useState(getUser())
    const [dateRange,setDateRange] = React.useState('');
    const [refresh,setRefresh] = React.useState(false);
    const [total,setTotal] = React.useState(0);

    // list for chat model
    const [countryList, setCountryList] = React.useState([]);
    const [programList, setProgramList] = useState([]);

    const [value, setValue] = React.useState([null, null]);

    const type = history?.location?.state?.type ?  history?.location?.state?.type : getCookie_type()
    const status = history?.location?.state?.status ? history?.location?.state?.status : getCookie_status()
    const val = history?.location?.state?.val ? history?.location?.state?.val : getStatusStudents()

    const Limit = 10

    const user_type = getUserType()

    

    const loadMore = (page) => {
        setPage(page)
    }

    const GetStudentData = (contact_no) => {
     
        var data = JSON.stringify({
            "userid":getUserId(),
            "sessionid":getSessionId(),
            "university":val?.university || null,
            "program":val?.program || null,
            "degree_type":val?.degree_type || null,
            "type" : type,
            "bypass": "validation",
            "uni_id": val?.universitiesid || val?.campaignid || val || null,
            "filtersearch": ``,
            "limit_end": Limit,
            "limit_start": Limit * (page - 1),
            "priority": "All",
        });
        Setloading(true)
        SetStudentList([])
        SetallStudentList([])
        GetUniversityProgramStudents(data).then((res)=>{
         console.log(res)
            Setloading(false)
            SetStudentList(res?.data?.list || [])
            SetallStudentList(res?.data?.list || [])
            setTotal(res?.data?.total || 0)
        }).catch((error) => {
            Setloading(false)
        }) 
    }
    useEffect(()=>{
        getSemesterPickList()
        getYearPickList()
        if(history?.location?.state?.val){
            setStatusStudents(history?.location?.state?.val)
            setCookie_status(history?.location?.state?.status)
            setCookie_type(history?.location?.state?.type)
        }
        else{
 
        }
    },[])

    useEffect(() => {
   
        GetStudentData()
    }, [type || "Student"])


    useEffect(() => {
   
        studentFilter()
    }, [selectedYear,selectedSemester])


    useEffect(() => {
        GetStudentData()
    }, [status, page, search,dateRange,refresh,selectedLeadsource])


    useEffect(() => {
        async function syncData() {
            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'related_programs');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                });
    
            params.append('name', 'country');
            const countryRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);
       
        setProgramList(response.data.data);
        setCountryList(countryRes.data.data);
        }
        syncData();
    
        
    }, []);


    const studentFilter = () =>{
        let Datas = allStudentList

        if(selectedYear || selectedSemester){
           
            let ResultData = []
            if (selectedYear) {
                // ResultData = _.filter((Datas), (d) => selectedYear.includes(d.year_looking_for))
                ResultData = _.filter((Datas), (d) => {return d.year_looking_for === selectedYear})
 
            }

            if (selectedSemester) {
                if(selectedYear){
                ResultData = _.filter((ResultData), (d) => {return d.semester_looking_for === selectedSemester})
                }
                else{
                ResultData = _.filter((Datas), (d) => {return d.semester_looking_for === selectedSemester})
                }
                // ResultData = _.filter((Datas), (d) => selectedSemester.includes(d.semester_looking_for))
    
                console.log(selectedSemester)
            }

            SetStudentList([...ResultData]) 
        }
    }

    return (
        <div >

            <div style={{ position: "relative" }}>
                {/* <AsyncMulti inputData={Student} setSearchSelectId={GetSelectedData} SubmitFilter={SubmitFilter} /> */}
                {/* <h3 style={{ marginTop: 20 }}>Student List</h3> */}
            </div>
            {/* <main className={`${classes.inputContainer}`}>
                <SearchIcon sx={{ opacity: 0.5 }} />
                 <input id="inputtextsearch" className={classes.input} autoFocus placeholder='Search Students...' />  
            </main> */}
   <Chip onClick={() => history.goBack()} size="small" sx={{marginBottom:'10px'}} icon={<ArrowBackIcon />} label="Back" color="primary" variant="outlined"/>

    <div className={`${classes.dropdownContainer} flexCenterJcB hideScrollbar`} >

            <FormControl  sx={{ m: 1,minWidth: "25%"}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Semester"
                        onChange={({ target }) => {
                            SetSelectedSemester(target.value)
                        }}
                        // size="small"
                    >
                        {semester_picklist?.semester_picklist?.length > 0 && semester_picklist?.semester_picklist?.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
 
      {/* ===================================================================== */}
      <FormControl   sx={{ m: 1,minWidth: "25%"}}  fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={({ target }) => {
                            SetSelectedYear(target.value)
                        }}
                        // size="small"
                    >
                        {year_picklist?.year_picklist?.length > 0 && year_picklist?.year_picklist?.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })}
                    </Select>
                </FormControl>



        
               
            </div>
     

            <div className='flexCenterJcB'>
                <main className='flexCenterJcB'>
                    {/* <CheckBoxOutlineBlankOutlinedIcon className={classes.squareIcon} /> */}
                    <p className={classes.student}><span style={{border:'1px solid #fff',fontSize:'13px',borderRadius:'10px',padding:'4px',backgroundColor:'#ff9800',color:'#fff'}}>{status || getCookie_status() || "All"}</span><span style={{border:'1px solid #fff',fontSize:'13px',borderRadius:'10px',padding:'4px',backgroundColor:'#ef5350',color:'#fff'}}>  {StudentList?.length || 0} </span></p>
                </main>
                <main>
                  
             
                </main>
            </div>
            {val?.degree_type} {' '} {val?.program}
            {val?.campaignname ? <Chip label={`Date ${moment(val?.event_date).format("MMM Do YYYY")}`} /> : ''}
            {val?.campaignname ? <Chip label={val?.camp_time} /> : ''}


           
            <Grid container spacing={2}>
            {loading ?
                <div style={{ textAlign: 'center', marginTop: '55%' }}>
                    <CircularProgress />
                </div> :
    
                StudentList?.length > 0 && StudentList?.map((d) => {
                 
                    return <Grid item lg={6}>
                    <Box sx={{
                        // bgcolor: 'transparent',
                        // borderRadius: 3,
                        pt: 1,
                        pb: 1,
                        mb: 2,
                        // paddingBottom: "30px",
                        height: 'auto', 
            
                        // boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)"
                    }}><Chat data={d} refreshPage={setRefresh} programList={programList} countryList={countryList} semesterList={semesterlist} /></Box>
                    </Grid>
                })
            }
            </Grid>
            {!loading && <Box
             display="flex"
             justifyContent="center"
             alignItems="center">
                <Pagination
                    style={{ paddingTop: 50 }}
                    defaultPage={1}
                    page={page}
                    count={Math.ceil(StudentList?.length / 10)}
                    color="primary"
                    onChange={(e, p) => loadMore(p)}
                />
            </Box>}

        </div>
    )
}

export default Students

const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        padding: '10px',
        width: '100%',
        height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        boxShadow: '2px 2px 10px #DCDCDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7
    },
    dropdownContainer: {
        margin: '10px 0 10px 0',
        textAlign:'center',
        // overflow:'auto',
        // whiteSpace:'nowrap'
     fontSize: '14px',textAlign:'center',overflow:'auto',whiteSpace:'nowrap',
     WebkitOverflowScrolling:'none'
    },

    squareIcon: {
        opacity: 0.5,
        fontSize:'20px'
    },
    addIcon: {
        opacity: 0.9,
        color: '#1b6cfc',
        border: '1px solid',
        padding: '5px',
        borderRadius: '20px',
        borderColor: 'lightgray',
        boxShadow: '1px 2px 3px lightgray',
        fontSize:'30px'
    },
    student: {
        // fontWeight: 'bold',
        fontSize:'14px',
        // marginLeft: '3%',
        // width: '300px',
        marginTop:'15px'
    },
    addContact: {
        display: 'flex',
        alignItems: 'center',
        fontSize:'14px'
    }
}))