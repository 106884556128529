import { useState } from "react"
import { Alert } from "@mui/material"
import { getISOByParam, getParamByISO } from "iso-country-currency"

export function handleError(err) {
    console.log(err)
  
    if (err.response) {
        Object.keys(err.response.data).forEach((e) => {
            // message.error(`${e}:${err.response.data[e]}`)
            <Alert severity="error">`${e}:${err.response.data[e]}`</Alert>

        })
    }
  
}


export const api = "https://erp.bscglobaledu.com/mapi/JsonApi/"
export function barColor(value){
    if(value>=75){
        return 'success'
    }else if(value<75){
        return 'warning'
    }
}

  export const imageExists=(image_url)=>{

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    // http.send();

    return http.status !== 404? image_url: `${process.env.PUBLIC_URL}/img/university-default.png`;
}

export function getCurrencySymbol(country){
    if(country.includes('|##|')){
        return
    }
    if(country){
        if(country === 'USA' || country === 'UK' || country === 'UAE' || country === 'Netherland'){
            if (country === 'USA'){
                return '$'
            }else if (country === 'UK'){
                return '£'
            }else if (country === 'Netherland'){
                return 'ƒ'
            }else{
                return 'د.إ'
            }
        }else{
            try{
            const countryCode =getISOByParam('countryName',country)
            const symbol=getParamByISO(countryCode,'symbol')
            return symbol
            }
            catch(err){
                console.error(err)
                
            }
            
        }
    }else{
        return ''
    }
}

export const OPERATION = "operation";
export const operationValue = {
    SIGNIN: "signin",
    SIGNUP: "checkUserExistance",
    ForgotPassword:"forgotPassword",
    ResendOtp:"resendOtp",
    FetchProfile:"fetchProfile",
    Fetch_profile_details:"fetch_profile_details",
    UpdateRecord:"updateRecord",
    Invite_AndRegisterUser:"Invite_AndRegisterUser",
    SearchProgram:"fetchProgramPicklist",
    ProfileEvaluationDetails:"profileEvaluationDetails",
    Applicationstatus:"applicationstatus",
    Favorites:"favorites",
    Bookmark:"bookmark",
    Yettoconfirmaction:"yettoconfirmaction",
    FetchScholarshipResult:"fetchScholarshipResult",
    FetchSOPResult:"fetchSOPResult",
    FetchProgramUniversityResult:"fetchProgramUniversityResult",
    FetchUniversity:"fetchUniversity",
    FetchVendor:"fetchVendor",
    Changepassword:"changepassword",
    FetchSOPwithoutUniversity:"fetchSOPwithoutUniversity", 
    linkVendor:"linkVendor", 
    // CONTACTS: "Contacts",


}

export const CONTACTS = "Contacts";

export const SortListData =[
    {
        // label:'University',
        label: 'profileEvaluationDetails',
        list:[
            [
                {
                    label:'GPA %',
                    value:[
                        {
                            label:'GPA % : Highest',
                            value:'ORDER BY cast(gpa as decimal(13,2)) DESC'
                        },
                        {
                            label:'GPA % : Lowest',
                            value:'ORDER BY cast(gpa as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'English %',
                    value:[
                        {
                            label:'English % : Highest',
                            value:'ORDER BY cast(english_percentage_12th as decimal(13,2)) DESC'
                        },
                        {
                            label:'English % : Lowest',
                            value:'ORDER BY cast(english_percentage_12th as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'University Rank %',
                    value:[
                        {
                            label:'University Rank % : Highest',
                            value:'ORDER BY cast(`uni_rank` as decimal(13,2)) DESC'
                        },
                        {
                            label:'University Rank % : Lowest',
                            value:'ORDER BY cast(`uni_rank` as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'Acceptance %',
                    value:[
                        {
                            label:'Acceptance % : Highest',
                            value:'ORDER BY cast(`acceptance_rate` as decimal(13,2)) DESC'
                        },
                        {
                            label:'Acceptance % : Lowest',
                            value:'ORDER BY cast(`acceptance_rate` as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'Deadline ',
                    value:[
                        {
                            label:'Deadline : Highest',
                            value:'ORDER BY cast(`uni_rank` as decimal(13,2)) DESC'
                        },
                        {
                            label:'Deadlin : Lowest',
                            value:'ORDER BY cast(`uni_rank` as decimal(13,2)) ASC'
                        }
                    ]
                },
                // {
                //     'label':'Tution Fee',
                //     'value':'ORDER BY cast(international_tuition_fee_yr as decimal(13,2)) ASC'
                // },
                // {
                //     'label':'Recommended',
                //     'value':'ORDER BY cast(commission_amount as decimal(13,2)) DESC'
                // },
                // {
                //     'label':'Acceptance Rate',
                //     'value':'Order by cast(`acceptance_rate` as decimal(13,2))'
                // },
                // {
                //     'label':'Deadline',
                //     'value':'ORDER BY diff asc' 
                // },
                // {
                //     'label':'Ranking',
                //     'value':'Order by cast(`uni_rank` as decimal(13,2))'
                // }
            ],
            [
                {
                    label:'App Fee',
                    value:[
                        {
                            label:'App Fee : Highest',
                            value:'ORDER BY cast(gpa as decimal(13,2)) DESC'
                        },
                        {
                            label:'App Fee : Lowest',
                            value:'ORDER BY cast(gpa as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'Tution Fee',
                    value:[
                        {
                            label:'Tution Fee : Highest',
                            value:'ORDER BY cast(international_tuition_fee_yr as decimal(13,2)) DESC'
                        },
                        {
                            label:'Tution Fee : Lowest',
                            value:'ORDER BY cast(international_tuition_fee_yr as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'Commision',
                    value:[
                        {
                            label:'Commission : Highest',
                            value:'ORDER BY cast(commission_amount as decimal(13,2)) DESC'
                        },
                        {
                            label:'Commision : Lowest',
                            value:'ORDER BY cast(commission_amount as decimal(13,2)) ASC'
                        }
                    ]
                },
            ],
            [
                {
                    label:'GRE',
                    value:[
                        {
                            label:'GRE : Highest',
                            value:'ORDER BY cast(gre as decimal(13,2)) DESC'
                        },
                        {
                            label:'GRE : Lowest',
                            value:'ORDER BY cast(gre as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'GMAT',
                    value:[
                        {
                            label:'Gmat : Highest',
                            value:'ORDER BY cast(Gmat as decimal(13,2)) DESC'
                        },
                        {
                            label:'Gmat : Lowest',
                            value:'ORDER BY cast(Gmat as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'IELTS',
                    value:[
                        {
                            label:'IELTS : Highest',
                            value:'ORDER BY cast(IELTS as decimal(13,2)) DESC'
                        },
                        {
                            label:'IELTS : Lowest',
                            value:'ORDER BY cast(IELTS as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'TOEFL',
                    value:[
                        {
                            label:'TOEFL : Highest',
                            value:'ORDER BY cast(TOEFL as decimal(13,2)) DESC'
                        },
                        {
                            label:'TOEFL : Lowest',
                            value:'ORDER BY cast(TOEFL as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'SAT',
                    value:[
                        {
                            label:'SAT : Highest',
                            value:'ORDER BY cast(SAT as decimal(13,2)) DESC'
                        },
                        {
                            label:'SAT : Lowest',
                            value:'ORDER BY cast(SAT as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'ACT',
                    value:[
                        {
                            label:'ACT : Highest',
                            value:'ORDER BY cast(ACT as decimal(13,2)) DESC'
                        },
                        {
                            label:'ACT : Lowest',
                            value:'ORDER BY cast(ACT as decimal(13,2)) ASC'
                        }
                    ]
                },
                {
                    label:'Duolingo',
                    value:[
                        {
                            label:'Duolingo : Highest',
                            value:'ORDER BY cast(Duolingo as decimal(13,2)) DESC'
                        },
                        {
                            label:'Duolingo : Lowest',
                            value:'ORDER BY cast(Duolingo as decimal(13,2)) ASC'
                        }
                    ]
                },

            ]
        ]
    },
    {
        label: 'Scholarship',
        list:[
            {
                'label':'All',
                'value':''
               
            },
            {
                'label':'Deadline',
                'value':'Deadline'
               
            },
            {
                'label':'Scholarship Amount',
                'value':'Scholarship Amount'
               
            },
            {
                'label':'Success Rate',
                'value':'Success Rate'
               
            },
        ]
    },
    {
        label:'Possible Options',
        list:[
            {
                'label':'All',
                'value':''
               
            },
            {
                'label':'Dream',
                'value':'Dream'
               
            },
            {
                'label':'Moderate',
                'value':'Moderate'
               
            },
        ]
    },
    {
        label:'',
        list:[
            {
                'label':'All',
                'value':''
               
            },
            {
                'label':'Dream',
                'value':'Dream'
               
            },
            {
                'label':'Moderate',
                'value':'Moderate'
               
            },
            {
                'label':'Reach',
                'value':'Reach'
               
            },
            {
                'label':'Safe',
                'value':'Safe'
               
            },
            {
                'label':'Top Rank',
                'value':'Top Rank'
               
            },
            {
                'label':'Low Fee',
                'value':'Low Fee'
               
            },
            {
                'label':'Recommend',
                'value':'Recommend'
               
            },
            {
                'label':'Fee Waiver',
                'value':'Fee Waiver'
               
            },
            {
                'label':'STEM',
                'value':'STEM'
               
            },
        ]
    }
]