import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { getSessionId, getUserId, SESSION_ID, USER_ID } from '../../../Controller/localStorageHandler';
import { OPERATION, operationValue } from '../../../Controller/Global';
import { Formik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash'
const Header = () => {
    const [universityList, setUniversityList] = React.useState('');

    const classes = useStyles();
    useEffect(() => {
        const university = new FormData()
        university.append(OPERATION, operationValue.FetchUniversity)
        university.append(USER_ID, getUserId())
        university.append(SESSION_ID, getSessionId())
        university.append('search', 'UNIVER')
        university.append('degreeType', "Masters")
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", university)
            .then((response) => {
                setUniversityList(response.data.data)
            })
    }, []);
    return (
        <div>

            <div style={{ display: 'flex', alignItems: 'center' }}>

                {/* <main className={classes.inputContainer} style={{ width: "100%" }}>
                    <SearchIcon sx={{ opacity: 0.5 }} />
                    <input style={{ width: "100%" }} className={classes.input} autoFocus placeholder='Search Events...' />
                </main> */}
                {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={() => _.map(universityList, (d) => {
                        return { label: d, value: d }
                    })}
                    sx={{ width: 300 }}
                    renderInput={(params) => <input {...params} style={{ width: "100%" }} className={classes.input} autoFocus placeholder='Search Events...' />}
                /> */}
                <div style={{ position: "relative", width: "100%" }}>
                    <Autocomplete
                        style={{ width: "100%" }}
                        name='country'
                        freeSolo
                        multiple
                        // value={countryValue}
                        // onChange={(e, val) => setcountryValue(val)}
                        options={universityList && universityList.map((option) => option)}
                        renderInput={(params) => <main className={classes.inputContainer} style={{ width: "100%" }}>
                            <SearchIcon sx={{ opacity: 0.5 }} style={{ position: "absolute", left: 10 }} /> <TextField  {...params} className={classes.input} label="University Name" />
                        </main>}
                    />
                </div>
                {/* <div >
            <img className={classes.filterIcon} src={`${process.env.PUBLIC_URL}/img/frame.png`} alt='' />
            </div> */}

            </div>
        </div>
    )
}

export default Header

const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        padding: '10px',
        width: '80%',
        height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        boxShadow: '2px 2px 10px #DCDCDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7
    },
    filterIcon: {
        position: 'relative',
        top: 10,
        height: '75px',
        width: '75px',
        //  boxShadow : '2px 2px 10px #DCDCDC','
    }
}))