import React, { useEffect, useRef, useState } from 'react';
import { Box } from "@mui/system";
import {  Grid, Input, Pagination,  Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Calling from '../../img/Calling.png';
import _ from 'lodash'

import { getSessionId, getUser, getUserId, SESSION_ID, USER_ID } from '../../Controller/localStorageHandler';

import { useHistory } from 'react-router-dom'
import FormLabel from '@mui/material/FormLabel';

import { CommonEmailSendAction, fetchApplicationStatusAction2 } from '../Action';
import TopStepper from '../Dashboards/Stepper';

import whatsapp from '../../img/whatsapp.png';
import Message from '../../img/Message.png';

import { AsyncMulti } from '../StudentSearch';

import moment from 'moment';
import { toast } from 'react-toastify';
import Chip from '@mui/material/Chip';



const ApplicationStatus = (props) => {
    const classes = useStyles();
    let ProfileData = getUser()
    const ref = useRef()
    const history = useHistory()
    const [CountData, setCountData] = useState([]);
    const [UserDatas, setUserData] = useState([]);
    const [FetchApplicationData, setFetchApplicationData] = useState([]);
    const [AllFetchApplicationData, AllsetFetchApplicationData] = useState([]);
    const [ApplicationStatus, setApplicationStatus] = useState('All');
    const [open, setOpen] = useState(false);
    const [SearchSelectId, setSearchSelectId] = React.useState(null)
    const [loading, setloading] = React.useState(false)
    const [Limit, setLimit] = React.useState(30)
    const [size, setsize] = React.useState(30)
    const [Start, setStart] = React.useState(0)
    const [page, setPage] = React.useState(1)
    const [contactId, setContactId] = React.useState(ProfileData?.contact_no || '')
    const [SelectedUser, setSelectedUser] = useState(ProfileData)
    const [projectCount,setProjectCount] = useState(0);

    const [statusListFilter,setStatusListFilter] = useState([]);
    const [univerSityList,setUniversityList] = useState([]);
 

    

    const fetchApplicationStatus = (status, contactIdNumber,source) => {
        setloading(true)
        var formdata = new FormData();
        formdata.append(SESSION_ID, getSessionId())
        formdata.append(USER_ID, getUserId())
        formdata.append('confirmstatus', status);
        formdata.append('contact_no', contactId);
        formdata.append('start_page',Start);
        formdata.append('end_page',10);
        if(source){
        formdata.append('source',source);
        }
        if (contactIdNumber) {
            console.log(contactIdNumber)
            formdata.append('contact_id', contactIdNumber);
        }

        fetchApplicationStatusAction2(formdata).then((data) => {
            // fetchUserData({ ...data })
            let grouped_data = _.groupBy(data?.data?.data, 'projectid')
            console.log(data)
            let university_list = _.uniqBy(data?.data?.data, 'university');

            setUniversityList(university_list)
            setProjectCount(_.size(grouped_data))
            setFetchApplicationData(data?.data?.data)
            AllsetFetchApplicationData(data?.data?.data)
            setCountData(data?.data?.data?.length)
            setloading(false)
        }).catch((error) => {
            setloading(false)
            // console.log("error", error)
        })
    }

 

    useEffect(() => {
        if (props?.userId) {
            fetchApplicationStatus('All', props?.userId)
        }
    }, [props?.userId])



    useEffect(() => {
        let status = history?.location?.state?.status || 'All'
        if (!props?.userId) {
            fetchApplicationStatus(status)
     
        }
    }, [history?.location?.state?.status, contactId])

    let user_contact_type = ProfileData?.user_contact_type || ''

    const loadMore = (p) => {
        setLimit(((p*30)-30) + 30)
        setStart((p*30)-30)
        setPage(p)
    }

    const SubmitFilter = (semester, year, status, univercity, country,mentor, filter) => {
        let Datas = AllFetchApplicationData
        // console.log(AllFetchApplicationData)
        if (filter) {
            setCountData(Datas.length || 0)
            setProjectCount(0)
            setFetchApplicationData([...Datas])
        }
        else {
            let ResultData = []
            if (semester.length > 0) {
                ResultData = _.filter((Datas), (d) => semester.includes(d.semester))
            }

            if (year.length > 0) {
                if (semester.length > 0) {
                    ResultData = _.filter((ResultData), (d) => year.includes(d.year))
                    // console.log("ResultData", ResultData)
                } else {
                    ResultData = _.filter((Datas), (d) => year.includes(d.year))
                }
            }

            if (status.length > 0) {
                if (semester.length > 0 || year.length > 0) {
                    ResultData = _.filter((ResultData), (d) => status.includes(d.projecttaskstatus))
                } else {
                    ResultData = _.filter((Datas), (d) => status.includes(d.projecttaskstatus))
                }
            }

            if (univercity.length > 0) {
                
                if (semester.length > 0 || year.length > 0 || status.length > 0) {
                    ResultData = _.filter((ResultData), (d) => univercity.includes(d.university))
                } else {
                    ResultData = _.filter((Datas), (d) => univercity.includes(d.university))
                    // console.log(ResultData)
                }
            }
            if (country.length > 0) {
                if (semester.length > 0 || year.length > 0 || status.length > 0 || univercity.length > 0) {
                    ResultData = _.filter((ResultData), (d) => country.includes(d.country))
                } else {
                    ResultData = _.filter((Datas), (d) => country.includes(d.country))
                }
            }
            if(mentor){
                if (semester.length > 0 || year.length > 0 || status.length > 0 || univercity.length > 0){
                    ResultData = _.filter((ResultData), (d) => {return d.mentorId === mentor})
                }
                else{
                    ResultData = _.filter((Datas), (d) => {return d.mentorId === mentor})
                }
                
            }
            var neeList = []
            var nameList = []
            ResultData.map((d)=>{
                neeList.push(d?.phone);
                nameList.push(d?.FullName);
            })
            console.log(neeList)
            console.log(nameList)
            setCountData(ResultData.length || 0)
            setProjectCount(0)
            setFetchApplicationData([...ResultData])
        }

    }

    const GetDataByFilterDropdown = (DataId) => {
        fetchApplicationStatus('All', DataId,'search')
        setProjectCount(0)
  
    }

    const GetUniverCityProgramStatusRemarData = (GetApplicationData) => {
        let String = ''
        _.map(GetApplicationData, (val, index) => {
            let Date = val?.app_end_date ? val?.app_end_date || null : val?.inter_adm_deadline || null
            String = String + `*${index + 1}) ${val?.scholarship_name ? val?.scholarship_name : val?.university}* 
            %0D%0A ${val?.program ? val?.program : val?.related_programs}             
            %0D%0A *Deadline*: ⏰${Date ? moment(Date).format('ll') : ""}
            %0D%0A *Intake*: ${val?.funding_type ? val?.funding_type : val?.semester} ${val?.year}
           %0D%0A *Status:* ${val?.projecttaskstatus || ''}
           ${val?.app_link ? `%0D%0A Application url : ${encodeURIComponent(val?.app_link)}` : ""} 
           ${val?.online_id ? `%0D%0A Login Id : ${encodeURIComponent(val?.online_id)}` : ""} 
           ${val?.online_pwd ? `%0D%0A Password : ${encodeURIComponent(val?.online_pwd)}` : ""} 
           %0D%0A *Remarks*: ${val.stu_remarks}
            %0D%0A%0D%0A`
        })
        return String
    }

    const GetDocumentRequiredList = (User) => {
        let documents_required = (User?.documents_required || '')?.split("|##|")
        let String = ''
        if (documents_required?.length > 0) {
            _.map((documents_required), (d, index) => {
                String = String + `${index + 1})${d}%0D%0A`
            })
            return String
        }
        return String
    }

    const getString = (Data) => {
        let GetApplicationData = _.filter(AllFetchApplicationData, (d) => d.FullName === Data.FullName)
        // console.log(GetApplicationData)
        let ApplicationStatusText = (GetUniverCityProgramStatusRemarData(GetApplicationData))
        const UserData = getUser()
        // console.log(UserData)
        const documents_required = GetDocumentRequiredList(Data)

        let TempData = `Dear ${Data.FullName}  
        %0D%0A%0D%0AGreetings from *${UserData.account_accountname}*
        %0D%0A%0D%0APlease find the Status of your Applications. 
        %0D%0A%0D%0AExplore 🏫 *60000*+  Programs and Universities, 10000+  Scholarship, 5000+  SOP and LOR Samples. 
        %0D%0A%0D%0AAndroid: https://bit.ly/FreeStudyAbroad
        %0D%0A%0D%0AiOS: https://apple.co/33ujR4Q 
        %0D%0A%0D%0A*Remarks* : ${Data.project_stu_remarks || ''}
        %0D%0A%0D%0APlease find the email and password which we have used for your University Applications 
        %0D%0A*Email* : ${Data.bsc_mail_id || ''}
        %0D%0A*Password* : ${Data.bsc_pwd || ''}
        %0D%0A%0D%0A${ApplicationStatusText} ${documents_required ? `*Document Required*📑 %0D%0A ${documents_required}` : ''} 
        %0D%0A%0D%0A *Upload* : https://youtu.be/ix173Ac0hQA  
        %0D%0A%0D%0A *Mentor*:${UserData?.firstname || ''} ${UserData?.lastname || ''} 
        ${UserData?.assigned_phone_mobile ? `%0D%0A${UserData?.assigned_phone_mobile}` : ''}
        ${UserData?.assigned_phone_work ? `%0D%0A${UserData?.assigned_phone_work}` : ''}
        ${UserData?.assistantphone ? `%0D%0A${UserData?.assistantphone}` : ''}
        ${UserData?.assigned_email1 ? `%0D%0A${UserData?.assigned_email1}` : ''}
        ${UserData?.reports_to_email1 ? `%0D%0A${UserData?.reports_to_email1}` : ''}
        ${UserData?.account_website ? `%0D%0A${UserData?.account_website}` : ''}  

        %0D%0A%0D%0A*Escalation*:Please mail to anoop@bscglobaledu.com regarding any escalation related to our services
        %0D%0A%0D%0A*BRAINSTORM CONSULTING*
        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
        %0D%0A iOS: https://apple.co/33ujR4Q             
        `
        return `https://api.whatsapp.com/send?phone=${Data?.phone || ''}&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")}`
    }

    const getEmailString = (contactid) => {
        var data = new FormData();
        data.append('type', 'app_sts_mail');
        data.append('contactid', contactid || getUserId());

        CommonEmailSendAction(data).then((data) => {
            toast.success(`email send Successfully`)
        }).catch((error) => {
            toast.error(`${error.message}`)
        })
    }
            
 
    let Temp = []
    if (loading) {
        return (<div className="gooey">
            <span className="dot"></span>
            <div className="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        )
    } else {
        return (
            <React.Fragment>
                <div style={{ position: "relative" }}>
                    {history.location.pathname !== "/Profile" &&
                        user_contact_type !== "Student" && <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <AsyncMulti hideFilter="No" inputData={SelectedUser} setSearchSelectId={(data) => GetDataByFilterDropdown(data)} SubmitFilter={SubmitFilter} univerSityList={univerSityList} />
                            {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" value={searchValue} onChange={(e)=>setSearchValue(e.target.value)} /> */}

                        </div>}
                    <Box className={classes.root} sx={{ bgcolor: '#fafafa' }}   >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                            <Grid item xs={9} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between" }}>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                    }}>
                                    <Chip label="Applications" color="success" />
                                    <Chip sx={{ marginLeft: 1}} size="small" label={CountData || 0} color="error" />
                                    
                                    
                                    <br/>
                                    {props?.userId ? '' : projectCount ? <><Chip label="Projects" color="warning" size="small" /><Chip sx={{ marginLeft: 1}} size="small" label={projectCount || 0} color="error" /> </> :''}
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid
                            xs={12}
                            item
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                        </Grid>
                        <Grid container spacing={2}>
                            {FetchApplicationData?.length > 0 && (FetchApplicationData).slice(Start, Limit)?.map((d,i) => {
                                // console.log(d)
                                let Name
                                let Count
                                if (!Temp.includes(d.FullName)) {
                                    Name = d.FullName
                                    Temp.push(d.FullName)
                                    Count = _.filter(FetchApplicationData, (d1) => d1.FullName === d.FullName).length
                                }
                                return <>
                                    {Name && <Grid item xs={12} sx={{ paddingTop: 1, display: "flex", alignItems: "center", marginTop: 2 }} container key={i}>
                                            <Grid item xs={8} sx={{ width: "55%", display: "flex", justifyContent: "left", alignItems: "left" }} onClick={() => {
                                                // let StudentData = _.find(UserDatas, (d1) => d1.email === d.email)
                                                history.push({ pathname: '/Profile', state: { StudentId: d?.linktoaccountscontacts, tab: 'Applications' } })
                                            }}>
                                                <p style={{ color: "#1A1A1A", fontSize: 20, fontWeight: "bold", color: "blue", cursor: "pointer" }} ><Chip label={Name} size="small" color="primary" />&nbsp;<Chip label={Count} size="small" color="error" /></p>
                                            </Grid>
                                            <Grid xs={4} item sx={{ paddingLeft: 2, display: "flex", justifyContent: "space-between", width: "45%" }}>
                                                {/* <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: 30,
                                                    height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                                }}> */}

                                                <span
                                                    onClick={() => getEmailString(d.contactid)}
                                                >
                                                    <img src={`${Message} `} style={{ height: 30, width: 30 }} />
                                                </span>
                                                {/* </div> */}

                                                {/* <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: 30,
                                                    height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                                }}> */}
                                                <a
                                                    href={getString(d)}
                                                    target="_blank"
                                                >
                                                    <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                                                </a >
                                                {/* </div > */}
                                                {/* <div
                                                   
                                                    style={{
                                                        marginRight: 16,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: 30,
                                                        height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                                    }}> */}
                                                <img onClick={() => {
                                                    window.open(`tel:${d?.phone || ''}`);
                                                }} src={`${Calling}`} style={{ height: 30, width: 30 }} />
                                                {/* </div> */}
                                            </Grid >
                                        </Grid >
                                    }  
                 
                                        <Grid item lg={4}>
                                    <Box
                                        sx={{
                                            boxShadow: 3,
                                            backgroundColor: "#fff",
                                            padding: "10px 0px",
                                            borderRadius: "10px",
                                            width: "100%",
                                            mt: 3,
                                            boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 0.2), 0px 3px 4px 0px rgba(0 0 0 / 0.4), 0px 1px 8px 0px rgba(0 0 0 / 0.2)"
                                        }}
                                    >
                                        <TopStepper
                                            ApplicationStatus={d?.projecttaskstatus || ''}
                                            ApplicationFee={d?.international_app_fee || ''}
                                            TYPE="STATUS"
                                            val={d}
                                            setOpen={setOpen}
                                            open={open}
                                        />

                                        <div style={{ paddingBottom: 20, paddingLeft: 20, paddingRight: 20, paddingTop: 20, borderTopRightRadius: 25, borderTopLeftRadius: 25 }}>


                                            <FormControl fullWidth >
                                                <FormLabel style={{ paddingTop: 2 }}>Application URL :</FormLabel>
                                                <Input defaultValue={d?.app_link || ''} style={{ marginTop: 0 }} />
                                            </FormControl>
                                            <FormControl fullWidth >
                                                <FormLabel style={{ paddingTop: 2 }}>Login ID:</FormLabel>
                                                <Input defaultValue={d?.online_id || ''} style={{ marginTop: 0 }} />
                                            </FormControl>
                                            <FormControl fullWidth >
                                                <FormLabel style={{ paddingTop: 2 }}>Password :</FormLabel>
                                                <Input defaultValue={d?.online_pwd || ''} style={{ marginTop: 0 }} />
                                            </FormControl>

                                        </div>
                                    </Box>
                                    </Grid>
                        
                                </>
                            })
                            }
                             </Grid >
                            {
                                !loading && <Box  
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                 style={{ marginTop: 20 }}>
                                    
                                    <Pagination
                                        defaultPage={1}
                                        page={page}
                                        count={Math.ceil(FetchApplicationData.length / 30)}
                                        color="primary"
                                        onChange={(e, p) => loadMore(p)}
                                    />
                                </Box>
                            }
                       
                    </Box >
                </div >
            </React.Fragment >
        )
    };
}

export default ApplicationStatus;
const useStyles = styled(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));