import React from "react";
import { Grid, Typography, Button, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { withRouter } from 'react-router-dom';

const Gre = (props) => {
    const classes = useStyles()
    return (

        /* university.jfif issue */
        <div style={{ padding: "0 5px" }}>
            <Grid
                onClick={() => props.history.replace({
                    pathname: (props.url ? `${props.url}` : '/SearchUniversity'),
                    state: { move: props.move, option: props.option, extraoption: props.extraoption, heading: props.heading }
                })}
                sx={{
                    width: "100%",
                    margin: 0,
                    justifyContent: "space-around",
                    // backgroundImage: props.slideImage,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "15px",
                    boxShadow: "5px 5px 1px #dcdcdc",
                    display: "flex"
                }}
            >

                <div className={classes.CardDesign} style={{ width: "50%", height: 100, padding: 2 }}>
                    <div style={{ backgroundImage: props.slideImage, width: "100%", height: "100%", backgroundSize: "cover", borderRadius: "13px" }} />

                    {/* <img src={props.slideImage} style={{ width: "100%", height: "100%" }} /> */}
                </div>
                <div className={classes.CardDesign} style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <Typography sx={{ fontWeight: "700", fontSize: 12, color: "black" }}>{`${props.heading} `}
                        {' '}
                        {
                            props.count === 0 || props.count === ''
                            ?
                            ''
                            :
                            <Chip variant="filled" color="error" size="small" label={props.count} />
                        }
                        {/* <span
                        style={{
                            color: "#1976d2",
                            fontWeight: "800",
                            backgroundColor: "#fff",
                            padding: "1px 8px",
                            // borderRadius: "50px",
                            fontSize: "10px",
                            marginLeft: "2px"
                        }}>{props.count}</span> */}
                    </Typography>
                    {/* <Typography
                        style={{
                            width: 110,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}
                        sx={{ mt: "5px", color: "black", fontSize: "11px", width: "230px" }}>{props.text}</Typography> */}
                    {/* <Button variant="text" disableElevation sx={{
                        backgroundColor: "#fff",
                        mt: "5px",
                        padding: "2px 8px",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "#1b6cfc",
                        border: " 1px solid #DCE8FF",
                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                        // borderRadius: "30px",
                        lineHeight: "15px"
                    }} onClick={() => history.push({ 
                        pathname:(props.url ? `${props.url}`: '/SearchUniversity'), 
                        state: { move: props.move, option: props.option, extraoption: props.extraoption,heading: props.heading} })}>More</Button> */}
                </div>
            </Grid>
        </div>
    );
};
export default withRouter(Gre);

const useStyles = makeStyles(() => ({
    CardDesign: {
        // background: linear-gradient("269.38deg, rgba(255, 255, 255, 0) 29.03%, rgba(43, 43, 43, 0.65) 69.97%"),
        // background: "linear-gradient(269.38deg, rgba(255, 255, 255, 0) 29.03%, rgba(43, 43, 43, 0.65) 69.97%)",
        // borderRadius: "15px",
        padding: "10px"
    }
}))