import React, { useState, useEffect } from 'react'


import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid"

import { OPERATION, operationValue } from "../../../Controller/Global";
import axios from "axios";

import { SESSION_ID, setSessionId, setUser, setUserId, USER_ID, setUserType, getUserType, getFCM_Token } from "../../../Controller/localStorageHandler";

import { useHistory } from "react-router-dom";
import useAppContext from "../../../useContext/userContext"
import PhoneInput from "react-phone-input-2";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import OtpInput from 'react18-input-otp';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import { champianInsertAction } from '../../Action';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CommonLogin = ({ openLogin, handleClose, return_url, campaignId, callBack }) => {
    const history = useHistory()
    // const return_url = history?.location?.state?.return_url
    const { setSessionInvalid, getEventList  } = useAppContext()
    let [values, handleValues] = useState({});
    let [validate, handleValidate] = useState(false);
    let [error, setError] = useState('');
    let [success, setSuccess] = useState('');
    let [portalErr, setPortalErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [name, setName] = useState('')
    const [customer_id, setCustomer_id] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const [contact_no, setContact_no] = useState('')

    const [showOtpBox, setShowOtpBox] = useState(false);

    const [otp, setOtp] = useState('');
    const [authType, setAuthType] = useState('')
    const [resend, setResend] = useState(false)


    const getOtp = async () => {
        setResend(false)
        setLoading(true)
        if (contact_no?.length > 10) {
            const formData = new FormData()

            formData.append('operation', 'sign_in_otp');
            formData.append('phone_mobile', contact_no);


            await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
                .then(response => {
                    setLoading(false)
                    if (response?.data?.status === 'success') {
                        setAuthType(response?.data?.type) //login, signup
                        setSuccess('OTP has been sent to ' + contact_no + ' kindly check Whatsapp Or Text sms app')
                        setShowOtpBox(true)
                        resendOtp()
                    }
                })
        }
        else {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Kindly Enter Valid Phone Number!',
            })
        }
    }

    const verifyOtp = async () => {
        setLoading(true)
        if (otp?.length > 3) {
            // if type is login 
            if (authType === 'login') {

                setError('')
                setSuccess('')
                const formData = new FormData()

                formData.append('operation', 'signin_with_mobile');
                formData.append('phone_mobile', contact_no);
                formData.append('otp', otp)

                await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
                    .then(response => {

                        if (response?.data?.portal?.portal === '0') {
                            setEmail(response?.data?.portal?.email);
                            setCustomer_id(response?.data?.portal?.customerid);
                            setPortalErr('Your account is deactivated.. would you like to activate..')
                            return
                        }
                        if (response.data.status === 'success') {
                            setSuccess(response.data.msg)
                            setSessionId(response.data.sessionid)
                            setUserId(response.data.id)
                            const profile = new FormData()
                            profile.append(OPERATION, operationValue.FetchProfile)
                            profile.append(SESSION_ID, response.data.sessionid)
                            profile.append(USER_ID, response.data.id)
                            axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', profile).then((res) => {


                                if (res.statusText === "OK") {
                                    let UserData = res?.data
                                    setUser(res.data)

                                    let latitudeAndlongitude = JSON.parse(localStorage.getItem('loaction') || `{}`)
                                    axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitudeAndlongitude?.latitude}&longitude=${latitudeAndlongitude?.longitude}&localityLanguage=en`).then((Data) => {

                                        let NewData = Data?.data?.localityInfo?.administrative || ''
                                        if (NewData.length > 3 && UserData) {

                                            UserData.mailingcity = NewData[2].name
                                            UserData.mailingstate = NewData[1].name
                                            UserData.mailingcountry = NewData[0].name
                                            UserData.id = response.data.id
                                            UserData.latitude = Data?.data?.latitude
                                            UserData.longitude = Data?.data?.longitude
                                            if (getFCM_Token()) {
                                                UserData.fcm_token = getFCM_Token()
                                            }

                                            let config = {
                                                url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
                                                data: JSON.stringify(UserData),
                                                method: 'post'
                                            }
                                            axios(config).then((res) => {

                                                setUser(UserData)

                                            }).catch((error) => {

                                            })
                                        }
                                    }).catch((err) => {

                                    })

                                    if(campaignId){
                                        joinEvent(response.data.id,response.data.sessionid)
                                    }

                                    let user_contact_type = res.data?.user_contact_type || null
                                    setUserType(user_contact_type)

                                    if(return_url === 'callback'){
                                        callBack()
                                        handleClose(false)
                                        return
                                    }
                                    if (return_url) {
                                        history.push(return_url)
                                    }
                                    else if (user_contact_type === "Accommodation") {
                                        history.push('/accommodationDashboard')
                                    }
                                    else if (user_contact_type === "University") {
                                        history.push('/universityDashboard')
                                    }
                                    else if (user_contact_type === "Partner") {
                                        history.push('/educationDashboard')
                                    }
                                    else if (user_contact_type === "Internship/Job") {
                                        history.push('/internshipDashboard')
                                    }
                                    else if (user_contact_type === "Employee" || user_contact_type === "Associate" || user_contact_type === "Associate Admin") {
                                        history.push('/associates')
                                    } else {
                                        history.push('/dashboard')
                                    }
                                    setLoading(false)
                                    handleClose(false)
                                } else {
                                    setLoading(false)
                                    setSuccess('')
                                    setError(res.msg)
                                }
                            })
                        }
                        else {
                            setLoading(false)
                            setSuccess('')
                            setError(response.data.msg)
                        }
                    })
            }
            else if (authType === 'signup') {
                if (email === '') {
                    setLoading(false)
                    setEmailErr(true);
                    setSuccess('')
                    setError('Kindly add your Email address')
                }
                else {
                    setError('')
                    setSuccess('')
                    const formData = new FormData()

                    formData.append('operation', 'confirmOTP_AndRegisterUser');
                    formData.append('phone_mobile', contact_no);
                    formData.append('email_address', email);
                    formData.append('username', name || 'student')
                    formData.append('otp', otp)

                    await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
                        .then(response => {
                            console.log(response)
                            if (response?.data?.portal?.portal === '0') {
                                setEmail(response?.data?.portal?.email);
                                setCustomer_id(response?.data?.portal?.customerid);
                                setPortalErr('Your account is deactivated.. would you like to activate..')
                                return
                            }
                            if (response.data.status === 'success') {
                                setSuccess(response.data.msg)
                                setSessionId(response.data.sessionid)
                                setUserId(response.data.id)
                                const profile = new FormData()
                                profile.append(OPERATION, operationValue.FetchProfile)
                                profile.append(SESSION_ID, response.data.sessionid)
                                profile.append(USER_ID, response.data.id)
                                axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', profile).then((res) => {


                                    if (res.statusText === "OK") {
                                        let UserData = res?.data
                                        setUser(res.data)

                                        let latitudeAndlongitude = JSON.parse(localStorage.getItem('loaction') || `{}`)
                                        axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitudeAndlongitude?.latitude}&longitude=${latitudeAndlongitude?.longitude}&localityLanguage=en`).then((Data) => {

                                            let NewData = Data?.data?.localityInfo?.administrative || ''
                                            if (NewData.length > 3 && UserData) {

                                                UserData.mailingcity = NewData[2].name
                                                UserData.mailingstate = NewData[1].name
                                                UserData.mailingcountry = NewData[0].name
                                                UserData.id = response.data.id
                                                UserData.latitude = Data?.data?.latitude
                                                UserData.longitude = Data?.data?.longitude
                                                if (getFCM_Token()) {
                                                    UserData.fcm_token = getFCM_Token()
                                                }

                                                let config = {
                                                    url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
                                                    data: JSON.stringify(UserData),
                                                    method: 'post'
                                                }
                                                axios(config).then((res) => {

                                                    setUser(UserData)

                                                }).catch((error) => {

                                                })
                                            }
                                        }).catch((err) => {

                                        })

                                        if(campaignId){
                                            joinEvent(response.data.id,response.data.sessionid)
                                        }

                                        let user_contact_type = res.data?.user_contact_type || null
                                        setUserType(user_contact_type)
                                        
                                        if(return_url === 'callback'){
                                            callBack()
                                            handleClose(false)
                                            return
                                        }
                                        if (return_url) {
                                            history.replace(return_url)
                                        }
                                        else if (user_contact_type === "Accommodation") {
                                            history.push('/accommodationDashboard')
                                        }
                                        else if (user_contact_type === "University") {
                                            history.push('/universityDashboard')
                                        }
                                        else if (user_contact_type === "Partner") {
                                            history.push('/educationDashboard')
                                        }
                                        else if (user_contact_type === "Internship/Job") {
                                            history.push('/internshipDashboard')
                                        }
                                        else if (user_contact_type === "Employee" || user_contact_type === "Associate" || user_contact_type === "Associate Admin") {
                                            history.push('/associates')
                                        } else {
                                            history.push('/dashboard')
                                        }
                                        setLoading(false)
                                        handleClose(false)
                                    } else {
                                        setLoading(false)
                                        setSuccess('')
                                        setError(res.msg)
                                    }
                                })
                            }
                            else {
                                setLoading(false)
                                setSuccess('')
                                setError(response.data.msg)
                            }
                        })

                }
                console.log(authType)
            }
        }
        else {
            setSuccess('')
            setError('OTP should be 4 digit')
            setLoading(false)
        }

    }


    const joinEvent = (userId,sessionid) =>{
                var formData = new FormData();
                formData.append('campaignid', campaignId);
                formData.append('contactid', userId);
                formData.append("userid", userId)
                formData.append("sessionid", sessionid)
                formData.append('validation','bypass')
                champianInsertAction(formData).then(res => {
                    if (res?.msg === "Success") {
                        getEventList(true)
                    } else {
                    }
                }).catch((erro) => {
            
                })
        
            }

    const resendOtp = () =>{
        setTimeout(
            () => setResend(true),
            60000
        ); 
    }

    return (
        <Dialog
            open={openLogin}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            style={{zIndex:'2000'}}
        >
            {
                !loading ?
                    showOtpBox
                        ?
                        <DialogTitle>{`Enter OTP`}</DialogTitle>
                        :
                        <DialogTitle>{"Your Mobile number."}</DialogTitle>
                    :
                    ''
            }

            {
                loading ?
                    <Box sx={{ display: 'flex', m: 5 }}>
                        <CircularProgress />
                    </Box>
                    :
                    <DialogContent>
                        {error && <Alert severity="error" sx={{ mb: 1 }}>{error}</Alert>}
                        {success && <Alert severity="success" sx={{ mb: 1 }}>{success}</Alert>}

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px 20px",
                                    margin: "16px 0",
                                }}
                            >
                        {
                            showOtpBox ?
                                <OtpInput inputProps={{ type: 'number' }} inputStyle={{ margin: 'auto', textAlign: 'center', padding: '5px', width: '50%', borderRadius: '5px' }} value={otp} onChange={(enteredOtp) => setOtp(enteredOtp)} numInputs={4} />
                                : <PhoneInput
                                    country={'in'}
                                    name='phone_mobile'
                                    placeholder="Enter Number"
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: "0",
                                        opacity: "0.8",
                                    }}
                                    // inputProps={{
                                    //     style:{
                                    //     zIndex:'3000'
                                    //     }
                                    // }}
                                    //   value={phone}
                                    searchPlaceholder={'Mobile Number'}
                                    inputClass={"mobile-field"}
                                    onChange={(phone) => {
                                        setContact_no(phone)
                                        //   handleChange()
                                    }}
                                />
                        }
                        </Grid>

                        {
                            showOtpBox && authType === 'signup'
                                ?
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            backgroundColor: "#fff",
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "start",
                                            borderRadius: "30px",
                                            border: "1px solid #EBF2FF",
                                            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                            padding: "10px", margin: "16px 0",
                                        }}
                                    >
                                        <svg width="17" height="22" viewBox="0 0 17 22" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <ellipse cx="8.57881" cy="6.27803" rx="4.77803" ry="4.77803" stroke="#1A1A1A"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M1.00002 17.7014C0.998732 17.3655 1.07385 17.0338 1.2197 16.7312C1.67736 15.8158 2.96798 15.3307 4.03892 15.111C4.81128 14.9462 5.59431 14.8361 6.38217 14.7815C7.84084 14.6534 9.30794 14.6534 10.7666 14.7815C11.5544 14.8367 12.3374 14.9468 13.1099 15.111C14.1808 15.3307 15.4714 15.7701 15.9291 16.7312C16.2224 17.348 16.2224 18.064 15.9291 18.6808C15.4714 19.6419 14.1808 20.0813 13.1099 20.2918C12.3384 20.4635 11.5551 20.5767 10.7666 20.6305C9.57937 20.7311 8.38659 20.7495 7.19681 20.6854C6.92221 20.6854 6.65677 20.6854 6.38217 20.6305C5.59663 20.5773 4.81632 20.4641 4.04807 20.2918C2.96798 20.0813 1.68652 19.6419 1.2197 18.6808C1.0746 18.3747 0.999552 18.0402 1.00002 17.7014Z"
                                                stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>

                                        <Input
                                            // style={{ width: "80%",marginLeft: "10px"}}
                                            type="text"
                                            name="name"
                                            className={classes.input}

                                            placeholder="Name"
                                            // error={nameErr}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            backgroundColor: "#fff",
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "start",
                                            borderRadius: "30px",
                                            border: "1px solid #EBF2FF",
                                            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                            padding: "10px", margin: "16px 0",
                                        }}
                                    >
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.2686 7.06116L12.0031 10.4954C11.1959 11.1283 10.0644 11.1283 9.25714 10.4954L4.95508 7.06116"
                                                stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M5.88787 1.5H15.3158C16.6752 1.51525 17.969 2.08993 18.896 3.0902C19.823 4.09048 20.3022 5.42903 20.222 6.79412V13.322C20.3022 14.6871 19.823 16.0256 18.896 17.0259C17.969 18.0262 16.6752 18.6009 15.3158 18.6161H5.88787C2.96796 18.6161 1 16.2407 1 13.322V6.79412C1 3.87545 2.96796 1.5 5.88787 1.5Z"
                                                stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>

                                        <Input
                                            // style={{ width: "80%",marginLeft: "10px"}}
                                            type="email"
                                            name="email_address"
                                            className={classes.input}

                                            placeholder="user@gmail.com"
                                            error={emailErr}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                </>
                                :
                                ''
                        }


                    </DialogContent>
            }
            {
                loading
                    ?
                    ''
                    :

                    <DialogActions>
                        {
                            showOtpBox ?
                                <>
                                    {resend ? <Button onClick={getOtp}>Resend OTP</Button> : ''}

                                    <Button onClick={verifyOtp}>Verify OTP</Button>
                                </>
                                :
                                <>
                                    <Button onClick={() => handleClose(false)}>Cancel</Button>
                                    <Button onClick={getOtp}>Get OTP</Button>
                                </>
                        }

                    </DialogActions>
            }
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    Sign: {
        color: "#1b6cfc",
        fontSize: "14px",
    },
    input: {
        borderColor: "transparent",
        outline: "none",
        marginLeft: "10px",
        fontSize: "16px",
        width: "80%",
        display: "flex",
        opacity: 0.7,
        letterSpacing: 0.7,
    },
    filterIcon: {
        position: "relative",
        top: 10,
        height: "75px",
        width: "75px",
    },
}));