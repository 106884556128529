import React, { useEffect, useState } from 'react';
import { Box } from "@mui/system";
import { Alert, Chip, Grid, IconButton, LinearProgress, Typography, Autocomplete, TextField, Pagination } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BottomNav from "../Common/BottomNav";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from 'axios';
import { getSessionId, getUser, getUserId, SESSION_ID, USER_ID, getUserType, clearUserData, setSearchSelectedUser, getSearchSelectedUser } from '../../Controller/localStorageHandler';
import { getCurrencySymbol, OPERATION, operationValue } from '../../Controller/Global';
import { useHistory, Redirect } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import ResultCard from '../Result/ResultCard';
import ResultCard1 from '../Result/ResultCard1';
import DownloadModal from '../Result/DownloadModal';
import whatsapp from '../../img/whatsapp.png';
import Message from '../../img/Message.png';
import SearchBoxWithFilter from '../SearchBox/SearchBoxWithFilter';
import _ from 'lodash'
import { AsyncMulti } from '../StudentSearch/index'
import { getStudentCountListUsingStatusAction1 } from '../Action/AssociateDocumentList';
import moment from 'moment'
import { toast } from 'react-toastify';
import { CommonEmailSendAction, fetchMachingUniversitiesAction, fetchPossibleScholarshipsAction, fetchRelaventSopAction } from '../Action';
import University from '../Dashboards/University';
import PopUpModal from '../Result/ApplyModal';
import FavouriteModal from './FavouriteModal';
import BookMarkMessageModal from '../Result/BookMarkMessageModal';
import AddStudentList from '../Result/AddStudentList';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Backdrop from '@mui/material/Backdrop';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import useAppContext from "../../useContext/userContext"
import ProgramModal from '../Result/ProgramModal';
import FilterModal from '../Result/FilterModal';


const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const barColor = (value) => {
    if (value >= 75) {
        return 'success'
    } else if (value < 75) {
        return 'warning'
    }
}

const AssociateUniversityFavourites = (props) => {
    // console.log(props?.location?.state?.tab)
    const { sessionInvalid, user, getUserContext, getFavouriteUniversities, favourite_universities, getParteneredUniversities, partenered_universities, getMatchingUniversities, matching_universities, getApplicationStatus, application_status_student,loading } = useAppContext()

    // console.log(partenered_universities)

    const userData = props?.user || getUser()
    let Data = ["All", "Dream", "Moderate", "Reach", "Safe", "Top Rank", "Low Fee", "Recommend", "Fee Waiver", "STEM", "10+2+3 Accepted", "Duolingo Accepted", "Backlogs Accepted", "IELTS / English Wavier", "Private", "Public"]
    if (userData?.country?.includes('USA') && (userData?.degree_looking_for === "Masters" || userData?.degree_looking_for === "PhD")) {
        Data.push("GRE Wavier", "GMAT Wavier")
    }
    if (userData?.country?.includes('USA') && userData?.degree_looking_for === "Bachelors") {
        Data.push("SAT/ACT Wavier")
    }
    if (userData?.country?.includes('Canada')) {
        Data.push("Co-Op")
    }
    if (userData?.country?.includes('UK') || userData?.country?.includes('France') || userData?.country?.includes('Germany')) {
        Data.push("Placement", "Internship")
    }
    // Data.push("Possible Options")

    if (userData?.degree_looking_for !== "") {
        Data.push("Possible Options")
    }





    const initialValues = {
        'All': true,
        'Dream': false,
        'Moderate': false,
        'Reach': false,
        'Safe': false,
        'Top Rank': false,
        'Low Fee': false,
        'Recommend': false,
        'Fee Waiver': false,
        'STEM': false,
        'Placement': false,
        'Co-Op': false,
        'Internship': false,
        '10+2+3 Accepted': false,
        'Duolingo Accepted': false,
        'Backlogs Accepted': false,
        'SAT/ACT Wavier': false,
        'GRE Wavier': false,
        'GMAT Wavier': false,
        'IELTS / English Wavier': false,
        'Possible Options': false,
        'Private': false,
        'Public': false,
    }

    const [selected, setSelected] = React.useState(initialValues);
    // console.log(props?.user)
    const classes = useStyles();
    const history = useHistory()
    const [openDownload, setOpenDownload] = React.useState(false)
    const [SopData, setSopData] = React.useState('')
    const [Student, setStudent] = React.useState([])
    const [openFav, setOpenFav] = React.useState(false)
    const [bookmarkId, setBookmarkId] = React.useState('')
    const [openApply, setOpenApply] = React.useState(false);
    const [isBookMark, setIsBookMark] = React.useState(false);
    const [applyData, setApplyData] = React.useState('')
    const [MechingUniversitiesData, setMechingUniversitiesData] = useState([]);
    const [matchingUniverCityModal, setMatchingUniverCityModal] = React.useState(false);
    const [PartneredUniversitiesData, setPartneredUniversitiesData] = useState([]);
    const [status, setStatus] = React.useState('')
    const [openMsg, setOpenMsg] = React.useState(false)
    const [bookmarkMsg, setBookmarkMsg] = React.useState('')
    const [PossibleScholarshipsData, setPossibleScholarshipsData] = useState([]);
    const [fetchRelaventSopData, setfetchRelaventSopData] = useState([]);

    const [loader, setLoader] = React.useState(false)
    const [Matchingloader, setMatchingLoader] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    // const response = await axios.post("/");
    // eslint-disable-next-line
    const [value, setValue] = useState('recents');
    const [activeDgree, setActiveDgree] = useState('All')
    const [activeTab, setActiveTab] = useState('Partnered')
    const [moduleType, setModuleType] = useState('Universities')
    const [mainmoduleType, setmainmoduleType] = useState('Universities')
    const [UserId, setUserId] = useState(null)
    const [selectedSearch, setselectedSearch] = useState('')
    const [SelectedStudentList, SetSelectedStudentList] = React.useState([])
    const [AddStudentOpen, setAddStudentOpen] = React.useState(false)

    const [currentUserId, setCurrentUserId] = useState('');
    const [Limit, setLimit] = useState(30)
    const [StartPartner, setStartPartner] = useState(0)
    const [pagePartner, setPagePartner] = useState(1)

    const [StartMatching, setStartMatching] = useState(0)
    const [pageMatching, setPageMatching] = useState(1)

    const [univerSityList, setUniverSityList] = useState([])
    const [allUniverSity, setAllUniverSity] = useState([])
    const [favouriteList, setFavouriteList] = useState([])
    const [backdrop, setBackdrop] = useState(false)
    const [isCollapsed, setCollapsed] = useState(true)

    const [programSelectArr, setProgramSelectArr] = useState([])
    const [selectedProgram, setSelectedProgram] = useState('')

    const [tabIndex, setTabIndex] = useState(0);

    const [open, setOpenProgram] = useState(false)

    const [openFilter, setOpenFilter] = useState(false)

    const [filterKey, setFilterKeys] = useState('')


    const selectedFilter = (data, stat) => {
        setSelected({ ...selected, [data]: stat })
    }

    useEffect(() => {
        // console.log(filterKey)
        if (activeTab === 'Partnered') {
            uniFilter(selected, partenered_universities?.partenered_universities)
        }
        if (activeTab === 'Matching') {
            uniFilter(selected, matching_universities?.matching_universities)
            // fetchMachingUniversities(JSON.stringify(selected), 'Universities',currentUserId)
        }
        if (activeTab === 'Favourite') {
            uniFilter(selected, favourite_universities?.favourite_universities)
            // console.log(favourite_universities?.favourite_universities)
            // fetchFavourite(JSON.stringify(selected), `${moduleType}`,`${currentUserId}`)
        }

        // console.log(JSON.stringify(selected)) 
    }, [selected, selectedProgram, filterKey])


    // eslint-disable-next-line
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [data, setData] = useState([]);
    const setNewData = (newdata) => {
        setData(newdata);
    }


    const loadMorePartner = (p) => {
        setLimit(((p * 30) - 30) + 30)
        setStartPartner((p * 30) - 30)
        setPagePartner(p)
    }

    const loadMoreMatching = (p) => {
        setLimit(((p * 30) - 30) + 30)
        setStartMatching((p * 30) - 30)
        setPageMatching(p)
    }

    useEffect(() => {
        if (getUserType() === 'Student') {
            getFavouriteUniversities(`All`, `${moduleType}`, `${JSON.stringify(getUser())}`)
            getParteneredUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(getUser())}`)
            getMatchingUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(getUser())}`)
        }
        else if (props?.user) {
            getFavouriteUniversities(`All`, `${moduleType}`, `${JSON.stringify(props?.user)}`, true)
            getParteneredUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(props?.user)}`, true)
            getMatchingUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(props?.user)}`, true)
        }
        else {
            // console.log(getSearchSelectedUser())
            if (getSearchSelectedUser() !== null) {
                getFavouriteUniversities(`All`, `${moduleType}`, `${JSON.stringify(getSearchSelectedUser())}`)
                getParteneredUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(getSearchSelectedUser())}`)
                getMatchingUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(getSearchSelectedUser())}`)
            }
        }

        // setFavouriteList()
        //    console.log(favourite_universities)
    }, [Student])

    useEffect(() => {
        let programArr = []
        let newArr = _.groupBy(allUniverSity?.data, item => item?.program)
        Object.entries(newArr).map(([key, value]) => {
            // return (
            //     <div>{key} : {value}</div>
            // );
            programArr.push({ 'program': key, 'Total': value.length })
            // console.log(value.length)
        })
        setProgramSelectArr(programArr)
        // console.log(data)
    }, [allUniverSity])

    useEffect(() => {
        if (getSearchSelectedUser() !== null) {
            setStudent(getSearchSelectedUser())
        }
        if (getUserType() === 'Student') {
            setStudent(getUser())
        }
        if (props?.user !== undefined) {
            setStudent(props?.user)
        }

    }, [props?.user])


    const filterResult = (val) => {
        if (val === null || val === '') {
            setUniverSityList(allUniverSity)
        }
        else {
            var Data = allUniverSity?.data
            const ResultData = _.filter((Data), (d) => val.includes(d.university))
            setUniverSityList(ResultData)
            setData(ResultData)
            // console.log(ResultData)
        }


    }

    const handleFilterList = (values) => {
        // console.log(values)
        setFilterKeys(values)
    }


    const uniFilter = (setVal, list) => {
        let ResultData = list?.data
        // console.log(ResultData)
        if (setVal['Dream'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.acceptance_rate < 25 })
        }
        if (setVal['Moderate'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.acceptance_rate >= 25 && d.acceptance_rate <= 50 })
        }
        if (setVal['Reach'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.acceptance_rate >= 50 && d.acceptance_rate <= 75 })
        }
        if (setVal['Safe'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.acceptance_rate >= 75 && d.acceptance_rate <= 100 })
        }
        if (setVal['Top Rank'] === true) {
            // _.sortBy(myArray, o => o.name)
            ResultData = _.sortBy(ResultData, (d) => parseInt(d.uni_rank, 10))
            ResultData = _.filter((ResultData), (d) => { return d.uni_rank > '0' })
        }
        if (setVal['Low Fee'] === true) {
            ResultData = _.sortBy(ResultData, (d) => parseInt(d.international_tuition_fee_yr, 10))
        }
        if (setVal['Recommend'] === true) {
            ResultData = _.sortBy(ResultData, (d) => parseInt(d.commission_amount, 10)).reverse()
        }
        if (setVal['Fee Waiver'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.app_fee_waiver === '1' })
        }
        if (setVal['STEM'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.uni_stem === '1' })
        }
        if (setVal['Placement'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.uni_placement === '1' })
        }
        if (setVal['Co-Op'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.co_op === '1' })
        }
        if (setVal['Internship'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.internship_in_months > 0 })
        }
        if (setVal['10+2+3 Accepted'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.years_15 === '1' })
        }
        if (setVal['Duolingo Accepted'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.duolingo > 0 })
        }
        if (setVal['Backlogs Accepted'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.num_backlogs > 0 })
        }
        if (setVal['SAT/ACT Wavier'] === true) {
            ResultData = _.filter((ResultData), (d) => { return (d.sat_total === "Not Applicable" || d.sat_total === "Not Required") && (d.act_score === "Not Applicable" || d.act_score === "Not Required") })
        }
        if (setVal['GRE Wavier'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.gre_total === "Not Applicable" || d.gre_total === "Not Required" })
        }
        if (setVal['GMAT Wavier'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.gmat_total === "Not Applicable" || d.gmat_total === "Not Required" })
        }
        if (setVal['IELTS / English Wavier'] === true) {
            ResultData = _.filter((ResultData), (d) => { return (d.ielts === "Not Applicable" || d.ielts === "Not Required") && d.uni_english === "0.00" })
        }

        if (setVal['Private'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.university_type === "Private" })
        }
        if (setVal['Public'] === true) {
            ResultData = _.filter((ResultData), (d) => { return d.university_type === "Public" })
        }

        if (selectedProgram !== '') {
            ResultData = _.filter((ResultData), (d) => { return d.program === selectedProgram })
        }

        if (filterKey !== '') {
            if (filterKey['country'] !== '') {
                filterKey['country'].map((val) => {
                    ResultData = _.filter((ResultData), (d) => { return d.country === val })
                })
            }

            if (filterKey['Rank'][0] !== 0 && filterKey['Rank'][1] !== 1000) {
                ResultData = _.filter((ResultData), (d) => { return d.uni_rank > filterKey['Rank'][0] && d.uni_rank > filterKey['Rank'][1] })
            }

            if (filterKey['GPA'][0] !== 0 && filterKey['GPA'][1] !== 100) {
                ResultData = _.filter((ResultData), (d) => { return d.gre_total_1st_attempt > filterKey['GPA'][0] && d.gre_total_1st_attempt > filterKey['GPA'][1] })
            }

            if (filterKey['English'][0] !== 0 && filterKey['English'][1] !== 100) {
                ResultData = _.filter((ResultData), (d) => { return d.uni_english > filterKey['English'][0] && d.uni_english > filterKey['English'][1] })
            }

            if (filterKey['Internship'][0] !== 0 && filterKey['Internship'][1] !== 100) {
                ResultData = _.filter((ResultData), (d) => { return d.internship_in_months > filterKey['Internship'][0] && d.internship_in_months > filterKey['Internship'][1] })
            }

            if (filterKey['University_Type'] !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.university_type === filterKey['University_Type'] })
            }

            if (filterKey['Application_Fee'][0] !== 0 && filterKey['Application_Fee'][1] !== 10000) {
                ResultData = _.filter((ResultData), (d) => { return d.international_app_fee > filterKey['Application_Fee'][0] && d.international_app_fee > filterKey['Application_Fee'][1] })
            }

            if (filterKey['Tution_Fee'][0] !== 0 && filterKey['Tution_Fee'][1] !== 10000) {
                ResultData = _.filter((ResultData), (d) => { return d.international_tuition_fee_yr > filterKey['Tution_Fee'][0] && d.international_tuition_fee_yr > filterKey['Tution_Fee'][1] })
            }

            if (filterKey['Appli_Fee_Waiver'] !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.app_fee_waiver === filterKey['Appli_Fee_Waiver'] })
            }

            if (filterKey['Associate'] !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.associate === filterKey['Associate'] })
            }

            if (filterKey['GRE'][0] !== 0 && filterKey['GRE'][1] !== 340) {
                ResultData = _.filter((ResultData), (d) => { return d.gre_total > filterKey['GRE'][0] && d.gre_total > filterKey['GRE'][1] })
            }

            if (filterKey['GMAT'][0] !== 0 && filterKey['GMAT'][1] !== 800) {
                ResultData = _.filter((ResultData), (d) => { return d.gmat_total > filterKey['GMAT'][0] && d.gmat_total > filterKey['GMAT'][1] })
            }

            if (filterKey['SAT'][0] !== 0 && filterKey['SAT'][1] !== 1600) {
                ResultData = _.filter((ResultData), (d) => { return d.sat_total > filterKey['SAT'][0] && d.sat_total > filterKey['SAT'][1] })
            }

            if (filterKey['TOEFL'][0] !== 0 && filterKey['TOEFL'][1] !== 120) {
                ResultData = _.filter((ResultData), (d) => { return d.toefl > filterKey['TOEFL'][0] && d.toefl > filterKey['TOEFL'][1] })
            }

            if (filterKey['PTE'][0] !== 0 && filterKey['PTE'][1] !== 120) {
                ResultData = _.filter((ResultData), (d) => { return d.pte > filterKey['PTE'][0] && d.pte > filterKey['PTE'][1] })
            }

            if (filterKey['IELTS'][0] !== 0 && filterKey['IELTS'][1] !== 9) {
                ResultData = _.filter((ResultData), (d) => { return d.ielts > filterKey['IELTS'][0] && d.ielts > filterKey['IELTS'][1] })
            }

            if (filterKey['IELTS'][0] !== 0 && filterKey['IELTS'][1] !== 9) {
                ResultData = _.filter((ResultData), (d) => { return d.ielts > filterKey['IELTS'][0] && d.ielts > filterKey['IELTS'][1] })
            }

            if (filterKey['ACT'][0] !== 0 && filterKey['ACT'][1] !== 9) {
                ResultData = _.filter((ResultData), (d) => { return d.act_score > filterKey['ACT'][0] && d.act_score > filterKey['ACT'][1] })
            }

            if (filterKey['DUOLINGO'][0] !== 0 && filterKey['DUOLINGO'][1] !== 9) {
                ResultData = _.filter((ResultData), (d) => { return d.duolingo > filterKey['DUOLINGO'][0] && d.duolingo > filterKey['DUOLINGO'][1] })
            }

        }


        // possible options start
        if (setVal['Possible Options'] === true) {

            // international deadline should be greater than today
            ResultData = _.filter((ResultData), (d) => { return moment(d.inter_adm_deadline).format('YYYY.MM.DD') >= moment().format('YYYY.MM.DD') })
            // console.log('today', moment().format('YYYY.MM.DD'))

            if (userData?.ielts_total_1st_attempt !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.ielts <= userData?.ielts_total_1st_attempt || d.ielts === 'Not Applicable' || d.ielts === 'Not Required' })
            }
            if (userData?.pte_total_1st_attempt !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.pte <= userData?.pte_total_1st_attempt || d.pte === 'Not Applicable' || d.pte === 'Not Required' })
            }
            if (userData?.toefl_total_1st_attempt !== '') {
                ResultData = _.filter((ResultData), (d) => { return parseInt(d.toefl) <= parseInt(userData?.toefl_total_1st_attempt) || d.toefl === 'Not Applicable' || d.toefl === 'Not Required' })
            }
            if (userData?.duolingo_total_1st_attempt !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.duolingo <= userData?.duolingo_total_1st_attempt || d.duolingo === 'Not Applicable' || d.duolingo === 'Not Required' })
            }
            if (userData?.english_percentage_12th !== '') {
                ResultData = _.filter((ResultData), (d) => { return d.uni_english <= userData?.english_percentage_12th })
            }


            if (userData?.degree_looking_for === "Masters") {
                if (userData?.bachelors_percentage > '0') {
                    ResultData = _.filter((ResultData), (d) => { return d.academic_requirements_gpa <= userData?.bachelors_percentage })
                }
                if (userData?.number_backlogs > '0') {
                    ResultData = _.filter((ResultData), (d) => { return parseInt(d.num_backlogs) <= parseInt(userData?.number_backlogs) })
                }
                if (userData?.gre_total_1st_attempt !== '') {
                    ResultData = _.filter((ResultData), (d) => { return parseInt(d.gre_total) <= parseInt(userData?.gre_total_1st_attempt) || d.gre_total === 'Not Applicable' || d.gre_total === 'Not Required' })
                }
                if (userData?.gmat_total_1st_attempt !== '') {
                    ResultData = _.filter((ResultData), (d) => { return parseInt(d.gmat_total) <= parseInt(userData?.gmat_total_1st_attempt) || d.gmat_total === 'Not Applicable' || d.gmat_total === 'Not Required' })
                }

            }
            if (userData?.degree_looking_for === "Bachelors") {
                if (userData?.puc_percentage_12th > '0') {
                    ResultData = _.filter((ResultData), (d) => { return d.uni_puc_inter_12 <= userData?.puc_percentage_12th })
                }
                if (userData?.act_total_1st_attempt !== '') {
                    ResultData = _.filter((ResultData), (d) => { return d.act_score <= userData?.act_total_1st_attempt || d.act_score === 'Not Applicable' || d.act_score === 'Not Required' })
                }
                if (userData?.sat_total_1st_attempt !== '') {
                    ResultData = _.filter((ResultData), (d) => { return d.sat_total <= userData?.sat_total_1st_attempt || d.sat_total === 'Not Applicable' || d.sat_total === 'Not Required' })
                }
            }
        }
        // possible options end

        setUniverSityList(ResultData)
        setData(ResultData)

        // console.log(ResultData)
        // console.log(list)
    }

    const fetchFavourite = (addonConditionType, moduleType, contactid, activeTab) => {
        // console.log(getUser())
        setModuleType(moduleType)
        setActiveDgree(addonConditionType)

        const Favourite = new FormData()
        Favourite.append(OPERATION, operationValue.Favorites)
        Favourite.append(SESSION_ID, getSessionId())
        Favourite.append(USER_ID, getUserId())
        Favourite.append('userType', getUserType())
        Favourite.append('moduleType', 'Universities')
        Favourite.append('addonConditionType', addonConditionType)
        if (contactid || props?.user?.contactid) {
            Favourite.append('contactid', `${props?.user?.contactid || contactid}`)
        }
        setLoader(true)
        setErrorMessage(null)
        setData([])
        axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', Favourite).then((response) => {
            setLoader(false)
            // console.log(response)
            if (response.data.status === "false") {
                if (response.data.msg === "Session Invalid") {
                    clearUserData()
                    history.push('/login')
                }
                setFavouriteList([])
                setErrorMessage("No Favourites Universities Are Added")
            }
            else {
                setFavouriteList(response?.data)
                // setUniverSityList(response?.data)
                // setAllUniverSity(response?.data)
                // setData(response?.data?.data)
            }
        }).catch((err) => {
            setLoader(false)
        })
    }


    const fetchScholarships = (addonConditionType, moduleType, contactid, activeTab) => {

        setModuleType(moduleType)
        setActiveDgree(addonConditionType)

        const Favourite = new FormData()
        Favourite.append(OPERATION, operationValue.Favorites)
        Favourite.append(SESSION_ID, getSessionId())
        Favourite.append(USER_ID, getUserId())
        Favourite.append('userType', getUserType())
        Favourite.append('moduleType', moduleType)
        Favourite.append('addonConditionType', `${addonConditionType}`)
        if (contactid || props?.user?.contactid) {
            Favourite.append('contactid', `${props?.user?.contactid || contactid}`)
        }
        Favourite.append('moduleType', moduleType)
        setLoader(true)
        setErrorMessage(null)
        setData([])
        axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', Favourite).then((response) => {
            setLoader(false)
            // console.log(response)
            if (response.data.status === "false") {
                setFavouriteList([])
                setErrorMessage("No Favourites Universities Are Added")
            } else {
                setFavouriteList(response?.data)
                setUniverSityList(response?.data)
                setAllUniverSity(response?.data)
                setData(response?.data?.data)
            }
        }).catch((err) => {
            setLoader(false)
        })
    }




    const GetResultCardForAll = (errorMessage, loader, data) => {
        return (
            errorMessage ? <Alert severity="error" color="error">
                {errorMessage}
            </Alert>
                : loader ?
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                    : data?.length > 0 ?
                        <Grid sx={{
                            marginLeft: 0,
                            marginRight: 0,
                            borderRadius: 2,
                            marginTop: 3,
                        }} container spacing={2}>
                            {
                                data && data?.map((val, i) => {
                                    var isApplied = _.some(application_status_student?.application_status_student?.data?.data, ['universitiesid', val?.universitiesid])
                                    return (
                                        <Grid sx={{
                                            bgcolor: '#fff',
                                            borderRadius: 3,
                                            pt: 2,
                                            pb: 2,
                                            mb: 2,
                                            // paddingBottom: "50px",
                                            height: 'auto', boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)"
                                        }}
                                            item
                                            md={4}
                                            xs={12}
                                            // onClick={() => history.push(`/CollegeDetails/`, val)}
                                            key={i}
                                        >
                                            {moduleType === "SOP" ?
                                                <ResultCard1
                                                    val={val}
                                                    setBookmarkId={setBookmarkId}
                                                    setApplyData={props?.location?.state?.RelatedPrograms ? setApplyData : null}
                                                    setOpenApply={props?.location?.state?.RelatedPrograms ? setOpenApply : null}
                                                    setOpenFav={setOpenFav}
                                                    setSopData={setSopData}
                                                    setOpenDownload={setOpenDownload}
                                                    setIsBookMark={setIsBookMark}
                                                />
                                                :
                                                moduleType === "Scholarships" ?
                                                    <ResultCard
                                                        val={val}
                                                        university={val.university}
                                                        program={val.program}
                                                        semester={val.semester}
                                                        acceptance_rate={val.acceptance_rate}
                                                        international_tuition_fee_yr={val.international_tuition_fee_yr}
                                                        inter_adm_deadline={val.inter_adm_deadline}
                                                        universitiesid={val.universitiesid}
                                                        setBookmarkId={setBookmarkId}
                                                        setApplyData={setApplyData}
                                                        setOpenApply={setOpenApply}
                                                        setOpenFav={setOpenFav}
                                                        setAddStudentOpen={setAddStudentOpen}
                                                        Student={Student}
                                                        setIsBookMark={setIsBookMark}
                                                        isApplied={isApplied}
                                                    />
                                                    :
                                                    <ResultCard
                                                        setAddStudentOpen={setAddStudentOpen}
                                                        val={val}
                                                        university={val.university}
                                                        program={val.program}
                                                        semester={val.semester}
                                                        related_programs={val.related_programs}
                                                        acceptance_rate={val.acceptance_rate}
                                                        // country={val.country}
                                                        international_tuition_fee_yr={val.international_tuition_fee_yr}
                                                        inter_adm_deadline={val.inter_adm_deadline}
                                                        universitiesid={val.universitiesid}
                                                        setBookmarkId={setBookmarkId}
                                                        setApplyData={setApplyData}
                                                        setOpenApply={setOpenApply}
                                                        setOpenFav={setOpenFav}
                                                        Student={Student}
                                                        setIsBookMark={setIsBookMark}
                                                        isApplied={isApplied}
                                                    />}
                                        </Grid>
                                    )
                                })
                            }

                        </Grid> : 'No Data'
        )
    }

    let UserLocalstorge = getUser()
    let user_contact_type = UserLocalstorge?.user_contact_type || ''

    const SubmitFilter = () => {

    }

    const setSearchSelectId = (user_id) => {
        setCurrentUserId(user_id)
        // console.log(user_id)
        // fetchFavourite(activeDgree, moduleType, user_id)
        var data = JSON.stringify({
            "contact_no": "",
            "status": "All",
            "year": "All",
            "semester": "All",
            "daterange": "",
            "limit_start": 0,
            "limit_end": 9,
            "search": user_id,
            "bypass": "validation",
        });

        getStudentCountListUsingStatusAction1(data).then((Data) => {
            if (Data?.data?.total > 0) {
                let contact_no = Data?.data?.list[0]
                // console.log(contact_no)
                setSearchSelectedUser(contact_no)
                setStudent(contact_no)
                getFavouriteUniversities(`All`, `${moduleType}`, `${JSON.stringify(contact_no)}`, true)
                getParteneredUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(contact_no)}`, true)
                getMatchingUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(contact_no)}`, true)

            }
        }).catch((error) => {
            // console.log("error", error)
        })
    }

    const GetDocumentRequiredList = (User) => {
        let documents_required = (User?.documents_required || '')?.split("|##|")
        let String = ''
        if (documents_required.length > 0) {
            _.map((documents_required), (d, index) => {
                String = String + `${index + 1}) ${d}%0D%0A`
            })

        }
        return String
    }

    const GetUniverCityProgramStatusRemarData = () => {
        let String = ''
        if (data.length > 0) {
            data?.slice(0, 30)?.map((val, index) => {
                // _.map(data, (val, index) => {
                let scholarship_name = (val.scholarship_name ? val.scholarship_name : val.university).replace("&", " AND ");
                // let related_programs = ((val.program ? val.program : val.related_programs).split("|##|").join(",")).replace("&", " AND ");
                let related_programs = (val.program).replace("&", " AND ");
                let funding_type = (val.funding_type ? val.funding_type : val.semester).replace("&", " AND ");
                let success_rate = (val?.success_rate ? val?.success_rate : val?.acceptance_rate).replace("&", " AND ");
                let scho_amt = Math.round(val?.scho_amt ? val?.scho_amt : val?.international_tuition_fee_yr)
                let Date = val.app_end_date ? val.app_end_date : val.inter_adm_deadline
                let application_fee = val?.app_fee_waiver && val?.app_fee_waiver === '1' ? 'waiver' : `💵 ${getCurrencySymbol(val.country)} ${val?.international_app_fee}`
                String = String + `%0D%0A%0D%0A ${index + 1}).*${scholarship_name}*,%0D%0A ${related_programs}%0D%0A*${funding_type}* *Deadline*⏰ ${Date ? moment(Date).format('ll') : ""}%0D%0A*Acceptance*: ${success_rate}%%0D%0A*Tuition Fee*:💵 ${getCurrencySymbol(val.country)}${scho_amt}/yr %0D%0A*Application Fee*:${application_fee} %0D%0A*Apply Now*:https%3A%2F%2Fapp.bscglobaledu.com%2F%23%2FCollegeDetails%2F${val?.universitiesid}`
            })
            if (data.length > 30) {
                String = String + `%0D%0A%0D%0AClick here for all Universities: https://bit.ly/3A2yFFA`
            }
            return String
        }
        else {
            return
        }

    }

    const getString = () => {

        let student1 = getUser()
        const documents_required = GetDocumentRequiredList(Student ? Student : student1)
        let TempData = `Dear${Student?.firstname} ${Student?.lastname}%0D%0A%0D%0AGreetings from *BRAINSTORM CONSULTING *%0D%0A%0D%0APlease find the *${data.length}* ${activeDgree} ${activeTab} Universities 🏫  based on your profile in our App. %0D%0A%0D%0A Please *confirm and Upload Documents* for processing your application.%0D%0A%0D%0AExplore 🏫 *60000+* Programs and Universities, 10000+ Scholarship, 5000+ SOP and LOR Samples. 
        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
        %0D%0A iOS: https://apple.co/33ujR4Q
        %0D%0A ${GetUniverCityProgramStatusRemarData()}%0D%0A%0D%0A ${documents_required ? `*Document Required*📑 %0D%0A ${documents_required}` : ''} 
        %0D%0A%0D%0A *Upload* : https://youtu.be/ix173Ac0hQA  
        %0D%0A%0D%0A *Mentor*:${student1?.firstname || ''} ${student1?.lastname || ''} 
        ${student1?.assigned_phone_mobile ? `%0D%0A${student1?.assigned_phone_mobile}` : ''}
        ${student1?.assigned_phone_work ? `%0D%0A${student1?.assigned_phone_work}` : ''}
        ${student1?.assistantphone ? `%0D%0A${student1?.assistantphone}` : ''}
        ${student1?.assigned_email1 ? `%0D%0A${student1?.assigned_email1}` : ''}
        ${student1?.reports_to_email1 ? `%0D%0A${student1?.reports_to_email1}` : ''}
        ${student1?.account_website ? `%0D%0A${student1?.account_website}` : ''}  
        %0D%0A%0D%0A*BRAINSTORM CONSULTING*
        %0D%0A%0D%0A Android: https://bit.ly/FreeStudyAbroad
        %0D%0A iOS: https://apple.co/33ujR4Q`

        return `https://api.whatsapp.com/send?phone=${Student?.mobile || student1?.mobile || ''}&text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")} `
    }

    const getEmailString = () => {
        var data = new FormData();
        data.append('type', 'FavpageEmail');
        data.append('contactid', Student?.contactid || getUserId());
        data.append('module', 'Emails');
        data.append('action', 'mailsend');
        data.append('frm_name', 'company_name');

        CommonEmailSendAction(data).then((res) => {
            // console.log(res)
            toast.success(`email send Successfully`)
        }).catch((error) => {
            toast.error(`${error.message}`)
        })
    }

    const callRefresh = () => {
        // setMechingUniversitiesData([])
        // setPartneredUniversitiesData([])
        // setMatchingLoader(true)
        // fetchFavourite(`${activeDgree}`, `${moduleType}`,`${currentUserId}`)
        // fetchMachingUniversities(`${activeDgree}`, `${moduleType}`,`${currentUserId}`)
        // fetchPartneredUniversities(`${activeDgree}`, `${moduleType}`,`${currentUserId}`)

        setTimeout(() => {
            setMatchingLoader(false)
        }, 3000);
    }
    useEffect(() => {
        callRefresh()
    }, [Student])

    useEffect(() => {
        if (activeTab === 'Partnered') {
            setUniverSityList(partenered_universities?.partenered_universities?.data || [])
            setAllUniverSity(partenered_universities?.partenered_universities || [])
            setData(partenered_universities?.partenered_universities?.data || [])
        }
        if (activeTab === 'Matching') {
            setUniverSityList(matching_universities?.matching_universities || [])
            setAllUniverSity(matching_universities?.matching_universities || [])
            setData(matching_universities?.matching_universities?.data || [])
        }
        if (activeTab === 'Favourite') {
            setUniverSityList(favourite_universities?.favourite_universities || [])
            setAllUniverSity(favourite_universities?.favourite_universities || [])
            setData(favourite_universities?.favourite_universities?.data || [])
        }

    }, [partenered_universities])

    useEffect(() => {
        if (props?.location?.state?.tab === 'Partnered') {
            // setUniverSityList(partenered_universities?.partenered_universities?.data || [])
            // setAllUniverSity(partenered_universities?.partenered_universities || [])
            // setData(partenered_universities?.partenered_universities?.data || [])
            setActiveTab('Partnered')
            setTabIndex(0)
        }
        if (props?.location?.state?.tab === 'Matching') {
            // setUniverSityList(matching_universities?.matching_universities || [])
            // setAllUniverSity(matching_universities?.matching_universities || [])
            // setData(matching_universities?.matching_universities?.data || [])
            setActiveTab('Matching')
            // console.log(props?.location?.state?.tab)
            setTabIndex(2)
        }
        if (props?.location?.state?.tab === 'Favourite') {
            // setUniverSityList(favourite_universities?.favourite_universities || [])
            // setAllUniverSity(favourite_universities?.favourite_universities || [])
            // setData(favourite_universities?.favourite_universities?.data || [])
            setActiveTab('Favourite')
            setTabIndex(1)
        }
    }, [props?.location?.state?.tab])



    const fetchMachingUniversities = (activeDgree, moduleType, currentUserId) => {
        setBackdrop(true)

        const Data = props?.user || Student || getUser()

        setMatchingLoader(true)
        if (Data && Data?.country && Data?.related_programs && Data?.degree_looking_for && Data?.semester_looking_for) {
            let programData = _.map(Data?.related_programs?.split("|##|"), (d) => d.trim())
            var formdata = new FormData();
            programData.map((d, i) => {
                formdata.append(`program[${i}]`, d);
            })
            formdata.append('operation', 'profileEvaluationDetails_new');
            formdata.append('country', Data?.country);

            formdata.append('degree_type', Data?.degree_looking_for);
            formdata.append('semester', Data?.semester_looking_for);
            formdata.append('selpage', '1');
            formdata.append('page_no', '1');
            formdata.append('page', '1');
            formdata.append('inter_adm_deadline', ' ');
            formdata.append('filter_apply', ' ');
            formdata.append('sort_name', ' ');
            formdata.append('Sort', 'Order by cast(`uni_rank` as decimal(13,2))');
            formdata.append('stdfrm', '1');
            formdata.append('call_frm', 'website');
            formdata.append(USER_ID, props?.user?.contactid || Student?.contactid || getUserId())
            formdata.append('Rank', '0.00,1000.00');
            formdata.append('Tution_Fee', '0.00,100000.00');
            formdata.append('addonConditionType', activeDgree);
            formdata.append('pages', 'all')
            fetchMachingUniversitiesAction(formdata).then((data) => {
                // console.log(data)
                setMatchingLoader(false)
                if (data?.status === "false") {
                    setMechingUniversitiesData([])
                    setErrorMessage("No Universities For this selection")
                    setUniverSityList([])
                    setAllUniverSity([])
                    setBackdrop(false)
                }
                else {
                    setMechingUniversitiesData(data)
                    setBackdrop(false)
                }

            }).catch((error) => {
                setMatchingLoader(false)
                // console.log("error", error)
                setBackdrop(false)
            })
        } else {
            let user_contact_type = Data?.user_contact_type || ''
            if (user_contact_type === "Student")
                setMatchingUniverCityModal(true)
            setMechingUniversitiesData([])
            setMatchingLoader(false)
            setBackdrop(false)
        }
    }

    const fetchPartneredUniversities = (activeDgree, moduleType, currentUserId) => {
        setBackdrop(true)
        const Data = props?.user || Student || getUser()


        if (Data && Data?.country && Data?.related_programs && Data?.degree_looking_for && Data?.semester_looking_for) {
            let programData = _.map(Data?.related_programs?.split("|##|"), (d) => d.trim())
            var formdata = new FormData();
            programData.map((d, i) => {
                formdata.append(`program[${i}]`, d);
            })
            formdata.append('operation', 'profileEvaluationDetails_new');
            formdata.append('country', Data?.country);

            formdata.append('degree_type', Data?.degree_looking_for);
            formdata.append('semester', Data?.semester_looking_for);
            formdata.append('selpage', '1');
            formdata.append('page_no', '1');
            formdata.append('page', '1');
            formdata.append('inter_adm_deadline', ' ');
            formdata.append('filter_apply', ' ');
            formdata.append('sort_name', ' ');
            formdata.append('Sort', 'Order by cast(`uni_rank` as decimal(13,2))');
            formdata.append('stdfrm', '1');
            formdata.append('call_frm', 'website');
            formdata.append(USER_ID, props?.user?.contactid || Student?.contactid || getUserId())
            formdata.append('Rank', '0.00,1000.00');
            formdata.append('Tution_Fee', '0.00,100000.00');
            formdata.append('partner_condition', 'partner');
            formdata.append('addonConditionType', activeDgree);
            formdata.append('pages', 'all')


            fetchMachingUniversitiesAction(formdata).then((data) => {
                // console.log(data)     
                if (data?.status === "false") {
                    setPartneredUniversitiesData([])
                    setErrorMessage("No Universities For this selection")
                    setUniverSityList([])
                    setAllUniverSity([])
                    setBackdrop(false)
                }
                else {
                    setPartneredUniversitiesData(data)
                    setUniverSityList(data)
                    setAllUniverSity(data)
                    setData(data?.data)
                    setBackdrop(false)
                }
            }).catch((error) => {
                // console.log("error", error)
                setBackdrop(false)
            })
        } else {
            // setMatchingUniverCityModal(true)
            setPartneredUniversitiesData([])
            setBackdrop(false)
        }
    }

    const fetchPossibleScholarships = (confirmstatus) => {
        var formdata = new FormData();
        formdata.append(OPERATION, operationValue.FetchScholarshipResult)
        formdata.append(SESSION_ID, getSessionId())
        formdata.append(USER_ID, props?.user?.contactid || getUserId())
        formdata.append('call_frm', 'website');
        formdata.append('Sort', '');
        formdata.append('degreeType', 'Masters');
        formdata.append('country', 'USA');
        formdata.append('program', 'Data Science');
        formdata.append('page', '1');
        formdata.append('stdfrm', '1');
        fetchPossibleScholarshipsAction(formdata)
            .then((data) => {
                data.data.data = _.filter(data?.data?.data || [], (d) => d.active)
                setPossibleScholarshipsData(data.data)
            }).catch((error) => {
                // console.log("error", error)
            })
    }

    const fetchRelaventSop = (confirmstatus) => {
        var formdata = new FormData();
        formdata.append(OPERATION, operationValue.FetchSOPwithoutUniversity)
        formdata.append(SESSION_ID, getSessionId())
        formdata.append(USER_ID, props?.user?.contactid || getUserId())
        formdata.append("operation", "fetchSOPwithoutUniversity");
        formdata.append("call_frm", "website");
        formdata.append("degreeType", "Masters");
        formdata.append("program", "'MBA'");
        formdata.append("page", "1");

        fetchRelaventSopAction(formdata)
            .then((data) => {
                setfetchRelaventSopData(data.data)
                // setLoading(false)
            }).catch((error) => {
                setfetchRelaventSopData([])
            })
    }

    const handleConfirm = async (value) => {
        // console.log(value)
        const bookmark = new FormData()
        bookmark.append(USER_ID, Student?.contactid || getUserId())
        bookmark.append(SESSION_ID, getSessionId())
        bookmark.append(OPERATION, operationValue.Bookmark)
        bookmark.append('universitiesid', bookmarkId)

        if (status === 'Favourite') {
            bookmark.append('btype', 'University')
        } else if (status === 'Matching') {
            bookmark.append('btype', 'University')
        } else if (status === 'scholarship') {
            bookmark.append('btype', 'Scholarship')
        } else if (status === 'Sop') {
            bookmark.append('btype', 'SOP')
        }
        bookmark.append('studentid', Student?.contactid || getUserId())
        if (value === 'add') {
            bookmark.append('bookmarkstatus', 1)

        } else {
            bookmark.append('bookmarkstatus', 0)
        }
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", bookmark)
            .then(response => {
                // console.log(response)
                if (response.data.status === 'success') {
                    setOpenFav(false)
                    setOpenMsg(true)
                    setBookmarkMsg(value === 'add' ? 'Bookmark successfully added' : 'Bookmark successfully removed')
                    // true for force refresh
                    getFavouriteUniversities(`All`, `${moduleType}`, `${JSON.stringify(Student)}`, true)
                    getParteneredUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(Student)}`, true)
                    getMatchingUniversities(`${activeDgree}`, `${moduleType}`, `${JSON.stringify(Student)}`, true)
                    // if (status === 'Favourite') {
                    //     fetchFavourite('all')
                    // } else if (status === 'Matching') {
                    //     fetchMachingUniversities()
                    // } else if (status === 'scholarship') {
                    //     fetchPossibleScholarships()
                    // } else if (status === 'Sop') {
                    //     fetchRelaventSop()
                    // }

                }
            })
    }
    if (sessionInvalid) {
        clearUserData()
        return <Redirect to="/login" />
    }

    return (
        <React.Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: 999 }}
                open={loading}
            //  onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box className={classes.root} sx={{ bgcolor: '#fafafa', }}>
                {history.location.pathname !== "/Profile" &&
                    <div style={{ position: "relative" }}>
                        {user_contact_type !== "Student" && <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <AsyncMulti inputData={Student} setSearchSelectId={setSearchSelectId} SubmitFilter={SubmitFilter} hideFilter="yes" />
                        </div>}
                        {/* <Grid item xs={12} sx={{ mt: 1, fontWeight: 'bold' }}> */}
                        {/* <Chip label="Favourites" color="success" /> {data && data.length > 0 && <Chip label={data.length} color="error" />} */}
                        {/* </Grid> */}
                    </div>
                }
                {getUserType() !== 'Student' &&
                    <Box sx={{ marginTop: '0px', marginLeft: 0, marginRight: 0, borderRadius: 2 }}>
                        {
                            Student?.firstname !== undefined ?
                                <Grid container spacing={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
                                    <Grid xs={9} sx={{ width: "55%", display: "flex", justifyContent: "left", alignItems: "left" }} >

                                        <p style={{ color: "#1A1A1A", fontSize: 20, fontWeight: "bold", color: "blue", cursor: "pointer" }} >
                                            <Chip onClick={() => {
                                                history.push({ pathname: '/Profile', state: { StudentData: Student, formNo: 1 } })
                                            }} label={`${Student?.firstname} ${Student?.lastname}`} color="warning" /> {' '}
                                            {/* <Chip label="Favourites" color="success" /> {favouriteList && favouriteList?.TotalRecords > 0 && <Chip label={favouriteList.TotalRecords} color="error" />} */}

                                        </p>

                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between" }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                        }}>
                                            <span onClick={() => getEmailString()} >
                                                <img src={`${Message}`} style={{ height: 30, width: 30 }} />
                                            </span>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                        }}>
                                            <a href={getString()} target="_blank" >
                                                <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} />
                                            </a>
                                        </div>
                                    </Grid>

                                </Grid>
                                :
                                ""
                        }
                        <hr />
                    </Box>}


                <Tabs selectedIndex={tabIndex}>
                    <TabList style={{ paddingLeft: 0, marginLeft: 0, justifyContent: 'start', display: "flex", textAlign: 'center', overflow: 'auto', whiteSpace: 'nowrap' }} className="tab_class tab_class_fav">

                        <Tab style={{ width: '100%', padding: '6px 12px', borderRadius: '14px', fontWeight: 'bold', margin: '8px 8px 8px 0px' }} className="dashboard_category" onClick={() => {

                            if (partenered_universities?.partenered_universities) {
                                setUniverSityList(partenered_universities?.partenered_universities?.data || [])
                                setAllUniverSity(partenered_universities?.partenered_universities || [])
                                setData(partenered_universities?.partenered_universities?.data || [])
                                setActiveTab('Partnered')
                                setTabIndex(0)
                            }

                        }}>Partnered {activeTab === 'Partnered' ? <span style={{ marginLeft: '2px', color: '#296dfc', fontWeight: 'bold', backgroundColor: '#fff', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }} >{data?.length}</span> : partenered_universities?.partenered_universities?.TotalRecords ? <span style={{ marginLeft: '2px', color: '#545454', fontWeight: 'bold', backgroundColor: '#e0e0e0', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }} >{partenered_universities?.partenered_universities?.TotalRecords}</span> : ""}</Tab>

                        <Tab style={{ width: '100%', padding: '6px 12px', borderRadius: '14px', fontWeight: 'bold', margin: '8px 8px 8px 0px' }} onClick={() => {

                            // fetchFavourite('all', 'Universities',currentUserId, 'Favourite')
                            setUniverSityList(favourite_universities?.favourite_universities || [])
                            setAllUniverSity(favourite_universities?.favourite_universities || [])
                            setData(favourite_universities?.favourite_universities?.data || [])
                            setActiveTab('Favourite')
                            setTabIndex(1)

                        }} className="dashboard_category">Shortlisted {activeTab === 'Favourite' ? <span style={{ marginLeft: '2px', color: '#296dfc', fontWeight: 'bold', backgroundColor: '#fff', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }}>{data?.length}</span> : favourite_universities && favourite_universities?.favourite_universities?.TotalRecords > 0 && moduleType === "Universities" && <span style={{ marginLeft: '2px', color: '#545454', fontWeight: 'bold', backgroundColor: '#e0e0e0', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }}>{favourite_universities?.favourite_universities?.TotalRecords}</span>}</Tab>


                        <Tab style={{ width: '100%', padding: '6px 12px', borderRadius: '14px', fontWeight: 'bold', margin: '8px 8px 8px 0px' }} className="dashboard_category" onClick={() => {
                            if (matching_universities?.matching_universities) {
                                setUniverSityList(matching_universities?.matching_universities || [])
                                setAllUniverSity(matching_universities?.matching_universities || [])
                                setData(matching_universities?.matching_universities?.data || [])
                                setActiveTab('Matching')
                                setTabIndex(2)
                            }

                        }}>Matching {activeTab === 'Matching' ? <span style={{ marginLeft: '2px', color: '#296dfc', fontWeight: 'bold', backgroundColor: '#fff', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }} >{data?.length}</span> : matching_universities?.matching_universities?.TotalRecords ? <span style={{ marginLeft: '2px', color: '#545454', fontWeight: 'bold', backgroundColor: '#e0e0e0', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }}>{matching_universities?.matching_universities?.TotalRecords}</span> : ""}</Tab>


                        <Tab style={{ width: '100%', padding: '6px 12px', borderRadius: '14px', fontWeight: 'bold', margin: '8px 8px 8px 0px' }} onClick={() => fetchFavourite('all', 'Scholarships', currentUserId, 'Scholarships')} className="dashboard_category">Scholarships {data && data.length > 0 && moduleType === "Scholarships" && <span style={{ marginLeft: '2px', color: '#545454', fontWeight: 'bold', backgroundColor: '#e0e0e0', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }} >{data.length}</span>}</Tab>

                        <Tab style={{ width: '100%', padding: '6px 12px', borderRadius: '14px', fontWeight: 'bold', margin: '8px 8px 8px 0px' }} onClick={() => fetchFavourite('all', 'SOP', currentUserId, 'SOP')} className="dashboard_category">SOP {data && data.length > 0 && moduleType === "SOP" && <span style={{ marginLeft: '2px', color: '#545454', fontWeight: 'bold', backgroundColor: '#e0e0e0', borderRadius: '12px', fontSize: '10px', padding: '2px 8px' }} >{data.length}</span>}</Tab>
                    </TabList>


                    <TabPanel>


                        {/* checkbox test */}
                        <div>


                            <FormControl component="fieldset" style={{ width: "100%", display: 'block', width: '100%', backgroundColor: '#fff', padding: '6px 12px 12px 6px', borderRadius: '8px', boxShadow: '0 10px 20px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%)', paddingBottom: '12px', height: `${isCollapsed === false ? '42px' : ''}`, position: 'relative', overflowY: 'hidden' }}>
                                {isCollapsed ? <div style={{ float: 'right', marginTop: '3px', backgroundColor: '#ebebeb', borderRadius: '5px' }} > <ExpandLessIcon onClick={() => setCollapsed(v => !v)} sx={{}} /></div> : <div style={{ float: 'right', marginTop: '3px', backgroundColor: '#ebebeb', borderRadius: '5px' }}><ExpandMoreIcon onClick={() => setCollapsed(v => !v)} sx={{}} /></div>}

                                {Data?.map((d, i) => {
                                    if (selected[d] === true) {
                                        return <Chip label={d} onClick={() => selectedFilter(d, selected[d] === true ? false : true)} sx={{ m: 1, fontSize: '13px' }} style={{ cursor: 'pointer', margin: '6px 0px 0px 6px', borderRadius: '4px' }} size="small" variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                                    }
                                })}
                                {Data?.map((d, i) => {
                                    if (selected[d] === false) {
                                        return <Chip label={d} onClick={() => selectedFilter(d, selected[d] === true ? false : true)} sx={{ m: 1, fontSize: '13px' }} style={{ cursor: 'pointer', margin: '6px 0px 0px 6px', borderRadius: '4px' }} size="small" variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                                    }
                                })}
                            </FormControl>

                            <Box>
                                <div className="CommonSearchForNameEmailandMobile" style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        id="free-solo-demo"
                                        freeSolo
                                        size="small"

                                        // value={selectedSemester}
                                        onChange={(e, val) => filterResult(val)}
                                        // onChange={(e, val) => console.log(val)}
                                        options={
                                            allUniverSity && _.uniqBy(allUniverSity?.data, 'university').map((option) => option?.university)
                                            //    allUniverSity &&  allUniverSity?.data?.map((option) => option?.university)
                                        }
                                        // renderOption={allUniverSity && allUniverSity?.data?.map((option) => option?.university)}

                                        renderInput={(params) => <TextField {...params} label={`Search University`} />}

                                    />
                                </div>
                            </Box>
                        </div>
                        {/* checkbox end */}



                        <div style={{ marginTop: 10, display: 'inline-block', padding: '10px 0', display: "flex", justifyContent: "space-evenly" }}>

                            {
                                !loading && <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ width: '100%' }}
                                >

                                    <Pagination
                                        siblingCount={0}
                                        defaultPage={1}
                                        page={pagePartner}
                                        count={Math.ceil(univerSityList?.data?.length / 30 || univerSityList?.length / 30)}

                                        onChange={(e, p) => loadMorePartner(p)}

                                    />
                                </Box>
                            }

                        </div>

                        <Box sx={{
                            marginLeft: 0,
                            marginRight: 0,
                            borderRadius: 2,
                            marginTop: 3,
                            display: "inline-block", width: "100%"
                        }}>
                            <Chip label="Related Programs" color="success" sx={{ boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} onClick={() => setOpenProgram(true)} />
                            <Chip icon={<FilterAltOutlinedIcon />} label="Filter" sx={{ bgcolor: '#fff', float: 'right', boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} onClick={() => setOpenFilter(true)} />
                            {/* <Chip avatar={<img alt="Natacha" src={`${process.env.PUBLIC_URL}/img/sort.png`} />} label="Sort" sx={{ bgcolor: '#fff', float: 'right', mx: 1, boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)" }} /> */}
                        </Box>
                        {Matchingloader ?
                            <></>
                            : univerSityList?.length > 0 ?
                                <>
                                    <Grid sx={{
                                        marginLeft: 0,
                                        marginRight: 0,
                                        borderRadius: 2,
                                        marginTop: 1,
                                    }} container spacing={2}>

                                        {
                                            (univerSityList).slice(StartPartner, Limit)?.map((val, i) => {
                                                var isApplied = _.some(application_status_student?.application_status_student?.data?.data, ['universitiesid', val?.universitiesid])
                                                return <Grid xs={12} md={4}>
                                                    <University
                                                        key={i}
                                                        university={val?.university}
                                                        universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                        programName={val?.program}
                                                        semester={val?.funding_type ? val?.funding_type : val?.semester}
                                                        Dates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                        val={val}
                                                        type="Matching"
                                                        setBookmarkId={setBookmarkId}
                                                        setOpenFav={setOpenFav}
                                                        setStatus={setStatus}
                                                        setApplyData={setApplyData}
                                                        setOpenApply={setOpenApply}
                                                        setIsBookMark={setIsBookMark}
                                                        isApplied={isApplied}
                                                    />
                                                </Grid>
                                            })
                                        }


                                    </Grid>
                                </>
                                : <Alert severity="error" color="error">
                                    No universities for this Selection.
                                </Alert>}
                    </TabPanel>


                    <TabPanel>
                        <React.Fragment>

                            {/* checkbox test */}
                            <div>


                                <FormControl component="fieldset" style={{ width: "100%", display: 'block', width: '100%', backgroundColor: '#fff', padding: '6px 12px 12px 6px', borderRadius: '8px', boxShadow: '0 10px 20px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%)', paddingBottom: '12px', height: `${isCollapsed === false ? '42px' : ''}`, position: 'relative', overflowY: 'hidden' }}>
                                    {isCollapsed ? <div style={{ float: 'right', marginTop: '3px', backgroundColor: '#ebebeb', borderRadius: '5px' }} > <ExpandLessIcon onClick={() => setCollapsed(v => !v)} sx={{}} /></div> : <div style={{ float: 'right', marginTop: '3px', backgroundColor: '#ebebeb', borderRadius: '5px' }}><ExpandMoreIcon onClick={() => setCollapsed(v => !v)} sx={{}} /></div>}

                                    {Data?.map((d, i) => {
                                        if (selected[d] === true) {
                                            return <Chip label={d} onClick={() => selectedFilter(d, selected[d] === true ? false : true)} sx={{ m: 1, fontSize: '13px' }} style={{ cursor: 'pointer', margin: '6px 0px 0px 6px', borderRadius: '4px' }} size="small" variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                                        }
                                    })}
                                    {Data?.map((d, i) => {
                                        if (selected[d] === false) {
                                            return <Chip label={d} onClick={() => selectedFilter(d, selected[d] === true ? false : true)} sx={{ m: 1, fontSize: '13px' }} style={{ cursor: 'pointer', margin: '6px 0px 0px 6px', borderRadius: '4px' }} size="small" variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                                        }
                                    })}
                                </FormControl>

                                <Box>
                                    <div className="CommonSearchForNameEmailandMobile" style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
                                        <Autocomplete
                                            style={{ width: '100%' }}
                                            id="free-solo-demo"
                                            freeSolo
                                            size="small"

                                            // value={selectedSemester}
                                            onChange={(e, val) => filterResult(val)}
                                            // onChange={(e, val) => console.log(val)}
                                            options={
                                                allUniverSity && _.uniqBy(allUniverSity?.data, 'university').map((option) => option?.university)
                                                //    allUniverSity &&  allUniverSity?.data?.map((option) => option?.university)
                                            }
                                            // renderOption={allUniverSity && allUniverSity?.data?.map((option) => option?.university)}

                                            renderInput={(params) => <TextField {...params} label={`Search University`} />}

                                        />
                                    </div>
                                </Box>
                            </div>
                            {/* checkbox end */}
                            {GetResultCardForAll(errorMessage, loader, univerSityList?.data || univerSityList)}
                        </React.Fragment>
                    </TabPanel>
                    <TabPanel>


                        {/* checkbox test */}
                        <div>


                            <FormControl component="fieldset" style={{ width: "100%", display: 'block', width: '100%', backgroundColor: '#fff', padding: '6px 12px 12px 6px', borderRadius: '8px', boxShadow: '0 10px 20px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%)', paddingBottom: '12px', height: `${isCollapsed === false ? '42px' : ''}`, position: 'relative', overflowY: 'hidden' }}>
                                {isCollapsed ? <div style={{ float: 'right', marginTop: '3px', backgroundColor: '#ebebeb', borderRadius: '5px' }} > <ExpandLessIcon onClick={() => setCollapsed(v => !v)} sx={{}} /></div> : <div style={{ float: 'right', marginTop: '3px', backgroundColor: '#ebebeb', borderRadius: '5px' }}><ExpandMoreIcon onClick={() => setCollapsed(v => !v)} sx={{}} /></div>}

                                {Data?.map((d, i) => {
                                    if (selected[d] === true) {
                                        return <Chip label={d} onClick={() => selectedFilter(d, selected[d] === true ? false : true)} sx={{ m: 1, fontSize: '13px' }} style={{ cursor: 'pointer', margin: '6px 0px 0px 6px', borderRadius: '4px' }} size="small" variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                                    }
                                })}
                                {Data?.map((d, i) => {
                                    if (selected[d] === false) {
                                        return <Chip label={d} onClick={() => selectedFilter(d, selected[d] === true ? false : true)} sx={{ m: 1, fontSize: '13px' }} style={{ cursor: 'pointer', margin: '6px 0px 0px 6px', borderRadius: '4px' }} size="small" variant={selected[d] === true ? 'filled' : 'outlined'} color="success" />
                                    }
                                })}
                            </FormControl>

                            <Box>
                                <div className="CommonSearchForNameEmailandMobile" style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        id="free-solo-demo"
                                        freeSolo
                                        size="small"

                                        // value={selectedSemester}
                                        onChange={(e, val) => filterResult(val)}
                                        // onChange={(e, val) => console.log(val)}
                                        options={
                                            allUniverSity && _.uniqBy(allUniverSity?.data, 'university').map((option) => option?.university)
                                            //    allUniverSity &&  allUniverSity?.data?.map((option) => option?.university)
                                        }
                                        // renderOption={allUniverSity && allUniverSity?.data?.map((option) => option?.university)}

                                        renderInput={(params) => <TextField {...params} label={`Search University`} />}

                                    />
                                </div>
                            </Box>
                        </div>
                        {/* checkbox end */}
                        <div style={{ marginTop: 10, display: 'inline-block', padding: '10px 0', display: "flex", justifyContent: "flex-end" }}>
                            {
                                !loading && <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ width: '100%' }}
                                >

                                    <Pagination
                                        defaultPage={1}
                                        page={pageMatching}
                                        count={Math.ceil(univerSityList?.data?.length / 30 || univerSityList?.length / 30)}

                                        onChange={(e, p) => loadMoreMatching(p)}
                                    />
                                </Box>
                            }
                            {/* <Typography
                            onClick={() => {
                                let MechingUniversitiesDatas = {
                                    screenName: 'Matching Universities',
                                    ...MechingUniversitiesData
                                }
                                history.push({
                                    pathname: '/UniversitySearchResult',
                                    state: {
                                        data: MechingUniversitiesDatas,
                                        screenName: 'Matching Universities'
                                    }
                                })
                            }}
                                sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    margin: "0"
                                }}>
                                See all
                            </Typography> */}
                        </div>
                        {univerSityList?.data?.length > 0 || univerSityList?.length > 0 ?
                            <>
                                <Grid sx={{
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                    marginTop: 1,
                                }} container spacing={2}>

                                    {(univerSityList?.data || univerSityList).slice(StartMatching, Limit)?.map((val, i) => {
                                        var isApplied = _.some(application_status_student?.application_status_student?.data?.data, ['universitiesid', val?.universitiesid])
                                        return <Grid xs={12} md={3}>
                                            <University
                                                key={i}
                                                university={val?.university}
                                                universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                programName={val?.program}
                                                semester={val?.funding_type ? val?.funding_type : val?.semester}
                                                Dates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                val={val}
                                                type="Matching"
                                                setBookmarkId={setBookmarkId}
                                                setOpenFav={setOpenFav}
                                                setStatus={setStatus}
                                                setApplyData={setApplyData}
                                                setOpenApply={setOpenApply}
                                                setIsBookMark={setIsBookMark}
                                                isApplied={isApplied}
                                            />
                                        </Grid>
                                    })}


                                </Grid>
                            </>
                            : <Alert severity="error" color="error">
                                No universities for this Selection.
                            </Alert>
                        }
                    </TabPanel>
                    <TabPanel>
                        <React.Fragment>
                            {/* <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                                <RadioGroup className={classes.radioGroup} row name="use-radio-group"
                                    defaultValue="diploma">
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="diploma"
                                        label="Diploma"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="bachelors"
                                        label="Bachelors"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="master"
                                        label="Masters"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="phd"
                                        label="Phd"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                </RadioGroup>

                            </FormControl> */}
                            {GetResultCardForAll(errorMessage, loader, data)}
                        </React.Fragment>
                    </TabPanel>
                    <TabPanel>
                        <React.Fragment>
                            {/* <FormControl component="fieldset" sx={{ m: 1, minWidth: "100%" }}>
                                <RadioGroup className={classes.radioGroup} row name="use-radio-group"
                                    defaultValue="diploma">
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="diploma"
                                        label="Diploma"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="bachelors"
                                        label="Bachelors"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="master"
                                        label="Masters"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                    <MyFormControlLabel
                                        className={"radioLabel"}
                                        value="phd"
                                        label="Phd"
                                        control={<Radio className={"radioRadioMain"} />}
                                    />
                                </RadioGroup>

                            </FormControl> */}

                            {GetResultCardForAll(errorMessage, loader, data)}
                        </React.Fragment>
                    </TabPanel>
                </Tabs>
                <BottomNav />
            </Box>
            {<DownloadModal open={openDownload} handleClose={() => {
                setOpenDownload(false)
            }} SopData={SopData} />}
            <BookMarkMessageModal open={openMsg} handleClose={() => {
                callRefresh()
                setOpenMsg(false)
            }} data={bookmarkMsg} />
            <PopUpModal
                setOpenMsg={setOpenMsg}
                setBookmarkMsg={setBookmarkMsg}
                studentData={Student}
                open={openApply}
                handleClose={() => {
                    // callRefresh()
                    // getApplicationStatus('All',true)
                    // console.log('close worked')
                    setOpenApply(false)
                }} data={applyData} />
            <FavouriteModal open={openFav} isBookMark={isBookMark} setIsBookMark={setIsBookMark} handleClose={() => setOpenFav(false)} confirm={(value) => handleConfirm(value)} />

            <AddStudentList SelectedStudentList={SelectedStudentList} SetSelectedStudentList={SetSelectedStudentList} handleOpenAndClose={() => setOpenApply(!openApply)} AddStudentOpen={AddStudentOpen} handleCloseAddStudentModal={() => setAddStudentOpen(false)} />

            <ProgramModal
                open={open}
                handleClose={() => setOpenProgram(false)}
                data={programSelectArr.length > 0 ? programSelectArr : 'No data'}
                handleProgramList={(program) => {
                    setOpenProgram(false)
                    setSelectedProgram(program)
                }}
            />

            {openFilter && <FilterModal
                open={openFilter}
                // handleSortList={(value) => handleSortList(value)}
                handleFilterList={(values) => {
                    handleFilterList(values)
                    setOpenFilter(false)
                }}
                filterValues = {filterKey}
                handleClose={() => setOpenFilter(false)}
            />}
        </React.Fragment >
    )
};

export default AssociateUniversityFavourites;
const useStyles = styled(() => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));