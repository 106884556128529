import React,{ useState} from "react";
import { Grid, Typography, Input, Button, FormHelperText, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from 'formik';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { OPERATION, operationValue } from "../../../../Controller/Global";
import { clearUserData, getSessionId, getUserId, SESSION_ID, USER_ID, USER_NAME, getUser } from "../../../../Controller/localStorageHandler";

const FormRecover = () => {
  const classes = useStyles();
  const history = useHistory()
  let [error,setError]=useState('');
  let [success,setSuccess]=useState('');
  let [validate, handleValidate] = useState(false);

  const formik = useFormik({
    initialValues: {
      password:'',
      Text:'',
      confirmPswd:''
    },
    onSubmit: (values) => {
    handleValidate(true);
    if(values.password && values.Text && values.confirmPswd){
      if(values.password === values.confirmPswd){
        values[OPERATION]= operationValue.Changepassword;
        const formData= new FormData()
        for(let val in values){
          if(val !== 'confirmPswd'){
            formData.append(val, values[val])
          }
        }
        formData.append(USER_ID,getUserId())
        formData.append(SESSION_ID,getSessionId())
        formData.append(USER_NAME,getUser().email)
        formData.append('Email',getUser().email)
        axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData)
        .then(response => {
            if(response.data.status==="success"){
                clearUserData()
                  setSuccess('Password has changed')
                  // history.push('/')
                  setTimeout(function(){ history.push('/') },3000)
            }
            else{
                setError(response.data.msg)
            }
            console.log(response)
        })
      }else{
        setError('Confirm Password Does not Match')
      }
    }
}
    
});
if(success){
  return <Alert style={{marginBottom:'100px'}} severity="success">{success}</Alert>
}else{
  return (
    <>
    {error && <Alert severity="error">{error}</Alert>}
    <form onSubmit={formik.handleSubmit}>
    <Grid xs={11} sx={{ margin: "auto", }}>
        <Grid item xs={12} sx={{ margin: "auto" }}>
          <div>
            <main className={classes.inputContainer}>
              <Input
                type="password"
                className={classes.input}
                autoFocus
                name='Text'
                error={validate&&!formik.values.Text} 
                placeholder="Old Password"
                value={formik.values.Text}
                onChange={formik.handleChange}
              />
            </main>
          </div>
          {validate&&!formik.values.Text?<FormHelperText style={{ marginTop:"10px",color:"red"}}id="component-error-text">Please enter old password</FormHelperText>:''}

        </Grid>
        <Grid item xs={12} sx={{ margin: "auto" }}>
          <div>
            <main className={classes.inputContainer}>
            <Input
                type="password"
                className={classes.input}
                autoFocus
                name='password'
                error={validate&&!formik.values.password} 
                placeholder="New Password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </main>
          </div>
          {validate&&!formik.values.password?<FormHelperText style={{ marginTop:"10px",color:"red"}}>Please enter new password</FormHelperText>:''}

        </Grid>
        <Grid item xs={12} sx={{ margin: "auto" }}>
          <div>
            <main className={classes.inputContainer}>
            <Input
                type="password"
                className={classes.input}
                autoFocus
                name="confirmPswd"
                error={validate&&!formik.values.confirmPswd}
                placeholder="Confirm Password"
                defaultValue={formik.values.confirmPswd}
                onChange={formik.handleChange}
              />
            </main>
          </div>
          {validate&&!formik.values.confirmPswd?<FormHelperText style={{ marginTop:"10px",color:"red"}}>Please enter confirm password</FormHelperText>:''}

        </Grid>
        <Grid xs={11} sx={{ margin : "auto" , mb:5}} > 
                     <Button
                     type="submit"
            sx={{
              fontSize: "14px",
              p: 1,
              color: "#fff",
              backgroundColor: "#1b6cfc",
              my: "10px",
              borderRadius: "100px",
              textTransform:"none",
              width: "100%",
            }}
          >
            Change Password
          </Button>
          </Grid>

      </Grid>
    </form>
    </>
  )
          }
}
export default FormRecover;

const useStyles = makeStyles(() => ({
  inputContainer: {
   display:"flex",
    padding: "5px",
    width: "90%",
    height: "20%",
    margin: "15px auto",
    border: "1px solid transparent",
    borderRadius: "25px",
    boxShadow: "2px 2px 10px #DCDCDC",
  },
  input: {
    borderColor: "transparent",
    outline: "none",
    marginLeft: "10px",
    fontSize: "14px",
    opacity: 0.7,
    color: "#000",
    letterSpacing: 0.7,
      width:"100%"
  },
  filterIcon: {
    position: "relative",
    top: 10,
    height: "75px",
    width: "75px",
    //  boxShadow : '2px 2px 10px #DCDCDC','
  },
}));
