import axios from "axios";


export const SendMessageAction = (data) => {

    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/student/update_student_remarks',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

    })
}



export const MentorDocumentDetailAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/profile/ajax_get_student_remarks',
            headers: {
                'Content-Type': 'text/plain',
                'Cookie': 'ci_session=qj9k5n1svcreng148kbumuudqk2as4e1'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    })
}
export const UploadDocumentAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapp/ishvi/website/back_up/uploadDocumentSave.php',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            });

    })
}
export const fetchStudentUploadDocumentAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/uploaddocument/ajax_student_uploaddocument',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            });

    })
}
export const fetchRequiredDataAction = (Data) => {
    return new Promise(async (resolve, reject) => {
        // var config = {
        //     method: 'get',
        //     url: 'https://erp.bscglobaledu.com/mapi/search/ajax_get_documentrequired',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     data: {
        //         'sessionid': 'ceb5n4gp',
        //         'userid': '633575',
        //     }
        // };

        let Response = {
            "status": 1,
            "data": [
                { "document": "10th and 12th Marksheet" },
                { "document": "Accommodation Confirmation" },
                { "document": "ACT" },
                { "document": "Admission Essay" },
                { "document": "Affidavit Of Support" },
                { "document": "Agent Authorization" },
                { "document": "Air Ticket Confirmation" },
                { "document": "All Semester Marksheet" },
                { "document": "Application Fee" },
                { "document": "Application Form" },
                { "document": "ATAS" },
                { "document": "Bank Certificate" },
                { "document": "Birth Certificate" },
                { "document": "Blocked Account" },
                { "document": "Bonafied Certificate" },
                { "document": "CAQ" },
                { "document": "Charted Account Report" },
                { "document": "Confirmation Of Acceptance For Studies-CAS" },
                { "document": "Confirmation Of Enrolment-COE" },
                { "document": "Course Completion Certificate" },
                { "document": "Covering Letter" },
                { "document": "Department Essay" },
                { "document": "Duolingo" },
                { "document": "Education Loan Sanction" },
                { "document": "Fee Payment Receipt" },
                { "document": "Financial Matrix" },
                { "document": "Financial Support Form" },
                { "document": "French Language Certificate" },
                { "document": "GATE" },
                { "document": "German Language Certificate" },
                { "document": "GIC" },
                { "document": "GMAT" },
                { "document": "GRE" },
                { "document": "I-20" },
                { "document": "I-94" },
                { "document": "IELTS" },
                { "document": "IHS Health Insurance" },
                { "document": "ITRs Of Sponsors" },
                { "document": "LOR-1" },
                { "document": "LOR-2" },
                { "document": "LOR-3" },
                { "document": "Medical Certificate" },
                { "document": "Medium Of Instruction" },
                { "document": "Motivational Letter" },
                { "document": "NOC" },
                { "document": "Offer Letter" },
                { "document": "Official Transcript" },
                { "document": "Passport Copy" },
                { "document": "PDC/Degree Certificate" },
                { "document": "PF/PPF Withdraw Certificate" },
                { "document": "Photo" },
                { "document": "Police Clearance Certificate" },
                { "document": "Property Evaluation" },
                { "document": "PTE" },
                { "document": "Research Proposal-1" },
                { "document": "Research Proposal-2" },
                { "document": "Research Proposal-3" },
                { "document": "Research Proposal-4" },
                { "document": "Research Proposal-5" },
                { "document": "Resume" },
                { "document": "SAT" },
                { "document": "SOP" },
                { "document": "TOEFL" },
                { "document": "Visa  Checklist" },
                { "document": "Visa Application" },
                { "document": "Visa Copy" },
                { "document": "WES" },
                { "document": "Work Experience" }
            ],
            "message": "Success"
        }
        resolve(Response.data)
        // var config = {
        //     method: 'get',
        //     url: 'https://erp.bscglobaledu.com/mapi/search/ajax_get_documentrequired?bypass=validation',
        // };

        // axios(config)
        //     .then(function (response) {
        //         resolve(response?.data.data || [])
        //     })
        //     .catch(function (error) {
        //         reject([])
        //     });
    })
}
export const fetchUnivercityApplyAction = (Data) => {
    return new Promise(async (resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/invoice/ajax_add_studentuniversity',
            data: Data
        };

        axios(config)
            .then(function (response) {
                resolve(response?.data || [])
            })
            .catch(function (error) {
                reject(error)
            });

    })
}


export const fetchProfileDetails = (formdata) => {
    return new Promise(async (resolve, reject) => {

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://erp.bscglobaledu.com/mapi/JsonApi/", requestOptions)
            .then(response => response.text())
            .then(result => {
                let data = JSON.parse(result)
                if (data)
                    // resolve(data?.data[0] || [])
                    console.log(data, 'if')
                else
                    // resolve([])
                    console.log(data, 'else')
            })
            .catch(error => reject(error));
    })
}


export const fetchRelatedProgramAction = (data) => {
    return new Promise((resolve, reject) => {


        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/dashboard/ajax_student_featured_program',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
    })
}

export const fetchMachingUniversitiesAction = (Favourite) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: Favourite
        };

        axios(config)
            .then(function (response) {
                resolve(response?.data || [])
            })
            .catch(function (error) {
                reject(error)
            });
    })
}

export const fetchPartneredUniversityAction = (Favourite) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: Favourite
        };

        axios(config)
            .then(function (response) {
                resolve(response?.data || [])
            })
            .catch(function (error) {
                reject(error)
            });
    })
}

export const fetchFavouriteAction = (Favourite) => {
    return new Promise((resolve, reject) => {
        axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/', Favourite).then((response) => {
            resolve(response?.data || [])
        }).catch((error) => {
            reject(error)
        })
    })
}

export const fetchApplicationStatusAction = (ApplicationStatusData) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: ApplicationStatusData
        };

        axios(config)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            });

    })
}
export const fetchApplicationStatusAction2 = (ApplicationStatusData) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/associatestatus/applicationstatus_new',
            data: ApplicationStatusData
        };

        axios(config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });

    })
}



export const fetchPossibleScholarshipsAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            });
    })
}

export const fetchRelaventSopAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            });
    })
}


/*-------------------------------------------Faq page-----------------------------*/

export const fetchFaqListAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/faq/ajax_get_allFaq',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error);
            });

    })
}
/*-------------------------------------------Service page-----------------------------*/

export const fetchServiceListAction = () => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'get',
            url: 'https://erp.bscglobaledu.com/mapi/service/ajax_get_service?bypass=validation'
        };

        axios(config)
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            });

    })
}

/*-------------------------------------------Document CheckList-----------------------------*/

export const fetchDocumentCheckListAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/documentchecklist/ajax_document_checklist',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });

    })
}


/*-------------------------------------------Event List-----------------------------*/

export const fetchEventListAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });

    })
}

/*-------------------------------------------Accomodation List-----------------------------*/

export const fetchAccomodationListAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });


    })
}

/*-------------------------------------------ApplyIntenship List-----------------------------*/

export const fetchApplyIntenshipListAction = (data) => {
    return new Promise((resolve, reject) => {


        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}
/*-------------------------------------------ApplyIntenship List-----------------------------*/

export const fetchComoonPickerListAction = (data) => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data.data)
            })
            .catch(function (error) {
                reject(error);
            });
    })
}


/*-------------------------------------------GetComment List-----------------------------*/

export const fetchCommentListAction = (data) => {
    return new Promise((resolve, reject) => {
        var data = new FormData();
        data.append('userid', '270453');
        data.append('project_task_id', '674335');
        data.append('sessionid', 'sdaf');

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/student/get_student_remarkss',
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}

/*-------------------------------------------GetComment List-----------------------------*/

export const fetchAddStudentAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

    })
}

/*-------------------------------------------Add visa mock List-----------------------------*/


export const fetchAddVisaMock = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

    })
}

/*-------------------------------------------GetComment List-----------------------------*/

export const fetchStudentProjectChatAction = (data) => {
    return new Promise((resolve, reject) => {



        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/student/get_student_remarkss',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });


    })
}

/*-------------------------------------------Update Tuition Deposit Update-----------------------------*/


export const updateTuitionDepoistStudent = (data) => {
    return new Promise((resolve, reject) => {



        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/student/update_deposit_status_comment',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });


    })
}


/*-------------------------------------------Notification List ----------------------------*/

export const fetchNotificationListAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/notification/ajax_get_notification',
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    })
}


/*-------------------------------------------Vender Link ----------------------------*/

export const fetchVenderLinkAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

    })
}

/*-------------------------------------------Add Student to universitied   ----------------------------*/

export const fetchAddStudentListAction = (data) => {
    return new Promise((resolve, reject) => {


        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/invoice/ajax_add_studentuniversity',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });


    })
}


/*-------------------------------------------Add Student to universitied   ----------------------------*/

export const fetchStudentListForDropDownAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapp/ishvi/website/back_up/GetContactList.php',
            data: data
        };

        axios(config)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });


    })
}
/*-------------------------------------------common email send   ----------------------------*/
export const CommonEmailSendAction = (data) => {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapp/ishvi/ws/SendMailJson.php',
            data: data
        };

        axios(config)
            .then(function (response) {

                resolve(response?.data || []);
            })
            .catch(function (error) {
                reject(error);
            });



    })
}

/*-------------------------------------------campian Insert   ----------------------------*/
export const champianInsertAction = (data) => {
    return new Promise((resolve, reject) => {



        var config = {
            method: 'post',
            url: 'https://erp.bscglobaledu.com/mapi/Whatsapp/campaign_insert', 
            data: data
        };
        axios(config)
        .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                debugger
                reject(error);
            });




    })
}
