import React, { useState, useEffect } from 'react';
import "../Profile.css";
import SearchBox from '../../SearchBox/SearchBox';
import Chat from '../../../img/Chat.png';
import Progress from '../../../img/Progress.png';
import Completed from '../../../img/Completed.png';
import { OPERATION, operationValue } from '../../../Controller/Global';
import { barColor } from '../../../Controller/Global';
import { getSessionId, getUserId, getUser, SESSION_ID, Contact_Id, USER_ID, setUser, logout,getUserType, setProfileDetails,getProfileDetails } from '../../../Controller/localStorageHandler';
import axios from 'axios';
import FormControlLabel from "@mui/material/FormControlLabel";
import PDF from '../../../img/pdf.png';
import ProgressBar from '../../../img/ProgressBar.png';
import CloseSquare from '../../../img/CloseSquare.png';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { fetchProfileDetails, MentorDocumentDetailAction } from '../../Action';
import { Alert, Autocomplete, Button, CircularProgress, FormControl, Grid, Input, MenuItem, Select, TextField, Chip, InputLabel, Typography } from '@mui/material';
import { Radio, RadioGroup, useRadioGroup } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import StatusBar from '../../Status/index'
import UploadDocuments from '../../Documents/index'
import { display, styled } from '@mui/system';
// import BottomNav from '../../../pages/inc/BottomNav';
import whatsapp from '../../../img/whatsapp.png';
import Message from '../../../img/Message.png';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import ChatCard from '../../Dashboards/Stepper/ChatCard';
import SendMessage from '../../../img/sendMessage.png';
import Calling from '../../../img/Calling.png';
import { AsyncMulti } from '../../StudentSearch';
import { getStudentCountListUsingStatusAction1 } from '../../Action/AssociateDocumentList';
import ApplicationStatus from '../../ApplicationStatus';
import AssociateUniversityFavourites from '../../University/AssociateUniversityFavourites';
import _ from 'lodash'
import CommentIcon from '@mui/icons-material/Comment';
import ChatModal from "../../Student/StudentList/ChatModal";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
 
import useAppContext from "../../../useContext/userContext"

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },

    }),

);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const Profile = () => {
    const history = useHistory();
    const classes = useStyles();
    // console.log(history?.location?.state?.StudentData)
    const {getFavouriteUniversities,getParteneredUniversities,getMatchingUniversities,
        getRelatedProgramsPickList,related_programs_picklist,
        getCountryPickList,country_picklist,
        getSemesterPickList,semester_picklist,
        getYearPickList,year_picklist,
    } = useAppContext()

    const [inputData, setInputData] = useState({});
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [countryList, setCountryList] = React.useState([]);
    const [programList, setProgramList] = React.useState([]);
    const [countryValue, setcountryValue] = React.useState(getUser().country ? [getUser().country] : [])
    const [programValue, setprogramValue] = React.useState(getUser().related_programs ? [getUser().related_programs] : [])
    const [semesterValue, setsemesterValue] = React.useState(getUser().semester_looking_for)
    const [yearValue, setyearValue] = React.useState(getUser().year_looking_for)
    const [yearList, setYearList] = React.useState([]);
    const [semesterList, setSemesterList] = React.useState([]);
    const [contactStatusList, setconstactStausList] = React.useState([]);
    const [contactTypeList, setContactTypeList] = React.useState([]);
    const [bachelorCollegeList, setbachelorCollegeList] = React.useState([]);
    const [bachelorYearList, setbachelorYearList] = React.useState([])
    const [pucCollegeList, setpucCollegeList] = React.useState([]);
    const [pucYearList, setpucYearList] = React.useState([])
    const [germanLangList, setgermanLangList] = React.useState([])
    const [greTotalList, setgreTotalList] = React.useState([])
    const [greQuantsList, setgreQuantsList] = React.useState([])
    const [toeflTotalList, settoeflTotalList] = React.useState([])
    const [toeflReadingList, settoeflReadingList] = React.useState([])
    const [pteTotalList, setpteTotalList] = React.useState([])
    const [pteReadingList, setpteReadingList] = React.useState([])
    const [duolingoTotalList, setduolingoTotalList] = React.useState([])
    const [duolingoReadingList, setduolingoReadingList] = React.useState([])
    const [ieltsTotalList, setieltsTotalList] = React.useState([])
    const [ieltsReadingList, setieltsReadingList] = React.useState([])
    const [gmatTotalList, setgmatTotalList] = React.useState([])
    const [gmatQuantsList, setgmatQuantsList] = React.useState([])
    const [actTotalList, setactTotalList] = React.useState([])
    const [actQuantsList, setactQuantsList] = React.useState([])
    const [satTotalList, setsatTotalList] = React.useState([])
    const [satQuantsList, setsatQuantsList] = React.useState([])
    const [Tab, setTab] = React.useState("Profile")
    const [mentorData, setmentorData] = React.useState({});
    const [FormNo, setStateFormNo] = React.useState(1)
    const [nextStudent,setNextStudent] = React.useState('');
    const [refresh,setRefresh] = React.useState(false);

    const [modalOpen,setModalOpen] = useState(false)

    const onChangeHandler = (name, value) => {
        setInputData({
            ...inputData, [name]: value
        })
        // console.log(inputData)
    }
    const handleOpenAndClose = () => setModalOpen(!open);
    const handleModalOpenAndClose = () => setModalOpen(false);

    let UserLocalstorge = getUser()
    let user_contact_type = UserLocalstorge?.user_contact_type || ''

    useEffect(() => {
     
        async function syncData() {
            // console.log(getUserType())
            if(getUserType() === 'Student'){
                const paramsData = new URLSearchParams()
                paramsData.append('operation','fetchProfile')
                paramsData.append('userid',getUserId())
                paramsData.append('contactid',getUserId())
                paramsData.append('sessionid',getSessionId())
                const res = await axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/',paramsData,
                    {
                        'Access-Control-Allow-Origin': '*',
                    })
                    // console.log(res)
                    setInputData(res?.data)
                    // localStorage.setItem('selectedStudentData', JSON.stringify(res?.data))
          
            }
            else{
            if(history?.location?.state?.StudentData){
                setInputData(history?.location?.state?.StudentData)
                setProfileDetails(history?.location?.state?.StudentData)
            }
            else if(history?.location?.state?.StudentId){
                getContactId(history?.location?.state?.StudentId)
                setTab(history?.location?.state?.tab)
            }
            else{
                if(getProfileDetails()){
                    setInputData(getProfileDetails())
                }
                else{
                    const paramsData = new URLSearchParams()
                    paramsData.append('operation','fetchProfile')
                    paramsData.append('userid',getUserId())
                    paramsData.append('contactid',getUserId())
                    paramsData.append('sessionid',getSessionId())
                    const res = await axios.post('https://erp.bscglobaledu.com/mapi/JsonApi/',paramsData,
                        {
                            'Access-Control-Allow-Origin': '*',
                        })
                        // console.log(res)
                        setInputData(res?.data)
                }
                
            }
        }
  

 

            const params = new URLSearchParams();
            params.append('operation', 'fetchPicklist');
            params.append('name', 'related_programs');
            const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
                , {
                    'Access-Control-Allow-Origin': '*',
                });

            params.append('name', 'country');
            const countryRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'semester');
            const semesterRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'year');
            const yearRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'leadstatus');
            const leadstatusRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name','contact_type');
            const contact_type = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'bachelors_college_name');
            const bachelorClgRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'bachelors_year_of_passing');
            const bachelorYearRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'puc_college_name_12th');
            const pucClgRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'puc_year_of_passing_12th');
            const pucYearRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'german_language');
            const germanRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'gre_total_1st_attempt');
            const greTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'gre_quants_1st_attempt');
            const greQuantsRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'toefl_total_1st_attempt');
            const toeflTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'toefl_reading_1st_attempt');
            const toeflReadingRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'pte_total_1st_attempt');
            const pteTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'pte_reading_1st_attempt');
            const pteReadingRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'duolingo_total_1st_attempt');
            const duolingoTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'duolingo_reading_1st_attempt');
            const duolingoReadingRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'ielts_total_1st_attempt');
            const ieltsTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'ielts_reading_1st_attempt');
            const ieltsReadingRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'gmat_total_1st_attempt');
            const gmatTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'gmat_quants_1st_attempt');
            const gmatQuantsRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'sat_total_1st_attempt');
            const satTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'sat_quant_1st_attempt');
            const satQuantsRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);
            // console.log('satquant',satQuantsRes)

            params.append('name', 'act_total_1st_attempt');
            const actTotalRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            params.append('name', 'act_quants_1st_attempt');
            const actQuantsRes = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params);

            setProgramList(response.data.data);
            setCountryList(countryRes.data.data);
            setSemesterList(semesterRes.data.data);
            setYearList(yearRes.data.data);
            setconstactStausList(leadstatusRes.data.data);
            setContactTypeList(contact_type.data.data);
            setbachelorCollegeList(bachelorClgRes.data.data)
            setbachelorYearList(bachelorYearRes.data.data)
            setpucCollegeList(pucClgRes.data.data)
            setpucYearList(pucYearRes.data.data)
            setgermanLangList(germanRes.data.data)
            setgreTotalList(greTotalRes.data.data)
            setgreQuantsList(greQuantsRes.data.data)
  
            settoeflTotalList(toeflTotalRes.data.data)
            settoeflReadingList(toeflReadingRes.data.data)
            setpteTotalList(pteTotalRes.data.data)
            setpteReadingList(pteReadingRes.data.data)
            setduolingoTotalList(duolingoTotalRes.data.data)
            setduolingoReadingList(duolingoReadingRes.data.data)
            setieltsTotalList(ieltsTotalRes.data.data)
            setieltsReadingList(ieltsReadingRes.data.data)
            setgmatTotalList(gmatTotalRes.data.data)
            setgmatQuantsList(gmatQuantsRes.data.data)
            setactTotalList(actTotalRes.data.data)
            setactQuantsList(actQuantsRes.data.data)
            setsatTotalList(satTotalRes.data.data)
            setsatQuantsList(satQuantsRes.data.data)
            setLoading(false)
        }
        setLoading(true)
        syncData();
        getRelatedProgramsPickList()
        getCountryPickList()
        getSemesterPickList()
        getYearPickList()
    }, [history?.location?.state?.StudentData,history?.location?.state?.StudentId])



    const fetchList = async (name) => {
        const params = new URLSearchParams();
        params.append('operation', 'fetchPicklist');
        params.append('name', name);
        const response = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", params
            , {
                'Access-Control-Allow-Origin': '*',
            })

        return response.data.data
    }

    const fetchNextPage = async() =>{

        // get next and back user data
        const newParams = new URLSearchParams()
        newParams.append('operation','get_next_back_user')
        newParams.append('contact_id',history?.location?.state?.StudentData?.contactid)
        const res = await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", newParams);
        // console.log(res)
        
        setNextStudent(res.data.data)
        
    }

    const updateProfileFunc = () => {
        
        let userId = inputData?.contactid || getUserId()
        // console.log(userId)
        let Programe = _.uniq(typeof inputData?.related_programs == "object" ? inputData?.related_programs : inputData?.related_programs.split("|##|"))
        let country = _.uniq(typeof inputData?.country == "object" ? inputData?.country : inputData?.country.split("|##|"))
        var data = JSON.stringify({
            "firstname": inputData?.firstname || '',
            "lastname": inputData?.lastname || '',
            "phone": inputData?.phone || '',
            "birthday": inputData?.birthday || '',
            "gender": inputData?.gender || '',
            "place_of_birth": inputData?.place_of_birth || '',
            "nationality": inputData?.nationality || '',
            "passport_no": inputData?.passport_no || '',
            "student_pan": inputData?.student_pan || '',
            "father_name": inputData?.father_name || '',
            "mother_name": inputData?.mother_name || '',
            "co_application_pan": inputData?.co_application_pan || '',
            "parent_mobile": inputData?.parent_mobile || '',
            "leadstatus": inputData?.leadstatus || '',
            "contact_type": inputData?.contact_type || '',
            "program": Programe,
            "country": country,
            "semester_looking_for": inputData?.semester_looking_for || '',
            "year_looking_for": inputData?.year_looking_for || '',
            "visa_date": inputData?.visa_date || '',
            "bachelors_percentage": inputData?.bachelors_percentage || '',
            "number_backlogs": inputData?.number_backlogs || '',
            "bachelors_college_name": inputData?.bachelors_college_name || '',
            "bachelors_major_name": inputData?.bachelors_major_name || '',
            "number_backlogs": inputData?.number_backlogs || '',
            "bachelors_year_of_passing": inputData?.bachelors_year_of_passing || '',
            "bachelors_subject": inputData?.bachelors_subject || '',
            "puc_percentage_12th": inputData?.puc_percentage_12th || '',
            "puc_subjects_12th": inputData?.puc_subjects_12th || '',
            "puc_college_name_12th": inputData?.puc_college_name_12th || '',
            "puc_year_of_passing_12th": inputData?.puc_year_of_passing_12th || '',
            "english_percentage_12th": inputData?.english_percentage_12th || '',
            "percentage_10th": inputData?.percentage_10th || '',
            "subjects_10th": inputData?.subjects_10th || '',
            "masters_percentage": inputData?.masters_percentage || '',
            "edu_budget": inputData?.edu_budget || '',
            "german_language": inputData?.german_language || '',
            "gre_awa": inputData?.gre_awa || '',
            "gmat_awa": inputData?.gmat_awa || '',
            "gre_total_1st_attempt": inputData?.gre_total_1st_attempt || '',
            "gre_quants_1st_attempt": inputData?.gre_quants_1st_attempt || '',
            "gre_verbal_1st_attempt": inputData?.gre_verbal_1st_attempt || '',
            "toefl_total_1st_attempt": inputData?.toefl_total_1st_attempt || '',
            "toefl_reading_1st_attempt": inputData?.toefl_reading_1st_attempt || '',
            "toefl_listening_1st_attempt": inputData?.toefl_listening_1st_attempt || '',
            "toefl_writing_1st_attempt": inputData?.toefl_writing_1st_attempt || '',
            "toefl_speaking_1st_attempt": inputData?.toefl_speaking_1st_attempt || '',
            "pte_total_1st_attempt": inputData?.pte_total_1st_attempt || '',
            "pte_reading_1st_attempt": inputData?.pte_reading_1st_attempt || '',
            "pte_listening_1st_attempt": inputData?.pte_listening_1st_attempt || '',
            "pte_writing_1st_attempt": inputData?.pte_writing_1st_attempt || '',
            "pte_speaking_1st_attempt": inputData?.pte_speaking_1st_attempt || '',
            "duolingo_total_1st_attempt": inputData?.duolingo_total_1st_attempt || '',
            "duolingo_reading_1st_attempt": inputData?.duolingo_reading_1st_attempt || '',
            "duolingo_listening_1st_attempt": inputData?.duolingo_listening_1st_attempt || '',
            "duolingo_writing_1st_attempt": inputData?.duolingo_writing_1st_attempt || '',
            "duolingo_speaking_1st_attempt": inputData?.duolingo_speaking_1st_attempt || '',
            "ielts_total_1st_attempt": inputData?.ielts_total_1st_attempt || '',
            "ielts_reading_1st_attempt": inputData?.ielts_reading_1st_attempt || '',
            "ielts_listening_1st_attempt": inputData?.ielts_listening_1st_attempt || '',
            "ielts_writing_1st_attempt": inputData?.ielts_writing_1st_attempt || '',
            "ielts_speaking_1st_attempt": inputData?.ielts_speaking_1st_attempt || '',
            "gmat_total_1st_attempt": inputData?.gmat_total_1st_attempt || '',
            "gmat_quants_1st_attempt": inputData?.gmat_quants_1st_attempt || '',
            "gmat_verbal_1st_attempt": inputData?.gmat_verbal_1st_attempt || '',
            "sat_total_1st_attempt": inputData?.sat_total_1st_attempt || '',
            "sat_quant_1st_attempt": inputData?.sat_quant_1st_attempt || '',
            "sat_verbal_1st_attempt": inputData?.sat_verbal_1st_attempt || '',
            "act_total_1st_attempt": inputData?.act_total_1st_attempt || '',
            "act_quants_1st_attempt": inputData?.act_quants_1st_attempt || '',
            "act_verbal_1st_attempt": inputData?.act_verbal_1st_attempt || '',
            "lor1_first_name": inputData?.lor1_first_name || '',
            "lor1_last_name": inputData?.lor1_last_name || '',
            "lor1_designation": inputData?.lor1_designation || '',
            "lor1_employer": inputData?.lor1_employer || '',
            "lor1_contact": inputData?.lor1_contact || '',
            "lor1_email": inputData?.lor1_email || '',
            "lor2_first_name": inputData?.lor2_first_name || '',
            "lor2_last_name": inputData?.lor2_last_name || '',
            "lor2_designation": inputData?.lor2_designation || '',
            "lor2_employer": inputData?.lor2_employer || '',
            "lor2_contact": inputData?.lor2_contact || '',
            "lor2_email": inputData?.lor2_email || '',
            "lor3_first_name": inputData?.lor3_first_name || '',
            "lor3_last_name": inputData?.lor3_last_name || '',
            "lor3_designation": inputData?.lor3_designation || '',
            "lor3_employer": inputData?.lor3_employer || '',
            "lor3_contact": inputData?.lor3_contact || '',
            "lor3_email": inputData?.lor3_email || '',
            "work_experience": inputData?.work_experience || '',
            "mailingstreet": inputData?.mailingstreet || '',
            "mailingpobox": inputData?.mailingpobox || '',
            "mailingcity": inputData?.mailingcity || '',
            "mailingstate": inputData?.mailingstate || '',
            "mailingzip": inputData?.mailingzip || '',
            "mailingcountry": inputData?.mailingcountry || '',
            "savefrom": "studentdetails",
            'degree_looking_for': inputData?.degree_looking_for || '',
            "related_to": userId
        });
        let config = {
            url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
            data: data,
            method: 'post'
        }
        axios(config).then((response) => {
            console.log(response)
            setOpen(true)
            setSuccess('Profile Updated Successfully');
            if (userId) {
                var formdata = new FormData();
                formdata.append(Contact_Id, userId);
                formdata.append(SESSION_ID, getSessionId())
                formdata.append(USER_ID, userId)
                formdata.append(OPERATION, 'fetch_profile_details')
                // fetchProfile
                formdata.append('validation', 'bypass')
                var config = {
                    method: 'post',
                    url: 'https://erp.bscglobaledu.com/mapi/JsonApi/',
                    data: formdata
                };
                axios(config)
                    .then((response) => {
                        // console.log(response)
                        if (response.status) {
                            setInputData(response?.data?.data[0])
                            // response?.data
                            getFavouriteUniversities(`All`, `Universities`,`${JSON.stringify(response?.data?.data[0])}`,true)
                            getParteneredUniversities(`All`, `Universities`,`${JSON.stringify(response?.data?.data[0])}`,true)
                            getMatchingUniversities(`All`, `Universities`,`${JSON.stringify(response?.data?.data[0])}`,true)
                            if (user_contact_type === "Student") {
                                setUser(response?.data?.data[0])
                            }
                        } else {
                            logout()
                        }
                    })
            }
        })
    }

    // useEffect(() => {
    //     return () => {
    //         localStorage.removeItem('selectedStudentData')
    //     }
    // }, [history?.location?.state?.StudentData])

    useEffect(() => {
        let formNo = history?.location?.state?.formNo || 1
        setStateFormNo(formNo)
    }, [history?.location?.state?.StudentData])


    useEffect(() => {
        fetchNextPage()
        var data = `{\n"student_id":"${getUserId()}","bypass":"validation"\n}`;
        MentorDocumentDetailAction(data).then((response) => {
            setmentorData(response)
        }).catch((error) => {
            setmentorData([])
        })
    }, [history?.location?.state?.StudentData])

    const SubmitFilter = () => {
    }

    const getContactId = (DataId) => {
        var data = JSON.stringify({
            "contact_no": "",
            "status": "All",
            "year": "All",
            "semester": "All",
            "daterange": "",
            "limit_start": 0,
            "limit_end": 9,
            "search": DataId,
            "bypass": "validation"
        });
        getStudentCountListUsingStatusAction1(data).then((Data) => {
            // console.log(Data)
            if (Data?.data?.total > 0) {
                let contact_no = Data?.data?.list[0]
                setInputData({ ...contact_no })
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }


    let looking_for = 0;

    // console.log(inputData)
    if(inputData?.degree_looking_for !== ''){
        looking_for = looking_for + 20
    }

    if(inputData?.country !== ''){
        looking_for = looking_for + 20
    }

    if(inputData?.semester_looking_for !== ''){
        looking_for = looking_for + 20
    }

    if(inputData?.year_looking_for !== ''){
        looking_for = looking_for + 20
    }

    if(inputData?.related_programs !== ''){
        looking_for = looking_for + 20
    }

    let personal_percentage = 0

    if(inputData?.firstname !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.lastname !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.birthday !== '' || inputData?.birthday !== '0000-00-00'){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.place_of_birth !== ''){
        personal_percentage = personal_percentage + 6.66
    }
    
    if(inputData?.nationality !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.passport_no !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.gender !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.work_experience !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.father_name !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.parent_mobile !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.student_pan !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.co_application_pan !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.mobile !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.homephone !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(inputData?.homephone !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    // academic percentage
    let academic_percentage = 0

    if(inputData?.bachelors_college_name !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.bachelors_year_of_passing !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.bachelors_percentage !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.number_backlogs !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.bachelors_subject !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.puc_percentage_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.puc_subjects_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.puc_college_name_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.puc_year_of_passing_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(inputData?.german_language !== ''){
        academic_percentage = academic_percentage + 10
    }

    // test scores percentage
    let test_score = 0

    if(inputData?.toefl_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.toefl_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.toefl_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.toefl_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.toefl_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.pte_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.pte_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.pte_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.pte_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.pte_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.ielts_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.ielts_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.ielts_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.ielts_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.ielts_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.duolingo_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.duolingo_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.duolingo_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.duolingo_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.duolingo_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gre_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gre_awa !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gre_verbal_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gre_quants_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gmat_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gmat_awa !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gmat_verbal_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gmat_quants_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(inputData?.gmat_quants_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    // recommend percentage

    let recommend_score = 0

    if(inputData?.lor1_first_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor1_last_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor1_designation !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor1_employer !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor1_contact !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor1_email !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor2_first_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor2_last_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor2_designation !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor2_employer !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor2_contact !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor2_email !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor3_first_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor3_last_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor3_designation !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor3_employer !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor3_contact !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(inputData?.lor3_email !== ''){
        recommend_score = recommend_score + 5.55
    }


    
    // console.log("-=-=-=-=", inputData?.related_programs)
    let related_programs = typeof inputData?.related_programs == "object" ? inputData?.related_programs : inputData?.related_programs?.split("|##|")
    related_programs = _.map(related_programs, (d) => d.trim())
    let country = typeof inputData?.country == "object" ? inputData?.country : inputData?.country?.split("|##|")
    country = _.map(country, (d) => d.trim())
    
    if (loading) {
        // return <div style={{ textAlign: 'center', marginTop: '55%' }}>
        //             <CircularProgress />
        //         </div>
        return <div className="gooey">
            <span className="dot"></span>
            <div className="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    } else {
        return (
            <div>
                {user_contact_type !== "Student" &&
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button sx={{ minWidth: '0px', paddingLeft: '0px' }} onClick={() => history.goBack()}><ArrowBackIcon /></Button>
                        <Button sx={{ minWidth: '0px', paddingLeft: '0px' }}
                         onClick={() => 
                            history.replace({ pathname: '/Profile', state: { StudentData: nextStudent, formNo: 1 } })
                        }
                        // onClick={()=>console.log(nextStudent)}
                         >Next Student<ArrowForwardSharpIcon /></Button>
                    </div>
                }
                <div style={{ position: "relative", marginBottom: 20 }}>
                    {user_contact_type !== "Student" && <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <AsyncMulti inputData={inputData} setSearchSelectId={getContactId} SubmitFilter={SubmitFilter} hideFilter="yes" />
                    </div>}
                </div>


                {/* not showing to student */}
                {user_contact_type !== "Student" &&
                    <FormControl className="mt-4" component="fieldset" sx={{ m: 1, }} style={{ width: "100%" }}>
                        <RadioGroup
                            onChange={(d) => {
                                setTab(d.target.value)
                            }}
                            style={{ whiteSpace: "nowrap", display: "block", overflowX: "scroll", width: "100%", height: 50, }}
                            className={`${classes.radioGroup} `}
                            row name="use-radio-group"
                            defaultValue={Tab}>
                            {["Profile", "Shortlisted", "Applications", "Document", "Comments"].map((d) => {
                                return <MyFormControlLabel
                                    className={"radioLabel radioLabel1"}
                                    value={d}
                                    label={d}
                                    style={{ padding: "5px 17px", marginBottom: 5 }}
                                    control={<Radio className={"radioRadioMain"} style={{ display: "none" }} />}
                                />
                            })}
                        </RadioGroup>
                    </FormControl>
                }


                {user_contact_type === "Student" &&
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingTop: 2,
                            paddingBottom: 2,
                            alignItems: "center"
                        }}
                        >
                        <Grid item xs={4}>
                            <h3 className="student_title m-0">Hi {inputData?.firstname || ''} {inputData?.lastname || ''}</h3>
                        </Grid>

                        <Grid item xs={8} sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingTop: 2,
                            paddingBottom: 2,
                            alignItems: "center"
                        }} >
                            <span style={{ fontSize: 17, fontWeight: "bold", color: "rgba(27, 108, 252)" }}>{mentorData?.data?.mentor?.name || ''}</span>
                            <div
                                onClick={() => {
                                    window.open(`tel:${mentorData?.data?.mentor?.mobile}`);
                                }}
                                style={{
                                    marginRight: 2,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 30,
                                    height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                }}
                            >
                                <img src={`${Calling}`} style={{ height: 30, width: 30 }} />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 30,
                                    height: 48,
                                    width: 48,
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                }}
                            >
                                <a href={`https://api.whatsapp.com/send?phone=${mentorData?.data?.mentor?.mobile || ''}&text=Hi`} target="_blank"> <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                            </div>
                        </Grid>
                    </Grid>
                }
                {Tab === "Profile" ?
                    <div className="row m-0 ">
                        {user_contact_type !== "Student" &&
                    
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    alignItems: "center"
                                }}>
                                <Grid item xs={6}>
                                    {/* <h3 className="student_title m-0">{inputData?.firstname || ''} {inputData?.lastname || ''}</h3> */}
                                    <Chip color="success" label={`${inputData?.firstname} ${' '} ${inputData?.lastname}`} />
                                    {/* <br/> */}
                                    <span style={{ fontSize: 14, color: "rgba(27, 108, 252)" }}>{mentorData?.data?.mentor?.name || ''}</span>
                                    
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'end', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* <span style={{ fontSize: 17, fontWeight: "bold", color: "rgba(27, 108, 252)" }}>{mentorData?.data?.mentor?.name || ''}</span> */}
             <div
            style={{
              marginLeft: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
              height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
            }}>
          <CommentIcon onClick={()=>setModalOpen(true)} sx={{fontSize:'30px', color:'#00acee'}} />
          </div>
                                    <ChatModal open={modalOpen} onClose={(handleModalOpenAndClose)} val={inputData} refreshPage={setRefresh} programList={programList} countryList={countryList} semesterList={semesterList} />
                                    <div
                                        onClick={() => {
                                            window.open(`tel:+${inputData?.mobile}`);
                                        }}
                                        style={{
                                            marginRight: 2,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                        }}>
                                        <img src={`${Calling}`} style={{ height: 30, width: 30 }} />
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 30,
                                        height: 48,
                                        width: 48,
                                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                    }}>
                                        <a target="_blank" href={`https://api.whatsapp.com/send?phone=${inputData?.mobile || ''}&text=Hi`}> <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                                    </div>
                                </Grid>
                            </Grid>
                        
                        }
                        <div className="col-12 slidingSection p-0">

                            {FormNo == "1" ?
                                <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample1" style={{ minWidth: "100% !important" }}>


                                    <div className="mt-2" style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                                        <span></span>
                                        <span style={{ marginBottom: 0, color: "white",position:'relative' }}> <Chip label="Personal" color="warning" /> 
                                         {
                                        getUserType() !== 'Student' 
                                        ?
                                        <><Typography style={{ position: "absolute", right: "-32px", bottom: "7px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(personal_percentage)}%</Typography>
                                        <CircularProgress  variant="determinate" color={barColor(Math.round(personal_percentage))}  value={Math.round(personal_percentage)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", right: "-35px"}} />
                                        </>
                                        :
                                        ''
                                         }
                                        </span>
                                        <span style={{ color: "white" }} onClick={() => {
                                            setStateFormNo(FormNo + 1)
                                            history.push({
                                                pathname: '/Profile',
                                                state: {
                                                    'formNo': FormNo + 1,
                                                    StudentData: inputData
                                                }
                                            })
                                        }}>     Next</span>
                                    </div>
                                    <div style={{ background: "white" }} id="flush-collapseOne-1" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne-1" data-bs-parent="#accordionFlushExample1">
                                        <div class="accordion-body">
                                            <div className="row m-0">
                                                <div className="col-12 p-0">

                                                    <FormControl fullWidth sx={{ mt: 1.5 }}>
                                                        <div style={{ width: "100%", display: "flex" }}>

                                                        <TextField
                                                            label="First Name"
                                                            variant="outlined"
                                                            onChange={(e) => onChangeHandler("firstname", e.target.value)}
                                                            value={inputData['firstname']}
                                                            />
                                                            {/* <input type="text"
                                                                class="form-control inputField"
                                                                name="firstname"
                                                                id="firstname"
                                                                value={inputData['firstname']}
                                                                onChange={(e) => onChangeHandler("firstname", e.target.value)}
                                                                placeholder="First Name" /> */}

                                                        <TextField
                                                            label="Last Name"
                                                            variant="outlined"
                                                            value={inputData['lastname']}
                                                            onChange={(e) => onChangeHandler("lastname", e.target.value)}
                                                            />
                                                            {/* <input type="text"
                                                                class="form-control inputField"
                                                                name="lastname"
                                                                id="lastname"
                                                                value={inputData['lastname']}
                                                                onChange={(e) => onChangeHandler("lastname", e.target.value)}
                                                                placeholder="Last Name" /> */}

                                                        </div>

                                                        


                                                        <div style={{ width: "100%",marginTop:'15px', display: "flex" }}>
                                            
                                                        {/* <LocalizationProvider  dateAdapter={AdapterDateFns}>
                                                            <Stack spacing={3} >
                                                            <MobileDatePicker
                                                            label="Date of Birth"
                                                            inputFormat="dd/MM/yyyy"
                                                            value={inputData['birthday']}
                                                            onChange={(e) => onChangeHandler("birthday", e.target.value)}
                                                            renderInput={(params) => <TextField  fullWidth   label="Date of Birth" {...params} />}
                                                            clearable={true}
                                                            fullWidth
                                                            />
                                                            </Stack> 


                                                            </LocalizationProvider>*/}
                                                            <div style={{position:'relative'}}>
                                                            <label class="form-label" style={{position:'absolute',fontSize:'13px',marginLeft:'15px', backgroundColor:'#fff',top:'-8px'}}>Date Of Birth</label>
                                                        
                                                            <input type="date"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '56px',
                                                                    border: '1px solid #DCE8FF',
                                                                    borderRadius: '24px'
                                                                }}
                                                                name="birthday"
                                                                id="birthday"
                                                                value={inputData['birthday']}
                                                                onChange={(e) => onChangeHandler("birthday", e.target.value)}
                                                                placeholder="Date of Birth" />
                                                            </div>
                                                            <TextField
                                                            label="Place of Birth"
                                                            variant="outlined"
                                                            value={inputData['place_of_birth']}
                                                            onChange={(e) => onChangeHandler("place_of_birth", e.target.value)}
                                                            fullWidth
                                                            />
                                                            {/* <input type="text"
                                                                class="form-control inputField"
                                                                name="place_of_birth"
                                                                id="place_of_birth"
                                                                value={inputData['place_of_birth']}
                                                                onChange={(e) => onChangeHandler("place_of_birth", e.target.value)}
                                                                placeholder="Place of Birth" /> */}
                                                        </div>


                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>

                                                            <div style={{ width: "48%" }}>
                                                            {/* countryRes */}
                                                            {/* <TextField
                                                            label="Nationality"
                                                            variant="outlined"
                                                            value={inputData['nationality']}
                                                            onChange={(e) => onChangeHandler("nationality", e.target.value)}
                                                            /> */}

<FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['nationality']}
                                                                onChange={(e) => onChangeHandler("nationality", e.target.value)}
                                                                label="Nationality"
                                                             
                                                                >
                                                                {
                                                                    countryList?.map((item)=>{
                                                                    return <MenuItem value={item}>{item}</MenuItem>
                                                                    })
                                                                }
                                                                
                                                            
                                                                </Select>
                                                            </FormControl>
                                                                {/* <input type="text"
                                                                    class="form-control inputField"
                                                                    name="nationality"
                                                                    id="nationality"
                                                                    value={inputData['nationality']}
                                                                    onChange={(e) => onChangeHandler("nationality", e.target.value)}
                                                                    placeholder="Nationality" /> */}
                                                            </div>
                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            label="Passport No"
                                                            variant="outlined"
                                                            value={inputData['passport_no']}
                                                            onChange={(e) => onChangeHandler("passport_no", e.target.value)}
                                                            />
                                                                {/* <input type="text"
                                                                    class="form-control inputField"
                                                                    name="passport_no"
                                                                    id="passport_no"
                                                                    value={inputData['passport_no']}
                                                                    onChange={(e) => onChangeHandler("passport_no", e.target.value)}
                                                                    placeholder="Passport No" /> */}
                                                            </div>
                                                        </div>
                                                       
                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>
                                                            <div style={{ width: "48%" }}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gender']}
                                                                onChange={(e) => 
                                                                    // console.log(e.target.value)
                                                                    onChangeHandler("gender", e.target.value)
                                                                }
                                                                label="Gender"
                                                             
                                                                >
                                                                <MenuItem value="">Gender</MenuItem>
                                                                <MenuItem value="Male">Male</MenuItem>
                                                                <MenuItem value="Female">Female</MenuItem>
                                                                <MenuItem value="Other"> Other</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                                {/* <select class="form-select inputField"
                                                                    aria-label="Default select example"
                                                                    name="gender"
                                                                    id="gender"
                                                                    value={inputData['gender']}
                                                                    onChange={(e) => onChangeHandler("gender", e.target.value)}
                                                                >
                                                                    <option className="selectOpt" selected>Gender</option>
                                                                    <option className="selectOpt" value="Male">Male</option>
                                                                    <option className="selectOpt" value="Female">Female</option>
                                                                    <option className="selectOpt" value="Other">Other</option>
                                                                </select> */}
                                                            </div>
                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            label="Work Experience"
                                                            variant="outlined"
                                                            value={inputData['work_experience']}
                                                            onChange={(e) => onChangeHandler("work_experience", e.target.value)}
                                                            />
        
                                                            </div>
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>
                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            label="Parents Name"
                                                            variant="outlined"
                                                            value={inputData['father_name']}
                                                            onChange={(e) => onChangeHandler("father_name", e.target.value)}
                                                            />
                                            
                                                            </div>
                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            label="Parents Mobile"
                                                            variant="outlined"
                                                            value={inputData['parent_mobile']}
                                                            onChange={(e) => onChangeHandler("parent_mobile", e.target.value)}
                                                            />
                                                            
                                                            </div>
                                                        </div>

                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>
                                                        <div style={{ width: "48%" }}>
                                                        <TextField
                                                            label="PAN Card"
                                                            variant="outlined"
                                                            value={inputData['student_pan']}
                                                            onChange={(e) => onChangeHandler("student_pan", e.target.value)}
                                                            fullWidth
                                                            />
                                                            </div>
                                                        <div style={{ width: "48%" }}>
                                                        <TextField
                                                            label="Co Applicant PAN"
                                                            variant="outlined"
                                                            value={inputData['co_application_pan']}
                                                            onChange={(e) => onChangeHandler("co_application_pan", e.target.value)}
                                                            fullWidth
                                                            />
                                                            </div>
                                                        </div>
                                                        {/* <input type="text"
                                                            class="form-control inputField"
                                                            name="invitee_contact_id"
                                                            id="invitee_contact_id"
                                                            disabled
                                                            value={inputData['invitee_contact_id']}
                                                            //    onChange={(e) => onChangeHandler("invitee_contact_id", e.target.value)}
                                                            placeholder="Invite Contact Id" /> */}

                                                <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>
                                                        <div style={{ width: "48%" }}>
                                                        <input type="text"
                                                            class="form-control inputField"
                                                            name="mobile"
                                                            id="mobile"
                                                            disabled
                                                            value={inputData['mobile']}
                                                            //    onChange={(e) => onChangeHandler("mobile", e.target.value)}
                                                            placeholder="Contact ID" />
                                                            </div>
                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            label="Home Phone"
                                                            variant="outlined"
                                                    
                                                            value={inputData['homephone']}
                                                            onChange={(e) => onChangeHandler("homephone", e.target.value)}
                                                            
                                                            />
                                                       
                                                            </div>
                                                            </div>

                                                        {/* <input type="text"
                                                            class="form-control inputField"
                                                            name="mobile"
                                                            id="mobile"
                                                            disabled
                                                            value={getUserId()}
                                                            //    onChange={(e) => onChangeHandler("mobile", e.target.value)}
                                                            placeholder="Contact ID" /> */}

                                                        <input type="text"
                                                            class="form-control inputField"
                                                            name="email"
                                                            id="email"
                                                            disabled
                                                            value={inputData['email']}
                                                            onChange={(e) => onChangeHandler("email", e.target.value)}
                                                            placeholder="Email ID" />

                                                        {user_contact_type !== "Student" &&<div class="input-group mb-2 mt-2">
                                                            <label class="form-label">Contact Status</label>
                                                            <select class="form-select inputField selectOpt"
                                                                aria-label="Default select example"
                                                                name="leadstatus"
                                                                id="leadstatus"
                                                                value={inputData['leadstatus']}
                                                                onChange={(e) => onChangeHandler("leadstatus", e.target.value)}
                                                            >

                                                                <option className="selectOpt" selected>Contact Status</option>
                                                                {contactStatusList && contactStatusList.map(o => <option className="selectOpt" value={o}>{o}</option>)}

                                                            </select>
                                                            </div>
                                                        }
                                                        {user_contact_type !== "Student" &&<div class="input-group mb-2 mt-2">
                                                            <label class="form-label">Contact Type</label>
                                                            <select class="form-select inputField selectOpt"
                                                                aria-label="Default select example"
                                                                name="contact_type"
                                                                id="contact_type"
                                                                value={inputData['contact_type']}
                                                                onChange={(e) => onChangeHandler("contact_type", e.target.value)}
                                                            >

                                                                <option className="selectOpt" selected>Contact Type</option>
                                                                {contactTypeList && contactTypeList.map(o => <option className="selectOpt" value={o}>{o}</option>)}

                                                            </select>
                                                            </div>
                                                        }
                                                        {/* <input type="text"
                                                            class="form-control inputField"
                                                            placeholder="Referral Code"
                                                            name="referral_code"
                                                            id="referral_code"
                                                            disabled
                                                            value={inputData['referral_code']}
                                                        //    onChange={(e) => onChangeHandler("referral_code", e.target.value)}
                                                        /> */}

                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px' , justifyContent: "space-between", position:'relative' }}>
                                                        <label class="form-label" style={{position:'absolute',fontSize:'13px',marginLeft:'15px', backgroundColor:'#fff'}}>BSC Email</label>
                                                        
                                                        <input type="text"
                                    
                                                            disabled={user_contact_type !== "Student" ? false : true}
                                                            value={inputData['BSC_Email']}
                                                           
                                                            // placeholder="BSC Email"
                                                            class="form-control inputField"
                                                            />
                                                        {/* <TextField
                                                            label="BSC Email"
                                                            variant="outlined"
                                                            disabled={user_contact_type !== "Student" ? false : true}
                                                            fullWidth
                                                            // sx={{backgroundColor:'#e9ecef'}}
                                                            className={classes.input}
                                                            /> */}
                                                        
                                                        </div>
                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>
                                                            <div style={{ width: "100%",position:'relative' }}>
                                                            <label class="form-label" style={{position:'absolute',fontSize:'13px',marginLeft:'15px', backgroundColor:'#fff'}}>BSC Password</label>
                                                        
                                                        <input type="text"
                                    
                                                            disabled={user_contact_type !== "Student" ? false : true}
                                                            value={inputData['BSC_PASSWORD']}
                        
                                                            // placeholder="BSC Password"
                                                            class="form-control inputField"
                                                            />
                                                            {/* <TextField
                                                            label="BSC Password"
                                                            variant="outlined"
                                                            disabled={user_contact_type !== "Student" ? false : true}
                                                            value={inputData['BSC_PASSWORD']}
                                                            // sx={{backgroundColor:'#e9ecef'}}
                                                            className={classes.input}
                                                            /> */}
                                                          
                                                            </div>
                                                            
                                                        </div>
                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px' , justifyContent: "space-between", position:'relative' }}>
                                                        <div style={{ width: "48%",position:'relative' }}>
                                                        <label class="form-label" style={{position:'absolute',fontSize:'13px',marginLeft:'15px', backgroundColor:'#fff',top:'-8px'}}>Visa Date</label>
                                                        
                                                        <input type="date"
                                                                  style={{
                                                                    width: '100%',
                                                                    height: '56px',
                                                                    border: '1px solid #DCE8FF',
                                                                    borderRadius: '24px'
                                                                }}
                                                                placeholder="Visa Date "
                                                                name="visa_date"
                                                                id="visa_date"
                                                                value={inputData['visa_date']}
                                                                onChange={(e) => onChangeHandler("visa_date", e.target.value)}
                                                            />
                                                            </div>
                                                            <div style={{ width: "48%",position:'relative' }}>
                                                            <label class="form-label" style={{position:'absolute',fontSize:'13px',marginLeft:'15px', backgroundColor:'#fff',top:'-8px'}}>Visa Time</label>
                                                        
                                                            <input type="time"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '56px',
                                                                        border: '1px solid #DCE8FF',
                                                                        borderRadius: '24px'
                                                                    }}
                                                                    placeholder="Visa Date "
                                                                    // name="visa_date"
                                                                    // id="visa_date"
                                                                    // value={inputData['visa_date']}
                                                                    // onChange={(e) => onChangeHandler("visa_date", e.target.value)}
                                                                />
                                                                </div>
                                                        </div>
                                                    </FormControl>



                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{ width: "48%" }}>

                                                            <div class="form-check checkBox">
                                                                <input class="form-check-input"
                                                                    type="checkbox"
                                                                    name="emailoptout"
                                                                    id="emailoptout"
                                                                    value={inputData['emailoptout']}
                                                                    onChange={(e) => onChangeHandler("emailoptout", e.target.value)} />
                                                                <label class="form-check-label" for="flexCheckDefault">Email Opt Out</label>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "48%" }}>
                                                            <div class="form-check checkBox">
                                                                <input class="form-check-input"
                                                                    type="checkbox"
                                                                    name="donotcall"
                                                                    id="donotcall"
                                                                    // checked={inputData['donotcall'] === "Yes" ? true : false}
                                                                    value={inputData['donotcall']}
                                                                    onChange={(e) => 
                                                                        // console.log(e.target.value)
                                                                        onChangeHandler("donotcall", e.target.value)
                                                                    }
                                                                />
                                                                <label class="form-check-label" for="flexCheckDefault">Do not Call</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="submitBtn mt-4" onClick={() => updateProfileFunc()}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : FormNo == "2" ?
                                    <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample2">
                                        <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                                            <span style={{ color: "white" }} onClick={() => {
                                                setStateFormNo(FormNo - 1)
                                                history.push({
                                                    pathname: '/Profile',
                                                    state: {
                                                        'formNo': FormNo - 1,
                                                        StudentData: inputData
                                                    }
                                                })
                                            }}>     Prev</span>
                                            <span style={{position:'relative'}}>
                                            <Chip label="Looking For" color="warning"  />
                                            {
                                            getUserType() !== 'Student' 
                                            ?
                                            <><Typography style={{ position: "absolute", right: "-32px", bottom: "7px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(looking_for)}%</Typography>
                                            <CircularProgress  variant="determinate" color={barColor(Math.round(looking_for))}  value={Math.round(looking_for)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", right: "-35px"}} />
                                            </>
                                            :
                                            ''
                                            }
                                            </span>
                                            {/* <span style={{ marginBottom: 0, color: "white" }}>  Looking For </span> */}
                                            <span style={{ color: "white" }} onClick={() => {
                                                setStateFormNo(FormNo + 1)
                                                history.push({
                                                    pathname: '/Profile',
                                                    state: {
                                                        'formNo': FormNo + 1,
                                                        StudentData: inputData
                                                    }
                                                })
                                            }}> Next</span>
                                        </div>


                                        <div style={{ background: "white" }} id="flush-collapseOne-2" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne-2" data-bs-parent="#accordionFlushExample2">


                                            <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                <div className="col-6 ps-0 pe-1" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Degree</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData?.degree_looking_for || ''}
                                                                onChange={(e) => onChangeHandler("degree_looking_for", e.target.value)}
                                                                label="Degree"
                                                             
                                                                >
                                                                <MenuItem >Degree</MenuItem>
                                                                <MenuItem value="Masters">Masters</MenuItem>
                                                                <MenuItem value="Diploma">Diploma</MenuItem>
                                                                <MenuItem value="Bachelors">Bachelors</MenuItem>
                                                                <MenuItem value="Phd">Phd</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                    {/* <select class="form-select inputField"
                                                        aria-label="Default select example"
                                                        name="degree_looking_for"
                                                        id="degree_looking_for"
                                                        value={inputData?.degree_looking_for || ''}
                                                        onChange={(e) => onChangeHandler("degree_looking_for", e.target.value)}
                                                    >
                                                        <option selected>Select Degree</option>
                                                        {["Masters", "Diploma", "Bachelors", "Phd"].map(o => <option value={o}>{o}</option>)}
                                                    </select> */}
                                                </div>
                                                <div className="col-6 ps-0 pe-1">

                                                    <Autocomplete
                                                        style={{ width: "100%", padding: 0 }}
                                                        name='Country'
                                                        freeSolo
                                                        multiple
                                                        onChange={(e, value) => {
                                                            onChangeHandler("country", value)
                                                        }}
                                                        value={country}
                                                        options={countryList && countryList.map((option) => option)}
                                                        renderInput={(params) => <main className={classes.inputContainer} style={{ width: "100%" }}>
                                                            <TextField  {...params} className={classes.input} label="Country" />
                                                        </main>}
                                                    />
                                                    {/* <select class="form-select inputField"
                                                        aria-label="Default select example"
                                                        name="country"
                                                        id="country"
                                                        value={inputData['country']}
                                                        onChange={(e) => onChangeHandler("country", e.target.value)}
                                                    >
                                                        <option selected>Select Country</option>
                                                        {countryList && countryList.map(o => <option value={o}>{o}</option>)}
                                                    </select> */}
                                                </div>
                                            </div>



                                            <div className="row  d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                <div className="col-6 ps-0 pe-1">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={inputData['semester_looking_for']}
                                                    onChange={(e) => onChangeHandler("semester_looking_for", e.target.value)}
                                                    label="Semester"
                                                    
                                                    >
                                                    <MenuItem >Semester</MenuItem>
                                                    {semesterList && semesterList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
                                                
                                                    </Select>
                                                </FormControl>
                                                    {/* <select class="form-select inputField"
                                                        aria-label="Default select example"
                                                        name="semester_looking_for"
                                                        id="semester_looking_for"
                                                        value={inputData['semester_looking_for']}
                                                        onChange={(e) => onChangeHandler("semester_looking_for", e.target.value)}
                                                    >
                                                        <option selected>Semester</option>
                                                        {semesterList && semesterList.map(o => <option value={o}>{o}</option>)}
                                                    </select> */}
                                                </div>
                                                <div className="col-6 pe-0 ps-1">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={inputData['year_looking_for']}
                                                    onChange={(e) => onChangeHandler("year_looking_for", e.target.value)}
                                                    label="Year"
                                                    
                                                    >
                                                    <MenuItem >Year</MenuItem>
                                                  {yearList && yearList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
                                                
                                                    </Select>
                                                </FormControl>
                                                    {/* <select class="form-select inputField"
                                                        aria-label="Default select example"
                                                        name="year_looking_for"
                                                        id="year_looking_for"
                                                        value={inputData['year_looking_for']}
                                                        onChange={(e) => onChangeHandler("year_looking_for", e.target.value)}
                                                    >
                                                        <option selected>Year</option>
                                                        {yearList && yearList.map(o => <option value={o}>{o}</option>)}
                                                    </select> */}
                                                </div>
                                            </div>
                                            <div className="col-12 ps-0 pe-1">

                                                <Autocomplete
                                                    style={{ width: "100%", padding: 0 }}
                                                    name='program'
                                                    freeSolo
                                                    multiple
                                                    onChange={(e, value) => {
                                                        onChangeHandler("related_programs", value)
                                                    }}
                                                    value={related_programs}
                                                    options={programList && programList.map((option) => option)}
                                                    renderInput={(params) => <main className={classes.inputContainer} style={{ width: "100%" }}>
                                                        <TextField  sx={{marginTop:'15px'}} {...params} className={classes.input} label="program" />
                                                    </main>}
                                                />
                                                {/* <select class="form-select inputField"
                                                    aria-label="Default select example"
                                                    name="program"
                                                    id="related_programs"
                                                    value={inputData?.related_programs || inputData?.program}
                                                    onChange={(e) => onChangeHandler("related_programs", e.target.value)}
                                                >
                                                    <option selected>Select Program</option>
                                                    {programList && programList.map(o => <option value={o}>{o}</option>)}
                                                </select> */}
                                            </div>
                                            <button className="submitBtn mt-4" onClick={() => updateProfileFunc()}>Submit</button>
                                        </div>

                                    </div>
                                    : FormNo == "3" ?
                                        <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample3">
                                            <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                                                <span style={{ color: "white" }} onClick={() => {
                                                    setStateFormNo(FormNo - 1)
                                                    history.push({
                                                        pathname: '/Profile',
                                                        state: {
                                                            'formNo': FormNo - 1,
                                                            StudentData: inputData
                                                        }
                                                    })
                                                }}>     Prev</span>
                                                <span style={{position:'relative'}}>
                                                <Chip label="Academic" color="warning"/>
                                                {
                                                getUserType() !== 'Student' 
                                                ?
                                                <><Typography style={{ position: "absolute", right: "-32px", bottom: "7px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(academic_percentage)}%</Typography>
                                                <CircularProgress  variant="determinate" color={barColor(Math.round(academic_percentage))}  value={Math.round(academic_percentage)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", right: "-35px"}} />
                                                </>
                                                :
                                                ''
                                                }
                                                </span>
                                                <span style={{ color: "white" }} onClick={() => {
                                                    setStateFormNo(FormNo + 1)
                                                    history.push({
                                                        pathname: '/Profile',
                                                        state: {
                                                            'formNo': 4,
                                                            StudentData: inputData
                                                        }
                                                    })
                                                }}> Next</span>
                                            </div>

                                            <div style={{ background: "white" }} id="flush-collapseOne-3" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne-3" data-bs-parent="#accordionFlushExample3">
                                                <div class="accordion-body">
                                                
                                                    <div className="row m-0">
                                                        <div className="col-12 p-0">
                                                        {
                                                inputData?.degree_looking_for === 'Bachelors' ? 
                                                        ''
                                                        :
                                                        <>
                                                        <Chip label="Bachelors Detail" color="secondary" /> 
                                                        <FormControl fullWidth sx={{marginTop:'15px'}}>
                                                            <InputLabel id="demo-simple-select-label">Bachelors College</InputLabel>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={inputData['bachelors_college_name']}
                                                            onChange={(e) => onChangeHandler("bachelors_college_name", e.target.value)}
                                                            label="Bachelors College"
                                                            
                                                            >
                                                            <MenuItem >Bachelors College</MenuItem>
                                                        {bachelorCollegeList && bachelorCollegeList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
          
                                                            </Select>
                                                        </FormControl>
                                                            {/* <select class="form-select inputField"
                                                                aria-label="Default select example"
                                                                name="bachelors_college_name"
                                                                id="bachelors_college_name"
                                                                value={inputData['bachelors_college_name']}
                                                                onChange={(e) => onChangeHandler("bachelors_college_name", e.target.value)}
                                                            >
                                                                <option className="selectOpt" selected>Bachelors College</option>
                                                                {bachelorCollegeList && bachelorCollegeList.map((o) => <option value={o}>{o}</option>)}

                                                            </select> */}
                                                    <FormControl fullWidth sx={{marginTop:'15px'}}>
                                                            <InputLabel id="demo-simple-select-label">Year of Passing</InputLabel>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={inputData['bachelors_year_of_passing']}
                                                            onChange={(e) => onChangeHandler("bachelors_year_of_passing", e.target.value)}
                                                            label="Bachelor Year of Passing"
                                                            
                                                            >
                                                            <MenuItem >Year of Passing</MenuItem>
                                                        {bachelorYearList && bachelorYearList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
          
                                                            </Select>
                                                        </FormControl>
                                                            {/* <select class="form-select inputField"
                                                                aria-label="Default select example"
                                                                name="bachelors_year_of_passing"
                                                                id="bachelors_year_of_passing"
                                                                value={inputData['bachelors_year_of_passing']}
                                                                onChange={(e) => onChangeHandler("bachelors_year_of_passing", e.target.value)}
                                                            >
                                                                <option className="selectOpt" selected>Bachelor Year of Passing</option>
                                                                {bachelorYearList && bachelorYearList.map((o) => <option value={o}>{o}</option>)}

                                                            </select> */}
                                                        <div style={{ width: "100%", display: "flex",marginTop:'15px', justifyContent: "space-between" }}>

                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            sx={{marginTop:'15px'}}
                                                            label="Bachelors Percentage"
                                                            variant="outlined"
                                                            type="number"
                                                            value={inputData['bachelors_percentage']}
                                                            onChange={(e) => {
                                                                if(e.target.value <= 100){
                                                                onChangeHandler("bachelors_percentage", e.target.value) 
                                                                }
                                                            }      
                                                            }
                                                            fullWidth
                                                            
                                                            />
                                                            </div>

                                                            <div style={{ width: "48%" }}>
                                                            <TextField
                                                            sx={{marginTop:'15px'}}
                                                            label="No of Backlogs"
                                                            variant="outlined"
                                                            type="number"
                                                            value={inputData['number_backlogs']}
                                                            onChange={(e) => onChangeHandler("number_backlogs", e.target.value)}
                                                            fullWidth
                                                            InputProps={{ inputProps: { min: 40, max: 100 } }}
                                                            />
                                                            </div>
                                                            </div>
                                                            {/* <input type="number"
                                                                class="form-control inputField"
                                                                placeholder="Bachelors Percentage"
                                                                name="bachelors_percentage"
                                                                id="bachelors_percentage"
                                                                value={inputData['bachelors_percentage']}
                                                                onChange={(e) => onChangeHandler("bachelors_percentage", e.target.value)}
                                                            /> */}

                                                             <TextField
                                                             sx={{marginTop:'15px'}}
                                                            label="Bachelors Subject"
                                                            variant="outlined"
                                                            type="number"
                                                            value={inputData['bachelors_subject']}
                                                            onChange={(e) => onChangeHandler("bachelors_subject", e.target.value)}
                                                            fullWidth
                                                            />
                                                            {/* <input type="text"
                                                                class="form-control inputField"
                                                                placeholder="Bachelors Subject"
                                                                name="bachelors_subject"
                                                                id="bachelors_subject"
                                                                value={inputData['bachelors_subject']}
                                                                onChange={(e) => onChangeHandler("bachelors_subject", e.target.value)}
                                                            /> */}
                                                            </>
                                                            
                                                        }
                                                            
                                                        <Chip sx={{marginTop:'10px'}} label="12th/PUC Details" color="secondary" />
                                                            <TextField
                                                            sx={{marginTop:'15px'}}
                                                            label="12th/PUC Percentage%"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={inputData['puc_percentage_12th']}
        
                                                            onChange={(e) => {
                                                                if(e.target.value <= 100){
                                                                onChangeHandler("puc_percentage_12th", e.target.value) 
                                                                }
                                                            }      
                                                            }
                                                            />

                                                            {/* <input type="number"
                                                                class="form-control inputField"
                                                                placeholder="12th/PUC Percentage%"
                                                                name="puc_percentage_12th"
                                                                id="puc_percentage_12th"
                                                                value={inputData['puc_percentage_12th']}
                                                                onChange={(e) => onChangeHandler("puc_percentage_12th", e.target.value)}
                                                            /> */}

                                                            <TextField
                                                            sx={{marginTop:'15px'}}
                                                            label="12th/PUC Subjects"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={inputData['puc_subjects_12th']}
                                                            onChange={(e) => onChangeHandler("puc_subjects_12th", e.target.value)}
                                                            />

                                                            {/* <input type="number"
                                                                class="form-control inputField"
                                                                placeholder="12th/PUC Subjects"
                                                                name="puc_subjects_12th"
                                                                id="puc_subjects_12th"
                                                                value={inputData['puc_subjects_12th']}
                                                                onChange={(e) => onChangeHandler("puc_subjects_12th", e.target.value)}
                                                            /> */}


                                                        <FormControl fullWidth sx={{marginTop:'15px'}}>
                                                            <InputLabel id="demo-simple-select-label">12th/PUC College</InputLabel>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={inputData['puc_college_name_12th']}
                                                            onChange={(e) => onChangeHandler("puc_college_name_12th", e.target.value)}
                                                            label="12th/PUC College"
                                                            
                                                            >
                                                            <MenuItem >12th/PUC College</MenuItem>
                                                        {pucCollegeList && pucCollegeList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
          
                                                            </Select>
                                                        </FormControl>

                                                            {/* <select class="form-select inputField"
                                                                aria-label="Default select example"
                                                                name="puc_college_name_12th"
                                                                id="puc_college_name_12th"
                                                                value={inputData['puc_college_name_12th']}
                                                                onChange={(e) => onChangeHandler("puc_college_name_12th", e.target.value)}
                                                            >
                                                                <option className="selectOpt" selected>12th/PUC College</option>
                                                                {pucCollegeList && pucCollegeList.map((o) => <option value={o}>{o}</option>)}
                                                            </select> */}


                                                            <FormControl fullWidth sx={{marginTop:'15px'}}>
                                                            <InputLabel id="demo-simple-select-label">12th/PUC Year of Passing</InputLabel>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={inputData['puc_year_of_passing_12th']}
                                                            onChange={(e) => onChangeHandler("puc_year_of_passing_12th", e.target.value)}
                                                            label="12th/PUC Year of Passing"
                                                            
                                                            >
                                                            <MenuItem >12th/PUC Year of Passing</MenuItem>
                                                        {pucYearList && pucYearList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
          
                                                            </Select>
                                                        </FormControl>

                                                            {/* <select class="form-select inputField"
                                                                aria-label="Default select example"
                                                                name="puc_year_of_passing_12th"
                                                                id="puc_year_of_passing_12th"
                                                                value={inputData['puc_year_of_passing_12th']}
                                                                onChange={(e) => onChangeHandler("puc_year_of_passing_12th", e.target.value)}
                                                            >
                                                                <option className="selectOpt" selected>12th/PUC Year of Passing</option>
                                                                {pucYearList && pucYearList.map((o) => <option value={o}>{o}</option>)}
                                                            </select> */}



                                                            
                                                        <FormControl fullWidth sx={{marginTop:'15px'}}>
                                                            <InputLabel id="demo-simple-select-label">German Language</InputLabel>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={inputData['german_language']}
                                                            onChange={(e) => onChangeHandler("german_language", e.target.value)}
                                                            label="German Language"
                                                            
                                                            >
                                                           
                                                        {germanLangList && germanLangList.map(o => <MenuItem value={o}>{o}</MenuItem>)}
          
                                                            </Select>
                                                        </FormControl>
{/* 
                                                            <select class="form-select inputField"
                                                                aria-label="Default select example"
                                                                name="german_language"
                                                                id="german_language"
                                                                value={inputData['german_language']}
                                                                onChange={(e) => onChangeHandler("german_language", e.target.value)}

                                                            >
                                                                <option className="selectOpt" selected>German Language</option>
                                                                {germanLangList && germanLangList.map((o) => <option value={o}>{o}</option>)}
                                                            </select> */}

                                                            <button className="submitBtn mt-4" onClick={() => updateProfileFunc()}>Submit</button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        : FormNo == "4" ?
                                            <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample4" >
                                                <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                                                    <span style={{ color: "white" }} onClick={() => {
                                                        setStateFormNo(FormNo - 1)
                                                        history.push({
                                                            pathname: '/Profile',
                                                            state: {
                                                                'formNo': FormNo - 1,
                                                                StudentData: inputData
                                                            }
                                                        })
                                                    }
                                                    }>     Prev</span>
                                                    <span style={{position:'relative'}}>
                                                    <Chip label="Prerequisite Test" color="warning"/>
                                                    {
                                                    getUserType() !== 'Student' 
                                                    ?
                                                    <><Typography style={{ position: "absolute", right: "-32px", bottom: "7px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(test_score)}%</Typography>
                                                    <CircularProgress  variant="determinate" color={barColor(Math.round(test_score))}  value={Math.round(test_score)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", right: "-35px"}} />
                                                    </>
                                                    :
                                                    ''
                                                    }
                                                    </span>
                                                    {/* <span style={{ marginBottom: 0, color: "white" }}>  Prerequisite Test </span> */}
                                                    <span style={{ color: "white" }} onClick={() => {
                                                        setStateFormNo(FormNo + 1)
                                                        history.push({
                                                            pathname: '/Profile',
                                                            state: {
                                                                'formNo': 5,
                                                                StudentData: inputData
                                                            }
                                                        })
                                                    }}> Next</span>
                                                </div>
                                                <div style={{ background: "white" }} id="flush-collapseOne-4" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne-4" data-bs-parent="#accordionFlushExample4">
                                                    <div class="accordion-body">

                                                    {/* new dropdown */}
                                                    
                                                    <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="TOEFL" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['toefl_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("toefl_total_1st_attempt", e.target.value)}
                                                                label="Total"
                                                             
                                                                >
                                                                      {
                                                                                    toeflTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Reading</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['toefl_reading_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("toefl_reading_1st_attempt", e.target.value)}
                                                                label="Reading"
                                                             
                                                                >
                                                                      {
                                                                                    toeflReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Listening</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['toefl_listening_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("toefl_listening_1st_attempt", e.target.value)}
                                                                label="Listening"
                                                             
                                                                >
                                                                      {
                                                                                    toeflReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Writing</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['toefl_writing_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("toefl_writing_1st_attempt", e.target.value)}
                                                                label="Writing"
                                                                >
                                                                      {
                                                                                    toeflReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Speaking</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['toefl_speaking_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("toefl_speaking_1st_attempt", e.target.value)}
                                                                label="Speaking"
                                                                >
                                                                      {
                                                                                    toeflReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>
                                    
                                            </div>
                                           

                                            {/* new dropdown end */}


                                                        <div className="row m-0">
                                                            
                                                            <div className="col-12 p-0">

                                                                <>
                                                                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div style={{ width: "45%" }}>

                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="TOEFL" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>
                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="toefl_reading_1st_attempt"
                                                                                id="toefl_reading_1st_attempt"
                                                                                value={inputData['toefl_reading_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("toefl_reading_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>TOEFL Total</option>
                                                                                {
                                                                                    toeflTotalList.map((item)=>{
                                                                                        return <option value={item}>{item}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div> */}

                                                                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="toefl_reading_1st_attempt"
                                                                            id="toefl_reading_1st_attempt"
                                                                            value={inputData['toefl_reading_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("toefl_reading_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>TOEFL Reading </option>
                                                                            {
                                                                                toeflReadingList.map((item)=>{
                                                                                    return <option value={item}>{item}</option>
                                                                                })
                                                                            }
                                                                     
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="toefl_listening_1st_attempt"
                                                                            id="toefl_listening_1st_attempt"
                                                                            value={inputData['toefl_listening_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("toefl_listening_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>TOEFL Listening</option>
                                                                            {
                                                                                toeflReadingList.map((item)=>{
                                                                                    return <option value={item}>{item}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div> */}
                                                                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="toefl_writing_1st_attempt"
                                                                            id="toefl_writing_1st_attempt"
                                                                            value={inputData['toefl_writing_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("toefl_writing_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>TOEFL Writing</option>
                                                                            {
                                                                                toeflReadingList.map((item)=>{
                                                                                    return <option value={item}>{item}</option>
                                                                                })
                                                                            }
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="toefl_speaking_1st_attempt"
                                                                            id="toefl_speaking_1st_attempt"
                                                                            value={inputData['toefl_speaking_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("toefl_speaking_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>TOEFL Speaking </option>
                                                                            {
                                                                                toeflReadingList.map((item)=>{
                                                                                    return <option value={item}>{item}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div> */}
                                                                </>

 {/* new dropdown */}
                                              
 <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="PTE" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">PTE Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['pte_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("pte_total_1st_attempt", e.target.value)}
                                                                label="PTE Total"
                                                             
                                                                >
                                                                      {
                                                                                    pteTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">PTE Reading</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['pte_reading_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("pte_reading_1st_attempt", e.target.value)}
                                                                label="PTE Reading"
                                                             
                                                                >
                                                                      {
                                                                                    pteReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">PTE Listening</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['pte_listening_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("pte_listening_1st_attempt", e.target.value)}
                                                                label="PTE Listening"
                                                             
                                                                >
                                                                      {
                                                                                    pteReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">PTE Writing</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['pte_writing_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("pte_writing_1st_attempt", e.target.value)}
                                                                label="PTE Writing"
                                                                >
                                                                      {
                                                                                    pteReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">PTE Speaking</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['pte_speaking_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("pte_speaking_1st_attempt", e.target.value)}
                                                                label="PTE Speaking"
                                                                >
                                                                      {
                                                                                    pteReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>
                                    
                                            </div>

                                            {/* new dropdown end */}

                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div style={{ width: "45%" }}>

                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="PTE" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>
                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="pte_speaking_1st_attempt"
                                                                                id="pte_speaking_1st_attempt"
                                                                                value={inputData['pte_speaking_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("pte_speaking_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>PTE Total </option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_speaking_1st_attempt"
                                                                            id="pte_speaking_1st_attempt"
                                                                            value={inputData['pte_speaking_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_speaking_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>PTE Speaking </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_reading_1st_attempt"
                                                                            id="pte_reading_1st_attempt"
                                                                            value={inputData['pte_reading_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_reading_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>PTE Reading </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>

                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_listening_1st_attempt"
                                                                            id="pte_listening_1st_attempt"
                                                                            value={inputData['pte_listening_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_listening_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>PTE Listening </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_writing_1st_attempt"
                                                                            id="pte_writing_1st_attempt"
                                                                            value={inputData['pte_writing_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_writing_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>PTE Writing </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>

                                                                </> */}

                                                                {/* new dropdown */}
                                            
                                                <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="IELTS" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">IELTS Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['ielts_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("ielts_total_1st_attempt", e.target.value)}
                                                                label="IELTS Total"
                                                             
                                                                >
                                                                      {
                                                                                    ieltsTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">IELTS Reading</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['ielts_reading_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("ielts_reading_1st_attempt", e.target.value)}
                                                                label="IELTS Reading"
                                                             
                                                                >
                                                                      {
                                                                                    ieltsReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">IELTS Listening</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['ielts_listening_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("ielts_listening_1st_attempt", e.target.value)}
                                                                label="IELTS Listening"
                                                             
                                                                >
                                                                      {
                                                                                    ieltsReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">IELTS Writing</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['ielts_writing_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("ielts_writing_1st_attempt", e.target.value)}
                                                                label="IELTS Writing"
                                                                >
                                                                        {
                                                                                    ieltsReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">IELTS Speaking</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['ielts_speaking_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("ielts_speaking_1st_attempt", e.target.value)}
                                                                label="IELTS Speaking"
                                                                >
                                                                      {
                                                                                    ieltsReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>
                                    
                                            </div>
                                          

                                            {/* new dropdown end */}

                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div style={{ width: "45%" }}>

                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="IELTS" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>
                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="ielts_total_1st_attempt"
                                                                                id="ielts_total_1st_attempt"
                                                                                value={inputData['ielts_total_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("ielts_total_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option className="selectOpt" selected>IELTS Total </option>
                                                                                {ieltsTotalList && ieltsTotalList.map((o) => <option value={o}>{o}</option>)}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select className="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_total_1st_attempt"
                                                                            id="pte_total_1st_attempt"
                                                                            value={inputData['pte_total_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_total_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option className="selectOpt" selected>IELTS
                                                                                Writing
                                                                            </option>
                                                                            {ieltsReadingList && ieltsReadingList.map((o) =>
                                                                                <option value={o}>{o}</option>)}
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="gre_total_1st_attempt"
                                                                            id="gre_total_1st_attempt"
                                                                            value={inputData['gre_total_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("gre_total_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option className="selectOpt" selected>IELTS Reading </option>
                                                                            {ieltsReadingList && ieltsReadingList.map((o) => <option value={o}>{o}</option>)}
                                                                        </select>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select className="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_total_1st_attempt"
                                                                            id="pte_total_1st_attempt"
                                                                            value={inputData['pte_total_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_total_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option className="selectOpt" selected>IELTS
                                                                                Speaking
                                                                            </option>
                                                                            {ieltsReadingList && ieltsReadingList.map((o) =>
                                                                                <option value={o}>{o}</option>)}
                                                                        </select>
                                                                        <select className="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="pte_total_1st_attempt"
                                                                            id="pte_total_1st_attempt"
                                                                            value={inputData['pte_total_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("pte_total_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option className="selectOpt" selected>IELTS
                                                                                Listening
                                                                            </option>
                                                                            {ieltsReadingList && ieltsReadingList.map((o) =>
                                                                                <option value={o}>{o}</option>)}
                                                                        </select>

                                                                    </div>

                                                                </> */}


                                                                {/* new dropdown */}
                                              
                                                <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="Duolingo" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['duolingo_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("duolingo_total_1st_attempt", e.target.value)}
                                                                label="Total"
                                                             
                                                                >
                                                                      {
                                                                                    duolingoTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Reading</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['duolingo_reading_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("duolingo_reading_1st_attempt", e.target.value)}
                                                                label="Reading"
                                                             
                                                                >
                                                                      {
                                                                                    duolingoReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Listening</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['duolingo_listening_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("duolingo_listening_1st_attempt", e.target.value)}
                                                                label="Listening"
                                                             
                                                                >
                                                                      {
                                                                                    duolingoReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Writing</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['duolingo_writing_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("duolingo_writing_1st_attempt", e.target.value)}
                                                                label="Writing"
                                                                >
                                                                        {
                                                                                    duolingoReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Speaking</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['duolingo_speaking_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("duolingo_speaking_1st_attempt", e.target.value)}
                                                                label="Speaking"
                                                                >
                                                                      {
                                                                                    duolingoReadingList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>
                                    
                                            </div>

                                            {/* new dropdown end */}

                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                                        <div style={{ width: "45%" }}>

                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="Duolingo" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>

                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="duolingo_total_1st_attempt"
                                                                                id="duolingo_total_1st_attempt"
                                                                                value={inputData['duolingo_total_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("duolingo_total_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>Duolingo Total </option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="duolingo_reading_1st_attempt"
                                                                            id="duolingo_reading_1st_attempt"
                                                                            value={inputData['duolingo_reading_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("duolingo_reading_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>Duolingo Reading  </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="duolingo_listening_1st_attempt"
                                                                            id="duolingo_listening_1st_attempt"
                                                                            value={inputData['duolingo_listening_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("duolingo_listening_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>Duolingo Listening </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>

                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="duolingo_writing_1st_attempt"
                                                                            id="duolingo_writing_1st_attempt"
                                                                            value={inputData['duolingo_writing_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("duolingo_writing_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>Duolingo Writing  </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="duolingo_speaking_1st_attempt"
                                                                            id="duolingo_speaking_1st_attempt"
                                                                            value={inputData['duolingo_speaking_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("duolingo_speaking_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>Duolingo Speaking  </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>
                                                                </> */}


                                            {/* new dropdown */}
                                             {
                                                inputData?.degree_looking_for === 'Masters' ? 
                                                <>
                                                
                            
                                        <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="GRE" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GRE Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gre_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("gre_total_1st_attempt", e.target.value)}
                                                                label="GRE Total"
                                                             
                                                                >
                                                                      {
                                                                                    greTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-12 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GRE awa</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gre_awa']}
                                                                onChange={(e) => onChangeHandler("gre_awa", e.target.value)}
                                                                label="GRE Reading"
                                                             
                                                                >
                                                                      {
                                                                                    greQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GRE Verbal</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gre_verbal_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("gre_verbal_1st_attempt", e.target.value)}
                                                                label="GRE Verbal"
                                                             
                                                                >
                                                                      {
                                                                                    greQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GRE Quants</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gre_quants_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("gre_quants_1st_attempt", e.target.value)}
                                                                label="GRE Quants"
                                                                >
                                                                        {
                                                                                    greQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                              
                                    
                                            </div>
                                         

                                            {/* new dropdown end */}
                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                                        <div style={{ width: "45%" }}>

                                                                            <h6 style={{ marginTop: '10px', marginBottom: '15px', fontWeight: "bold", marginRight: 5 }}><Chip label="GRE" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>


                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="gre_total_1st_attempt"
                                                                                id="gre_total_1st_attempt"
                                                                                value={inputData['gre_total_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("gre_total_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>GRE Total  </option>
                                                                                <option value="Masters">Masters</option>
                                                                                <option value="Bachelors">Bachelors</option>
                                                                                <option value="PHD">PHD</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <select class="form-select inputField"
                                                                        aria-label="Default select example"
                                                                        name="gre_verbal_1st_attempt"
                                                                        id="gre_verbal_1st_attempt"
                                                                        value={inputData['gre_verbal_1st_attempt']}
                                                                        onChange={(e) => onChangeHandler("gre_verbal_1st_attempt", e.target.value)}
                                                                    >
                                                                        <option selected>GRE awa </option>
                                                                        <option value="Masters">Masters</option>
                                                                        <option value="Bachelors">Bachelors</option>
                                                                        <option value="PHD">PHD</option>
                                                                    </select>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="gre_verbal_1st_attempt"
                                                                            id="gre_verbal_1st_attempt"
                                                                            value={inputData['gre_verbal_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("gre_verbal_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>GRE Verbal </option>
                                                                            <option value="Masters">Masters</option>
                                                                            <option value="Bachelors">Bachelors</option>
                                                                            <option value="PHD">PHD</option>
                                                                        </select>
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="gre_quants_1st_attempt"
                                                                            id="gre_quants_1st_attempt"
                                                                            value={inputData['gre_quants_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("gre_quants_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>GRE Quants  </option>
                                                                            <option value="Masters">Masters</option>
                                                                            <option value="Bachelors">Bachelors</option>
                                                                            <option value="PHD">PHD</option>
                                                                        </select>
                                                                    </div>
                                                                </> */}

                                                                 {/* new dropdown */}
                                              
                                        <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="GMAT" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GMAT Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gmat_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("gmat_total_1st_attempt", e.target.value)}
                                                                label="GMAT Total"
                                                             
                                                                >
                                                                      {
                                                                                    gmatTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-12 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GMAT awa</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gmat_awa']}
                                                                onChange={(e) => onChangeHandler("gmat_awa", e.target.value)}
                                                                label="GMAT awa"
                                                             
                                                                >
                                                                      {
                                                                                    greQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GMAT Verbal</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gmat_verbal_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("gmat_verbal_1st_attempt", e.target.value)}
                                                                label="GMAT Verbal"
                                                             
                                                                >
                                                                      {
                                                                                    gmatQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">GMAT Quants</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['gmat_quants_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("gmat_quants_1st_attempt", e.target.value)}
                                                                label="GMAT Quants"
                                                                >
                                                                        {
                                                                                    greQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                              
                                    
                                            </div>
                                            </>
                                            :
                                            ''
                                            }

                                            {/* new dropdown end */}
                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                                        <div style={{ width: "45%" }}>
                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="GMAT" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>

                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="gmat_total_1st_attempt"
                                                                                id="gmat_total_1st_attempt"
                                                                                value={inputData['gmat_total_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("gmat_total_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>GMAT Total </option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <select class="form-select inputField"
                                                                        aria-label="Default select example"
                                                                        name="gmat_quants_1st_attempt"
                                                                        id="gmat_quants_1st_attempt"
                                                                        value={inputData['gmat_quants_1st_attempt']}
                                                                        onChange={(e) => onChangeHandler("gmat_quants_1st_attempt", e.target.value)}
                                                                    >
                                                                        <option selected>GMAT Awa </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                    </select>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="gmat_quants_1st_attempt"
                                                                            id="gmat_quants_1st_attempt"
                                                                            value={inputData['gmat_quants_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("gmat_quants_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>GMAT Quants </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="gmat_verbal_1st_attempt"
                                                                            id="gmat_verbal_1st_attempt"
                                                                            value={inputData['gmat_verbal_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("gmat_verbal_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>GMAT Verbal </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>

                                                                </> */}

                                        {/* new dropdown */}
                                        {
                                                inputData?.degree_looking_for === 'Bachelors' ? 
                                                <>

                                        <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="ACT" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">ACT Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['act_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("act_total_1st_attempt", e.target.value)}
                                                                label="ACT Total"
                                                             
                                                                >
                                                                                {
                                                                                    actTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                {/* <div className="col-12 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">ACT awa</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['act_awa']}
                                                                onChange={(e) => onChangeHandler("act_awa", e.target.value)}
                                                                label="ACT awa"
                                                             
                                                                >
                                                                      {
                                                                                    actQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div> */}

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">ACT Verbal</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['act_verbal_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("act_verbal_1st_attempt", e.target.value)}
                                                                label="ACT Verbal"
                                                             
                                                                >
                                                                      {
                                                                                    actQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">ACT Quants</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['act_quants_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("act_quants_1st_attempt", e.target.value)}
                                                                label="ACT Quants"
                                                                >
                                                                        {
                                                                                    actQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                              
                                    
                                            </div>
                                        

                                            {/* new dropdown end */}
                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                                        <div style={{ width: "45%" }}>
                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="ACT" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>

                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="act_total_1st_attempt"
                                                                                id="act_total_1st_attempt"
                                                                                value={inputData['act_total_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("act_total_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>ACT Total </option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <select class="form-select inputField"
                                                                        aria-label="Default select example"
                                                                        name="act_quants_1st_attempt"
                                                                        id="act_quants_1st_attempt"
                                                                        value={inputData['act_quants_1st_attempt']}
                                                                        onChange={(e) => onChangeHandler("act_quants_1st_attempt", e.target.value)}
                                                                    >
                                                                        <option selected>ACT AWA  </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                    </select>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="act_quants_1st_attempt"
                                                                            id="act_quants_1st_attempt"
                                                                            value={inputData['act_quants_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("act_quants_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>ACT Quants  </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="act_verbal_1st_attempt"
                                                                            id="act_verbal_1st_attempt"
                                                                            value={inputData['act_verbal_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("act_verbal_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>ACT Verbal 1st Attempts</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>
                                                                </> */}




                                        {/* new dropdown */}
                                              
                                        <div className="row d-flex justify-content-between" style={{marginTop:'15px',marginRight:'3px',marginLeft:'3px'}}>
                                                    <div className="col-6 ps-0 pe-1 mt-2" >
                                                    <Chip label="SAT" color="success"/>
                                                    </div>
                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">SAT Total</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['sat_total_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("sat_total_1st_attempt", e.target.value)}
                                                                label="SAT Total"
                                                             
                                                                >
                                                                                {
                                                                                    satTotalList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                {/* <div className="col-12 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">SAT awa</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['act_awa']}
                                                                onChange={(e) => onChangeHandler("act_awa", e.target.value)}
                                                                label="SAT awa"
                                                             
                                                                >
                                                                      {
                                                                                    satQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div> */}

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">SAT Verbal</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['sat_verbal_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("sat_verbal_1st_attempt", e.target.value)}
                                                                label="SAT Verbal"
                                                             
                                                                >
                                                                      {
                                                                                    satQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                                }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                                <div className="col-6 ps-0 pe-1 mt-2" >
                                                <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">SAT Quants</InputLabel>
                                                                <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={inputData['sat_quant_1st_attempt']}
                                                                onChange={(e) => onChangeHandler("sat_quant_1st_attempt", e.target.value)}
                                                                label="SAT Quants"
                                                                >
                                                                        {
                                                                                    satQuantsList.map((item)=>{
                    
                                                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                                                    })
                                                                        }
                                                           
                                                                </Select>
                                                            </FormControl>
                                              
                                                </div>

                                              
                                    
                                            </div>
                                            </>
                                            :
                                            ''
                                            }
                                            {/* new dropdown end */}
                                                                {/* <>
                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                                        <div style={{ width: "45%" }}>
                                                                            <h6 style={{ marginTop: '20px', marginBottom: '15px', fontWeight: "bold" }}><Chip label="SAT" color="success"/></h6>
                                                                        </div>
                                                                        <div style={{ width: "45%" }}>


                                                                            <select class="form-select inputField"
                                                                                aria-label="Default select example"
                                                                                name="sat_total_1st_attempt"
                                                                                id="sat_total_1st_attempt"
                                                                                value={inputData['sat_total_1st_attempt']}
                                                                                onChange={(e) => onChangeHandler("sat_total_1st_attempt", e.target.value)}
                                                                            >
                                                                                <option selected>SAT Total </option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <select class="form-select inputField"
                                                                        aria-label="Default select example"
                                                                        name="sat_verbal_1st_attempt"
                                                                        id="sat_verbal_1st_attempt"
                                                                        value={inputData['sat_verbal_1st_attempt']}
                                                                        onChange={(e) => onChangeHandler("sat_verbal_1st_attempt", e.target.value)}
                                                                    >
                                                                        <option selected>SAT AWA  </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                    </select>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="sat_quants_1st_attempt"
                                                                            id="sat_quants_1st_attempt"
                                                                            value={inputData['sat_quants_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("sat_quants_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>SAT Quants  </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>

                                                                        <select class="form-select inputField"
                                                                            aria-label="Default select example"
                                                                            name="sat_verbal_1st_attempt"
                                                                            id="sat_verbal_1st_attempt"
                                                                            value={inputData['sat_verbal_1st_attempt']}
                                                                            onChange={(e) => onChangeHandler("sat_verbal_1st_attempt", e.target.value)}
                                                                        >
                                                                            <option selected>SAT Verbal  </option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                        </select>
                                                                    </div>

                                                                </> */}


                                                                <button className="submitBtn mt-4" onClick={() => updateProfileFunc()}>Submit</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : FormNo == "5" ?
                                                <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample3">
                                                    <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                                                        <span style={{ color: "white" }} onClick={() => {
                                                            setStateFormNo(FormNo - 1)
                                                            history.push({
                                                                pathname: '/Profile',
                                                                state: {
                                                                    'formNo': FormNo - 1,
                                                                    StudentData: inputData
                                                                }
                                                            })
                                                        }}>     Prev</span>
                                                        <span style={{position:'relative'}}>
                                                        <Chip label="LOR" color="warning"/>
                                                        {
                                                        getUserType() !== 'Student' 
                                                        ?
                                                        <><Typography style={{ position: "absolute", right: "-32px", bottom: "7px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(recommend_score)}%</Typography>
                                                        <CircularProgress  variant="determinate" color={barColor(Math.round(recommend_score))}  value={Math.round(recommend_score)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", right: "-35px"}} />
                                                        </>
                                                        :
                                                        ''
                                                        }
                                                        </span>
                                                        {/* <span style={{ marginBottom: 0, color: "white" }}>  LOR </span> */}
                                                        <span style={{ color: "white" }} >      </span>
                                                    </div>

                                                    <div style={{ background: "white" }} id="flush-collapseOne-3" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne-3" data-bs-parent="#accordionFlushExample3">
                                                        <div class="accordion-body">

                                                            <div className="row m-0">
                                                                <div className="col-12 p-0">
                                                                <Chip label="LOR1" color="error"/>
                                                                    <div style={{ marginBottom: 40 }}>
                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR1 First Name"
                                                                            name="lor1_first_name"
                                                                            id="lor1_first_name"
                                                                            value={inputData['lor1_first_name']}
                                                                            onChange={(e) => onChangeHandler("lor1_first_name", e.target.value)}
                                                                        />

                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR1 Last Name"
                                                                            name="lor1_last_name"
                                                                            id="lor1_last_name"
                                                                            value={inputData['lor1_last_name']}
                                                                            onChange={(e) => onChangeHandler("lor1_last_name", e.target.value)}
                                                                        />

                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR1 Designation"
                                                                            name="lor1_designation"
                                                                            id="lor1_designation"
                                                                            value={inputData['lor1_designation']}
                                                                            onChange={(e) => onChangeHandler("lor1_designation", e.target.value)}
                                                                        />

                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR1 Employer"
                                                                            name="lor1_employer"
                                                                            id="lor1_employer"
                                                                            value={inputData['lor1_employer']}
                                                                            onChange={(e) => onChangeHandler("lor1_employer", e.target.value)}
                                                                        />

                                                                        <input type="number"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR1 Contact"
                                                                            name="lor1_contact"
                                                                            id="lor1_contact"
                                                                            value={inputData['lor1_contact']}
                                                                            onChange={(e) => onChangeHandler("lor1_contact", e.target.value)}
                                                                        />

                                                                        <input type="email"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR1 Email"
                                                                            name="lor1_email"
                                                                            id="lor1_email"
                                                                            value={inputData['lor1_email']}
                                                                            onChange={(e) => onChangeHandler("lor1_email", e.target.value)}

                                                                        />
                                                                    </div>
                                                                    <Chip label="LOR2" color="error"/>
                                                                    <div style={{ marginBottom: 40 }}>
                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR2 First Name"
                                                                            name="lor2_first_name"
                                                                            id="lor2_first_name"
                                                                            value={inputData['lor2_first_name']}
                                                                            onChange={(e) => onChangeHandler("lor2_first_name", e.target.value)}
                                                                        />

                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR2 Last Name"
                                                                            name="lor2_last_name"
                                                                            id="lor2_last_name"
                                                                            value={inputData['lor2_last_name']}
                                                                            onChange={(e) => onChangeHandler("lor2_last_name", e.target.value)}
                                                                        />

                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR2 Designation"
                                                                            name="lor2_designation"
                                                                            id="lor2_designation"
                                                                            value={inputData['lor2_designation']}
                                                                            onChange={(e) => onChangeHandler("lor2_designation", e.target.value)}
                                                                        />

                                                                        <input type="text"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR2 Employer"
                                                                            name="lor2_employer"
                                                                            id="lor2_employer"
                                                                            value={inputData['lor2_employer']}
                                                                            onChange={(e) => onChangeHandler("lor2_employer", e.target.value)}
                                                                        />

                                                                        <input type="number"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR2 Contact"
                                                                            name="lor2_contact"
                                                                            id="lor2_contact"
                                                                            value={inputData['lor2_contact']}
                                                                            onChange={(e) => onChangeHandler("lor2_contact", e.target.value)}
                                                                        />

                                                                        <input type="email"
                                                                            class="form-control inputField"
                                                                            placeholder="LOR2 Email"
                                                                            name="lor2_email"
                                                                            id="lor2_email"
                                                                            value={inputData['lor2_email']}
                                                                            onChange={(e) => onChangeHandler("lor2_email", e.target.value)}
                                                                        />
                                                                    </div>

                                                                    <Chip label="LOR3" color="error"/>
                                                                    <input type="text"
                                                                        class="form-control inputField"
                                                                        placeholder="LOR3 First Name"
                                                                        name="lor3_first_name"
                                                                        id="lor3_first_name"
                                                                        value={inputData['lor3_first_name']}
                                                                        onChange={(e) => onChangeHandler("lor3_first_name", e.target.value)}
                                                                    />

                                                                    <input type="text"
                                                                        class="form-control inputField"
                                                                        placeholder="LOR3 Last Name"
                                                                        name="lor3_last_name"
                                                                        id="lor3_last_name"
                                                                        value={inputData['lor3_last_name']}
                                                                        onChange={(e) => onChangeHandler("lor3_last_name", e.target.value)}
                                                                    />

                                                                    <input type="text"
                                                                        class="form-control inputField"
                                                                        placeholder="LOR3 Designation"
                                                                        name="lor3_designation"
                                                                        id="lor3_designation"
                                                                        value={inputData['lor3_designation']}
                                                                        onChange={(e) => onChangeHandler("lor3_designation", e.target.value)}
                                                                    />

                                                                    <input type="text"
                                                                        class="form-control inputField"
                                                                        placeholder="LOR3 Employer"
                                                                        name="lor3_employer"
                                                                        id="lor3_employer"
                                                                        value={inputData['lor3_employer']}
                                                                        onChange={(e) => onChangeHandler("lor3_employer", e.target.value)}
                                                                    />

                                                                    <input type="number"
                                                                        class="form-control inputField"
                                                                        placeholder="LOR3 Contact"
                                                                        name="lor3_contact"
                                                                        id="lor3_contact"
                                                                        value={inputData['lor3_contact']}
                                                                        onChange={(e) => onChangeHandler("lor3_contact", e.target.value)}
                                                                    />

                                                                    <input type="email"
                                                                        class="form-control inputField"
                                                                        placeholder="LOR3 Email"
                                                                        name="lor3_email"
                                                                        id="lor3_email"
                                                                        value={inputData['lor3_email']}
                                                                        onChange={(e) => onChangeHandler("lor3_email", e.target.value)}
                                                                    />



                                                                    <button className="submitBtn mt-4" onClick={() => updateProfileFunc()}>Submit</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* </div> */}

                                                </div>
                                                : null}
                            {/* <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample5">

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne-5">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-5" aria-expanded="false" aria-controls="flush-collapseOne-5">
                                    Upload Documents
                                </button>
                            </h2>
                            <div id="flush-collapseOne-5" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne-5" data-bs-parent="#accordionFlushExample5">
                                <div class="accordion-body">

                                    <div className="row m-0">
                                        <div className="col-12 p-0">

                                            <div className="row m-0 mt-2">
                                                <div className="col-12 p-2 docField d-flex align-items-center justify-content-between">
                                                    <div className="addedDocuments d-flex align-items-center justify-content-center">
                                                        <p className="mb-0">1</p>
                                                    </div>

                                                    <button className="docView">View</button>
                                                </div>
                                            </div>

                                            <h3 className="doneTask m-0 mt-4 mb-4">Required Documents</h3>

                                            <select class="form-select inputField"
                                                aria-label="Default select example"
                                                name="documents_required"
                                                id="documents_required"
                                                value={inputData['documents_required']}
                                                onChange={(e) => onChangeHandler("documents_required", e.target.value)}
                                            >
                                                <option selected>Select you Document</option>
                                                <option value="Passport">Passport</option>
                                                <option value="National ID">National ID</option>
                                                <option value="3">Other</option>
                                            </select>

                                            <p className="mb-0 mt-4 doneTaskLight">Uploaded files</p>

                                            <div className="row m-0 mt-4 d-flex justify-content-between">
                                                <div className="col-2 p-0 d-flex justify-content-start">
                                                    <img src={PDF} alt="" />
                                                </div>
                                                <div className="col-8 p-0">

                                                    <div className="row m-0 d-flex justify-content-between">
                                                        <div className="col-10 p-0 d-flex justify-content-start">
                                                            <p className="mb-0 doneTask doneTaskSm">10th and 12th Marksheet</p>
                                                        </div>
                                                        <div className="col-2 p-0 d-flex justify-content-start">
                                                            <p className="mb-0 doneTaskLight doneTaskLightSm">70%</p>
                                                        </div>
                                                    </div>

                                                    <div className="row m-0 mt-2 d-flex justify-content-between">
                                                        <div className="col-12 p-0 d-flex justify-content-start">
                                                            <img width="100%" src={ProgressBar} alt="" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-2 p-0 d-flex align-items-center justify-content-center">
                                                    <img src={CloseSquare} alt="" />
                                                </div>
                                            </div>

                                            <button className="submitBtn mt-5" onClick={() => updateProfileFunc()}>Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div> */}



                        </div>
                    </div>
                    : Tab === "Shortlisted" ?
                        <div>
                            {/* {console.log('this working')} */}
                            <AssociateUniversityFavourites user={inputData || null} />
                        </div>
                        : Tab === "Applications" ?
                            <div> <ApplicationStatus userId={inputData?.contactid || null} /> </div>
                            : Tab === "Document" ?
                                <div> <UploadDocuments user={inputData || null} /> </div>
                                : Tab === "Comments" ?
                                    <div>
                                        <Grid sx={12} sx={{ paddingTop: 1, display: "flex", alignItems: "center" }} container>
                                            <Grid sx={{ width: "5%", textAlign: "center" }}>
                                                <div style={{
                                                    marginLeft: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: 30,
                                                    height: 33,
                                                    width: 33,
                                                    background: `url(${process.env.PUBLIC_URL}/img/user.png)`,
                                                    backgroundSize: "cover",
                                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                                }}>

                                                </div>
                                            </Grid>
                                            <Grid sx={{ paddingLeft: 5, width: "75%", display: "flex", justifyContent: "left", alignItems: "left" }}>
                                                <p style={{ color: "#1A1A1A", fontSize: 16, fontWeight: "bold", textAlign: "center" }}>{"yves_moneyteam" || ''}</p>
                                            </Grid>
                                            <Grid sx={{ paddingLeft: 2, display: "flex", justifyContent: "flex-end", width: "20%" }}>
                                                {/* <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                        }}>
                                            <a
                                            //  href={`mailto:${mentorData?.data?.mentor?.email || ''}`}
                                            ><img src={`${Message}`} style={{ height: 30, width: 30 }} /></a>
                                        </div> */}

                                                {/* <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                        }}>
                                            <a
                                            // href={`https://api.whatsapp.com/send?phone=${mentorData?.data?.mentor?.mobile || ''}&text=Hi`}
                                            >
                                                <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                                        </div> */}
                                                <div
                                                    onClick={() => {
                                                        // window.open(`tel:${mentorData?.data?.mentor?.mobile}`);
                                                    }}
                                                    style={{
                                                        marginRight: 16,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: 30,
                                                        height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                                    }}>
                                                    <img src={`${process.env.PUBLIC_URL}/img/Calling.png`} style={{ height: 30, width: 30 }} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div style={{ height: window.innerHeight - 460, overflowX: "scroll" }}>
                                            <ChatCard
                                                userID={"101"}
                                                sender={true}
                                                commentcontent="dsdsd"
                                            />
                                        </div>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            {/* <div style={{ width: "40%", paddingLeft: 31, display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <img src={landscape} style={{ height: 20, width: 20 }} />
                            <img src={Camera} style={{ height: 20, width: 20 }} />
                            <img src={Voice} style={{ height: 20, width: 20 }} />
                        </div> */}
                                            <div style={{ width: "100%", paddingRight: 10, paddingLeft: 10, display: "flex", alignItems: "center" }}>
                                                <textarea
                                                    onChange={({ target }) => {
                                                        // this.setState({ Message: target.value })
                                                    }}
                                                    multiline
                                                    placeholder="Type message"
                                                    style={{ width: "85%", borderRadius: 25, paddingLeft: 15, paddingTop: 20 }}
                                                ></textarea>
                                                <img
                                                    //  onClick={() => this.SendMessageHandler()} 
                                                    src={SendMessage} style={{ height: 60, width: 60, }} />
                                            </div>
                                        </div>
                                    </div> : ""
                }
                {/* <Box mt={4} className={classes.progressBar} >
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2}>
                        <p className={classes.doneTask}>Complete your profile</p>
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'center' }}>
                        <img src={Progress} alt="" />
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.doneTask}> <span>&#8226;</span> Personal Information</p>
                        <img src={Completed} alt="" />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.doneTask}> <span>&#8226;</span> Looking For</p>
                        <img src={Completed} alt="" />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.doneTask}> <span>&#8226;</span> Academic Information</p>
                        <img src={Completed} alt="" />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.remainTask}> <span>&#8226;</span> Test Scores</p>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.remainTask}> <span>&#8226;</span> Recommender Details</p>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.remainTask}> <span>&#8226;</span> Documents</p>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} mt={2} pl={2} pr={2} style={{ display : 'flex', alignItems : 'center', justifyContent : 'space-between' }}>
                        <p className={classes.remainTask}> <span>&#8226;</span> Profile Evaluate</p>
                    </Grid>
                </Grid>
            </Box> */}

                {/* <div className="slidingSection mt-4 pb-5">
                <Accordion className="slidingCards">

                    <AccordionSummary className={classes.accoHeader} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography>Personal information</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <FormGroup>

                            //<TextField id="inputField" label="First Name" variant="outlined" classes={classes.inputField}/>

                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="First Name"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Last Name"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Contact ID"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Email ID"/>

                            <div class="form-check checkBox">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">Email Opt Out</label>
                            </div>

                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Referece"/>

                            <div class="form-check checkBox">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">Do not Call</label>
                            </div>

                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Home Phone"/>
                            <input type="date" class="form-control inputField" id="exampleFormControlInput1" placeholder=""/>

                            <select class="form-select inputField" aria-label="Default select example">
                                <option selected>Gander</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Other</option>
                            </select>

                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Place of Birth"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Nationality"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Passport No"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Father Name"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Parents Mobile"/>
                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Invite Contact Id"/>

                            <select class="form-select inputField" aria-label="Default select example">
                                <option selected>Contact Status</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Other</option>
                            </select>

                            <input type="text" class="form-control inputField" id="exampleFormControlInput1" placeholder="Referral Code"/>
                            <button className={classes.submitBtn}>Submit</button>



                            // <input className={classes.inputField} type="text" placeholder="First Name" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Last Name" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Contact ID" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Email ID" value="" />
                            // <FormControlLabel className={classes.checkBox} control={<Checkbox />} label="Email Opt Out" />
                            // <input className={classes.inputField} type="text" placeholder="Referece" value="" />
                            // <FormControlLabel className={classes.checkBox} control={<Checkbox />} label="Do not Call" />
                            // <input className={classes.inputField} type="text" placeholder="Home Phone" value="" />
                            // <input className={classes.inputField} type="date" placeholder="Home Phone" value="" />
                            // <select className={classes.inputField} name="gender" id="gender">
                            //     <option value="male">Gander</option>
                            //     <option value="male">Male</option>
                            //     <option value="female">Female</option>
                            //     <option value="other">Other</option>
                            // </select>
                            // <input className={classes.inputField} type="text" placeholder="Place of Birth" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Nationality" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Passport No" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Father Name" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Parents Mobile" value="" />
                            // <input className={classes.inputField} type="text" placeholder="Invite Contact Id" value="" />

                            // <select className={classes.inputField} name="contactSts" id="contactSts">
                            //     <option value="">Contact Status</option>
                            //     <option value=""></option>
                            //     <option value=""></option>
                            //     <option value=""></option>
                            // </select>

                            // <input className={classes.inputField} type="text" placeholder="Referral Code" value="" />

                            // <button className={classes.submitBtn}>Submit</button>

                        </FormGroup>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="slidingCards">

                    <AccordionSummary className={classes.accoHeader} expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                        <Typography>Application</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <p>Hello</p>
                    </AccordionDetails>
                </Accordion>

            </div> */}

                <Dialog
                    onClose={() => setOpen(false)}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    scroll={'body'}
                >
                    {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={()=>setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle> */}
                    <DialogContent >
                        <Alert severity="success">{success}</Alert>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Profile;


const useStyles = styled((theme) => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    },


}));
