import { makeStyles } from "@mui/styles";
import React from "react";

const EventsNav = ({ toggle, setToggle }) => {
 const { current, past, upcoming } = toggle;
 const classes = useStyles();
 return (
  <div className={classes.container}>
   <p
       style={{marginBottom:0, cursor:'pointer'}}
       className={`${current && classes.bgBlue} ${classes.text}`}
       onClick={() =>
         setToggle({ current: true, past: false, upcoming: false })
       }
   >
     Current
   </p>
   <p
       style={{marginBottom:0, cursor:'pointer'}}
       className={`${upcoming && classes.bgBlue} ${classes.text}`}
       onClick={() =>
         setToggle({ current: false, past: false, upcoming: true })
       }
   >
    Upcoming
   </p>
   <p
       style={{marginBottom:0, cursor:'pointer'}}
       className={`${past && classes.bgBlue} ${classes.text}`}
       onClick={() =>
         setToggle({ current: false, past: true, upcoming: false })
       }
   >
    Past
   </p>
  </div>
 );
};

export default EventsNav;

const useStyles = makeStyles((theme) => ({
 container: {
    display: "flex",
    justifyContent: "space-evenly",
    border: "1px solid transparent",
    borderRadius: "30px",
    boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05)",
     backgroundColor: '#FFFFFF',
     marginBottom:'20px'
 },
 text: {
    fontSize: "16px",
    lineHeight:'20px',
    fontWeight : '600',
    color:'#CED0D5',
     padding:'10px 0'
 },
 bgBlue: {
  color: "#1B6CFC",
 },
}));
