import React from 'react'
import {  Container, Typography } from '@mui/material';

const Nav1 =() => {
  
  return (
    <Container sx={{backgroundColor:"#000080",borderRadius:"20px",mb:3}} marginBottom="10px"  marginTop="10px">
        <Typography variant="h5" align="center" margin="dense" sx={{ color: "#f8f8ff",fontFamily:"Century Gothic" }} >
          Personal
        </Typography>
        </Container>
  )
}

export default Nav1;