import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import _ from 'lodash'
import { fetchNotificationListAction } from '../Action'
import BottomNav from '../Common/BottomNav'
import PageTitle from '../PageTitle'
import Card from './Card/idex'
import { getSessionId, getUserId, getUser } from '../../Controller/localStorageHandler'

const Notifications = () => {
    const [NotificationData, setNotificationData] = useState([])

    useEffect(() => {
        // console.log(getUserId())
        var data = JSON.stringify({
            "contactid": getUserId(),
            "contact_no": getUser()?.contact_no,
            "bypass": "validation"
        });

        fetchNotificationListAction(data).then((response) => {
            setNotificationData(response.data.list)
            console.log(response)
        }).catch((error) => {
            setNotificationData([])
        })
    }, [])

    console.log(":NotificationData", NotificationData)
    let Data = _.groupBy(NotificationData, 'remindertype')
    console.log("Data", Data)
    return (
        <div style={{ backgroundColor: '#fafafa' }}>
            <div >
                <PageTitle pageTitle={`Notification`} />
                <Tabs>
                    <TabList style={{ paddingLeft: 0, marginLeft: 0, justifyContent: 'start' }} className="tab_class tab_class_fav">
                        <Tab className="dashboard_category">Alert ({Data?.Alert?.length || ''})</Tab>
                        <Tab className="dashboard_category">Warning ({Data?.Warning?.length || ''})</Tab>
                        <Tab className="dashboard_category">Message ({Data?.Message?.length || ''}) </Tab>
                    </TabList>
                    <TabPanel>
                        {Data?.Alert?.map((d) => {
                            return <div className="mt-3">
                                <Card Data={d} />
                            </div>
                        })
                        }
                    </TabPanel>
                    <TabPanel>
                        {Data?.Warning?.map((d) => {
                            return <div className="mt-3">
                                <Card Data={d} />
                            </div>
                        })
                        }
                    </TabPanel>
                    <TabPanel>
                        {Data?.Message?.map((d) => {
                            return <div className="mt-3">
                                <Card Data={d} />
                            </div>
                        })
                        }
                    </TabPanel>
                </Tabs>


            </div>
            <BottomNav />
        </div>
    )
}

export default Notifications
