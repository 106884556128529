import React, { useEffect, useState } from "react";
// import party from "${process.env.PUBLIC_URL}/img/party.png";
//mui
import { makeStyles } from "@mui/styles";

import { champianInsertAction  } from '../../Action/index.jsx';
import { getSessionId, getUserId } from "../../../Controller/localStorageHandler";
import { useHistory, Link } from 'react-router-dom'
import _ from 'lodash'
import PageTitle from "../../PageTitle";
import Colors from "../../../static/layout/Colors";
import moment from "moment";
import Share from '../../../img/share.png';
import { ToastContainer, toast } from 'react-toastify';
import { Dialog, DialogContent, DialogTitle, IconButton, Box, Grid,Input,Button,FormHelperText,Alert,Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";

import Slide from '@mui/material/Slide';
import axios from 'axios';
import Typography from '@mui/material/Typography';

import useAppContext from "../../../useContext/userContext.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const EventsCard = ({ data, countData, joinData }) => {
    const { getEventList } = useAppContext()
    const classes = useStyles();
    const history = useHistory();

    const[isJoined,setIsJoined] = useState(false);
    const[newCount,setNewCount] = useState(0);
    const[open,setOpen] = useState(false);
    const[campaignData,setCampaignData] = useState([]);
    const[email,setEmail] = useState('');
    const[phone,setPhone] = useState('')
    const[accPresent,setAccPresent] = useState(false)
    const[joinSuccess,setJoinSuccess] = useState(false)

    const getCountData = () => {
        let Data = _.find(Object.keys(countData), (d) => {
            let id = d.replace("count_", "");
            if (id === data.campaignid) {
                return countData[d]
            }
        })
        return `${Data ? countData[`${Data}`] : 0} Attending`
    }

    const getJoin = () => {
        return joinData.filter((element) => {
            if(element.campaignid === data.campaignid){
                return setIsJoined(true);
            }
            // return element.isEdit === true;
          })
    }
    useEffect(()=>{
        getJoin();
    },[])

    const joinEvent = (data,userId) =>{
        setIsJoined(true);
        
        var formData = new FormData();
        formData.append('campaignid', data.campaignid);
        formData.append('contactid', getUserId() || userId);
        formData.append("userid", getUserId() || userId)
        formData.append("sessionid", getSessionId())
        formData.append('validation','bypass')
        champianInsertAction(formData).then(res => {
            if (res?.msg === "Success") {
                setIsJoined(true);
                const getCount = getCountData();
                var thenum = getCount.match(/\d/g);
                thenum = thenum.join("");
                setNewCount(parseInt(thenum)+parseInt(1));
                setJoinSuccess(true)
                toast.success(`Event Joined Successfully`)
                getEventList()
            } else {
                toast.error(`${res?.msg}`)
 
            }
        }).catch((erro) => {
            toast.error(`Event Add failed`)
        })

    }

    const openModal = (data) => {
        setOpen(true)
        setCampaignData(data)
    }

    const handleClose = () => {
        setOpen(false)
        setCampaignData([])
    }

    const joinWhatsapp = async (data) =>{
        console.log(data)

        const formData = new FormData()
        formData.append('operation','Whatsapp')
        formData.append('username','Student')
        formData.append('email_address',email)
        formData.append('phone_mobile',phone)
        formData.append('country',data?.country)
        formData.append('year',data?.year_looking_for)
        formData.append('semester',data?.semester_looking_for)
        formData.append('program',data?.related_programs)
        formData.append('leadsources','Whatsapp')

        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response=>{
            console.log(response)
            if(response?.data?.status === 'false'){
                setAccPresent(true)
            }
            else if(response?.data?.status === "success"){
                joinEvent(data,response?.data?.data)
            }
        })


    }
        
    const shareEvent = (data) =>{
        let about = `${data?.venue?.split('||').map((item)=>item+'%0D%0A')}`
        let TempData = `You are invited for the Webinar on *${data?.campaignname}* ${data?.semester_looking_for} ${' '} ${data?.year_looking_for}
        %0D%0A%0D%0A*Date:* ${data?.event_date}
        %0D%0A%0D%0A*Time:* ${data?.camp_time}
    
        %0D%0A%0D%0A*Register:* https%3A%2F%2Fapp.bscglobaledu.com%2F%23%2FEventDetails%2F${data?.campaignid}
        %0D%0A%0D%0A*Speaker:* ${data?.uni_representative}

        %0D%0A%0D%0A*About:* ${about.replaceAll(",","")}
        `;

        return `https://api.whatsapp.com/send?text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")}`;
    }

    return (
        <div className={classes.eventCard} 
        //     onClick={() => history.push({
        //     pathname: '/add-event',
        //     state: { data: data, countData: countData }
        // })}

        onClick={() => {
            history.push({
                pathname: '/EventDetails/'+data?.campaignid,
                state: {
                    val:data,
                    countData:countData[`count_${data?.campaignid}`],
                    joinData:isJoined
                }
            })
        }}
            style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)', }}
        >
            <div className={classes.eventListContainer}>
                <img alt="" style={{ width: "300px" }} className={classes.partyImage} 
                src={`https://erp.bscglobaledu.com/${data.path}/${data.attachmentsid}_${data.storedname}`}
                // src={`${process.env.PUBLIC_URL}/img/EVENTS/${data?.campaignname}.png`}
                 />
            </div>

            <div style={{ marginTop: '16px', marginBottom: '16px' }} className={classes.container2}>
                <div>
                    <PageTitle bold sx={{ fontSize: '16px', lineHeight: '18px', color: '#1A1A1A', marginBottom: '3%' }} pageTitle={data?.campaignname || ''} />
                </div>
                <div>
                   
                    {/* <p style={{ marginTop: '14px' }} className={classes.distance}>2km</p> */}
                </div>
            </div>

            <Chip label={`${moment(data?.event_date).format("MMM Do YYYY")}`} color="error"/>
                            <Chip label={`${data?.camp_time} IST`} color="success"/>
                        
            <div className={classes.container3}>
         
                <div style={{ display: "flex", alignItems: "center" }}>
                    <main className={classes.joined}>{newCount > 0 ? `${newCount} Attending` : getCountData()}</main>
                    <img alt={''} className={classes.img} src={`${process.env.PUBLIC_URL}/img/displayPicture.jpg`} />
                    <div className={classes.userLength} style={{ background: "#ffcccb", color: "red", }}>
                        <main style={{ fontSize: "14px", textAlign: "left" }}>
                            +25
                        </main>
                    </div>
                </div>
                <div className="col-5 p-0 pe-2 ps-2">
                    <div className="row m-0 d-flex justify-content-between align-items-center">
                        {
                            isJoined ? <a className="col-7 userCount whtApp pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly bg-success">
                             <p className="mb-0 join">Joined</p>
                             </a>
                             :
                        <a className="col-7 userCount whtApp pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly">
                            {getUserId() ? <p className="mb-0 join" onClick={()=>joinEvent(data)}>Join</p>  : <p className="mb-0 join" onClick={()=>openModal(data)}>Join</p>  }
                        </a>
                        
                        }
                        
                        <div className="col-3 share pt-1 pb-1 p-0 d-flex align-items-center justify-content-evenly">
                            <a href={shareEvent(data)} target="_blank" >
                            <img src={Share} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Whatsapp join modal */}
            <Dialog
            onClose={handleClose}
            open={open}
    
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            keepMounted
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Join Event
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {
                    accPresent 
                    ?
                    <>
                    <Typography>Account Already exist with {email}. Please Login</Typography>
                    <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                component={Link}
                                to="/login"
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Login
                            </Button>
                            </Grid>
                    </>
                    :
                    joinSuccess ? 
                    <>
                    <Alert severity="success">Event Joined successfully..</Alert>
                    </>

                    :
                
            <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
              <Grid xs={12}>
          <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px", margin: "16px 0",
                                }}
                            >
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.2686 7.06116L12.0031 10.4954C11.1959 11.1283 10.0644 11.1283 9.25714 10.4954L4.95508 7.06116"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.88787 1.5H15.3158C16.6752 1.51525 17.969 2.08993 18.896 3.0902C19.823 4.09048 20.3022 5.42903 20.222 6.79412V13.322C20.3022 14.6871 19.823 16.0256 18.896 17.0259C17.969 18.0262 16.6752 18.6009 15.3158 18.6161H5.88787C2.96796 18.6161 1 16.2407 1 13.322V6.79412C1 3.87545 2.96796 1.5 5.88787 1.5Z"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>

                                <Input
                                    // style={{ width: "80%",marginLeft: "10px"}}
                                    type="email"
                                    name="email_address"
                                    sx={{width: "80%"}}
                                    autoFocus
                                    placeholder="Email@gmail.com"
                                    error={!email}
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </Grid>
                            {/* {!email ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Email</FormHelperText> : ''} */}
                        </Grid>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px 30px",
                                    margin: "16px 0",
                                }}
                            >
                                <PhoneInput
                                    country={'in'}
                                    name='phone_mobile'
                                    placeholder="Enter Number"
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: "0",
                                        opacity: "0.8"
                                    }}
                                      value={phone}
                                    searchPlaceholder={'Mobile Number'}
                                    inputClass={"mobile-field"}
                                    onChange={(phone) => {
                                        setPhone(phone)
                                        //   handleChange()
                                    }}
                                    // value={}
                                //   onChange={formik.handleChange}
                                />
                                {/*<input*/}
                                {/*    type="number"*/}
                                {/*    className={classes.input}*/}
                                {/*    autoFocus*/}
                                {/*    placeholder="Mobile Number"*/}
                                {/*/>*/}
                            </Grid>
                            {/* {!phone ?
                                <FormHelperText style={{ marginTop: "10px", color: "red" }} id="component-error-text">Please
                                    Enter Phone Number</FormHelperText> : ''} */}
                        </Grid>
                        <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                onClick={()=>joinWhatsapp(campaignData)}
                                disabled={!email || !phone}
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Join
                            </Button>
                            </Grid>
                        </Grid>
          </Box>
}
            </DialogContent>
        </Dialog>
        </div >
    );
};

export default EventsCard;

const useStyles = makeStyles((theme) => ({
    eventCard: {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        minHeight: '350px',
        padding: '18px 15px',
        marginBottom: '20px'
    },
    container2: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "2%",
    },
    container3: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
    },
    eventListContainer: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        borderRadius: '20px'
    },
    partyImage: {
        width: '100%',
        borderRadius: '20px'
    },
    location: {
        display: "flex",
        alignItems: "center",
    },
    date: {
        backgroundColor: Colors.lightBlue,
        borderRadius: "10px",
    },
    dateText: {
        fontSize: "14px",
        padding: "5px",
        fontWeight: "bold",
        marginBottom: '2%',
        background: "#ffcccb",
        color: "red",
        borderRadius: 25
    },
    distance: {
        fontSize: "14px",
        display: "flex",
        color: '#CED0D5',
        justifyContent: "flex-end",
        fontWeight: 'bold',
        lineHeight: '20px',
        marginBottom: 0,
    },
    joined: {
        fontWeight: "bold",
    },
    img: {
        height: "30px",
        width: "30px",
        borderRadius: "100%",
        marginLeft: "10px",
    },
    userLength: {
        backgroundColor: Colors.blue,
        borderRadius: "50%",
        padding: "7px",
    },
}));
