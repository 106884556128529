import React, { useState, useEffect, Suspense } from "react";
import TopStepper from "./Stepper";
import { Grid, Typography } from "@mui/material";
import University from "./University";
import IconsPage from "./IconsPage";
import CardsOne from "./Cards/Cards";
import CountryCard from "./Cards/CuntrysCard";
import Birthday from "./Cards/Birthday";
import GreCard from "./Cards/Gre";
import Step from "./Sliders/Slider1";
// import Slider from 'react-slick';
import Chip from '@mui/material/Chip';
import { red } from '@mui/material/colors';

import '../Dashboards/Sliders/Slider1/slider.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import DashStepper from "./Dashfor/DashStepper";
import { useHistory, Redirect } from 'react-router-dom';
import { OPERATION, operationValue } from "../../Controller/Global";
import { getSessionId, getUser, getUserId, SESSION_ID, USER_ID, clearUserData } from "../../Controller/localStorageHandler";
import _ from 'lodash'
import {
    fetchRelatedProgramAction,
} from "../Action";
import { Box } from "@mui/system";
import ResultCard1 from "../Result/ResultCard1";
import { MatchingUnivercityPopUp } from "./MatchingUnivercity";
import ContentLoader from 'react-content-loader'
import BookMarkMessageModal from "../Result/BookMarkMessageModal";
import PopUpModal from "../Result/ApplyModal";
import FavouriteModal from "../University/FavouriteModal";
import axios from "axios";
import EventsCard from "../Event/EventsCard";
// import DownloadModal from "../Result/DownloadModal";
import useAppContext from "../../useContext/userContext"

const DownloadModal = React.lazy(() => import('../Result/DownloadModal'));
const ProjectStatusModal = React.lazy(() => import('../Result/ProjectStatusModal'));
const DashSlider = React.lazy(() => import('./Sliders/DashSlider'));



const DashBoard = (props) => {
    const history = useHistory()
    const { sessionInvalid,
        getFavouriteUniversities, favourite_universities,
        getParteneredUniversities, partenered_universities,
        getMatchingUniversities, matching_universities,
        getApplicationStatus, application_status_student,
        getPossibleScholarShips, possible_scholarship_list,
        getSop, sop_list,
        getEventList, event_list,
        loading, setLoading,
        GetUploadDocument,
    } = useAppContext()



    const [PossibleScholarshipsData, setPossibleScholarshipsData] = useState([]);
    const [fetchRelaventSopData, setfetchRelaventSopData] = useState([]);
    const [fetchRelaventProgrameData, setFetchRelaventProgrameData] = useState([]);
    const [ApplicationStatus, setApplicationStatus] = useState('All');
    const [openFav, setOpenFav] = React.useState(false)
    const [bookmarkId, setBookmarkId] = React.useState('')
    const [openApply, setOpenApply] = React.useState(false);
    const [isBookMark, setIsBookMark] = React.useState(false);
    const [applyData, setApplyData] = React.useState('')
    const [matchingUniverCityModal, setMatchingUniverCityModal] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [openMsg, setOpenMsg] = React.useState(false)
    const [bookmarkMsg, setBookmarkMsg] = React.useState('')
    const [status, setStatus] = React.useState('')
    const [openDownload, setOpenDownload] = React.useState(false)
    const [SopData, setSopData] = React.useState('')


    const fetchRelaventPrograms = () => {
        setLoading(true)
        var data = JSON.stringify({
            "user_id": getUserId(),
            "limit_start": 1,
            "limit_end": 10,
            "bypass": "validation"
        });

        fetchRelatedProgramAction(data)
            .then((data) => {
                // console.log(data)
                setFetchRelaventProgrameData(data.data)
                setLoading(false)
            }).catch((error) => {
                setFetchRelaventProgrameData([])
                setLoading(false)
            })

    }



    useEffect(() => {
        setLoading(true)
        GetUploadDocument()
        fetchRelaventPrograms()
        getApplicationStatus(ApplicationStatus)
        getFavouriteUniversities(`All`, 'Universities', JSON.stringify(getUser()))

        getMatchingUniversities('All', 'Universities', JSON.stringify(getUser()))
        getParteneredUniversities('All', 'Universities', JSON.stringify(getUser()))


        getPossibleScholarShips()

        getSop()


        getEventList()

    }, [])

    useEffect(() => {
        if (getUser()?.related_programs === '' ||
            getUser()?.country === '' ||
            getUser()?.semester_looking_for === '' ||
            getUser()?.year_looking_for === '') {
            setMatchingUniverCityModal(true)
        }
    }, [getUser()])

    const handleConfirm = async (value) => {
        const bookmark = new FormData()
        bookmark.append(USER_ID, getUserId())
        bookmark.append(SESSION_ID, getSessionId())
        bookmark.append(OPERATION, operationValue.Bookmark)
        bookmark.append('universitiesid', bookmarkId)

        if (status === 'Favourite') {
            bookmark.append('btype', 'University')
        } else if (status === 'Matching') {
            bookmark.append('btype', 'University')
        } else if (status === 'scholarship') {
            bookmark.append('btype', 'Scholarship')
        } else if (status === 'Sop') {
            bookmark.append('btype', 'SOP')
        }
        bookmark.append('studentid', getUserId())
        if (value === 'add') {
            bookmark.append('bookmarkstatus', 1)

        } else {
            bookmark.append('bookmarkstatus', 0)
        }
        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", bookmark)
            .then(response => {
                if (response.data.status === 'success') {
                    setOpenFav(false)
                    setOpenMsg(true)
                    setBookmarkMsg(value === 'add' ? 'Bookmark successfully added' : 'Bookmark successfully removed')
                    getFavouriteUniversities(`All`, `Universities`, getUser(), true)
                    getParteneredUniversities(`All`, `Universities`, getUser(), true)
                    getMatchingUniversities(`All`, `Universities`, getUser(), true)


                }
            })
    }
    var settings = {
        // dots: true,
        centerMode: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        rows: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    rows: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    rows: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                }
            }
        ]
    };
    if (sessionInvalid) {
        clearUserData()
        return <Redirect to="/" />
    }

    if (loading) {
        return (<ContentLoader height={window.innerHeight} width={window.innerWidth} viewBox="0 0 265 230">
            <rect x="15" y="15" rx="4" ry="4" width="200" height="25" />
            <rect x="15" y="50" rx="2" ry="2" width="40" height="15" />
            <rect x="75" y="45" rx="16" ry="16" width="55" height="22" />
            <rect x="15" y="75" rx="3" ry="3" width="215" height="15" />
            <rect x="15" y="105" rx="3" ry="3" width="50" height="15" />
            <rect x="75" y="105" rx="3" ry="3" width="50" height="15" />
            <rect x="135" y="105" rx="3" ry="3" width="50" height="15" />
            <rect x="15" y="135" rx="16" ry="16" width="55" height="22" />
            <rect x="15" y="165" rx="2" ry="2" width="150" height="50" />
            <rect x="215" y="180" rx="2" ry="2" width="40" height="20" />
        </ContentLoader>)
    } else {
        return (
            <Grid
                sx={{
                    margin: "0px",
                    padding: "0px",
                    backgroundColor: "#FAFAFA",
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                }}
            >

                <Suspense fallback={<div>Loading...</div>}>
                    <DashSlider />
                </Suspense>



                <br />

                <Typography sx={{ marginTop: "40px" }}></Typography>

                <></>
                {application_status_student?.application_status_student?.data?.data?.length > 0 &&
                    <>
                        <div className={'dashBackColor'} classes={{ paddingBottom: '20px' }}>
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                item>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        marginTop: "20px"
                                    }}>
                                    <Chip label="Applications" size="small" color="success" />
                                    {" "}
                                    <Chip label={application_status_student?.application_status_student?.data?.TotalRecords || 0} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}

                                </Typography>
                            </Grid>
                            <Grid xs={12} container spacing={3}>
                                {application_status_student?.application_status_student?.data?.data?.map((d, i) => {
                                    return <Grid item xs={12} md={4}>
                                        <Box
                                            key={i}
                                            sx={{
                                                backgroundColor: "#fff",
                                                padding: "10px 0px",
                                                borderRadius: "10px",
                                                width: "100%",
                                                mt: 3,
                                                boxShadow: "0px 3px 3px -2px rgba(0 0 0 / 0.2), 0px 3px 4px 0px rgba(0 0 0 / 0.4), 0px 1px 8px 0px rgba(0 0 0 / 0.2)"
                                            }}
                                        > <TopStepper
                                                ApplicationStatus={d?.projecttaskstatus || ''}
                                                ApplicationFee={d?.international_app_fee || ''}
                                                TYPE="STATUS"
                                                val={d}
                                                setOpen={setOpen}
                                                open={open}
                                                setApplyData={setApplyData}
                                                setOpenApply={setOpenApply}
                                            />
                                        </Box>
                                    </Grid>
                                })
                                }
                            </Grid>
                        </div>
                        <br />
                        <br />
                    </>
                }

                {partenered_universities?.partenered_universities?.data?.length > 0 &&
                    <>
                        <div className={'dashBackColor'}>
                            <Grid
                                xs={12}
                                item
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        marginTop: "20px",
                                        zIndex: '1',
                                    }}>
                                    <Chip label="Partnered Universities" size="small" color="warning" />
                                    {" "}
                                    <Chip label={partenered_universities?.partenered_universities?.TotalRecords || ''} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}
                                </Typography>
                                <div onClick={() => {
                                    history.push({
                                        pathname: '/UniversityFavourites',
                                        state: {
                                            tab: 'Partnered',
                                        }
                                    })
                                }} style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                                    <Typography
                                        sx={{
                                            color: "#1976d2",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            margin: "0"
                                        }}>
                                        See all
                                    </Typography>
                                </div>
                            </Grid>
                            <div style={{ display: "flex", overflowX: "scroll", width: "100%", paddingBottom: 15, }}>
                                {_.chunk(partenered_universities?.partenered_universities?.data, 2).slice(0, 25).map((d) => {
                                    return <div style={{ flex: "0 0 auto", }} >
                                        {
                                            d.map((val) => {
                                                // console.log(val)
                                                var isApplied = _.some(application_status_student?.application_status_student?.data?.data, ['universitiesid', val?.universitiesid])
                                                return <University
                                                    university={val?.university}
                                                    universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                    programName={val?.program}
                                                    semester={val?.funding_type ? val?.funding_type : val?.semester}
                                                    Dates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                    val={val}
                                                    type="Matching"
                                                    setBookmarkId={setBookmarkId}
                                                    setOpenFav={setOpenFav}
                                                    setStatus={setStatus}
                                                    setApplyData={setApplyData}
                                                    setOpenApply={setOpenApply}
                                                    setIsBookMark={setIsBookMark}
                                                    isApplied={isApplied}
                                                />
                                            })
                                        }
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <br />
                        <br />
                    </>
                }

                {matching_universities?.matching_universities?.data?.length > 0 &&
                    <>
                        <div className={'dashBackColor'}>
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                item>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        marginTop: "20px"
                                    }}>
                                    <Chip label="Matching Universities" size="small" color="info" />
                                    {" "}
                                    <Chip label={matching_universities?.matching_universities?.TotalRecords || 0} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}


                                </Typography>
                                <div onClick={() => {
                                    history.push({
                                        pathname: '/UniversityFavourites',
                                        state: {
                                            tab: 'Matching',
                                        }
                                    })
                                }} style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                                    <Typography
                                        sx={{
                                            color: "#1976d2",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            margin: "0"
                                        }}>
                                        See all
                                    </Typography>
                                </div>
                            </Grid>
                            <div style={{ display: "flex", overflowX: "scroll", width: "100%", paddingBottom: 15 }}>
                                {_.chunk(matching_universities?.matching_universities?.data, 2).slice(0, 25).map((d, i) => {
                                    return <div style={{ flex: "0 0 auto", }} key={i}>
                                        {
                                            d.map((val) => {
                                                var isApplied = _.some(application_status_student?.application_status_student?.data?.data, ['universitiesid', val?.universitiesid])
                                                return <University
                                                    university={val?.university}
                                                    universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                    programName={val?.related_programs ? val?.related_programs : val?.program}
                                                    semester={val?.funding_type ? val?.funding_type : val?.semester}
                                                    Dates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                    val={val}
                                                    type="Matching"
                                                    setBookmarkId={setBookmarkId}
                                                    setOpenFav={setOpenFav}
                                                    setStatus={setStatus}
                                                    setApplyData={setApplyData}
                                                    setOpenApply={setOpenApply}
                                                    setIsBookMark={setIsBookMark}
                                                    isApplied={isApplied}
                                                />
                                            })
                                        }
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <br />
                        <br />
                    </>
                }

                {favourite_universities?.favourite_universities?.data?.length > 0 &&
                    <>
                        <div className={'dashBackColor'}>
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                item>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        marginTop: "20px"
                                    }}>
                                    <Chip label="Shortlisted Universities" size="small" color="success" />
                                    {" "}
                                    <Chip label={favourite_universities?.favourite_universities?.TotalRecords || 0} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}


                                </Typography>
                                <div onClick={() => {
                                    history.push({
                                        pathname: '/UniversityFavourites',
                                        state: {
                                            tab: 'Favourite',
                                        }
                                    })
                                }}
                                    style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                                    <Typography
                                        sx={{

                                            color: "#1976d2",
                                            fontSize: "16px",
                                            fontWeight: "450",
                                            margin: "0"
                                        }}>
                                        See all
                                    </Typography>
                                </div>
                            </Grid>
                            <div style={{ display: "flex", overflowX: "scroll", width: "100%", paddingBottom: 15 }}>
                                {_.chunk(favourite_universities?.favourite_universities?.data, 2).map((d, i) => {
                                    return <div style={{ flex: "0 0 auto", }} key={i}>
                                        {
                                            d.map((val) => {
                                                var isApplied = _.some(application_status_student?.application_status_student?.data?.data, ['universitiesid', val?.universitiesid])
                                                return <University
                                                    type="Favourite"
                                                    university={val?.university}
                                                    universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                    programName={val?.related_programs ? val?.related_programs : val?.program}
                                                    semester={val?.funding_type ? val?.funding_type : val?.semester}
                                                    Dates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                    val={val}
                                                    setBookmarkId={setBookmarkId}
                                                    setOpenFav={setOpenFav}
                                                    setStatus={setStatus}
                                                    setApplyData={setApplyData}
                                                    setOpenApply={setOpenApply}
                                                    setIsBookMark={setIsBookMark}
                                                    isApplied={isApplied}
                                                />
                                            })
                                        }
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <br />
                        <br />
                    </>
                }

                {possible_scholarship_list?.possible_scholarship_list?.data?.data?.length > 0 &&
                    <>
                        <div className={'dashBackColor'}>
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                item>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        marginTop: "20px"
                                    }}>

                                    <Chip label="Possible Scholarships" size="small" color="warning" />
                                    {" "}
                                    <Chip label={possible_scholarship_list?.possible_scholarship_list?.data?.TotalRecords || 0} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}

                                </Typography>
                                <div onClick={() => {
                                    let PossibleScholarshipsDatas = {
                                        screenName: 'Possible Scholarships',
                                        ...PossibleScholarshipsData
                                    }
                                    history.push({
                                        pathname: '/UniversitySearchResult',
                                        state: { data: PossibleScholarshipsDatas, screenName: 'Possible Scholarships' }
                                    })
                                }} style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                                    <Typography
                                        sx={{

                                            color: "#1976d2",
                                            fontSize: "16px",
                                            fontWeight: "450",
                                            margin: "0"
                                        }}>
                                        See all
                                    </Typography>
                                </div>
                            </Grid>
                            <div style={{ display: "flex", overflowX: "scroll", width: "100%", whiteSpace: "nowrap", paddingBottom: 15 }}>
                                {_.chunk(possible_scholarship_list?.possible_scholarship_list?.data?.data || [], 2).map((d, i) => {
                                    return <div key={i} >
                                        {
                                            d.map((val) => {
                                                return <University
                                                    type="scholarship"
                                                    university={val?.university}
                                                    universityName={val?.scholarship_name ? val?.scholarship_name : val?.university}
                                                    funding_type={val?.funding_type || ''}
                                                    bookmark={val?.bookmark || ''}
                                                    schollrshipDates={val?.app_end_date ? val?.app_end_date : val?.inter_adm_deadline}
                                                    val={val}
                                                    setBookmarkId={setBookmarkId}
                                                    setOpenFav={setOpenFav}
                                                    setStatus={setStatus}
                                                    setIsBookMark={setIsBookMark}
                                                />
                                            })
                                        }
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <br />
                        <br />
                    </>
                }

                {sop_list?.sop_list?.data?.data?.length > 0 &&
                    <>
                        <div className={'dashBackColor'}>
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                item>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        marginTop: "20px"
                                    }}>
                                    <Chip label="Statement of Purpose" size="small" color="success" />
                                    {" "}
                                    <Chip label={sop_list?.sop_list?.data?.TotalRecords || 0} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}


                                </Typography>
                                <div onClick={() => {
                                    let fetchRelaventSopDatas = {
                                        screenName: 'Statement of Purpose(SOP)',
                                        ...fetchRelaventSopData
                                    }
                                    history.push({
                                        pathname: '/UniversitySearchResult',
                                        state: { data: fetchRelaventSopDatas, screenName: 'Statement of Purpose(SOP)' }
                                    })
                                }}
                                    style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                                    <Typography
                                        sx={{

                                            color: "#1976d2",
                                            fontSize: "16px",
                                            fontWeight: "450",
                                            margin: "0"
                                        }}>
                                        See all
                                    </Typography>
                                </div>
                            </Grid>
                            <div style={{ display: "flex", overflowX: "scroll", width: "100%", whiteSpace: "nowrap", justifyContent: "space-between" }}>
                                {_.chunk(sop_list?.sop_list?.data?.data || [], 2).map((d, i) => {
                                    return <div key={i}>
                                        {
                                            d.map((val) => {
                                                return <Box sx={{
                                                    bgcolor: '#fff',
                                                    borderRadius: 3,
                                                    pt: 2,
                                                    pb: 2,
                                                    mb: 2,
                                                    paddingBottom: "20px",
                                                    height: '100px',
                                                    boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.1)",
                                                    width: 300,
                                                    marginRight: 2,
                                                }}
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: '/CollegeDetails/',
                                                            state: { data: val }
                                                        })
                                                        // history.push(`/CollegeDetails/`, val)
                                                    }}
                                                >
                                                    <ResultCard1
                                                        type="Sop"
                                                        val={val}
                                                        setBookmarkId={setBookmarkId}
                                                        // setApplyData={props?.location?.state?.RelatedPrograms ? setApplyData : null}
                                                        // setOpenApply={props?.location?.state?.RelatedPrograms ? setOpenApply : null}
                                                        setOpenFav={setOpenFav}
                                                        setStatus={setStatus}
                                                        setSopData={setSopData}
                                                        setOpenDownload={setOpenDownload}
                                                    />
                                                </Box>
                                            })
                                        }
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <br />
                        <br />
                    </>
                }
                {/* evaluate profile start */}
                <div className={'dashBackColor'} >
                    <Typography
                        sx={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "700",
                            pt: 3,
                            mb: 3
                        }}>
                        <Chip label="Evaluate Profile" size="small" color="info" />

                    </Typography>
                    {/*<Typography*/}
                    {/*    sx={{*/}
                    {/*    fontSize: "16px",*/}
                    {/*    fontWeight: "500",*/}
                    {/*    marginTop: "20px",*/}
                    {/*    color: "#000"*/}
                    {/*}}>*/}
                    {/*    Question 1/3*/}
                    {/*</Typography>*/}
                    {/*<div className={classes.containerPer}>*/}
                    {/*    <div className={classes.percentage} width="100%"></div>*/}
                    {/*</div>*/}
                    <DashStepper />

                </div>
                {/* evaluate  */}


                {/* all search's start */}
                <div className={'dashBackColor'}>
                    <Typography
                        sx={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "700",
                            mt: 3,
                            ml: 1
                        }}>


                        <Chip label="All Search's" size="small" color="warning" />

                    </Typography>
                    <IconsPage />
                </div>

                <div style={{ marginBottom: '20px' }}></div>
                {/* Featured universities start */}
                <div className={'dashBackColor'}>
                    <Grid
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                        item>
                        <Typography
                            sx={{
                                color: "#000",
                                fontSize: "18px",
                                fontWeight: "700",
                                marginTop: "20px"
                            }}>
                            <Chip label="Featured Universities" size="small" color="info" />
                            {" "}
                            <Chip label={fetchRelaventSopData?.TotalRecords || 0} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}

                        </Typography>
                        <div style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                            <Typography
                                sx={{
                                    color: "#1976d2",
                                    fontSize: "16px",
                                    fontWeight: "450",
                                    marginBottom: "0"
                                }}>
                                See all
                            </Typography>
                        </div>
                    </Grid>
                    <CardsOne />
                </div>
                <br />

                <Typography sx={{ marginTop: "30px" }}></Typography>
                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1
                    }}
                    item>
                    <Typography
                        sx={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "20px"
                        }}>
                        <Chip label="Related Programs" size="small" color="primary" />
                        {' '}
                        <Chip label={fetchRelaventProgrameData?.length > 0 && fetchRelaventProgrameData['0'].alltotal} size="small" sx={{ backgroundColor: red[800], color: '#fff' }} /> {" "}


                    </Typography>
                    <div style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                        <Typography
                            onClick={() => {
                                history.push('/related-Program')
                            }}
                            sx={{
                                color: "#1976d2",
                                fontSize: "16px",
                                fontWeight: "450",
                                margin: "0"
                            }}>
                            See all
                        </Typography>
                    </div>
                </Grid>
                <div style={{ display: "-webkit-box", overflowY: "scroll", width: "100%", whiteSpace: "nowrap", justifyContent: "space-between" }}>
                    {
                        fetchRelaventProgrameData.length > 0 && fetchRelaventProgrameData.map((d) => {
                            return <CountryCard Data={d} />
                        })
                    }
                </div>
                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1
                    }}
                    item>
                    <Typography
                        sx={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "700",
                            marginTop: "20px"
                        }}>
                        <Chip label="Popular Events" size="small" color="success" />


                    </Typography>
                    <div style={{ marginTop: 10, display: 'inline-block', padding: '10px 0' }}>
                        <Typography
                            onClick={() => history.push("/EventsList")}
                            sx={{
                                color: "#1976d2",
                                fontSize: "16px",
                                fontWeight: "450",
                                margin: "0"
                            }}>
                            See all
                        </Typography>
                    </div>
                </Grid>
                {/* <CarouselProvider
        naturalSlideWidth={90}
        naturalSlideHeight={100}
        isPlaying={true}
        interval={3000}
        totalSlides={EventListData?.data?.length}
        style={{ margin: "15px 0 50px 0", maxHeight: 300 }}
        
      >
        <Slider>
          {EventListData?.data?.length > 0 && EventListData?.data?.map((d,i) => {
                        return  <Slide index={i}><Birthday data={d} countData={EventListData?.count_data || null} /></Slide>
                    })}
        </Slider>
      </CarouselProvider> */}


                <Slider className={'gre-slider third-slider'} style={{ margin: "15px 0", maxHeight: 300, width: "100%" }} {...settings}>
                    {event_list?.event_list?.data?.length > 0 && event_list?.event_list?.data?.map((d, i) => {
                        return <Birthday key={i} data={d} countData={event_list?.event_list?.count_data || null} />
                        // return <EventsCard data={d} countData={EventListData?.count_data || null} joinData={EventListData?.join_data || null} />
                    })}
                </Slider>

                <br />

                <Typography sx={{ marginTop: "200px" }}></Typography>
                <Typography
                    sx={{
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: "700",
                        mt: 4,
                        ml: 1
                    }}>
                    <Chip label="Countries" size="small" color="warning" />


                </Typography>
                <Step />
                {/*<BottomNav/>*/}
                <FavouriteModal open={openFav} isBookMark={isBookMark} handleClose={() => setOpenFav(false)} confirm={(value) => handleConfirm(value)} />
                <PopUpModal open={openApply} handleClose={() => setOpenApply(false)} data={applyData} />
                <BookMarkMessageModal open={openMsg} handleClose={() => setOpenMsg(false)} data={bookmarkMsg} />
                <MatchingUnivercityPopUp setLoading={(d) => setLoading(d)} onclose={() => setMatchingUniverCityModal(false)} matchingUniverCity={matchingUniverCityModal} />

                {/* <Suspense fallback={<div>Loading...</div>}>
                <ProjectStatusModal status="No Application" />
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                <ProjectStatusModal status="Deposit Due" />
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                <ProjectStatusModal status="Visa Yet To Apply" />
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                <ProjectStatusModal status="Visa Applied" />
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                <ProjectStatusModal status="Visa Approved" />
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                <ProjectStatusModal status="Not Enrolled" />
                </Suspense>
                */}

                <Suspense fallback={<div>Loading...</div>}>
                    <ProjectStatusModal status="Info/Doc Pending" />
                    <DownloadModal open={openDownload} handleClose={() => setOpenDownload(false)} SopData={SopData} />
                </Suspense>
            </Grid >
        );
    }
};

export default DashBoard;
