import React from 'react';
import { useHistory } from 'react-router-dom';
import './Students.css';

const StudentsCard = ({ studentsData, persent, today_modified }) => {
    const history = useHistory()

    const { status, count } = studentsData;

    return (
        <div className="d-flex align-items-center follow_up_card" style={{ boxShadow: "0px 10px 20px rgba(0 0 0 / 0.4), 0px 2px 6px rgba(0 0 0 / 0.4), 0px 0px 1px rgba(0 0 0 / 0.4)", justifyContent: "space-between", paddingLeft: 20, paddingRight: 30 }}
            onClick={() => {
                history.push({
                    pathname: '/student-list',
                    state: {
                        'status': status !== "Total" ? status : 'All',
                        type: "Student"
                    }
                })
            }}
        >
            {/* <div>
                <img src={logo1} alt="" />
            </div> */}
            <div>
                <h3 className="budget_text" style={{ color: "#1B6CFC", fontWeight: "bold", fontSize: 16 }} >{status}</h3>
                <h4 style={{ fontWeight: "bold", fontSize: 16 }} className="budget_title">{today_modified ? `${today_modified} transactions` : ""}</h4>
            </div>
            <div>
                <h3 style={{ color: "#1B6CFC", fontWeight: "bold", fontSize: 16 }} className="budget_text">{`${count}`}</h3>
                <h4 style={{ fontWeight: "bold", fontSize: 16 }} className="budget_title">{persent}</h4>
            </div>
        </div>
    );
};

export default StudentsCard;