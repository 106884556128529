import React, { useEffect, useState } from 'react';

import { styled } from "@mui/material/styles";
import {useHistory, useParams} from "react-router-dom";





const University_Front = () => {
    const classes = useStyles();

    const { name } = useParams()
    console.log(name); 

    return (
    <React.Fragment>

   </React.Fragment >
    )
};

export default University_Front;
const useStyles = styled((theme) => ({
    cardBox: {
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px;',
        paddingLeft: '15px',
        paddingRight: '30px',
        marginTop: '15px',
    },
    radioGroup: {
        marginBottom: '5px'
    },
    root: {
        flexGrow: 1,
        bgcolor: '#E5E5E5',
        fontFamily: 'Montserrat'
    }

}));