import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import StudentList from './StudentList';


const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 5,
    height: "90%",
    overflowY: "scroll"
};

const AddStudentList = (props) => {


    return (
        <div>
            {/* <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={successModal}
                message={successMsg}
                autoHideDuration={4000}
            /> */}
            <Modal
                open={props.AddStudentOpen}
                onClose={() => { props.handleCloseAddStudentModal() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <StudentList confirm={props.confirm} source={props.source} SelectedStudentList={props.SelectedStudentList} setOpenApply={props.handleOpenAndClose} SetSelectedStudentList={props.SetSelectedStudentList} />
                </Box>

            </Modal>
        </div>
    );
}
export default AddStudentList;
