import React, { useState, useEffect } from "react";
// import party from "${process.env.PUBLIC_URL}/img/party.png";
//mui
import { makeStyles } from "@mui/styles";
import ShareIcon from "@mui/icons-material/Share";
import Chip from '@mui/material/Chip';
import PageTitle from "../../PageTitle";
import Colors from "../../../static/layout/Colors";
import {useParams, useHistory, Link, useLocation} from 'react-router-dom';
import moment from "moment";
import { fetchEventListAction, champianInsertAction } from '../../Action/index'
import { getSessionId, getUserId, getUserType } from "../../../Controller/localStorageHandler";
import _ from 'lodash'
import { Dialog, DialogContent, DialogTitle, IconButton, Box, Grid,Input,Button,FormHelperText,Alert } from '@mui/material'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";

import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CommonLogin } from "../../Forms/CommonLogin";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const EventsCardDetails = () => {
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation();
    
    const[data,setData] = useState([]);
    const[countData,setCountData] = useState('');
    const[isJoined,setIsJoined] = useState(false);

    const[open,setOpen] = useState(false);

    const[email,setEmail] = useState('');
    const[phone,setPhone] = useState('')
    const[accPresent,setAccPresent] = useState(false)
    const[joinSuccess,setJoinSuccess] = useState(false)
    const[campaignData,setCampaignData] = useState([]);
    const [openLogin,setOpenLogin] = useState(false)

    const openModal = (data) => {
        setOpen(true)
        setCampaignData(data)
    }

    
    const handleClose = () => {
        setOpen(false)
        setCampaignData([])
    }


    const joinWhatsapp = async (data) =>{
  

        const formData = new FormData()
        formData.append('operation','Whatsapp')
        formData.append('username','Student')
        formData.append('email_address',email)
        formData.append('phone_mobile',phone)
        formData.append('country',data?.country)
        formData.append('year',data?.year_looking_for)
        formData.append('semester',data?.semester_looking_for)
        formData.append('program',data?.related_programs)
        formData.append('leadsources','Webinar')

        await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/", formData).then(response=>{
            console.log(response)
            if(response?.data?.status === 'false'){
                joinEvent(data,response?.data?.data)
                setAccPresent(true)
            }
            else if(response?.data?.status === "success"){
                joinEvent(data,response?.data?.data)
            }
        })


    }

    const joinEvent = (data,userId) =>{
        setIsJoined(true);
                
                var formData = new FormData();
                formData.append('campaignid', data?.campaignid);
                formData.append('contactid', getUserId() || userId);
                formData.append("userid", getUserId() || userId)
                formData.append("sessionid", getSessionId())
                formData.append('validation','bypass')
                champianInsertAction(formData).then(res => {
                    if (res?.msg === "Success") {
                        setIsJoined(true);
                        setCountData(parseInt(countData)+parseInt(1))
                        setJoinSuccess(true)
                        toast.success(`Event Joined Successfully`)
        
                    } else {
                     
         
                    }
                }).catch((erro) => {
                    toast.error(`Event Add failed`)
                })
        
            }

    useEffect(() => {
        console.log('working')
        if(history?.location?.state){
            setData(history?.location?.state?.val)
            setCountData(history?.location?.state?.countData)
            setIsJoined(history?.location?.state?.joinData)
        }
        else{
        let formData = new FormData()
        formData.append("operation", "get_event_list")
        formData.append("campaigntype", "Spot Admissions")
        formData.append("userid", getUserId())
        formData.append("sessionid", getSessionId())
        formData.append("campainId",id);

        fetchEventListAction(formData).then((response) => {
            // setEventListData(response)
            console.log(response)
            setData(response?.data[0])
   
            setCountData(response?.count_data['count_'+id])
            if(_.find(response?.join_data, ['campaignid', id]) !== undefined){
                setIsJoined(true)
            }
        }).catch((error) => {
            console.log("error", error)
        })
        }
    }, [])

    const getAbout = (data) =>{
        {
            data?.venue?.split('||').map((item)=>{
                return(<>{item} <br/></>)
            })
        }
    }


    const shareEvent = (data) =>{
  
        let about = `${data?.venue?.split('||').map((item)=>item+'%0D%0A')}`
        let TempData = `You are invited for the Webinar on *${data?.campaignname}* ${data?.semester_looking_for} ${' '} ${data?.year_looking_for}
        %0D%0A%0D%0A*Date:* ${data?.event_date}
        %0D%0A%0D%0A*Time:* ${data?.camp_time}
    
        %0D%0A%0D%0A*Register:* https%3A%2F%2Fapp.bscglobaledu.com%2F%23%2FEventDetails%2F${id}
        %0D%0A%0D%0A*Speaker:* ${data?.uni_representative}

        %0D%0A%0D%0A*About:* ${about.replaceAll(",","")}
        `;
        return `https://api.whatsapp.com/send?text=%0D%0A%0D%0A ${TempData.replaceAll("&", "and")}`;
    }


    const classes = useStyles();

    return (
        <React.Fragment>
            <div>
            <Button sx={{ minWidth: '0px', paddingLeft: '0px' }} onClick={() => history.goBack()}><ArrowBackIcon /> <Typography style={{ fontSize: '12px' }}>Back To Event List</Typography></Button>

                </div>
            <div style={{ minHeight: 'auto' }} className={classes.eventCard}>
            
                <div className={classes.eventListContainer}>
                    <img height={300} alt={''} className={classes.partyImage} src={`https://erp.bscglobaledu.com/${data?.path}/${data?.attachmentsid}_${data?.storedname}`} />
                </div>
            </div>
            <div style={{ marginTop: '16px', marginBottom: '16px' }} className={classes.container2}>
                <div>
                    {/* <p style={{ fontSize: 14, lineHeight: '17px', fontWeight: 'normal', color: '#CED0D5', }}>Event</p> */}
                    <PageTitle bold sx={{
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#1A1A1A',
                        marginBottom: '3%',
                    }} pageTitle={data?.campaignname} />
                    {/* <p style={{fontSize:'12px',color: '#CED0D5'}}>{data?.campaigntype}</p> */}
                    {/* <p style={{ fontSize: 14, lineHeight: '17px', fontWeight: 'normal', color: '', }}>Date and
                        time</p> */}
                    <div style={{ marginTop: '14px' }} className={classes.location}>
                        <p className="mb-0"
                            style={{ fontSize: 14, lineHeight: '17px', fontWeight: 'bold', color: '#636363', }}>
                            <Chip label={`${moment(data?.event_date).format("MMM Do YYYY")}`} color="error"/>
                            <Chip label={`${data?.camp_time} IST`} color="success"/>
                        
                        </p>
                    </div>
                </div>
                <div>
                {
                    isJoined ? <a href={`${data?.zoom_link || data?.google_meet || data?.jio_meet}`} target="_blank"><Chip label="Go to Meet" color="primary"/></a>
                        :
                    getUserId() ? <Chip label="Join Meet" color="primary" onClick={()=>joinEvent(data)} />  : <Chip label="Join" color="primary" onClick={()=>setOpenLogin(true)} /> 
                      
                        }
                {/* <a href={shareEvent(data)} >
                    <ShareIcon sx={{
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                        padding: '7px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
                    }} />
                    </a> */}
                </div>
            </div>
            <div className={classes.container3}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img alt={''} className={classes.img} src={`${process.env.PUBLIC_URL}/img/displayPicture.jpg`} />
                    <div className={classes.userLength}>
                        <main style={{ fontSize: "14px", textAlign: "left", color: "white" }}>
                            {/* <span style={{position : 'relative', right : 1}}>+</span>25 */}
                            +25
                        </main>
                    </div>
                    <main  
            onClick={() => {
                history.push({
                    pathname: getUserId() ? getUserType() === 'Student' ? '/students' : '/student-list/'+data?.campaignid :'',
                    state: {
                        'status': data?.campaignname,
                        "type": "Event",
                        'campaignId': data?.campaignid,
                        'val': data,
                    }
                })
            }} 
            className={classes.joined}>{countData || '0'} Attending</main>

<button onClick={()=>window.location.href=shareEvent(data)} className={classes.inviteMore}>Invite</button>
                </div>
        
                
   
            </div>

           
                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                {/* <PageTitle bold sx={{
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#1A1A1A',
                    marginBottom: '3%',
                }} pageTitle={`Speakar`} /> */}
                <p style={{
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#1A1A1A',
                    marginBottom: '3%',
                }}>Speakar : <Box sx={{backgroundColor:'#ed6c02',padding:'5px',color:'#fff',fontSize:'13px'}}>{data?.uni_representative}</Box></p>
                
                {/* <p className={classes.description}>
              {/* <a href={data?.zoom_link || data?.google_meet || data?.jio_meet}> <button className={classes.moreBtn}>Join Meet</button></a> */}
                    {/* <p>{data?.uni_representative}</p>
                </p> */} 
            </div>
          

            

                
            

            <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <PageTitle bold sx={{
                    fontSize: '18px',
                    lineHeight: '22px',

                    marginBottom: '3%',
                }} pageTitle={`About Event`} />
                <p className={classes.description} style={{color:'#262323'}}>
                    {
                        data?.venue?.split('||').map((item,i)=>{
                            return(<>{item} <br/></>)
                        })
                    }
        
                </p>
            </div>

            

            

          
                {/* Whatsapp join modal */}

        <Dialog
            onClose={handleClose}
            open={open}
    
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            keepMounted
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Join Event
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {
                    accPresent 
                    ?
                    <>
                    <Typography>Account Already exist with {email}. Please Login</Typography>
                    <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                component={Link}
                                to="/login"
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Login
                            </Button>
                            </Grid>
                    </>
                    :
                    joinSuccess ? 
                    <>
                    <Alert severity="success">
                        {data?.zoom_link || data?.google_meet || data?.jio_meet}<br/>
                        <Chip label="Join Meet" onClick={()=>window.location.href= data?.zoom_link || data?.google_meet || data?.jio_meet} /></Alert>
                    </>

                    :
                
            <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
              <Grid xs={12}>
          <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px", margin: "16px 0",
                                }}
                            >
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.2686 7.06116L12.0031 10.4954C11.1959 11.1283 10.0644 11.1283 9.25714 10.4954L4.95508 7.06116"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.88787 1.5H15.3158C16.6752 1.51525 17.969 2.08993 18.896 3.0902C19.823 4.09048 20.3022 5.42903 20.222 6.79412V13.322C20.3022 14.6871 19.823 16.0256 18.896 17.0259C17.969 18.0262 16.6752 18.6009 15.3158 18.6161H5.88787C2.96796 18.6161 1 16.2407 1 13.322V6.79412C1 3.87545 2.96796 1.5 5.88787 1.5Z"
                                        stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>

                                <Input
                                    // style={{ width: "80%",marginLeft: "10px"}}
                                    type="email"
                                    name="email_address"
                                    sx={{width: "80%"}}
                                    autoFocus
                                    placeholder="Email@gmail.com"
                                    error={!email}
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </Grid>
                          
                        </Grid>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "start",
                                    borderRadius: "30px",
                                    border: "1px solid #EBF2FF",
                                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                    padding: "10px 30px",
                                    margin: "16px 0",
                                }}
                            >
                                <PhoneInput
                                    country={'in'}
                                    name='phone_mobile'
                                    placeholder="Enter Number"
                                    style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: "0",
                                        opacity: "0.8"
                                    }}
                                      value={phone}
                                    searchPlaceholder={'Mobile Number'}
                                    inputClass={"mobile-field"}
                                    onChange={(phone) => {
                                        setPhone(phone)
                                        //   handleChange()
                                    }}
                                    // value={}
                                //   onChange={formik.handleChange}
                                />
                       
                            </Grid>

                        </Grid>
                        <Grid xs={12} sx={{ margin: "auto", textAlign: "center" }}>
                            <Button
                                onClick={()=>joinWhatsapp(campaignData)}
                                disabled={!email || !phone}
                                variant="contained"
                                sx={{
                                    fontSize: "14px",
                                    fontWaight: "600",
                                    padding: " 8px  0",
                                    color: "#fff",
                                    backgroundColor: "#1B6CFC",
                                    borderRadius: "100px",
                                    width: "60%",
                                }}
                            >
                                Join
                            </Button>
                            </Grid>
                        </Grid>
          </Box>
}
            </DialogContent>
        </Dialog>
        <CommonLogin
        openLogin={openLogin}
        handleClose={setOpenLogin}
        return_url={location.pathname}
        campaignId={id}
         />
            
        </React.Fragment>
    );
};

export default EventsCardDetails;

const useStyles = makeStyles((theme) => ({
    eventCard: {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        minHeight: '350px',
        padding: '18px 15px',
        marginBottom: '20px',
        boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.05)'
    },
    container2: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
    },
    container3: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
    },
    eventListContainer: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        borderRadius: '20px'
    },
    partyImage: {
        width: '100%',
        borderRadius: '20px'
    },
    location: {
        display: "flex",
        alignItems: "center",
    },
    date: {
        backgroundColor: Colors.lightBlue,
        borderRadius: "10px",
    },
    dateText: {
        fontSize: "10px",
        padding: "8px",
        fontWeight: "bold",
        marginBottom: '2%'
    },
    distance: {
        fontSize: "14px",
        display: "flex",
        color: '#CED0D5',
        justifyContent: "flex-end",
        fontWeight: 'bold',
        lineHeight: '20px',
        marginBottom: 0,
    },
    joined: {
        fontWeight: "bold",
        marginLeft: "10px",
    },
    img: {
        height: "30px",
        width: "30px",
        borderRadius: "100%",
    },
    userLength: {
        backgroundColor: Colors.blue,
        borderRadius: "50%",
        padding: "7px",
    },
    inviteMore: {
        border: '0',
        color: '#ffffff',
        borderRadius: '52px',
        backgroundColor: '#1B6CFC',
        padding: '10px 20px',
        boxShadow: ' 0px 4px 19px rgba(27, 108, 252, 0.21)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '17px',
        marginLeft:'30px'
    },
    description: {
        fontSize: '16px',
        lineHeight: '23px',
        textAlign: 'justify',
        fontWeight: 'normal',
        color: '#888888'
    },
    moreBtn: {
        border: 0,
        backgroundColor: 'transparent',
        color: '#1B6CFC',
        fontWeight: 'bolder',
        fontSize: '18px'
    }
}));
