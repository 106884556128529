import React from "react";
import { Grid, Typography } from "@mui/material";
import RoomRoundedIcon from "@mui/icons-material/RoomRounded";
import { makeStyles } from "@mui/styles";
import Event1 from '../../../img/Events/Event1.jpeg'
import moment from "moment";
import { useHistory } from 'react-router-dom';
import Share from '../../../img/share.png';
import Colors from "../../../static/layout/Colors";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Birthday = ({ data, countData }) => {
  const history = useHistory()

  const getCountData = () => {
    let Data = 0
    Object.keys(countData).map((d) => {
      let id = d.replace("count_", "");
      if (id === data?.campaignid) {
        Data = countData[d]
      }
    })
    return `${Data ? Data : 0} Joined`
  }

  const classes = useStyles();
  return (
    <Grid
      onClick={() => history.push("/EventsList")}
      sx={{
        borderRadius: 4,
        boxShadow: 3,
        width: "100%",
        mt: 3,
        p: 2,
        position: "relative",
      }}
      style={{ marginRight: 15, height: 300 }}
    >
      <LazyLoadImage 
      // src={`${process.env.PUBLIC_URL}/img/EVENTS/${data?.campaignname}.png`} 
      src={`https://erp.bscglobaledu.com/${data.path}/${data.attachmentsid}_${data.storedname}`}
      alt='BurthdayImage' width="100%" style={{ borderRadius: "20px", height: 150 }} />
      <Grid xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid xs={8}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "20px",
              width: "100%",
              // whiteSpace: "nowrap",
              // overflow: "hidden !important",
              // textOverflow: "ellipsis"
            }}
          >
            {data?.campaignname || ''}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <button variant="contained" style={{ background: "#ffcccb", color: "red", fontSize:'10px' }} className={classes.CeleBtn}>{moment(data.event_date).format("MMM Do")}  </button>
        </Grid>
      </Grid>
      <div className={classes.container3}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <main className={classes.joined}>{getCountData()}</main>
          <LazyLoadImage alt={''} className={classes.img} src={`${process.env.PUBLIC_URL}/img/displayPicture.jpg`} />
          <div className={classes.userLength} style={{ background: "#ffcccb", color: "red", }}>
            <main style={{ fontSize: "14px", textAlign: "left"  }}>
              +25
            </main>
          </div>
        </div>
        <div className="col-5 p-0 pe-2 ps-2">
          <div className="row m-0 d-flex justify-content-between align-items-center">
            <a className="col-7 userCount whtApp pt-2 pb-2 p-0 d-flex align-items-center justify-content-evenly">
              <p className="mb-0 join">Join</p>
            </a>
            <div className="col-3 share pt-1 pb-1 p-0 d-flex align-items-center justify-content-evenly">
              <LazyLoadImage src={Share} alt="" />
            </div>
          </div>
        </div>
      </div>
      
    </Grid>
  );
};
export default Birthday;

const useStyles = makeStyles(() => ({
  texts: {
    color: "gray",
    right: "10px",
    display: "flex",
  },
  CeleBtn: {
    textTransform: "none",
    backgroundColor: "#c5d8fa",
    color: "#1b6cfc",
    padding: "0px 10px",
    borderRadius: "20px",
    border: "none",
    fontSize: "16px",
    fontWeight: "100",
    height: "auto",
    marginTop: "20px",
    float: "right",
  },
  Favator: {
    position: "absolute",
    right: "113px",
    // marginTop:"10px"
  },
  Savator: {
    position: "absolute",
    right: "100px",
    marginTop: "7px"

  },
  Tavator: {
    position: "absolute",
    right: "75px",
    marginTop: "7px"

  },
  Gavator: {
    position: "absolute",
    right: "50px",
    marginTop: "7px"

  },
  img: {
    height: "30px",
    width: "30px",
    borderRadius: "100%",
    marginLeft: "10px",
  },
  container3: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2%",
  },
  joined: {
    fontWeight: "bold",
    fontSize:'12px',
  },
  userLength: {
    backgroundColor: Colors.blue,
    borderRadius: "50%",
    padding: "7px",
  },
}));
