import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SearchIcon from '@mui/icons-material/Search';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { clearUserData, getUser, getUserId, setUser } from '../../../Controller/localStorageHandler';
import { barColor } from '../../../Controller/Global';
import ReactFlagsSelect from 'react-flags-select';
import Completed from '../../../img/Completed.png';
import Calling from '../../../img/Calling.png';
import whatsapp from '../../../img/whatsapp.png';
import Message from '../../../img/Message.png';
import { MentorDocumentDetailAction } from '../../Action';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AddStudentModal } from '../../Student/StudentList/AddStudentModal';
import profile_img from "../../../img/profile_img.ico";

import CircularProgress from '@mui/material/CircularProgress';
import _ from "lodash"
// import { useFormik } from 'formik'; 

import useAppContext from "../../../useContext/userContext"

import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import Switch from '@mui/material/Switch';

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import axios from 'axios'

export default function Navbar() {
    let UserLocalstorge = getUser()
    const {doc_list,upload_doc_list, GetUploadDocument} = useAppContext()

    let history = useHistory()
    const location = useLocation();
    const [state, setState] = React.useState({ top: false, left: false, bottom: false, right: false, });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mentorData, setmentorData] = React.useState({});
    const [open1, SetOpen] = React.useState(false)
    const [isNotify,setIsNotify] = React.useState(UserLocalstorge?.notify_owner === 'Yes' || UserLocalstorge?.notify_owner === '1' ? true : false);


    const handleNotification = () =>{
        let userId = getUserId()
        var data = JSON.stringify({
            "notify_owner_update": !isNotify,
            "userId": userId,
        });
        let config = {
            url: 'https://erp.bscglobaledu.com/mapi/student/ajax_update_student_contact',
            data: data,
            method: 'post'
        }
        axios(config).then((res)=>{
            // console.log(res)
            if(!isNotify === true){
                UserLocalstorge['notify_owner'] = 'Yes'
            }
            else{
                UserLocalstorge['notify_owner'] = 'No'
            }
            setUser(UserLocalstorge)
         
            setIsNotify(!isNotify)
        })
    }
    const open = Boolean(anchorEl);
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    let user_contact_type = UserLocalstorge?.user_contact_type || ''

    // percentage for menu 
    // console.log(UserLocalstorge)

    // document %
    let doc_percentage = 0;


    // looking for
    let looking_for = 0;

    if(UserLocalstorge?.degree_looking_for !== ''){
        looking_for = looking_for + 20
    }

    if(UserLocalstorge?.country !== ''){
        looking_for = looking_for + 20
    }

    if(UserLocalstorge?.semester_looking_for !== ''){
        looking_for = looking_for + 20
    }

    if(UserLocalstorge?.year_looking_for !== ''){
        looking_for = looking_for + 20
    }

    if(UserLocalstorge?.related_programs !== ''){
        looking_for = looking_for + 20
    }

    let personal_percentage = 0

    if(UserLocalstorge?.firstname !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.lastname !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.birthday !== '' || UserLocalstorge?.birthday !== '0000-00-00'){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.place_of_birth !== ''){
        personal_percentage = personal_percentage + 6.66
    }
    
    if(UserLocalstorge?.nationality !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.passport_no !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.gender !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.work_experience !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.father_name !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.parent_mobile !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.student_pan !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.co_application_pan !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.mobile !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.homephone !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    if(UserLocalstorge?.homephone !== ''){
        personal_percentage = personal_percentage + 6.66
    }

    // academic percentage
    let academic_percentage = 0

    if(UserLocalstorge?.bachelors_college_name !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.bachelors_year_of_passing !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.bachelors_percentage !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.number_backlogs !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.bachelors_subject !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.puc_percentage_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.puc_subjects_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.puc_college_name_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.puc_year_of_passing_12th !== ''){
        academic_percentage = academic_percentage + 10
    }

    if(UserLocalstorge?.german_language !== ''){
        academic_percentage = academic_percentage + 10
    }

    // test scores percentage
    let test_score = 0

    if(UserLocalstorge?.toefl_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.toefl_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.toefl_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.toefl_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.toefl_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.pte_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.pte_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.pte_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.pte_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.pte_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.ielts_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.ielts_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.ielts_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.ielts_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.ielts_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.duolingo_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.duolingo_reading_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.duolingo_listening_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.duolingo_writing_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.duolingo_speaking_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gre_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gre_awa !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gre_verbal_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gre_quants_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gmat_total_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gmat_awa !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gmat_verbal_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gmat_quants_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    if(UserLocalstorge?.gmat_quants_1st_attempt !== ''){
        test_score = test_score + 3.44
    }

    // recommend percentage

    let recommend_score = 0

    if(UserLocalstorge?.lor1_first_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor1_last_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor1_designation !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor1_employer !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor1_contact !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor1_email !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor2_first_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor2_last_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor2_designation !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor2_employer !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor2_contact !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor2_email !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor3_first_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor3_last_name !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor3_designation !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor3_employer !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor3_contact !== ''){
        recommend_score = recommend_score + 5.55
    }

    if(UserLocalstorge?.lor3_email !== ''){
        recommend_score = recommend_score + 5.55
    }

    


    // overall percentage
    let overall_percentage =   looking_for + personal_percentage + academic_percentage + test_score + recommend_score


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      

    const navListNoAuth = [
        { text: 'Events', url: '/EventsList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/add-event.png` },
        { text: 'Whatsapp Groups', url: '/WhatsappGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/whatsapp1.png` },
        { text: 'Telegram Groups', url: '/TelegramGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/telegram.png` },
        { text: 'Facebook Groups', url: '/FacebookGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.jpeg` },
        { text: 'Find Accommodation', url: '/accomodation', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png` },
    ]


    const studentMenuList = [
        { text: 'Profile Evaluation', url: '/ProfileEvaluation', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/evaluation.png` },
        { text: 'Mentors', url: '/mentorList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/presentation.png` },
        { text: 'Events', url: '/EventsList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/add-event.png` },
        { text: 'Whatsapp Groups', url: '/WhatsappGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/whatsapp1.png` },
        { text: 'Telegram Groups', url: '/TelegramGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/telegram.png` },
        { text: 'Facebook Groups', url: '/FacebookGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.jpeg` },
        // {text: 'Zolve', url: '/zolve', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/zolve.png`},
        // { text: 'Education Loan', url: '/EducationLoanList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/mortarboard.png` },
        { text: 'Find Accommodation', url: '/accomodation', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png` },
        // { text: 'Apply Internship', url: '/applyinternship', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.png` },
        { text: 'Document Checklist', url: '/document-checklist', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/signature.png` },
        { text: 'Agreements', url: '/privacy-policy', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/invoice.png` },
        // {text: 'Invoice', url: '/ComingSoon', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/documents.png`},
        { text: 'FAQ', url: '/faq', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/faq.png` },
        { text: 'Services', url: '/ServicesLists', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png` },
        // {text: 'Videos', url: '/ComingSoon', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.png`},
        // {text: 'Chat', url: '/ComingSoon', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/chat.png`},
        // {text: 'Contact Us', url: '/ComingSoon', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/communicate.png`}
    ]

    const employeeMenuList = [
        {
            text: 'Student', url: {
                pathname: '/student-list',
                state: {
                    'status': 'All',
                    "type": "Student"
                }
            }, image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png`
        },
        {
            text: 'Registered', url: {
                pathname: '/student-list',
                state: {
                    'status': 'All',
                    "type": "Register"
                }
            }, image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png`
        },
        {
            text: 'Applications', url: {
                pathname: '/student-list',
                state: {
                    'status': 'All',
                    "type": "Application"
                }
            }, image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png`
        },
        { text: 'Events', url: '/EventsList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/add-event.png` },
        { text: 'Whatsapp Groups', url: '/WhatsappGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/whatsapp1.png` },
        { text: 'Telegram Groups', url: '/TelegramGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/telegram.png` },
        { text: 'Facebook Groups', url: '/FacebookGroup', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.jpeg` },
        // { text: 'Education Loan list', url: '/EducationLoanList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/mortarboard.png` },
        { text: 'Accommodations', url: '/accomodation', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png` },
        // { text: 'Internship List-Details', url: '/DocumentCheckList', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.png` },
        { text: 'Document Checklist', url: '/document-checklist', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/signature.png` },
        { text: 'Agreements', url: '/privacy-policy', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/invoice.png` },
        // { text: 'Invoice', url: '/', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/documents.png` },
        { text: 'FAQ', url: '/faq', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/faq.png` },
        { text: 'Services', url: '/', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/accommodation.png` },
        // { text: 'Videos', url: '/', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/facebook.png` },
        // { text: 'Chat', url: '/', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/chat.png` },
        // { text: 'Contact Us', url: '/', image: `${process.env.PUBLIC_URL}/img/SidebarIcon/communicate.png` }
    ]

    // const eduLoanMenuList = [
    //     { text: 'Student', url: '/' },
    //     { text: 'Events List-Details', url: '/EducationLoanList' },
    //     { text: 'Education Loan', url: '/accomodation' },
    //     { text: 'Accommodation list', url: '/' },
    //     { text: 'Internship List-Details', url: '/DocumentCheckList' },
    //     { text: 'Document Checklist', url: '/' },
    //     { text: 'Agreements', url: '/' },
    //     { text: 'Invoice', url: '/' },
    //     { text: 'FAQ', url: '/faq' },
    //     { text: 'Services', url: '/' },
    //     { text: 'Videos', url: '/' },
    //     { text: 'Chat', url: '/' },
    //     { text: 'Contact Us', url: '/' }
    // ]
    // const accommodationMenuList = [
    //     { text: 'Student', url: '/' },
    //     { text: 'Post Accomidation', url: '/' },
    //     { text: 'Manage Accomidation', url: '/' },
    //     { text: 'Events List-Details', url: '/EducationLoanList' },
    //     { text: 'Education Loan', url: '/accomodation' },
    //     { text: 'Accommodation list', url: '/' },
    //     { text: 'Internship List-Details', url: '/DocumentCheckList' },
    //     { text: 'Document Checklist', url: '/' },
    //     { text: 'Agreements', url: '/' },
    //     { text: 'Invoice', url: '/' },
    //     { text: 'FAQ', url: '/faq' },
    //     { text: 'Services', url: '/' },
    //     { text: 'Videos', url: '/' },
    //     { text: 'Chat', url: '/' },
    //     { text: 'Contact Us', url: '/' },
    //     { text: 'Push Notification', url: '/' }
    // ]
    // const internshipMenuList = [
    //     { text: 'Interns', url: '/' },
    //     { text: 'Post Internship', url: '/' },
    //     { text: 'Manage Internship', url: '/' },
    //     { text: 'Events List-Details', url: '/EducationLoanList' },
    //     { text: 'Education Loan', url: '/accomodation' },
    //     { text: 'Accommodation list', url: '/' },
    //     { text: 'Internship List-Details', url: '/DocumentCheckList' },
    //     { text: 'Document Checklist', url: '/' },
    //     { text: 'Agreements', url: '/' },
    //     { text: 'Invoice', url: '/' },
    //     { text: 'FAQ', url: '/faq' },
    //     { text: 'Services', url: '/' },
    //     { text: 'Videos', url: '/' },
    //     { text: 'Chat', url: '/' },
    //     { text: 'Contact Us', url: '/' },
    //     { text: 'Push Notification', url: '/' }
    // ]
    // const universityMenuList = [
    //     { text: 'Student', url: '/' },
    //     { text: 'Applications', url: '/' },
    //     { text: 'Enrolled', url: '/' },
    //     { text: 'Programs', url: '/EducationLoanList' },
    //     { text: 'Events List-Details', url: '' },
    //     { text: 'Post Events', url: '' },
    //     { text: 'Education Loan List-Details', url: '/accomodation' },
    //     { text: 'Accommodation list', url: '/' },
    //     { text: 'Agreements', url: '/' },
    //     { text: 'Invoice', url: '/' },
    //     { text: 'FAQ', url: '/faq' },
    //     { text: 'Services', url: '/' },
    //     { text: 'Videos', url: '/' },
    //     { text: 'Chat', url: '/' },
    //     { text: 'Contact Us', url: '/' },
    //     { text: 'Push Notification', url: '/' }
    // ]


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        clearUserData()
        history.push('/')
    }

    const GetActiveMenu = () => {
        if (user_contact_type === "Student") {
            return studentMenuList
        }
        else if (user_contact_type === "University") {
            return employeeMenuList
        }
        else if (user_contact_type === "Internship/Job") {
            return employeeMenuList
        }
        else if (user_contact_type === "Accommodation") {
            return employeeMenuList
        }
        else if (user_contact_type === "Employee" || user_contact_type === "Partner" || user_contact_type === "Associate" || user_contact_type === "Associate Admin") {
            return employeeMenuList
        }
        else{
            return navListNoAuth
        }
        // else if (user_contact_type === "Associate Admin") {

        // }
        // else if (user_contact_type === "Admin") {

        // }
        // else if (user_contact_type === "University") {
        //     return universityMenuList

        // }


    }


    useEffect(() => {
        GetUploadDocument()
        var data = `{\n"student_id":"${getUserId()}","bypass":"validation"\n}`;
        MentorDocumentDetailAction(data).then((response) => {
            setmentorData(response)
        }).catch((error) => {
            setmentorData([])
        })
    }, [])

    const list = (anchor) => {
        return <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {GetActiveMenu()?.map((obj, index) => (
                    <ListItem style={{ fontSize: 11, padding: '2px 10px' }} button key={obj.text}>
                        <div onClick={() => history.push(obj.url)} style={{ display: "flex" }}>
                            <img src={obj.image} style={{ height: 30, width: 30 }} />
                            <ListItemText primary={obj.text} style={{ marginLeft: 10 }} />
                        </div>
                    </ListItem>
                ))}
            </List>
        </Box>
    };

    let LogoLink = user_contact_type === "Student" ? '/dashboard' : "/associates"

    if(location.pathname === '/' || location.pathname === "/login" || location.pathname === "/SignUp" || location.pathname === "/forgetPassword" || location.pathname === "/RegisterForm" || location.pathname === "/TransferUniversityForm" || location.pathname.includes('/token') ){
        return <></>
    }
    return (
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', bgcolor: '#fafafa' }}>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"
                onClick={toggleDrawer('left', true)}
                color="inherit"
                sx={{ marginTop: 1, paddingLeft: 0 }}
            >
                <MenuIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <Drawer
                // anchor={anchor}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
            >
                {/* <div style={{
                    display: 'inline-block',
                    margin: '20px 0 0',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '1px solid #e5e5e5',
                    paddingBottom: '10px'
                }}>
                    <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt='user'/>
                </div> */}
                {list('left')}

                <Grid item xs={12} style={{ textAlign: "center", maxHeight: 40 }}>
                    <span style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        color: "rgba(27, 108, 252)"
                    }}>{mentorData?.data?.mentor?.name || ''}</span>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-around" }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 30,
                        height: 48,
                        width: 48,
                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                    }}>
                        <a href={`mailto:${mentorData?.data?.mentor?.email || ''}`}><img src={`${Message}`} style={{
                            height: 30,
                            width: 30
                        }} /></a>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 30,
                        height: 48,
                        width: 48,
                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                    }}>
                        <a href={`https://api.whatsapp.com/send?phone=${mentorData?.data?.mentor?.mobile || ''}&text=Hi`}>
                            <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
                    </div>
                    <div
                        onClick={() => {
                            window.open(`tel:${mentorData?.data?.mentor?.mobile}`);
                        }}
                        style={{
                            marginRight: 16,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 30,
                            height: 48,
                            width: 48,
                            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                        }}>
                        <img src={`${Calling}`} style={{ height: 30, width: 30 }} />
                    </div>
                </Grid>
            </Drawer>
            <Link to={LogoLink} style={{ marginTop: 27, marginLeft: 8, cursor:'pointer' }}><img
                src={`${process.env.PUBLIC_URL}/img/logo.png`} alt='user' /></Link>
            <IconButton
                // onClick={() => history.push('/SearchUniversity')}
                onClick={() => history.push('/')}
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                // aria-haspopup="true"
                color="inherit"
                sx={{ marginTop: 1, flex: 2, justifyContent: 'flex-end',"&:hover": { backgroundColor: "transparent" } }}
            >
                <SearchIcon sx={{ fontSize: 25 }} />
            </IconButton>

            <IconButton
                onClick={() => SetOpen(true)}
                // onClick={() => history.push('/InviteShare')}
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"

                color="inherit"
                sx={{ marginTop: 1, justifyContent: 'flex-end' }}
            >
                <ShareOutlinedIcon sx={{ fontSize: 25 }} />
            </IconButton>

            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"

                color="inherit"
                sx={{ marginTop: 1, justifyContent: 'flex-end' }}
            >
                <Link to="/notifications"><NotificationsNoneIcon sx={{ fontSize: 25 }} /></Link>
            </IconButton>

            <IconButton
                onClick={handleClick}
                size="large"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"

                color="inherit"
                sx={{ marginTop: 1, paddingRight: 0, justifyContent: 'flex-end', position:'relative' }}
            >
                {user_contact_type === "Student" && overall_percentage ? <>
                <Typography style={{ position: "absolute", right: "18px", top: "26px", zIndex: "9", fontSize: "10px", color: "blue", fontWeight: "bolder" }}>{Math.round(overall_percentage/5)}%</Typography>
                <CircularProgress  variant="determinate" color={barColor(overall_percentage/5)}  value={Math.round(overall_percentage/5)} style={{  borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", top: "16px", right: "12px"}} />
                </>
                :
                ''
                }

                <img src={profile_img} alt='user'
                    style={{ height: 30, width: 30, marginRight: 15 }}  />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem
                    onClick={() => history.push({
                        pathname: '/Profile',
                        state: {
                            'formNo': 1,
                        }
                    })} >
                    <img style={{ marginRight: 5 }} src={`https://cdn-icons-png.flaticon.com/512/1177/1177568.png`} alt='user' style={{ height: 30, width: 30, marginRight: 5 }} /> Profile
                </MenuItem> */}

                <MenuItem onClick={() => history.push({
                    pathname: '/Profile',
                    state: {
                        'formNo': 1,
                    }
                })}>
                    <ListItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/file.png`} alt='user'
                            style={{ width: 30, height: 30, marginRight: 5 }} />
                        {/* <Settings fontSize="small" /> */}
                    </ListItemIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        Personal
                        {/* <img src={Completed} alt="" /> */}
                        <Typography style={{ position: "absolute", right: "16px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(personal_percentage)}%</Typography>
                        <CircularProgress  variant="determinate" color={barColor(personal_percentage)}  value={Math.round(personal_percentage)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />

                    </div>
                </MenuItem>

                {user_contact_type === "Student" && <MenuItem onClick={() => history.push({
                    pathname: '/Profile',
                    state: {
                        'formNo': 2,
                    }
                })}>
                    <ListItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/explore.png`} alt='user'
                            style={{ width: 30, height: 30, marginRight: 5 }} />
                        {/* <Settings fontSize="small" /> */}
                    </ListItemIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        Looking for
                        <Typography style={{ position: "absolute", right: "16px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(looking_for)}%</Typography>
                        <CircularProgress  variant="determinate" color={barColor(Math.round(looking_for))}  value={Math.round(looking_for)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />

                        {/* <CheckCircleIcon style={{ marginLeft: 10, fontSize: 30, color: "orange" }} /> */}
                    </div>
                </MenuItem>
                }

                {user_contact_type === "Student" && <MenuItem onClick={() => history.push({
                    pathname: '/Profile',
                    state: {
                        'formNo': 3,
                    }
                })}>
                    <ListItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/academic.png`} alt='user'
                            style={{ width: 30, height: 30, marginRight: 5 }} />
                        {/* <Settings fontSize="small" /> */}
                    </ListItemIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        Academic
                        <Typography style={{ position: "absolute", right: "16px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(academic_percentage)}%</Typography>
                        <CircularProgress  variant="determinate" color={barColor(Math.round(academic_percentage))}  value={Math.round(academic_percentage)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />

                    </div>
                </MenuItem>
                }
                {user_contact_type === "Student" && <MenuItem onClick={() => history.push({
                    pathname: '/Profile',
                    state: {
                        'formNo': 4,
                    }
                })}>
                    <ListItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/quality-control.png`} alt='user'
                            style={{ width: 30, height: 30, marginRight: 5 }} />
                        {/* <Settings fontSize="small" /> */}
                    </ListItemIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        Test Scores
                        <Typography style={{ position: "absolute", right: "16px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(test_score)}%</Typography>
                        <CircularProgress  variant="determinate" color={barColor(Math.round(test_score))}  value={Math.round(test_score)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />

                    </div>
                </MenuItem>}
                {user_contact_type === "Student" && <MenuItem onClick={() => history.push({
                    pathname: '/Profile',
                    state: {
                        'formNo': 5,
                    }
                })}>
                    <ListItemIcon>
                        {/* <Settings fontSize="small" /> */}
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/user.png`} alt='user'
                            style={{ width: 30, height: 30, marginRight: 5 }} />
                    </ListItemIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        Recommenders
                        <Typography style={{ position: "absolute", right: "16px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{Math.round(recommend_score)}%</Typography>
                        <CircularProgress  variant="determinate" color={barColor(Math.round(recommend_score))}  value={Math.round(recommend_score)} style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />

                    </div>
                </MenuItem>}

                {user_contact_type === "Student" && <MenuItem onClick={() => history.push({
                    pathname: '/UploadDocuments',
                })}>
                    <ListItemIcon>
                        {/* <Settings fontSize="small" /> */}
                        <img src={`${process.env.PUBLIC_URL}/img/SidebarIcon/documents.png`} alt='user'
                            style={{ width: 30, height: 30, marginRight: 5 }} />
                    </ListItemIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        Documents 
                        <Typography style={{ position: "absolute", right: "22px", bottom: "16px", zIndex: "9", fontSize: "10px", color: "#000", fontWeight: "bold" }}>{doc_list?.doc_list?.length}</Typography>
                        <CircularProgress  variant="determinate"  style={{ backgroundColor:"#E4E4E4", borderRadius: "50px", width: "35px", height: "auto !important", padding:"3px", position:"absolute", bottom: "6px", right: "10px"}} />

                    </div>
                </MenuItem>}
                <Divider />
                <MenuItem style={{display:'inline-block',width:'100%', margin:'auto',textAlign:'center'}}>
                <FacebookIcon style={{width:'25%'}} onClick={()=>window.location.href="https://www.facebook.com/bscglobaledu"} sx={{fontSize:'25px', color:'#3b5998',cursor:'pointer'}} />
                <InstagramIcon style={{width:'25%'}} onClick={()=>window.location.href="https://www.instagram.com/brainstorm.consulting/"}  sx={{fontSize:'25px', color:'#bc2a8d',cursor:'pointer'}} />
                <LinkedInIcon style={{width:'25%'}} onClick={()=>window.location.href="https://www.linkedin.com/in/bsc-universitysearch-a45662193/"} sx={{fontSize:'25px', color:'#0072b1',cursor:'pointer'}} />
                <TelegramIcon style={{width:'25%'}} onClick={()=>window.location.href="https://t.me/Brainstromconsulting"} sx={{fontSize:'25px', color:'#0088cc',cursor:'pointer'}} />
                </MenuItem>
                <Divider />

                <MenuItem onClick={() => history.push('/recoverPassword')}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem onClick={() => history.push({
                    pathname: '/students',
                    state: {
                        'status': 'Referred List',
                        "type": "Referral",
                        'val': getUser()?.mobile,
                    }
                })}>
                    <ListItemIcon>
                        <AutoAwesomeIcon fontSize="small" />
                    </ListItemIcon>
                    Rewards
                </MenuItem>
                <MenuItem onClick={(e) => e.stopPropagation()}>
                    {/* <ListItemIcon>
                        <AutoAwesomeIcon fontSize="small"/>
                    </ListItemIcon>
                        Language */}
                    <ReactFlagsSelect
                        countries={["US", "GB", "FR", "DE", "IT"]}
                        customLabels={{ "US": "EN-US", "GB": "EN-GB", "FR": "FR", "DE": "DE", "IT": "IT" }}
                        placeholder="Select Language"
                        showSelectedLabel={false}
                        showOptionLabel={false}
                        selectedSize={18}
                        optionsSize={14}

                    />
                    {/* <ReactFlagsSelect
                        countries={["US", "GB", "FR","DE","IT"]}
                        customLabels={{"US": "EN-US","GB": "EN-GB","FR": "FR","DE": "DE","IT": "IT"}}
                        placeholder="SelectCountry" 
                        value={'countries'}
                        showSelectedLabel={true}
                        showOptionLabel={true}
                    /> */}

                </MenuItem>
                <MenuItem onClick={function(e){
                    e.stopPropagation();
                }}>
                <ListItemIcon>
                    <NotificationsNoneIcon fontSize="small"/>
                </ListItemIcon>
                Notification 
               
                <FormControlLabel  
                sx={{margin:'auto'}}
                control={<IOSSwitch onClick={handleNotification} sx={{ m: 1 }} checked={isNotify} />}
                // label="iOS style"
                />
                </MenuItem>
                <MenuItem onClick={() => logOut()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <AddStudentModal open={open1} onClose={() => SetOpen(false)} />
        </Box>
    )
}
