import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, Chip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Chat from './Chat';
import { useHistory } from 'react-router-dom';
import { getStudentCountListUsingStatusAction, GetRegisterCountDataAction } from '../../Action/AssociateDocumentList';
import { getUser } from '../../../Controller/localStorageHandler';
import { Box } from '@mui/system';
import { fetchComoonPickerListAction } from '../../Action';
// import { AddStudentModal } from './AddStudentModal';

const StudentListPage = ({ source,confirm, setOpenApply, SetSelectedStudentList, SelectedStudentList }) => {
    const history = useHistory();
    const classes = useStyles();
    const [StudentList, SetStudentList] = useState([])
    const [loading, Setloading] = useState(false)
    const [page, setPage] = React.useState(1)
    const [semesterlist, SetsemesterList] = React.useState([])
    const [yearList, SetyearList] = React.useState([])
    const [selectedSemester, SetSelectedSemester] = React.useState('All')
    const [selectedYear, SetSelectedYear] = React.useState('All')
    const [open, SetOpen] = React.useState(false)
    const [searchKey,setSearchKey] = React.useState('')
    const Limit = 9


    const loadMore = (page) => {
        setPage(page)
    }


    const GetSemester = () => {
        var data = new FormData();
        data.append('operation', 'fetchPicklist');
        data.append('name', 'semester');
        fetchComoonPickerListAction(data).then((semester) => {
            SetsemesterList(semester)
        }).catch((err) => {
            console.log("err", err)

        })
    }

    const GetYear = () => {
        var data = new FormData();
        data.append('operation', 'fetchPicklist');
        data.append('name', 'year');
        fetchComoonPickerListAction(data).then((semester) => {
            SetyearList(semester)
        }).catch((err) => {
            console.log("err", err)
        })
    }

    useEffect(() => {
        GetSemester()
        // GetYear()
        GetStudentList()
    }, [])

    const GetStudentList = () => {
        let ProfileData = getUser()
        var data = JSON.stringify({
            "contact_no": ProfileData.contact_no,
            "status": "All",
            "year": selectedYear ? selectedYear : 'All',
            "semester": selectedSemester ? selectedSemester : 'All',
            "daterange": "",
            "limit_start": Limit * (page - 1),
            "limit_end": Limit,
            "search": '',
            "bypass": "validation",
            "searchKey" : searchKey ? searchKey : '',
        });
        Setloading(true)
        SetStudentList([])
        getStudentCountListUsingStatusAction(data).then((Data) => {
            Setloading(false)
            SetStudentList(Data?.data || [])
        }).catch((error) => {
            Setloading(false)
            console.log("error", error)
        })
    }

    useEffect(() => {
        GetStudentList()
    }, [page, selectedSemester, selectedYear,searchKey])

    const getDropList = () => {
        const year = new Date().getFullYear();
        return (
          Array.from( new Array(10), (v,i) =>
            <MenuItem value={year+i}>{year+i}</MenuItem>
          )
        );
      };

    return (
        <div >
            <div>
                <h3>{source ==='bookmark' ? 'Add student bookmark' : 'Apply for Student'}</h3>
                <hr />
            </div>
            <main className={`${classes.inputContainer}`} style={{ marginTop: 25 }}>
                <SearchIcon sx={{ opacity: 0.5 }} />
                <input className={classes.input} autoFocus 
                placeholder='Search Students...'
                onChange={(e)=>setSearchKey(e.target.value)}
                />
            </main>

            <div className={`${classes.dropdownContainer} flexCenterJcB`}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">All</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={({ target }) => {
                            SetSelectedSemester(target.value)
                        }}
                    >
                        {semesterlist.length > 0 && semesterlist.map((d) => {

                            return <MenuItem value={d}>{d}</MenuItem>
                            // <MenuItem value={20}>Twenty</MenuItem>
                            // <MenuItem value={30}>Thirty</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                {/* ===================================================================== */}
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">All</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={({ target }) => {
                            SetSelectedYear(target.value)
                        }}
                    >
                        {/* {yearList.length > 0 && yearList.map((d) => {
                            return <MenuItem value={d}>{d}</MenuItem>
                        })} */}
                        {getDropList()}
                    </Select>
                </FormControl>
                {/* ===================================================================== */}
                {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">All</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={({ target }) => {
                        }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl> */}
                {/* ===================================================================== */}
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Date</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
            </div>


            {/* <div className='flexCenterJcB'> */}
            {/* <main className='flexCenterJcB'>
                    <CheckBoxOutlineBlankOutlinedIcon className={classes.squareIcon} />
                    <p className={classes.student}>Students - {history.location.state.status}  {StudentList?.total || 0}</p>
                </main>

                <main>
                    <AddBoxOutlinedIcon className={classes.addIcon} />
                </main>
            </div>

            <div className={classes.addContact} onClick={() => { SetOpen(true) }}>
                <img src={`${process.env.PUBLIC_URL}/img/student/addContact.png`} alt='' />
                <p style={{ fontWeight: 'bold' }}>Add New Contact</p>
            </div> */}
            {loading ?
                <div style={{ textAlign: 'center', marginTop: '55%' }}>
                    <CircularProgress />
                </div> :

                StudentList?.list?.length > 0 && StudentList?.list?.map((d) => {
                    return <div className="pt-3"><Chat SetSelectedStudentList={SetSelectedStudentList} data={d} /></div>
                })
            }
            {!loading && <Box>
                <Pagination
                    style={{ paddingTop: 50 }}
                    defaultPage={1}
                    page={page}
                    count={Math.ceil(StudentList?.total / 10)}
                    color="primary"
                    onChange={(e, p) => loadMore(p)}
                />
            </Box>}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Chip
                    label="Next"
                    sx={{ bgcolor: '#1B6CFC', color: '#fff', marginTop: '5px', marginRight: '15px', float: 'left', width: 150 }}
                    onClick={(e) => {
                       source === 'bookmark' 
                       ?
                       confirm('add')
                       :
                        setOpenApply()
                    }}
                />
            </div>
            {/* <AddStudentModal open={open} onClose={() => SetOpen(false)} /> */}
        </div>
    )
}

export default StudentListPage


const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        padding: '10px',
        width: '100%',
        height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        boxShadow: '2px 2px 10px #DCDCDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7
    },
    dropdownContainer: {
        margin: '10% 0 6% 0'
    },
    squareIcon: {
        opacity: 0.5
    },
    addIcon: {
        opacity: 0.9,
        color: '#1b6cfc',
        border: '1px solid',
        padding: '5px',
        borderRadius: '20px',
        borderColor: 'lightgray',
        boxShadow: '1px 2px 3px lightgray'
    },
    student: {
        fontWeight: 'bold',
        marginLeft: '3%',
        width: '300px'
    },
    addContact: {
        display: 'flex',
        alignItems: 'center'
    }
}))