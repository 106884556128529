import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import "./commonType.css";
import "./city.css"

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));


const Opinions = ({ HandlePrev, HandleNext }) => {
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div class="accordion accordion-flush bootAccor slidingCards " id="accordionFlushExample1" style={{ minWidth: "100% !important" }}>
            <div style={{ display: "flex", justifyContent: "space-between", background: "#1B6CFC", textAlign: "center", borderRadius: "10px 10px 0px 0px", padding: "0.5rem 1.0rem" }}>
                <span style={{ color: "white" }} onClick={() => HandlePrev()}>     Prev</span>
                <span style={{ marginBottom: 0, color: "white" }}>  City/Cities</span>
                <span style={{ color: "white" }} onClick={() => HandleNext()}> Next</span>
            </div>
            <div className="accordion-collapse collapse show">
                <div className="collapse_check_item">
                    <input type="text" placeholder="e.g 4" className="input_tag" />
                </div>
            </div>
        </div>
    );
};

export default Opinions;
