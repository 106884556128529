import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, Chip,Box } from "@mui/material";
import { fetchRelatedProgramAction } from '../Action';
import CountryCard from "./CountryCard"
import { getUserId } from "../../Controller/localStorageHandler";
import Pagination from '@mui/material/Pagination';

export const RelatedProgrames = () => {
    const [loading, setLoading] = useState(false)
    const [fetchRelaventProgrameData, setFetchRelaventProgrameData] = useState([]);
    const [page, setPage] = React.useState(1)

    const Limit = 10

    const fetchRelaventPrograms = () => {
        setLoading(true)

        var data = JSON.stringify({
            "user_id": getUserId(),
            "limit_start": Limit * (page - 1),
            "limit_end": Limit,
            "bypass": "validation"
        });

        fetchRelatedProgramAction(data)
            .then((data) => {
                setLoading(false)
                setFetchRelaventProgrameData(data.data)
                console.log(data)
            }).catch((error) => {
                setLoading(false)
                setFetchRelaventProgrameData([])
            })

    }
    useEffect(() => {
        fetchRelaventPrograms()
    }, [page])

    const loadMore = (page) => {
        setPage(page)
    }

    if (loading) {
        return (<div className="gooey">
            <span className="dot"></span>
            <div className="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>)
    } else {
        return <>
            <Grid
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1
                }}>
                <Typography
                    sx={{
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: "700",
                        marginTop: "20px",
                        marginBottom: "20px"
                    }}>
                <Chip label="Related Programs" size="small" color="primary" />
                {' '} 
                <Chip label={fetchRelaventProgrameData?.length > 0 && fetchRelaventProgrameData['0'].alltotal} size="small" color="error" /> {" "}
 
                    {/* Related Programs {fetchRelaventProgrameData?.length || 0} */}
                </Typography>
                
            </Grid>
            {fetchRelaventProgrameData.length > 0 && fetchRelaventProgrameData.map((d) => {
                return <CountryCard Data={d} />
            })}


{!loading && <Box
             display="flex"
             justifyContent="center"
             alignItems="center">
                <Pagination
                    style={{ paddingTop: 50 }}
                    defaultPage={1}
                    page={page}
                    count={Math.ceil(fetchRelaventProgrameData?.length > 0 && fetchRelaventProgrameData['0'].alltotal / 10)}
                    color="primary"
                    onChange={(e, p) => loadMore(p)}
                />
            </Box>}
        </>
    }
}