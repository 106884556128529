import React from "react";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import Calling from '../../../img/Calling.png';
import whatsapp from '../../../img/whatsapp.png';
import { useHistory } from 'react-router-dom';
import { Checkbox } from "@mui/material";

const Chat = ({ data, SetSelectedStudentList }) => {
  const history = useHistory();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const classes = useStyles();
  return (
    <div className={`${classes.MainDiv}`}>
      <div className={`${classes.container} flexCenterJcB`} style={{ width: "100%", padding: 15 }}>
        <div style={{ width: "10%" }}  >
          <Checkbox {...label}
            onChange={() => {
              SetSelectedStudentList(data)
              // console.log(data)
            }}
          />
          {/* <CheckBoxOutlineBlankOutlinedIcon /> */}
          {/* /img/SidebarIcon/user.png */}
        </div>
        <div style={{ display: "flex", width: "60%" }} onClick={() => history.push({ pathname: '/Profile', state: { StudentData: data, formNo: 1 } })}>
          <img
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/img/SidebarIcon/user.png`}
            alt=""
          />
          <main className={classes.nameDetails}>
            <p className={classes.name}>{data.firstname} {data.lastname}</p>
            <p className={classes.details}>{data?.related_programs || ''}</p>
          </main>
        </div>
        {/* <nav className="flexCenterJcB"> */}
        <div style={{ display: "flex", width: "30%" }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 30,
            height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          }}>
            <a href={`https://api.whatsapp.com/send?phone=${data.mobile || ''}&text=Hi`}> <img src={`${whatsapp}`} style={{ height: 30, width: 30 }} /></a>
          </div>
          <div
            onClick={() => {
              window.open(`tel:${data.mobile}`);
            }}
            style={{
              marginLeft: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
              height: 48, width: 48, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
            }}>
            <img src={`${Calling}`} style={{ height: 30, width: 30 }} />
          </div>
          {/* <a href={`https://api.whatsapp.com/send?phone=${data.mobile || ''}&text=Hi`}>
            <img
              // className={classes.logos}
              src={`${process.env.PUBLIC_URL}/img/student/whatsapp.png`}
              alt=""
              style={{ width: 80, height: 80 }}
            />
          </a>
          <img
            onClick={() => {
              window.open(`tel:${data.mobile || ''}`);
            }}
            style={{ width: 80, height: 80 }}
            // className={classes.logos}
            src={`${process.env.PUBLIC_URL}/img/student/call.png`}
            alt=""
          /> */}
        </div>
        {/* </nav> */}
      </div>
    </div>
  );
};

export default Chat;

const useStyles = makeStyles(() => ({

  container: {
    background: "white",
    boxShadow: "rgba(0 0 0 / 0.4) 0px 10px 20px, rgba(0 0 0 / 0.4) 0px 2px 6px, rgba(0 0 0 / 0.4) 0px 0px 1px",
    backgroundColor: "#f0f0f0",
    borderRadius: "12px",
    padding: "0 3% 0 5%",
    height: '10%'
  },
  image: {
    height: "45px",
    width: "45px",
    borderRadius: "30px",
  },
  nameDetails: {
    marginTop: "2%",
  },
  name: {
    fontWeight: "bold",
    //   minWidth: "200px",
    flexGrow: 1,
    '@media (max-width: 472px)': {
      fontSize: '13px',
      wordBreak: 'break-all'
    }
  },
  details: {
    fontWeight: '500',
    marginTop: '-2%',
    '@media (max-width: 472px)': {
      fontSize: '11px',
      wordBreak: 'break-all'
    }
  },
  logos: {
    alignSelf: 'flex-end',
    '@media (max-width: 472px)': {
      justifyContent: 'center',
      //  margin : 'auto'
    }
  }
}));
