import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './static/fonts/gothic.ttf'
import ErrorBoundary from "./ErrorBoundary";
import { StyledEngineProvider } from "@mui/material";
// axios.defaults.baseURL = "https://erp.bscglobaledu.com/mapi/JsonApi";
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
    <StyledEngineProvider injectFirst>
      <App />
      </StyledEngineProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
