import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Modal, ModalUnstyled, Select, TextField,Alert, Typography, Button } from "@mui/material";
import { makeStyles } from '@mui/styles'
import { Box, styled, width } from '@mui/system'
import { ToastContainer, toast } from 'react-toastify';
import { fetchAddStudentAction, fetchComoonPickerListAction } from '../../../Action';
import { getSessionId, getUserId, getUserType,getUser } from '../../../../Controller/localStorageHandler';
import SearchIcon from '@mui/icons-material/Search';
import PhoneInput from 'react-phone-input-2';
import axios from "axios";
import {useHistory} from "react-router-dom";
import _ from "lodash"

import useAppContext from "../../../../useContext/userContext"

const style = {
    width: "95%",
    margin: "20px auto 0",
    backgroundColor: '#ffffff',
    border: 'transparent',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '20px',
    pt: 3,
    pb: 1,
    px: 0,
    overflow: 'scroll',
    height: "95%",
    pl: 3,
    pr: 3
};



export const AddStudentModal = (props) => {
    const classes = useStyles();
    const history = useHistory()
    // console.log(getUser())
    const {
        getRelatedProgramsPickList,related_programs_picklist,
        getCountryPickList,country_picklist,
        getSemesterPickList,semester_picklist,
        getYearPickList,year_picklist,
    } = useAppContext()

    const [FirstName, SetFirstName] = React.useState('')
    const [LastName, SetLastName] = React.useState('')
    const [Email, SetEmail] = React.useState('')
    const [PhoneNumber, SetPhoneNumber] = React.useState('')
    const [Degree, SetDegree] = React.useState('')

    const [selectedYear, SetSelectedYear] = React.useState(null)

    const [selectedSemester, SetSelectedSemester] = React.useState(null)

    const [Country, SetCountry] = React.useState(null)

    const [Programmer, SetProgrammer] = React.useState(null)

    const [error,setError] = useState(false);
    const [customer_id,setCustomer_id] = useState('');
    const [success,setSuccess] = useState('');


    const [check, setcheck] = useState(false);

    const [isdisable,setisDisable] = useState(true);


    useEffect(() => {
        // GetSemester()
        // GetYear()
        // GetCountry()
        // GetProgrammer()
        getRelatedProgramsPickList()
        getCountryPickList()
        getSemesterPickList()
        getYearPickList()
    }, [])


    const SubmitButtonHandler = () => {
        setisDisable(false)

        let Programe = _.uniq(typeof Programmer == "object" ? Programmer : Programmer.split("|##|"))
        let country = _.uniq(typeof Country == "object" ? Country : Country.split("|##|"))
 
        var data = new FormData();
        data.append('degree_looking_for', Degree);
        data.append('operation', 'Add_student');
        data.append('sessionid', getSessionId());
        data.append('userid', getUserId());
        if(getUserType() !== 'Student'){
        data.append('assigned_to',getUser()?.assigned_userid);
        }
        data.append('email_address', Email);
        data.append('phone_mobile', PhoneNumber);
        data.append('year_looking_for', selectedYear ? selectedYear : 'All');
        data.append('country', country ? country : 'All');
        data.append('semester_looking_for', selectedSemester ? selectedSemester : 'All');
        data.append('username', `${FirstName} ${LastName}`);
        data.append('related_programs', Programe ? Programe : 'All');
        data.append('referred_contact',getUser()?.mobile)
        data.append('from_url',window.location.href);
        if (!Email) {
            toast.error(`Email is Required`)
            setisDisable(true)
        } else if (!PhoneNumber) {
            toast.error(`Phone Number is Required`)
            setisDisable(true)
        } else
            fetchAddStudentAction(data).then((res) => {
                setisDisable(true)
                console.log(res)
                if (res.status === "false") {
                    toast.error(`${res.message}`)
                    setCustomer_id(res?.data)
                    setError(true)

                } else {
                    toast.success(`Student Add SuccessFully`)
                    props.onClose()
                }
            }).catch((error) => {
                toast.error(error?.message || 'student add Failed')
            })

    }

    const activate_acc = async ()=>{
        const formData= new FormData()
            // values[OPERATION]=operationValue.ForgotPassword
            formData.append('operation','forgotPassword');
            formData.append('username',Email);
            formData.append('type','activate');
            formData.append('customer_id',customer_id);
            
            await axios.post("https://erp.bscglobaledu.com/mapi/JsonApi/",formData)
            .then(response => {
              console.log(response)
                if(response.data.status === 'success'){
                    setError('')
                  setSuccess('Student account has been activated And New Password is been sent to student email')
                }else{
                  setError(response.data.msg)
                }
            })
      }

    return <>
        <Modal
            open={props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => props.onClose()}
        >

            <Box sx={style} >
                <div style={{ textAlign: "center" }}>
                    <h3>Invite Student</h3>
                    <hr />
                </div>
                {
                    error && 
                    <Typography
                    sx={{ color: "blue", textAlign: "center", fontSize: "15px", m: 1 }}
                  >
                      <Alert severity="error"> This Email or Mobile is already registered<Button onClick={() => activate_acc()}> Click here to Activate and send password.</Button></Alert>
                    
                    
                  </Typography>
                }
                {success && <Alert severity="success">{success}</Alert>}
                
                <div style={{ paddingLeft: 15, paddingRight: 15, height: "90%", overflowY: "scroll", paddingTop: 15 }}>
                    <div style={{ marginBottom: 15, width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <TextField
                            value={FirstName}
                            id="outlined-basic"
                            label="First Name"
                            variant="outlined"
                            style={{ width: "48%" }}
                            onChange={({ target }) => SetFirstName(target.value)}
                        />
                        <TextField
                            value={LastName}
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            style={{ width: "48%" }}
                            onChange={({ target }) => SetLastName(target.value)}
                        />
                    </div>
                    <TextField
                        type="email"
                        value={Email}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        style={{ width: "100%" }}
                        onChange={({ target }) => SetEmail(target.value)}
                    />

                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                // backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "start",
                                borderRadius: "30px",
                                border: "1px solid #EBF2FF",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                                padding: "10px 30px",
                                my: "10px"
                            }}
                        >
                            <PhoneInput
                                country={'in'}
                                value={PhoneNumber}
                                searchPlaceholder={'Mobile Number'}
                                inputClass={"mobile-field"}
                                onChange={phone => SetPhoneNumber(phone)}
                            />
                            {/*<input*/}
                            {/*    type="number"*/}
                            {/*    className={classes.input}*/}
                            {/*    autoFocus*/}
                            {/*    placeholder="Mobile Number"*/}
                            {/*/>*/}
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: 15, width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <FormControl style={{ width: "48%", paddingTop: 5 }}>
                            <Autocomplete
                                style={{ width: "100%", padding: 0 }}
                                name='Degree'
                                freeSolo
                                multiple
                                onChange={(e, value) => {
                                    SetDegree(value)
                                }}
                                options={["Masters", "Diploma", "Bachelors", "Phd"].map((option) => option)}
                                renderInput={(params) => <main className={classes.inputContainer} style={{ width: "100%" }}>
                                    <TextField  {...params} className={classes.input} label="Degree" />
                                </main>}
                            />

                        </FormControl>
                        <FormControl style={{ width: "48%", paddingTop: 5 }}>
                            <Autocomplete
                                style={{ width: "100%", padding: 0 }}
                                name='Country'
                                freeSolo
                                multiple
                                onChange={(e, value) => {
                                    SetCountry(value)
                                }}
                                options={country_picklist?.country_picklist && country_picklist?.country_picklist?.map((option) => option)}
                                renderInput={(params) => <main className={classes.inputContainer} style={{ width: "100%" }}>
                                    <TextField  {...params} className={classes.input} label="Country" />
                                </main>}
                            />
                        </FormControl>
                    </div>
                    <div style={{ marginBottom: 15, width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <FormControl style={{ width: "48%" }}>
                            <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                            <Select
                                label="Semester"
                                value={selectedSemester}
                                id="demo-simple-select"
                                onChange={({ target }) => {
                                    SetSelectedSemester(target.value)
                                }}
                            >
                                {semester_picklist?.semester_picklist?.length > 0 && semester_picklist?.semester_picklist?.map((d,i) => {
                                    return <MenuItem key={i} value={d}>{d}</MenuItem>
                                })
                                }
                            </Select>
                        </FormControl>

                        <FormControl style={{ width: "48%" }}>
                            <InputLabel id="demo-simple-select-labelYear">Year</InputLabel>
                            <Select
                                label="Year"
                                value={selectedYear}
                                id="demo-simple-select"
                                onChange={({ target }) => {
                                    SetSelectedYear(target.value)
                                }}
                            >

                                {year_picklist?.year_picklist?.length > 0 && year_picklist?.year_picklist?.map((d,i) => {
                                    return <MenuItem key={i} value={d}>{d}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>



                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            style={{ width: "100%" }}
                            name='Program'
                            freeSolo
                            multiple
                            onChange={(e, value) => { SetProgrammer(value) }}
                            options={related_programs_picklist?.related_programs_picklist && related_programs_picklist?.related_programs_picklist?.map((option) => option)}
                            renderInput={(params) => <main className={classes.inputContainer} style={{ width: "100%" }}>
                                <TextField  {...params} className={classes.input} label="Program" />
                            </main>}
                        />
                    </FormControl>

                    <Grid  sx={{ display: "flex", margin: "20px 0", }}>
                            <input type="checkbox"
                                //   {...label}
                                //   defaultChecked
                                style={{
                                    height: "22px",
                                    width: "30px",
                                    background: "orange",
                                    border: " 1px solid #DCE8FF"
                                }}
                                //    onChange={handleChange}
                                onChange={(e) => setcheck(e.target.checked)}
                            />
                            <Typography
                                sx={{
                                    color: "#666666",
                                    textAlign: "left",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    marginLeft: "5px",
                                    cursor:"pointer"
                                }}
                                onClick={() => history.push('/privacy-policy-front')}
                            >
                                By creating your account you agree with to our 
                                <span className={classes.Terms}> Terms</span> and <span
                                        className={classes.Terms}>Conditions</span>
                            </Typography>
                        </Grid>
                    <div style={{ marginBottom: 15 }}>
                        {
                            isdisable ? <Button type="submit"
                            disabled={!check}
                            variant="contained" className="submitBtn mt-4" onClick={() => SubmitButtonHandler()}> Submit</Button> : ''
                        }
    
                    </div>
                </div>
            </Box>


        </Modal>
        <ToastContainer />
    </>
}



const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        // padding: '10px',
        width: '100%',
        // height: "20%",
        border: '1px solid transparent',
        borderRadius: '20px',
        // boxShadow: '2px 2px 10px #DCDCDC',
    },
    input: {
        borderColor: 'transparent',
        outline: 'none',
        // marginLeft: '10px',
        fontSize: '16px',
        opacity: 0.7,
        letterSpacing: 0.7,
        width: "100%"
    },
    filterIcon: {
        position: 'relative',
        top: 10,
        height: '75px',
        width: '75px',
        //  boxShadow : '2px 2px 10px #DCDCDC','
    }
}))